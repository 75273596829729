<template>
    <div v-if="question" class="questionnaire-query">
        <div class="query">
            <template v-if="!isMobile" class="web-layout">
                <el-select 
                    v-model="selectedValue" 
                    class="list-selecter" 
                    :class="[selectedValue === '' ? 'no-option-choosen' : '']" 
                    clearable
                    filterable
                    placeholder="Search and Choose" 
                    no-match-text="No results found"
                    no-data-text="No results found"
                    size="large">
                    <el-option
                        v-for="option in listOptions"
                        :key="option.id"
                        :label="option.value"
                        :value="option.value">
                    </el-option>
                    <div class="add-new-choice-button" v-show="question.other">
                        <button class="add-button" @click="newChDialog = true">
                            <AddIcon></AddIcon>
                            Add
                        </button>
                    </div>
                </el-select>
                <el-dialog
                    title="Add a choice below"
                    custom-class="new-choice-dialog"
                    :visible.sync="newChDialog"
                    :close-on-click-modal="false"
                    :handleClose="handleClose2"
                    :show-close="false">
                        <div class="new-choice-cont">
                            <el-input
                                class="new-choice-input"
                                type="textarea"
                                :rows="2"
                                placeholder="Start typing..."
                                v-model="other">
                            </el-input>
                            <span class="character-count" :style="charactersLeft <= 0 ? 'color: #da4e4e' : ''">
                                {{ charactersLeft }} character{{ charactersLeft > 1 ? 's' : '' }}
                            </span>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <el-button class="cancel-btn" @click="newChDialog = false">Cancel</el-button>
                            <el-button class="done-btn" type="primary" @click="addOption">Done</el-button>
                        </div>
                </el-dialog>
            </template>
            <template v-else class="mobile-web-layout">
                <div class="list-select-cont" :class="[(selectedValue.trim() == '' || selectedValue === null || selectedValue === undefined) ? '' : 'valued-select-cont']" v-on:click="openDialog">
                    <span class="list-value-cont">
                        <span class="default-value" v-if="selectedValue.trim() == '' || selectedValue === null || selectedValue === undefined">
                            Choose
                        </span>
                        <span v-else class="selected-value">
                            {{selectedValue}}
                        </span>
                    </span>
                    <span class="down-arrow-icon">
                        <Arrow></Arrow>
                    </span>
                </div>
                <el-dialog
                    custom-class="select-query-dialog"
                    :visible.sync="openListDialog"
                    :close-on-click-modal="false"
                    :handleClose="handleClose"
                    :modal-append-to-body="true"
                    @open="handleDialogOpen">
                    <div class="popup-dialog-cont">
                        <div class="filter-input-cont">
                            <input type="text" class="search-box" ref="filter" placeholder="Search" v-model="searchedText">
                            <button class="close-icon" v-show="(searchedText.length > 0)" v-on:click="clearSelected"></button>
                        </div>
                        <div class="other-option" v-show="question.other">
                            <button v-if="!otherFocus" class="add-button" @click="focusOther" >
                                <AddIcon></AddIcon>
                                Add
                            </button>
                            <input v-else id="myTextField" class="other-input" ref="other" v-model="other"
                                v-bind:style="{ 'font-size': '16px' }"
                                @change="addOption"
                                @blur="addOption"
                                v-on:keyup.13="addOption">
                            <span v-show="otherFocus" class="character-count" :style="charactersLeft <= 0 ? 'color: #da4e4e' : ''">
                                {{ charactersLeft }} character{{ charactersLeft > 1 ? 's' : '' }}
                            </span>
                        </div>
                        <ul class="list-cont" :class="[otherFocus ? 'other-input-enabled' : '']">
                            <li v-for="item in listOptions" :id="item.id" :key="item.id" @click="handleSelect" :class="['list-option', (!!selectedValue && selectedValue === item.value ? 'highlight-sel-val' :  '')]">
                                {{ item.value }}
                            </li>
                            <li v-if="searchedText && !listOptions.length" class="no-match">
                                No results found
                            </li>
                        </ul>
                        
                    </div>
                </el-dialog>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Question from './index'
    import { Select, Option, Dialog, Autocomplete, Button, Input } from 'element-ui'
    import AddIcon from './icon/Add.vue'
    import Arrow from "../../../setting/image/Arrow.vue"
    import { v4 as uuidv4 } from 'uuid'
    import { mapGetters } from 'vuex'

    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Dialog)
    Vue.use(Autocomplete)
    Vue.use(Button);
    Vue.use(Input);

    export default {
        name: 'questionnaire-query',
        extends: Question,
        props: {
            saved: {
                type: Array
            }
        },
        data() {
            return {
                selectedValue: '',
                openListDialog: false,
                searchedText: '',
                other: '',
                defaultLimit: 180,
                otherFocus: false,
                newChDialog: false
            }
        },
        watch: {
            selectedValue: function() {
                if(this.selectedValue.trim() === '' || this.selectedValue === null || this.selectedValue === undefined) this.$emit('selected', [])
                else {
                    let reqObj = this.getSelectedObject(this.selectedValue)
                    if(reqObj == '') this.$emit('selected', [])
                    else this.$emit('selected', [ reqObj ])
                }
            },
            other(value) {
                if (value.length > this.defaultLimit)
                    this.other = this.other.substr(0, this.defaultLimit)
            }
        },
        mounted() {
            if (!this.saved || this.saved.length === 0)
                this.$emit('selected', [])
            else {
                this.selectedValue = this.saved[0].value
            }
        },
        computed: {
            ...mapGetters('user', [
                'getQueryQuestOptions',
            ]),
            listOptions() {
                var links = this.questionOptions;
                var results = this.searchedText && this.searchedText.length >= 2 ? links.filter(this.createFilter(this.searchedText)) : links;
                return results
            },
            charactersLeft() {
                return this.defaultLimit - this.other.length
            },
            questionOptions() {
                if(Object.keys(this.getQueryQuestOptions).length) return this.getQueryQuestOptions[this.question.query_type.split(":")[0]][this.question.query_type.split(":")[1]]
                else return []
            }
        },
        methods: {
            getSelectedObject(value) {
                let found = this.questionOptions && this.questionOptions.find(opt => opt.value === value)
                if (!!found) {
                    found.description = String(found.value)
                    found.value = String(found.value)
                    return found
                } else return ''
            },
            openDialog() {
                this.openListDialog = true
            },
            handleClose() {
                this.openListDialog = false
            },
            handleClose2() {
                this.openListDialog = false
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            handleSelect(event) {
                this.selectedValue = event.target.innerText.trim()
                this.openListDialog = false
            },
            clearSelected() {
                this.searchedText = ""
                this.selectedValue = ""
            },
            handleDialogOpen() {
                if(this.selectedValue.trim() !== '') {
                    this.searchedText = ''
                    const selVal = this.questionOptions && this.questionOptions.find(opt => opt.value === this.selectedValue)
                    this.$nextTick(() => {
                        document.getElementById(selVal.id).scrollIntoView()
                    })
                }
            },
            focusOther() {
                this.otherFocus = true
                this.$nextTick(() => this.$refs.other.focus())
            },
            addOption() {
                this.other = this.other.trim()
                if (this.other !== '') {
                    if (this.questionOptions.length) {
                        if (this.questionOptions.filter(option => option.value === this.other).length)
                        return
                    }

                    let reqObj = {
                        value: this.other,
                        description: this.other,
                        id: uuidv4()
                    }
                    

                    if (this.questionOptions.length)
                        this.questionOptions.push(reqObj)
                    // this.select(reqObj)

                    this.selectedValue = this.other.trim()
                }

                if(!this.isMobile) {
                    this.newChDialog = false
                } else {
                    this.otherFocus = false
                    this.openListDialog = false
                }
                this.other = ''
            },
            // filterMethod(val) {
            //     this.searchedText = val
            // },
            // dropdnDisapp() {
            //     this.searchedText = ""
            // }
        },
        components: {
            Arrow,
            AddIcon
        }
    }
</script>

<style lang="scss" scoped>
.query {
    .el-select {
        width: 100%;
    }
}
</style>
<style lang="scss">
// .el-select-dropdown.el-popper {
//     .el-scrollbar.is-empty {
//         display: block !important;
//     }
// }
.el-select.list-selecter {
    .el-input {
        input.el-input__inner {
            border: 1px solid #CCCCCC;
        }

        input:read-only {
            border: 2px solid #833E93;
            font-weight: bold;
        }
    }
}
.el-select.no-option-choosen {
    .el-input {
        input.el-input__inner {
            border: 1px solid #CCCCCC !important;
        }
    }
}
.el-dialog.new-choice-dialog {
    width: 472px;
    height: 250px;
    border-radius: 4px;
    background-color: #ffffff;

    .el-dialog__header {
        text-align: left;
        font-weight: bold;
        padding: 25px 20px 10px;
        height: 20%;

        .el-dialog__title {

        }
    }

    .el-dialog__body {
        padding: 10px 20px;
        height: 50%;

        .new-choice-cont {
            .new-choice-input {
                border: 1px solid #833E93;

                .el-textarea__inner {
                    background-color: #FAF5FC;
                    resize: none;
                    font-size: 18px;
                    color: #833E93;
                    font-weight: bold;
                    border: unset;
                }
                .el-textarea__inner:focus {
                    outline: none;
                    border-color: unset;
                }

                .el-textarea__inner::placeholder {
                    color: #833E93;
                    font-weight: normal;
                }
            }

            .character-count {
                float: right;
                color: #666666;
                font-size: 13px;
                margin-top: 7px;
                // margin-right: 20px;
            }
        }
    }

    .el-dialog__footer {
        height: 30%;

        .dialog-footer {
            display: inline-flex;
            width: 100%;

            .cancel-btn {
                width: 35%;
                font-weight: bold;
                margin-right: 2%;
                color: #833E93;
                border: 2px solid #833E93;
            }
            .cancel-btn:hover {
                border: 2px solid #833E93;
                background-color: #ffffff;
            }
            .cancel-btn:focus {
                border: 2px solid #833E93;
                background-color: #ffffff;
            }

            .done-btn {
                margin-left: 2%;
                width: 61%;
                background-color: #833E93;
                font-weight: bold;
                border-color: #833E93;
            }
            .done-btn:focus {
                background-color: #833E93;
                font-weight: bold;
                border-color: #833E93;
            }
            .done-btn:hover {
                background-color: #833E93;
                font-weight: bold;
                border-color: #833E93;
            }
        }
    }
}
.el-select-dropdown.el-popper {
    .el-scrollbar {
        .el-select-dropdown__wrap.el-scrollbar__wrap.el-scrollbar__wrap--hidden-default {
            ul.el-scrollbar__view.el-select-dropdown__list {
                li.el-select-dropdown__item.selected {
                    background-color: #FAF5FC;

                    span {
                        color: #833E93;
                        font-weight: bold;
                    }
                }
                li.el-select-dropdown__item.hover {
                    background-color: #F8F8F8;
                }

                div.add-new-choice-button {
                    button.add-button {
                        height: 45px;
                        color: #833E93;
                        font-weight: bold;
                        padding: 1px 20px;
                        width: 100%;
                        display: inline-flex;
                        justify-content: left;
                        flex-direction: row;
                        align-items: center;
                        background-color: #FFFFFF;
                        border-top: 1px solid #E2E2E2;
                        // border-bottom: 1px solid #E2E2E2;
                        border-right:  none;
                        border-bottom: none;
                        border-left:   none;
                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .el-select-dropdown__empty {
        color: #DA4E4E;
        text-align: left;
        margin-left: 10px;
    }
}
.select-query-dialog {
    margin: 4% 4% 4% 4% !important;
    position: absolute;
    top: 4%;
    left: 4%;
    right: 4%;
    bottom: 0%;
    width: auto;
    height: auto;

    .el-dialog__body {
        height: 92%;
        width: 100%;
        padding: 15px 15px 0px;

        .popup-dialog-cont {
            height: 100%;
            width: 100%;
            display: inline-flex;
            flex-direction: column;

            .filter-input-cont {
                width: 100%;
                min-height: 45px;
                max-height: 45px;
                display: inline-flex;
                border: 1px solid #B2B2B2;
                border-radius: 4px;

                .search-box {
                    width: 100%;
                    height: 100%;
                    // border: 1px solid #B2B2B2;
                    // border-radius: 4px;
                    // padding: 0px 0px 0px 10px;
                    padding-left: 10px;
                    border: unset;
                    
                }
                .search-box:focus-visible {
                    border: unset;
                    outline: none;
                }
                .search-box::placeholder {
                    color: #999999
                }

                .close-icon {
                    border:1px solid transparent;
                    background-color: transparent;
                    display: inline-block;
                    vertical-align: middle;
                    outline: 0;
                    cursor: pointer;
                }
                .close-icon:after {
                    content: "X";
                    display: block;
                    width: 15px;
                    height: 15px;
                    // position: absolute;
                    background-color: #999999;
                    z-index:1;
                    // right: 35px;
                    // top: 0;
                    // bottom: 0;
                    margin: auto;
                    padding: 2px;
                    border-radius: 50%;
                    text-align: center;
                    color: white;
                    font-weight: normal;
                    font-size: 12px;
                    cursor: pointer;
                }
                .search-box:not(:valid) ~ .close-icon {
                    display: none;
                }
            }

            .other-input-enabled {
                max-height: 70% !important;
            }

            .list-cont {
                width: 100%;
                height: auto;
                padding: 0;
                overflow-y: scroll;

                .list-option {
                    line-height: 20px;
                    width: 100%;
                    min-height: 35px;
                    font-size: 15px;
                    text-align: left;
                    color: #333333;
                    word-break: break-word;
                    padding: 7px 10px;
                }

                .highlight-sel-val {
                    background-color: #FAF5FC;
                    color: #833E93;
                    font-weight: bold;
                }

                .no-match {
                    color: #DA4E4E;
                    line-height: 20px;
                    width: 100%;
                    min-height: 35px;
                    font-size: 15px;
                    text-align: left;
                    word-break: break-word;
                    padding: 7px 10px;
                }
            }
        }
    }
}
.valued-select-cont {
    border: 1px solid #833E93 !important;
}
.list-select-cont {
    min-height: 45px;
    border: 1px solid #B2B2B2;
    padding: 13px 7px;
    text-align: left;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    .list-value-cont {
        width: 93%;

        .default-value {
            color: #828282;
        }

        .selected-value {
            color: #333333;
        }
    }

    .down-arrow-icon {
        margin-left: 5px;

        svg {
            transform: rotate(90deg);
            vertical-align: middle;

            path {
                fill: #C4C4C4
            }
        }
    }
}

.other-option {
    height: 60px;

    // .other-input.el-input {
    //     width: 100%;
    //     height: 45px;

    //     .el-input__inner {
    //         background-color: #FAF5FC;
    //         border: 2px solid #833E93;
    //         font-weight: bold;
    //         color: #833E93;
    //         border-radius: 24px;
    //         text-align: center;
    //     }
    // }

    .other-input {
        width: 100%;
        height: 45px;
        background-color: #FAF5FC;
        border: 2px solid #833E93;
        font-weight: bold;
        color: #833E93;
        border-radius: 24px;
        text-align: center;
    }

    input.other-input:focus {
		outline: none;
	}

	button.add-button {
        height: 45px;
        color: #333333;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 24px;
        border: 1px solid #E2E2E2;
        margin-top: 4px;

        svg {
            margin-right: 5px;
        }
	}

	.character-count {
		float: right;
		color: #666666;
		font-size: 13px;
		margin-top: 7px;
        margin-right: 20px;
	}
}
</style>