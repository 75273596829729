<template>
<svg width="92" height="60" viewBox="0 0 92 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M91.7618 32.127C91.7618 26.593 92.4156 10.4408 87.7618 6.12695C79.6434 -1.39857 64.7346 0.126953 48.7618 0.126953C32.789 0.126953 14.3803 -1.39857 6.26184 6.12695C1.60806 10.4408 0.761841 26.593 0.761841 32.127C0.761841 47.3148 21.1329 59.627 46.2618 59.627C71.3908 59.627 91.7618 47.3148 91.7618 32.127Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'glutes_buttock',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
