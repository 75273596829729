<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15">
        <g fill="#333" fill-rule="evenodd">
            <path d="M9.646 2.706h-1.09c.042.13.07.265.07.409 0 .171-.034.333-.092.483h1.112c.18 0 .326.151.326.337v9.786a.332.332 0 0 1-.326.337H1.19a.332.332 0 0 1-.326-.337V3.935c0-.186.146-.337.326-.337h1.054a1.326 1.326 0 0 1-.092-.483c0-.144.027-.28.07-.41H1.19c-.656 0-1.19.552-1.19 1.23v9.786c0 .678.534 1.23 1.19 1.23h8.456c.656 0 1.19-.552 1.19-1.23V3.935c0-.678-.534-1.23-1.19-1.23"/>
            <path d="M3.407 2.265H7.37a.82.82 0 0 1 .717.44h.468c-.167-.513-.631-.887-1.185-.887H3.407c-.553 0-1.018.374-1.185.887h.468a.82.82 0 0 1 .717-.44M7.371 3.965H3.407a.816.816 0 0 1-.675-.367h-.488c.187.476.636.813 1.163.813H7.37c.527 0 .977-.337 1.163-.813h-.488a.816.816 0 0 1-.675.367"/>
            <path d="M6.93 2.53a.439.439 0 0 1-.43-.447C6.5 1.46 6.006.951 5.402.951c-.605 0-1.096.508-1.096 1.132a.44.44 0 0 1-.432.447.44.44 0 0 1-.432-.447c0-1.116.879-2.025 1.96-2.025 1.08 0 1.96.909 1.96 2.025a.439.439 0 0 1-.432.447M8.194 3.115a.86.86 0 0 1-.148.483h.487c.059-.15.093-.312.093-.483 0-.144-.028-.28-.07-.41h-.468a.86.86 0 0 1 .106.41M2.244 3.598h.487a.863.863 0 0 1-.148-.483c0-.149.041-.287.107-.41h-.469c-.041.13-.07.266-.07.41 0 .171.034.333.093.483M3.407 3.965H7.37a.816.816 0 0 0 .675-.367H2.732c.149.221.395.367.675.367"/>
            <path d="M7.371 2.265H3.407a.82.82 0 0 0-.717.44h5.398a.819.819 0 0 0-.717-.44M7.371 3.616H3.407c-.216 0-.391-.202-.391-.452s.175-.453.39-.453h3.965c.216 0 .392.204.392.453 0 .25-.176.452-.392.452m.823-.452c0-.167-.04-.322-.106-.459H2.69a1.06 1.06 0 0 0 .042 1h5.314c.093-.153.148-.34.148-.54M5.418 10.19a.44.44 0 0 1-.432-.447V6.345c0-.247.193-.447.432-.447.238 0 .432.2.432.447v3.398a.44.44 0 0 1-.432.447"/>
            <path d="M6.888 8.49h-2.94a.439.439 0 0 1-.432-.446c0-.246.193-.446.432-.446h2.94c.239 0 .432.2.432.446a.439.439 0 0 1-.432.446M8.33 12.367H4.53a.44.44 0 0 1-.432-.447.44.44 0 0 1 .432-.446h3.798c.239 0 .432.2.432.446a.44.44 0 0 1-.432.447M3.147 12.367h-.564a.44.44 0 0 1-.432-.447.44.44 0 0 1 .432-.446h.564c.239 0 .431.2.431.446a.439.439 0 0 1-.431.447"/>
        </g>
    </svg>
</template>
<script>
export default { name: 'icon-health-data' }
</script>
