<template>
<svg width="62" height="94" viewBox="0 0 62 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3387 20.0761C24.5151 12.7729 31.3708 0.467773 31.3708 0.467773C31.3708 0.467773 36.9546 10.9159 43.227 14.148C49.3743 17.3156 61.0113 15.516 61.0113 15.516C61.0113 15.516 57.5372 27.3845 55.3112 33.5283C53.0852 39.6721 52.7146 42.3742 50.0671 48.3485C43.3635 63.476 33.8393 69.4081 25.8987 84.373C24.0323 87.8906 21.3387 93.4932 21.3387 93.4932C21.3387 93.4932 16.1095 89.4744 12.2185 88.0211C7.99486 86.4435 0.818359 86.1971 0.818359 86.1971C0.818359 86.1971 4.61003 79.0561 6.74645 74.3409C15.8031 54.3524 12.5861 40.1996 21.3387 20.0761Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_forearm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
