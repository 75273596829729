<template>
<svg width="64" height="62" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M57.5121 28.1201C47.8965 46.7856 43.927 61.4917 32.0565 61.4917C20.1859 61.4917 16.8137 42.5586 6.60554 28.1201C-4.59503 12.2828 -4.14441 0.404785 32.0561 0.404785C63.1644 0.404785 69.1673 5.49536 57.5121 28.1201Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'groin',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
