import { v4 as uuidv4 } from 'uuid'

const SINGLE_SELECT = ['radio', 'star', 'binary']
const TOGGLE_SELECT = ['large_image_select', 'box_select', 'small_image_select', 'select', 'long_text_bubble']

export default {
    props: {
        question: {
            type: Object
        },
        saved: {
            type: Array
        }
    },

    data() {
        return {
            selected: this.saved || []
        }
    },

    methods: {
        select(choice) {
            if (!this.selected.includes(choice)) {
                if (this.isSingleSelect && this.selected.length > 0)
                    this.selected = []
                this.selected.push(choice)
            }
            else if (this.isToggleSelect)
                this.selected.splice(this.selected.indexOf(choice), 1)
            this.$emit('selected', this.selected)
        },

        isSelected(choice) {
            return this.selected.includes(choice)
        }
    },

    computed: {
        isSingleSelect() {
            return this.question.single ||
                SINGLE_SELECT.includes(this.question.type)
        },
        isToggleSelect() {
            return this.question.type.indexOf('long_text_bubble') >= 0 || this.question.type.indexOf('select') >= 0
        },

        max() {
            return this.question.max
        }
    }
}
