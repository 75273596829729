<template>
    
    <svg width="52" height="128" viewBox="0 0 52 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.3372 76.3936C21.0285 86.7395 27.0387 96.2997 30.8193 104.451C38.8237 121.71 43.7281 128.232 47.3193 126.951C57.8193 125.451 47.3193 106.951 38.8193 73.4512C37.9508 71.0169 37.8264 61.7079 36.485 55.9512C34.807 48.7502 29.5773 41.0221 28.8193 37.9512C25.2554 23.5122 26.1273 24.6184 24.3193 14.9506C23.3193 6.95066 21.3194 0.167402 8.31933 0.167402C-2.18073 0.167567 0.0692097 16.4933 0.819333 23.4512C2.05016 34.8679 10.1879 56.3553 17.3372 76.3936Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>