<template>
    
    <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M11 10.0137C11 15.5339 10.2178 20.1842 5.52406 20.0089C1.14336 20.0089 0.0481173 15.5339 0.0481173 10.0137C0.0481173 4.49348 -0.890568 -0.156888 5.52406 0.0184753C10.3742 0.0191343 11 4.49348 11 10.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>