<template>
    <svg width="65" height="72" viewBox="0 0 65 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.515625 56.6161V46.6542C0.515625 45.8173 0.515625 45.3989 0.577306 44.9373C0.638987 44.4758 0.73611 44.1189 0.930355 43.4051L0.930364 43.4051C2.05077 39.2879 5.37003 28.2913 10.0156 23.5C16.3047 17.0136 56.2263 2.03579 58.8631 1.05104C58.9586 1.01538 59.0491 0.985984 59.1471 0.957984C62.677 -0.0505809 65.546 3.89227 63.5003 6.94074L44 36L29.431 63.666C27.065 68.1588 25.882 70.4053 23.7634 71.4036C21.6448 72.4019 19.1592 71.8841 14.1882 70.8485L11.6603 70.3218C6.32237 69.2097 3.65342 68.6537 2.08452 66.7243C0.515625 64.7948 0.515625 62.0686 0.515625 56.6161Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_shoulder_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>