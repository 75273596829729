<template>
    
    <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 10.0137C0 15.5339 0.782226 20.1842 5.47594 20.0089C9.85664 20.0089 10.9519 15.5339 10.9519 10.0137C10.9519 4.49348 11.8906 -0.156888 5.47594 0.0184753C0.625766 0.0191343 0 4.49348 0 10.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>