<template>
	<TopicTemplate 
		:name="name" 
		:topicImage="topicImage" 
		:title="title"
		:misc="misc"
		:description="getDescriptionText"
		:appointment="appointment"
		:summary="summary"
		:content="content"
		:result="result"
		:selected="selected">
	</TopicTemplate>
</template>
<script>

import AppointmentView from '../../mixin/appointment-view'
import TopicTemplate from './template/TopicTemplate'

export default {
	name: 'appointment_cause_risk_question',
	mixins: [ AppointmentView ],
	data() {
		return {
			name: 'cause_risk_question',
			topicImage: 'cause_risk.png',
			title: 'Cause / Risk Factors',
			misc: true
		}
	},
	mounted() {
	},
	computed: {
        getDescriptionText() {
            return `<strong>This section relates to topics you may want to discuss about what causes your condition, how your risk is impacted, etc.</strong>`
        }
	},
	components: {
		TopicTemplate
	}
}
</script>

<style lang="scss" scoped>
</style>