<template>
<svg width="54" height="146" viewBox="0 0 54 146" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M53.601 13.8342C53.6037 7.25271 48.7582 1.72234 42.2139 1.02447C38.0779 0.583418 33.5385 0.211594 29.9622 0.207528C24.9066 0.20178 15.8552 3.20003 9.08149 5.74658C3.77107 7.74303 0.531563 13.0159 0.838417 18.6809L6.98551 132.165C7.37558 139.367 13.3286 145.006 20.5405 145.006H28.3753C35.2761 145.006 41.0781 139.849 41.8873 132.996C43.9719 115.342 47.9345 82.6749 50.6322 65.9233C53.162 50.2147 53.5955 27.3983 53.601 13.8342Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_front_thigh',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
