<template>
	<section>
		<router-link class="header" to="/questionnaire">
			<QuestionnaireIcon class="icon"></QuestionnaireIcon>
			<span style="color:#833e93">QUESTIONNAIRES</span>
			<ArrowIcon class="arrow"></ArrowIcon>
		</router-link>

		<div class="item">
			<!-- <router-link to="/questionnaire/test">
				<QuestionnaireArrowIcon class="icon"></QuestionnaireArrowIcon>
				<h5>Quick follow up</h5>
				<p>8 questions&nbsp;&nbsp;&middot;&nbsp;&nbsp;3 minutes</p>
			</router-link>
			<router-link to="/questionnaire/test">
				<QuestionnaireArrowIcon class="icon"></QuestionnaireArrowIcon>
				<h5>Quick follow up</h5>
				<p>8 questions&nbsp;&nbsp;&middot;&nbsp;&nbsp;3 minutes</p>
			</router-link> -->
		</div>
	</section>
</template>

<script>
import Icons from './icon/components'

export default {
	name: 'home-questionnaire-section',

	components: {
		...Icons
	}
}
</script>

<style lang="scss" scoped>
@import "../section.scss";
@import "questionnaire-section.scss";
</style>
