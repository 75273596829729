<template>
    
    <svg width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M27.4998 7C31.3941 12.9073 36.592 15.2394 35.4998 15C26.4998 13.027 25.5852 13.2745 17.4998 12.973C13.3853 12.8196 7.81734 12.8971 5.31425 12.9448C4.65295 12.9574 4.14956 12.3357 4.24456 11.6811C4.40854 10.5514 4.51174 8.9473 3.99982 8C3.13654 6.4025 1.82365 4.88019 0.939796 3.94398C0.482105 3.45917 0.58078 2.67195 1.20211 2.43014C4.22984 1.25182 9.67862 0.499138 12.4998 0.5C15.4296 0.500897 20.7875 1.71677 21.8237 1.95848C21.9417 1.986 22.049 2.03341 22.1482 2.10295C22.8856 2.61984 26.1721 4.98595 27.4998 7Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_foot_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>