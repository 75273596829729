<template>
<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.07316 3.94642L16.9611 0.125262C16.9611 0.125262 23.666 0.959455 27.7622 2.7608C31.8699 4.56725 36.6793 8.82114 36.6793 8.82114L36.5909 22.8467L30.9543 39.6958L0.185382 26.5132L2.61211 17.957L5.07316 3.94642Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_hand_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
