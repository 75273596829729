import ArticleSection from './article/ArticleSection'
import ExerciseSection from './exercise/ExerciseSection'
import FitbitSection from './fitbit/FitbitSection'
import QuestionnaireSection from './questionnaire/QuestionnaireSection'
import SettingsSection from './setting/SettingsSection'

export default {
    ArticleSection,
    ExerciseSection,
    FitbitSection,
    QuestionnaireSection,
    SettingsSection
}
