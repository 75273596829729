<template>
<svg width="49" height="55" viewBox="0 0 49 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4613 35.5769C18.5363 30.4582 18.0086 26.2661 14.6211 21.441C9.88907 14.7006 0.0288467 6.72203 0.0288467 6.72203L4.58895 0.920643C4.58895 0.920643 12.1835 0.431075 16.9011 1.37665C25.6525 3.13071 30.5865 6.07797 36.9654 12.3208C42.9024 18.1312 47.4535 27.369 47.9096 30.5611C48.3657 33.7531 47.9096 54.2734 47.9096 54.2734C47.9096 54.2734 25.1093 41.961 21.4613 35.5769Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_shoulder_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
