<template>
	<el-row>
		<el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 8, offset: 8 }">
			<div class="settings">

				<img class="error" src="@/image/error.png">

				<p class="content">
					In order to receive video calls from your doctor, you must Allow Notifications and Camera access in DoctorPlan app.
					Please open the email on the device that has DoctorPlan app installed.
				</p>

				<hr class="divider">

				<img class="logo" src="@/image/DoctorPlan_logo_blue.png">

			</div>
		</el-col>
	</el-row>
</template>
<script>

export default {
	name: 'settings',
	props: {
	},
	methods: {
	}
}
</script>
<style lang="scss">
.settings {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 80vh;
	padding: 0 10px;

	.error {
		width: 60px;
	}

	.content {
		font-size: 17px;
		font-weight: 500;
		color: #333333;
		text-align: center;
		line-height: 22px;
		padding: 40px 0 30px 0;
	}

	.divider {
		border-top: 1px solid #cccccc;
		width: 80%;
	}

	.logo {
		padding-top: 10px;
		width: 150px;
	}
}
</style>
