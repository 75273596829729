<template>
	<section>
		<a class="header">
			<!-- <FitbitIcon class="icon"></FitbitIcon> -->
			<span style="color:#2D3E4F">SETTINGS</span>
		</a>

		<div class="item">
			<router-link :to="'/setting'">
				<SettingArrowIcon class="icon"></SettingArrowIcon>
				<h5>Profile and Settings</h5>
				<p>View your profile and settings information</p>
			</router-link>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
import Icons from './icon/components'

export default {
	name: 'home-setting-section',

	computed: {
	},

	components: {
		...Icons
	}
}
</script>

<style lang="scss" scoped>
@import "../section.scss";
@import "style.scss";
</style>
