<template>
    <div class="topic-summary">
        <span class="summary">Summary: {{ formatDate(this.summary.start) }} - {{ formatDate(this.summary.end) }}</span>
        <div
            v-bind:style="{ border: '2px solid' + this.color }" class="box">
            <div
                v-if="type === 'activity'">
                    <ActivityBox
                        :topics="conditions">
                    </ActivityBox>
            </div>
            <div
                v-else-if="type === 'lifestyle'">
                    <LifestyleBox
                        :topics="conditions">
                    </LifestyleBox>
            </div>
            <div
                v-else
                v-for="entry in count"
                :key="entry">
                    <MedicationBox 
                        v-if="type === 'medication'"
                        :attributes="entry" >
                    </MedicationBox>
                    <Box v-else
                        :attributes="entry">
                    </Box>
            </div>
        </div>
    </div>
</template>

<script>
import formatDate from '@/module/util/format-date'
import Box from '../box/Box'
import MedicationBox from '../box/MedicationBox'
import ActivityBox from '../box/ActivityBox'
import LifestyleBox from '../box/LifestyleBox'

export default {

	props: {
        attributes: {
            type: Object
        },
        summary: {
            type: Object
        },
        color: {
            type: String
        },
        count: {
            type: Array
        },
        type: {
            type: String
        },
        conditions: {
            type: Object
        }
	},

    data() {
        return {
            topic: null
        }
    },
    mounted() {
        this.setTopic()
    },
	methods: {
        formatDate,
        setTopic() {
            this.topic = this.type
        }
    },
    computed: {

    },
	components: {
        Box,
        MedicationBox,
        ActivityBox,
        LifestyleBox
	}
}
</script>

<style lang="scss">
//@import "style.scss";
.summary {
    padding-left: 10%;
    z-index: 1;
    background-color: white;
}
.box {
    border: 1px solid blue;
    box-sizing: border-box;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}
@media only screen and (min-width: 600px) {
    .summary {
        font-size: 18pt;
        margin-top: 3%;
        margin-left: 2.5%;
    }
}
</style>
