<template>
    
    <svg width="19" height="64" viewBox="0 0 19 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.254321 58.8128C1.2919 52.0181 3.02936 39.236 3 30C2.9753 22.2311 1.68433 11.8809 0.67751 4.96882C0.214308 1.78879 3.4674 -0.705225 6.39532 0.619312L16.2119 5.06014C18.4592 6.07677 19.2785 8.87844 18.0451 11.0145C15.3406 15.6983 11.2702 23.615 10.5 30C9.43619 38.8195 13.9903 50.3445 17.302 57.3204C18.626 60.1093 16.623 63.5 13.5358 63.5H4.25646C1.77303 63.5 -0.12056 61.2678 0.254321 58.8128Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>