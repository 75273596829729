<template>
	<div class="image">
		<img :src="src">
	</div>
</template>
<script>
import Markdown from '.'

export default {
	name: 'markdown-image',
	extends: Markdown,

	computed: {
		src() {
			return this.text.replace(/\!\[\]\((.*)\)/g, '$1')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../style/markdown.scss";
img {
	display: block;
	width: 80%;
	margin-top: 30px;
	margin-bottom: 30px;
}
</style>
