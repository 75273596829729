import head_f from './head_f'
import neck_f from './neck_f'
import upper_abdomen from './upper_abdomen'
import lower_abdomen from  './lower_abdomen'
import right_foot_f from  './right_foot_f'
import left_foot_f from  './left_foot_f'
import right_lower_leg_f from  './right_lower_leg_f'
import right_thigh_f from  './right_thigh_f'
import right_fingers_f from  './right_fingers_f'
import right_hand_f from  './right_hand_f'
import left_hand_f from  './left_hand_f'
import left_fingers_f from  './left_fingers_f'
import left_shoulder_f from  './left_shoulder_f'
import right_shoulder_f from  './right_shoulder_f'
import right_chest from  './right_chest'
import left_chest from  './left_chest'
import right_upper_arm_f from  './right_upper_arm_f'
import left_upper_arm_f from  './left_upper_arm_f'
import left_flank_f from  './left_flank_f'
import right_flank_f from  './right_flank_f'
import left_forearm_f from  './left_forearm_f'
import right_forearm_f from  './right_forearm_f'
import right_pelvic from  './right_pelvic'
import left_pelvic from  './left_pelvic'
import left_knee_f from  './left_knee_f'
import right_knee_f from  './right_knee_f'
import left_lower_leg_f from  './left_lower_leg_f'
import left_thigh_f from  './left_thigh_f'

import left_lower_leg_b from  './left_lower_leg_b'
import right_lower_leg_b from  './right_lower_leg_b'
import left_thigh_b from  './left_thigh_b'
import right_thigh_b from  './right_thigh_b'
import head_b from  './head_b'
import right_neck_b from  './right_neck_b'
import left_neck_b from  './left_neck_b'
import right_buttock from  './right_buttock'
import left_buttock from  './left_buttock'
import left_flank_b from  './left_flank_b'
import right_flank_b from  './right_flank_b'
import right_lower_back from  './right_lower_back'
import left_lower_back from  './left_lower_back'
import left_knee_b from  './left_knee_b'
import right_knee_b from  './right_knee_b'
import right_foot_b from  './right_foot_b'
import left_foot_b from  './left_foot_b'
import right_upper_back from  './right_upper_back'
import left_upper_back from  './left_upper_back'
import left_forearm_b from  './left_forearm_b'
import right_forearm_b from  './right_forearm_b'
import left_fingers_b from  './left_fingers_b'
import right_fingers_b from  './right_fingers_b'
import right_hand_b from  './right_hand_b'
import left_hand_b from  './left_hand_b'
import left_shoulder_b from  './left_shoulder_b'
import right_shoulder_b from  './right_shoulder_b'
import right_upper_arm_b from  './right_upper_arm_b'
import left_upper_arm_b from  './left_upper_arm_b'
import right_elbow from  './right_elbow'
import left_elbow from  './left_elbow'
import left_mid_back from  './left_mid_back'
import right_mid_back from  './right_mid_back'

export default {
    head_f,
    neck_f,
    upper_abdomen,
    lower_abdomen,
    right_foot_f,
    left_foot_f,
    right_lower_leg_f,
    right_thigh_f,
    right_fingers_f,
    right_hand_f,
    left_hand_f,
    left_fingers_f,
    left_shoulder_f,
    right_shoulder_f,
    right_chest,
    left_chest,
    right_upper_arm_f,
    left_upper_arm_f,
    left_flank_f,
    right_flank_f,
    left_forearm_f,
    right_forearm_f,
    right_pelvic,
    left_pelvic,
    left_knee_f,
    right_knee_f,
    left_lower_leg_f,
    left_thigh_f,


    left_lower_leg_b,
    right_lower_leg_b,
    left_thigh_b,
    right_thigh_b,
    head_b,
    right_neck_b,
    left_neck_b,
    right_buttock,
    left_buttock,
    left_flank_b,
    right_flank_b,
    right_lower_back,
    left_lower_back,
    left_knee_b,
    right_knee_b,
    right_foot_b,
    left_foot_b,
    right_upper_back,
    left_upper_back,
    left_forearm_b,
    right_forearm_b,
    left_fingers_b,
    right_fingers_b,
    right_hand_b,
    left_hand_b,
    left_shoulder_b,
    right_shoulder_b,
    right_upper_arm_b,
    left_upper_arm_b,
    right_elbow,
    left_elbow,
    left_mid_back,
    right_mid_back
}