import Vue from 'vue'
import 'regenerator-runtime';

import App from './module/app/App.vue'
import router from './module/app/router'
import store from './store'
import './registerServiceWorker'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://406c5e21438f433a957cb6b6f0c6dc91@o4504136566046720.ingest.sentry.io/4504136575680512",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["dev.my.doctorplan.com", "my.doctorplan.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.35,
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
