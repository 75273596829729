<template>
    <svg width="109" height="28" viewBox="0 0 109 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M53 27.5C64.4786 27.5449 77.6828 25.987 89.4474 24.0883C100.779 22.2595 106.445 21.345 108.007 17.9635C108.536 16.8198 108.754 15.4171 108.599 14.1668C108.141 10.4702 102.883 7.80317 92.3676 2.46909C90.2795 1.40991 89.2355 0.880319 88.1775 0.703786C87.7563 0.633497 87.3996 0.6053 86.9725 0.608526C85.9 0.61663 84.7126 0.999149 82.3377 1.76419C75.3062 4.02935 62.5061 7.65362 53 7.5C44.4136 7.36124 32.9926 4.05096 26.3735 1.87508C23.9037 1.06318 22.6687 0.657228 21.55 0.656526C21.1118 0.656251 20.7446 0.68871 20.3132 0.765852C19.2119 0.962811 18.1352 1.54257 15.9818 2.7021C6.14103 8.00099 1.22063 10.6504 0.821011 14.3089C0.68821 15.5246 0.913566 16.9001 1.42739 18.0099C2.97356 21.3495 8.31357 22.2615 18.9936 24.0854C29.8063 25.932 42.0136 27.457 53 27.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'collarbone_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>

