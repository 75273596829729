<template>
    
    <svg width="15" height="37" viewBox="0 0 15 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 18.0137C0 27.95 1.06667 36.3207 7.46719 36.005C13.4409 36.005 14.9344 27.95 14.9344 18.0137C14.9344 8.07732 16.2144 -0.293336 7.46719 0.022318C0.853317 0.0235043 0 8.07732 0 18.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>