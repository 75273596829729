<template>
    
    <svg width="33" height="67" viewBox="0 0 33 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M32 33.5C32 52.0015 36 67 17 67C0.5 67 0.5 52.0015 0.5 33.5C0.5 14.9985 1.5 0 17 0C32.5 0 32 14.9985 32 33.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>