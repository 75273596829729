<template>
<svg xmlns="http://www.w3.org/2000/svg" width="140" height="132" viewBox="0 0 140 132">
    <defs>
        <linearGradient id="a" x1="100%" x2="0%" y1="6.129%" y2="93.871%">
            <stop offset="0%" stop-color="#2C6CA3"/>
            <stop offset="100%" stop-color="#2D3E4F"/>
        </linearGradient>
        <linearGradient id="b" x1="100%" x2="0%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#2C6CA3"/>
            <stop offset="100%" stop-color="#2D3E4F"/>
        </linearGradient>
        <linearGradient id="c" x1="60.331%" x2="39.669%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#2C6CA3"/>
            <stop offset="100%" stop-color="#2D3E4F"/>
        </linearGradient>
        <linearGradient id="d" x1="50.781%" x2="49.219%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#2C6CA3"/>
            <stop offset="100%" stop-color="#2D3E4F"/>
        </linearGradient>
        <linearGradient id="e" x1="63.85%" x2="36.15%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#2C6CA3"/>
            <stop offset="100%" stop-color="#2D3E4F"/>
        </linearGradient>
        <linearGradient id="f" x1="82%" x2="18%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#2C6CA3"/>
            <stop offset="100%" stop-color="#2D3E4F"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(1)">
        <circle cx="133" cy="5" r="3" fill="#FFF" stroke="#2D3E4F"/>
        <circle cx="3" cy="32" r="3" fill="#FFF" stroke="#2D3E4F"/>
        <circle cx="11.5" cy="103.5" r="1.5" fill="#2D3E4F"/>
        <circle cx="137.5" cy="90.5" r="1.5" fill="#2D3E4F"/>
        <circle cx="67.5" cy="1.5" r="1.5" fill="#2D3E4F"/>
        <g transform="translate(45 18)">
            <rect width="74.241" height="78.94" x="3.759" y="9.398" fill="#F3F9FF" rx="14"/>
            <path stroke="url(#a)" stroke-width="1.5" d="M10 11.277h54.241c5.523 0 10 4.477 10 10V66.82c0 7.732-6.268 14-14 14H14c-7.732 0-14-6.268-14-14V21.277c0-5.523 4.477-10 10-10z"/>
            <path stroke="url(#a)" stroke-width="1.5" d="M10 15.036h54.241c5.523 0 10 4.477 10 10v45.542c0 7.732-6.268 14-14 14H14c-7.732 0-14-6.268-14-14V25.036c0-5.523 4.477-10 10-10z"/>
            <path fill="#CDE6FF" d="M0 25.373v-4.698C0 13.408 2.995 7.518 10.082 7.518H64.16c7.087 0 10.082 5.89 10.082 13.157v4.698"/>
            <path stroke="url(#a)" stroke-width="1.5" d="M10 7.518h54.241c5.523 0 10 4.477 10 10V63.06c0 7.732-6.268 14-14 14H14c-7.732 0-14-6.268-14-14V17.518c0-5.523 4.477-10 10-10z"/>
            <path stroke="#D4E0EC" stroke-linecap="round" stroke-width=".75" d="M36.324 38.854l-1.615-15.362M39.523 69.29L37.908 53.93M59.17 44.456l-15.016 1.578M30.695 47.448l-15.016 1.579"/>
            <path stroke="#D4E0EC" stroke-linecap="round" d="M31.17 42.217l-1.344-1.057"/>
            <path stroke="#D4E0EC" stroke-dasharray="1.478,2.216" stroke-linecap="round" d="M26.252 38.64l-3.744-3.057"/>
            <path stroke="#D4E0EC" stroke-linecap="round" d="M20.61 33.364l-1.342-1.059M54.655 61.218l-1.343-1.056"/>
            <path stroke="#D4E0EC" stroke-dasharray="1.478,2.216" stroke-linecap="round" d="M49.885 56.797L46.14 53.74"/>
            <path stroke="#D4E0EC" stroke-linecap="round" d="M44.933 52.514l-1.341-1.056M51.27 28.95l-1.045 1.352"/>
            <path stroke="#D4E0EC" stroke-dasharray="1.478,2.216" stroke-linecap="round" d="M46.894 33.751l-3.027 3.77"/>
            <path stroke="#D4E0EC" stroke-linecap="round" d="M42.36 40.425l-1.047 1.35M33.301 52.744l-1.047 1.35"/>
            <path stroke="#D4E0EC" stroke-dasharray="1.478,2.216" stroke-linecap="round" d="M29.761 57.694l-3.026 3.769"/>
            <path stroke="#D4E0EC" stroke-linecap="round" d="M25.52 62.677l-1.045 1.352"/>
            <path fill="#2D3E4F" fill-rule="nonzero" d="M34.723 52.368L33.18 32.055l5.602-.196-.077 20.37-3.983.139zm-.828 6.407c-.059-1.67 1.25-3.123 2.913-3.18 1.663-.059 3.113 1.298 3.17 2.968.058 1.625-1.294 3.08-2.957 3.138-1.663.058-3.07-1.3-3.126-2.926z"/>
            <circle cx="19.265" cy="11.478" r="4.229" fill="url(#b)"/>
            <rect width="4.541" height="11.791" x="16.994" y=".75" fill="#E7F3FF" stroke="url(#c)" stroke-width="1.5" rx="2.271"/>
            <circle cx="54.976" cy="11.478" r="4.229" fill="url(#b)"/>
            <rect width="4.541" height="11.791" x="52.705" y=".75" fill="#E7F3FF" stroke="url(#c)" stroke-width="1.5" rx="2.271"/>
        </g>
        <ellipse cx="77" cy="127.5" fill="#EEE" fill-rule="nonzero" rx="56" ry="4.5"/>
        <g>
            <path fill="#F3F9FF" d="M22.615 85.692H30V26.615h-7.385z"/>
            <path stroke="url(#d)" stroke-width="1.5" d="M1.846 63.692h7.385V4.615H1.846z" transform="translate(18 22)"/>
            <path fill="#2D3E4F" d="M22.615 25.692h2.77V22h-2.77z"/>
            <path stroke="url(#e)" stroke-width="1.5" d="M0 24.923V7.471h.158l9.073-.086" transform="translate(18 22)"/>
            <path stroke="url(#f)" stroke-width="1.5" d="M1.846 64.615h7.385L6.43 73.846H4.647z" transform="translate(18 22)"/>
        </g>
    </g>
</svg>



</template>
<script>
export default {
	name: 'questionnaire-none-icon',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
// @import "./style.scss";
    svg {
        padding-bottom:20px;
    }
</style>
