<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <g fill="#333" fill-rule="evenodd">
            <path d="M3.572.968c-1.025 0-1.859.807-1.859 1.8S2.547 4.57 3.572 4.57c1.024 0 1.858-.808 1.858-1.8 0-.994-.834-1.801-1.858-1.801m0 4.569C1.996 5.537.713 4.295.713 2.769.713 1.242 1.996 0 3.572 0 5.147 0 6.43 1.242 6.43 2.769c0 1.526-1.283 2.768-2.858 2.768M14.321.968H9.227a.492.492 0 0 1-.5-.484c0-.267.223-.484.5-.484h5.094c.277 0 .5.217.5.484a.492.492 0 0 1-.5.484M14.321 14.797H9.227a.492.492 0 0 1-.5-.483c0-.267.223-.484.5-.484h5.094c.277 0 .5.217.5.484a.492.492 0 0 1-.5.483"/>
            <path d="M11.774 14.797a.492.492 0 0 1-.5-.483V.838c0-.267.224-.484.5-.484.277 0 .5.217.5.484v13.476a.492.492 0 0 1-.5.483M1.714 13.723H5.43v-2.93c0-.383.322-.695.718-.695h.006v-2c-.037-.225-.451-2.561-2.552-2.561h-.026C1.506 5.555 1.06 7.309 1 7.606v2.456a.707.707 0 0 1 .714.695v2.966zm3.999.968H1.43a.707.707 0 0 1-.718-.695V11.03A.708.708 0 0 1 0 10.335l.012-2.88c.197-1.06 1.169-2.865 3.555-2.886h.035c2.389 0 3.352 2.153 3.544 3.432l.008.098v2.272a.707.707 0 0 1-.718.695H6.43v2.93a.707.707 0 0 1-.717.695z"/>
        </g>
    </svg>
</template>
<script>
export default { name: 'icon-height' }
</script>
