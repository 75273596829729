<template>
	<TopicTemplate 
		:name="name" 
		:topicImage="topicImage" 
		:title="title"
		:type="type"
		:conditions="getConditions"
		:appointment="appointment"
		:summary="summary"
		:content="content"
		:result="result"
		:selected="selected">
	</TopicTemplate>
</template>
<script>

import AppointmentView from '../../mixin/appointment-view'
import TopicTemplate from './template/TopicTemplate'

export default {
	name: 'appointment-lifestyle',
	data() {
		return {
			name: 'lifestyle_question',
			topicImage: 'lifestyle.png',
			type: 'lifestyle',
			title: 'Lifestyle'
		}
	},
	mixins: [ AppointmentView ],
	mounted() {
	},
	computed: {
        getConditions() {
			if (this.summary) {
				var conditions = {}
				if (this.summary.food)
					conditions.food = this.summary.food
				if (this.summary.water)
					conditions.water = this.summary.water
				if (this.summary.excretion)
					conditions.excretion = this.summary.excretion
				return conditions
			}
        }
	},
	components: {
		TopicTemplate
	}
}
</script>

<style lang="scss" scoped>
</style>