<template>
	<el-row class="select-container">
		<el-col :span="12" v-for="(option, index) in question.option" :key="option + index"
				class="option" @click.native="select(option)">
			<span :class="[{ selected: isSelected(option) }]">{{ option }}</span>
		</el-col>
		<el-col :span="24" v-show="question.other" class="option-other">
			<input ref="other" v-model="other" placeholder="Other" v-on:keyup.13="selectOther">
			<button :class="[other.trim().length ? 'btn-active' : '']" @click="selectOther">ADD</button>
			<span class="character-count" :style="charactersLeft <= 0 ? 'color: #da4e4e' : ''">
				{{ charactersLeft }} character{{ charactersLeft > 1 ? 's' : '' }}
			</span>
		</el-col>
	</el-row>
</template>


<script>
import Question from './index'

export default {
	name: 'symptom-select',
	extends: Question,

	data() {
		return {
			other: '',
			defaultLimit: 100
		}
	},
	watch: {
		other(value) {
			if (value.length > this.defaultLimit)
				this.other = this.other.substr(0, this.defaultLimit)
		}
	},
	mounted() {
		if (this.saved) {
			this.$emit('selected', this.saved)
			this.saved.forEach((selected) => {
				if (! this.question.option.includes(selected))
					this.question.option.push(selected)
			})
		}
	},

	methods: {
		selectOther() {
			this.other = this.other.trim()
			if (this.other !== '') {
				if (this.question.option.filter(option => option === this.other).length)
					return
				this.select(this.other)
				this.question.option.push(this.other)
			}
			this.other = ''
		}
	},

	computed: {
		charactersLeft() {
			return this.defaultLimit - this.other.length
		}
	}
}
</script>

<style lang="scss" scoped>
$choice-size: 50px;
$choice-font-size: 14px;

@import "style.scss";

$background: #eeeeee;
$choice-border: darken($background, 5%);
$background-hover: lighten($background, 5%);

.select-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
}
.option {
	flex-basis: 49%;
	margin-bottom: 10px;
	padding: 7px;
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #eeeeee;
		height: auto;
		min-height: 45px;
		font-size: $choice-font-size;
		border-radius: 30px;
		width: 95%;
		padding: 10px;
		cursor: pointer;
		white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
		white-space: -webkit-pre-wrap;          /* Chrome & Safari */
		white-space: -pre-wrap;                 /* Opera 4-6 */
		white-space: -o-pre-wrap;               /* Opera 7 */
		white-space: pre-wrap;                  /* CSS3 */
		word-wrap: break-word;                  /* Internet Explorer 5.5+ */
		word-break: break-word;
		white-space: normal;
	}
	.selected {
		background: $selected-background;
		border: 2px solid $selected-color;
		color: $selected-color;
		font-weight: 700;
	}
}
.option-other {
	input {
		width: 80%;
		float: left;
		border: 1px solid #999999;
		outline: none;
		height: 40px;
		line-height: 40px;
		font-size: $choice-font-size;
		padding-left: 10px;
	}
	input:focus {
		outline: none;
	}
	.btn-active {
		background-color: $selected-color;
		color: #ffffff;
	}
	button {
		background-color: #ffffff;
		width: 20%;
		height: 40px;
		line-height: 40px;
		border: 1px solid #999999;
		border-left: none;
		font-size: $choice-font-size;
		color: #999999;
		padding: 0;
	}
	button:focus {
		outline:0;
	}
	.character-count {
		float: right;
		color: #666666;
		font-size: 13px;
		margin-top: 7px;
	}
}
.select {
	position: relative;
	display: inline-block;
	margin-bottom: 40px;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: center;
}

.choice-other {
	vertical-align: top;
	position: relative;
	display: inline-block;
	background: $background;
	width: auto;
	height: auto;
	font-size: $choice-font-size;
	text-align: center;
	//margin-right: 15px;
	border: 2px solid $choice-border;


	width: auto;
	height: auto;
	padding: 2.5px 2.5px;
	//padding: 0px 0px 0px 0px;
	border-radius: 10px;
	input {
		background: none;
		border: none;
		height: 29;//height: 30px;
		//line-height: 30px;
		font-size: 20px;
		width: auto;//100px;
		height: auto;
	}

	input:focus {
		outline: none;
	}
}
</style>
