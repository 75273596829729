<template>
    <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M20.0107 25.9997C22.3749 25.9882 25.4105 25.6537 28.4666 25.1994C33.58 24.4392 36.1368 24.0591 37.7975 22.3105C38.4759 21.5963 39.0595 20.6214 39.3686 19.6861C40.1252 17.3963 39.1994 14.8151 37.3476 9.65272C36.703 7.85544 36.3806 6.9568 35.7497 6.11446C35.5035 5.78568 35.1202 5.36759 34.8141 5.09372C34.0297 4.39204 33.2861 4.04978 31.7988 3.36526C28.4932 1.84385 23.7618 0.0229663 20.0107 0.00020223C16.2915 -0.0223691 11.5989 1.8489 8.36295 3.39504C6.93662 4.07654 6.22345 4.41729 5.45908 5.11397C5.19285 5.35663 4.84906 5.73007 4.62922 6.01543C3.99804 6.83471 3.66803 7.70337 3.00801 9.44069C0.984462 14.7672 -0.0273135 17.4304 0.777395 19.8102C1.07055 20.6772 1.61317 21.5992 2.22875 22.2764C3.91854 24.1354 6.56059 24.5182 11.8447 25.2838C14.7866 25.7101 17.6985 26.011 20.0107 25.9997Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>