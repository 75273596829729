<template>
<svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M30.4251 14.1418C30.4251 17.0419 30.5774 20.2033 28.8254 22.0464C26.48 24.5137 19.2728 22.0464 15.2268 22.0464C11.6161 22.0464 5.95492 24.4528 3.62804 22.4285C1.39967 20.49 0.428467 17.0523 0.428467 14.1418C0.428467 10.7398 1.90202 5.75832 4.82798 3.78405C7.0191 2.30562 12.2001 0 15.2268 0C18.2535 0 23.4346 2.30489 25.6257 3.78332C28.5517 5.75759 30.4251 10.7398 30.4251 14.1418Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
