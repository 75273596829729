<template>
    
    <svg width="47" height="177" viewBox="0 0 47 177" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.43058 95C18.4307 121.5 1.77936 149.57 5.93058 165C8.39449 174.158 17.242 176.5 20.4305 176.5C23.619 176.5 31.4666 169.659 33.9306 160.5C38.0818 145.07 33.9306 116.306 33.9306 86.5C33.9306 60.8141 49.1485 29.2583 45.9306 13.5C43.1982 0.119187 24.3615 1.00021 20.4305 1.00021C16.4995 1.00021 4.16297 0.119184 1.43058 13.5C-1.7873 29.2583 1.43058 69.3141 1.43058 95Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>