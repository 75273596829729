<template>
	<div class="sis">
		<!-- Iterate through choices -->
		<template v-if="question.hasOwnProperty('options')">
			<div v-for="(option, index) in question.options" :key="option.id"
				:class="['choice-small', 'with-image', 'small-image', { selected: isSelected(option) }]"
				@click="select(option)">

				<!-- Choice content -->
				<div class="image-container">
					<img :src="isSelected(option) ? option.active_image : option.inactive_image">
				</div>
				<div class="text">{{ option.value }}</div>
			</div>
		</template>
		<div v-else v-for="choice in max" :key="choice"
			v-bind:class="['choice-small', 'with-image', 'small-image', { selected: isSelected(question.select[choice - 1]) }]"
			@click="selectFor(choice - 1)">

			<!-- Choice content -->
			<div class="image-container">
				<img :src="getImage(choice)">
			</div>
			<div class="text">{{ getSelectText(choice) }}</div>
		</div>
	</div>
</template>


<script>
import ImageSelect from './mixin/image-select'
import Question from './index'

export default {
	name: 'small-image-select',
	extends: Question,
	mixins: [ ImageSelect ],

	mounted() {
		this.$emit('selected', this.saved)
	}
}
</script>

<style lang="scss" scoped>
$choice-size: 100px;
$choice-font-size: 18px;
$selected-background: #faf5fc;
$selected-color: #833e93;
$background: #eeeeee;

@import "style.scss";
.choice-small {
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100px;
	min-height: 150px;
	height: auto;
	margin: 10px;
	.image-container {
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		// border: 2px solid $choice-border;
		img {
			width: 100%;
			max-height: 80px;
			height: auto;
			background: $background;
		}
	}

}
.choice-small:hover {
    cursor: pointer;
    background: $background-hover;
    border-color: $background;
}
.choice-small.selected {
	font-weight: 700;
	color: $selected-color;
	.image-container {
		border: 2px solid $selected-color;
	}
	img {
		background: $selected-background;
		color: $selected-color;
	}
}

.text {
	top: $choice-size;
	font-size: 14px !important;
	line-height: 18px !important;
	margin-top: 10px !important;
}
div.sis {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
</style>
