import BODYMAP from './get-body-map'
import getOldAnswer from './get-old-answer'
import formatDateTime from './format-date-time'
const monthShortName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export default function (question, answer, version) {
    if(version === 2 && answer)
        answer = getOldAnswer(question, answer)
    if (Array.isArray(answer)) {
        let transAnswer = answer
        if (question.type === 'long_text_bubble') {
            return answer.map((ansObj) => ansObj.display_text || ansObj.value).join(', ')
        } else if (question.type === 'human_map') {
            return transAnswer.map((bodyPart) => BODYMAP[bodyPart] ? BODYMAP[bodyPart].name : '').join(', ')
        } else if (question.type === 'sign_document') {
            if (answer[0] && (answer[0].image_url || answer[0].value)) {
                return 'Signed - ' + formatDateTime(answer[0].meta.device_time)
            } else {
                return 'Not Answered'
            }
        } else if (question.type === 'date') {
            if (answer[0] && answer[0].year) {
                if (answer[0].date) {
                    return monthShortName[answer[0].month - 1] + ' ' + ((answer[0].date < 9) ? ' 0' + answer[0].date : answer[0].date) + ', ' + answer[0].year
                } else {
                    return monthShortName[answer[0].month - 1] + ', ' + answer[0].year
                }
            } else {
                return 'Not Answered'
            }
        } else if (question.type === 'unit') {
            if (answer[0] && typeof answer[0] === 'object' && Object.keys(answer[0]).length > 0) {
                return answer[0].answer + ' ' + (answer[0].display_text || answer[0].value)
            }
            else
                return 'Not Answered'
        } else if (question.type === 'select') {
            // check if answers is new type
            if(answer.some((ansObj) => ansObj && ansObj.value)) {
                transAnswer = answer.map((ansObj) => ansObj.value)
            }
            return transAnswer.map((ansValue) => question.option.includes(ansValue) ? ansValue : `"${ansValue}"`).join(', ')
        } else if (question.type === 'text') {
            if(answer[0] && typeof answer[0] === 'string')
                return answer[0]
            return answer[0] ? answer[0].answer : ''
        }
        if(answer.some((ansObj) => ansObj && ansObj.value))
            return answer.map((ansObj) => ansObj.description || ansObj.value).join(', ')
        return answer.join(', ')
    }
    if (question.type === 'radio') {
        return answer
    }
    if (answer != null && typeof answer === 'object') {

        var nullValue = false
        Object.keys(answer).forEach((a) => {
            if (answer[a] == null)
                nullValue = true
        })

        if (question.type === 'star') {
            let index = parseInt(Object.keys(answer)[0])
            return (index + 1) + ' star' + (question.label[index] ? (' (' + question.label[index] + ')') : '')
        } else if (question.type === 'radio') {
            return answer
        } else if (question.type === 'image_upload') {
            if (answer.image_url)
                return 'Image Added ' + (answer.image_description ? `( ${answer.image_description.trim()} )` : '')
            else
                return answer.image_description
        } else if (question.type === 'slider' || question.type === 'scroller') {

            let k = Object.keys(answer)[0]
            let n = parseInt(k) + 1
            let v = answer[k]
            return v || n
        } else if (nullValue) {
            if (question.type === 'text')
                return answer[0] ? answer[0].trim() : ''
            return Object.keys(answer).join(', ')
        } else if (question.type === 'text')
            return answer[0] ? answer[0].trim() : ''
        else
            return Object.keys(answer).map((k) => {
                if(Array.isArray(answer[k]))
                    return answer[k].join(' ,')
                return answer[k]
            })
    } else
        return ''
}