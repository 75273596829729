<template>
<svg width="36" height="64" viewBox="0 0 36 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.4964 35.0227C4.75488 46.3162 8.43947 54.9945 13.3936 60.0484C16.0494 62.7576 18.8232 64.166 21.4266 63.8402C23.5708 63.5718 32.613 63.3429 34.0003 61.0498C37.4394 55.365 31.1244 43.1006 28.6524 30.7394C26.6205 20.5791 28.062 7.74875 23.6961 2.41014C20.6078 -1.36627 11.9543 0.632688 8.86369 1.0195C6.23442 1.34856 4.12996 3.40263 2.6589 6.68439C-0.0437964 12.7137 0.249976 23.7894 2.4964 35.0227Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_flank_side',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
