<template>
    <div class="settings-footer">
        <img src="@/image/DoctorPlan_logo_blue.png">
        <div class="version">App Version - V 2.2</div>
        <div class="rights">Copyright 2019 DoctorBox Health Inc.</div>
        <div class="rights">All rights reserved.</div>
    </div>
</template>
<script>

export default {
	props: {
	},
	mounted() {
	},
	data() {
		return {

		}
	},
    methods: {

    },
    computed: {
    },
    components: {
    }
}
</script>
<style lang="scss" scoped>
.settings-footer {
    background: #EEEEEE;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    img {
        color: #2D3E4F;
        width: 150px;
        height: auto;
    }
    .version {
        font-size: 10pt;
        padding-top: 10px;
        padding-bottom: 15px;
    }
    .rights {
        font-size: 10pt;
        color: #6D6D6D;
        padding-bottom: 5px;
    }
}
</style>