<template>
<svg width="55" height="41" viewBox="0 0 55 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.268686 2.84039C0.13804 1.45928 1.41645 0.35949 2.76576 0.681826C8.0882 1.9533 19.9816 4.59463 28.5 5C35.6018 5.33796 45.9687 4.57563 52.1925 4.0187C54.0335 3.85396 55.2265 6.14421 54.1127 7.61921C51.384 11.2325 48 16.5167 48 20.5C48 25.8238 46.0911 33.1533 45.3187 35.901C45.126 36.5863 44.5811 37.0982 43.8857 37.2501C40.3546 38.0215 29.1929 40.2938 21.5 40C15.9157 39.7867 8.02078 38.0631 4.87306 37.3291C4.04776 37.1366 3.44665 36.436 3.36684 35.5923L0.268686 2.84039Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_upper_abdomen',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
