<template>
    
    <svg width="286" height="391" viewBox="0 0 286 391" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_4017_7628" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="286" height="391">
<rect width="286" height="391" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_4017_7628)">
<path d="M112.572 367.265H91.6642C91.076 367.265 90.6999 366.638 90.9767 366.119L96.4776 355.805C96.5564 355.657 96.6791 355.539 96.8317 355.471C97.8376 355.018 101.89 353.275 104.78 353.24C107.629 353.206 111.578 354.804 112.9 355.376C113.178 355.497 113.351 355.771 113.351 356.074V366.486C113.351 366.916 113.002 367.265 112.572 367.265Z" fill="white"/>
<path d="M66.3941 370.771H75.951C76.3813 370.771 76.7302 370.422 76.7302 369.992V363.839C76.7302 363.55 76.571 363.285 76.309 363.163C75.5697 362.818 74.0059 362.165 72.8344 362.2C71.6575 362.236 70.0674 362.991 69.5215 363.268C69.394 363.333 69.2902 363.434 69.2186 363.558L65.7198 369.602C65.4191 370.121 65.7939 370.771 66.3941 370.771Z" fill="white"/>
<path d="M44.8146 368.434H53.7448C54.1751 368.434 54.5239 368.085 54.5239 367.654V361.83C54.5239 361.574 54.3992 361.335 54.1816 361.2C53.4884 360.77 51.8782 359.863 50.6281 359.863C49.2681 359.863 47.4821 360.936 46.914 361.301C46.7934 361.379 46.7015 361.49 46.6433 361.621L44.1026 367.338C43.8736 367.853 44.2507 368.434 44.8146 368.434Z" fill="white"/>
<path d="M27.6427 363.369H35.0448C35.4751 363.369 35.8239 363.02 35.8239 362.59V358.258C35.8239 358.039 35.7323 357.831 35.5645 357.691C34.8513 357.094 32.8042 355.518 31.149 355.577C29.6127 355.632 27.7675 357.119 27.1105 357.692C26.95 357.832 26.8635 358.035 26.8635 358.249V362.59C26.8635 363.02 27.2124 363.369 27.6427 363.369Z" fill="white"/>
<path d="M11.9456 349.734H17.5135C17.9439 349.734 18.2927 349.385 18.2927 348.954V345.391C18.2927 345.179 18.2065 344.975 18.0453 344.837C17.568 344.428 16.486 343.587 15.5656 343.5C14.5438 343.404 13.1925 344.188 12.6691 344.523C12.5242 344.615 12.4215 344.758 12.371 344.923L11.2009 348.725C11.0467 349.226 11.4214 349.734 11.9456 349.734Z" fill="white"/>
<path d="M112.572 367.265H91.6642C91.076 367.265 90.6999 366.638 90.9767 366.119L96.4776 355.805C96.5564 355.657 96.6791 355.539 96.8317 355.471C97.8376 355.018 101.89 353.275 104.78 353.24C107.629 353.206 111.578 354.804 112.9 355.376C113.178 355.497 113.351 355.771 113.351 356.074V366.486C113.351 366.916 113.002 367.265 112.572 367.265Z" :stroke="color"/>
<path d="M66.3941 370.771H75.951C76.3813 370.771 76.7302 370.422 76.7302 369.992V363.839C76.7302 363.55 76.571 363.285 76.309 363.163C75.5697 362.818 74.0059 362.165 72.8344 362.2C71.6575 362.236 70.0674 362.991 69.5215 363.268C69.394 363.333 69.2902 363.434 69.2186 363.558L65.7198 369.602C65.4191 370.121 65.7939 370.771 66.3941 370.771Z" :stroke="color"/>
<path d="M44.8146 368.434H53.7448C54.1751 368.434 54.5239 368.085 54.5239 367.654V361.83C54.5239 361.574 54.3992 361.335 54.1816 361.2C53.4884 360.77 51.8782 359.863 50.6281 359.863C49.2681 359.863 47.4821 360.936 46.914 361.301C46.7934 361.379 46.7015 361.49 46.6433 361.621L44.1026 367.338C43.8736 367.853 44.2507 368.434 44.8146 368.434Z" :stroke="color"/>
<path d="M27.6427 363.369H35.0448C35.4751 363.369 35.8239 363.02 35.8239 362.59V358.258C35.8239 358.039 35.7323 357.831 35.5645 357.691C34.8513 357.094 32.8042 355.518 31.149 355.577C29.6127 355.632 27.7675 357.119 27.1105 357.692C26.95 357.832 26.8635 358.035 26.8635 358.249V362.59C26.8635 363.02 27.2124 363.369 27.6427 363.369Z" :stroke="color"/>
<path d="M11.9456 349.734H17.5135C17.9439 349.734 18.2927 349.385 18.2927 348.954V345.391C18.2927 345.179 18.2065 344.975 18.0453 344.837C17.568 344.428 16.486 343.587 15.5656 343.5C14.5438 343.404 13.1925 344.188 12.6691 344.523C12.5242 344.615 12.4215 344.758 12.371 344.923L11.2009 348.725C11.0467 349.226 11.4214 349.734 11.9456 349.734Z" :stroke="color"/>
<path d="M27.2529 328.307C25.3049 332.592 21.3312 344.046 21.0195 355.577M47.5112 337.657C44.6542 341.812 39.0962 353.863 39.7195 368.823M68.1591 337.657C68.1591 337.657 66.9801 348.67 65.432 355.577C63.5485 363.982 58.8091 376.615 58.8091 376.615M94.6508 337.657C93.482 352.461 82.5737 365.707 84.9112 376.615" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.1056 135.852C36.8469 108.528 19.0081 4.24437 17.2244 -6.16193C17.1428 -6.63803 17.522 -7 18.0051 -7H127.794C128.251 -7 128.615 -6.65753 128.575 -6.20221C127.777 2.94074 120.341 88.1691 117.632 119.49C114.851 151.641 116.369 174.245 116.46 175.532C116.463 175.573 116.467 175.603 116.477 175.644C116.678 176.504 119.19 187.415 119.19 196.237C119.19 207.756 116.542 237.061 116.466 237.904C116.463 237.931 116.464 237.914 116.464 237.94C116.475 238.789 116.861 269.954 117.631 276.102C118.412 282.335 125.423 302.594 127.371 310.385C129.278 318.014 126.332 342.073 126.208 343.078C126.204 343.11 126.204 343.126 126.204 343.159V370.714C126.204 370.752 126.202 370.785 126.195 370.822C126.04 371.717 123.574 384.017 105.556 384.017C93.0385 384.017 88.2016 380.525 86.3716 378.217C85.9099 377.634 84.4967 377.537 84.0361 378.121C82.0692 380.611 77.6882 384.605 70.1067 384.017C62.6351 383.437 59.9764 379.162 59.1433 377.202C58.9402 376.724 58.3491 376.465 57.8773 376.681C55.4277 377.806 48.6334 380.409 43.6112 377.394C41.1455 375.913 40.3906 372.209 40.1724 369.618C40.1065 368.835 38.772 368.313 38.1426 368.782C36.2237 370.214 32.8792 371.769 28.0279 370.771C21.7449 369.478 21.4361 360.522 21.6313 356.434C21.6654 355.72 20.7198 355.18 20.0828 355.505C18.4283 356.35 15.7499 357.186 12.8348 355.967C8.03372 353.959 8.94452 348.954 9.33158 343.11C9.71865 337.267 11.2734 325.579 15.9499 314.671C20.6055 303.811 30.2779 280.983 30.3648 280.778C30.3675 280.772 30.3634 280.782 30.3659 280.776C30.4472 280.568 40.3036 255.394 40.1056 237.923C40.012 229.657 37.082 221.387 37.7666 211.042C38.4157 201.233 43.0885 186.56 43.5716 185.064C43.6002 184.975 43.6105 184.906 43.6066 184.813C43.5172 182.683 42.2954 154.213 40.1056 135.852Z" :stroke="color" stroke-width="2"/>
<path d="M174.717 367.265H195.625C196.213 367.265 196.589 366.638 196.312 366.119L190.811 355.805C190.733 355.657 190.61 355.539 190.457 355.471C189.451 355.018 185.399 353.275 182.509 353.24C179.66 353.206 175.711 354.804 174.389 355.376C174.111 355.497 173.938 355.771 173.938 356.074V366.486C173.938 366.916 174.287 367.265 174.717 367.265Z" fill="white"/>
<path d="M220.895 370.771H211.338C210.908 370.771 210.559 370.422 210.559 369.992V363.839C210.559 363.55 210.718 363.285 210.98 363.163C211.719 362.818 213.283 362.165 214.455 362.2C215.632 362.236 217.222 362.991 217.768 363.268C217.895 363.333 217.999 363.434 218.07 363.558L221.569 369.602C221.87 370.121 221.495 370.771 220.895 370.771Z" fill="white"/>
<path d="M242.474 368.434H233.544C233.114 368.434 232.765 368.085 232.765 367.654V361.83C232.765 361.574 232.89 361.335 233.107 361.2C233.801 360.77 235.411 359.863 236.661 359.863C238.021 359.863 239.807 360.936 240.375 361.301C240.496 361.379 240.588 361.49 240.646 361.621L243.187 367.338C243.416 367.853 243.038 368.434 242.474 368.434Z" fill="white"/>
<path d="M259.646 363.369H252.244C251.814 363.369 251.465 363.02 251.465 362.59V358.258C251.465 358.039 251.557 357.831 251.725 357.691C252.438 357.094 254.485 355.518 256.14 355.577C257.676 355.632 259.522 357.119 260.179 357.692C260.339 357.832 260.426 358.035 260.426 358.249V362.59C260.426 363.02 260.077 363.369 259.646 363.369Z" fill="white"/>
<path d="M275.343 349.734H269.776C269.345 349.734 268.996 349.385 268.996 348.954V345.391C268.996 345.179 269.083 344.975 269.244 344.837C269.721 344.428 270.803 343.587 271.723 343.5C272.745 343.404 274.097 344.188 274.62 344.523C274.765 344.615 274.868 344.758 274.918 344.923L276.088 348.725C276.242 349.226 275.868 349.734 275.343 349.734Z" fill="white"/>
<path d="M174.717 367.265H195.625C196.213 367.265 196.589 366.638 196.312 366.119L190.811 355.805C190.733 355.657 190.61 355.539 190.457 355.471C189.451 355.018 185.399 353.275 182.509 353.24C179.66 353.206 175.711 354.804 174.389 355.376C174.111 355.497 173.938 355.771 173.938 356.074V366.486C173.938 366.916 174.287 367.265 174.717 367.265Z" :stroke="color"/>
<path d="M220.895 370.771H211.338C210.908 370.771 210.559 370.422 210.559 369.992V363.839C210.559 363.55 210.718 363.285 210.98 363.163C211.719 362.818 213.283 362.165 214.455 362.2C215.632 362.236 217.222 362.991 217.768 363.268C217.895 363.333 217.999 363.434 218.07 363.558L221.569 369.602C221.87 370.121 221.495 370.771 220.895 370.771Z" :stroke="color"/>
<path d="M242.474 368.434H233.544C233.114 368.434 232.765 368.085 232.765 367.654V361.83C232.765 361.574 232.89 361.335 233.107 361.2C233.801 360.77 235.411 359.863 236.661 359.863C238.021 359.863 239.807 360.936 240.375 361.301C240.496 361.379 240.588 361.49 240.646 361.621L243.187 367.338C243.416 367.853 243.038 368.434 242.474 368.434Z" :stroke="color"/>
<path d="M259.646 363.369H252.244C251.814 363.369 251.465 363.02 251.465 362.59V358.258C251.465 358.039 251.557 357.831 251.725 357.691C252.438 357.094 254.485 355.518 256.14 355.577C257.676 355.632 259.522 357.119 260.179 357.692C260.339 357.832 260.426 358.035 260.426 358.249V362.59C260.426 363.02 260.077 363.369 259.646 363.369Z" :stroke="color"/>
<path d="M275.343 349.734H269.776C269.345 349.734 268.996 349.385 268.996 348.954V345.391C268.996 345.179 269.083 344.975 269.244 344.837C269.721 344.428 270.803 343.587 271.723 343.5C272.745 343.404 274.097 344.188 274.62 344.523C274.765 344.615 274.868 344.758 274.918 344.923L276.088 348.725C276.242 349.226 275.868 349.734 275.343 349.734Z" :stroke="color"/>
<path d="M260.036 328.307C261.984 332.592 265.958 344.046 266.27 355.577M239.778 337.657C242.635 341.812 248.193 353.863 247.57 368.823M219.13 337.657C219.13 337.657 220.309 348.67 221.857 355.577C223.741 363.982 228.48 376.615 228.48 376.615M192.638 337.657C193.807 352.461 204.715 365.707 202.378 376.615" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M247.183 135.852C250.442 108.528 268.281 4.24437 270.065 -6.16193C270.146 -6.63803 269.767 -7 269.284 -7H159.495C159.038 -7 158.674 -6.65753 158.714 -6.20221C159.512 2.94074 166.948 88.1691 169.657 119.49C172.438 151.641 170.92 174.245 170.829 175.532C170.826 175.573 170.822 175.603 170.812 175.644C170.611 176.504 168.099 187.415 168.099 196.237C168.099 207.756 170.747 237.061 170.823 237.904C170.826 237.931 170.825 237.914 170.825 237.94C170.814 238.789 170.428 269.954 169.658 276.102C168.877 282.335 161.866 302.594 159.918 310.385C158.011 318.014 160.957 342.073 161.081 343.078C161.085 343.11 161.086 343.126 161.086 343.159V370.714C161.086 370.752 161.087 370.785 161.094 370.822C161.25 371.717 163.715 384.017 181.733 384.017C194.251 384.017 199.087 380.525 200.917 378.217C201.379 377.634 202.792 377.537 203.253 378.121C205.22 380.611 209.601 384.605 217.182 384.017C224.654 383.437 227.313 379.162 228.146 377.202C228.349 376.724 228.94 376.465 229.412 376.681C231.861 377.806 238.656 380.409 243.678 377.394C246.144 375.913 246.898 372.209 247.117 369.618C247.183 368.835 248.517 368.313 249.146 368.782C251.065 370.214 254.41 371.769 259.261 370.771C265.544 369.478 265.853 360.522 265.658 356.434C265.624 355.72 266.569 355.18 267.206 355.505C268.861 356.35 271.539 357.186 274.454 355.967C279.255 353.959 278.345 348.954 277.957 343.11C277.57 337.267 276.016 325.579 271.339 314.671C266.684 303.811 257.011 280.983 256.924 280.778C256.922 280.772 256.926 280.782 256.923 280.776C256.842 280.568 246.985 255.394 247.183 237.923C247.277 229.657 250.207 221.387 249.522 211.042C248.873 201.233 244.201 186.56 243.717 185.064C243.689 184.975 243.679 184.906 243.682 184.813C243.772 182.683 244.994 154.213 247.183 135.852Z" :stroke="color" stroke-width="2"/>
</g>
</svg>

</template>

<script>
export default {
	name: 'body_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>