<template>
	<div class="download">
		<p>{{ getText }}</p>
		<div class="link">

			<a v-if="isAccountGerd(account)" href="https://apps.apple.com/in/app/gerdhelp/id1568842316">
				<img src="@/image/download/ios.png">
			</a>
			<a v-else-if="account === 'livechair'" href="https://apps.apple.com/in/app/live-chair-health/id1537755288">
				<img src="@/image/download/ios.png">
			</a>
			<a v-else href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
				<img src="@/image/download/ios.png">
			</a>

			<a v-if="isAccountGerd(account)" href="https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US">
				<img src="@/image/download/android.png">
			</a>
			<a v-else-if="account === 'livechair'" href="https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient">
				<img src="@/image/download/android.png">
			</a>
			<a v-else href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
				<img src="@/image/download/android.png">
			</a>


		   <!-- <a :href="account === 'livechair' ? 'https://apps.apple.com/in/app/live-chair-health/id1537755288' : 'https://itunes.apple.com/us/app/doctorbox/id1437144807'">
			   <img src="@/image/download/ios.png">
		   </a>
		   <a :href="account === 'livechair' ? 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient' : 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'">
			   <img src="@/image/download/android.png">
		   </a> -->
		</div>
	</div>
</template>
<script>
import isAccountGerd from '../../../util/is-account-gerd';

export default {
	name: 'auth-download-app',
	props: {
		connect: {
			type: Boolean
		},
		account: {
			type: String,
			default: 'doctorplan'
		}
	},

	methods: {
		isAccountGerd
	},

	computed: {
		getText() {
			return 'Download the ' + (this.account === 'livechair' ? 'LiveChair' : (this.isAccountGerd(this.account) ? 'GERDHelp' : 'DoctorPlan')) + 
			' app to ' + (this.connect ? 'stay connected with your doctor and care team.' : 'track your symptoms, vitals, lifestyle, and more.')
		}
	}
}
</script>
<style lang="scss" scoped>
p {
	font-size: 14px;
	color: #5f5f5f;
}
.link {
	a {
		margin-top: 15px;
		width: 40%;
		height: 40px;
		display: inline-block;

		img {
			width: 100%;
		}
	}
	a:first-child {
		width: 36%;
		margin-right: 2%;
	}
}
</style>
