<template>
    <div class ="medication">
        <span class="title">
            {{ attributes.name }}
        </span>
        <span class="count">
            {{ this.attributes.dose }}
        </span>
    </div>
</template>

<script>
export default {
	props: {
        attributes: {
            type: Object
        }
	}
}
</script>

<style lang="scss" scoped>
.medication {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-right: 5%;
    .title {
        margin-left: 5%;
    }
    .count {
        margin-left: auto;
    }
}
@media only screen and (min-width: 600px) {
    .medication {
        .title {
            font-size: 18pt;
            font-weight: 500;
        }
        .count {
            font-size: 18pt;
            font-weight: 500;
        }
    }
}
</style>
