export default function({ dispatch, commit }, credentials) {
    commit('startedAuthentication')

    // POST /auth/login
    return dispatch('post', {
        url: '/auth/login/initiate',
        data: credentials
    }, {
        root: true
    })
    .then((response) => {
        commit('finishedAuthentication')
        commit('validatedEmail', response.email)
        return response
    })
    .catch((error) => {
        commit('finishedAuthentication')
        throw error
    })
}
