<template>
<svg width="62" height="71" viewBox="0 0 62 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 6.99502C7.5 6.99502 17.9862 2.03818 36.5 0.999448C43.8315 0.588106 52.4894 1.91675 61 3V47.5C61 47.5 63.2782 72.2058 29.7071 70.7062C29.7071 70.7062 7.60683 71.2067 0 52.3322L7.5 6.99502Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_buttock',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
