<template>
    <form class="form">
        <div class="inputGroup">
            <input id="radio1" name="radio" type="radio" value="male" v-model="picked" @change="updateInput"/>
            <label for="radio1">Male</label>
        </div>
        <div class="inputGroup">
            <input id="radio2" name="radio" type="radio" value="female" v-model="picked" @change="updateInput"/>
            <label for="radio2">Female</label>
        </div>
        <div class="inputGroup">
            <input id="radio3" name="radio" type="radio" value="other" v-model="picked" @change="updateInput"/>
            <label for="radio3">Other</label>
        </div>
    </form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
	props: {
        value: {
            type: String
        }
    },
	data() {
		return {
            genderValue: null,
            picked: null
		}
    },
	mounted() {

	},
    methods: {
        updateInput() {
            this.$emit('invalidText', true)
            this.$emit('params', { gender: this.picked})
        }
    },
    computed: {
    },
    components: {
    }
}
</script>
<style lang="scss" scoped>
// .topic-gender {

// }
.inputGroup {
    display: block;
    margin: 10px 0;
    position: relative;

    label {
      padding: 12px 30px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        //background-color: #5562eb;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #2D3E4F;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #2D3E4F;
      font-weight: 600;

      &:after {
        background-color: #2D3E4F;
        border-color: #2D3E4F;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
}

.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 60px;
    margin-bottom: 5px;
  //padding: 0 10px;
  //max-width: 250px;
  //margin: 50px auto;
  font-size: 18px;
  font-weight: 200;
  line-height: 15px;
}
</style>