<template>
	<DownloadView v-if="showDownload" :type="'symptom'"></DownloadView>
	<el-row class="thankyou" v-else-if="completed && symptom">
		<el-col :span="24">
			<div class="header">
				<span @click="goBack"><NavIcon color="black"></NavIcon></span>
				<span class="header-title">{{ symptom.title }}</span>
			</div>
		</el-col>
		 <el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 8, offset: 8 }" class="thankyou-section">
			 <el-row class="status">
			  	<el-col :span="2" type="flex" align="left">
					<img :src="symptom.image">
				</el-col>
			  	<el-col :span="16" :offset="1" type="flex" align="left">
					<span>Thanks for logging <b>{{ symptom.title }}</b> at <b>{{ time }}</b> on <b>{{ day }}.</b></span>
				</el-col>
			 </el-row>
			 <div class="message">
				 <p><b>IMPORTANT:</b></p>
				 <br>
				 <p>
					 The health information you record in the DoctorPlan app is NOT IMMEDIATELY REVIEWED by your doctor and their staff.
					 They review it before your visits, so they are up to date on your health and are prepared for your visit.
				 </p>
				 <br>
				 <p>
					 If your reported symptom is an urgent matter,
					 please call your doctor’s office as you would normally do. If you have a medical emergency, please call 911.
				 </p>

			 </div>
			 <div class="done">
				 <button @click="done">Done</button>
			 </div>

		 </el-col>
	</el-row>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import NavIcon from './header/icon/Back'
import DownloadView from '@/component/DownloadView'

export default {
	name: 'symptom-thankyou',

	props: {
		id: {
			type: String
		},
		symptom: {
			type: Object,
			required: true
		},
		result: {
			type: Array,
			default: []
		},
		hasHandoffToken: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			completed: false,
			showDownload: false,
			timestamp: undefined
		}
	},
	mounted() {
		console.log('thank you page mounted')
		// check if the symptom is already completed/logged or not
		// if not then log the symptom
		if (!this.completed && this.id) {
			// check if we have handoff_token
			// if yes then use event api to log symptom
			// then show thank you page, then download page
			if (this.hasHandoffToken) {
				this.patchSymptom().then((res) => {
					console.log('res patch', res)
					this.timestamp = res.time
					this.removeLocalStorage()
					this.completed = true
				})
			}
			// else use patient symptom logging api
			// then show thank you page then redirect to symptom landing page.
			else {
				this.postSymptom().then((res) => {
					console.log('res post', res)
					this.timestamp = res.time
					this.removeLocalStorage()
					this.completed = true
				})
			}
		}
		// else remove all data and show download page
		else this.removeLocalStorage()
	},
	methods: {
		...mapActions('user', [
			'addEvent',
			'logSymptom',
		]),
		...mapActions('auth', [
			'logout'
		]),
		done() {
			// check if we have handoff_token
			// then logout and show download page
			if (this.hasHandoffToken) this.showDownload = true
			else this.goBack()

		},
		goBack() {
			this.$router.push('/symptom')
		},
		postSymptom() {
			localStorage.removeItem(this.id)
			return this.logSymptom({
				id: this.id,
				type: 'symptom',
				body: {
					time: Date.now(),
					type: 'symptom:' + this.id,
					question: this.result
				}
			})
		},
		patchSymptom() {
			localStorage.removeItem(this.id)
			return this.addEvent({
				id: this.id,
				type: 'symptom',
				body: {
					// completed: Date.now(),
					question: this.result
				}
			}).then().catch((error) => {
				this.$message({
					showClose: true,
					message: 'Unable to send response. Error: ' + error.code,
					type: 'error',
					duration: '0'
				})
			})
		},
		removeLocalStorage() {
			// localStorage.removeItem('auth')
			localStorage.removeItem('handoff_token')
			localStorage.removeItem(this.id + '_result')
		},
		pad(num) {
			return ("0"+num).slice(-2);
		}
	},
	computed: {
		time() {
			if (this.timestamp) {
				let m = moment(this.timestamp)
				return m.format('h:mm A')
			} return ''
		},
		day() {
			if (this.timestamp) {
				let m = moment(this.timestamp)
				return m.format('ddd, MMM D')
			} return ''
		}
	},
	components: {
		NavIcon,
		DownloadView
	}
}
</script>


<style lang="scss" scoped>
@import "./style/style.scss";
</style>
