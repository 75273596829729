<template>
    
    <svg width="32" height="52" viewBox="0 0 32 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.6651 8.12341L1.89282 43.7265C1.93107 44.8356 2.11889 45.9406 2.59808 46.9416C3.40624 48.6298 4.92189 51.0904 6.86595 51.017C17.4785 50.6166 26.1976 38.8741 29.3365 29.9509C31.7492 23.0921 33.3063 10.6604 27.9321 4.6713C23.7557 0.0170275 18.7203 0.00172019 12.4836 0.458064C9.64757 0.665576 6.12461 2.02829 3.64322 3.14863C1.71235 4.02041 0.592089 6.00611 0.6651 8.12341Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_cheekbone',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
