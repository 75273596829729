<template>
    
    <svg width="47" height="13" viewBox="0 0 47 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M44.958 9.33462L46.6181 4.24038C47.2781 2.21512 45.6762 0.166388 43.5515 0.318516L35.3853 0.903226H27.3119L16.7156 2.25806L6.90809 3.51204C6.392 3.57803 5.9037 3.78358 5.49578 4.10654L1.3447 7.39313C-0.325184 8.71526 1.09565 11.3652 3.12099 10.706C3.73259 10.507 4.4011 10.6354 4.95888 10.9556C5.52468 11.2804 6.31022 11.6425 7.12844 11.7419C7.89856 11.8355 8.90046 11.6171 9.80832 11.33C11.2889 10.8617 12.9311 11.0534 14.2937 11.7981C15.0697 12.2223 15.9458 12.5908 16.7156 12.6452C17.7783 12.7202 19.0562 12.3621 20.1178 11.9584C21.4797 11.4405 23.0227 11.4935 24.3699 12.0486C25.1524 12.371 26.0369 12.6415 26.8073 12.6452C27.7679 12.6497 28.8212 12.2405 29.7496 11.7389C31.3832 10.8564 33.4202 10.7369 35.0868 11.5554C36.2933 12.148 37.7093 12.6703 38.9174 12.6452C40.2476 12.6175 41.8901 12.0206 43.042 11.5204C43.9737 11.1159 44.6433 10.3003 44.958 9.33462Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>