<template>
    <svg width="41" height="52" viewBox="0 0 41 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M37.8751 42.7242C38.1114 42.2783 38.0495 41.7491 37.8238 41.2978L32.1756 29.9999L27.4796 20.4832C26.9558 19.4217 25.6298 19.0448 24.626 19.6723L24.1378 19.9774C23.2249 20.5481 22.9275 21.7389 23.4649 22.6717L34.9369 42.5855C35.0938 42.8579 35.3108 43.0934 35.5924 43.2332C36.0268 43.4488 36.7079 43.707 37.176 43.4999C37.4723 43.3688 37.7076 43.0404 37.8751 42.7242Z" :fill="color"/>
<path opacity="0.9" d="M39.0263 20.0308C39.0091 20.0093 38.9924 19.9889 38.9754 19.9672L33.5007 13L27.6539 4.22879C27.5525 4.07678 27.4308 3.93941 27.2921 3.82054C25.9983 2.71189 23.9999 3.63113 23.9998 5.33496L23.9997 7.21416C23.9997 7.71901 24.1906 8.2052 24.5341 8.57515L36.9091 21.9023C36.9695 21.9673 37.0249 22.0365 37.083 22.1035C37.326 22.3838 38.0759 23.097 39.0004 22.4994C40.2645 21.6824 39.2077 20.2584 39.0263 20.0308Z" :fill="color"/>
<path opacity="0.9" d="M29.0852 50.759C29.5717 50.294 29.6731 49.5758 29.4521 48.9401L25.1713 36.6264L20.6974 23.9787C20.3919 23.1151 19.5269 22.5801 18.6177 22.6926C17.3699 22.8469 16.5914 24.1237 17.0257 25.3037L25.9829 49.6432C26.0607 49.8548 26.1729 50.0523 26.3263 50.2176C26.7413 50.6649 27.6357 51.5287 28.2126 51.3572C28.4891 51.275 28.8067 51.0252 29.0852 50.759Z" :fill="color"/>
<path opacity="0.9" d="M18.2519 50.2129C19.022 49.8369 19.3504 48.9423 19.1353 48.1127L15.9949 36.0002L13.8818 26.2631C13.6644 25.2615 12.725 24.5863 11.7063 24.6996L11.3653 24.7375C10.1307 24.8747 9.3186 26.0923 9.66615 27.2848L15.9604 48.8819C15.9834 48.9608 16.011 49.0381 16.0466 49.1123C16.2244 49.4826 16.8369 50.6377 17.5001 50.5001C17.7113 50.4563 17.9803 50.3455 18.2519 50.2129Z" :fill="color"/>
<path opacity="0.9" d="M8.49391 44.6266C8.50032 44.5417 8.49469 44.4571 8.48408 44.3727L7.49568 36.4992L6.55835 26.6477C6.51872 26.2312 6.32129 25.8457 6.00651 25.5701C4.84195 24.5507 3.04352 25.5656 3.31429 27.0895L6.49355 44.9822C6.49559 44.9937 6.49759 45.0045 6.50019 45.0159C6.52991 45.1457 6.75362 45.9994 7.49761 45.9994C8.31702 45.9994 8.46661 44.9887 8.49391 44.6266Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_fingers_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>