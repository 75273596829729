<template>
    
    <svg width="43" height="114" viewBox="0 0 43 114" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.4984 26.5C38.4877 34.4883 42.1958 46.7796 42.8841 49.1115C42.9607 49.3708 42.9803 49.6437 42.9471 49.912C39.9118 74.4491 39.0318 89.4928 39.3184 111.551C39.3465 113.712 36.4621 114.378 35.5872 112.402C32.2773 104.926 27.6592 93.9916 24.998 85.5006C23.1504 79.6051 20.8705 70.4981 20.539 69.1655C20.5106 69.0516 20.477 68.9533 20.4296 68.8458C19.6845 67.1545 13.0537 51.9715 10.498 41.5006C9.38771 36.9514 8.23771 29.9776 8.03087 28.7039C8.00889 28.5685 7.97345 28.436 7.92464 28.3078L0.864719 9.77051C0.30463 8.29987 0.237099 6.67423 0.863148 5.23043C1.67153 3.36614 2.9804 0.96017 4.49805 0.500567C6.74061 -0.17857 8.89271 -2.44677e-05 10.498 0.500567C12.1034 1.00116 18.8181 4.1358 22.998 8.00057C27.178 11.8653 30.8626 19.2194 34.4984 26.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'thumb_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>