
export default {
    props: {
        question: {
            type: Object
        }
    },
    methods: {
        /**
         * @func limitReached
         * @desc checks the validations array if the limit of the side is reached
         * @param bodyPart(String) string indicating the body part
         */
        limitReached(humanSide) {
            if (this.question.hasOwnProperty('validations') && this.question.validations.filter(v => v.type === 'max_selection').length) {
                let maxSelectionObj = this.question.validations.filter(v => v.type === 'max_selection')[0]
                // Only For swelling type 'Front' and 'Face' are the same, for other type just match the side as is.
                let maxSelectionSideArray = maxSelectionObj.value.filter(v => (v.type === humanSide) || 
                (this.question.sub_type === 'swelling' && v.type === 'Face' && humanSide === 'Front'))

                if (maxSelectionSideArray.length) {
                    let limit = maxSelectionSideArray[0].value
                    if (this.calcSelectedList[humanSide].length >= limit)
                        return true
                }
            }
            return false
        }
    }
}
