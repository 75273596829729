import SettingViewer from './Viewer'
import ContactSupport from './component/ContactSupport'
import SMS from './component/SMS'
import DownloadView from '@/component/DownloadView'

export default [
	{
		path: '/setting',
		name: 'setting-landing',
		component: SettingViewer,
		props: true,
		children: [
			{
				name: 'contact',
				path: 'support',
				props: true,
				component: ContactSupport
			}
			// {
			// 	name: 'sms',
			// 	path: 'sms',
			// 	props: true,
			// 	component: SMS
			// }
		]
	},
	{
		path: '/download',
		name: 'download',
		component: DownloadView
	}
]
