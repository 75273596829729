<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
	    <defs>
	        <linearGradient id="i-h-jg" x1="91.068%" x2="14.543%" y1="-3.431%" y2="100%">
	            <stop offset="0%" stop-color="#2C6CA3"/>
	            <stop offset="100%" stop-color="#2D3E4F"/>
	        </linearGradient>
	    </defs>
	    <g fill="none" fill-rule="evenodd">
	        <path fill="#2D3E4F" d="M7 5.5h5.5v1H7V12H6V6.5H.5v-1H6V0h1v5.5z"/>
	        <path fill="url(#i-h-jg)" fill-rule="nonzero" d="M14.24 10v19h3.33V10h-3.33zm4.217 0v19H28V10h-1.553v5.489a.446.446 0 0 1-.652.394l-1.79-.967-1.789.966a.446.446 0 0 1-.652-.393V10h-3.107zm3.995 0v4.75l1.345-.725a.446.446 0 0 1 .416 0l1.346.725V10h-3.107zm-10.028 1.547a.44.44 0 1 0 .042.884h1.33v-.884h-1.372zm0 5.08a.44.44 0 1 0 .042.884h1.33v-.883h-1.372zm0 4.861a.44.44 0 1 0 .042.884h1.33v-.884h-1.372zm0 5.082a.44.44 0 1 0 .042.884h1.33v-.884h-1.372z" transform="translate(1)"/>
	    </g>
	</svg>
</template>
<script>
export default { name: 'header-add-journal-icon' }
</script>
