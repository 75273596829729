<template>
<svg width="31" height="42" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M30.0189 28.6097V0.495369H9.71421C5.80945 7.52394 3.07609 14.943 1.51418 20.8001C0.342741 28.6097 0.308012 33.3567 1.12365 41.1049H16.7428C16.7428 41.1049 17.5236 35.6382 21.0379 32.1239C24.1617 29.0001 30.0189 28.6097 30.0189 28.6097Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_upper_outer_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
