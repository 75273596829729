<template>
<svg width="55" height="77" viewBox="0 0 55 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.9" width="55" height="77" rx="12" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'mid_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
