import { render, staticRenderFns } from "./Welcome.vue?vue&type=template&id=5ae01218&scoped=true"
import script from "./Welcome.vue?vue&type=script&lang=js"
export * from "./Welcome.vue?vue&type=script&lang=js"
import style0 from "./Welcome.vue?vue&type=style&index=0&id=5ae01218&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae01218",
  null
  
)

export default component.exports