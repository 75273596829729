<template>
	<div class="viewer">
        <ExtendDownload :type="type"></ExtendDownload>
	</div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import ExtendDownload from '@/component/ExtendDownload'

export default {
	name: 'setting-download',
	props: {
		type: {
			type: String
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {

	},
	computed: {

	},
	components: {
        ExtendDownload
	}
}
</script>
<style lang="scss" scoped>
// @import "./style.scss";
</style>
