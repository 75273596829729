export default {
    props: ['value', 'secondary', 'error', 'autofocus'],

    data() {
        return {
            type: 'input'
        }
    },

    methods: {
        change() {
			this.$emit('input', this.$refs.input.value)
		},
        enter() {
			this.change()
			this.primaryNext()
		},
        primaryNext() {
            this.$emit('next')
        },
        secondaryNext() {
            this.$emit('secondary')
        },
        focus() {
            this.$refs.input.focus()
        }
    }
}
