<template>
    
    <svg width="32" height="97" viewBox="0 0 32 97" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.9941 63.5L31.4823 88.6542C31.7487 89.8748 30.8401 91.0362 29.5925 91.1014C20.1295 91.5961 15.2485 93.0916 7.16117 96.7668C5.87741 97.3502 4.39744 96.477 4.30809 95.0697L2.49414 66.5V32.5L0.630783 7.34467C0.540874 6.1309 0.709103 4.88441 1.47759 3.94062C2.67231 2.47334 5.09656 0.5 9.49414 0.5C16.1859 0.5 17.3944 5.06931 17.4879 5.47176C17.4928 5.4927 17.4959 5.5106 17.4994 5.53181L21.9941 32.5L25.9941 63.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'index_finger_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>