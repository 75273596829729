<template>
<svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M5.05155 22.5505C5.50965 31.2314 13.4815 37.8676 22.8573 37.3729C32.233 36.8781 39.4622 29.4397 39.0041 20.7588C38.546 12.0778 30.5741 5.44165 21.1984 5.93641C11.8226 6.43117 4.59345 13.8695 5.05155 22.5505ZM30.0943 19.9692C30.101 20.5214 29.6588 20.9746 29.1066 20.9813C28.5543 20.9881 28.1012 20.5459 28.0944 19.9936C28.0877 19.4414 28.5299 18.9882 29.0822 18.9815C29.6344 18.9747 30.0875 19.417 30.0943 19.9692ZM21.0948 20.079C21.6471 20.0722 22.0893 19.6191 22.0826 19.0669C22.0758 18.5146 21.6227 18.0724 21.0704 18.0791C20.5182 18.0859 20.076 18.539 20.0827 19.0913C20.0895 19.6435 20.5426 20.0857 21.0948 20.079ZM23.168 26.0542C23.1748 26.6064 22.7325 27.0596 22.1803 27.0663C21.6281 27.073 21.1749 26.6308 21.1682 26.0786C21.1614 25.5263 21.6036 25.0732 22.1559 25.0664C22.7081 25.0597 23.1613 25.5019 23.168 26.0542ZM15.1688 26.1522C15.7211 26.1455 16.1633 25.6923 16.1565 25.1401C16.1498 24.5879 15.6967 24.1456 15.1444 24.1524C14.5922 24.1591 14.15 24.6123 14.1567 25.1645C14.1634 25.7168 14.6166 26.159 15.1688 26.1522Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_knee_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
