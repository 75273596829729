<template>
<svg width="52" height="128" viewBox="0 0 52 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.337 76.3936C21.0282 86.7395 27.0385 96.2997 30.8191 104.451C38.8235 121.71 43.7278 128.232 47.3191 126.951C57.8191 125.451 47.3191 106.951 38.8191 73.4512C37.9506 71.0169 37.8261 61.7079 36.4847 55.9512C34.8068 48.7502 29.5771 41.0221 28.8191 37.9512C25.2552 23.5122 26.127 24.6184 24.319 14.9506C23.319 6.95066 21.3192 0.167402 8.31909 0.167402C-2.18097 0.167567 0.0689655 16.4933 0.819089 23.4512C2.04992 34.8679 10.1876 56.3553 17.337 76.3936Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
