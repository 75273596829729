<template>
<svg width="47" height="177" viewBox="0 0 47 177" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.85868 95C18.8588 121.5 2.20746 149.57 6.35868 165C8.82259 174.158 17.6701 176.5 20.8586 176.5C24.0471 176.5 31.8947 169.659 34.3587 160.5C38.5099 145.07 34.3587 116.306 34.3587 86.5C34.3587 60.8141 49.5766 29.2583 46.3587 13.5C43.6263 0.119187 24.7896 1.00021 20.8586 1.00021C16.9276 1.00021 4.59107 0.119184 1.85868 13.5C-1.3592 29.2583 1.85868 69.3141 1.85868 95Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_leg_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
