<template>
<svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.0979 16.8885C22.8313 22.0254 15.6226 23.8196 8.99686 20.8961C2.37114 17.9725 -1.16258 11.4381 1.10408 6.3012C3.37074 1.16428 10.5794 -0.629981 17.2052 2.29361C23.8309 5.2172 27.3646 11.7515 25.0979 16.8885ZM4.95511 9.52699C4.95511 10.0626 5.38934 10.4969 5.92499 10.4969C6.46064 10.4969 6.89487 10.0626 6.89487 9.52699C6.89487 8.99134 6.46064 8.55711 5.92499 8.55711C5.38934 8.55711 4.95511 8.99134 4.95511 9.52699ZM13.6841 9.52715C13.1485 9.52715 12.7143 9.09291 12.7143 8.55726C12.7143 8.02161 13.1485 7.58738 13.6841 7.58738C14.2198 7.58738 14.654 8.02161 14.654 8.55726C14.654 9.09291 14.2198 9.52715 13.6841 9.52715ZM11.7443 15.3463C11.7443 15.882 12.1785 16.3162 12.7142 16.3162C13.2498 16.3162 13.6841 15.882 13.6841 15.3463C13.6841 14.8107 13.2498 14.3764 12.7142 14.3764C12.1785 14.3764 11.7443 14.8107 11.7443 15.3463ZM19.5034 15.3465C18.9677 15.3465 18.5335 14.9123 18.5335 14.3766C18.5335 13.8409 18.9677 13.4067 19.5034 13.4067C20.039 13.4067 20.4732 13.8409 20.4732 14.3766C20.4732 14.9123 20.039 15.3465 19.5034 15.3465Z" :fill="color"/>
</g>
</svg>
</template>
<script>
export default {
	name: 'right_elbow_crease',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
