<template>
    
    <svg width="114" height="181" viewBox="0 0 114 181" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M96.2828 163.994C95.642 148.094 95.163 123.643 97.1201 105.642C98.2329 95.406 107.8 79.2986 110.12 67.6418C113.694 49.6845 113.595 35.0451 113.322 28.8426C113.231 26.7858 112.264 24.9036 110.651 23.6248C102.671 17.2999 76.9174 -1.59449 56.6201 0.64183C33.524 3.18652 8.43841 37.4819 1.80484 47.1315C0.690729 48.7522 0.287891 50.6954 0.595889 52.6378C2.10795 62.1737 6.89352 92.4652 10.1201 114.142C12.8799 132.682 16.8131 157.151 19.4145 173.134C20.4948 179.771 29.0824 182.425 34.319 178.206C40.8003 172.985 48.986 167.72 56.6201 166.642C64.8226 165.484 74.8728 168.81 82.9094 172.449C88.9436 175.182 96.5496 170.613 96.2828 163.994Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>