<template>
    <el-dialog
        :visible.sync="dialogVisible"
        width="80%"
        center
        custom-class="thankyou-dialog"
        >
        <div slot="title" style="font-size: 20px; font-weight: 700; margin-top: 20px;">
            {{ count == 1 ? 'You are almost done!': 'You are not done yet!' }}
        </div>
        <div class="thankyou-body">
            <span class="questionnaire-left">
                {{ count }} More Questionnaire{{ count !== 1 ? 's': '' }} Remaining
            </span>
            <div class="questionnaire welcome" v-if="content">
                <img v-if="content.welcome_image" :src="content.welcome_image">
                <img v-else src="./image/welcome.png">
                <p class="title">{{ content.welcome_header }}</p>
                <p class="subtitle">{{ content.welcome_subheader }}</p>
                <!-- <p class="counts">{{ getQuestionLength }} questions &bull; {{ content.length }} minutes</p> -->
                <p class="appointment-date" v-if="questionnaire && questionnaire.hasOwnProperty('schedule_time')">
                    <img src="./image/appointment.png"> Appointment - <span style="font-weight: 600">{{ getAppointmentDate() }}</span>
                </p>
                <el-button @click="dialogVisible = false" class="start-question">
                    Start Questionnaire
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import formatDate from '@/module/util/format-date'

export default {
    name: 'thankyou-dialog',

    props: {
        count: {
            type: Number
        },
        content: {
            type: Object
        },
        questionnaire: {
			type: Object,
			required: true
		}
    },

    watch: {
        dialogVisible: {
            handler(value) {
                if(value === false)
                    this.$emit('close-up')
            }
        }
    },

    data() {
        return {
            dialogVisible: true
        }
    },
    methods: {
        getAppointmentDate() {
			return formatDate(this.questionnaire.schedule_time)
		}
    }
}
</script>

<style lang="scss">
.thankyou-dialog {
    max-width: 650px;
    div.el-dialog__body {
        padding: 0;
        word-break: normal;
        padding-bottom: 20px;
    }
    .questionnaire-left {
        display: block;
        text-align: center;
        background: #FFEBEB;
        color: #DA4E4E;
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
    }
    .welcome {
        text-align: center;
        img {
            width: 55%;
            height: auto;
            max-height: 45vh;
        }
        .title {
            font-size: 20px;
            color: #333333;
            font-weight: 700;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .subtitle {
            padding: 0px 15px;
            font-size: 16px;
            padding-top: 10px;
        }
        .start-question{
            margin-top: 15px;
            background-color: #833E93;
            color: #ffffff;
            display: block;
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;
        }
        .appointment-date {
            width: 200px;
            height: 36px;
            background-color: #EEF7FF;
            margin-top: 20px;
            padding: 4px;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: center;
            color: #333333;
            display: inline-block;
            img {
                width: 20px;
                height: 20px;
                margin-bottom: -4px;
                margin-right: 6px;
            }
        }

    }
}
</style>