<template>
    
    <svg width="286" height="391" viewBox="0 0 286 391" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_4017_7605" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="286" height="391">
<rect width="286" height="391" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_4017_7605)">
<path d="M156.349 354.565C156.349 354.565 160.646 362.3 168.81 361.011C176.975 359.722 189.006 358.433 189.006 358.433C189.006 358.433 204.906 360.34 216.078 358.433C227.25 356.525 231.548 350.698 231.548 350.698L249.166 337.807" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M179.123 116.939C175.685 138.855 155.231 166.356 154.63 181.825C154.028 197.295 164.513 218.35 164.513 218.35" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M209.632 141.862C209.632 141.862 200.157 135.589 193.303 135.416C185.98 135.231 175.685 141.862 175.685 141.862" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M249.165 338.238C249.165 338.238 255.344 329.354 256.47 322.768C258.262 312.294 250.947 307.032 249.165 296.556C247.305 285.617 248.653 279.226 247.447 268.196C245.778 252.947 242.13 244.782 240.571 229.522C239.241 216.491 239.282 196.005 239.282 196.005L240.571 178.816C240.571 178.816 243.209 165.782 243.149 157.331C243.088 148.693 240.142 135.416 240.142 135.416C240.142 135.416 237.625 121.336 237.134 112.212C236.501 100.471 238.423 82.1323 238.423 82.1323L250.884 17.2465C250.884 17.2465 256.201 -9.84717 256.9 -27.4431C257.654 -46.4271 253.892 -76 253.892 -76H151.622C151.622 -76 150.621 -45.3326 151.622 -25.7242C153.349 8.12123 163.654 60.2172 163.654 60.2172C163.654 60.2172 168.296 91.898 167.091 112.212C165.961 131.27 158.497 160.339 158.497 160.339L156.563 172.371C156.563 172.371 155.015 181.238 154.63 186.981C153.819 199.065 156.778 217.92 156.778 217.92C156.778 217.92 159.047 243.057 158.497 259.172C157.996 273.841 154.63 296.556 154.63 296.556C154.63 296.556 152.871 310.668 152.481 319.76C152.143 327.64 152.481 339.957 152.481 339.957V353.278C152.481 353.278 149.903 375.193 170.529 381.209C191.155 387.224 189.436 358.864 189.436 358.864C189.436 358.864 186.428 384.217 200.179 383.787C213.929 383.357 206.624 359.723 206.624 359.723C206.624 359.723 207.191 382.459 216.937 378.63C228.969 373.904 219.086 357.575 219.086 357.575C219.086 357.575 225.096 376.008 231.547 372.185C243.149 365.309 231.547 350.699 231.547 350.699C231.547 350.699 237.993 363.161 245.728 358.649C252.71 354.576 249.165 338.238 249.165 338.238Z" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M205.765 125.102C205.765 125.102 224.39 150.612 231.117 169.361C235.2 180.739 238.422 199.441 238.422 199.441C238.422 199.441 243.15 229.091 235.845 243.701" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M130.651 354.565C130.651 354.565 126.354 362.3 118.19 361.011C110.025 359.722 97.9935 358.433 97.9935 358.433C97.9935 358.433 82.0943 360.34 70.9219 358.433C59.7495 356.525 55.4525 350.698 55.4525 350.698L37.8345 337.807" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M107.877 116.939C111.315 138.855 131.769 166.356 132.37 181.825C132.972 197.295 122.487 218.35 122.487 218.35" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M77.3677 141.862C77.3677 141.862 86.8431 135.589 93.6966 135.416C101.02 135.231 111.315 141.862 111.315 141.862" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M37.8346 338.238C37.8346 338.238 31.6559 329.354 30.5296 322.768C28.7382 312.294 36.053 307.032 37.8346 296.556C39.6949 285.617 38.3468 279.226 39.5534 268.196C41.2216 252.947 44.8704 244.782 46.4288 229.522C47.7595 216.491 47.7179 196.005 47.7179 196.005L46.4288 178.816C46.4288 178.816 43.7908 165.782 43.8505 157.331C43.9116 148.693 46.8585 135.416 46.8585 135.416C46.8585 135.416 49.3748 121.336 49.8664 112.212C50.4991 100.471 48.5773 82.1323 48.5773 82.1323L36.1158 17.2465C36.1158 17.2465 30.7987 -9.84717 30.0999 -27.4431C29.3459 -46.4271 33.1078 -76 33.1078 -76H135.378C135.378 -76 136.379 -45.3326 135.378 -25.7242C133.651 8.12123 123.346 60.2172 123.346 60.2172C123.346 60.2172 118.704 91.898 119.909 112.212C121.039 131.27 128.503 160.339 128.503 160.339L130.437 172.371C130.437 172.371 131.985 181.238 132.37 186.981C133.181 199.065 130.222 217.92 130.222 217.92C130.222 217.92 127.953 243.057 128.503 259.172C129.004 273.841 132.37 296.556 132.37 296.556C132.37 296.556 134.129 310.668 134.519 319.76C134.857 327.64 134.519 339.957 134.519 339.957V353.278C134.519 353.278 137.097 375.193 116.471 381.209C95.8451 387.224 97.5639 358.864 97.5639 358.864C97.5639 358.864 100.572 384.217 86.8212 383.787C73.0706 383.357 80.3756 359.723 80.3756 359.723C80.3756 359.723 79.8094 382.459 70.0627 378.63C58.0308 373.904 67.9141 357.575 67.9141 357.575C67.9141 357.575 61.904 376.008 55.4526 372.185C43.8505 365.309 55.4526 350.699 55.4526 350.699C55.4526 350.699 49.007 363.161 41.2723 358.649C34.2901 354.576 37.8346 338.238 37.8346 338.238Z" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
<path d="M81.2355 125.102C81.2355 125.102 62.6103 150.612 55.8828 169.361C51.8001 180.739 48.5777 199.441 48.5777 199.441C48.5777 199.441 43.8504 229.091 51.1555 243.701" :stroke="color" stroke-width="1.71883" stroke-linecap="round"/>
</g>
</svg>

</template>

<script>
export default {
	name: 'body_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>