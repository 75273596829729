<template>
    <div v-if="food" class="box-section">
        <div v-if="food" class="image-title">
            <img 
                :src="getFoodImage()">
            <span v-html="getFoodText" class="title">
            </span>
        </div>
        <div v-if="water" class="image-title">
            <img 
                :src="getWaterImage()">
            <span v-html="getWaterText" class="title">
            </span>
        </div>
        <div v-if="excretion" class="image-title">
            <img 
                :src="getExcretionImage()">
            <span v-html="getExcretionText" class="title">
            </span>
        </div>
    </div>
</template>

<script>
export default {

	props: {
        topics: {
            type: Object
        }
	},
    data() {
        return {
            food: this.topics.food,
            water: this.topics.water,
            excretion: this.topics.excretion
        }
    },
    mounted() {
    },
	methods: {
        getFoodImage() {
            return require('../image/food.png')
        },
        getWaterImage() {
            return require('../image/water.png')
        },
        getExcretionImage() {
            return require('../image/bowel.png')
        },
		getFoodSize(value) {
			var size
			if (value > 2)
				size = "Large"
			else if (value > 1)
				size = 'Medium'
			else {
				size = 'Small'
			}
			return size
		},
		getFoodHealthy(value) {
			var health
			if (value > 2)
				health = "Very Healthy"
			else if (value > 1)
				health = 'Somewhat Healthy'
			else {
				health = 'Not Healthy'
			}
			return health
        },
        getWaterAverage(value) {
            return Math.round(value).toFixed(1)
        }
    },
    computed: {
        getFoodText() {
            return `Food - Avg <strong>${this.getFoodSize(this.food.average.size)}</strong> 
            and <strong>${this.getFoodHealthy(this.food.average.health)}</strong>`
        },
        getWaterText() {
            return `Water - Avg <strong>${this.getWaterAverage(this.water.average)}</strong> glasses per day`
        },
        getExcretionText() {
            return `Bowel Movement - Recorded <strong>${this.excretion.count > 1 ? this.excretion.count + ' times' : ' 1 time'}</strong>`
        }
    }
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
