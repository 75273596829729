<template>
    
    <svg width="36" height="58" viewBox="0 0 36 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M35.9995 28.5137C35.9995 44.2538 33.4996 57.5137 18.4995 57.0137C4.49963 57.0137 0.999476 44.2538 0.999476 28.5137C0.999476 12.7736 -2.00037 -0.486329 18.4995 0.0136963C33.9996 0.0155754 35.9995 12.7736 35.9995 28.5137Z" :fill="color" />
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>