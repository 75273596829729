<template>
	<div class="landing">
		<Header :landing="true"></Header>

		<!-- Show all unfinished appointments -->
		<template v-if="unfinishedAppointments">
			<Appointment
				v-for="appointment in unfinishedAppointments"
				:key="appointment.id"
				:appointment="appointment"
				:content="appointmentContent(appointment.type)">
			</Appointment>
		</template>

		<!-- If the events are still loading -->
		<Loading v-else-if="loadingEvents"
			color="#2D3E4F"
			text="Loading appointments...">
		</Loading>
		<!-- Show empty state -->
		<Empty v-else-if="unfinishedAppointments.length == 0"></Empty>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import formatDate from '@/module/util/format-date'

import Header from '../header/Header'
import Loading from '@/component/Loading'
import Empty from './Empty'
import Appointment from './Appointment'

export default {
	name: 'appointment-landing',

	mounted() {
		this.loadEvents({ type: 'appointment' })
	},

	data() {
		return {
			sidebar: false,
			landing: 'landing'
		}
	},

	methods: {
		formatDate,
		...mapActions('user', [
			'loadEvents'
		])
	},

	computed: {
		...mapState('user', [
			'loadingEvents'
		]),
		...mapGetters('user', [
			'getEventType'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		appointments() {
			return this.getEventType('appointment')
		},
		unfinishedAppointments() {
			if (this.appointments)
				return this.appointments.filter((a) => (! a.completed))
		},
		appointmentContent() {
			return (type) => {
				let id = type.substring(type.indexOf(':') + 1)
				return this.getObject('appointment', id)
			}
		},
		getScreen() {
			if (this.appointments)
				return "history"
			else
				return "planning"
		},
		getTitle() {
			if (this.appointments)
				return "History"
			else
				return "Appointments"
		}
		// content() {
		// 	return this.getContent
		// }
	},

	components: {
		Header,
		Empty,
		Loading,
		Appointment
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
