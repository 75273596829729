<template>
<svg width="49" height="55" viewBox="0 0 49 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.1474 35.5769C30.0724 30.4582 30.6 26.2661 33.9875 21.441C38.7196 14.7006 48.5797 6.72203 48.5797 6.72203L44.0197 0.920643C44.0197 0.920643 36.4252 0.431075 31.7075 1.37665C22.9561 3.13071 18.0221 6.07797 11.6432 12.3208C5.70623 18.1312 1.1551 27.369 0.699034 30.5611C0.242968 33.7531 0.699034 54.2734 0.699034 54.2734C0.699034 54.2734 23.4993 41.961 27.1474 35.5769Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_shoulder_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
