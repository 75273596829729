<template>
    
    <svg width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M7.37792 7.53915C9.25376 2.46065 14.4804 -0.44035 19.8344 0.362746L56.0072 5.78867C60.59 6.47608 64.3646 9.75929 65.2912 14.2997C66.3377 19.4278 67.4625 26.6322 67.4777 33.9604C67.4953 42.4566 66.0331 53.7636 64.8812 61.338C64.1267 66.2997 60.2124 70.1068 55.1966 70.2748C48.6808 70.4931 38.9002 70.1691 27.8978 67.6016C16.7749 65.006 9.82018 62.2019 5.86226 60.244C2.97424 58.8153 1.33546 55.9046 1.13272 52.6889C0.739938 46.4588 0.336027 35.7164 1.51461 28.0237C2.49848 21.6021 5.16256 13.5368 7.37792 7.53915Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_buttock_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>