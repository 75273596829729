<template>
<svg width="42" height="68" viewBox="0 0 42 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.7335 18.6183L0.461086 0.833984L1.3731 38.2265C1.3731 38.2265 3.94286 47.3118 6.38918 52.8188C9.0435 58.794 14.5973 67.411 14.5973 67.411C14.5973 67.411 24.6084 69.0706 30.1015 66.499C36.3998 63.5504 41.5017 52.8188 41.5017 52.8188C41.5017 52.8188 39.1103 41.4842 36.4856 34.5785C34.0238 28.1014 28.7335 18.6183 28.7335 18.6183Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_upper_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
