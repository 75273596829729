<template>
    <div class="topic">
        <div class="topic-title">
            {{ getTitle }}
        </div>
        <div
            v-for="section in topicsList"
            :key="section.id"
            class="topics">
            <SectionItem
                :section="topics[section]"
                :last="checkLast(topics[section].id)"
                :type="topics[section].id"
                :personal="personal"
                :settings="settings">
            </SectionItem>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SectionItem from './SectionItem'

import topics from '../../util/topics'
import settingTopics from '../../util/settings'

export default {
	props: {
        personal: {
            type: Boolean
        },
        settings: {
            type: Boolean
        }
    },
	data() {
		return {
            topics: this.personal ? topics : settingTopics,
            topicsList: null,
            last: null
		}
	},
	mounted() {
        this.setTopics()
	},
    methods: {
        setTopics() {
            this.topicsList = Object.keys(this.topics)
            this.last = this.topicsList[this.topicsList.length - 1]
        },
        checkLast(id) {
            return (id == this.last) ? true : false
        }
    },
    computed: {
        getTopics() {

        },
        getTitle() {
            if (this.personal)
                return 'Personal'
            else
                return 'Settings'
        },
        getType() {
            return 
        }
    },
    components: {
        SectionItem
    }
}
</script>
<style lang="scss" scoped>
$font-family: 'Proxima Nova';
.topic {
    margin-left: 40px;
    .topic-title {
        font-size: 12pt;
        font-family: $font-family;
        font-weight: 600;
        margin-top: 20px;
    }
}
</style>