<template>
    
    <svg width="87" height="44" viewBox="0 0 87 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M79.9076 29.9602L86.1204 6.48968C87.019 3.09489 83.3647 0.149635 80.1766 1.62216C71.3116 5.71679 57.1221 11.4023 46.5 11.5C35.3776 11.6023 17.5123 5.08592 6.28248 0.471209C2.80363 -0.958365 -0.777035 2.73031 0.847416 6.12255L12.4916 30.4383C12.8235 31.1314 13.3382 31.7087 14.0021 32.0958C18.1695 34.5261 34.4724 43.5578 46.5 43.5C58.03 43.4446 73.501 34.9986 78.1342 32.3112C79.0189 31.798 79.6459 30.949 79.9076 29.9602Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>