<template>
    
    <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path v-if="getActive" d="M1.75601 16.5003C0.51401 12.2914 0.0381786 8.44084 0.877704 5.59614C1.63894 3.01672 3.70232 1.54714 6.71265 0.697013C7.95028 0.347505 10.0157 0.421819 12.032 0.643548C14.0233 0.862533 15.8808 1.21415 16.6559 1.36996C16.9663 1.43238 17.2814 1.43745 17.5936 1.38509C18.4714 1.23792 20.7227 0.879695 23.1066 0.653407C24.2988 0.540243 25.5178 0.460706 26.6113 0.456208C27.7133 0.451676 28.6548 0.523858 29.3082 0.698683C30.8812 1.11951 31.9619 1.73118 32.722 2.52064C33.4817 3.30973 33.9548 4.31143 34.2524 5.57188C35.0881 9.11026 34.0092 12.2139 32.5449 16.3771L32.5094 16.4781C31.165 20.3009 29.21 24.3419 26.7373 27.4177C24.2591 30.5005 21.3202 32.544 18.0074 32.544C14.6725 32.544 11.1867 30.5003 8.2191 27.4224C5.26078 24.3541 2.88457 20.3248 1.75601 16.5003Z" fill="#FAF5FC" stroke="#833E93" stroke-width="0.912014" stroke-linecap="round"/>
        <path v-else d="M1.31864 16.6294C0.0707211 12.4004 -0.437893 8.44294 0.440346 5.46707C1.264 2.67617 3.50153 1.13 6.58872 0.258169C9.2563 -0.495162 15.1837 0.608868 16.7457 0.922903C17.0017 0.974364 17.2607 0.978538 17.5182 0.935365C19.283 0.639485 26.5717 -0.505489 29.4261 0.258169C32.6848 1.13 34.0758 2.8399 34.6962 5.46707C35.5677 9.15714 34.4307 12.39 32.9751 16.5284L32.9396 16.6294C30.2305 24.3326 25.0344 33 18.0074 33C10.9805 33 3.6197 24.4273 1.31864 16.6294Z" fill="#E2E2E2"/>
    </svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	},
    computed: {
        getColorCode() {
            if(this.color === "#833E93") return "#FAF5FC";
            return "#E2E2E2";
        },
        getActive() {
            if(this.color === "#833E93")
                return true
            return false
        }

    }
}
</script>