<template>

    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <g fill="none" fill-rule="evenodd" :stroke="color" stroke-linecap="round" stroke-width="1.5">
            <path d="M10.446 0C4.677 0 0 4.677 0 10.446c0 5.77 4.677 10.447 10.446 10.447 5.77 0 10.447-4.677 10.447-10.447" transform="translate(1 1)"/>
            <g>
                <path d="M0 9.429L4.256 12.768M4.398 12.646L14.328.973" transform="translate(1 1) translate(6.964)"/>
            </g>
        </g>
    </svg>


</template>
<script>
    export default {
        name: 'completed-icon',

        props: {
            // Color or hex code
            color: {
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>
    // @import "./style.scss";

</style>

