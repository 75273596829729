<template>
<svg width="79" height="132" viewBox="0 0 79 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M77.7353 60.8785C77.7353 73.1294 78.2883 108.886 74.3518 118.436C67.4847 135.096 54.874 131.719 41.3632 131.719C27.8525 131.719 12.2812 135.096 5.41412 118.436C1.47767 108.886 0.761877 73.1294 0.761877 60.8785C0.761877 27.2562 -8.23816 0 39.2486 0C88.7619 0 77.7353 27.2562 77.7353 60.8785Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
