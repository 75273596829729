<template>
    
    <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse opacity="0.9" rx="20.5" ry="21.5" transform="matrix(-1 0 0 1 21 21.5)" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>