<template>
    
    <svg width="65" height="50" viewBox="0 0 65 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.20562 25C1.20562 38.8071 -6.83176 50 31.3458 50C64.5 50 64.5 38.8071 64.5 25C64.5 11.1929 62.4907 0 31.3458 0C0.200943 0 1.20562 11.1929 1.20562 25Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>