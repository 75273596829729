<template>
	<div class="viewer">
		<Header
			v-if="appointment"
			:landing="false"
			:appointment="appointment">
		</Header>
		<template v-if="appointmentContent">
			<router-view
				:summary="summary"
				:appointment="appointment"
				:content="appointmentContent"
				:result="results"
				:selected="selected">
			</router-view>
		</template>
		<template v-else>
			<!-- If the page is still loading -->
			<Loading
				color="#2D3E4F"
				text="Loading appointments...">
			</Loading>
		</template>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Loading from '@/component/Loading'
import Header from '../header/Header'
import Download from './Download'
// Get a reference to the VueX store
import store from '../../../store'

export default {
	name: 'appointment-viewer',

	props: {
		id: {
			type: String
		},
		query: {
			type: Object
		}
	},

	data() {
		return {
			// Mapping of sections to keep track of which topics to show for details
			selected: {},
			results: {},
			input: null
		}
	},
	mounted() {
		// Check if handoff information needs to be saved
		this.handoff(this.query)
		.then(() => {
			// Check if user is already logged in
			if (this.authenticated) {
				this.loadUser()
				return this.loadAppointment({ id: this.id })
			}
			// Not logged in and handoff token is given
			else if (this.handoff_token) {
				var id = this.handoff_token.split(':')[0]
				this.loadEvent({ id, type: 'appointment'}).then((result) => {
					var appointmentData = this.appointment
					var appointmentType = appointmentData.type.split(':') [1]
					return this.loadObject({ type: 'appointment', id: appointmentType })
				})
			}
		})
	},
	methods: {
		...mapActions('user', [
			'loadUser',
			'loadEvent',
			'loadProvider',
			'loadAppointment'
		]),
		...mapActions('content', [
			'loadObject'
		]),
		...mapActions('auth', [
			'handoff'
		]),
		getImage(file) {
			return require('./image/' + file)
		},
		// Update the topic selection from child Section
		onClickSection(key, status) {
			this.selected[key] = status
		},
		submitPassword(input) {
			this.$router.push({ name: 'login' })
		}
	},
	computed: {
		...mapGetters('user', [
			'getEvent',
			'getName',
			'getAppointment'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		...mapState('auth', [
			'authenticated',
			'handoff_token'
		]),
		appointmentContent() {
			var content
			if (this.contentId) {
				content = this.getObject('appointment', this.contentId)
			}
			return content
		},
		contentId() {
			if (this.appointment) {
				let type = this.appointment.type
				if (type.split(':').length > 2)
					return type.split(':') [1]
				else
					return type.substring(type.indexOf(':') + 1)
			}
		},
		summary() {
			if (this.authenticated) {
				return this.getAppointment(this.id)
			}
			else {
				return null
			}
		},
		appointment() {
			return this.getEvent(this.id)
		},
		name() {
			if (this.getName)
				return this.getName.split(' ')[0]
			else
				return null
		}
	},
	components: {
		Header,
		Download,
		Loading
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
