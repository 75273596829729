<template>
    <div>
        <el-radio-group v-model="humanSide" fill="#833e93" text-color="#ffffff" size="small">
            <el-radio-button label="Front"></el-radio-button>
            <el-radio-button label="Back"></el-radio-button>
        </el-radio-group>

        <div class="human-map-wrapper">
            <span :class="humanSideLowerCase + '_left'">LEFT</span>
            <span :class="humanSideLowerCase + '_right'">RIGHT</span>
            <div v-for="bodyPart in humanMap" :key="bodyPart.id">
                <template v-if="bodyPart.side.toLowerCase() === humanSideLowerCase">
                    <div class="tooltip" v-if="selectedParts.filter(bp => bp.id === bodyPart.id).length">
                        <span :ref="bodyPart.id + '_tooltip'" class="tooltiptext">{{ bodyPart.tooltip }}</span>
                    </div>
                    <div 
                        :is="bodyPart.id" 
                        :ref="bodyPart.id"
                        :key="selectedParts.length" 
                        :class="bodyPart.id"  
                        @click.native="toggle(bodyPart.id)" 
                        :color="selectedParts.filter(bp => bp.id === bodyPart.id).length ? '#833E93' : '#CCCCCC'">
                    </div>
                </template>
            </div>
            <img v-if="humanSide === 'Back'" :src="getImage('line')" class="line">
        </div>
        <div class="selected-text">
            <span v-if="calcSelectedList['Front'].length" style="margin-top: 20px; ">Front<br>
                <span v-for="part in calcSelectedList['Front']" :key="part" class="bullet-text">&bull; {{ part }}</span>
            </span>
            <br><br>
            <span v-if="calcSelectedList['Back'].length" style="margin-top: 10px;">Back<br>
                <span v-for="part in calcSelectedList['Back']" :key="part" class="bullet-text">&bull; {{ part }}</span>
            </span>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import { RadioGroup, RadioButton } from 'element-ui'
    import SubQuestion from './index'
    import IconComponents from '../icon/GeneralLocation/components'

    Vue.use(RadioGroup)
    Vue.use(RadioButton)

    const humanMap = [
        { id: 'head_f', side: 'Front', tooltip: 'Head (F)' },
        { id: 'neck_f', side: 'Front', tooltip: 'Neck (F)' },
        { id: 'upper_abdomen', side: 'Front', tooltip: 'Upper Abdomen' },
        { id: 'lower_abdomen', side: 'Front', tooltip: 'Lower Abdomen' },
        { id: 'right_foot_f', side: 'Front', tooltip: 'Right Foot (F)' },
        { id: 'left_foot_f', side: 'Front', tooltip: 'Left Foot (F)' },
        { id: 'right_lower_leg_f', side: 'Front', tooltip: 'Right Lower Leg (F)' },
        { id: 'right_thigh_f', side: 'Front', tooltip: 'Right Thigh (F)' },
        { id: 'right_fingers_f', side: 'Front', tooltip: 'Right Fingers (F)' },
        { id: 'right_hand_f', side: 'Front', tooltip: 'Right Hand (F)' },
        { id: 'left_hand_f', side: 'Front', tooltip: 'Left Hand (F)' },
        { id: 'left_fingers_f', side: 'Front', tooltip: 'Left Fingers (F)' },
        { id: 'left_shoulder_f', side: 'Front', tooltip: 'Left Shoulder (F)' },
        { id: 'right_shoulder_f', side: 'Front', tooltip: 'Right Shoulder (F)' },
        { id: 'right_chest', side: 'Front', tooltip: 'Right Chest' },
        { id: 'left_chest', side: 'Front', tooltip: 'Left Chest' },
        { id: 'right_upper_arm_f', side: 'Front', tooltip: 'Right Upper Arm (F)' },
        { id: 'left_upper_arm_f', side: 'Front', tooltip: 'Left Upper Arm (F)' },
        { id: 'left_flank_f', side: 'Front', tooltip: 'Left Flank (F)' },
        { id: 'right_flank_f', side: 'Front', tooltip: 'Right Flank (F)' },
        { id: 'left_forearm_f', side: 'Front', tooltip: 'Left Forearm (F)' },
        { id: 'right_forearm_f', side: 'Front', tooltip: 'Right Forearm (F)' },
        { id: 'right_pelvic', side: 'Front', tooltip: 'Right Pelvic' },
        { id: 'left_pelvic', side: 'Front', tooltip: 'Left Pelvic' },
        { id: 'left_knee_f', side: 'Front', tooltip: 'Left Knee (F)' },
        { id: 'right_knee_f', side: 'Front', tooltip: 'Right Knee (F)' },
        { id: 'left_lower_leg_f', side: 'Front', tooltip: 'Left Lower Leg (F)' },
        { id: 'left_thigh_f', side: 'Front', tooltip: 'Left Thigh (F)' },

        { id: 'left_lower_leg_b', side: 'Back', tooltip: 'Left Lower Leg (B)' },
        { id: 'right_lower_leg_b', side: 'Back', tooltip: 'Right Lower Leg (B)' },
        { id: 'left_thigh_b', side: 'Back', tooltip: 'Left Thigh (B)' },
        { id: 'right_thigh_b', side: 'Back', tooltip: 'Right Thigh (B)' },
        { id: 'head_b', side: 'Back', tooltip: 'Head (B)' },
        { id: 'right_neck_b', side: 'Back', tooltip: 'Right Neck (B)' },
        { id: 'left_neck_b', side: 'Back', tooltip: 'Left Neck (B)' },
        { id: 'right_buttock', side: 'Back', tooltip: 'Right Buttock' },
        { id: 'left_buttock', side: 'Back', tooltip: 'Left Buttock' },
        { id: 'left_flank_b', side: 'Back', tooltip: 'Left Flank (B)' },
        { id: 'right_flank_b', side: 'Back', tooltip: 'Right Flank (B)' },
        { id: 'right_lower_back', side: 'Back', tooltip: 'Right Lower Back' },
        { id: 'left_lower_back', side: 'Back', tooltip: 'Left Lower Back' },
        { id: 'left_knee_b', side: 'Back', tooltip: 'Left Knee (B)' },
        { id: 'right_knee_b', side: 'Back', tooltip: 'Right Knee (B)' },
        { id: 'right_foot_b', side: 'Back', tooltip: 'Right Foot (B)' },
        { id: 'left_foot_b', side: 'Back', tooltip: 'Left Foot (B)' },
        { id: 'right_upper_back', side: 'Back', tooltip: 'Right Upper Back' },
        { id: 'left_upper_back', side: 'Back', tooltip: 'Left Upper Back' },
        { id: 'right_mid_back', side: 'Back', tooltip: 'Right Mid Back' },
        { id: 'left_mid_back', side: 'Back', tooltip: 'Left Mid Back' },
        { id: 'left_forearm_b', side: 'Back', tooltip: 'Left Forearm (B)' },
        { id: 'right_forearm_b', side: 'Back', tooltip: 'Right Forearm (B)' },
        { id: 'left_fingers_b', side: 'Back', tooltip: 'Left Fingers (B)' },
        { id: 'right_fingers_b', side: 'Back', tooltip: 'Right Fingers (B)' },
        { id: 'right_hand_b', side: 'Back', tooltip: 'Right Hand (B)' },
        { id: 'left_hand_b', side: 'Back', tooltip: 'Left Hand (B)' },
        { id: 'left_shoulder_b', side: 'Back', tooltip: 'Left Shoulder (B)' },
        { id: 'right_shoulder_b', side: 'Back', tooltip: 'Right Shoulder (B)' },
        { id: 'right_upper_arm_b', side: 'Back', tooltip: 'Right Upper Arm (B)' },
        { id: 'left_upper_arm_b', side: 'Back', tooltip: 'Left Upper Arm (B)' },
        { id: 'right_elbow', side: 'Back', tooltip: 'Right Elbow' },
        { id: 'left_elbow', side: 'Back', tooltip: 'Left Elbow' },
        // { id: 'right_lower_arm_b', side: 'Back', tooltip: 'Throat', image: 'right_lower_arm_b' },
        // { id: 'left_lower_arm_b', side: 'Back', tooltip: 'Throat', image: 'left_lower_arm_b' },
    ]

    export default {
        name: 'general_location',
        extends: SubQuestion,
        watch: {
            humanSide: function() {
                this.$nextTick(() => this.removeTooltips())
                this.$emit('changeHumanMapSide', this.humanSide)
            }
        },

        props: {
            saved: {
                type: Array
            }
        },

        data() {
            return {
                humanMap: JSON.parse(JSON.stringify(humanMap)),
                humanSide: 'Front',
                selectedParts: [] || this.saved,
                unsortedBodyParts: [] || this.saved
            }
        },

        mounted() {
            this.loadData()
            this.$emit('changeHumanMapSide', this.humanSide)
        },

        methods: {
            /**
             * @func placeTooltip
             * @desc places tooltip on body part selection
             * @param bodyPart(String) string indicating if the body part is active or inactive
             */
            placeTooltip(bodyPart) {
                let imgRef = this.$refs[bodyPart][0].$el
                let tooltipRef = this.$refs[bodyPart + '_tooltip'][0]
                tooltipRef.style.visibility = 'visible'

                let top = parseInt(window.getComputedStyle(imgRef, null).getPropertyValue('top').replace('px', ''))
                let left = parseInt(window.getComputedStyle(imgRef, null).getPropertyValue('left').replace('px', ''))

                tooltipRef.style.top = (top + 80) + 'px'
                // for body parts on right side of the screen
                if ((this.humanSide === 'Front' && bodyPart.includes('left')) || (this.humanSide === 'Back' && bodyPart.includes('right'))) {
                    tooltipRef.style.left = (left - 90) + 'px'
                }
                // for body parts on left side of the screen
                else if ((this.humanSide === 'Front' && bodyPart.includes('right')) || (this.humanSide === 'Back' && bodyPart.includes('left'))) {
                    tooltipRef.style.left = (left - 30) + 'px'
                }
                // for head
                else if(bodyPart.includes('head') || bodyPart.includes('neck') || bodyPart.includes('throat') || bodyPart.includes('knee') || bodyPart.includes('abdomen')) {
                    tooltipRef.style.top = (top + 50) + 'px'
                    tooltipRef.style.left = (left) + 'px'
                }
                // for middle body parts
                else tooltipRef.style.left = (left + 10) + 'px'
            },
            /**
             * @func removeTooltips
             * @desc removes all tooltips
             * @param tooltipText(String)
             */
            removeTooltips(tooltipText) {
                if (document.getElementsByClassName('tooltiptext')) {
                    let classes = document.getElementsByClassName('tooltiptext')
                    Object.keys(classes).forEach(cls => {
                        if (!tooltipText || (tooltipText && classes[cls].innerHTML.trim() === tooltipText))
                            classes[cls].style.visibility = 'hidden'
                    })
                }
            },
            loadData() {
                if (!this.saved) this.$emit('selectbp', [])
                else {
                    this.unsortedBodyParts = this.saved
                    this.selectedParts = this.saved
                    this.sortBodyParts()
                    this.$emit('selectbp', this.saved)
                }
                this.$nextTick(() => this.removeTooltips())
            },
            /**
             * @func toggle
             * @desc toggles the state of body part
             * @param bodyPart(String) string indicating if the body part is active or inactive
             */
            toggle(bodyPart) {
                // making inactive
                if (this.selectedParts.filter(bp => bp.id === bodyPart).length) {
                    this.selectedParts = this.selectedParts.filter(part => part.id !== bodyPart)
                    this.unsortedBodyParts = this.unsortedBodyParts.filter(part => part.id !== bodyPart)
                }
                // making active
                else {
                    if (this.limitReached(this.humanSide)) 
                        return

                    let tooltipText = ''
                    let reqObj = {
                        id: bodyPart,
                        value: bodyPart
                    }

                    this.selectedParts.push(reqObj)
                    this.unsortedBodyParts.push(reqObj)

                    tooltipText = this.humanMap.filter(bp => bp.id === bodyPart)[0].tooltip
                    // placing tooltip on top of the body part
                    this.$nextTick(() => this.placeTooltip(bodyPart))
                    // remove tooltip after 1.5seconds
                    setTimeout(() => this.removeTooltips(tooltipText), 1500)
                }
                // sort
                this.sortBodyParts()
                this.$emit('selectbp', this.selectedParts)
            },
            sortBodyParts() {
                let tempSelectedParts = []
                this.humanMap.forEach(bp => {
                    this.selectedParts.forEach(sp => {
                        if (sp.value === bp.id) tempSelectedParts.push(sp)
                    })
                })
                this.selectedParts = tempSelectedParts
            },
            getImage(bodyPart) {
                return require('../icon/GeneralLocation/' + bodyPart + '.svg')
            }
        },

        computed: {
            humanSideLowerCase() {
                return this.humanSide.toLowerCase()
            },
            calcSelectedList() {
                let selectedList = { 'Front': [], 'Back': [] }
                this.unsortedBodyParts.forEach(part => {
                    let partObj = this.humanMap.filter(bodyPart => part.value === bodyPart.id)[0]
                    selectedList[partObj.side].push(partObj.tooltip)
                })
                return selectedList
            }
        },

        components: {
            ...IconComponents
        },
    }
</script>

<style lang="scss" scoped>
    $choice-size: 150px;
    $choice-font-size: 20px;
    @import "style.scss";

    .head_f {
        position: absolute;
        top: 64px;
        right: 129px;
    }
    .neck_f {
        position: absolute;
        top: 136px;
        right: 118px;
    }
    .upper_abdomen {
        position: absolute;
        top: 284px;
        right: 137px;
    }
    .lower_abdomen {
        position: absolute;
        top: 315px;
        right: 137px;
        z-index: 102;
    }
    .right_foot_f {
        position: absolute;
        top: 621px;
        right: 187px;
    }
    .left_foot_f {
        position: absolute;
        top: 621px;
        right: 88px;
    }
    .right_lower_leg_f {
        position: absolute;
        top: 494px;
        right: 176px;
    }
    .right_thigh_f {
        position: absolute;
        top: 346px;
        right: 166px;
    }
    .right_fingers_f {
        position: absolute;
        top: 355px;
        right: 282px;
    }
    .right_hand_f {
        position: absolute;
        top: 350px;
        right: 276px;
        z-index: 101;
    }
    .left_hand_f {
        position: absolute;
        top: 350px;
        right: 13px;
        z-index: 101;
    }
    .left_fingers_f {
        position: absolute;
        top: 355px;
        right: -3px;
    }
    .left_shoulder_f {
        position: absolute;
        top: 170px;
        right: 73px;
    }
    .right_shoulder_f {
        position: absolute;
        top: 170px;
        right: 203px;
    }
    .right_chest {
        position: absolute;
        top: 177px;
        right: 163px;
    }
    .left_chest {
        position: absolute;
        top: 177px;
        right: 103px;
    }
    .right_upper_arm_f {
        position: absolute;
        top: 208px;
        right: 223px;
    }
    .left_upper_arm_f {
        position: absolute;
        top: 208px;
        right: 61px;
    }
    .left_flank_f {
        position: absolute;
        top: 277px;
        right: 112px;
    }
    .right_flank_f {
        position: absolute;
        top: 277px;
        right: 188px;
    }
    .left_forearm_f {
        position: absolute;
        top: 262px;
        right: 29px;
    }
    .right_forearm_f {
        position: absolute;
        top: 262px;
        right: 236px;
    }
    .right_pelvic {
        position: absolute;
        top: 330px;
        right: 163px;
        z-index: 101;
    }
    .left_pelvic {
        position: absolute;
        top: 330px;
        right: 108px;
        z-index: 101;
    }
    .left_knee_f {
        position: absolute;
        top: 475px;
        right: 108px;
        z-index: 101;
    }
    .right_knee_f {
        position: absolute;
        top: 475px;
        right: 181px;
        z-index: 101;
    }
    .left_lower_leg_f {
        position: absolute;
        top: 494px;
        right: 102px;
    }
    .left_thigh_f {
        position: absolute;
        top: 346px;
        right: 96px;
    }


    
    
    
    .head_b {
        position: absolute;
        top: 64px;
        right: 129px;
    }
    .left_neck_b {
        position: absolute;
        top: 129px;
        right: 164px;
    }
    .right_neck_b {
        position: absolute;
        top: 130px;
        right: 118px;
    }
    .right_foot_b {
        position: absolute;
        top: 647px;
        right: 109px;
    }
    .left_foot_b {
        position: absolute;
        top: 647px;
        right: 181px;
    }
    .right_lower_leg_b {
        position: absolute;
        top: 534px;
        right: 105px;
    }
    .right_thigh_b {
        position: absolute;
        top: 415px;
        right: 96px;
    }
    .right_fingers_b {
        position: absolute;
        top: 355px;
        right: -7px;
    }
    .right_hand_b {
        position: absolute;
        top: 350px;
        right: 9px;
        z-index: 101;
    }
    .left_hand_b {
        position: absolute;
        top: 351px;
        right: 275px;
        z-index: 101;
    }
    .left_fingers_b {
        position: absolute;
        top: 356px;
        right: 281px;
    }
    .left_shoulder_b {
        position: absolute;
        top: 170px;
        right: 202px;
    }
    .right_shoulder_b {
        position: absolute;
        top: 170px;
        right: 71px;
    }
    .right_upper_arm_b {
        position: absolute;
        top: 209px;
        right: 57px;
    }
    .left_upper_arm_b {
        position: absolute;
        top: 210px;
        right: 223px;
    }
    .left_flank_b {
        position: absolute;
        top: 296px;
        right: 189px;
    }
    .right_flank_b {
        position: absolute;
        top: 298px;
        right: 106px;
    }
    .left_forearm_b {
        position: absolute;
        top: 263px;
        right: 237px;
    }
    .right_forearm_b {
        position: absolute;
        top: 262px;
        right: 25px;
    }
    .right_buttock {
        position: absolute;
        top: 358px;
        right: 96px;
    }
    .left_buttock {
        position: absolute;
        top: 357px;
        right: 164px;
    }
    .left_knee_b {
        position: absolute;
        top: 493px;
        right: 176px;
    }
    .right_knee_b {
        position: absolute;
        top: 494px;
        right: 105px;
    }
    .left_lower_leg_b {
        position: absolute;
        top: 534px;
        right: 178px;
    }
    .left_thigh_b {
        position: absolute;
        top: 414px;
        right: 167px;
    }
    .right_lower_back {
        position: absolute;
        top: 308px;
        right: 136px;
    }
    .left_lower_back {
        position: absolute;
        top: 306px;
        right: 165px;
    }
    .right_upper_back {
        position: absolute;
        top: 178px;
        right: 99px;
    }
    .left_upper_back {
        position: absolute;
        top: 177px;
        right: 164px;
    }
    .right_mid_back {
        position: absolute;
        top: 252px;
        right: 99px;
    }
    .left_mid_back {
        position: absolute;
        top: 251px;
        right: 164px;
    }
    .left_elbow {
        position: absolute;
        top: 261px;
        right: 240px;
        z-index: 101;
    }
    .right_elbow {
        position: absolute;
        top: 260px;
        right: 57px;
        z-index: 101;
    }
</style>
