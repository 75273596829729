<template>
<svg width="42" height="31" viewBox="0 0 42 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.495422 0.666885H28.6097C28.6097 0.666885 29.0002 8.08593 33.2954 11.6002C37.5907 15.1145 41.8859 14.724 41.8859 14.724V29.9526C41.8859 29.9526 26.6625 31.899 14.7684 23.3352C2.87427 14.7715 0.495422 0.666885 0.495422 0.666885Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_lower_inner_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
