<template>
	<TopicTemplate 
		:name="name" 
		:topicImage="topicImage" 
		:title="title"
		:appointment="appointment"
		:summary="summary"
		:content="content"
		:result="result"
		:selected="selected">
	</TopicTemplate>
</template>
<script>

import AppointmentView from '../../mixin/appointment-view'
import TopicTemplate from './template/TopicTemplate'

export default {
	name: 'appointment-symptom',
	data() {
		return {
			name: 'symptom_question',
			topicImage: 'symptom.png',
			title: 'Symptoms'
		}
	},
	mixins: [ AppointmentView ],
	mounted() {
	},
	components: {
		TopicTemplate
	}
}
</script>

<style lang="scss" scoped>
</style>