<template>
    
    <svg width="21" height="72" viewBox="0 0 21 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.01077 57.0489C3.03356 55.7761 3.04595 54.4277 3.04598 53C3.04603 51.2881 -0.361753 39.0759 0.0455183 32.5C0.449351 25.9796 3.93688 16.5 6.04296 10.5C6.40385 9.2008 6.69184 7.99537 6.92046 6.88372C7.78533 2.6784 12.8533 -1.02734 16.8778 0.468165C19.2801 1.36087 20.7296 3.80808 20.3552 6.34339C19.3206 13.3495 17.3268 27.014 16.5448 33.5C15.236 44.3561 18.5 47 18.5 62C18.5 64.2836 18.0954 66.2079 17.4095 67.8295C13.6664 76.6784 2.83877 66.6554 3.01077 57.0489Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>