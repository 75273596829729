<template>
<svg width="42" height="67" viewBox="0 0 42 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8749 17.8243L41.1473 0.0400391L40.2353 37.4326C40.2353 37.4326 37.6655 46.5178 35.2192 52.0248C32.5649 58.0001 27.0111 66.617 27.0111 66.617C27.0111 66.617 23.4931 53.2777 18 50.7061C11.7017 47.7575 0.106689 52.0248 0.106689 52.0248C0.106689 52.0248 2.49807 40.6903 5.12277 33.7845C7.58455 27.3074 12.8749 17.8243 12.8749 17.8243Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_upper_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
