<template>
<svg width="59" height="94" viewBox="0 0 59 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.2409 20.2821C35.0645 12.979 28.2088 0.673828 28.2088 0.673828C28.2088 0.673828 26.5796 15.2061 18.5796 18.7061C11.3652 21.8624 0.0795898 15.2061 0.0795898 15.2061C0.0795898 15.2061 2.04239 27.5905 4.26841 33.7343C6.49443 39.8782 6.865 42.5802 9.51249 48.5546C16.2161 63.682 25.7402 69.6141 33.6809 84.5791C35.5473 88.0966 38.2409 93.6992 38.2409 93.6992C38.2409 93.6992 43.4701 89.6805 47.3611 88.2272C51.5847 86.6496 58.7612 86.4031 58.7612 86.4031C58.7612 86.4031 54.9696 79.2622 52.8331 74.547C43.7765 54.5584 46.9935 40.4057 38.2409 20.2821Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_forearm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
