<template>
    
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.36877 13.6634C3.22139 12.9439 1.53181 6.85721 0.853197 2.57936C0.587963 0.907377 2.49689 -0.151692 4.00089 0.625373C5.09473 1.19052 6.29217 1.68 7.36877 1.8338C9.29016 2.10828 11.6055 1.42486 13.5669 0.592919C15.2036 -0.101328 17.108 1.29421 16.5615 2.98602C15.0131 7.77942 11.9956 14.4662 7.36877 13.6634Z" :fill="color"/>
</svg>

</template>


<script>
export default {
	name: 'under_ear_s',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
