<template>
	<el-row class="box-select">
		<!-- Iterate through choices -->
		<template v-if="question.hasOwnProperty('options')">
			<el-col :span="12" v-for="(option) in question.options" :key="option.id"
				:class="['choice', 'with-box-text', { selected: isSelected(option) }]" @click.native="select(option)">
				<p class="select-text">{{ option.value }}</p>
			</el-col>
		</template>
		<el-col :span="12" v-else v-for="choice in max" :key="choice"
			v-bind:class="['choice', 'with-box-text',
				{ selected: isSelected(getSelectText(choice)) }]"
			@click.native="select(getSelectText(choice))">
			<p class="select-text">{{ getSelectText(choice) }}</p>
		</el-col>
		<el-col :span="24" v-show="question.other" class="option-other">
			<input ref="other" :value="other" @input="other = $event.target.value" placeholder="Other" v-on:keyup.13="selectOther">
			<button :class="[other.trim().length ? 'btn-active' : '']" @click="selectOther">ADD</button>
			<span class="character-count" :style="charactersLeft <= 0 ? 'color: #da4e4e' : ''">
				{{ charactersLeft }} character{{ charactersLeft > 1 ? 's' : '' }}
			</span>
		</el-col>
	</el-row>
</template>


<script>
import Question from './index'
import ImageSelect from './mixin/image-select'
import { v4 as uuidv4 } from 'uuid'
export default {
	name: 'box-select',
	extends: Question,
	mixins: [ ImageSelect ],

	data() {
		return {
			other: '',
			defaultLimit: 100
		}
	},

	watch: {
		other(value) {
			if (value.length > this.defaultLimit)
				this.other = this.other.substr(0, this.defaultLimit)
		}
	},

	mounted() {
		this.$emit('selected', this.saved)
	},


	methods: {
		selectOther() {
			this.other = this.other.trim()
			if (this.other !== '') {
				if (this.question.hasOwnProperty('options')) {
					if (this.question.options.filter(option => option.value === this.other).length)
					return
				}
				else if (this.question.option.filter(option => option === this.other).length)
					return

				let reqObj = {
					version: 2, // version indicating that this is qv2 format
					value: this.other,
					id: uuidv4()
				}
				

				if (this.question.hasOwnProperty('options'))
					this.question.options.push(reqObj)
				else 
					this.question.option.push(this.other)
				this.select(reqObj)
			}
			this.other = ''
		}
	},

	computed: {
		charactersLeft() {
			return this.defaultLimit - this.other.length
		}
	}
}
</script>

<style lang="scss" scoped>
// $choice-size: 115px;
// $choice-font-size: 20px;
// @import "style.scss";

$choice-size: 50px;
$choice-font-size: 14px;

@import "style.scss";

$background: #eeeeee;
$choice-border: darken($background, 5%);
$background-hover: lighten($background, 5%);


.choice {
	margin-right: 5px;
	min-height: 100px;
	height: auto;
	width: 110px;//150px;
	display: inline-block;
	justify-content: center;
	padding: 5px;

	p {
		cursor: pointer;
		white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
		white-space: -webkit-pre-wrap;          /* Chrome & Safari */
		white-space: -pre-wrap;                 /* Opera 4-6 */
		white-space: -o-pre-wrap;               /* Opera 7 */
		white-space: pre-wrap;                  /* CSS3 */
		word-wrap: break-word;                  /* Internet Explorer 5.5+ */
		word-break: break-word;
		white-space: normal;
	}

    .select-text {
        font-size: 15px;
        line-height: 20px;
        align-self: center;
		margin-top: 20px;
		margin-bottom: 20px;
    }
}
	.box-select {
		// display: grid;
		// grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
		// //grid-gap: 5px;
		// max-width: 960px;
		// margin: 0 auto 10px;
		// text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.option-other {
		input {
			width: 80%;
			float: left;
			border: 1px solid #999999;
			outline: none;
			height: 40px;
			line-height: 40px;
			font-size: $choice-font-size;
			padding-left: 10px;
		}
		input:focus {
			outline: none;
		}
		.btn-active {
			background-color: $selected-color;
			color: #ffffff;
		}
		button {
			background-color: #ffffff;
			width: 20%;
			height: 40px;
			line-height: 40px;
			border: 1px solid #999999;
			border-left: none;
			font-size: $choice-font-size;
			color: #999999;
			padding: 0;
		}
		button:focus {
			outline:0;
		}
		.character-count {
			float: right;
			color: #666666;
			font-size: 13px;
			margin-top: 7px;
		}
	}
</style>
