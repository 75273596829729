<template>
    <svg width="53" height="63" viewBox="0 0 53 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 47.6161V37.6542C0 36.8173 0 36.3989 0.0616813 35.9373C0.123362 35.4758 0.220485 35.1189 0.41473 34.4051L0.414739 34.4051C1.53515 30.2879 4.85441 19.2913 9.5 14.5C15.2424 8.57742 36.5664 2.20042 41.4838 0.787556L41.484 0.787497C41.5603 0.765593 41.5984 0.754626 41.6598 0.738205C42.1871 0.597003 42.7974 0.511064 43.3433 0.501153C43.4068 0.5 43.4606 0.5 43.5683 0.5C44.8685 0.5 45.5186 0.5 45.9794 0.561468C50.3874 1.14952 53.1369 5.64537 51.652 9.83726C51.4968 10.2754 51.2007 10.8541 50.6084 12.0113L50.6083 12.0115L28.8648 54.495C26.5249 59.0667 25.355 61.3526 23.2217 62.373C21.0885 63.3934 18.5746 62.8697 13.5469 61.8223L11.1446 61.3218C5.80674 60.2097 3.13779 59.6537 1.5689 57.7243C0 55.7948 0 53.0686 0 47.6161Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_shoulder_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>