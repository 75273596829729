<template>
<svg width="106" height="41" viewBox="0 0 106 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M105.886 20.8953C105.886 36.9048 82.2847 40.8096 53.1714 40.8096C24.0581 40.8096 0.457092 38.8572 0.457092 20.8953C0.457092 0.980995 24.0581 0.980995 53.1714 0.980995C82.2847 0.980995 105.886 2.578 105.886 20.8953Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'under_collarbone',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
