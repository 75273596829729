<template>
<svg width="49" height="54" viewBox="0 0 49 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4322 34.783C18.5072 29.6642 17.9795 25.4722 14.592 20.647C9.86002 13.9067 -9.53674e-05 5.92809 -9.53674e-05 5.92809L4.55989 0.126697C4.55989 0.126697 12.1544 -0.36287 16.8721 0.582704C25.6235 2.33676 30.5575 5.28403 36.9364 11.5269C42.8734 17.3372 47.4245 26.5751 47.8806 29.7671C48.3366 32.9592 47.8806 53.4795 47.8806 53.4795C47.8806 53.4795 25.0803 41.1671 21.4322 34.783Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_shoulder_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
