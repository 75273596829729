<template>
    
    <svg width="44" height="74" viewBox="0 0 44 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M44.0005 37C44.0005 57.4345 34.1508 74 22.0005 74C9.85022 74 0.000488281 57.4345 0.000488281 37C0.000488281 16.5655 0.000488281 0 22.0005 0C44.0005 0 44.0005 16.5655 44.0005 37Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>