<template>
    
    <svg width="116" height="138" viewBox="0 0 116 138" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.9941 0.999627C21.1595 2.11389 4.43164 6.49974 3.49414 8.49963C2.55664 10.4995 1.05664 11.9995 1.05664 24.4995C1.05664 36.9995 3.49414 37.9995 4.49414 41.9996C5.48637 45.9686 -0.175629 87.6626 0.966049 117.305C0.983521 117.759 1.15669 118.186 1.45507 118.528L17.3497 136.76C17.7562 137.227 18.3381 137.48 18.9556 137.442C24.0311 137.133 48.0757 135.606 63.9941 133.5C79.1203 131.498 101.269 127.073 107.381 125.83C108.325 125.638 108.98 124.803 108.967 123.839C108.938 121.663 108.906 117.796 108.994 115C109.461 100.206 113.285 92.2515 114.494 77.4996C116.041 58.6225 114.494 47.9401 114.494 28.9996V17.8708C114.494 17.0428 113.989 16.306 113.214 16.0142C109.526 14.6253 98.6498 10.6007 90.9941 8.49963C80.6172 5.65166 74.6543 4.46694 63.9941 2.99963C51.976 1.34541 45.0721 -0.13755 32.9941 0.999627Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'palm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>