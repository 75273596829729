<template>
    <svg width="44" height="57" viewBox="0 0 44 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M5.29797 48.225C5.0617 47.7792 5.1236 47.25 5.34924 46.7986L10.9975 35.5008L15.1083 25.6373C15.5777 24.5111 16.9373 24.064 17.9835 24.6918L19.2946 25.4786C20.2372 26.0442 20.5466 27.2646 19.9874 28.211L8.23484 48.0991C8.07869 48.3633 7.86573 48.5917 7.59115 48.7288C7.15739 48.9454 6.46893 49.2095 5.9971 49.0007C5.70075 48.8696 5.46553 48.5412 5.29797 48.225Z" :fill="color"/>
<path opacity="0.9" d="M3.10559 26.119C2.62799 25.5042 2.85212 24.6478 3.40254 24.0974L10.7089 16.7903C10.9003 16.5989 11.0509 16.3705 11.1515 16.1191L14 9V9C14.6485 7.57375 16.7735 7.96678 16.869 9.53064L17.0624 12.6982C17.0939 13.2129 16.9253 13.7198 16.592 14.1132L6.2706 26.293C5.69457 26.9728 4.73093 27.2019 3.95224 26.7689C3.79204 26.6798 3.6371 26.5887 3.50034 26.5003C3.35697 26.4077 3.22431 26.2718 3.10559 26.119Z" :fill="color"/>
<path opacity="0.9" d="M13.1663 54.8459C12.7112 54.35 12.6571 53.6267 12.9193 53.0069L17.9978 41.0001L23.5147 28.5875C23.8086 27.9262 24.4645 27.5 25.1882 27.5V27.5C26.5113 27.5001 27.3977 28.8601 26.8636 30.0706L16.3353 53.9329C16.2435 54.1408 16.118 54.3322 15.9528 54.4883C15.5085 54.9079 14.5613 55.7086 13.9977 55.5C13.7272 55.3998 13.4267 55.1298 13.1663 54.8459Z" :fill="color"/>
<path opacity="0.9" d="M24.1436 55.1692C23.6775 54.8064 23.5843 54.1703 23.7326 53.5985L26.9989 41.0002L30.1192 30.2978C30.3434 29.529 31.0481 29.0005 31.8489 29.0005V29.0005C33.064 29.0005 33.9306 30.1787 33.5687 31.3387L26.5582 53.8094C26.5186 53.9364 26.4672 54.0587 26.3975 54.172C26.1386 54.5926 25.4283 55.6312 24.7975 55.5004C24.5634 55.4519 24.3386 55.3209 24.1436 55.1692Z" :fill="color"/>
<path opacity="0.9" d="M35.255 50.4353C34.721 50.2656 34.5362 49.6826 34.5981 49.1257L35.501 40.9997L36.4262 31.2757C36.4737 30.7766 36.7069 30.3136 37.0795 29.9783L37.1431 29.9211C38.5614 28.645 40.791 29.8904 40.4482 31.7673L37.364 48.6536C37.3196 48.8964 37.2322 49.1298 37.088 49.3301C36.7517 49.7974 36.0702 50.6176 35.502 50.4998C35.4135 50.4814 35.3312 50.4595 35.255 50.4353Z" :fill="color"/>

</svg>

</template>

<script>
export default {
	name: 'right_fingers_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>