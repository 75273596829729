<template>
    
    <svg width="103" height="96" viewBox="0 0 103 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.17167 46.168C3.08485 36.9701 13.3142 19.5765 20.1409 8.67535C22.8929 4.28082 24.2689 2.08355 26.2277 1.21527C28.1864 0.346987 31.2849 0.900595 37.4817 2.00781C41.0844 2.65152 45.4115 3.14231 50.231 3.14231C55.0422 3.14231 59.2762 2.65319 62.7615 2.0111C68.8187 0.89521 71.8472 0.337266 73.7255 1.08844C75.6037 1.83961 77.0492 3.83168 79.9401 7.81582C87.7352 18.5585 99.9098 36.4723 101.939 46.168C104.529 58.5387 101.832 73.5324 99.6499 82.4466C98.7714 86.0349 98.3321 87.829 96.8129 89.2519C95.2936 90.6747 93.3848 91.0114 89.5671 91.6847C79.7173 93.4219 62.6728 96.0581 50.231 95.999C37.9808 95.9409 22.189 93.4881 12.6995 91.7919C8.70281 91.0775 6.70448 90.7203 5.16136 89.2057C3.61824 87.6911 3.24001 85.7817 2.48356 81.9628C0.702056 72.9689 -1.33904 58.2386 1.17167 46.168Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'upper_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>