<template>
<svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.0466 13.4716L27.8976 0.246142L30.1777 11.6591C32.5635 13.722 37.5971 18.1926 42.0339 19.4114C46.4707 20.6301 46.5886 15.8058 46.0466 13.4716Z" :fill="color"/>
<path d="M37.5876 51.7071L31.9088 29.1453L30.5236 24.0862L23.3376 27.2278L22.4256 27.6265L14.6734 31.0155L13.7614 31.4143L6.70166 34.5006L5.55331 35.0026L0.537235 37.1955L6.70166 55.416C6.70166 55.416 8.48266 56.3588 9.36757 55.7812C10.1616 55.263 9.91627 53.4401 9.91627 53.4401L5.55331 35.0026L6.70166 34.5006L16.2974 61.9714C16.2974 61.9714 18.3606 64.0883 19.6983 63.5084C20.9285 62.9752 21.0329 60.3831 21.0329 60.3831L13.7614 31.4143L14.6734 31.0155L25.793 62.6908C25.793 62.6908 27.8197 64.7176 29.1939 64.2278C30.729 63.6806 30.5236 60.3233 30.5236 60.3233L22.4256 27.6265L23.3376 27.2278L32.2935 54.0782C32.2935 54.0782 34.7095 56.4071 36.2578 55.6116C37.7053 54.8679 37.5876 51.7071 37.5876 51.7071Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_fingers_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
