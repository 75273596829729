<template>
    
    <svg width="26" height="112" viewBox="0 0 26 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4941 53L25.2944 109.529C25.3897 110.947 24.0146 112.007 22.6499 111.61C15.5394 109.54 10.275 109.3 2.38962 110.581C1.14732 110.782 0.00422859 109.83 0.0150776 108.571L0.494141 53V8.55877C0.494141 8.5196 0.49321 8.48606 0.491915 8.44691C0.468199 7.73031 0.411293 0 8.99414 0C17.4987 0 17.9669 7.58995 17.9926 8.42556C17.9943 8.47787 17.9962 8.52612 18.0003 8.57829L21.4941 53Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'middle_finger_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>