<template>
<svg width="63" height="71" viewBox="0 0 63 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M55.0266 7.06876C55.0266 7.06876 44.5404 2.11192 26.0266 1.07319C18.6951 0.661849 9.51059 1.91675 1 3V47C1 47 -0.751549 72.2796 32.8196 70.78C32.8196 70.78 54.9198 71.2805 62.5266 52.4059L55.0266 7.06876Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_buttock',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
