<template>
    <div class="text multi-question" v-if="answerObj.length" ref="multi" >

        <div v-for="(subQuestion, index) in question.questions" :key="subQuestion.id" class="question-block"
            :ref="'multi-question-' + index">
            <span class="question-title"><span v-if="isSubQMandatory[index]" class="mandatory-indicator">*</span> {{ subQuestion.question }}</span>
            <br>
            <span class="question-text">
                {{ subQuestion.subheader }}
            </span>
            <template v-if="subQuestion.type === 'text'">
                <textarea :value="answers[index]" @input="onInput($event, index)"
                      :ref="'text-area-' + index" placeholder="Start typing..">
                </textarea>
                <span v-if="subQuestion.hasOwnProperty('validations') && subQuestion.validations.length" class="character-count"
                    :style="characterLimit[index] - answers[index].length <= 0 ? 'color: #da4e4e' : ''">
                    {{ characterLimit[index] - answers[index].length }}
                </span>
                <span v-if="showError(index)" class="mandatory-alert">
                    Enter your answer
                </span>
            </template>
            <template v-else-if="subQuestion.type === 'date'">
                <Date :question="subQuestion"
                    :saved="getSavedAnswer(subQuestion.id)"
                    v-on:selected="selectAnswer($event, index)" />
                <span v-if="showError(index)" class="mandatory-alert">
                    Enter a valid date
                </span>
            </template>
            <template v-else-if="subQuestion.type === 'select'">
                <MultiSelect :question="subQuestion"
                    :saved="getSavedAnswer(subQuestion.id)"
                    v-on:selected="selectAnswer($event, index)"
                    @scroll-into="$emit('scroll-into')" />
                <span v-if="showError(index)" class="mandatory-alert">
                    Choose your answer
                </span>
            </template>
            <template v-else-if="subQuestion.type === 'scroller'">
                <MultiScroller :question="subQuestion"
                    :saved="getSavedAnswer(subQuestion.id)"
                    v-on:selected="selectAnswer($event, index)" />
                <span v-if="showError(index)" class="mandatory-alert">
                    Select your answer
                </span>
            </template>
            <template v-else-if="subQuestion.type === 'unit'">
                <MultiUnit :question="subQuestion"
                    :saved="getSavedAnswer(subQuestion.id)"
                    v-on:selected="selectAnswer($event, index)" 
                     />
                <span v-if="showError(index)" class="mandatory-alert">
                    Please fill both fields to answer
                </span>
            </template>
            <template v-else-if="subQuestion.type === 'query'">
                <Query :question="subQuestion"
                    :saved="getSavedAnswer(subQuestion.id)"
                    v-on:selected="selectAnswer($event, index)" 
                     />
            </template>
        </div>
        

        <template v-if="isQuestionMandatory">
            <span class="mandatory-indicator" style="margin-left: 15px;">*</span><span class="mandatory-text">Mandatory questions</span>
        </template>
        <div v-if="isScrollingNeeded && showArrow" class="scroll-down" @click="scrollDown">
            <DownIcon ></DownIcon>
        </div>


    </div>


</template>

<script>
    import Vue from 'vue'
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'

    import Date from './Date.vue'
    import MultiSelect from './MultiSelect.vue'
    import MultiScroller from './MultiScroller.vue'
    import MultiUnit from './MultiUnit.vue'
    import Query from './Query.vue'
    import DownIcon from './icon/Down.vue'
    import question from './index'


    export default {
        name: 'multi-question',
        extends: Question,
        props: {
            saved: {
                type: Array
            },
            showNotice: {
                type: Boolean
            },
            showArrow: {
                type: Boolean
            }
        },
        data() {
            return {
                answerObj: [],
                answers: [],
                date: null,
                options: {
                    date: true,
                    delimiter: '/',
                    datePattern: ['m', 'd', 'Y']
                },
                isScrollingNeeded: false
            }
        },
        watch: {
            answers: function() {
                this.answers.forEach((ans, index) => {
                    if (this.question.questions[index].hasOwnProperty('validations') &&
                        this.question.questions[index].validations.length &&
                        this.question.questions[index].type ===  'text' &&
                        ans.length > this.characterLimit[index]) {
                        ans = ans.substr(0, this.characterLimit[index])
                    }
                    let ansObj
                    if(this.question.questions[index].type === 'text')
                        ansObj = [{
                            'id': uuidv4(),
                            'answer': ans
                        }]
                    else
                        ansObj = ans
                    this.answerObj[index].answer = ansObj
                    this.answerObj[index].version = 2
                })
            },
            answerObj: {
               deep: true,
               handler: function () {
                   this.$emit('selected', this.removeEmptyObjects(this.answerObj))
               }
            }
        },
        mounted() {
            // filling default values
            this.answerObj = this.question.questions.map(q => {
                return { 'id': q.id, 'answer': [] }
            }) || []
            if (!this.saved || this.saved.length === 0) {
                this.$emit('selected', this.answerObj)
            }
                
            else {
                // filling answer in order
                this.question.questions.forEach((q, index) => {
                    this.saved.forEach(ans => {

                        if (ans.id === q.id) this.answerObj[index] = ans
                    })
                })
            }
            // filling answer array
            this.answerObj.forEach((ans, index) => {
                if(this.question.questions[index].type === 'text')
                    this.answers.push(ans.answer.length ? ans.answer[0].answer : '')
                else
                    this.answers.push(ans.answer ? ans.answer : '')
            })
            try {
                this.$nextTick(() => {
                    const height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight
                    if(this.$refs.multi && ((this.$refs.multi.clientHeight + 160) > height))
                        this.isScrollingNeeded = true
                });
            } catch (error) {
                console.error('Problem Accessing ref ', error)
            }
            
            // setTimeout(() => { this.$refs['text-area-0'][0].focus() }, 100)
        },

        methods: {
            removeEmptyObjects(obj) {
                return obj.filter(ans =>{
                    const question = this.question.questions.find(({ id }) => id === ans.id)
                    if(question && question.type === 'query') return true
                    if(!ans.answer || ans.answer === "")
                        return false
                    if(Array.isArray(ans.answer) && ans.answer.length === 0)
                        return false
                    if(question && ['date', 'unit'].includes(question.type)) {
                        if(Array.isArray(ans.answer) && ans.answer.length === 1 && Object.entries(ans.answer[0]).length === 0)
                            return false
                    }
                    
                    return true
                })
            },
            onInput(evt, idx) {
                if (this.question.questions[idx].hasOwnProperty('validations') &&
                    this.question.questions[idx].validations.length &&
                    evt.target.value.length > this.characterLimit[idx] && evt.keyCode !== 8) {
                    Vue.set(this.answers, idx, evt.target.value.substr(0, this.characterLimit[idx]))
                    evt.preventDefault()
                    return
                }
                Vue.set(this.answers, idx, evt.target.value)
            },
            selectAnswer(event, index) {
                if(this.question.questions[index].type === 'query') Vue.set(this.answers, index, event)
                else {
                    if(Array.isArray(event) && event.length > 0)
                        Vue.set(this.answers, index, event)
                }
            },
            isEmptyAnswer(answer) {
                if(answer === '')
                    return true
                if(Array.isArray(answer) && answer.length === 0)
                    return true
                if(Array.isArray(answer) && answer.length === 1 && answer[0] && typeof answer[0] === 'object' && Object.keys(answer[0]).length === 0)
                    return true
                return false
            },
            scrollDown() {
                if(this.$refs['multi-question-1'] && this.$refs['multi-question-1'][0])
                    this.$refs['multi-question-1'][0].scrollIntoView({behavior: 'smooth'})
            },
            getSavedAnswer(subId) {
                if(this.answerObj.find((ans) => ans.id === subId))
                    return this.answerObj.find((ans) => ans.id === subId).answer
            },
            showError(index) {
                if(this.question.questions[index] && this.question.questions[index].validations && this.question.questions[index].validations.some((val) => val.mandatory)) {
                    if(this.showNotice && this.isEmptyAnswer(this.answers[index]))
                    return true
                    if(!this.isEmptyAnswer(this.answers[index]))
                        return false
                    //console.log(index, this.answers.slice(index).some((answer) => this.isEmptyAnswer(answer)))
                    return this.answers.slice(index).some((answer) => !this.isEmptyAnswer(answer))
                }
                return false
            },
            // copyQuestion(question) {
            //     let copyQ = {...question}
            //     // copyQ.options = [...copyQ.options]
            //     return copyQ
            // }
        },
        computed: {
            isQuestionMandatory() {
                let isQuestionMandatory = false
                Object.keys(this.isSubQMandatory).forEach(subQ => {
                    if (this.isSubQMandatory[subQ]) isQuestionMandatory = true
                })
                return isQuestionMandatory
            },
            isSubQMandatory() {
                let isSubQMandatory = {}
                this.question.questions.forEach((subQ, i) => {
                    if(subQ.validations)
                        subQ.validations.forEach(type => {
                            if (type.type === 'mandatory')
                                isSubQMandatory[i] = type.mandatory
                        })
                })
                return isSubQMandatory
            },
            characterLimit() {
                let characterLimits = []
                this.question.questions.forEach(subQ => {
                    if(subQ.validations && subQ.type === 'text'){
                        const validate = subQ.validations.find(({type }) => type === 'length')
                        if(validate)
                            characterLimits.push(validate.max)
                        else
                            characterLimits.push(3000)
                    }
                    else
                        characterLimits.push(0)
                })
                return characterLimits
            }
        },
        components: {
            Date,
            MultiSelect,
            MultiScroller,
            MultiUnit,
            DownIcon,
            Query
        }
    }
</script>

<style lang="scss" scoped>
    .text {
        text-align: left;
        textarea {
            width: 100%;
            color: #333333;
            border: 1px solid #b2b2b2;
            padding: 10px 25px 10px 10px;
            background-color: #ffffff;
            font-size: 14px;
            height: 150px;
        }
        textarea:focus, input:focus{
            outline: none;
            border: 2px solid #833e93;
            background-color: #faf5fc;
            color: black;
        }
        .question-block {
            padding: 36px 25px;
            margin-bottom: 8px;
            position: relative;
            background-color: #ffffff;
            .mandatory-indicator {
                color: #833e93;
                font-size: 20px;
                margin-right: 3px;
            }
            span {
                font-size: 18px;
                color: #333333;
                font-weight: 700;
                float: left;
                text-align: left;
                margin-bottom: 10px;
            }
            .mandatory-alert {
                color: #DA4E4E;
                font-size: 14px;
                padding-top: 4px;
                font-weight: 400;
            }
            .character-count {
                position: absolute;
                bottom: 32px;
                right: 31px;
                color: #666666;
                font-size: 13px;
            }
            .question-text {
                display: block;
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
            }
            .question-title {
                margin-bottom: 2px;
            }
        }
        .mandatory-indicator {
            color: #833e93;
            font-size: 20px;
            margin-right: 3px;
            float: left
        }
        .mandatory-text {
            float: left;
            font-size: 15px;
            padding-bottom: 50px;
        }

        &.multi-question {
            padding: 0px;
            background-color: #eeeeee;
            margin-top: 0;
        }

    }
    .form-control {
        padding: 5px 20px;
        height: 52px;
        font-size: 20px;
        border: none;
        border-bottom: 3px solid #833e93;
        letter-spacing: 2px;
        font-weight: 500;
        background-color: #ffffff;
    }
    .form-control::placeholder {
        font-size: 18px;
    }
    .form-control, focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        outline: none;
    }
    input.form-control:focus {
        border: none;
        background-color: #ffffff;
        border-bottom: 3px solid #833e93;

    }
    .scroll-down {
        position: absolute;
        left: calc(50% - 32px);
        bottom: 100px;
        animation: MoveUpDown 1s ease-in infinite;
    }

    @keyframes MoveUpDown {
        0%, 100% {
            bottom: 97px;
        }
        50% {
            bottom: 100px;
        }
    }
    
</style>
