export default function({ dispatch, commit }, verification) {
    commit('startedAuthentication')

    // POST /auth/verify/phone
    return dispatch('post', {
        url: '/auth/verify/phone',
        data: verification
    }, {
        root: true
    })
    .then((response) => {
        commit('finishedAuthentication')
        return response
    })
    .catch((error) => {
        commit('finishedAuthentication')
        throw error
    })
}
