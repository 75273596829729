<template>
	<div class="form">
		<h3>
			Login
			<router-link v-if="! authenticatedEmail" to="/join">create an account</router-link>
			<router-link v-if="authenticatedEmail" v-on:click.native="resetAction" to="/reset-verify">forgot password</router-link>
		</h3>

		<!-- Username input -->
		<EmailDisplay
			v-if="authenticatedEmail && ! forgotPassword"
			v-model="email"
			v-bind:error="emailError">
		</EmailDisplay>
		<EmailInput
			v-else-if="! forgotPassword"
			v-model="email"
			v-bind:error="emailError"
			@next="initiateLogin">
		</EmailInput>

		<!-- Password input -->
		<PasswordInput
			v-if="authenticatedEmail && ! forgotPassword"
			:autofocus="true"
			v-model="password"
			v-bind:error="passwordError"
			@next="login">
		</PasswordInput>
		<p v-if="forgotPassword" class="message">
			Sending email...
		</p>
		<!-- <router-link v-if="authenticatedEmail" to="/reset">forgot password</router-link> -->
		<!-- Login button -->
		<button
			v-bind:class="{ disabled: authenticating }"
			v-on:click="loginAction">
			{{ loginActionText }}
		</button>
	</div>
</template>

<script>
import AuthForm from '.'

export default {
	name: 'login-form',
	extends: AuthForm,

	props: {
		logout: {
			type: Boolean
		}
	},
	data() {
		return {
			forgotPassword: false
		}
	},
	methods: {
		loginAction() {
			if (this.authenticatedEmail)
				this.login()
			else
				this.initiateLogin()
		},
		resetAction() {
			this.forgotPassword = true
			this.reset()
			.then(() => {
				return this.$router.push({ name: 'reset-verify' })
			})
		}
	},

	computed: {
		emailComponent() {
			return (this.authenticatedEmail) ? 'EmailDisplay' : 'EmailInput'
		},
		loginActionText() {
			return (this.authenticatedEmail) ? 'login' : 'next'
		}
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
