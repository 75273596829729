<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="206" height="143" viewBox="0 0 206 143">
        <defs>
            <linearGradient id="a" x1="141.756%" x2="0%" y1="-49.009%" y2="147.62%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="b" x1="72.217%" x2="37.894%" y1="-2945.017%" y2="3002.994%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="c" x1="53.228%" x2="48.241%" y1="-2945.017%" y2="3002.994%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="d" x1="141.756%" x2="0%" y1="-698.754%" y2="788.249%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="e" x1="62.903%" x2="42.969%" y1="-2945.017%" y2="3002.994%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="f" x1="77.299%" x2="35.124%" y1="-2945.017%" y2="3002.994%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="g" x1="141.756%" x2="0%" y1="-698.754%" y2="788.249%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="h" x1="84.267%" x2="31.327%" y1="-2945.017%" y2="3002.994%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="i" x1="108.724%" x2="18%" y1="-2945.017%" y2="3002.994%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
            <linearGradient id="j" x1="100%" x2="0%" y1="50%" y2="50%">
                <stop offset="0%" stop-color="#A54EAE"/>
                <stop offset="100%" stop-color="#833E93"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path fill="#F1E9F3" fill-rule="nonzero" d="M166 77.973h40v31h-40zM6 13.973h34v26H6z"/>
            <path stroke="url(#a)" d="M38 52c0 .553-2.463 1-5.5 1-3.038 0-5.5-.447-5.5-1 0-.551 2.462-1 5.5-1 3.037 0 5.5.449 5.5 1z" transform="translate(65 24.973)"/>
            <path stroke="url(#b)" d="M26.984 52.287c6.95 31.498-4.241 43.855-7.905 46.945a3.012 3.012 0 0 0-1.078 2.389c.194 6.895 2.032 13.666 15.033 13.666 14.353 0 15.825-6.702 15.965-13.562A3.88 3.88 0 0 0 47.955 99c-3.859-4.121-16.625-20.185-10.244-46.712" transform="translate(65 24.973)"/>
            <path fill="#833E93" stroke="url(#b)" d="M29.984 52.287c6.95 31.498-4.241 43.855-7.905 46.945a3.012 3.012 0 0 0-1.078 2.389c.194 6.895 2.032 13.666 15.033 13.666 14.353 0 15.825-6.702 15.965-13.562A3.88 3.88 0 0 0 50.955 99c-3.859-4.121-16.625-20.185-10.244-46.712" opacity=".117" transform="translate(65 24.973)"/>
            <path stroke="url(#c)" d="M28.885 52c-.351-6.854-1.593-14.353-3.885-20.713" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="url(#d)" d="M23.03 29.907c-.558 1.177-2.443 2.768-1.948 3.38.374 0 2.01-1.57 2.948-2.433.868.45 2.286 1.361 2.778.914.485-.471-.499-1.033-.895-1.442 1.043-.024 2.827.124 3.087-.448-.49-1.07-2.597-.349-3.906-.407" transform="rotate(-4 439.206 -919.328)"/>
            <path fill="#D8D8D8" stroke="url(#e)" d="M24.595 30.287c-2.242-1.035-4.171-5.263-4.493-7.629-.322-2.365.177-4.754.675-7.09l1.975-9.281c1.962 2.195 3.958 4.438 5.13 7.102 1.903 4.332 1 11.027.05 15.637" opacity=".28" transform="rotate(-4 439.206 -919.328)"/>
            <path fill="#D8D8D8" stroke="url(#f)" d="M28.437 15.921c1.572-3.031 5.19-5.831 8.29-7.323.73-.352 1.819-.539 2.175.183.123.248.109.54.073.813-.131 1.037-.515 2.024-.922 2.989C35.913 17.642 33 26.313 27 30.287" opacity=".28" transform="rotate(-4 439.206 -919.328)"/>
            <path fill="#D8D8D8" stroke="url(#g)" d="M24 30.213c-6.008.459-12.15-1.232-17.153-4.723-2.065-1.44-4.01-3.285-4.847-5.72.813-.292 1.679-.38 2.537-.424 6.373-.327 10.5.622 15.57 4.654" opacity=".28" transform="rotate(-4 439.206 -919.328)"/>
            <path fill="#D8D8D8" stroke="url(#h)" d="M14.779 20.287c-2.597-1.53-4.745-6.284-4.778-9.218-.034-2.935.681-5.85.569-8.782 2.893 2.733 5.778 5.515 8.047 8.75A25.495 25.495 0 0 1 21 15.187" opacity=".28" transform="rotate(-4 439.206 -919.328)"/>
            <path fill="#D8D8D8" stroke="url(#i)" d="M25.002 10.353c-.045-1.91.852-3.766 2.144-5.268 1.292-1.502 2.963-2.692 4.665-3.798 1.54 2.968 1.656 7.122-.076 10" opacity=".28" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="url(#e)" d="M22.595 30.287c-2.242-1.035-4.171-5.263-4.493-7.629-.322-2.365.177-4.754.675-7.09l1.975-9.281c1.962 2.195 3.958 4.438 5.13 7.102 1.903 4.332 1 11.027.05 15.637" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="url(#f)" d="M26.437 15.921c1.572-3.031 5.19-5.831 8.29-7.323.73-.352 1.819-.539 2.175.183.123.248.109.54.073.813-.131 1.037-.515 2.024-.922 2.989C33.913 17.642 31 26.313 25 30.287" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="url(#g)" d="M22 30.213c-6.008.459-12.15-1.232-17.153-4.723C2.782 24.05.837 22.205 0 19.77c.813-.292 1.679-.38 2.537-.424 6.373-.327 10.5.622 15.57 4.654" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="url(#h)" d="M12.779 20.287c-2.597-1.53-4.745-6.284-4.778-9.218-.034-2.935.681-5.85.569-8.782 2.893 2.733 5.778 5.515 8.047 8.75A25.495 25.495 0 0 1 19 15.187" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="url(#i)" stroke-linecap="round" d="M24.069 9.426c-.045-1.911.852-3.767 2.144-5.269C27.506 2.655 29.176 1.465 30.88.36c1.539 2.968 1.656 7.122-.076 10" transform="rotate(-4 439.206 -919.328)"/>
            <path stroke="#833E93" stroke-linecap="round" stroke-width="3" d="M63.5 141.117l68-.144"/>
            <g fill-rule="nonzero" stroke="#833E93" stroke-linecap="square">
                <path d="M31.5 14.573v11.01M1 31.557v5.887M31.413 26.649H5.587M18.5 28.584v9.778M39.421 38.973H18.58M1.577 37.973h13.48"/>
            </g>
            <g fill-rule="nonzero" stroke="#833E93" stroke-linecap="square">
                <path d="M162.5 78.673V91.52M193.304 91.986h-30.608M177.5 94.64v10.667M202.316 105.473h-24.632M157.692 105.473h16.175"/>
            </g>
            <path stroke="url(#j)" stroke-linecap="round" d="M97.261 76.754c1.628-15.788 18.692-61.073 8.518-74.3-.797-1.036-1.715-1.036-2.773-.825-1.365.357-1.839 1.04-1.423 2.048.625 1.512 8.756.232 4.793-3.677" transform="translate(0 1)"/>
        </g>
    </svg>

</template>
<script>
export default {
	name: 'questionnaire-Bye-icon',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
// @import "./style.scss";

</style>

