<template>
    
    <svg width="17" height="43" viewBox="0 0 17 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.52343 20.1466C1.27644 31.6774 -1.97657 41.1079 7.26991 42.1079C14.0234 42.8382 13.7765 34.1772 15.0234 22.6465C16.2704 11.1157 20.0479 1.24486 11.7857 0.351345C3.52343 -0.542169 3.77042 8.61587 2.52343 20.1466Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>