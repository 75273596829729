import axios from 'axios'
import baseUrl from './base-url'

export default function({ state }, { url, data, query }) {
    let hasHandoff = url.includes('handoff_token')
    if (query)
        url = url + '?' + Object.keys(query).map((q) => (q + '=' + query[q])).join('&')

    // if handoff_token is present 
    // then don't use credentials (withCredentials)
    return axios.put(baseUrl + url, data || {}, {
            withCredentials: !hasHandoff
        })
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            throw error.response.data
        })
}
