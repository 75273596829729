import neck_f from "./neck_f"
import neck_b from "./neck_b"
import collarbone from "./collarbone"
import left_shoulder_b from "./left_shoulder_b"
import left_shoulder_f from "./left_shoulder_f"
import left_upper_arm_b from "./left_upper_arm_b"
import left_upper_arm_f from "./left_upper_arm_f"
import mid_back from "./mid_back"
import right_shoulder_b from "./right_shoulder_b"
import right_shoulder_f from "./right_shoulder_f"
import right_upper_arm_b from "./right_upper_arm_b"
import right_upper_arm_f from "./right_upper_arm_f"
import upper_back from "./upper_back"
import upper_chest from "./upper_chest"
import body_f from "./body_f"
import body_b from "./body_b"

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'


export default {
    neck_f,
    neck_b,
    collarbone,
    left_shoulder_b,
    left_shoulder_f,
    left_upper_arm_b,
    left_upper_arm_f,
    mid_back,
    right_shoulder_b,
    right_shoulder_f,
    right_upper_arm_b,
    right_upper_arm_f,
    upper_back,
    upper_chest,
    body_f,
    body_b,
    body_f_selected,
    body_b_selected
}