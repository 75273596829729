import LoginForm from './Login'
import JoinForm from './Join'
import UpgradeForm from './Upgrade'
import ResetForm from './Reset'
import ResetVerifyForm from './ResetVerify'
import ResetPasswordForm from './ResetPassword'
import VerifyEmailForm from './Verify'
import VerifyPhoneForm from './VerifyPhone'
import VerifyProviderForm from './VerifyProvider'

export default {
    LoginForm,
    JoinForm,
    UpgradeForm,
    ResetForm,
    ResetVerifyForm,
    ResetPasswordForm,
    VerifyEmailForm,
    VerifyPhoneForm,
    VerifyProviderForm
}
