<template>
<svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path d="M37.4999 12.8418C37.4999 16.3414 37.6903 20.1565 35.5 22.3807C32.568 25.358 23.558 25.3418 18.4999 25.3418C13.9861 25.3418 6.90881 25.2846 3.9999 22.8418C1.21414 20.5024 0 16.354 0 12.8418C0 8.73642 1.84214 2.72498 5.49999 0.342531C8.23918 -1.44157 14.7161 4.3418 18.4999 4.3418C22.2837 4.3418 28.7608 -1.44246 31.5 0.341648C35.1578 2.72409 37.4999 8.73642 37.4999 12.8418Z" :fill="color"/>
<circle cx="8.1272" cy="7.12724" r="1" transform="rotate(7.85191 8.1272 7.12724)" fill="#F8F8F8"/>
<circle cx="30.1272" cy="7.12724" r="1" transform="rotate(7.85191 30.1272 7.12724)" fill="#F8F8F8"/>
<circle cx="30.1272" cy="17.1272" r="1" transform="rotate(7.85191 30.1272 17.1272)" fill="#F8F8F8"/>
<circle cx="8.1272" cy="17.1272" r="1" transform="rotate(7.85191 8.1272 17.1272)" fill="#F8F8F8"/>
<circle cx="33.1272" cy="12.1272" r="1" transform="rotate(7.85191 33.1272 12.1272)" fill="#F8F8F8"/>
<circle cx="4.1272" cy="12.1272" r="1" transform="rotate(7.85191 4.1272 12.1272)" fill="#F8F8F8"/>
</g>
</svg>
</template>
<script>
export default {
	name: 'neck_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
