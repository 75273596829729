<template>
	<div>
		<el-row class="container" v-if="this.query.auth">
			<el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 8, offset: 8 }">

				<template v-if="verifySuccess">
					<div class="image-container">
						<img class="image" :src="verifySuccess.image">
						<img class="tick" src="../icon/tick.png">
					</div>
					<p class="title">{{ verifySuccess.name }} is Confirmed</p>
				</template>

				<template v-else-if="gotError">
					<div class="error-section">
						<div class="error">
							<img src="./icon/exclamation-mark.png" class="image">
						</div>
						<span class="error-text">{{ errorText }}</span>
					</div>
				</template>

				<template v-else-if="isLoading">
					<div class="loading-section">
						<Spinner class="icon"></Spinner>
						<div class="text">Confirming Doctor...</div>
					</div>
				</template>

				<p class="description">
					Get instant alerts from your doctor, record vitals and symptoms on DoctorPlan
					app and help {{ verifySuccess ? verifySuccess.name : 'your doctor' }} get a better insight about your health.
				</p>

				<el-row class="download-now">
				 	<el-col :span="7"><hr class="line"></el-col>
					<el-col :span="10"><div class="text">Download now!</div></el-col>
				 	<el-col :span="7"><hr class="line"></el-col>
				</el-row>

				<div class="logo">
					<img src="@/image/DoctorPlan_logo_blue.png">
				</div>

				<div class="link">
					<a v-if="isAccountGerd(query.account)" href="https://apps.apple.com/in/app/gerdhelp/id1568842316">
						<img src="@/image/download/ios.png">
					</a>
					<a v-else-if="query.account === 'livechair'" href="https://apps.apple.com/in/app/live-chair-health/id1537755288">
						<img src="@/image/download/ios.png">
					</a>
					<a v-else href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
						<img src="@/image/download/ios.png">
					</a>

					<a v-if="isAccountGerd(query.account)" href="https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US">
						<img src="@/image/download/android.png">
					</a>
					<a v-else-if="query.account === 'livechair'" href="https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient">
						<img src="@/image/download/android.png">
					</a>
					<a v-else href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
						<img src="@/image/download/android.png">
					</a>


					<!-- <a :href="query.account === 'livechair' ? 'https://apps.apple.com/in/app/live-chair-health/id1537755288' : 'https://itunes.apple.com/us/app/doctorbox/id1437144807'">
						<img src="@/image/download/ios.png">
					</a>
					<a :href="query.account === 'livechair' ? 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient' : 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'">
						<img src="@/image/download/android.png">
					</a> -->
				</div>

			</el-col>
		</el-row>
	</div>
</template>

<script>
import AuthForm from '.' 

export default {
	name: 'verify-provider',
	extends: AuthForm,

	data() {
		return {
			verifySuccess: null,
			gotError: false,
			isLoading: false,
			errorText: 'Doctor could not be confirmed'
		}
	},

	created() {
		if (this.query.auth) {
			this.$store.dispatch('auth/logout').then(() => {
				this.isLoading = true
				this.verifyProviderEmail(this.query).then((result) => {
					this.verifySuccess = result.doctor
					this.isLoading = false
				})
				.catch((error) => {
					this.gotError = true
					try {
						if (error.code === 'InvalidConfirmationToken') this.errorText = 'Invalid Confirmation Token'
						else if (error.code === 'DuplicateConfirmation') this.errorText = 'Doctor Already Exists'
						else this.errorText = error.message
					} catch(err) {
						console.log('Error in verifying provider email', err)
					}
					this.isLoading = false
				})
			})
			.catch(() => {
				this.$router.push({ path: '/download', query: { account: this.query.account } })
			})
		} else {
			this.$router.push({ path: '/download', query: { account: this.query.account } })
			if (!this.query.auth) {
				this.$message({
					showClose: true,
					message: 'Auth token missing.',
					type: 'error',
					duration: '0'
				})
			}
		}
	},

	mounted() {

	},

	methods: {

	},
	computed: {

	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
.container {
	padding: 0 25px;
	.image-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		.image {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			padding: 3px;
			border: 2px solid #44b358;
		}
		.tick {
			height: 30px;
			width: 30px;
			position: absolute;
			bottom: -3px;
			right: 38%;
		}
	}
	.loading-section {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.icon {
			padding: 12px;
			border-radius: 50%;
			background-color: #eeeeee;
		}
		.text {
			padding: 30px 0;
			text-align: center;
			color: #666666;
		}
	}
	.error-section {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.error {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			background-color: #ffe7e7;
			display: flex;
			align-items: center;
			justify-content: center;
			.image {
				height: 35px;
				width: 35px;
			}
		}
		.error-text {
			padding: 30px 0;
			text-align: center;
			color: #da4e4e;
		}
	}
	.title {
		font-size: 23px;
		color: #333333;
		text-align: center;
		font-weight: 600;
		padding: 20px 0;
	}
	.description {
		font-size: 16px;
		color: #666666;
		text-align: center;
		padding: 0px 0 20px 0;
	}
	.download-now {
		.text {
			color: #666666;
			text-align: center;
		}
		.line {
			border-top: 1px solid #cccccc;
			border-bottom: none;
		}
	}
	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0 15px 0;
		img {
			width: 150px;
		}
	}
	.link {
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			margin-top: 15px;
			width: 40%;
			height: 40px;
			display: inline-block;

			img {
				width: 100%;
			}
		}
		a:first-child {
			width: 36%;
			margin-right: 2%;
		}
	}
}


</style>
