<template>
<svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 19.0008C0 29.494 7.16423 38 15.9992 38C24.8374 38 32 29.494 32 19.0008C32 8.50604 24.8374 0 15.9992 0C7.16423 0 0 8.50604 0 19.0008Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_knee_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
