import Auth from './Auth.vue'

function route(path, name, form, props) {
    return {
        path,
        name,
        component: Auth,
        props: (route) => ({
            form,
            query: route.query,
            ...props
        }),
        meta: {
            authComponent: true
        }
    }
}

export default [
    // For logging in
    route('/login', 'login', 'Login'),

    // For signing up
    route('/join', 'join', 'Join'),

    // For resetting a password
    route('/reset', 'reset', 'Reset'),
    route('/reset/verify', 'reset-verify', 'ResetVerify'),
    route('/reset/password', 'reset-password', 'ResetPassword'),

    // For upgrading
    route('/upgrade', 'upgrade', 'Upgrade'),
    route('/upgrade/verify', 'upgrade-verify', 'Upgrade'),

    route('/verify/email', 'verify-email', 'VerifyEmail'),
    route('/verify/phone', 'verify-phone', 'VerifyPhone'),
    route('/verify/connect', 'verify-provider', 'VerifyProvider'),

    route('/logout', 'logout', 'Login', {
        logout: true
    })
]
