<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9.5" fill="#FAF5FC" stroke="#833E93"/>
        <rect x="5.17859" y="9.396" width="9.64286" height="1.37755" rx="0.688776" fill="#833E93"/>
        <rect x="10.6027" y="5.17725" width="9.64286" height="1.37755" rx="0.688775" transform="rotate(90 10.6027 5.17725)" fill="#833E93"/>
    </svg>
</template>

<script>
export default {
    name: 'add-icon',
}
</script>