<template>
    <div class="progress">
        <el-progress type="circle" :stroke-width="5" :percentage="percentage"></el-progress>
    </div>
</template>

<script>
/**
 * A component that displays a progress bar for file upload.
 */
export default {
    name: 'upload-progress',
    props: {
        // The percentage of the progress bar to display
        percentage: {
            type: Number,
            required: true
        }
    }
}
</script>