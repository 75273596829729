<template>
	<div>
		<h3>
			Register
			<router-link to="/login">already have an account?</router-link>
		</h3>

		<!-- Username input -->
		<EmailInput
			v-show="step == 0"
			ref="email"
			v-model="email"
			v-bind:error="emailError"
			@next="next">
		</EmailInput>

		<div v-show="step == 1">
			<NameInput
				ref="name"
				v-model="name"
				v-bind:error="nameError"
				@next="$refs.code.focus()">
			</NameInput>
			<CodeInput
				ref="code"
				v-model="code"
				v-bind:error="codeError"
				@next="next">
			</CodeInput>
			<DoctorDisplay v-if="doctor" :doctor="doctor">
			</DoctorDisplay>
		</div>

		<div v-show="step == 2">
			<PasswordInput
				ref="password"
				v-model="password"
				v-bind:error="passwordError"
				@next="next">
			</PasswordInput>
		</div>

		<!-- Login button -->
		<button v-bind:class="['primary', { 'disabled': authenticating }]"
			v-on:click="next">
			next
		</button>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import AuthForm from '.'

export default {
	name: 'join-form',
	extends: AuthForm,

	data() {
		return {
			lookupTimeout: null
		}
	},

	computed: {
		step() {
			if (! this.authenticatedEmail)
				return 0
			else if (! this.authenticatedName || ! this.authenticatedCode)
				return 1
			else
				return 2
		}
	},

	watch: {
		step(step) {
			setTimeout(() => {
				if (step == 0)
					this.$refs.email.focus()
				else if (step == 1)
					this.$refs.name.focus()
				else
					this.$refs.password.focus()
			}, 100)
		},
		code(code) {
			clearTimeout(this.lookupTimeout)
			if (code.length >= 6) {
				this.lookupTimeout = setTimeout(() => {
					this.verifyCode(code)
				}, 200)
			}
		}
	},

	methods: {
		...mapActions('auth', [
			'verifyCode'
		]),
		next() {
			if (! this.authenticatedEmail)
				this.initiateJoin()
			else if (! this.authenticatedCode)
				this.initiateJoinNameCode()
			else
				this.join()
		}
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
