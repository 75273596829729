<template>
        
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24">
        <defs>
            <linearGradient id="a" x1="0%" y1="50%" y2="50%">
                <stop offset="0%"/>
                <stop offset="100%" stop-opacity="0"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path fill="url(#a)" d="M5 7.377l16.773-3.193 3.366 18.02-15.505 2.852 5.501-8.71L5 7.377z" opacity=".339" transform="rotate(142 14.586 12.776)"/>
            <path fill="#FFF" fill-rule="nonzero" d="M11.124 0h14a1 1 0 0 1 1 1v9.556a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm6.111 7.111V8.89h7.111V7.11h-7.11zM13.68 9.778a1.778 1.778 0 1 0 0-3.556 1.778 1.778 0 0 0 0 3.556zm3.555-7.111v1.777h7.111V2.667h-7.11zm-1.506-.909c-.143-.148-.445-.11-.541-.018L13.192 3.67l-.698-.691a.405.405 0 0 0-.555.027c-.21.201-.061.486.035.578l1.041.994a.25.25 0 0 0 .177.068.25.25 0 0 0 .176-.068l2.428-2.318c.096-.092.075-.354-.067-.503z"/>
        </g>
    </svg>


</template>

<script>
export default { 
	name: 'header-icon'
}
</script>