import body_f from './body_f'
import body_selected from './body_selected'

import right_upper_abdomen from './right_upper_abdomen'
import left_upper_abdomen from './left_upper_abdomen'
import pelvic from './pelvic'
import epigastric_area from './epigastric_area'
import right_flank_side from './right_flank_side'
import left_flank_side from './left_flank_side'
import around_belly_button from './around_belly_button'
import lower_abdomen from './lower_abdomen'


export default {
    body_f,
    body_selected,

    right_upper_abdomen,
    left_upper_abdomen,
    pelvic,
    epigastric_area,
    right_flank_side,
    left_flank_side,
    around_belly_button,
    lower_abdomen
}