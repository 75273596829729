<template>
    <svg width="60" height="55" viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48 42.7691L59.5 0C43 10 43.5 13.5 23 15.5L0 17V55C0 55 13.5 55 24.5 52.5C35.5 50 37.8849 50.4267 48 42.7691Z" :fill="color"/>
    </svg>
    
    </template>
    <script>
    export default {
        name: 'right_mid_back',
    
        props: {
            // Color or hex code
            color: {
                type: String
            }
        }
    }
    </script>
    