<template>
<svg width="105" height="90" viewBox="0 0 105 90" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.989844 56.7271C-2.86636 28.3448 5.54076 18.9334 9.15262 16.2663C10.1238 15.5492 11.2912 15.2258 12.4658 14.9466C14.7678 14.3994 19.1321 13.2462 24.4497 11.2812C32.9621 8.13549 43.1959 0 52.0435 0C60.891 0 72.5668 8.13549 81.0793 11.2812C85.6817 12.9819 89.5703 14.0745 92.0257 14.6925C93.8802 15.1592 95.7233 15.7719 97.1168 17.0815C101.162 20.8836 108.644 31.6737 102.922 56.7271C99.6867 70.8913 88.494 81.1927 82.6287 85.7586C80.2895 87.5796 77.4557 88.5578 74.5048 88.8412C69.5106 89.3208 61.2303 90 54.384 90C46.3436 90 36.2583 89.0632 31.8757 88.613C30.4075 88.4622 28.9811 88.1416 27.6281 87.5517C21.4978 84.8788 3.54351 75.5225 0.989844 56.7271Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'upper_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
