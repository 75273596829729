export default {
	profile: [],
	user: null,
	event: [],
	eventIndex: {},
	appointment: {},
	loadingAppointment: false,
	content: null,
	loadedContent: false,
	loadingContent: false,
	loadingEvents: false,
	patchedSubQ: {},
	name: null,
	queryQuestOptions: {}
}
