import ArticleLanding from './Landing'
import ArticleViewer from './Viewer'

export default [
	{
		path: '/article',
		name: 'article-landing',
		props: true,
		component: ArticleLanding
	},
	{
		path: '/article/:id',
		name: 'article-viewer',
		props: (route) => ({
			id: route.params.id,
			query: route.query
		}),
		component: ArticleViewer,

		meta: {
			link: true
		}
	},
	{
		path: '/learn/:id',
		name: 'learn-viewer',
		props: true,
		component: ArticleViewer,

		meta: {
			link: true
		}
	}
]
