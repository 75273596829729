export default {
    props: {
		appointment: {
			type: Object
		},
		content: {
			type: Object
		},
		summary: {
			type: Object
		},
		result: {
			type: Object
		},
		selected: {
			type: Object
		}
	}
}
