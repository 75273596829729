<template>
	<div class="questionnaire welcome" v-if="question">
		<img v-if="question.section_image" :src="question.section_image" style="max-height: 200px; max-width: 200px; width: auto">
		<img v-else src="../image/welcome.png">
		<!-- <img v-else src="./image/welcome.png"> -->
        <p class="title">{{ question.section_header }}</p>
        <p class="subtitle">{{ question.section_description }}</p>
	</div>
</template>

<script>
import Question from './index'
export default {
	name: 'questionnaire-separator',
    extends: Question,
	props: {
	},

	methods: {
		start() {
			this.$emit('start')
		}
	},

	computed: {
	}
}
</script>


<style lang="scss" scoped>
$choice-size: 50px;
$choice-font-size: 14px;

@import "../style.scss";
</style>
