<template>
<svg width="47" height="177" viewBox="0 0 47 177" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.43021 95C18.4303 121.5 1.77899 149.57 5.93021 165C8.39412 174.158 17.2417 176.5 20.4301 176.5C23.6186 176.5 31.4663 169.659 33.9302 160.5C38.0814 145.07 33.9303 116.306 33.9303 86.5C33.9303 60.8141 49.1481 29.2583 45.9303 13.5C43.1979 0.119187 24.3611 1.00021 20.4301 1.00021C16.4992 1.00021 4.1626 0.119184 1.43021 13.5C-1.78767 29.2583 1.43021 69.3141 1.43021 95Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_leg_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
