<template>
    <div v-bind:class="['input', { other } ]">
        <input 
            v-model="input" 
            placeholder="Type your own topic"
            :size="getSize"
            class="inputItem"/>
        <button 
            v-on:click="addTopic(input)"
            @clicked="onClickSection"
            type="primary"
            class="buttonItem">
                ADD
        </button>
    </div>
</template>

<script>
import formatDate from '@/module/util/format-date'

export default {
	props: {
        input: {
            type: String
        },
        other: {
            type: Boolean
        },
        size: {
            type: String
        }
	},
	methods: {
        formatDate,
        addTopic(input) {
            this.$emit('add', input)
        },
        onClickSection() {
            this.$emit('clicked', input)
        }
    },
    computed: {
        getSize() {
            return this.size ? this.size : 20
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    .inputItem {
        width: 85%;
        height: 10%;
        padding: 2%;
        font-size: 9pt;
        border: 1px solid #E5E3E3;
        box-sizing: border-box;
        padding-left: 10px;
    }
    .buttonItem {
        color: #6D6D6D;
        width: 15%;
        height: 10%;
        padding: 2%;
        font-size: 9pt;
        border: 1px solid #E5E3E3;
        box-sizing: border-box;
        background: white;
    }
}
.input.other {
    .inputItem {
        font-size: 9pt;
        padding-bottom: 50%;
    }
    .buttonItem {
        padding-bottom: 26%;
        padding-top: 26%;
        font-size: 9pt;
    }
}
@media only screen and (min-width: 350px) {
    .input, .input.other {
        .inputItem, .buttonItem {
            font-size: 11pt;
        }
    }
}
@media only screen and (min-width: 450px) {
    .input, .input.other {
        .inputItem, .buttonItem {
            font-size: 14pt;
        }
    }
}
@media only screen and (min-width: 600px) {
    .input, .input.other {
        .inputItem, .buttonItem {
            font-size: 18pt;
        }
    }
}
</style>
