<template>
<svg width="40" height="117" viewBox="0 0 40 117" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 6.99971C4.5 6.99971 0.987287 27.9552 0.987305 37.3529C0.987305 55.75 5.11786 100.649 8.5 116.5C8.5 116.5 13.1963 111.5 19.5 111.5C25.8037 111.5 30.5 115 30.5 115C31.868 110.503 30.6278 75.7825 34.7319 60.2471C37.2458 50.7314 39.5 5.00052 39.5 5.00052C39.5 5.00052 35.4884 1.93068 25 1.00002C15.2721 0.136846 4.5 6.99971 4.5 6.99971Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_lower_leg_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
