import Radio from './Radio'
import BoxSelect from './BoxSelect'
import Select from './Select'
import VerticalSlider from './VerticalSlider'
import Scroller from './Scroller'
import HumanMap from './HumanMap'



export default {
    Radio,
    BoxSelect,
    Select,
    VerticalSlider,
    Scroller,
    HumanMap
}
