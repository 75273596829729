<template>
	<div class="topic">
        <TopicHeader
            :other="other"
            :color="color"
            :image="topicImage"
			:title="title"
            :description="descriptionOther">
        </TopicHeader>
		<Summary 
			v-if="summary && ! misc && ! other"
            :summary="summary" 
            :color="color" 
            :count="count" 
            :type="type"
            :conditions="conditions"
            class="topic-summary">
        </Summary>
        <div 
            v-if="description" 
            class="description">
            <span v-html="description"></span>
        </div>
		<p v-html="getStatement(misc)" class="statement"></p>
		<div
			v-for="(question, index) in topics"
			v-bind:topics="topics"
			:key="question.key">
			<TopicSection
            	:index="index"
				:topics="topics"
				:section="question"
				@clicked="onClickSection">
			</TopicSection>
		</div>
		<TopicAdder 
            :size="size"
            :other="other"
			@add="addTopic" 
			@clicked="onClickSection">
		</TopicAdder>
		<div v-bind:class="['buttons', { first }]">
			<PreviousButton 
                v-if="name !== result.first"
				:first="first" 
				:route="getPreviousRoute" 
				:buttonName="previousName">
			</PreviousButton>
			<NextButton 
				:first="first" 
				:route="getRoute" 
				:buttonName="buttonName">
			</NextButton>
		</div>
	</div>
</template>
<script>

import AppointmentView from '../../../mixin/appointment-view'
import TopicView from '../../mixin/topic-view'

export default {
	name: 'appointment-vital',
    props: {
		name: {
			type: String
		},
		topicImage: {
			type: String
		},
		title: {
			type: String
        },
        type: {
            type: String
        },
        conditions: {
            type: Object
        },
        misc: {
            type: Boolean
        },
        other: {
            type: Boolean
        },
        size: {
            type: String
        },
        description: {
            type: String
        },
        descriptionOther: {
            type: String
        }
    },
	data() {
		return {
		}
	},
	mixins: [ AppointmentView, TopicView ],
	mounted() {
		this.getFromStorage
		this.updateStorage
        this.getReferences
        // Set previous button contents
        this.setPrevious()
        // Check if current topic is the first one
        this.checkFirst()
        // Get image and color for topic title
        this.getResources(this.name)
        // Load all questions
        this.loadSelected
        // For all topics that are not misc (Coping/Living, Cause/Risk, Diagnosis). Also lifestyle & activity topics
        if (! this.misc && this.name !== 'lifestyle_question' && this.name !== 'activity_question' && this.name !== 'other_question')
            this.getCount(this.name.split('_')[0])
        // Set name and route accordingly
        this.setButtonName()
    }
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>