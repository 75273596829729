export default {
    pn: {
        id: 'pn',
        title: "Push Notifications",
        on: "You will receive push notifications from your doctor on your phone.",
        off: "You will not receive push notifications from your doctor on your phone.",
        disabled: "You have disabled PN from phone settings, please click on the button to allow it."
    },
    email: {
        id: 'email',
        title: "Email Notifications",
        on: "You will receive messages from your doctor on your email.",
        off: "You will not receive messages from your doctor on your email."
    },
    sms: {
        id: 'sms',
        title: "Text Messages",
        on: "You will receive messages from your doctor on your phone.",
        off: "You will not receive messages from your doctor on your phone."
    }
}