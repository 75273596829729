<template>
    <svg width="60" height="55" viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 42.7691L0.5 0C17 10 16.5 13.5 37 15.5L60 17V55C60 55 46.5 55 35.5 52.5C24.5 50 22.1151 50.4267 12 42.7691Z" :fill="color"/>
    </svg>
    
    </template>
    <script>
    export default {
        name: 'left_mid_back',
    
        props: {
            // Color or hex code
            color: {
                type: String
            }
        }
    }
    </script>
    