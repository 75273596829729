<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="59" height="51" viewBox="0 0 59 51">
		<defs>
			<linearGradient id="i-hqs-a" x1="91.795%" x2="11.72%" y1="22.976%" y2="80.559%">
				<stop offset="0%" stop-color="#A54EAE"/>
				<stop offset="100%" stop-color="#833E93"/>
			</linearGradient>
			<linearGradient id="i-hqs-b" x1="0%" x2="89.581%" y1="50%" y2="42.894%">
				<stop offset="0%"/>
				<stop offset="100%" stop-opacity="0"/>
			</linearGradient>
		</defs>
		<g fill="none" fill-rule="evenodd" transform="translate(0 2)">
			<path fill="#833E93" d="M0 8h32v32H0z"/>
			<circle cx="33.5" cy="23.5" r="24.5" fill="url(#i-hqs-a)" stroke="#FFF" stroke-width="2"/>
			<path fill="url(#i-hqs-b)" d="M15.874 21.62l22.146-1.39.563 17.059-18.415 2.913 6.3-9.013-10.594-9.57z" opacity=".339" transform="rotate(142 27.228 30.216)"/>
			<path fill="#FFF" fill-rule="nonzero" d="M27 18h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H27a1 1 0 0 1-1-1V19a1 1 0 0 1 1-1zm6.111 7.385v1.846h7.111v-1.846h-7.11zm-3.555 2.769c.981 0 1.777-.827 1.777-1.846 0-1.02-.796-1.846-1.777-1.846-.982 0-1.778.826-1.778 1.846s.796 1.846 1.778 1.846zm3.555-7.385v1.846h7.111V20.77h-7.11zm-1.507-.943c-.142-.155-.445-.114-.54-.02l-1.996 2.006-.698-.718a.394.394 0 0 0-.555.029c-.21.208-.061.504.035.6l1.041 1.032a.245.245 0 0 0 .177.07.245.245 0 0 0 .176-.07l2.428-2.407c.096-.095.075-.368-.068-.522z"/>
		</g>
	</svg>

</template>
<script>
export default { name: 'questionnaire-section-icon' }
</script>
