<template>
    <button @click="clickButton" v-bind:class="[{ save }, { cancel }, { logout }, { logoutRight }, { change }, { send }, { done }]">
        {{ getButtonName }}
    </button>
</template>
<script>

export default {
	props: {
        cancel: {
            type: Boolean
        },
        save: {
            type: Boolean
        },
        logout: {
            type: Boolean
        },
        logoutRight: {
            type: Boolean
        },
        change: {
            type: Boolean
        },
        send: {
            type: Boolean
        },
        done: {
            type: Boolean
        }
	},
	mounted() {
	},
	data() {
		return {
		}
	},
    methods: {
        clickButton() {
            this.$emit('clickButton', '')
        }
    },
    computed: {
        getButtonName() {
            if (this.cancel)
                return 'Cancel'
            if (this.save)
                return 'Save'
            if (this.logout || this.logoutRight)
                return 'Log Out'
            if (this.change)
                return 'Change'
            if (this.send)
                return 'Send'
            if (this.done)
                return 'Done'
        }
    }
}
</script>
<style lang="scss" scoped>
button {
    font-size: 12pt;
    text-align: center;
    border: 1px solid #2D3E4F;
    border-radius: 4px;
    cursor: pointer;
}
.save, .change {
    color: white;
    text-decoration: none;
    padding: 12px 10px 12px 10px;
    background: #2D3E4F;
    margin-left: auto;
    width: 65%;
}
.cancel {
    color: #2D3E4F;
    padding: 12px 10px 12px 10px;
    margin-right: 2%;
    background: white;
    width: 35%;
}
.logout {
    border: 1px solid red;
    color: red;
    background: white;
    padding: 12px 50px 12px 50px;
}
.logoutRight {
    border: 1px solid red;
    color: red;
    background: white;
    padding: 12px 10px 12px 10px;
    width: 45%;
}
.send, .done {
    padding: 12px 10px 12px 10px;
    background: #2D3E4F;
    color: white;
}
</style>