export default function(phone, country) {
    if (phone == null || typeof phone !== 'string')
        return false

    phone = phone.replace(/[^0-9]/g, '')
    if (country == null || country === 'US') {
        if (phone.length == 10)
            phone = '+1' + phone
        else if (phone.length == 11)
            phone = '+' + phone
        return phone.length == 12
    }

    return false
}
