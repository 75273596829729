<template>
    
    <svg width="93" height="53" viewBox="0 0 93 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M92.1252 47.4307C89.0468 29.9525 88.1802 19.3884 88.0132 2.42736C88.0008 1.17104 86.8419 0.229294 85.6093 0.472478C53.82 6.74418 35.5596 9.24927 2.84929 10.8219C1.23087 10.8997 0.333397 12.7801 1.27247 14.1005C3.99247 17.9251 8.43202 24.585 10.4941 30C12.6556 35.6758 13.8812 44.3855 14.314 47.924C14.4268 48.8463 15.1566 49.5864 16.0782 49.7039C45.701 53.4798 61.9427 53.4041 90.405 49.7702C91.5382 49.6255 92.3234 48.5558 92.1252 47.4307Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'wrist_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>