<template>
    <div 
        v-if="sectionCount()"
        v-bind:class="['section-edit']">
        <div class="image-title">
            <img
                v-if="! checkTitle()"
                :src="getImage(section.image)"
                class="button">
            <span 
                v-bind:style="{ color: section.color }"
                v-bind:class="{ sectionTitle }"
                class="title">
                <strong>{{ section.title }}</strong>
            </span>
            <button
				v-bind:class="{ selected }"
                v-bind:style="{ 
                    color: section.color, 
                    border: '2px solid ' + section.color , 
                    'border-color': section.color, 
                    background: 'white'
                }"
                v-on:click="toggleSelected"
                class="edit">
                {{ selectText }}
            </button>
        </div>
        <div
			v-for="question in this.questions"
			:key="question"
            class="message">
            <span> • {{ question }}</span>
        </div>
    </div>
</template>

<script>

import Vue from 'vue'

export default {

	props: {
        section: {
            type: Object
        },
        questions: {
            type: Array
        },
        message: {
            type: String
        },
        result: {
            type: String
        }
	},
    data() {
        return {
            selectComponent: false,
            selected: false,
            sectionTitle: this.checkTitle()
        }
    },
	methods: {
		getImage(file) {
            if (file == null)
                return
			return require('../../../image/' + file)
		},
        toggleSelected() {
            this.$emit('clicked', {
				key: this.section.key
            })
        },
        getQuestion(question) {
            return Object.keys(question) [0]
        },
        checkTitle() {
            if (this.section.title == "Other Questions")
                return true
            return false
        },
        sectionCount() {
            var count = 0
            if (this.questions.length == 0)
                return false
            Object.keys(this.questions).forEach((question) => {
                if (this.questions[question]) {
                    count++
                    return
                }
            })
            if (count > 0)
                return true
            return false
        }
    },
    computed: {
		selectText() {
			return 'Edit'
		}
    },
	components: {

	}
}
</script>

<style lang="scss" scoped>
//@import "./style.scss";
.section-edit {
    border-bottom: 1px solid #E5E3E3;
    .image-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 25px;
        }

        span {
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 3%;
        }
        span.sectionTitle {
            margin-left: 0%;
        }
        button {
            margin-right: 10%;
            margin-left: auto;
        }
        .edit {
            border-radius: 12px;
            padding: 4px 14px 4px 14px;
            border-width: thin;
            background: white;
            font-size: 14px;
        }
    }
    .message {
        margin-left: 10%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 600px) {
    .section-edit {
        .image-title {
            .button {
                width: 35px;
            }
            .title {
                font-size: 24px;
            }
            .edit {
                border-radius: 30px;
                padding: 8px 28px 8px 28px;
                border-width: thin;
                margin-left: auto;
                font-size: 18px;
            }
        }
        .message {
            font-size: 24px;
        }
    }
}
</style>
