export default function({ dispatch, commit }, credentials) {
    // POST /auth/login
    return dispatch('post', {
        url: '/auth/join',
        data: credentials
    }, {
        root: true
    })
    .then((token) => {
        commit('finishedAuthentication')
        commit('receivedToken', token)
        return token
    })
    .catch((error) => {
        commit('finishedAuthentication')
        console.log('error', error)
    })
}
