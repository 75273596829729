<template>
<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.7543 3.94642L19.8664 0.125262C19.8664 0.125262 13.1614 0.959455 9.0653 2.7608C4.95758 4.56725 0.14813 8.82114 0.14813 8.82114L0.236593 22.8467L5.87319 39.6958L36.6421 26.5132L34.2153 17.957L31.7543 3.94642Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_hand_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
