<template>
    <div>
        <div class="human-map-wrapper">
            <span :class="humanSideLowerCase + '_left'">LEFT</span>
            <span :class="humanSideLowerCase + '_right'">RIGHT</span>
            <div key="body">
                <body_f
                    v-if="selectedParts.length != humanMap.length"
                    :key="selectedParts.length"
                    :color="selectedParts.length === humanMap.length ? '#833E93' : '#B2B2B2'"
                    class="body">
                </body_f>
                <body_selected
                    v-if="selectedParts.length === humanMap.length"
                    :key="selectedParts.length"
                    :color="selectedParts.length === humanMap.length ? '#833E93' : '#B2B2B2'"
                    class="body">
                </body_selected>
            </div>
            <div v-for="bodyPart in humanMap" :key="bodyPart.id">
                <template>
                    <div class="tooltip" v-if="selectedParts.filter(bp => bp.id === bodyPart.id).length">
                        <span :ref="bodyPart.id + '_tooltip'" class="tooltiptext">{{ bodyPart.tooltip }}</span>
                    </div>
                    <div 
                        :is="bodyPart.id" 
                        :ref="bodyPart.id"
                        :key="selectedParts.length" 
                        :class="bodyPart.id"  
                        @click.native="toggle(bodyPart.id)" 
                        :color="selectedParts.filter(bp => bp.id === bodyPart.id).length ? '#833E93' : '#CCCCCC'">
                    </div>
                </template>
            </div>
        </div>
        <div class="selected-text">
            <span v-if="calcSelectedList['Front'].length" style="margin-top: 20px; ">Front<br>
                <span v-for="part in calcSelectedList['Front']" :key="part" class="bullet-text">&bull; {{ part }}</span>
            </span>
            <br><br>
            <span v-if="calcSelectedList['Back'].length" style="margin-top: 10px;">Back<br>
                <span v-for="part in calcSelectedList['Back']" :key="part" class="bullet-text">&bull; {{ part }}</span>
            </span>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import { RadioGroup, RadioButton } from 'element-ui'
    import { v4 as uuidv4 } from 'uuid'
    import SubQuestion from './index'
    import IconComponents from '../icon/Back/components'
    import components from '../../../../auth/form/components'

    Vue.use(RadioGroup)
    Vue.use(RadioButton)

    const humanMap = [
        { id: 'neck', side: 'Back', tooltip: 'Neck' },
        { id: 'left_shoulder', side: 'Back', tooltip: 'Left Shoulder' },
        { id: 'right_shoulder', side: 'Back', tooltip: 'Right Shoulder' },
        { id: 'upper_back', side: 'Back', tooltip: 'Upper Back' },
        { id: 'mid_back', side: 'Back', tooltip: 'Mid Back' }, 
        { id: 'right_buttock', side: 'Back', tooltip: 'Right Buttock' },
        { id: 'left_buttock', side: 'Back', tooltip: 'Left Buttock' },
        { id: 'lower_back', side: 'Back', tooltip: 'Lower Back' },
        { id: 'left_side_flank', side: 'Back', tooltip: 'Left Side/Flank' },
        { id: 'right_side_flank', side: 'Back', tooltip: 'Right Side/Flank' }
    ]

    export default {
        name: 'back',
        extends: SubQuestion,
        watch: {
            humanSide: function() {
                this.$nextTick(() => this.removeTooltips())
                this.$emit('changeHumanMapSide', this.humanSide)
            }
        },

        props: {
            saved: {
                type: Array
            }
        },

        data() {
            return {
                humanMap: JSON.parse(JSON.stringify(humanMap)),
                humanSide: 'Back',
                selectedParts: [] || this.saved,
                unsortedBodyParts: [] || this.saved
            }
        },

        mounted() {
            this.loadData()
            this.$emit('changeHumanMapSide', this.humanSide)
        },

        methods: {
            /**
             * @func placeTooltip
             * @desc places tooltip on body part selection
             * @param bodyPart(String) string indicating if the body part is active or inactive
             */
            placeTooltip(bodyPart) {
                let imgRef = this.$refs[bodyPart][0].$el
                let tooltipRef = this.$refs[bodyPart + '_tooltip'][0]
                tooltipRef.style.visibility = 'visible'

                let top = parseInt(window.getComputedStyle(imgRef, null).getPropertyValue('top').replace('px', ''))
                let left = parseInt(window.getComputedStyle(imgRef, null).getPropertyValue('left').replace('px', ''))

                tooltipRef.style.top = (top + 80) + 'px'
                // for body parts on right side of the screen
                if ((this.humanSide === 'Front' && bodyPart.includes('left')) || (this.humanSide === 'Back' && bodyPart.includes('right'))) {
                    tooltipRef.style.left = (left - 30) + 'px'
                }
                // for body parts on left side of the screen
                else if ((this.humanSide === 'Front' && bodyPart.includes('right')) || (this.humanSide === 'Back' && bodyPart.includes('left'))) {
                    tooltipRef.style.left = (left - 30) + 'px'
                }
                // for head
                else if(bodyPart.includes('head') || bodyPart.includes('neck') || bodyPart.includes('throat') || bodyPart.includes('knee') || bodyPart.includes('abdomen')) {
                    tooltipRef.style.top = (top + 50) + 'px'
                    tooltipRef.style.left = (left) + 'px'
                }
                // for middle body parts
                else tooltipRef.style.left = (left + 10) + 'px'
            },
            /**
             * @func removeTooltips
             * @desc removes all tooltips
             * @param tooltipText(String)
             */
            removeTooltips(tooltipText) {
                if (document.getElementsByClassName('tooltiptext')) {
                    let classes = document.getElementsByClassName('tooltiptext')
                    Object.keys(classes).forEach(cls => {
                        if (!tooltipText || (tooltipText && classes[cls].innerHTML.trim() === tooltipText))
                            classes[cls].style.visibility = 'hidden'
                    })
                }
            },
            loadData() {
                if (!this.saved) this.$emit('selectbp', [])
                else {
                    this.unsortedBodyParts = this.saved
                    this.selectedParts = this.saved
                    this.sortBodyParts()
                    this.$emit('selectbp', this.saved)
                }
                this.$nextTick(() => this.removeTooltips())
            },
            /**
             * @func toggle
             * @desc toggles the state of body part
             * @param bodyPart(String) string indicating if the body part is active or inactive
             */
            toggle(bodyPart) {
                // making inactive
                if (this.selectedParts.filter(bp => bp.id === bodyPart).length) {
                    this.selectedParts = this.selectedParts.filter(part => part.id !== bodyPart)
                    this.unsortedBodyParts = this.unsortedBodyParts.filter(part => part.id !== bodyPart)
                }
                // making active
                else {
                    if (this.limitReached(this.humanSide)) 
                        return

                    let tooltipText = ''
                    let reqObj = {
                        id: bodyPart,
                        value: bodyPart
                    }

                    this.selectedParts.push(reqObj)
                    this.unsortedBodyParts.push(reqObj)

                    tooltipText = this.humanMap.filter(bp => bp.id === bodyPart)[0].tooltip
                    // placing tooltip on top of the body part
                    this.$nextTick(() => this.placeTooltip(bodyPart))
                    // remove tooltip after 1.5seconds
                    setTimeout(() => this.removeTooltips(tooltipText), 1500)
                }
                // sort
                this.sortBodyParts()
                this.$emit('selectbp', this.selectedParts)
            },
            sortBodyParts() {
                let tempSelectedParts = []
                this.humanMap.forEach(bp => {
                    this.selectedParts.forEach(sp => {
                        if (sp.value === bp.id) tempSelectedParts.push(sp)
                    })
                })
                this.selectedParts = tempSelectedParts
            },
            getImage(bodyPart) {
                return require('../icon/Back/' + bodyPart + '.svg')
            }
        },

        computed: {
            humanSideLowerCase() {
                return this.humanSide.toLowerCase()
            },
            calcSelectedList() {
                let selectedList = { 'Front': [], 'Back': [] }
                this.unsortedBodyParts.forEach(part => {
                    let partObj = this.humanMap.filter(bodyPart => part.value === bodyPart.id)[0]
                    selectedList[partObj.side].push(partObj.tooltip)
                })
                return selectedList
            }
        },

        components: {
            ...IconComponents
        },
    }
</script>

<style lang="scss" scoped>
    $choice-size: 150px;
    $choice-font-size: 20px;
    @import "style.scss";

    .body {
        position: absolute;
        top: 91px;
        right: 3px;
        z-index: 90;
    }

    .neck {
        position: absolute;
        top: 112px;
        right: 137px;
        z-index: 100;
    }
    .left_shoulder {
        position: absolute;
        top: 157px;
        right: 185px;
        z-index: 100;
    }
    .right_shoulder {
        position: absolute;
        top: 157px;
        right: 65px;
        z-index: 100;
    }
    .upper_back {
        position: absolute;
        top: 167px;
        right: 105px;
        z-index: 100;
    }
    .mid_back {
        position: absolute;
        top: 265px;
        right: 130px;
        z-index: 100;
    }
    .right_buttock {
        position: absolute;
        top: 400px;
        right: 94px;
        z-index: 100;
    }
    .left_buttock {
        position: absolute;
        top: 400px;
        right: 162px;
        z-index: 100;
    }
    .lower_back {
        position: absolute;
        top: 345px;
        right: 97px;
        z-index: 100;
    }
    .left_side_flank {
        position: absolute;
        top: 268px;
        right: 191px;
        z-index: 100;
    }
    .right_side_flank {
        position: absolute;
        top: 268px;
        right: 96px;
        z-index: 100;
    }

    .human-map-wrapper {
        min-height: 500px;
    }
</style>
