<template>
    
    <svg width="57" height="34" viewBox="0 0 57 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M56.063 20.7076C57.7237 15.7257 54.008 9.13122 53.7934 8.75677L53.7773 8.72875C53.5583 8.34834 47.6999 -1.48405 28.1399 0.192123C8.85302 1.84489 3.42049 11.4918 3.08287 12.1268C3.07075 12.1496 3.06189 12.1682 3.05139 12.1918C2.8056 12.7442 -0.494317 20.2844 0.786459 24.1264C2.11641 28.1158 2.49615 33.2442 27.2851 33.2442C52.074 33.2442 54.3533 25.8368 56.063 20.7076Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'above_ear_s',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
