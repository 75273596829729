<template>
    <div>
        <div :class="['video-wrapper', {disabled : disabled}]" v-show="videoLoaded" @mouseover="showControls" @mouseleave="hideControls">
            <!-- Video element with reference for control -->
            <video ref="video" @timeupdate="updateTime" @play="onPlay" @pause="onPause" @ended="onEnded" 
                @loadedmetadata="onLoadedMetadata" @waiting="onBuffering" @playing="onPlaying"
                disablePictureInPicture
                class="video">
                <source :src="src" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <!-- Watermark -->
            <img class="watermark" alt="Informed Consent" src="../../image/informed-consent.png" />
            <!-- Current time and time left -->
            <div class="time-info">
                <span>{{ formattedCurrentTime }}</span> / <span>{{ formattedTimeLeft }}</span>
            </div>
            <!-- Play button in the middle -->
            <i v-if="!isPlaying" @click="togglePlayPause" :disabled="disabled" class="play-button">
                <PlayIcon />
            </i>
            <i v-else-if="buffering" class="play-button loading-variant" :disabled="disabled">
                <LoadingIcon :size="30" />
            </i>
            <!-- Pause button in the middle -->
            <div v-if="isPlaying && showControlElements">
                <i class="play-button pause-variant" @click="manualPause" :disabled="disabled">
                    <PauseIcon />
                </i>
                <i class="play-button rewind-variant" @click="rewind10Seconds" :disabled="disabled">
                    <RewindIcon />
                </i>
            </div>

            <!-- Progress bar, shown only if video is loaded and controls are visible -->
            <div v-if="videoLoaded && showControlElements" class="progress-bar">
                <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
            </div>
        </div>
        <div class="loading-screen" v-show="!videoLoaded">
            <LoadingIcon :size="25" />
        </div>
    </div>
</template>

<script>
import PlayIcon from '../icon/Play.vue';
import PauseIcon from '../icon/Pause.vue';
import RewindIcon from '../icon/Rewind.vue';
import LoadingIcon from '@/component/icon/LoadingIcon'
import Vue from 'vue'


export default {
    props: {
        // Source URL for the video
        src: {
            type: String,
            required: true
        },
        // Disabled prop to control if the video can be played
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // Track whether the video is playing
            isPlaying: false,
            // Track wether the video is manually paused
            manuallyPaused: false,
            // Track whether the video has ended
            hasEnded: false,
            // Track the current time of the video
            currentTime: 0,
            // Track the duration of the video
            duration: 0,
            // Tracks whether the video metadata is loaded
            videoLoaded: false,
            // Show control elements or not
            showControlElements: false,
            // Track if the video is buffering
            buffering: false
        };
    },
    mounted() {
        // Add event listener for visibility change
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        // Set the video's duration once it is loaded
        this.$refs.video.onloadedmetadata = () => {
            this.duration = this.$refs.video.duration;
        };

        const state = {
            progress: {}
        }
        const abc = 'kcjabckjsdkc-askdhcbksd'
        Vue.set(state.progress, abc, 0)
        console.log(state.progress)
    },
    beforeDestroy() {
        // Remove event listener before component is destroyed
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    methods: {
        /**
        * Toggles between play and pause states.
        */
        togglePlayPause() {
            if (this.disabled) return; // Prevent toggling if disabled
            
            if (this.isPlaying) {
                this.$refs.video.pause();
            } else {
                this.manuallyPaused = false;
                this.$refs.video.play();
            }
        },
        /**
         * Do a manual pause, which essentially means pausing the video with a 
         * different state `maunallyPaused`
         */
        manualPause() {
            this.manuallyPaused = true;
            this.$refs.video.pause();
        },
        /**
        * Handles play event.
        */
        onPlay() {
            this.isPlaying = true;
            this.hasEnded = false; // Reset hasEnded when video is played again
            this.buffering = false; // Reset buffering state
        },
        /**
        * Handles pause event.
        */
        onPause() {
            this.isPlaying = false;
        },
        /**
        * Handles ended event.
        */
        onEnded() {
            this.isPlaying = false;
            this.hasEnded = true;
            this.$emit('video-ended');
        },
        /**
        * Updates the current time of the video.
        */
        updateTime() {
            this.currentTime = this.$refs.video.currentTime;
        },
        /**
        * Plays the video.
        */
        playVideo() {
            if (this.disabled || this.manuallyPaused) return; // Prevent playing if disabled
            this.$refs.video.play();
        },
        /**
        * Pauses the video.
        */
        pauseVideo() {
            if (this.disabled) return; // Prevent pausing if disabled
            this.$refs.video.pause();
        },
        /**
        * Handles visibility change to pause video when tab is not active.
        */
        handleVisibilityChange() {
            if (document.hidden && !this.disabled) {
                this.$refs.video.pause();
            }
        },
        /**
        * Handles video buffering event.
        */
        onBuffering() {
            this.buffering = true;
        },
        /**
         * Handles video playing event.
         */
        onPlaying() {
            this.buffering = false;
        },
        /**
        * Handles video loaded metadata event.
        */
        onLoadedMetadata() {
            this.duration = this.$refs.video.duration;
            this.videoLoaded = true;
        },
        /**
        * Show control elements.
        */
        showControls() {
            this.showControlElements = true;
        },
        /**
        * Hide control elements with setTimeout of 1 second.
        */
        hideControls() {
            setTimeout(() => {
                this.showControlElements = false;
            }, 1000);
        },
        /**
         * Rewinds the video by 10 seconds.
         */
        rewind10Seconds() {
            const video = this.$refs.video;
            video.currentTime = Math.max(0, video.currentTime - 10);
        }

    },
    computed: {
        /**
        * Formats the current time in mm:ss format.
        */
        formattedCurrentTime() {
            const minutes = Math.floor(this.currentTime / 60);
            const seconds = Math.floor(this.currentTime % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        /**
        * Formats the time left in mm:ss format.
        */
        formattedTimeLeft() {
            const timeLeft = this.duration - this.currentTime;
            const minutes = Math.floor(timeLeft / 60);
            const seconds = Math.floor(timeLeft % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        /**
        * Calculates the progress percentage of the video.
        */
        progressPercentage() {
            return (this.currentTime / this.duration) * 100;
        }
    },
    components: {
        PlayIcon,
        PauseIcon,
        LoadingIcon,
        RewindIcon
    }
};
</script>

<style scoped>
.video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.video-wrapper.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    .play-button {
        cursor: not-allowed;
    
    }
}
.video {
    width: 100%;
    height: 100%;
}

.watermark {
    position: absolute;
    top: 0;
    right: 0;
}

.time-info {
    position: absolute;
    bottom: 15px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    border-radius: 3px;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}
.loading-variant {
    transform: translate(-50%, -50%) translateX(50px);
    background-color: rgba(0, 0, 0, 0.5);
}
.rewind-variant {
    transform: translate(-50%, -50%) translateX(-50px);
}
.progress-bar {
    position: absolute;
    bottom: 8px;
    left: 5px;
    width: calc(100% - 10px);
    height: 5px;
    background: rgba(0, 0, 0, 0.3);
}

.progress {
    height: 100%;
    background: white;
}


.controls {
    position: absolute;
    bottom: 12px;
    left: 12px;
}

button {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

button[disabled] {
    background: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
}

button:hover:not([disabled]) {
    background: rgba(0, 0, 0, 0.7);
}
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    background-color: #000000;
}
</style>
