<template>
<svg width="32" height="129" viewBox="0 0 32 129" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M31.7889 13.26C32.1682 6.94193 27.9898 1.17879 21.6721 0.793649C17.4848 0.538379 12.8592 0.800448 9.37933 2.46914C3.47489 5.3005 6.88891 26.9268 6.88891 35.8407C6.88891 49.4156 4.62643 57.3342 1.79833 77.6965C-1.74078 103.178 1.23876 121.602 1.67173 124.048C1.70556 124.239 1.752 124.418 1.81113 124.603C3.30722 129.281 9.93315 129.145 11.1857 124.396C16.3518 104.81 24.6523 72.4725 27.2512 57.3344C29.7132 42.9939 31.1035 24.6787 31.7889 13.26Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_outer_thigh_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
