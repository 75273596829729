<template>
<svg width="60" height="148" viewBox="0 0 60 148" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.790989 49.2487C0.790989 49.2487 37.2715 4.10406 49.5837 0C49.5837 0 62.3519 54.7208 59.6156 90.2894C56.8793 125.858 57.3358 124.034 50.9517 142.73C50.9517 142.73 43.4077 125.774 29.9753 125.805C16.0099 127.68 9.45485 147.29 9.45485 147.29C9.45485 147.29 6.06711 126.702 4.89505 112.178C3.56486 95.7019 0.611675 75.313 0.790989 49.2487Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_thigh_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
