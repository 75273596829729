// Login actions
import initiateLogin from './action/initiateLogin'
import login from './action/login'

// Registration actions
import initiateJoin from './action/initiateJoin'
import verifyCode from './action/verifyCode'
import join from './action/join'

// Get query parameters
import setQuery from './action/set-query'

// Upgrade actions
import upgrade from './action/upgrade'
import verifyUpgrade from './action/verifyUpgrade'

// Reset password actions
import reset from './action/reset'
import verifyReset from './action/verifyReset'
import resetPassword from './action/resetPassword'

// Verify an access token
import verify from './action/verify'

// Change email address
import changeEmail from './action/changeEmail'

// Check phone number exists
import checkPhone from './action/checkPhone'

// Add phone number
import addPhone from './action/addPhone'

// Add Email
import addEmail from './action/addEmail'

// Change phone number
import changePhone from './action/changePhone'

// Change password
import changePassword from './action/changePassword'

// Handoff from an email or link
import handoff from './action/handoff'

// Verify an email address from a verification email link
import verifyEmail from './action/verifyEmail'
import verifyPhone from './action/verifyPhone'
import verifySetPassword from './action/verifySetPassword'

// Verify provider
import verifyProviderEmail from './action/verifyProviderEmail'

// Logout and invalidate the access token
import logout from './action/logout'
// Track link entry
import link from './action/link'

export default {
    initiateLogin,
    login,
    initiateJoin,
    verifyCode,
    setQuery,
    join,
    upgrade,
    verifyUpgrade,
    reset,
    verifyReset,
    resetPassword,
    verify,
    verifyEmail,
    verifyPhone,
    verifySetPassword,
    logout,
    link,
    handoff,
    changeEmail,
    addPhone,
    addEmail,
    changePhone,
    changePassword,
    checkPhone,
    verifyProviderEmail
}
