<template>
    
    <svg width="80" height="96" viewBox="0 0 80 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.241211 48.1406C0.241211 74.3742 17.926 95.6406 39.7412 95.6406C61.5565 95.6406 79.2412 74.3742 79.2412 48.1406C79.2412 21.9071 72.2412 0.640625 39.7412 0.640625C7.24121 0.640625 0.241211 21.9071 0.241211 48.1406Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>