import { render, staticRenderFns } from "./BreastProblems.vue?vue&type=template&id=0ac5b23a&scoped=true"
import script from "./BreastProblems.vue?vue&type=script&lang=js"
export * from "./BreastProblems.vue?vue&type=script&lang=js"
import style0 from "./BreastProblems.vue?vue&type=style&index=0&id=0ac5b23a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac5b23a",
  null
  
)

export default component.exports