import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import axios from 'axios'
axios.defaults.withCredentials = true

import state from './state'
import actions from './action'

import auth from './module/auth'
import content from './module/content'
import user from './module/user'

export default new Vuex.Store({
	state,
	actions,
	mutations: {
		// Set the progress of uploading file from a question
		setProgress(state,{ progressId, percentCompleted}) {
			Vue.set(state.uploadProgress, progressId, percentCompleted)
		}
	},
	modules: {
		auth,
		user,
        content
    }
})
