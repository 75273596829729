<template>
	<router-link :to="'/questionnaire/' + questionnaire.id +
			(hasAccessToken ? '' : ('?auth=' + questionnaire.user + '&handoff_token=' + questionnaire.id + ':' + questionnaire.handoff_token + '&account=' + account))">
		<el-row class="questionnaire-link">
			<el-col :xs="16" :sm="19" class="question-column">
				<h1>{{ content.hasOwnProperty('display_name') && content.display_name ? content.display_name : content.title }}</h1>
				<h2>{{ content.description }}</h2>
				<!-- <p>{{ content.question.length }} questions&nbsp;&nbsp;&bull;&nbsp;&nbsp;{{ content.length }} minutes</p> -->
				<div class="status" v-if="questionnaire.hasOwnProperty('doctor')">
					<div>
						<img v-if="questionnaire.doctor.hasOwnProperty('image')" :src="questionnaire.doctor.image">
						<div v-else style="width: 35px; height: 35px;"><DefaultDoctor></DefaultDoctor></div>
					</div>
					<div>
						<span class="title">{{ questionnaire.doctor.name }}</span>
						<br>
						<span class="subtitle">{{ questionnaire.doctor.specialization }}</span>
					</div>
				</div>
			</el-col>
			<el-col :xs="8" :sm="5" class="start-column">
				<i class="el-icon-arrow-right"></i>
				<small class="requested">{{ formatDate(questionnaire.requested, false, true) }}</small>
			</el-col>

		</el-row>
	</router-link>

</template>
<script>
import DefaultDoctor from './icon/DefaultDoctor'
import formatDate from '@/module/util/format-date'

export default {
	name: 'landing-questionnaire',

	props: {
		questionnaire: {
			type: Object
		},
		content: {
			type: Object
		},
		account: {
			type: String,
			default: 'doctorplan'
		}
	},

	methods: {
		formatDate
	},

	computed: {
		hasAccessToken() {
			return !!localStorage.getItem['access_token']
		}
	},
	components: {
		DefaultDoctor
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
