<template>
<svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.468742 24.2305C0.085325 16.4823 -0.106383 12.6082 3.02968 8.63959C6.16574 4.67101 10.3412 3.89459 18.692 2.34175C23.8638 1.38005 29.1443 0.61229 32.9334 0.583384C35.559 0.563354 38.5131 0.892997 41.4774 1.40229C49.5044 2.78139 53.518 3.47094 56.6895 7.23386C59.8611 10.9968 59.8611 15.7593 59.8611 25.2844V41.4292C59.8611 46.1119 59.8611 48.4532 58.6043 51.1555C57.3476 53.8577 56.0583 54.9439 53.4799 57.1164C49.6762 60.3212 44.5721 63.495 39.2981 63.4652C29.05 63.4072 20.8607 62.2395 10.9019 53.6711C3.35154 47.175 0.988689 34.7378 0.468742 24.2305Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_buttock_inner_hip',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
