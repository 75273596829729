<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.5794 15.7533C22.0971 16.9817 22.6511 24.7948 18.8274 23.6126C15.0037 22.4305 8.65497 20.6687 5.58374 16.2523C3.01504 12.5586 1.69077 8.47563 1.30874 6.95061C1.07722 6.02637 1.50325 3.68733 1.87485 1.98782C2.0501 1.18633 3.06371 0.964534 3.57865 1.60323C5.39747 3.85913 8.79285 8.001 10.8797 10.1176C13.9298 13.211 19.0616 14.525 20.5794 15.7533Z" :fill="color"/>
<circle cx="6.53416" cy="10.3416" r="0.994312" transform="rotate(4.37147 6.53416 10.3416)" fill="#EEEEEE"/>
<circle cx="10.1967" cy="14.6107" r="0.994312" transform="rotate(4.37147 10.1967 14.6107)" fill="#EEEEEE"/>
<circle cx="14.0108" cy="16.8963" r="0.994312" transform="rotate(4.37147 14.0108 16.8963)" fill="#EEEEEE"/>
</svg>
</template>
<script>
export default {
	name: 'right_jaw',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
