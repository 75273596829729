<template>
	<div class="checklists list" :key="renderKey" :style="'height: ' + this.checklistHeight + 'px'">
		<el-row>
		 	<el-col :span="24" type="flex" align="left" style="line-height: 40px;">
				<span :style="checklists.hasOwnProperty('item') && calcCompleted === checklists.item.length ? 'color: #44b358' : ''"
					  class="checklist-status-text" v-html="calcText">
				</span>
			</el-col>
		</el-row>
		<el-row v-for="(task, index) in checklists.item"
				class="task" type="flex"
				:style="getBackground(task)"
				@click.native="openTask(task)">
				<el-col :span="15" type="flex" align="left">
					<span class="checklist-text" :style="task.state === 'n/a' ? 'color: #999999' : ''">{{ task.text }}</span><br>
					<div v-if="task.hasOwnProperty('comment') && task.comment" class="checklist-comment" @click="openNote($event, index)">
						<Doc></Doc>
						<div>{{ truncateText(task.comment) }}</div>
					</div>
				</el-col>
				<el-col :span="9" v-if="task.hasOwnProperty('state') && task.state" style="align-self: start">
					<span class="completed-text" v-if="task.state === 'complete'">
						<Completed color="#44b358"></Completed>&nbsp;
						<span>Completed</span>
					</span>
					<span class="not-applicable-text" v-if="task.state === 'n/a'">
						<NotApplicable color="#666666"></NotApplicable>&nbsp;
						<span>N/A</span>
					</span>
				</el-col>
				<el-col :span="9" v-else>
					<i class="el-icon-arrow-right"></i>
				</el-col>
		</el-row>
		<el-dialog
				class="note-dialog"
				custom-class="dialog"
				:visible.sync="showNote"
				:task="task"
				width="300px">
			<div class="dialog-title">Notes - {{ truncateText(task.text || '') }}</div><br>
			<span>{{ task.comment }}</span>
			<div slot="footer" class="dialog-footer">
				<el-button class="doneBtn" style="height: 40px;" @click="showNote = false">Done</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue'
import formatDate from '@/module/util/format-date'
import Doc from './icon/Doc'
import Completed from './icon/Completed'
import NotApplicable from './icon/NotApplicable'
import { Dialog } from 'element-ui'
Vue.component(Dialog.name, Dialog)

export default {
	name: 'checklists-list',

	props: {
		content: {
			type: Object,
			required: true
		},
		checklists: {
			type: Object,
		},
		urlToken: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			task: Object,
			renderKey: 0,
			showNote: false,
			checklistHeight: '0'
		}
	},

	mounted() {
		window.addEventListener("resize", this.resetHeight)
		// called to initially set the height.
		this.resetHeight()

		if (this.checklists) {
			if (!this.checklists.hasOwnProperty('item')) this.checklists.item = this.content.item
		}
		this.renderKey += 1
	},

	methods: {
		formatDate,
		/**
		 * @param task Object
		 * @desc checks the task state, if not found not completed then opens the task
		 */
		openTask(task) {
			if (!task.hasOwnProperty('state')) {
				this.$router.push('/checklist/' + this.checklists.id + '/task/' + task.id + '?' + this.urlToken)
			}
		},
		/**
		 * @func getBackground
		 * @desc Gets the background according to the state of the checklist
		 */
		getBackground(task) {
			if (task && task.hasOwnProperty('state')) {
				if (task.state === 'complete') return 'background-color: #f4fff6'
				else if (task.state === 'n/a') return 'background-color: #f3f3f3'
			}
			return 'background-color: #ffffff'
		},
		truncateText(text) {
			return text.length > 25 ? text.slice(0, 22)+'...' : text
		},
		/**
		 * @func openNote
		 * @desc opens note dialog box
		 * @param (obj, number)
		 */
		openNote(event, index) {
			if (event) {
				event.preventDefault()
				event.stopPropagation()
			}
			this.task = this.checklists.item[index]
			this.showNote = true
		},
		resetHeight() {
			// reset the body height to that of the inner browser
			// substracting 60px to give space to navigation buttons
			this.checklistHeight = window.innerHeight
		},
	},

	computed: {
		/**
		 * @func calcCompleted
		 * @desc Calculates the number of tasks completed in a checklist
		 * @return Number
		 */
		calcCompleted() {
			if (this.checklists && this.checklists.hasOwnProperty('item'))
				return this.checklists.item.filter(task => task.hasOwnProperty('state') && task.state).length
			return 0
		},
		/**
		 * @func calcText
		 * @desc Calculates the text to be shown according to the number of tasks completed
		 * @return String
		 */
		calcText() {
			let completed = this.calcCompleted
			if (this.checklists.hasOwnProperty('item') && completed === this.checklists.item.length) {
				return 'All ' + completed + ' tasks completed before ' + this.formatDate(this.checklists.time)
			} else {
				return completed + ` tasks completed <span style="color: #333333; font-weight: 400">&nbsp;&bull;&nbsp; Due on <b>${ this.formatDate(this.checklists.time) }</b></span>`
			}
		}
	},
	components: {
		Doc,
		Completed,
		NotApplicable
	}
}
</script>


<style lang="scss" scoped>
@import "./style.scss";
</style>
