<template>
<svg width="42" height="68" viewBox="0 0 42 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8749 18.6183L41.1473 0.833984L40.2353 38.2265C40.2353 38.2265 37.6655 47.3118 35.2192 52.8188C32.5649 58.794 27.0111 67.411 27.0111 67.411C27.0111 67.411 17 69.0706 11.5069 66.499C5.20857 63.5504 0.106689 52.8188 0.106689 52.8188C0.106689 52.8188 2.49807 41.4842 5.12277 34.5785C7.58455 28.1014 12.8749 18.6183 12.8749 18.6183Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_upper_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
