<template>
<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M60.7148 21.5945C60.9718 14.6514 61.1003 11.1798 58.3153 7.69734C55.5303 4.21484 51.7646 3.51142 44.2332 2.10459C38.2892 0.994261 31.9071 0.0327725 27.5002 0.000838667C24.1966 -0.0231006 20.3838 0.467864 16.6695 1.16527C9.24319 2.55966 5.53004 3.25686 2.76513 6.58924C0.000221252 9.92162 0.000221252 14.1932 0.000221252 22.7364V40.0163C0.000221252 44.3429 0.000221252 46.5062 1.14755 48.9379C2.29488 51.3696 3.54041 52.3957 6.03146 54.4479C9.96106 57.6851 15.3864 61.0311 21.0002 61.001C31.4662 60.9448 39.8295 59.8119 50 51.5C58.0532 44.9185 60.3277 32.0505 60.7148 21.5945Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_buttock',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
