<template>
    
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M23.5109 15.2618C20.5078 24.2618 15.5153 22.2618 8.51403 19.2618C1.51403 15.2618 -1.48597 15.2618 1.51288 6.26176C3.01214 0.261897 4.01098 -1.23796 13.5117 1.26196C21.0109 4.26202 26.514 6.26176 23.5109 15.2618Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_elbow_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>