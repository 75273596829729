<template>

    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
        <g fill="#666" fill-rule="evenodd">
            <path d="M1.922 1.44c-.53 0-.962.432-.962.962v10.637c0 .53.432.962.962.962h4.695l4.418-4.41v-7.19c0-.53-.431-.961-.962-.961H1.922zm5.092 13.52H1.922C.862 14.96 0 14.1 0 13.04V2.402C0 1.342.862.48 1.922.48h8.151c1.06 0 1.922.862 1.922 1.922v7.587l-4.98 4.972z"/>
            <path d="M7.296 10.27v3.062l3.06-3.061h-3.06zm-.327 4.485c-.082 0-.164-.016-.243-.049-.237-.098-.39-.328-.39-.584V9.31h4.81c.259 0 .488.153.586.39.098.238.044.509-.139.69l-4.177 4.178c-.122.121-.282.186-.447.186zM8.886 4.37H3.11c-.318 0-.575.211-.575.471s.257.472.575.472h5.777c.318 0 .575-.211.575-.472 0-.26-.257-.47-.575-.47M8.886 7.25H3.11c-.318 0-.575.21-.575.47s.257.471.575.471h5.777c.318 0 .575-.21.575-.47 0-.261-.257-.472-.575-.472M5.142 10.128H3.11c-.318 0-.575.21-.575.471 0 .26.257.472.575.472h2.033c.318 0 .575-.211.575-.472 0-.26-.257-.471-.575-.471"/>
        </g>
    </svg>


</template>
<script>
    export default {
        name: 'doc-icon',

        props: {
            // Color or hex code
            color: {
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>
    // @import "./style.scss";

</style>

