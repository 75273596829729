<template>
    
    <svg width="44" height="57" viewBox="0 0 44 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M4.30969 48.225C4.07342 47.7792 4.13532 47.25 4.36096 46.7986L10.0092 35.5008L14.1201 25.6373C14.5894 24.5111 15.9491 24.064 16.9952 24.6918L18.3063 25.4786C19.249 26.0442 19.5584 27.2646 18.9991 28.211L7.24656 48.0991C7.09041 48.3633 6.87745 48.5917 6.60287 48.7288C6.16911 48.9454 5.48065 49.2095 5.00882 49.0007C4.71247 48.8696 4.47725 48.5412 4.30969 48.225Z" :fill="color"/>
<path opacity="0.9" d="M2.11731 26.119C1.63971 25.5042 1.86384 24.6478 2.41426 24.0974L9.72061 16.7903C9.91205 16.5989 10.0626 16.3705 10.1632 16.1191L13.0117 9C13.6603 7.57375 15.7852 7.96678 15.8807 9.53064L16.0742 12.6982C16.1056 13.2129 15.937 13.7198 15.6037 14.1132L5.28232 26.293C4.70628 26.9728 3.74265 27.2019 2.96396 26.7689C2.80376 26.6798 2.64881 26.5887 2.51206 26.5003C2.36869 26.4077 2.23603 26.2718 2.11731 26.119Z" :fill="color"/>
<path opacity="0.9" d="M12.178 54.8459C11.723 54.35 11.6688 53.6267 11.931 53.0069L17.0095 41.0001L22.5264 28.5875C22.8203 27.9262 23.4762 27.5 24.1999 27.5C25.523 27.5001 26.4095 28.8601 25.8754 30.0706L15.347 53.9329C15.2552 54.1408 15.1297 54.3322 14.9645 54.4883C14.5202 54.9079 13.573 55.7086 13.0095 55.5C12.7389 55.3998 12.4384 55.1298 12.178 54.8459Z" :fill="color"/>
<path opacity="0.9" d="M23.1553 55.1692C22.6892 54.8064 22.596 54.1703 22.7443 53.5985L26.0106 41.0002L29.131 30.2978C29.3551 29.529 30.0598 29.0005 30.8606 29.0005C32.0757 29.0005 32.9423 30.1787 32.5804 31.3387L25.5699 53.8094C25.5303 53.9364 25.4789 54.0587 25.4092 54.172C25.1503 54.5926 24.44 55.6312 23.8092 55.5004C23.5751 55.4519 23.3503 55.3209 23.1553 55.1692Z" :fill="color"/>
<path opacity="0.9" d="M34.2667 50.4353C33.7327 50.2656 33.548 49.6826 33.6098 49.1257L34.5127 40.9997L35.4379 31.2757C35.4854 30.7766 35.7186 30.3136 36.0913 29.9783L36.1548 29.9211C37.5731 28.645 39.8028 29.8904 39.46 31.7673L36.3757 48.6536C36.3313 48.8964 36.2439 49.1298 36.0997 49.3301C35.7634 49.7974 35.0819 50.6176 34.5137 50.4998C34.4252 50.4814 34.3429 50.4595 34.2667 50.4353Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_fingers_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>