<template>
    <router-link
        :to="route"
        v-bind:class="['button', { first }]">
            {{ buttonName }}
    </router-link>
</template>
<script>
import { mapActions } from 'vuex'

export default {
	name: 'button-next',
	props: {
		buttonName: {
			type: String
        },
        route: {
            type: Object
        },
        first: {
            type: Boolean
        }
	}
}
</script>
<style lang="scss" scoped>
.button {
    margin-left: auto;
    margin-right: 10%;
    border: 2px solid #2D3E4F;
    background-color: #2D3E4F;
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    width: 65%;
    height: 10%;
    padding: 2%;
}
.button.first {
    border: #2D3E4F;
    background-color: #2D3E4F;
    color: white;
    text-decoration: none;
    text-align: center;
    width: 100%;
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    padding: 2%;
    font-size: 16px;
}
@media only screen and (min-width: 600px) {

}
</style>
