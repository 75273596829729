<template>
    
    <svg width="55" height="59" viewBox="0 0 55 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M26.1469 0.920359C18.3852 0.964967 7.8766 4.46721 2.78119 6.34962C1.00986 7.004 0.0195485 8.84403 0.382318 10.6972C1.39421 15.8663 3.44639 26.279 5.03876 33.9018C6.37799 40.3127 8.31788 49.0784 9.61155 54.8709C10.2448 57.7064 13.6526 58.9731 16.1312 57.4573C20.3283 54.8906 26.3646 51.8281 31.424 51.7119C36.5465 51.5943 42.6863 54.4505 47.1373 57.0165C49.8512 58.5811 53.504 56.8717 53.7077 53.7457C54.0808 48.0182 54.5185 39.9844 54.5111 33.9018C54.5015 26.0309 53.7387 14.8126 53.3775 9.93554C53.2672 8.44604 52.3284 7.16212 50.9295 6.63883C46.1009 4.83263 34.5426 0.872108 26.1469 0.920359Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>