<template>
    
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M5.13224 0.619097L17.5181 4.21533C18.1347 4.39438 18.6279 4.85862 18.8439 5.46334L23.3336 18.0346C23.4429 18.3405 23.476 18.6685 23.43 18.9901L22.626 24.6181C22.5542 25.121 22.1168 25.4901 21.6091 25.4763L4.98774 25.0267C4.36517 25.0099 3.78657 24.7054 3.46929 24.1695C2.62089 22.7364 1.04878 19.6037 1 16C0.955141 12.686 2.03276 5.73301 2.62434 2.18445C2.81952 1.01366 3.99237 0.288135 5.13224 0.619097Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>