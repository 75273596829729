<template>
<svg width="47" height="29" viewBox="0 0 47 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M46.9996 9.48299C46.9996 14.4535 35.1875 24 22.5 27.5C17.0004 29.0171 11.4996 27.9829 7.4996 25.9829C2.4996 23.4829 0 21.7608 0 19C0 14.0294 8.5 15.1667 14.5 12.5C19 10.5 22.6575 2.00945 27.9996 0.482997C31.4996 -0.517099 38.0893 0.14308 41.4996 1.48281C46.0831 3.28341 46.9996 7.59666 46.9996 9.48299Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_lower_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
