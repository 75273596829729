<template>
	<div class="viewer" :key="renderKey">
		<template v-if="!featureReleased">
			<Download :account="query.account" text="Download the DoctorPlan app to answer this questionnaire."></Download>
		</template>
		<!-- If the questionnaire content has been loaded -->
		<template v-else-if="questionnaireContent">
			<Header
					class="questionnaire-header"
					:questionnaire="questionnaireEvent"
					:content="questionnaireContent"
					:complete="complete">
			</Header>

			<el-row v-if="questionnaireEvent" style="height: 100%" class="full-background">
				<el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 10, offset: 7 }">


					<Expired v-if="questionnaireEvent.expiry_ref"
						:query="query"
						:expiry_ref="questionnaireEvent.expiry_ref"
						:expiry_handoff="questionnaireEvent.hasOwnProperty('expiry_handoff') ? questionnaireEvent.expiry_handoff : ''">
					</Expired>

					<!-- The welcome screen initially shows if the user has not started the questionnaire -->
					<Welcome v-else-if="showWelcome"
							:query="urlToken" 
							:questionnaire="questionnaireEvent" 
							:content="questionnaireContent">
					</Welcome>

					<Complete
							v-else-if="index === questions.length"
							v-bind:questionnaire="questionnaireContent"
							:event="questionnaireEvent"
							@patchError="patchError"
							v-bind:result="subQSavedResult()"
							:id="id">
					</Complete>
					<!-- Question viewer -->
					<template v-else-if="showQuestion">
						<ProgressBar
								v-bind:index="index"
								style="margin-top: 15px;"
								:length="questions.length - 1">
						</ProgressBar>
						<router-view
								:key="index"
								ref="question"
								class="question"
								:style="'height: ' + this.questionHeight + 'px'"
								v-bind:index="index"
								v-bind:saved="savedSelected"
								v-bind:result="savedResult()"
								:forward-skip="forwardSkip"
								:id="id"
								@changeHumanMapSide="changeHumanMapSide"
								@disableNext="disableNext"
								:questions="questions"
								:show-notice="showMandatoryNotice"
								v-bind:state="state"
								v-on:selected="selected"
								v-on:skipQuestion="skipQuestion">
						</router-view>
						<!-- Navigate to the next/previous question -->
						<nav>
							<span v-if="showQuestionIncompleteNotice" class="mandatory-notice-text">
								<template v-if="questions[this.index].type === 'unit'">* All fields are required </template>
								<template v-if="questions[this.index].type === 'date'">* Invalid date</template>
								<template v-if="questions[this.index].type === 'video_consent'">* You must watch the entire video to proceed</template>
							</span>
							<span class="notice-text">
								{{showQuestionCountText}}
							</span>
							<span v-if="!showMandatoryNotice && isMandatoryAndNotAnswered && questions[index].type === 'multi_question'" class="mandatory-notice-text">
								* You must answer this question to move forward
							</span>
							<span v-else-if="showMandatoryNotice && questions[index].type === 'multi_question' && isMandatoryAndNotAnswered" class="mandatory-notice-text shake-effect">
								* Complete all mandatory questions
							</span>
							<span v-else-if="showMandatoryNotice && isMandatoryAndNotAnswered && ['video', 'video_consent'].includes(questions[index].type)" class="mandatory-notice-text">
								* You must watch this video to move forward
							</span>
							<span v-else-if="showMandatoryNotice && isMandatoryAndNotAnswered" class="mandatory-notice-text">
								* You must answer this question to move forward
							</span>
							<router-link v-if="index > 0"
										 :to="'' + (disablePrev ? (index + 1) : backwardSkip[index-1]) + '?' + urlToken" class="previous"
										 :class="{ 'disabled' : disablePrev}"
										 >
								<span>Previous</span>
							</router-link>
							<router-link v-if="forwardSkip[index] === questions.length + 1 || index == questions.length - 1"
										 :to="'' + ((isMandatoryAndNotAnswered || showQuestionIncompleteNotice) ? index + 1 : parseInt(forwardSkip[index])) + '?' + urlToken"
										 @click.native="showMandatoryNoticeFunc"
										 :class="['next', 'submit', { 'disabled': isMandatoryAndNotAnswered || showQuestionIncompleteNotice }]">
										<span><img class="submit-icon" src="./image/send.png"> Submit</span>
							</router-link>
							<router-link v-else-if="index < questions.length - 1"
										 :to="'' + ((isMandatoryAndNotAnswered || showQuestionIncompleteNotice) ? index + 1 : parseInt(forwardSkip[index])) + '?' + urlToken"
										 @click.native="showMandatoryNoticeFunc"
										 :class="['next', { 'disabled': isMandatoryAndNotAnswered || showQuestionIncompleteNotice }]">
								<span>Next Question</span>
							</router-link>
						</nav>
					</template>



				</el-col>
			</el-row>
			<template v-else>
				<div class="loading-icon">
					<Spinner color="#833e93" class="icon"></Spinner>
					<div class="text">Loading...</div>
					<span class="sub-text">Just a few seconds</span>
				</div>
			</template>
			<ThankyouDialog
				v-if="showThankyouDialog && query.thanks && this.index === 0"
				:content="questionnaireContent"
				:questionnaire="questionnaireEvent || {}"
				:count="parseInt(query.thanks)"
				@close-up="showThankyouDialog = false">
			</ThankyouDialog>

		</template>
		<template v-else-if="complete">
			<Complete
				:id="id"
				:completed="complete">
			</Complete>
		</template>
		<!-- Otherwise show a loading dialog -->
		<template v-else>
			<Loading v-if="query.account === 'doctorplan'" color="#a54eae" text="Loading questionnaire..."></Loading>
			<GenericLoading v-else color="#a54eae" text="Loading questionnaire..."></GenericLoading>
		</template>

	</div>



</template>


<script>
import PromptDownload from '@/mixin/prompt-download'

import { mapActions, mapGetters } from 'vuex'
import Loading from '@/component/Loading'
import GenericLoading from '@/component/GenericLoading'
import Spinner from '@/component/icon/Spinner'
import Vue from 'vue'
import Expired from './Expired'
import Welcome from './Welcome'
import Thankyou from './Thankyou'
import Complete from './Complete'
import Question from './Question'
import Header from '../header/Header'
import ProgressBar from './ProgressBar'
import ThankyouDialog from './ThankyouDialog'

import { v4 as uuidv4 } from 'uuid'

const keyMap = {
	auth: true,
	verified: true,
	authenticated: true,
	handoff_token: true
}

export default {

	name: 'questionnaire-viewer',

	props: {
		id: {
			type: String
		},
		query: {
			type: Object
		},
		number: {
			type: String
		},
		start: {
			type: Boolean
		}
	},

	mixins: [ PromptDownload ],

	/**
	 * @desc Uses the vue-meta library to add tags dynamically
	 * Adds meta tags according to environment for Apple app clips
	 * @returns {{meta: Array}}
	 */
	metaInfo() {
		if (['livechair', 'gerdhelp', 'vgcc'].includes(this.query.account))
			return { meta: [] }

		let metaArray = []
		if (process.env.NODE_ENV === 'live' || process.env.NODE_ENV === 'production')
			metaArray.push({ name: 'apple-itunes-app', content: 'app-clip-bundle-id=com.doctorbox.member.Clip, app-id=1437144807'})
		else if (process.env.NODE_ENV === 'dev')
			metaArray.push({ name: 'apple-itunes-app', content: 'app-clip-bundle-id=com.doctorbox.member.dev.Clip, app-id=1449410069'})
		return {
			meta: metaArray
		}
	},

	watch: {
		number: function(newVal, oldVal) {
			this.showMandatoryNotice = false
			this.automaticCall = true
			this.maintainSkips()
			this.fillDefaultResult()

			// initiates a patch call to patch after every question
			let result = JSON.parse(localStorage.getItem(this.id + '_result'))
			if (result && (parseInt(newVal) > parseInt(oldVal)) && this.questions[this.index] && !this.questions[this.index].subQuestionnaire) {
				let transResult = this.getSubQuestionnaireAnswer(result)
				// const currentQuestion = this.questions[this.index]
				// const previousQuestion = this.questions[this.index - 1]

				// If the previous question was part of a sub-questionnaire and the current question is not
				// if(previousQuestion.subQuestionnaireId && !currentQuestion.subQuestionnaireId) {
				// 	this.patchQuestionnaire(transResult)
				// }

			}
			this.isPreviousCall = parseInt(oldVal) > parseInt(newVal) ? true : false

		}
	},

	data() {
		return {
			result: [],
			state: {},
			showThankYou: false,
			complete: false,
			featureReleased: true,
			backwardSkip: [],
			forwardSkip: [],
			renderKey: 0,
			showMandatoryNotice: false,
			automaticCall: true,
			questionHeight: '0',
			urlToken: this.$route.fullPath.split('?')[1],
			isPreviousCall: false,
			showQuestionIncompleteNotice: false,
			showThankyouDialog: true,
			showQuestion: false,
			expired: true,
			humanMapSide: null
		}
	},

	mounted() {
		this.showQuestionIncompleteNotice = false
		window.addEventListener("resize", this.resetHeight)
		// called to initially set the height.
		this.resetHeight()

		if (!this.questionnaireEvent) { // for opening the questionnaire using email link with handoff_token
			if (!this.query['handoff_token']) {
				this.complete = true
				return
			}
			this.logout()
				.then(() => {
					return this.handoff(this.query)
				})
				.then(() => {
					return this.loadEvent({ id: this.id, type: 'questionnaire', provider_survey: this.query.hasOwnProperty('provider_survey') ? this.query.provider_survey : false })
				})
				.then((event) => {

					if (Object.keys(event).length === 0 || event.completed) {
						return this.$router.push({
							name: 'questionnaire-complete',
							params: { completed: true },
							query: { account: this.query.account }
						})
					}
					this.loadObject({
						type: 'questionnaire',
						id: event.type.split(':')[1]
					}).then(_ => {

						if(event.question && Array.isArray(event.question))
							this.fillServerResult(event.question)

						this.showQuestion = true
						this.maintainSkips()
						this.fillAllResult()
						this.fillDefaultResult()
						this.callQueryQestresp()
						this.renderKey += 1
					})
				})
		}
		else if (this.query['handoff_token']) { // for thank you page
			this.handoff(this.query)
					.then(() => {
						return this.loadEvent({ id: this.id, type: 'questionnaire' })
					})
					.then((event) => {
						if (Object.keys(event).length === 0 || event.completed)
							return this.$router.push({
								name: 'questionnaire-complete',
								params: { completed: true },
								query: { account: this.query.account }
							})

						return this.loadObject({
							type: 'questionnaire',
							id: event.type.split(':')[1]
						}).then(_ => {

							if(event.question && Array.isArray(event.question))
								this.fillServerResult(event.question)

							this.showQuestion = true
							this.maintainSkips()
							this.fillAllResult()
							this.fillDefaultResult()
							this.callQueryQestresp()
						})
					}).then(_ => this.renderKey += 1)
		}

		// console.log('mounted', this.questionnaireEvent)
	},

	methods: {
		...mapActions('user', [
			'loadEvent',
			'addEvent',
			'loadQueryQuestOptions'
		]),
		...mapActions('content', [
			'loadObject'
		]),

		...mapActions('auth', [
			'handoff',
			'logout'
		]),
		patchQuestionnaire(questionData) {
			if(Array.isArray(this.result) && this.result.length === 0)
				throw 'Empty result pushed' + JSON.stringify(this.questionnaire)
			return this.addEvent({
				id: this.id,
				type: 'questionnaire',
				body: {
					question: questionData
				}
			}).catch((error) => {
				this.$message({
					showClose: true,
					message: 'Unable to send response. Error: You appear to be Offline.',
					type: 'error',
					duration: '0'
				})
				throw error
			})
		},
		patchError() {
			this.$router.go(-1)
			this.complete = false
		},
		initializeHandoff() {
			if (this.query.handoff_token)
				return this.handoff(this.query)
			return Promise.resolve()
		},
		/**
		 * @param  {String} side
		 * @desc Changes the side of the human map eg: Front, Back, Side, Body
		 */
		changeHumanMapSide(side) {
			this.humanMapSide = side
		},
		/**
		 * @param  {Boolean} shouldDisable
		 * @desc Disables the Next/Submit button when emitted from child components
		 */
		disableNext(shouldDisable) {
			this.showQuestionIncompleteNotice = shouldDisable
		},
		skipQuestion() {
			
			if(this.isPreviousCall)
				this.$router.push('' + (this.disablePrev ? (this.index + 1) : this.backwardSkip[this.index-1]) + '?' + this.urlToken)
			else
				this.$router.push('' + parseInt(this.forwardSkip[this.index]) + '?' + this.urlToken)
		},
		/**
		 * Fill results from server to localstorage
		 * @param {[*]} questionList 
		 */
		fillServerResult(questionList) {
			let flatQuestionList = questionList.flatMap((ques) => {
				let questArr = [ques]
				if(ques && ques.questionnaire && Array.isArray(ques.questionnaire) && ques.questionnaire.length > 0)
					questArr.push(...ques.questionnaire[0].question)
				return questArr
			})
			let state = this.questions.map((ques) => {
				const quesFind = flatQuestionList.find((answeredQuestion) => answeredQuestion.id === ques.id )
				
				if(quesFind) {
					if(ques.type === 'text' && quesFind.answer && Array.isArray(quesFind.answer) && quesFind.answer.length > 0)
						return quesFind.answer[0] ? [quesFind.answer[0].answer] : null
					return quesFind ? quesFind.answer : null
				}
					
				return null
			})
			localStorage.setItem(this.id, JSON.stringify({ question: state}))
		},
		/**
		 * @desc Fills the result array using the state array
		 * Used for loading all the previous question results on mid-questionnaire reload.
		 */
		fillAllResult() {
			this.state = this.savedState()
			if (this.state.hasOwnProperty('question')) {
				this.result = []
				this.state.question.forEach((ans, i) => this.select(ans, i))
			}
		},
		/**
		 * @param  {Array} selected
		 * @param  {Number} index
		 * @desc Validates the selected array and calls specific
		 * functions for single select and multi select questions.
		 */
		select(selected, index) {
			let currentQuestion = this.questions[index]
			if (selected === null || selected === undefined)
				this.result.push({ id: currentQuestion.id, answer: null, index: index })
			else if (currentQuestion.type === 'box_select' || currentQuestion.type === 'small_image_select' || currentQuestion.type === 'large_image_select')
				this.multiSelectedQuestion(selected, currentQuestion, index)
			else
				this.selectedQuestion(selected, currentQuestion, index)
		},
		/**
		 * @param  {Array} choice
		 * @param  {Object} currentQuestion
		 * @desc Converts Single Select questions this.state array
		 * format to result object according to the question type
		 */
		selectedQuestion(choice, currentQuestion, index) {
			var answer, value, choiceText = null
			let reqObj = {}

			if (currentQuestion.hasOwnProperty('options')) {

			}
			// Is a selection question in object format with defined values for each index
			else if (currentQuestion.type === 'multi_question') {
				reqObj = { type: currentQuestion.type }
				answer = choice
			} else if (currentQuestion.type === 'long_text_bubble') {
				reqObj = { type: currentQuestion.type }
				answer = choice
			} else if (currentQuestion.type === 'date') {
				answer = choice
			} else if (currentQuestion.type === 'sign_document') {
				answer = choice
			} else if (currentQuestion.type === 'unit') {
				answer = choice
			} else if (currentQuestion.type === 'query') {
				answer = choice
			}

			this.result.push({ id: currentQuestion.id, answer: choice, version: 2, index })
			try {
				localStorage.setItem(this.id + '_result', JSON.stringify(this.result))
			} catch (error) {
				console.error('Setting local storage ', error)
			}
			
		},
		/**
		 * Convert linear result into sub-questionnaire result
		 */
		getSubQuestionnaireAnswer(result) {
			let transResult = result
			if(Array.isArray(result)) {
				transResult = []
				// Go through each question to check if part of
				// sub-questionnaire or parent
				result.filter((event) => event && event.answer && (event.answer.length !== 0)).forEach((question) => {
					if(!question)
						return
					let pushQuestion = {...question}
					try {
						if(pushQuestion.hasOwnProperty("index"))
							delete pushQuestion.index
					} catch (error) {
						console.error('Error in pushQuestion', error)
					}
					let questionContent
					if(question.hasOwnProperty("index") && this.questions[question.index] && this.questions[question.index].id  === question.id)
						questionContent = this.questions[question.index]
					if(!questionContent)
						questionContent = this.questions.find((qContent) => question.id === qContent.id)
					if(questionContent.type === 'section_separator')
						return
					// If question is part of sub-questionnaire
					if(questionContent.branchOut) {
						const subQIndex = transResult.findIndex((event) => event.id === questionContent.subQuestionnaireId)
						const subQAns = transResult[subQIndex] ? transResult[subQIndex].answer : null
						if(subQIndex !== -1 && Array.isArray(subQAns) && subQAns.find(({id}) => id === questionContent.selection)) {
							if(!transResult[subQIndex].questionnaire)
								transResult[subQIndex].questionnaire = []
							let subBranchIndex = transResult[subQIndex].questionnaire.findIndex(({selection}) => selection === questionContent.selection)
							if(subBranchIndex === -1) {
								transResult[subQIndex].questionnaire.push({
									type: `questionnaire:${questionContent.subQuestionnaire}`,
									selection: questionContent.selection,
									question: [pushQuestion]
								})
								subBranchIndex = transResult[subQIndex].questionnaire.length - 1
							}
							else {
								transResult[subQIndex].questionnaire[subBranchIndex].question.push({...pushQuestion})
							}
							try {
								if(this.isSubQPatched(questionContent.subQuestionnaireId)) {
									const patchedQuestionId = this.isSubQPatched(questionContent.subQuestionnaireId).find(({ type }) => type.includes(questionContent.subQuestionnaire)).id
									if(patchedQuestionId)
										transResult[subQIndex].questionnaire[subBranchIndex].id = patchedQuestionId
								}
							} catch (error) {
								console.error('Error in isSubQPatched', error)
							}
							
						}

					}
					else if(questionContent.subQuestionnaireId) {
						/**
						 * Index of sub-questionnaire events in `transResult`
						 */
						const subQIndex = transResult.findIndex((event) => event.id === questionContent.subQuestionnaireId)
						if(subQIndex === -1) {
							transResult.push({
								id: questionContent.subQuestionnaireId,
								version: 2,
								questionnaire: [{
									type: `questionnaire:${questionContent.subQuestionnaire}`,
									question: [pushQuestion]
								}]
							})
							// If the sub questionnaire is already patched, use the id with which it was patched before.
							try {
								if (this.isSubQPatched(questionContent.subQuestionnaireId)) {
									transResult[transResult.length - 1].questionnaire[0].id = this.isSubQPatched(questionContent.subQuestionnaireId)[0].id
								}
							} catch (error) {
								console.error('Error in isSubQPatched', error)
							}
							
						}
						else {
							//console.log(subQIndex, transResult[subQIndex], questionContent)
							transResult[subQIndex].questionnaire[0].question.push({...pushQuestion})
						}
					}
					else
						transResult.push(pushQuestion)
				})
			}
			return transResult
		},
		isSubQPatched(id) {
			return this.getPatchedSubQ[id]
		},
		/**
		 * @param  {Array} choice
		 * @param  {Object} currentQuestion
		 * @desc Converts the multiSelect questions this.state array format
		 * to result object according to the question type
		 */
		multiSelectedQuestion(decision, currentQuestion, index) {
			this.result.push({ id: currentQuestion.id, answer: decision, index: index })
			localStorage.setItem(this.id + '_result', JSON.stringify(this.result))
		},
		resetHeight(){
			// reset the body height to that of the inner browser
			// substracting 60px to give space to navigation buttons
			this.questionHeight = window.innerHeight - 130
		},
		/**
		 * @param
		 * @func showMandatoryNoticeFunc
		 * @desc Shows mandatory notice only when next/submit button is clicked manually
		 */
		showMandatoryNoticeFunc() {
			if (this.automaticCall) this.automaticCall = false
			else if (this.isMandatoryAndNotAnswered)
				this.showMandatoryNotice = true
		},

		fillDefaultResult() {
			let curIdx = this.index
			if (!this.result[curIdx] && this.questions[curIdx]) {
				this.result[curIdx] = { id: this.questions[curIdx].id, answer: null, version: 2, index: this.index }
				if (this.questions[curIdx].type === 'radio') this.result[curIdx].value = null
			}
		},

		maintainSkips() {
			this.backwardSkip = this.questions.map((_, index) => index + 1)
			this.forwardSkip = this.questions.map((_, index) => index + 2)
			const branchTypes = ['select', 'long_text_bubble', 'box_select', 'large_image_select', 'small_image_select', 'binary']
			// maintaining backwardSkip array

			let curQuesState = localStorage.getItem(this.id) ? localStorage.getItem(this.id) : '{"question":[]}'
			if (curQuesState) {
				curQuesState = JSON.parse(curQuesState).question
				this.questions.forEach((question, index) => {
					const branchOutOptions = question.options ? question.options.filter(({questionnaire}) => questionnaire) : []
					if(branchTypes.includes(question.type) && !question.branchOut && branchOutOptions.length > 0) {
						let currentState = curQuesState[index] ? curQuesState[index] : []
						if (currentState && Array.isArray(currentState)) {
							let branchQIndex = index + 1
							const branchIndexes = branchOutOptions.filter((option) => currentState.find((answer) => answer.id === option.id))
								.map((option) => {
									if(!this.questions[branchQIndex].branchOut)
										return {}
									while(this.questions[branchQIndex].subQuestionnaire !== option.questionnaire)
										branchQIndex += 1
									let startIndex = branchQIndex

									while(this.questions[branchQIndex + 1] && this.questions[branchQIndex + 1].subQuestionnaire === option.questionnaire)
										branchQIndex += 1
									let endIndex = branchQIndex
									return {
										index: startIndex,
										subQuestionnaire: option.questionnaire,
										end: endIndex
									}
								})
							while(this.questions[branchQIndex] && this.questions[branchQIndex].branchOut)
								branchQIndex += 1
							branchIndexes.push({
								index: branchQIndex
							})
							let branchStartIndex = index
							for (let i= 0; i < branchIndexes.length; i++) {
								const branchIndex = branchIndexes[i]
								for (let j = branchStartIndex; j < branchIndex.index; j++) {
									this.forwardSkip[j] = branchIndex.index + 1
									this.backwardSkip[j] = branchStartIndex + 1
								}
								if(branchIndex.end)
									branchStartIndex = branchIndex.end
							}
						}
					}
					if (question.type === 'binary') {
						if (curQuesState[index] && Array.isArray(curQuesState[index]) && curQuesState[index].length > 0) {
							let currentStateAnswer = curQuesState[index][0].value
							if (((currentStateAnswer === 'Yes' || currentStateAnswer === 1) && question.hasOwnProperty('skip_on_yes')) ||
									((currentStateAnswer === 'No' || currentStateAnswer === 0) && question.hasOwnProperty('skip_on_no'))) {
								let skipNum = question.skip_on_yes || question.skip_on_no
								let skipSub = 0, cachedSubQuestionnaire = null, skipMax = index + skipNum
								// Check for subquestionnaires skips only if outside of subquestionnaire
								if(!question.subQuestionnaireId) {
									for (let i = index + 1; i <= skipMax + 1; i++) {
										const forwardQuestion = this.questions[i]
										if(forwardQuestion.branchOut) {
											skipSub += 1
											skipMax += 1
										}
										else if(forwardQuestion.subQuestionnaireId) {
											if(cachedSubQuestionnaire === forwardQuestion.subQuestionnaireId) {
												skipSub += 1
												skipMax += 1
											}
											else
												cachedSubQuestionnaire = forwardQuestion.subQuestionnaireId
										}
									}
								}
								skipNum += skipSub
								for (let i = index+1; i <= (index + skipNum); i++)
									this.backwardSkip[i] = index + 1
								for (let i = index; i <= (index + skipNum); i++){
									this.forwardSkip[i] = index + skipNum + 2
								}
									
							}
						}
					}
				})
			}
		},

		selected(selected) {
			//console.log('Viewer selected()', selected)
			var state
			if (Object.keys(this.state) > 0)
				state = this.state
			else {
				// Get the state from local storage, and initialize if not yet initialized
				state = localStorage.getItem(this.id)
				if (state)
					state = JSON.parse(state)
				else
					state = { question: [] }
			}
			// Set the question state
			if (selected.length === 0) state.question[this.index] = null
			else state.question[this.index] = selected


			// fill the state of skipped questions
			let curIdx = this.index
			let curQuestion = this.questions[curIdx]
			let skips = curQuestion.hasOwnProperty('skip_on_yes') ? curQuestion.skip_on_yes : curQuestion.hasOwnProperty('skip_on_no') ? curQuestion.skip_on_no : 0
			if (curQuestion.hasOwnProperty('skip_on_yes') || curQuestion.hasOwnProperty('skip_on_no')) {
				// if there is no skip on yes in current question and selected choice is not yes
				// and there is no skip on no in current question and selected choice is not no
				if ((curQuestion.hasOwnProperty('skip_on_yes') && selected[0].value === 'Yes') || (curQuestion.hasOwnProperty('skip_on_no') && selected[0].value === 'No')) {
					for (let i  = curIdx + 1; i <= (curIdx + skips); i++) {
						state.question[i] = []
						
						this.result[i] = { id: this.questions[i].id, answer: null, version: 2, index: i }
						// if (this.questions[i].type === 'radio') this.result[i].value = null
					}
				}
			}

			// Update variable that manages which answers are chosen for each question
			// (handles saving of decisions when previous button is clicked)
			// If screen is hard-refreshed, the localStorage data will be used to populate answers
			Vue.set(this.state, 'question', state.question)
			// save to local storage
			localStorage.setItem(this.id, JSON.stringify(state))
			// console.log('THIS.STATE', JSON.parse(JSON.stringify(this.state)))
			// console.log('STATE', JSON.parse(JSON.stringify(state)))
			this.maintainSkips()
			if(curQuestion.hasOwnProperty('skip_on_yes') || curQuestion.hasOwnProperty('skip_on_no')) {
				skips = this.forwardSkip[this.index] - this.index - 2
				if ((curQuestion.hasOwnProperty('skip_on_yes') && selected[0].value === 'Yes') || (curQuestion.hasOwnProperty('skip_on_no') && selected[0].value === 'No')) {
					for (let i  = curIdx + 1; i <= (curIdx + skips); i++) {
						state.question[i] = []
						
						this.result[i] = { id: this.questions[i].id, answer: null, version: 2, index: i }
						// if (this.questions[i].type === 'radio') this.result[i].value = null
					}
				}
			}
			Vue.set(this.state, 'question', state.question)
			localStorage.setItem(this.id, JSON.stringify(state))

			// changes the router to go to next question
			if(this.questions[this.index] && this.questions[this.index].type === 'binary' && !this.isPreviousCall) {
				this.$router.push('' + (this.isMandatoryAndNotAnswered ? this.index + 1 : parseInt(this.forwardSkip[this.index])) + '?' + this.urlToken)
			}
			if(this.questions[this.index] && this.questions[this.index].ref_question) {
				this.skipQuestion()
			}
		},
		savedState() {
			var state
			if (Object.keys(this.state).length > 0) {
				state = this.state
				return state
			}
			else {
				state = localStorage.getItem(this.id)
				if (state) {
					return JSON.parse(state)
				}
				else
					return { question: [] }
			}
		},
		savedResult() {
			var result
			if (this.result.length > 0) {
				result = this.result
				return result
			}
			else {
				result = localStorage.getItem(this.id + '_result')
				if (result) {
					return JSON.parse(result)
				}
				else
					return []
			}

		},
		subQSavedResult() {
			let result
			if (this.result.length > 0)
				result = JSON.parse(JSON.stringify(this.result))
			else {
				result = localStorage.getItem(this.id + '_result')
				if (result) {
					result = JSON.parse(result)
				}
				else
					result = []
			}
			return this.getSubQuestionnaireAnswer(result)

		},
		isSubQuestionMandatory(subId) {
			let isMandatory = false
			this.questions[this.index].questions.filter(subQ => subQ.id === subId)
					.forEach(subQ => {
						if (subQ.validations) {
							subQ.validations.forEach(type => {
								if (type.type === 'mandatory' && type.mandatory)
									isMandatory = true
							})
						}
					})
			return isMandatory
		},
		callQueryQestresp() {
			const queryQuestTypeObject = this.queryQuestionParams
				Object.keys(queryQuestTypeObject).forEach((qType) => {
					let queryQuestParam = {
						qType,
						params: queryQuestTypeObject[qType]
					}

					this.loadQueryQuestOptions(queryQuestParam).then((resp) => {
						console.log('Query Question options: ', resp)
					})
				})
			}

	},

	computed: {
		...mapGetters('user', [
			'getEvent',
			'getPatchedSubQ'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		isMandatoryAndNotAnswered() {
			return this.isMandatory && !this.isAnswered
		},
		disablePrev() {
			return this.showQuestionIncompleteNotice && this.questions[this.index].type === 'image_upload'
		},
		// returns if the current question is answered or not
		// counts answers with only spaces as not answered.
		isAnswered() {
			if (this.state && this.state.hasOwnProperty('question') && this.state.question[this.index]) {

				// if multi_question we need to check if the mandatory subquestion is answered or not
				// because when subquestion is left unanswered no default obj is inserted in the answer array
				// i.e no order, so we need to check this via id
				let isMandatorySubQAnswered = true
				if (this.questions[this.index].type === 'multi_question') {
					this.questions[this.index].questions.forEach(subQ => {
						if (subQ.hasOwnProperty('validations'))
							subQ.validations.forEach(type => {
								if (type.type === 'mandatory' && type.mandatory) {
									let foundAnswered = false
									try {
										this.state.question[this.index].forEach(ans => {
											if (ans.id === subQ.id) {
												if (ans.hasOwnProperty('answer') && ans.answer && ans.answer.length) {
													if (subQ.type === 'text' && ans.answer[0].hasOwnProperty('answer') && ans.answer[0].answer.length)
														foundAnswered = true
													else if (subQ.type === 'unit') {
														let unitTypeAnswer = ans.answer[0]
														if (unitTypeAnswer.hasOwnProperty('answer') && unitTypeAnswer.answer && unitTypeAnswer.answer.length && 
															unitTypeAnswer.hasOwnProperty('value') && unitTypeAnswer.value && unitTypeAnswer.value.length) {
																foundAnswered = true
															}
													} else if (subQ.type === 'date' && ans.answer[0] && Object.keys(ans.answer[0]).length) {
														foundAnswered = true
													} else if (['select', 'scroller'].includes(subQ.type) && ans.answer.length && ans.answer[0]) {
														foundAnswered = true
													} else if(subQ.type === 'query' && ans.answer && ans.answer.length) {
														foundAnswered = true
													}
												}
											}
										})
									} catch (error) {
										console.error(error)
									}
									
									isMandatorySubQAnswered = isMandatorySubQAnswered && foundAnswered
								}
							})
					})
				}
				// counts the number of unanswered questions
				let isNotAnswered = this.state.question[this.index].filter((ans, i) => {
					// for image upload type image description is not compulsory
					// For eg: state[this.index] = ["image url", "some description"]
					// we dont need to check index 1
					if (this.questions[this.index].type === 'image_upload' && i === 1)
						return false
					if (typeof ans === 'string') return !ans || !ans.trim().length

					else if (typeof ans === 'object' && ans.hasOwnProperty('value')) {
						if (ans.value === null || ans.value === undefined)
							return true
						else return !String(ans.value).trim().length
					} else if (typeof ans === 'object' && ans.hasOwnProperty('answer') && Array.isArray(ans.answer) && ans.answer.length) {
						// counts the number of unanswered subquestions
						// unanswered then return true
						// answered then return false
						return ans.answer.filter(subQAns => !subQAns)
								.filter(_ => this.isSubQuestionMandatory(ans.id)).length !== 0
					}
					else return false
				}).length === 0

				return isNotAnswered && isMandatorySubQAnswered
			}
			return false
		},
		// returns if the question is mandatory or not
		isMandatory() {
			let isMandatory = false
			if (this.questions[this.index].hasOwnProperty('validations')) {
				this.questions[this.index].validations.forEach(type => {
					if (type.type === 'mandatory') isMandatory = type.mandatory
				})
			}
			// for multi_question type mandatory key in parent question does not mean anything
			// for multi_question sub questions are checked if at least one of them is mandatory or not.
			if (this.questions[this.index].type === 'multi_question') {
				let isMultiMandatory = false
				this.questions[this.index].questions.forEach(subQ => {
					if(subQ.validations) {
						subQ.validations.forEach(type => {
						if (type.type === 'mandatory')
							isMultiMandatory = isMultiMandatory || type.mandatory
						})
					}
				})
				return isMultiMandatory
			}
			return isMandatory
		},
		/**
		 * @func index
		 * @desc Convert the human-readable number in the URL to the index in the question array
		 */
		index() {
			if (this.number) {
				let current = parseInt(this.number) - 1
				if ( current >= this.questions.length)
					this.complete = true
				return current
			}
			return -1
		},
		showWelcome() {
			return this.number == null && !this.questionnaireEvent.expiry_ref
		},
		showQuestionCountText() {
			const currentQuestion = this.questions[this.index]

			// Check if humanMapSide is not null and max_selection type is present in validation array
			if (this.humanMapSide && 
				currentQuestion.type === 'human_map' && 
				currentQuestion.hasOwnProperty('validations') && 
				currentQuestion.validations.filter(v => v.type === 'max_selection').length) {
					// Get the max_selection value from the validation array
					let maxSelectionValue = null
					let maxSelectionObj = currentQuestion.validations.filter(v => v.type === 'max_selection')[0]
					// Only For swelling type 'Front' and 'Face' are the same, for other type just match the side as is.
					let maxSelectionSideObj = maxSelectionObj.value.filter(mx => (mx.type === this.humanMapSide) || 
											(currentQuestion.sub_type === 'swelling' && mx.type === 'Face' && this.humanMapSide === 'Front'))
					if (maxSelectionSideObj.length) {
						maxSelectionValue = maxSelectionSideObj[0].value
					}
					if (maxSelectionValue) {
						if (maxSelectionValue === 1)
							return "* Can select only 1 location"
						else
							return "* Can select up to " + maxSelectionValue + " locations"
					}
			}

			if(!currentQuestion.limit_select) return "";

			if(!["select", "box_select", "large_image_select", "small_image_select", "long_text_bubble"].includes(currentQuestion.type)) return ""

			if(currentQuestion.limit_select && (currentQuestion.max_selection == 1 || !currentQuestion.max_selection))
				return ""


			let totalChoices
			if(currentQuestion.limit_select) {
				totalChoices = currentQuestion.max_selection
				return "*Can select up to " + totalChoices + " choices"
			}	
			return "";	
			// if (this.state && this.state.hasOwnProperty('question') && this.state.question[this.index]) {
			// 	const selectedChoices = this.state.question[this.index].filter(ele => {
			// 		return ele.value !== "undefined"
			// 	})
			// 	const remainingQuestions = totalChoices - selectedChoices.length
			// 	if(currentQuestion.limit_select) {
			// 		return "*Can select up to " + remainingQuestions + " choices"
			// 	}
			// 	return "";
			// }
			
		},

		savedSelected() {
			return this.savedState().question[this.index]
		},

		questionnaireEvent() {
			return this.getEvent(this.id)
		},

		questionnaireContent() {
			if (this.questionnaireEvent) {
				let questionnaire = { ...this.getObject('questionnaire', this.questionnaireEvent.type.split(':')[1])}
				if (!questionnaire || !questionnaire.hasOwnProperty('question')) return
				let compress = 0
				// Fetches sub questionnaires and flattens the questionnaire list
				let flattenedList = []
				questionnaire.question.forEach(question => {
					if (question.type === 'questionnaire') {
						let subQuestionObject = this.getObject('questionnaire', question.questionnaire)
						if (!subQuestionObject) return
						if (subQuestionObject && subQuestionObject.hasOwnProperty('question'))
							subQuestionObject.question.forEach(q => {
								q.subQuestionnaireId = question.id
								q.subQuestionnaire = question.questionnaire
								flattenedList.push(JSON.parse(JSON.stringify(q)))
							})
					}
					else if(Array.isArray(question.options) && question.options.length > 0 && question.options.some(({questionnaire}) => questionnaire)) {
						// Add the main question to the content
						flattenedList.push(question)
						// Add the branche-wise questionnaire
						flattenedList.push(
							...question.options
								.filter(({questionnaire}) => questionnaire)
								.flatMap(({questionnaire, id}) => {
									let subContent = this.getObject('questionnaire', questionnaire)
									if(subContent && subContent.question) {
										return subContent.question
											.map((subQ) => ({
												...JSON.parse(JSON.stringify(subQ)),
												subQuestionnaireId: question.id,
												subQuestionnaire: questionnaire,
												selection: id,
												branchOut: true
											}))
									}
									return []
								})
						)
							
					}
					else
						flattenedList.push(question)
				})
				questionnaire.question = flattenedList
				// setting question number for question skip
				// questionnaire.question.forEach((question, index) => {
				// 	if (!question.hasOwnProperty('index')) question.index = index + 1 - compress
				// 	if (question.hasOwnProperty('skip_on_yes') || question.hasOwnProperty('skip_on_no')) {
				// 		let part = 'a'
				// 		compress += parseInt(question.skip_on_yes || question.skip_on_no)
				// 		for (let i = index+1; i <= index+(question.skip_on_yes || question.skip_on_no); i++) {
				// 			console.log(i, questionnaire.question[i])
				// 			questionnaire.question[i].index = question.index + part
				// 			part = String.fromCharCode(part.charCodeAt(0) + 1)
				// 		}
				// 	}
				// })
				let separatorCount = 0
				questionnaire.question.forEach((question, index) => {
					// if(question.type === 'section_separator')
					// 	separatorCount += 1
					question.index = index
				})
				return questionnaire
			}
			return {}
		},

		questions() {
			return this.questionnaireContent && this.questionnaireContent.question
		},

		queryQuestionParams() {
			let myObj = {}
			this.questions && this.questions.filter((quest) => (quest.type === 'query' || quest.type === 'multi_question'))
				.forEach((filtQuest) => {
					if(filtQuest.type === 'query') {
						const queryTypeArray = filtQuest.query_type.split(":")
						if(myObj.hasOwnProperty(queryTypeArray[0])) myObj[queryTypeArray[0]].push(queryTypeArray[1])
						else {
							myObj[queryTypeArray[0]] = new Array()
							myObj[queryTypeArray[0]].push(queryTypeArray[1])
						}
					} else if(filtQuest.type === 'multi_question') {
						filtQuest.questions.filter((subQuest) => subQuest.type === 'query')
							.forEach((filtQuerySubQuest) => {
								const subQuestQueryTypeArray = filtQuerySubQuest.query_type.split(":")
								if(myObj.hasOwnProperty(subQuestQueryTypeArray[0])) myObj[subQuestQueryTypeArray[0]].push(subQuestQueryTypeArray[1])
								else {
									myObj[subQuestQueryTypeArray[0]] = new Array()
									myObj[subQuestQueryTypeArray[0]].push(subQuestQueryTypeArray[1])
								}
							})
					}
			})

			return myObj
		}
	},

	components: {
		Loading,
		GenericLoading,
		Spinner,
		Expired,
		Welcome,
		Thankyou,
		Question,
		ProgressBar,
		Header,
		Complete,
		ThankyouDialog
	}
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>