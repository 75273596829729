<template>
    <div class="modal-backdrop" role="dialog">
      <div class="modal" ref="modal">
        <section class="modal-body">
            Are you sure you want to log out of DoctorPlan?
        </section>
        <div class="buttons-logout">
            <MainButton @clickButton="cancel" :cancel=true></MainButton>
            <MainButton @clickButton="close" :logoutRight=true></MainButton>
        </div>
      </div>
    </div>
</template>

<script>
import MainButton from './button/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {

        }
    },
    props: {

    },
    mounted() {

    },
    methods: {
        ...mapActions('auth', [
            'logout'
		    ]),  
        cancel() {
            this.$emit('close')
        },
        close(event) {
            return this.logout()
              .then((result) => {
                  return this.$router.push({ path: 'login'})
              })
        }
    },
    components: {
        MainButton
    }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 20px;
}

.buttons-logout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 20px;
}
</style>