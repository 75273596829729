<template>
    
    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M33.807 9.02779C32.731 13.5056 31.3993 18.8127 30.293 22.5003C28.793 27.5003 24.293 34.0003 20.793 36.5003C17.293 39.0003 8.93198 37.9236 5.79305 29.0003C3.38035 22.1415 -0.938584 6.09703 1.79308 1.99973C4.79305 -2.50001 13.5564 2.04365 19.7931 2.49999C21.6754 2.63772 24.4524 2.3788 27.1667 2.00601C31.2229 1.44891 34.7635 5.04673 33.807 9.02779Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>