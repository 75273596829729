<template>
	<div style="-webkit-overflow-scrolling: auto !important" v-if="questions" ref="questionRef">
		<div class="question-title">
			<!-- Display the question text -->
			<h3 v-if="question.hasOwnProperty('type') && question.type !== 'section_separator'">{{ totalNoCount - altSkippedQuestions - refQuestionCount }}. {{ question.question }}</h3>
			<p v-if="question.hasOwnProperty('subheader') && question.subheader" style="font-size: 14px; padding: 5px 0px; margin: 5px 0px;">{{ question.subheader}}</p>
			<div v-if="question.hasOwnProperty('question_image') && question.question_image" >
				<img :src="question.question_image" style="max-width: 312px; width: 100%;" />
			</div>
		</div>
		<!-- Show the underlying text -->
		<div :is="getComponent(question.type)"
			:question="question"
			:saved="saved"
			:show-notice="showNotice"
			:show-arrow="showArrow"
			:questions="questions"
			:result="result"
			:event-id="id"
			@changeHumanMapSide="changeHumanMapSide"
			@disableNext="disableNext"
			v-on:selected="selected"
			v-scroll="handleScroll"
			@scroll-into="doScroll"
			class="question-content">
		</div>
	</div>
</template>

<script>
import Questions from './question/components'
import questionComponent from './question/map'
import { mapActions } from 'vuex'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
	props: {
		questions: {
			type: Array,
			required: true
		},
		number: {
			type: String,
			required: true
		},
		saved: {
			type: Array
		},
		result: {
			type: Array
		},
		id: {
			type: String
		},
		forwardSkip: {
			type: Array
		},
		showNotice: {
			type: Boolean
		}
	},
	data() {
		return {
			showArrow: true
		}
	},
	mounted() {
		if(this.question && this.question.ref_question) {
			const refQ = this.question.ref_question
			const qResult = this.result.find((currResult) => currResult && currResult.id === refQ)
			if(qResult && qResult.answer){
				console.log('qResult' ,qResult)
				this.selected(qResult.answer)
			}
			else{
				console.log('qResult' ,qResult)
				this.selected([])
			}
				
				
		}

		this.$nextTick(() => {
			if(this.$refs.questionRef)
				this.$refs.questionRef.addEventListener('scroll', this.handleArrow)
		});

	},
	computed: {
		index() {
			return parseInt(this.number) - 1
		},
		question() {
			return this.questions[this.index]
		},
		length() {
			return this.questions.length
		},
		/**
		 * Counts total skipped questions before this
		 */
		skippedQuestions() {
			let skippedNo = 0
			try {
				for (let skipIndex = 0; skipIndex < this.index; skipIndex++) {
					const aElement = this.result[skipIndex];
					const qElement = this.questions.find(({ type }) => type === 'binary')
					if(aElement && qElement && (qElement.skip_on_yes || qElement.skip_on_no)) {
						if(aElement.answer && Array.isArray(aElement.answer) && aElement.answer.length === 1) {
							if(aElement.answer[0].value === 'Yes' && qElement.skip_on_yes)
								skippedNo += qElement.skip_on_yes
							if(aElement.answer[0].value === 'No' && qElement.skip_on_no)
								skippedNo += qElement.skip_on_no
						}
					}
				}
			} catch (error) {
				console.error(error)
			}
			
			return skippedNo
		},
		altSkippedQuestions() {
			let slicedForward = this.forwardSkip.slice(0, this.index)
			return [...new Set(slicedForward.filter((sliceKey) => this.questions[sliceKey - 1].type === 'section_separator'))].length
		},
		totalNoCount() {
			let slicedForward = this.forwardSkip.slice(0, this.index)
			return [...new Set(slicedForward)].length + 1
		},
		refQuestionCount() {
			return this.questions.slice(0, this.index).filter((question) => question.ref_question).length
		}
	},

	methods: {
		...mapActions('user', [
			'addEvent'
		]),
		/**
		 * @param  {String} side
		 * @desc Changes the side of the human map eg: Front, Back, Side, Body
		 */
		changeHumanMapSide(side) {
			this.$emit('changeHumanMapSide', side)
		},
		/**
		 * @desc Function to emit the state of next button
		 * @param didDisable {Boolean} 
		 */
		disableNext(didDisable) {
			this.$emit('disableNext', didDisable)
		},
		skipQuestion() {
			this.$emit('skipQuestion')
		},
		handleArrow(){
			this.showArrow = false
		},
		selected(selected) {
			if (selected === null || selected === undefined) return
			if (this.question.type === 'box_select' || this.question.type === 'small_image_select' || this.question.type === 'large_image_select')
				this.multiSelectedQuestion(selected)
			else if(this.question.type === 'video' || this.question.type === 'video_consent')
				this.videoSelectedQuestion(selected)
			else {
				this.selectedQuestion(selected)
			}
			this.$emit('selected', selected)
		},
		convertAnsToObj(choice) {
            let reqObj = {
                id: uuidv4(),
				value: String(choice),
                // display_text: String(choice)
			}
            return reqObj
        },
		selectedQuestion(choice) {
			if (Array.isArray(choice) && this.question.type === 'text') {
				choice = [{
					id: uuidv4(),
					answer: choice[0]
				}]
			} else if (typeof choice !== 'object')
				choice = this.convertAnsToObj(choice)
			
			var answer, value, choiceText = null
			let reqObj = {}

			//console.log('Question after Selected', choice)


			if (this.question.hasOwnProperty('options')) {
				
			}
			// Is a selection question in object format with defined values for each index
			else if (this.question.select && Object.keys(this.question.select).length > 0) {
				answer = {
					[choice.toString()]: this.question.select[choice.toString()]
				}
			} else if (this.question.type === 'multi_question') {
				reqObj = {
					type: this.question.type
				}
				answer = choice
			} else if (this.question.type === 'long_text_bubble') {
				reqObj = {
					type: this.question.type
				}
				answer = choice
			} else if (this.question.type === 'date') {
				answer = choice
			} else if (this.question.type === 'sign_document') {
				answer = choice
			} else if (this.question.type === 'unit') {
				answer = choice

				// if the question is unit type and if numerical value or unit is missing
				// Disable Next button
				if (!choice.length)
					this.disableNext(false)
				else {
					if (!choice[0].answer && !choice[0].value)
						this.disableNext(false)
					else if (!choice[0].answer || !choice[0].value)
						this.disableNext(true)
					else
						this.disableNext(false)
				}
					
			}

			Vue.set(this.result, this.index, {
				id: this.question.id,
				answer: choice,
				version: 2,
				index: this.index
			})

			// fill the state of skipped questions
			let curIdx = this.index
			let curQuestion = this.questions[curIdx]
			let skips = curQuestion.hasOwnProperty('skip_on_yes') ? curQuestion.skip_on_yes : curQuestion.hasOwnProperty('skip_on_no') ? curQuestion.skip_on_no : 0
			if (curQuestion.hasOwnProperty('skip_on_yes') || curQuestion.hasOwnProperty('skip_on_no')) {
				// if there is no skip_on_yes in current question and selected choice is not yes
				// and there is no skip_on_no in current question and selected choice is not no
				if ((curQuestion.hasOwnProperty('skip_on_yes') && choice.value === 'Yes') || (curQuestion.hasOwnProperty('skip_on_no') && choice.value === 'No')) {
					for (let i  = curIdx + 1; i <= (curIdx + skips); i++) {
						this.result[i] = { id: this.questions[i].id, answer: [], index: this.index }
						if (this.questions[i].type === 'radio') this.result[i].value = null
					}
				}
			}

			localStorage.setItem(this.id + '_result', JSON.stringify(this.result))
		},
		videoSelectedQuestion(seen) {
			if(seen && (seen !== null || seen !== undefined)) {
				Vue.set(this.result, this.index, {
					id: this.question.id,
					answer: seen,
					version: 2,
					index: this.index
				})

				localStorage.setItem(this.id + '_result', JSON.stringify(this.result))
			}
		},
		multiSelectedQuestion(decision) {
			Vue.set(this.result, this.index, { id: this.question.id, answer: decision, version: 2, index: this.index })
			localStorage.setItem(this.id + '_result', JSON.stringify(this.result))
		},
		getComponent(id) {
			return questionComponent[id]
		},
		home() {

		},
		handleScroll(evt, el) {
			if (window.scrollY > 50) {
				el.setAttribute(
					'style',
					'opacity: 1; transform: translate3d(0, -10px, 0)'
				)
			}
			return window.scrollY > 100
		},
		doScroll() {
			this.$refs.questionRef.scrollBy({
				top: 70,
				left: 0,
				behavior: 'smooth'
			})
		}
	},

	components: {
		...Questions
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
.question-content {
	text-align: center;
	margin-top: 20px;
	padding: 0px 25px 40px 25px;
}
.question-title {
	height: auto;
	min-height: 0px;
	padding: 0 25px;

	h3 {
		text-align: left;
		margin: 10px 0px;
	}
}
</style>
