<template>
    <svg width="151" height="139" viewBox="0 0 151 139" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.22468 66.8213C5.29527 52.1621 23.0519 23.1304 32.7236 8.06805L32.7236 8.06802C35.5707 3.63406 36.9943 1.41708 39.0478 0.581303C41.1014 -0.254472 44.0526 0.427029 49.9549 1.79003C56.006 3.18738 64.2325 4.5 73.785 4.5C83.3034 4.5 91.2727 3.19674 97.0695 1.805C102.81 0.426878 105.68 -0.262185 107.655 0.453827C109.631 1.16984 111.127 3.1797 114.119 7.19942C125.044 21.879 145.972 51.4658 149.209 66.8213C153.354 86.486 148.214 110.727 144.968 122.882C144.003 126.498 143.52 128.306 142.026 129.684C140.532 131.061 138.624 131.408 134.808 132.101L134.808 132.101C121.371 134.541 93.5832 139.093 73.785 139C54.1508 138.907 28.2797 134.627 15.2201 132.214C11.2167 131.475 9.21492 131.105 7.69635 129.635C6.17778 128.165 5.75745 126.242 4.9168 122.395C2.21873 110.05 -1.81243 86.0948 2.22468 66.8213Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'upper_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>