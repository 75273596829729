<template>
<svg width="67" height="63" viewBox="0 0 67 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M15.7397 19.4944L56.5552 1.16911C59.8777 -0.322637 63.7855 1.07102 65.4143 4.32857C67.1107 7.72137 65.7002 11.8461 62.2815 13.4897L45.8392 21.3946C43.6846 22.4305 41.9724 24.2039 41.0129 26.3936L27.6357 56.9212C25.1905 62.5013 18.3662 64.6167 13.1934 61.3981L5.21698 56.435C2.28348 54.6097 0.5 51.3995 0.5 47.9444V40.4661C0.5 38.2209 1.25557 36.041 2.64508 34.2774L11.9806 22.4284C12.9787 21.1616 14.2684 20.155 15.7397 19.4944Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_shoulder',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
