<template>
	<header class="header">
		<!-- Redirect back to the landing screen or to the home page -->
		<router-link v-if="authenticated" :to="(contact || sms) ? '/setting' : '/'" class="nav">
			<NavIcon :color="'black'"></NavIcon>
		</router-link>
		<!-- <HeaderIcon v-if="landing" class="icon"></HeaderIcon> -->
		<span v-bind:class="['title', { 'title-left': !authenticated }]">
			{{ contact ? 'Contact Support' : sms ? 'Text Message' : 'Account Settings' }}
		</span>
	</header>

</template>

<script>
import { mapState } from 'vuex'

import NavIcon from './icon/Back'
import HeaderIcon from './icon/HeaderIcon'

export default {

	props: {
		contact: {
			type: Boolean
		},
		sms: {
			type: Boolean
		}
	},

	computed: {
		...mapState('auth', [
			'authenticated'
		])
	},

	components: {
		NavIcon,
		HeaderIcon
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
