<template>
<svg width="50" height="61" viewBox="0 0 50 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.825443 8.57486C0.487712 7.02627 1.99375 5.61885 3.52936 6.01136C7.09636 6.92309 13.0618 8.16983 20 8.5C26.8113 8.82413 38.6313 4.12569 46.3844 0.636101C48.3075 -0.229477 50.3298 2.16008 49.1302 3.89463C44.6028 10.4412 38.1683 20.1297 35.5 26C31.7747 34.1957 23.886 48.7753 20.1058 55.6763C18.7851 58.0876 16.3917 59.7098 13.6655 60.0654C9.51487 60.6068 5.57142 58.1015 4.6981 54.0079C3.60405 48.8795 2.58036 41.8244 3.5 36C4.72984 28.211 2.2091 14.9193 0.825443 8.57486Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_ribs',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
