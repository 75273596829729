<template>
<svg width="75" height="134" viewBox="0 0 75 134" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.837374 13.4621C1.03407 5.43653 8.55646 -0.0829419 16.4879 1.15857C23.4918 2.25489 31.7531 3.26576 38.3832 3.2612C44.2179 3.25717 51.3218 2.46502 57.7061 1.5246C66.4372 0.238487 74.5189 7.06387 74.1514 15.8815L69.8013 120.284C69.5005 127.501 63.5618 133.198 56.3381 133.198H35.3581C29.2079 133.198 23.844 129.057 22.3097 123.101C17.929 106.097 9.10575 71.9214 4.52891 54.6972C0.989995 41.379 0.552642 25.0799 0.837374 13.4621Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
