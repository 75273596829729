<template>
    
    <svg width="67" height="69" viewBox="0 0 67 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.848082 38.5778C1.95908 47.508 5.14529 59.702 6.90333 66.0351C7.53116 68.2968 9.9924 69.4864 12.2053 68.7039C17.7168 66.7548 27.6831 63.644 35.1489 63.644C42.1093 63.644 51.2432 66.348 56.9142 68.2935C59.5121 69.1847 62.3236 67.3559 62.4472 64.6121L63.8428 33.6308L66.0614 16.5981C66.4212 13.8362 63.8871 11.4806 61.1228 11.8214C54.7361 12.6088 43.7016 13.1766 31.8507 10.2139C18.0267 6.75787 11.0258 3.56715 5.76092 1.15231C3.33498 0.0396154 0.651749 1.67966 0.504208 4.34453C0.0605409 12.3579 -0.504639 27.7048 0.848082 38.5778Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>