<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="59" height="51" viewBox="0 0 59 51">
	    <defs>
	        <linearGradient id="i-as-a" x1="100%" x2="0%" y1="0%" y2="100%">
	            <stop offset="0%" stop-color="#2C6CA3"/>
	            <stop offset="100%" stop-color="#2D3E4F"/>
	        </linearGradient>
	        <linearGradient id="i-as-b" x1="0%" x2="89.581%" y1="50%" y2="43.571%">
	            <stop offset="0%"/>
	            <stop offset="100%" stop-opacity="0"/>
	        </linearGradient>
	    </defs>
	    <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
	        <path fill="#2D3E4F" d="M0 8h32v32H0z"/>
	        <circle cx="33.5" cy="23.5" r="24.5" fill="url(#i-as-a)" stroke="#FFF" stroke-width="2"/>
			<svg version="1.1" width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="21px" y="11px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
		 	   	<g fill="#FFFFFF"><path d="M797.3,167.9c-2.1-12.5-8.8-24.2-19.8-32.3c-15.7-11.5-32.2-21.7-48.9-31.1c1.3-19.9-9.3-39.5-28.5-48.6c-122.8-58.4-268-61.1-392.8-7.6c-19.6,8.4-31,27.9-30.2,48c-18.4,9.5-36.4,20-53.7,32c-13.7,9.5-21.1,24.7-21.4,40.2C100.1,253.7,35,381.9,35,525c0,256.4,208.6,465,465,465c256.4,0,465-208.6,465-465C965,381.6,899.7,253.2,797.3,167.9z M489.2,67.7c6,0,10.8,4.9,10.8,10.8c0,6-4.9,10.8-10.8,10.8c-6,0-10.8-4.9-10.8-10.8C478.3,72.6,483.2,67.7,489.2,67.7z M437.6,69.7c6,0,10.8,4.9,10.8,10.8c0,6-4.9,10.8-10.8,10.8c-6,0-10.8-4.9-10.8-10.8C426.7,74.6,431.6,69.7,437.6,69.7z M386.2,74.9c9.8,0,17.7,7.9,17.7,17.7c0,9.8-7.9,17.7-17.7,17.7c-9.8,0-17.7-7.9-17.7-17.7C368.4,82.9,376.4,74.9,386.2,74.9z M500,923.1c-219.5,0-398.1-178.6-398.1-398.1c0-122.9,56-232.9,143.8-306c11.9,1.5,24.4-1,35.1-8.4c63.2-43.9,137.4-67,214.6-67c80.8,0,157.9,25.2,222.8,72.9c9,6.6,19.3,9.7,29.7,9.7c4.2,0,8.4-0.6,12.5-1.7c84.2,73,137.8,180.5,137.8,300.5C898.1,744.5,719.5,923.1,500,923.1z"/></g>
		 	</svg>
	    </g>
	</svg>

</template>
<script>
export default {
	name: 'fitbit-icon'
}
</script>
