<template>
	<p v-html="rendered"></p>
</template>
<script>
import Markdown from '.'

export default {
	name: 'markdown-text',
	extends: Markdown,

	computed: {
		rendered() {
			return this.text
				.replace(/\*\*(.*)\*\*/g, '<strong>$1</strong>')
				.replace(/\*(.*)\*/g, '<em>$1</em>')
				.replace(/\!\[\]\((.*)\)/g, '<img src="$1">')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../style/markdown.scss";
img {
	display: block;
	width: 80%;
}
</style>
