<template>
    
    <svg width="30" height="79" viewBox="0 0 30 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M15.5 30.5C18.4424 41.9311 24.9713 56.7044 29.1695 65.6225C31.1739 69.8805 29.2309 75.0076 24.8613 76.7555L23.9144 77.1342C23.3071 77.3772 22.6723 77.5447 22.0242 77.6331L19.1033 78.0314C15.0606 78.5826 11.2508 76.0033 10.2613 72.045L2.67909 41.7164C2.55995 41.2398 2.48496 40.7533 2.45506 40.263L0.346085 5.67577C0.158451 2.59862 2.60345 0 5.68631 0C8.42309 0 10.7222 2.06313 11.061 4.77885C11.8538 11.1322 13.4379 22.4888 15.5 30.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>