<template>
<svg width="88" height="39" viewBox="0 0 88 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M65.5086 19.202V0.86163C65.5086 0.367644 64.7368 0.173399 64.4827 0.597042C62.1273 4.52455 55.5932 13.0661 43.6203 12.3618C31.926 11.6739 25.9391 4.2409 23.7029 0.629306C23.4419 0.207694 22.6439 0.407384 22.6439 0.90327V19.202L0.755615 32.8822L4.85968 38.3542H35.8681H43.6203H51.3724H83.2929L87.8529 32.4262L65.5086 19.202Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'neck_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
