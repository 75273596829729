<template>
    
    <svg width="67" height="49" viewBox="0 0 67 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M6.57886 8.38781C7.0739 6.69603 8.14031 5.22097 9.7312 4.46186C13.3046 2.75679 21.0013 1.48057e-08 33.4136 0C45.8212 -1.48e-08 53.5168 2.75467 57.0919 4.45989C58.6854 5.21991 59.7512 6.69822 60.2526 8.39096C63.931 20.81 65.8088 29.4633 66.7584 40.4774C67.2133 45.7533 62.2745 49.7532 57.0758 48.7456C40.1528 45.4653 27.4992 45.4394 10.6274 48.7338C5.44529 49.7457 0.501281 45.7884 0.828505 40.5186C1.51302 29.4947 2.93586 20.8378 6.57886 8.38781Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
