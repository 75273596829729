<template>
    
    <svg width="44" height="114" viewBox="0 0 44 114" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.06213 26.5C5.07288 34.4883 1.36474 46.7796 0.676406 49.1115C0.599867 49.3708 0.580292 49.6437 0.613482 49.912C3.64875 74.4491 4.52875 89.4928 4.24214 111.551C4.21406 113.712 7.09845 114.378 7.97338 112.402C11.2833 104.926 15.9014 93.9916 18.5625 85.5006C20.4102 79.6051 22.69 70.4981 23.0216 69.1655C23.0499 69.0516 23.0836 68.9533 23.1309 68.8458C23.876 67.1545 30.5068 51.9715 33.0625 41.5006C34.1728 36.9514 35.3228 29.9776 35.5297 28.7039C35.5517 28.5685 35.5871 28.436 35.6359 28.3078L43.3135 8.14872C43.4735 7.72853 43.4889 7.26916 43.3368 6.84604C42.7547 5.22667 41.096 1.1164 39.0625 0.500567C36.8199 -0.17857 34.6678 -2.44677e-05 33.0625 0.500567C31.4572 1.00116 24.7424 4.1358 20.5625 8.00057C16.3826 11.8653 12.698 19.2194 9.06213 26.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'thumb_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>