<template>
<svg width="139" height="53" viewBox="0 0 139 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.609375 14.2896C0.609375 6.34898 7.44008 0.115825 15.3563 0.738039C29.995 1.88865 52.6481 3.41748 69.6683 3.41748C86.6884 3.41748 109.341 1.88865 123.98 0.73804C131.896 0.115826 138.727 6.34899 138.727 14.2896V38.7891C138.727 46.2311 132.694 52.264 125.252 52.264H14.0843C6.6423 52.264 0.609375 46.2311 0.609375 38.7891V14.2896Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'lower_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
