<template>
    
    <svg width="64" height="135" viewBox="0 0 64 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M23.5854 130.09L18.0772 110.278C18.0258 110.093 17.9682 109.914 17.9017 109.734C16.9784 107.228 9.79587 87.6179 7 76.4995C1.91797 56.2897 0.807362 24.3083 0.566261 12.2823C0.508415 9.39692 2.29877 6.83622 5.04123 5.93755C11.0646 3.96377 21.8278 0.787661 29.5 0.499502C38.6691 0.155121 50.8806 2.08254 57.8969 3.38222C61.1572 3.98614 63.4106 6.89458 63.2287 10.2053C62.6626 20.5074 61.0971 42.532 57.5 57.4995C53.5076 74.112 51.144 100.845 51.0064 102.426C51.0019 102.478 50.9972 102.526 50.9917 102.577L48.2419 127.988C47.8325 131.771 44.4144 134.603 40.6102 134.703C39.612 134.73 38.5078 134.775 37.2993 134.845C34.698 134.995 32.0297 135.016 29.8385 134.99C26.8893 134.956 24.3754 132.932 23.5854 130.09Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>