<template>
    
    <svg width="16" height="33" viewBox="0 0 16 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 16.0137C0 24.846 1.13778 32.2865 7.965 32.006C14.3369 32.006 15.93 24.846 15.93 16.0137C15.93 7.18136 17.2954 -0.259224 7.965 0.0213574C0.910205 0.0224118 0 7.18136 0 16.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>