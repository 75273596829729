<template>
	<div class="dashboard">
		<Sidebar v-bind:class="{ active: sidebar }" @hide="hideSidebar"></Sidebar>
		<Header @sidebar="showSidebar"></Header>
		<template v-if="content" class="content">
			<SettingsSection></SettingsSection>
			<!-- <FitbitSection></FitbitSection> -->
			<ArticleSection v-if="content.article.length > 0" :article="content.article"></ArticleSection>
			<ExerciseSection v-if="content.exercise.length > 0" :exercise="content.exercise"></ExerciseSection>
			<!-- <QuestionnaireSection></QuestionnaireSection> -->
		</template>
		<template v-else>
			<Loading style="opacity:0.8" text="Loading content..."></Loading>
		</template>
		<Copyright></Copyright>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Loading from '@/component/Loading'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'
import Copyright from './copyright/Copyright'
import Sections from './section/components'

export default {
	name: 'home-dashboard',

	mounted() {
		this.loadUser()
		this.loadContent()
	},

	data() {
		return {
			sidebar: false
		}
	},

	methods: {
		...mapActions('user', [
			'loadUser',
			'loadContent'
		]),
		showSidebar() {
			this.sidebar = true
		},

		hideSidebar() {
			this.sidebar = false
		}
	},

	computed: {
		...mapGetters('user', [
			'getContent'
		]),
		content() {
			return this.getContent
		}
	},

	components: {
		Loading,
		Header,
		Sidebar,
		Copyright,
		...Sections
	}
}
</script>

<style scoped>
	.content {

	}
	.dashboard {
		/*display: flex;*/
		/*flex-direction: column;*/
	}
	.copyright {
		text-align: center;
		font-size: 12px;
		padding: 20px 0;
	}
</style>
