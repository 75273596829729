<template>
<svg width="67" height="69" viewBox="0 0 67 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.81086 38.6686C5.84998 38.7241 5.93801 38.7078 5.92497 38.6409C5.40985 36.0001 2.43322 16.7661 12.5026 7.94875C23.0528 -1.28923 33.4514 0.0703042 33.4514 0.0703042C33.4514 0.0703042 46.7354 -0.161175 53.4939 7.51839C60.2297 15.1719 62.7498 22.0902 61.3968 39.7788C61.3936 39.8358 61.4539 39.8782 61.4946 39.8374C61.9348 39.3973 63.816 37.9563 65.2439 39.8065C67.9565 43.3162 65.6662 52.8802 63.1525 57.5277C63.1525 57.5277 61.0724 61.0096 58.919 57.6467C58.8913 57.6027 58.8261 57.5929 58.8131 57.6434C58.531 58.6474 55.5 68.5 55.5 68.5C55.5 68.5 45.5 63 35 63C19.5 63 12.5 68.5 12.5 68.5C12.5 68.5 9.89711 61.8332 8.77934 58.3721C8.76467 58.3297 8.71739 58.3167 8.69131 58.3541C8.45168 58.6867 7.44263 59.8718 5.87607 58.8562C4.06662 57.6874 -1.68776 46.9024 1.99308 39.1789C1.99308 39.1789 3.94599 36.0914 5.81086 38.6686Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'head_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
