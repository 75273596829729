<template>
    
    <svg width="40" height="17" viewBox="0 0 40 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9596 1.0054C26.1103 0.832485 34.3471 4.86038 37.5577 6.57281C38.3602 7.00082 38.7133 7.94258 38.4258 8.8054C38.2904 9.21148 38.0292 9.55734 37.667 9.78557C35.4063 11.2103 26.6328 16.4539 19.9596 16.4539C13.6384 16.4539 4.96207 12.6899 2.0217 11.324C1.39004 11.0306 1 10.3963 1 9.69983C1 9.09271 1.29612 8.52749 1.80734 8.20002C4.48226 6.4866 13.2649 1.1936 19.9596 1.0054Z" fill="white" :stroke="color" stroke-linecap="round"/>
<circle cx="19.2583" cy="8.72995" r="5.61765" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'around_right_eye',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
