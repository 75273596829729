<template>
    
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M23.4992 15.2618C20.4961 24.2618 15.5035 22.2618 8.50231 19.2618C1.50231 15.2618 -1.49768 15.2618 1.50116 6.26176C3.00043 0.261897 3.99927 -1.23796 13.5 1.26196C20.9992 4.26202 26.5023 6.26176 23.4992 15.2618Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_elbow_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>