<template>
<svg width="96" height="33" viewBox="0 0 96 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M96 16.5C96 31.5 74.5097 33 48 33C21.4903 33 0 33 0 16.5C0 2.5 21.4903 0 48 0C74.5097 0 96 0 96 16.5Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'center_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
