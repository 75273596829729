import ChecklistsLanding from './landing/Landing'
import ChecklistsViewer from './viewer/Viewer'

import ChecklistTask from './viewer/Task'

export default [
	{
		path: '/checklist',
		name: 'checklists-landing',
		// Import the Checklists landing screen
		component: ChecklistsLanding
		// component: DownloadView
	},
	{
		path: '/checklist/:id',
		name: 'checklists-viewer',
		props: (route) => ({
			id: route.params.id,
			query: route.query,
			taskId: route.params.taskId
		}),
		// Import the Checklists viewer
		component: ChecklistsViewer,
		//component: ChecklistsDownload,

		meta: {
			link: true
		},

		children: [
			{
				path: 'task/:taskId',
				name: 'checklist-task',
				props: true,
				// Import the Checklists viewer
				component: ChecklistTask
			}
		]
	}
]
