<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="105" height="139" viewBox="0 0 105 139">
        <g fill="none" fill-rule="evenodd">
            <path fill="#FFEDE8" d="M87.376 120H26.622C24.08 120 22 117.876 22 115.282V28.718C22 26.122 24.08 24 26.622 24h60.754C89.92 24 92 26.122 92 28.718v86.564c0 2.594-2.081 4.718-4.624 4.718z"/>
            <path fill="#FFF" stroke="#C45531" stroke-width="1.5" d="M82.376 117H21.622C19.08 117 17 114.876 17 112.282V25.718C17 23.122 19.08 21 21.622 21h60.754C84.92 21 87 23.122 87 25.718v86.564c0 2.594-2.081 4.718-4.624 4.718z"/>
            <path fill="#C45531" d="M38 25h28v-9H38z"/>
            <path fill="#FFF" d="M46 16h12v-3H46z"/>
            <path fill="#C45531" stroke="#C45531" stroke-width="1.5" d="M46 16h12v-3H46z"/>
            <path fill="#FFEDE8" stroke="#C45531" stroke-width="1.5" d="M23 111h59V31H23z"/>
            <g stroke="#C45531" stroke-linecap="round" opacity=".203">
                <g stroke-width=".75">
                    <path d="M52.231 65.679v-13.42M52.231 92.269v-13.42M71.741 72.595H58.433M46.505 72.595H33.198"/>
                </g>
                <path d="M47.054 67.748l-1.44-1.388"/>
                <path stroke-dasharray="1.478,2.216" d="M43.512 63.674l-3-3"/>
                <path d="M39.094 59.225l-1.438-1.39M66.54 86.86l-1.44-1.388"/>
                <path stroke-dasharray="1.478,2.216" d="M62.313 81.549l-3-3"/>
                <path d="M58.58 78.336l-1.437-1.388M66.267 57.287l-1.388 1.44"/>
                <path stroke-dasharray="1.478,2.216" d="M61.917 61.79l-3 3"/>
                <path d="M57.193 67.169l-1.39 1.438M48.118 77.05l-1.39 1.438"/>
                <path stroke-dasharray="1.478,2.216" d="M45.004 80.867l-3 3"/>
                <path d="M40.83 84.322l-1.388 1.44"/>
            </g>
            <text fill="#C45531" font-family="ProximaNova-Semibold, Proxima Nova" font-size="44" font-weight="500">
                <tspan x="47" y="87">!</tspan>
            </text>
            <circle cx="101" cy="33" r="3" fill="#FFF" stroke="#C45531"/>
            <circle cx="9" cy="17" r="3" fill="#FFF" stroke="#C45531"/>
            <circle cx="1.5" cy="75.5" r="1.5" fill="#C45531"/>
            <circle cx="100.5" cy="100.5" r="1.5" fill="#C45531"/>
            <circle cx="69.5" cy="1.5" r="1.5" fill="#C45531"/>
            <ellipse cx="53.5" cy="134.5" fill="#EEE" fill-rule="nonzero" rx="43.5" ry="4.5"/>
        </g>
    </svg>



</template>
<script>
export default {
	name: 'checklists-none-icon',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
// @import "./style.scss";
    svg {
        padding-bottom:20px;
    }
</style>
