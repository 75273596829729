<template>
    <div class="select-container">
        <div class="select">
            <div v-for="option in question.options"
                :key="option.id"
                :class="['choice-blocks', { selected: isSelected(option) }]"
                @click="select(option)">
                {{ option.value }}
            </div>
            <div v-show="question.other" class="option-other">
                <input ref="other" :value="other" @input="other = $event.target.value" placeholder="Other" v-on:keyup.13="selectOther">
                <button :class="[other.trim().length ? 'btn-active' : '']" @click="selectOther">ADD</button>
                <span class="character-count" :style="charactersLeft <= 0 ? 'color: #da4e4e' : ''">
                    {{ charactersLeft }} character{{ charactersLeft > 1 ? 's' : '' }}
                </span>
            </div>
        </div>
    </div>
</template>


<script>
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'

    export default {
        name: 'questionnaire-select',
        extends: Question,
        watch: {
            other(value) {
                if (value.length > this.defaultLimit)
                    this.other = this.other.substr(0, this.defaultLimit)
            }
        },
        data() {
            return {
                other: '',
                defaultLimit: 100
            }
        },
        mounted() {
            if (this.saved) {
                // filling saved answers
                this.saved.forEach((selected) => {
                    let found = false
                    // checking if question object has the option 
                    this.question.options.forEach(option => {
                        if (typeof selected === 'object' && option.value === selected.value)
                            found = true
                    })
                    // if option not found that means it was "other" option and
                    // does not have it's question in question object
                    // we push the "other" choice as a new option in the question
                    if (!found)
                        this.question.options.push({ 'id': uuidv4(), 'value': String(selected.value) })
                })
                this.$emit('selected', this.selected)
            }
        },

        methods: {
            selectOther() {
                this.other = this.other.trim()
                if (this.other !== '') {
                    if (this.question.hasOwnProperty('options')) {
                        if (this.question.options.filter(option => option.value === this.other).length)
                        return
                    }
                    else if (this.question.option.filter(option => option === this.other).length)
                        return

                    let reqObj = {
                        version: 2, // version indicating that this is qv2 format
                        value: this.other,
                        id: uuidv4()
                    }
                    

                    if (this.question.hasOwnProperty('options'))
                        this.question.options.push(reqObj)
                    else 
                        this.question.option.push(this.other)
                    this.select(reqObj)
                }
                this.other = ''
            }
        },

        computed: {
            charactersLeft() {
                return this.defaultLimit - this.other.length
            }
        }
    }
</script>

<style lang="scss" scoped>
    $choice-size: 50px;
    $choice-font-size: 14px;

    @import "style.scss";

    $background: #eeeeee;
    $choice-border: darken($background, 5%);
    $background-hover: lighten($background, 5%);

    .select-container {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }
    .select {
        position: relative;
        display: inline-block;
        margin-bottom: 40px;
        width: 100%;
    }

    .choice-blocks {
        padding: 10px;
        width: 100%;
        height: auto;
        border-radius: 4px;
        color: #333333;
        background-color: #eeeeee;
        text-align: left;
        line-height: 25px;
        font-weight: 500;
        margin-bottom: 15px;
        cursor: pointer;
        white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
        white-space: -webkit-pre-wrap;          /* Chrome & Safari */
        white-space: -pre-wrap;                 /* Opera 4-6 */
        white-space: -o-pre-wrap;               /* Opera 7 */
        white-space: pre-wrap;                  /* CSS3 */
        word-wrap: break-word;                  /* Internet Explorer 5.5+ */
        word-break: break-word;
        white-space: normal;
    }
    .selected {
        background: $selected-background;
        border: 2px solid $selected-color;
        color: $selected-color;
        font-weight: 600;
    }
    .add-button, .add-button:hover {
        margin-left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: none;
        background-color: #666666;
        color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        float: left;
    }

	.option-other {
		input {
			width: 80%;
			float: left;
			border: 1px solid #999999;
			outline: none;
			height: 40px;
			line-height: 40px;
			font-size: $choice-font-size;
			padding-left: 10px;
		}
		input:focus {
			outline: none;
		}
		.btn-active {
			background-color: $selected-color;
			color: #ffffff;
		}
		button {
			background-color: #ffffff;
			width: 20%;
			height: 40px;
			line-height: 40px;
			border: 1px solid #999999;
			border-left: none;
			font-size: $choice-font-size;
			color: #999999;
			padding: 0;
		}
		button:focus {
			outline:0;
		}
		.character-count {
			float: right;
			color: #666666;
			font-size: 13px;
			margin-top: 7px;
		}
	}
</style>
