<template>
<svg width="69" height="36" viewBox="0 0 69 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M68.9762 16.6032C68.9762 19.9444 69.4717 29.6963 65.9442 32.3008C59.7906 36.8444 48.4901 35.9234 36.383 35.9234C24.276 35.9234 10.3225 36.8444 4.16889 32.3008C0.64142 29.6963 0 19.9444 0 16.6032C0 7.43352 15.4408 0 34.4881 0C53.5353 0 68.9762 7.43352 68.9762 16.6032Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'around_belly_button',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
