<template>
    
    <svg width="50" height="95" viewBox="0 0 50 95" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M31.5 0.999838C33.8151 0.999838 35.9547 0.905097 37.9082 0.749297C44.1649 0.250275 50.6243 6.00409 49.6519 12.2048C47.9856 22.8302 45.9653 37.0181 45.5 46C44.7917 59.6728 46.4487 76.1165 47.5716 85.175C48.059 89.1068 45.6673 92.7752 41.7453 93.3363C37.8058 93.8998 32.0972 94.4408 24.5 94.5009C14.7857 94.5777 7.52896 90.5475 3.4031 87.4582C0.861419 85.555 0.367087 82.1475 1.58544 79.2153C4.39899 72.444 9.25884 59.5451 11 47.5C12.4307 37.6025 12.6893 21.9382 12.6645 10.8857C12.6512 4.95203 19.1426 0.185604 25.0524 0.718281C26.9845 0.892438 29.1348 0.999837 31.5 0.999838Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>