export default {
    head_f: {
        name: "Head Front"
    },
    throat: {
        name: "Throat"
    },
    right_upper_arm_f: {
        name: "Right Upper Arm (F)"
    },
    right_lower_arm_f: {
        name: 'Right Lower Arm (F)'
    },
    right_hand_f: {
        name: "Right Hand (F)"
    },
    upper_body: {
        name: "Upper Body"
    },
    lower_body: {
        name: "Lower Body"
    },
    left_upper_arm_f: {
        name: "Left Upper Arm (F)"
    },
    left_lower_arm_f: {
        name: "Left Lower Arm (F)"
    },
    left_hand_f: {
        name: "Left Hand (F)"
    },
    right_upper_leg_f: {
        name: "Right Upper Leg (F)"
    },
    right_lower_leg_f: {
        name: "Right Lower Leg (F)"
    },
    right_knee: {
        name: "Right Knee"
    },
    left_knee: {
        name: "Left Knee"
    },
    right_foot_f: {
        name: "Right Foot (F)"
    },
    left_upper_leg_f: {
        name: "Left Upper Leg (F)"
    },
    left_lower_leg_f: {
        name: "Left Lower Leg (F)"
    },
    left_foot_f: {
        name: "Left Foot (F)"
    },
    head_b: {
        name: "Head Back"
    },
    neck: {
        name: "Neck"
    },
    right_upper_arm_b: {
        name: "Right Upper Arm (B)"
    },
    right_elbow: {
        name: "Right Elbow"
    },
    right_lower_arm_b: {
        name: "Right Lower Arm (B)"
    },
    right_hand_b: {
        name: "Right Hand (B)"
    },
    upper_back: {
        name: "Upper Back"
    },
    lower_back: {
        name: "Lower Back"
    },
    left_upper_arm_b: {
        name: "Left Upper Arm (B)"
    },
    left_lower_arm_b: {
        name: "Left Lower Arm (B)"
    },
    left_elbow: {
        name: "Left Elbow"
    },
    left_hand_b: {
        name: "Left Hand (B)"
    },
    right_buttock: {
        name: "Right Buttock"
    },
    left_buttock: {
        name: "Left Buttock"
    },
    right_upper_leg_b: {
        name: "Right Upper Leg (B)"
    },
    right_lower_leg_b: {
        name: "Right Lower Leg (B)"
    },
    right_foot_b: {
        name: "Right Foot (B)"
    },
    left_foot_b: {
        name: "Left Foot (B)"
    },
    left_upper_leg_b: {
        name: "Left Upper Leg (B)"
    },
    left_lower_leg_b: {
        name: "Left Lower Leg (B)"
    },
    right_upper_body: {
        name: "Right Upper Body (F)"
    },
    right_lower_body: {
        name: "Right Lower Body (F)"
    },
    left_upper_body: {
        name: "Left Upper Body (F)"
    },
    left_lower_body: {
        name: "Left Lower Body (F)"
    },
    left_neck: {
        name: "Left Neck (B)"
    },
    right_neck: {
        name: "Right Neck (B)"
    },
    right_upper_back: {
        name: "Right Upper Back (B)"
    },
    right_lower_back: {
        name: "Right Lower Back (B)"
    },
    left_upper_back: {
        name: "Left Upper Back (B)"
    },
    left_lower_back: {
        name: "Left Lower Back (B)"
    },
    left_groin: {
        name: 'Left Groin'
    },
    right_groin: {
        name: 'Right Groin'
    }
}