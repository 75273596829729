<template>
  <div v-bind:class="['input', { error: error }]">
    <input
      type="text"
      :value="value"
      autofocus="true"
      @input="change"
      @keydown="handleKeyDown"
      v-on:keydown.13="enter"
      placeholder="e.g. (123) 456-7890"
      maxlength="14"
    />

    <label>Phone Number</label>
    <label v-if="error" class="error">
      {{ error }}
    </label>
    <label v-if="secondary" class="forgot" @click="altNext">
      {{ secondary }}
    </label>
  </div>
</template>

<script>
export default {
  name: "auth-phone-input",
  props: ["value", "secondary", "error", "change"],
  methods: {
    handleKeyDown: (event) =>  {
      if(!['0','1','2','3','4','5','6','7','8','9','Backspace'].includes(event.key)) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>