<template>
    <div>
        <div class="settings-sms">
            <p class="title">Please enter your mobile phone number.</p>
            <p class="message">It will only be used to send you text messages from your doctor's office.</p>
            <span class="country">Country</span>
            <div
                v-bind:style="{ border: '2px solid gray' }" class="country-box">
                <dropdown :options="arrayOfObjects" 
                        :selected="object" 
                        v-on:updateOption="methodToRunOnSelect" 
                        :placeholder="'Select an Item'"
                        :closeOnOutsideClick="true">
                </dropdown>
            </div>
            <span class="phone-number">Phone Number</span>
            <div
                v-bind:style="{ border: '2px solid gray' }" class="country-box">
                <div class="phone-section">
                    <div class="int-code">{{ code }}</div>
                    <input v-model="phoneNumber" type="tel" @keypress="isNumber($event)" placeholder="Enter digits" class="phone-input">
                </div>
            </div>
            <div v-bind:class="['invalid-phone', { invalid }]">
                {{ getInvalidText }}
            </div>
            <div class="buttons-cancel-save" >
                <MainButton @clickButton="cancel" :cancel=true></MainButton>
                <MainButton @clickButton="save" :save=true></MainButton>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from './DropDown'
import validate from '../../util/valid-phone'
import MainButton from './button/Button'

export default {
	props: {
	},
	mounted() {
    },
	data() {
		return {
            input: null,
            thanks: false,
            phoneNumber: null,
            number: null,
            code: "+1",
            invalid: false,
            arrayOfObjects: [
                {
                    name: 'United States',
                }
                // {
                //     name: 'United Kingdom',
                // },
                // {
                //     name: 'Canada',
                // }
            ],
            object: {
              name: 'Choose Country...',
            },
            phoneExists: false
		}
	},
    methods: {
        ...mapActions('auth',[
            'checkPhone',
            'addPhone'
        ]),
        ...mapActions('user',[
            'loadUser',
            'updateUser'
        ]),
        methodToRunOnSelect(payload) {
            this.object = payload;
        },
        isNumber(event) {
            this.invalid = false
            this.phoneExists = false
            event = (event) ? event : window.event
            var charCode = (event.which) ? event.which : event.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 45) {
                event.preventDefault()
            }
            else {
                return true
            }
        },
        cancel() {
            return this.$router.go(-1)
        },
        save() {
            this.invalid = false
            this.phoneExists = false
            // Validate phone number structure
            if (this.validatePhone) {
                return this.addPhone({ 
                        phone: this.code + this.number
                    })
                    .then((response) => {
                        if (response.phone_exist)
                            throw new Error('PhoneExists')
                        return this.loadUser()
                    })
                    .then((response) => {
                        return this.$router.go(-1)
                    })
                    .catch((error) => {
                        if (error.message == 'PhoneExists') {
                            this.phoneExists = true
                            this.invalid = true
                        }
                        return
                    })
            }
            else {
                // Tell user to type in valid phone number
                this.invalid = true
                this.phoneNumber = null
            }
        }
    },
    computed: {
        validatePhone() {
            if (! this.phoneNumber)
                return false
            this.number = this.phoneNumber.replace('-', '')
            var result = validate(this.number)
            if (result)
                return true
            return false
        },
        getInvalidText() {
            return (this.phoneExists ? 'Phone number already in use' : 'Invalid phone number') + ', please try again.'
        }
    },
    components: {
        'dropdown': DropDown,
        MainButton
    }
}
</script>
<style lang="scss" scoped>

@media only screen and (min-width: 0px) {
    .settings-sms {
        margin-left: 10%;
        margin-right: 10%;
        .country {
            position: absolute;
            z-index: 1;
            background-color: white;
            top: 220px;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 15px;
        }
        .country-box {
            border: 1px solid blue;
            box-sizing: border-box;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 10px;
            //width: 350px;
            margin-bottom: 40px;
            .phone-section {
                display: flex;
                align-items: center;
                .int-code {
                    margin-left: 5px;
                    margin-right: 10px;
                }
                .phone-input {
                    border: 0px solid black;
                    font-size: 12pt;
                }
            }
        }
        .phone-number {
            position: absolute;
            z-index: 1;
            background-color: white;
            top: 310px;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 15px;
        }
        .invalid-phone {
            color: white;
            font-size: 10pt;
            margin-top: -30px;
        }
        .invalid-phone.invalid {
            color: red;
        }
        .title {
            font-size: 18px;
            font-weight: 700;
            margin-top: 50px;
        }
        .message {
            font-size: 12pt;
            margin-top: 10px;
            margin-bottom: 40px;
            width: 325px;
            line-height: 20px;
        }
        .buttons-cancel-save {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
        }
    }
}
@media only screen and (min-width: 412px) {
    .settings-sms {
        .country {
            top: 200px;
        }
        .phone-number {
            top: 290px;
        }
    }
}

</style>