<template>
	<svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M31.5059 29.2655C29.6818 25.6175 25.5778 15.5853 25.5778 14.2173C25.5778 12.8493 24.6657 5.55319 24.6657 5.55319C24.6657 5.55319 18.3517 0.537109 13.7216 0.537109C9.09144 0.537109 2.32139 5.55319 2.32139 5.55319C2.32139 5.55319 -0.870632 23.7935 0.497387 26.9855C1.86541 30.1776 8.2495 33.8256 9.61752 37.0177C10.9855 40.2097 10.5295 48.8738 12.3535 52.0659C14.1776 55.2579 21.2189 65.7461 26.4898 63.9221C28.544 63.2112 29.2258 62.098 30.1378 61.642C31.0499 61.186 35.6099 61.186 37.8899 60.73C40.17 60.274 45.6421 56.626 46.0981 54.8019C46.554 52.9779 46.5541 51.1539 46.0981 48.8738C45.6421 46.5938 33.3299 32.9136 31.5059 29.2655Z" :fill="color"/>
	</svg>
</template>
<script>
export default {
	name: 'left_foot_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
