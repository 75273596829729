<template>
	<div class="download-prompt" @click="hide">
		<div class="prompt">
			<img src="@/image/logo/circle.png">
			<h1>Download the {{ account === 'livechair' ? 'LiveChair' : (isAccountGerd(account) ? 'GERDHelp' : 'DoctorPlan') }} 
				app to track your symptoms, vitals, lifestyle, and more.</h1>
			<div class="link">
				<a v-if="isAccountGerd(account)" href="https://apps.apple.com/in/app/gerdhelp/id1568842316">
					<img src="@/image/download/ios.png">
				</a>
				<a v-else-if="account === 'livechair'" href="https://apps.apple.com/in/app/live-chair-health/id1537755288">
					<img src="@/image/download/ios.png">
				</a>
				<a v-else href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
					<img src="@/image/download/ios.png">
				</a>

				<a v-if="isAccountGerd(account)" href="https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US">
					<img src="@/image/download/android.png">
				</a>
				<a v-else-if="account === 'livechair'" href="https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient">
					<img src="@/image/download/android.png">
				</a>
				<a v-else href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
					<img src="@/image/download/android.png">
				</a>


<!-- 		   <a :href="account === 'livechair' ? 'https://apps.apple.com/in/app/live-chair-health/id1537755288' : 'https://itunes.apple.com/us/app/doctorbox/id1437144807'">
 				   <img src="@/image/download/ios.png">
 			   </a>
 			   <a :href="account === 'livechair' ? 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient' : 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'">
 				   <img src="@/image/download/android.png">
 			   </a> -->
		   </div>
		   <a @click="hide">or continue on the web</a>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex'
import isAccountGerd from '../util/is-account-gerd';

export default {
	name: 'download-prompt',

	props: {
		account: {
			type: String,
			default: 'doctorplan'
		},
	},


	methods: {
		...mapActions(['promptDownload']),
		hide() {
			this.promptDownload(false)
		},
		isAccountGerd
	}
}
</script>
<style lang="scss">
.download-prompt {
	position: fixed;
	z-index: 10001;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);

	.prompt {
		display: block;
		margin: 0 auto;
		width: 360px;
		height: 360px;
		margin-top: 100px;
		background: white;
		border-radius: 4px;
		padding-top: 20px;

		text-align: center;

		> img {
			display: block;
			margin: 10px auto;
			width: 40%;
		}
		h1 {
			font-size: 20px;
			color: #3f3f3f;
			text-align: center;
			padding: 5px 25px;
		}
		.link {
			a {
				margin-top: 15px;
				width: 40%;
				height: 40px;
				display: inline-block;

				img {
					width: 100%;
				}
			}
			a:first-child {
				width: 36%;
				margin-right: 2%;
			}
		}
		> a {
			display: block;
			margin-top: 10px;
			color: #6f6f6f;
			font-size: 14px;
		}
		> a:hover {
			cursor: pointer;
		}
	}
}
</style>
