export default {
    birthdate: {
        title: 'Date of Birth',
        image: 'DateOfBirth',
        id: 'birthdate'
    },
    gender: {
        title: 'Gender',
        image: 'Gender',
        id: 'gender'
    },
    height: {
        title: 'Height',
        image: 'Height',
        id: 'height'
    }
    // health_data: {
    //     title: 'Health Data',
    //     image: 'HealthData',
    //     id: 'health_data'
    // },
    // my_doctors: {
    //     title: 'My Doctors',
    //     image: 'MyDoctors',
    //     id: 'my_doctors'
    // }
}