<template>
    <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  d="M8 8l-4 4 4 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path  d="M13 8l-4 4 4 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <text x="15" y="15" font-family="Arial" font-size="8" fill="white">10</text>
    </svg>
</template>

<script>
/**
 * An Icon component that displays a rewind icon.
 */
export default {
    name: 'rewind-icon',
}
</script>