<template>
    <div v-bind:class="['section', { selected }]">
        <div v-bind:class="['image-message', { last }]">
            <div class="section-content">
                <p>{{ section.question }}</p>
                <button
                    v-if="selected"
                    v-bind:class="{ selected }"
                    v-on:click="toggleSelected">
                    <img
                        class="check"
                        src="../../../image/check_blue.png">
                </button>
                <button
                    v-else
                    class="button"
                    v-on:click="toggleSelected">
                    {{ selectText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {

	props: {
        section: {
            type: Object
        },
        index: {
            type: Number
        },
        topics: {
            type: Array
        }
	},
    data() {
        return {
            selectComponent: false,
            selected: this.section.selected,
            last: this.index === Object.keys(this.topics).length - 1 ? true : false
        }
    },
	methods: {
        toggleSelected() {
            this.section.selected = ! this.section.selected
            this.selected = ! this.selected
            this.$emit('clicked', {
                key: this.section.question, 
                selected: this.selected
			})
        },
        getSelected() {
            return this.section.selected
        }
    },
    computed: {
		selectText() {
			return (this.selected) ? 'SELECTED' : 'SELECT'
		}
    },
	components: {

	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
