<template>
	<div class="task-page" :style="'height: ' + this.checklistHeight + 'px'">
		<div class="checklist-content">
			<span class="checklist-title">{{ task.text }}</span><br>
			<span class="task-text">Please choose the status of the task</span>
			<el-row :key="renderKey">
				 <el-col :span="11" :class="[task.state === 'n/a' ? 'state-active' : '', 'state-box']"
						 @click.native="setStatus('n/a')">
					 <div>
						 <NotApplicable :color="task.state === 'n/a' ? '#fff' : '#666'"></NotApplicable><br>
						 I was not asked to<br> do this task...
					 </div>
				 </el-col>
				 <el-col :span="11" :offset="1" :class="[task.state === 'complete' ? 'state-active' : '', 'state-box']"
						 @click.native="setStatus('complete')">
					 <div>
						 <Completed :color="task.state === 'complete' ? '#fff' : '#666'"></Completed><br>
						 Completed
					 </div>
				 </el-col>
			</el-row>
			<span class="task-text">Add a note or comment?</span><br>
			<el-input
					type="textarea"
					:rows="4"
					:placeholder="placeholder"
					:disabled="task.state === ''"
					v-model="comment">
			</el-input>
			<div class="buttons">
				<el-button size="default" class="cancelBtn" @click="goBack" :disabled="task.state === ''">Cancel</el-button>
				<el-button size="default" class="doneBtn" @click="patchTask" style="float: right" :disabled="task.state === ''">Done</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue'
import Completed from './icon/Completed'
import NotApplicable from './icon/NotApplicable'

export default {
	props: {
		id: {
			type: String,
			required: true
		},
		taskId: {
			type: String,
			required: true
		},
		checklists: {
			type: Object,
			required: true
		},
		content: {
			type: Object,
			required: true
		},
		urlToken: {
			type: String,
			required: true
		}
	},
	watch: {
		comment: function() {
			this.task.comment = this.comment
		}
	},
	data() {
		return {
			task: {},
			comment: '',
			renderKey: 0,
			placeholder: '',
			opened: Date.now(),
			saved: false,
			checklistHeight: '0'
		}
	},
	mounted() {
		window.addEventListener("resize", this.resetHeight)
		// called to initially set the height.
		this.resetHeight()

		if (this.content.hasOwnProperty('item')) this.loadTask()
	},
	methods: {
		...mapActions('user', [
			'addEvent'
		]),
		resetHeight() {
			// reset the body height to that of the inner browser
			// substracting 40px to give space to navigation buttons
			this.checklistHeight = window.innerHeight - 40
		},
		/**
		 * @func goBack
		 * @desc Removes the temporary values if not saved when route changed
		 */
		goBack() {
			this.checklists.item.forEach(task => {
				if (task.id === this.taskId) {
					delete task.state
					delete task.comment
					delete task.opened
				}
			})
			this.$router.push(`/checklist/${this.id}?${this.urlToken}`)
		},
		/**
		 * @func loadTask
		 * @desc Fills in the value of task
		 */
		loadTask() {
			this.content.item.forEach((task, index) => {
				if (task.id === this.taskId)
					this.task = this.checklists.item[index]
			})
			if (!this.task) {
				this.logout()
				return
			}
			if (!this.task.hasOwnProperty('state')) this.task.state = ''
			if (!this.task.hasOwnProperty('comment')) this.task.comment = ''
			if (!this.task.hasOwnProperty('opened')) this.task.opened = this.opened
			this.comment = this.task.comment
		},
		setStatus(from) {
			this.task.state = from
			if (from === 'n/a') this.placeholder = 'My doctor didn’t ask me to do this task…'
			else if (from === 'complete') this.placeholder = 'I completed it on...'
			this.renderKey += 1
		},
		/**
		 * @func patchTask
		 * @desc API call to save the task
		 */
		patchTask() {
			this.task.completed = Date.now()
			return this.addEvent({
				id: this.id,
				type: 'checklist',
				body: {
					item: this.checklists.item.map(task => {
						if (task.id === this.taskId) return this.task
						return task
					})
				}
			})
			.then(_ => {
				this.saved = true
				this.$router.push('/checklist/' + this.id + '?' + this.urlToken)
				this.$message({ message: 'Success', type: 'success', duration: '1000' })
			})
			.catch(_ => this.$message.error('Checklist not updated.'))
		}
	},
	components: {
		Completed,
		NotApplicable
	},
	beforeRouteLeave(to, from, next) {
		// if the content is not saved, returns to initial object.
		if (!this.saved) {
			this.checklists.item.forEach(task => {
				if (task.id === this.taskId) {
					delete task.state
					delete task.comment
					delete task.opened
				}
			})
		}
		next()
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
