<template>
<svg width="22" height="50" viewBox="0 0 22 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0V47C0 47 12.3607 50 22 50V1.5C12.4142 1.5 0 0 0 0Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_lower_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
