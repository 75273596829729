<template>
    <svg width="42" height="106" viewBox="0 0 42 106" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.771439 46.7511L4.86513 12.8878C5.35775 8.81279 5.60405 6.7753 6.73747 5.27155C7.05154 4.85485 7.41123 4.47458 7.80982 4.13781C9.24822 2.92253 11.2689 2.5633 15.3101 1.84484C20.877 0.855147 23.6605 0.360303 25.7998 1.50536C26.3844 1.81822 26.9215 2.21255 27.3952 2.67651C29.1286 4.37458 29.4903 7.17843 30.2139 12.7861L34.3124 44.549C34.4047 45.2643 34.4508 45.622 34.5265 45.9527C34.6023 46.2833 34.7245 46.6497 34.969 47.3824C36.2103 51.1031 39.5495 61.5513 40.4996 69C41.1429 74.0443 41.0733 80.7137 40.8833 85.6585C40.7591 88.8912 40.697 90.5075 39.8601 91.987C39.0232 93.4664 37.6032 94.3958 34.7633 96.2547L30.274 99.1931C22.4459 104.317 18.5319 106.879 15.1072 105.45C11.6826 104.02 10.7508 99.4362 8.88733 90.2678L0.950768 51.2199L0.950756 51.2198C0.725651 50.1123 0.613102 49.5585 0.590542 48.9963C0.567982 48.4342 0.635799 47.8731 0.771439 46.7511Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_upper_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>