<template>
    
    <svg width="43" height="45" viewBox="0 0 43 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M38.8071 25.706C37.6199 30.9137 35.6021 37.0329 34.5287 40.152C34.0867 41.4365 33.0065 42.3844 31.6732 42.6446C28.7113 43.2228 23.2714 44.1713 19.3071 44.206C15.3436 44.2407 9.99092 43.3868 6.72501 42.7838C4.98976 42.4635 3.72322 40.9977 3.61207 39.2367C3.32108 34.6257 2.80713 25.7038 2.80713 20.206C2.80713 14.5735 1.81573 9.86298 0.763598 6.4498C-0.114935 3.59978 1.86692 0.254391 4.84849 0.185608C9.94553 0.0680237 16.8075 0.00993729 20.8071 0.390694C25.6684 0.853474 34.3195 2.73803 39.1993 3.85791C41.2347 4.32502 42.5329 6.29845 42.1676 8.35459C41.3447 12.9871 39.8919 20.948 38.8071 25.706Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>