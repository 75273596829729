<template>
    
    <svg width="27" height="89" viewBox="0 0 27 89" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M9.84224 88.4098L18.0966 87.0048C21.5962 86.4091 24.2815 83.5882 24.6339 80.0558C25.4784 71.5922 26.8301 55.8392 26.4999 44.5C26.1254 31.6414 23.2721 13.6059 21.8295 5.18092C21.3635 2.45908 18.9984 0.5 16.237 0.5C13.0684 0.5 10.4999 3.0686 10.4999 6.23712V38.6809C10.4999 39.5544 10.3568 40.422 10.0764 41.2492L0.923328 68.2508C0.642906 69.078 0.499855 69.9456 0.499855 70.8191V80.5232C0.499855 85.4779 4.95781 89.2412 9.84224 88.4098Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>