<template>
<svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.532984 13.4716L18.682 0.246142L16.4019 11.6591C14.0161 13.722 8.98253 18.1926 4.54568 19.4114C0.108841 20.6301 -0.00901892 15.8058 0.532984 13.4716Z" :fill="color"/>
<path d="M8.99203 51.7071L14.6708 29.1453L16.056 24.0862L23.242 27.2278L24.154 27.6265L31.9061 31.0155L32.8182 31.4143L39.8779 34.5006L41.0263 35.0026L46.0424 37.1955L39.8779 55.416C39.8779 55.416 38.0969 56.3588 37.212 55.7812C36.418 55.263 36.6633 53.4401 36.6633 53.4401L41.0263 35.0026L39.8779 34.5006L30.2821 61.9714C30.2821 61.9714 28.219 64.0883 26.8813 63.5084C25.6511 62.9752 25.5466 60.3831 25.5466 60.3831L32.8182 31.4143L31.9061 31.0155L20.7866 62.6908C20.7866 62.6908 18.7599 64.7176 17.3857 64.2278C15.8506 63.6806 16.056 60.3233 16.056 60.3233L24.154 27.6265L23.242 27.2278L14.2861 54.0782C14.2861 54.0782 11.8701 56.4071 10.3218 55.6116C8.87426 54.8679 8.99203 51.7071 8.99203 51.7071Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_fingers_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
