<template>
	<svg width="67" height="81" viewBox="0 0 67 81" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M5.81086 38.6686C5.84998 38.7241 5.93801 38.7078 5.92497 38.6409C5.40985 36.0001 2.43322 16.7661 12.5026 7.94875C23.0528 -1.28923 33.4514 0.0703042 33.4514 0.0703042C33.4514 0.0703042 46.7354 -0.161175 53.4939 7.51839C60.2297 15.1719 62.7498 22.0902 61.3968 39.7788C61.3936 39.8358 61.4539 39.8782 61.4946 39.8374C61.9348 39.3973 63.8159 37.9563 65.2439 39.8065C67.9565 43.3162 65.6662 52.8802 63.1525 57.5277C63.1525 57.5277 61.0724 61.0096 58.919 57.6467C58.8913 57.6027 58.8261 57.5929 58.8131 57.6434C58.3208 59.3958 52.0464 80.5028 35.0718 80.5028C17.4451 80.5028 11.0941 65.5398 8.77934 58.3721C8.76467 58.3297 8.71739 58.3167 8.69131 58.3541C8.45168 58.6867 7.44263 59.8718 5.87607 58.8562C4.06662 57.6874 -1.68776 46.9024 1.99308 39.1789C1.99308 39.1789 3.94599 36.0914 5.81086 38.6686Z" :fill="color"/>
	<path d="M13.9979 35.9036C18.819 33.3783 23.0718 33.9427 26.6736 37.5946C26.7971 37.7198 26.7957 37.9214 26.6705 38.0449C26.5453 38.1683 26.3437 38.167 26.2202 38.0418C22.8195 34.5937 18.8714 34.0697 14.2933 36.4677C14.1376 36.5493 13.9452 36.4892 13.8636 36.3334C13.782 36.1777 13.8421 35.9852 13.9979 35.9036Z" fill="white"/>
	<path d="M53.2179 35.9036C48.3968 33.3783 44.144 33.9427 40.5422 37.5946C40.4187 37.7198 40.4201 37.9214 40.5453 38.0449C40.6705 38.1683 40.8721 38.167 40.9956 38.0418C44.3964 34.5937 48.3444 34.0697 52.9225 36.4677C53.0782 36.5493 53.2707 36.4892 53.3523 36.3334C53.4338 36.1777 53.3737 35.9852 53.2179 35.9036Z" fill="white"/>
	<circle cx="21.195" cy="37.118" r="2.27426" fill="white"/>
	<circle r="2.27426" transform="matrix(-1 0 0 1 45.7723 37.118)" fill="white"/>
	<path d="M30.8266 65.6366L31.5225 65.7311C32.6049 65.8688 33.3441 65.9111 34.4026 65.9147C35.6469 65.9189 36.5338 65.8574 37.9785 65.6608C38.2274 65.6269 38.4567 65.8012 38.4905 66.0502C38.5244 66.2991 38.3501 66.5283 38.1012 66.5622L37.3406 66.6592C36.2661 66.7855 35.4528 66.8279 34.3995 66.8244C33.0869 66.8199 32.2345 66.7567 30.6979 66.5372C30.4492 66.5017 30.2764 66.2713 30.3119 66.0226C30.3435 65.8015 30.5291 65.6404 30.7446 65.6323L30.8266 65.6366Z" fill="white"/>
	<path d="M33.3772 63.5141C33.5039 63.4812 33.6386 63.5046 33.7468 63.5781L34.4356 64.0462L35.1942 63.5693C35.2801 63.5153 35.381 63.4919 35.4807 63.5017L35.555 63.5153L40.788 64.9294C41.0305 64.9949 41.1739 65.2446 41.1084 65.4871C41.0502 65.7027 40.8464 65.84 40.6315 65.8218L40.5507 65.8076L35.51 64.4447L34.6686 64.9745C34.5156 65.0707 34.3203 65.0673 34.1709 64.9658L33.4059 64.4465L28.1472 65.8088C27.9311 65.8648 27.7109 65.7556 27.6204 65.5598L27.5928 65.4825C27.5368 65.2664 27.646 65.0462 27.8418 64.9557L27.9191 64.9281L33.3772 63.5141Z" fill="white"/>
	<path d="M29.2946 56.7583C29.741 56.2576 30.6183 56.0611 31.5991 56.347C32.242 56.5343 32.6478 56.7725 33.109 57.1816L33.5379 57.5802C33.743 57.7661 34.6229 57.8711 35.1239 57.5383L35.2529 57.4472C35.5713 57.2137 36.1015 56.7792 36.0533 56.8157C36.4125 56.5436 36.7434 56.395 37.2015 56.3329C38.077 56.2142 38.9106 56.4691 39.6805 57.0838C39.8768 57.2406 39.9089 57.5268 39.7522 57.7231C39.5954 57.9194 39.3092 57.9514 39.1129 57.7947C38.5307 57.3299 37.9435 57.1503 37.3238 57.2344C37.0313 57.274 36.8436 57.3582 36.6027 57.5408L36.3892 57.7165C36.1644 57.8973 35.8174 58.1697 35.6273 58.296C34.765 58.8688 33.4291 58.7094 32.9269 58.2541L32.6421 57.9873C32.208 57.5791 31.9028 57.383 31.3446 57.2203C30.7335 57.0422 30.2509 57.1261 30.0247 57.3145L29.9735 57.3637C29.8064 57.5512 29.5188 57.5676 29.3313 57.4005C29.1438 57.2333 29.1274 56.9457 29.2946 56.7583Z" fill="white"/>
	<path d="M31.8447 39.2129C32.0792 39.123 32.3423 39.2402 32.4322 39.4747C33.2309 41.5574 32.5249 48.8949 31.5775 51.3601C30.9301 53.0447 30.3489 53.866 29.475 54.6143C29.0238 55.0007 28.9301 55.9836 29.9023 56.6935C30.1052 56.8417 30.1495 57.1262 30.0014 57.3291C29.8532 57.532 29.5687 57.5764 29.3658 57.4282C27.8656 56.3328 28.0253 54.658 28.8833 53.9233C29.6427 53.2731 30.1352 52.577 30.7283 51.0338C31.6026 48.7591 32.2876 41.6384 31.5828 39.8004C31.4929 39.5659 31.6101 39.3028 31.8447 39.2129Z" fill="white"/>
	<path d="M17.2692 29.0599C21.1363 29.0599 25.7413 29.745 29.2485 30.932C29.5816 31.0448 29.7603 31.4062 29.6475 31.7394C29.5348 32.0725 29.1733 32.2512 28.8402 32.1384C25.4714 30.9982 21.0034 30.3335 17.2692 30.3335C15.0794 30.3335 13.3731 30.9099 12.1187 32.0525C11.8587 32.2893 11.4559 32.2705 11.2191 32.0105C10.9823 31.7504 11.0011 31.3477 11.2611 31.1109C12.7662 29.7401 14.7795 29.0599 17.2692 29.0599Z" fill="white"/>
	<path d="M50.7309 29.0599C46.8637 29.0599 42.2587 29.745 38.7516 30.932C38.4184 31.0448 38.2398 31.4062 38.3525 31.7394C38.4653 32.0725 38.8267 32.2512 39.1599 32.1384C42.5287 30.9982 46.9966 30.3335 50.7309 30.3335C52.9206 30.3335 54.6269 30.9099 55.8814 32.0525C56.1414 32.2893 56.5441 32.2705 56.7809 32.0105C57.0178 31.7504 56.999 31.3477 56.7389 31.1109C55.2338 29.7401 53.2206 29.0599 50.7309 29.0599Z" fill="white"/>
	</svg>
</template>
<script>
export default {
	name: 'head_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
