import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
import VueSignaturePad from 'vue-signature-pad'

Vue.use(VueSignaturePad)
Vue.use(VueMeta)
Vue.use(Router)

// Get a reference to the VueX store
import store from '../../store'

const TOKEN_EXPIRATION = 86400000

// Following are the restricted pages on Live.
const restrictedPages = ['/login', '/logout', '/join', '/reset', '/reset/verify', '/upgrade/verify']

import HomeRoute from '@/module/home/route'
import AuthRoute from '@/module/auth/route'
import ArticleRoutes from '@/module/article/routes'
import ExerciseRoutes from '@/module/exercise/routes'
import MindfulnessRoutes from '@/module/mindfulness/routes'
import YogaRoutes from '@/module/yoga/routes'
import QuestionnaireRoutes from '@/module/questionnaire/routes'
import ChecklistsRoutes from '@/module/checklists/routes'
import SymptomRoutes from '@/module/symptom/routes'
import AppointmentRoutes from '@/module/appointment/routes'
import SettingRoutes from '@/module/setting/routes'
import CategoryRoutes from '@/module/category/routes'

import DownloadView from '../../component/DownloadView'
import DownloadPhoneEnroll from '../../component/DownloadPhoneEnroll'
import AbsentModule from '../../component/AbsentModule'
import Settings from '../../component/Settings'
import Message from '../../component/Message'


// Filters out restricted routes if environment is Live
let EnvSpecificAuthRoute = AuthRoute
if (['live', 'production'].includes(process.env.NODE_ENV)) {
    EnvSpecificAuthRoute = EnvSpecificAuthRoute.filter(routeObj => !restrictedPages.includes(routeObj.path))
}

const router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
        HomeRoute,
        ...EnvSpecificAuthRoute,
        ...ArticleRoutes,
        ...ExerciseRoutes,
        ...MindfulnessRoutes,
        ...YogaRoutes,
        ...QuestionnaireRoutes,
        ...ChecklistsRoutes,
        ...SymptomRoutes,
        ...AppointmentRoutes,
        ...SettingRoutes,
        ...CategoryRoutes,
        {
            path: '/download',
            name: 'setting-download',
            component: DownloadView
        },
        {
            path: '/downloadPhoneEnroll',
            name: 'download-phone-enroll',
            component: DownloadPhoneEnroll
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings
        },
        {
            path: '/message/:id',
            name: 'message',
            component: Message,
            meta: { link: true },
            props: (route) => ({
                id: route.params.id,
                query: route.query
            }),
        },
        {
            path: '/vital/:id',
            name: 'absent-module',
            component: AbsentModule,
            meta: { link: true },
            props: (route) => ({
                id: route.params.id,
                type: 'vital',
                query: route.query
            }),
        }
    ]
})

router.beforeEach((to, from, next) => {
    let authenticationView = to.matched.some((record) => record.meta.authComponent),
        link = to.matched.some((record) => record.meta.link), //&& to.query.link,
        validPath = to.matched.length > 0,
        verified = store.state.auth.verified,
        authenticated = store.state.auth.authenticated

    // Redirect to the login page
    let loginRedirect = () => {
        // Save the path as a redirect if the path matched an existing path
        store.state.redirect = (validPath) ? to.fullPath : null
        // Redirect to the login path
        next({ path: '/download', query: { account: to.query.account } })
    }

    // If the user is not authenticated and the view is not an authentication view (login, join, etc)
    // if (! authenticated && ! authenticationView && ! link && !['/download', '/settings'].includes(to.fullPath))
    if (! authenticated && ! authenticationView && ! link &&
        !to.fullPath.includes('/download') && !to.fullPath.includes('/settings'))
        loginRedirect()

    // If the user is not authenticated and this is a landing point for a link, immediately show the
    // link content and dispatch an action to notify the link was used
    else if (! authenticated && link) {
        store.dispatch('auth/link', to.query.link)
        next()
    }

    // If the user is authenticated with an unverified token, or one that may have expired
    else if (authenticated && (!verified || Date.now() - verified >= TOKEN_EXPIRATION))
        store.dispatch('auth/verify').then(() => {
            next()
        })
        .catch(() => {
            if (authenticationView)
                next()
            else
                loginRedirect()
        })

    // Otherwise the user is authenticated and can proceed, or unauthenticated and can proceed to an authentication view
    else
        next()
})

export default router
