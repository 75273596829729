import axios from 'axios'
import baseUrl from './base-url'

export default function({ state }, { url, query }) {

    let hasHandoff = url.includes('handoff_token')

    // Insert query parameters into query
    if (query) {
        url = url + '?' + Object.keys(query).map((q) => (q + '=' + query[q])).join('&')
    }
    // if handoff_token is present 
    // then don't use credentials (withCredentials)
    return axios.get(baseUrl + url, {
        withCredentials: !hasHandoff
    })
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        console.log(error)
        throw error.response.data
    })
}
