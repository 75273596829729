<template>
	<div class="slider" :key="renderKey"
		 :style="question.type === 'scroller' ? '' : 'padding-left: 25px;'"
		 :class="question.type === 'scroller' ? 'hide-numbers' : ''">
		<div class="hiding-element" :style="question.type === 'scroller' ? 'height: ' + (question.max * 42 + 20) + 'px' : ''"></div>
		<div class="slider-bar">
			<img v-if="showThumb && question.type === 'vertical_slider'"
				 src="../image/thumb_active.svg"
				 class="thumb-image" :style="'bottom: ' + getThumbHeight">
			<img v-if="!showThumb && question.type === 'vertical_slider'"
				 src="../image/thumb_inactive.svg"
				 :class="[this.question.max > 1 ? 'element' : '',  'thumb-image']" :style="'bottom: ' + getThumbHeight">
			<vue-slider
					ref="slider"
					class="left"
					v-model="value"
					v-bind="options"
					:marks="'marks'"
					:data="marksMap">
			</vue-slider>
			<div class="disabled-option" @click="selectDisabledOption"></div>
		</div>
	</div>
</template>


<script>
	import VueSlider from 'vue-slider-component'
	import 'vue-slider-component/theme/antd.css'
	import Question from './index'

	const SINGLE = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
	const FIRST = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
	const DOUBLE = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"


	export default {

		name: 'symptom-slider',
		extends: Question,
		props: {
			saved: {
				type: Array
			}
		},
		data() {
			return {
				renderKey: 0,
				showThumb: false,
				value: this.getValue(),
				options: {
					// Go to nearest value
					adsorb: true,
					duration: 0.3,

					height: this.question.max > 1 ? this.question.max * 42: 10,
					width: 5,

					direction: 'btt',
					min: 1,
					max: this.question.max,
					useKeyboard: true,
					tooltip: 'none',
					included: true,
					processStyle: {
						cursor: 'pointer',
						backgroundColor: '#e28816',
						'overflow-wrap': 'break-word',
						'word-wrap': 'break-word',
						'hyphens': 'auto'
					},
					dotStyle: {
						display: 'none',
						cursor: 'pointer',
						borderColor: '#e28816',
						backgroundColor: '#e28816',
						boxShadow: '0 0 0 2px #e28816',
					},
					labelActiveStyle: {
						cursor: 'pointer',
					},
					labelStyle: {
						cursor: 'pointer',
						fontSize: '14px',
						marginTop: '1px',
						marginLeft: '-39px',
						fontWeight: '400',
						'overflow-wrap': 'break-word',
						'word-wrap': 'break-word',
						'hyphens': 'auto'
					},
					stepStyle: {
						left: '-12px',
						top: '2px',
						width: '30px',
						borderRadius: '0',
						height: '2px',
						backgroundColor: '#fff1df',
						padding: '0',
						boxShadow: 'none'
					},
					stepActiveStyle: {
						cursor: 'pointer',
						borderColor: '#fff1df',
						backgroundColor: '#fff1df',
					},
					railStyle: {
						backgroundColor: '#fff1df',
						width: '104px',
						margin: '0 60px 0 0',
						borderRight: '100px solid #ffffff',
						borderRadius: '0'
					}
				}
			}
		},
		watch: {
			value(value) {
				this.showActiveColors()
				var split = value.split('\xa0')
				var string = split[0]
				// Check if multiple spaces exists
				if (value.includes('\xa0'))
					string += ' - ' + split[split.length-1]
				else
					string += ' '
				// Send the formatted value to be saved
				this.$emit('selected', [ string ])
				this.showThumb = true
				this.renderKey += 1
			}
		},
		// Note: when sending binded data back to be saved, use '-' and ' - '
		// instead of a bunch of spaces
		mounted() {
			// If there is already a saved slider value
			if (this.saved) {
				this.$emit('selected', this.saved)
				this.showActiveColors()
				this.showThumb = true
			}
			// If first time interacting with question, show default value
			// else
			// 	this.$emit('selected', [ this.noSaved() ])
		},
		methods: {
			selectDisabledOption() {
				this.$refs.slider.setIndex(0)
			},
			showActiveColors() {
				this.options.labelActiveStyle.color = '#e28816'
				this.options.labelActiveStyle.fontWeight = '600'
				this.options.stepActiveStyle.borderColor = '#e28816'
				this.options.stepActiveStyle.backgroundColor = '#e28816'
			},
			noSaved() {
				var index = (Math.floor(this.question.max / 2)).toString(),
						text = this.question.label[index - 1]
				if (! text)
				// Space is added
					return index + " "
				else
				// Hyphen and text added if label exists for item
					return index + " - " + text
			},
			getValue() {
				if (this.saved && this.saved.length) {
					var saved, string, split = this.saved[0].split(' ')
					// Check if extra space is unnecessary for values with more than 1 digit
					if (split[0] == '10')
						string = DOUBLE
					else
						string = SINGLE
					// If saved value has a label, format correctly so data loads correctly with label
					// Same goes for saved value with no label
					if (this.saved[0].includes(' - '))
						saved = this.saved[0].replace(' - ', string)
					else
						saved = split[0] + ' '

					return saved
				}
				else
					return ''
			}
		},
		computed: {
			getThumbHeight() {
				let curValue = parseInt(this.getValue())
				if (!this.showThumb) return this.question.max > 1 ? '-15px': '-11px'
				return 'calc(' + (100 / (this.question.max - 1) * (curValue - 1)) + '%' + ' - 15px)'
			},
			marks() {
				var arr = []
				var max = this.question.max
				for (var i = 1; i <= max; i++){
					arr.push(i)
				}
				return arr
			},

			marksMap() {
				var arr = []
				var max = this.question.max
				var string = ''
				for (var i = max; i >= 1; i--){
					if (this.question.label[max-i]) {

						if (i == max)
							string = FIRST
						else if ((max - i > 8) && max > 9)
							string = DOUBLE
						else
							string = SINGLE

						arr.push(max - i + 1 + string + this.question.label[max-i])
					} else {
						arr.push(max - i + 1 + " ")
					}
				}
				return arr
			},

		},

		components: {
			VueSlider
		}
	}

</script>

<style lang="scss" scoped>
	.disabled-option {
		cursor: pointer;
		position: absolute;
		z-index: 10000;
		bottom: -2.5%;
		width: 275px;
		margin-left: -35px;
		height: 20px;
		background-color: transparent;
		// border: 1px solid red;
	}
	.slider {
		height: auto;
		padding: 10px 0 30px 0;
		display: flex;
		justify-content: flex-start;
		.number-bar {

		}
		.slider-bar {
			margin: 10px 0 0 15px;
			position: relative;
		}
		.thumb-image {
			position: absolute;
			left: -38px;
		}
		.numbers {
			font-size: 1pt;
			font-weight: 600;
			margin-bottom: 25px;
		}
	}
	.hide-numbers {
		.hiding-element {
			z-index: 210;
			width: 20px;
			margin-right: -7px;
			background-color: #ffffff;
		}
	}


	.element {
		animation: nudge 2s linear infinite alternate;
	}

	@keyframes nudge {
		0%, 100% {
			transform: translate(0, 0);
		}

		50% {
			transform: translate(0, -55px);
		}
	}
</style>
