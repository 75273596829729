<template>
    
    <svg width="66" height="59" viewBox="0 0 66 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M20.9991 58.5273C29.0463 61.0789 26.8506 43.5 28.4991 41.0273C31.8506 36 33.5 38 35.4991 41.0273C36.7313 42.8933 36.9991 52.5273 38.8743 57.4447C40.8177 62.5408 64.7826 43.313 65.9602 27.4148C66.6144 18.5824 58.8943 9.16132 51.8285 5.6284C33.2709 -3.65034 23.1724 0.132827 13.555 5.6285C10.6109 7.19869 0.483142 14.1076 0.0120841 29.1814C-0.458974 44.2553 12.9518 55.9758 20.9991 58.5273Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>