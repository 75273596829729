<template>
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1390_2626)">
    <circle cx="32" cy="32" r="24" fill="#FAF5FC"/>
    <circle cx="32" cy="32" r="23" stroke="#833E93" stroke-width="2"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5652 32.5374C40.8902 33.094 40.7819 33.8494 40.2764 34.2072L32.0794 42L23.738 34.1675C23.6658 34.1277 23.6297 34.088 23.5575 34.0085L23.5575 34.0084C23.1242 33.5313 23.1603 32.7759 23.5936 32.3386C24.0269 31.8615 24.713 31.9012 25.1102 32.3783L31.125 38.0006L31.125 23.0625C31.125 22.4757 31.6007 22 32.1875 22C32.7743 22 33.25 22.4757 33.25 23.0625L33.25 37.7132L38.8681 32.3783C38.9042 32.3386 38.9764 32.2591 39.0486 32.2193C39.5542 31.8615 40.2403 31.9807 40.5652 32.5374Z" fill="#833E93"/>
    <defs>
    <filter id="filter0_d_1390_2626" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="4"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1390_2626"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1390_2626" result="shape"/>
    </filter>
    </defs>
</svg>
</template>

<script>
export default {
    name: 'down-icon'
}
</script>

<style>

</style>