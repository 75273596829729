<template>
    
    <svg width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M23.2485 11.7725C26.0556 17.1631 25.7435 26.2976 25.4877 29.9041C25.4163 30.9103 25.0378 31.86 24.3832 32.6274C22.7767 34.5105 19.4265 38.0699 16.2264 39.1585C13.8441 39.969 10.4345 39.7878 8.14619 39.525C6.57529 39.3446 5.22592 38.3882 4.51029 36.9782C3.00547 34.0133 0.545111 28.453 0.0756991 23.0078C-0.354138 18.0217 1.13524 9.90268 2.30619 4.53932C2.96294 1.53115 5.98766 -0.238701 8.84803 0.900873C13.6515 2.81458 20.4206 6.34188 23.2485 11.7725Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_temple',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
