<template>
    
    <svg width="66" height="39" viewBox="0 0 66 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.849625 34.2887L2.35602 9.80974C2.44393 8.38132 3.28264 7.11208 4.58989 6.52966C8.79185 4.65757 18.5372 0.714053 26.5 0.500859C36.4548 0.234334 53.5023 3.27042 59.9378 4.49853C61.5013 4.7969 62.7096 6.00333 63.0146 7.56554C63.7611 11.3891 65.0962 18.701 65.4981 24.0009C65.7594 27.4462 65.7638 30.887 65.7008 33.4953C65.6428 35.894 63.4317 37.5992 61.068 37.1866C54.4284 36.0275 41.647 34.0368 32 34.0009C24.4684 33.9728 12.829 36.6933 6.00691 38.4801C3.31834 39.1842 0.678917 37.0627 0.849625 34.2887Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>