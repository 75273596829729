<template>
    <svg width="41" height="106" viewBox="0 0 41 106" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M40.2442 46.2511L36.1505 12.3878C35.6579 8.31279 35.4116 6.2753 34.2782 4.77155C33.9641 4.35485 33.6044 3.97458 33.2058 3.63781C31.7674 2.42253 29.7468 2.0633 25.7055 1.34484C20.1386 0.355147 17.3552 -0.139697 15.2158 1.00536C14.6312 1.31822 14.0941 1.71255 13.6205 2.17651C11.8871 3.87458 11.5253 6.67843 10.8017 12.2861L6.70327 44.049C6.61097 44.7643 6.56482 45.122 6.48908 45.4527C6.41334 45.7833 6.29112 46.1497 6.04667 46.8824C4.8053 50.6031 1.46608 61.0513 0.516048 68.5C-0.127324 73.5443 -0.0577088 80.2137 0.132292 85.1585C0.256508 88.3912 0.318617 90.0075 1.15552 91.487C1.99242 92.9664 3.4124 93.8958 6.25235 95.7547L10.7416 98.6931C18.5697 103.817 22.4837 106.379 25.9084 104.95C29.333 103.52 30.2648 98.9362 32.1283 89.7678L40.0649 50.7199L40.0649 50.7198C40.29 49.6123 40.4025 49.0585 40.4251 48.4963C40.4476 47.9342 40.3798 47.3731 40.2442 46.2511Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_upper_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>