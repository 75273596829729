<template>
<svg width="60" height="94" viewBox="0 0 60 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3387 20.2821C24.5151 12.979 31.3708 0.673828 31.3708 0.673828C31.3708 0.673828 33 15.2061 41 18.7061C48.2144 21.8624 59.5 15.2061 59.5 15.2061C59.5 15.2061 57.5372 27.5905 55.3112 33.7343C53.0852 39.8782 52.7146 42.5802 50.0671 48.5546C43.3635 63.682 33.8393 69.6141 25.8987 84.5791C24.0323 88.0966 21.3387 93.6992 21.3387 93.6992C21.3387 93.6992 16.1095 89.6805 12.2185 88.2272C7.99486 86.6496 0.818359 86.4031 0.818359 86.4031C0.818359 86.4031 4.61003 79.2622 6.74645 74.547C15.8031 54.5584 12.5861 40.4057 21.3387 20.2821Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_forearm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
