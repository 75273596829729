<template>
	<div class="scroller">
		<Hand v-if="!valueChanged" class="element"></Hand>
		<Scroller v-model="labelValue" color="questionnaire" :key="renderKey" :valueChanged="valueChanged" :options="labels"></Scroller>
	</div>
</template>

<script>
import Question from './index'
import Scroller from '@/component/picker/picker'
import '@/component/picker/picker.scss'
import Hand from './icon/hand'
import { v4 as uuidv4 } from 'uuid'

import Vue from 'vue'

Vue.component('Scroller', Scroller)

export default {

	name: 'questionnaire-scroller',
	extends: Question,
	props: {
		saved: {
			type: Array
		}
	},
	data() {
		return {
			renderKey: 0,
			labelValue: '',
			valueChanged: false
		}
	},
    watch: {
        labelValue(value) {
			if (this.labelValue === 'Please choose') this.$emit('selected', [])
            else {
				this.valueChanged = true
            	this.$emit('selected', [this.getScrollerValue(value)])
			}
        }
	},
	mounted() {
		if (this.saved && this.saved.length) {
			if (this.saved[0]) {
				if (typeof this.saved[0] === 'object')
					this.labelValue = this.saved[0].description || ''
				else this.labelValue = this.saved[0]
			}
		} else this.labelValue = 'Please choose'
		this.$nextTick(() => this.renderKey += 1)
	},

	computed: {
		labels() {
			var arr = ['Please choose']
			if (this.question.hasOwnProperty('options'))
				this.question.options.forEach(opt => arr.push(opt.description || ''))
			else {
				var keys = Object.keys(this.question.label)
				if (keys.length > 0) {
					keys.forEach((item) => {
						arr.push(this.question.label[item] || '')
					})
				}
			}
			return arr
		}
	},
	methods: {
		getScrollerValue(value) {
			var match = ''
			if (this.question.hasOwnProperty('options')) {
				let found = this.question.options.filter(opt => (opt.description || '') === value)
				if (found.length) {
					found[0].description = String(found[0].description)
					found[0].value = String(found[0].value)
					return found[0]
				} else return ''
			} else {
				let index = -1
				Object.entries(this.question.label).forEach((entry) => {
					if (entry[1] === value) index = parseInt(entry[0])
				})
				match = {
					id: uuidv4(),
					description: String(value),
					value: index >= 0 ? String(index) : ''
				}
			}
			return match
		}
	},
	components: {
		Scroller,
		Hand
	}
}
</script>

<style lang="scss" scoped>
.scroller {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}
.element {
	animation: nudge 2s linear infinite alternate;
	z-index: 100;
	position: absolute;
	right: 10%;
	top: 42%;
}

@keyframes nudge {
	0%, 100% {
		transform: translate(0, 0);
	}

	50% {
		transform: translate(0, -30px);
	}
}
</style>
