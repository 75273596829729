<template>
    
    <svg width="87" height="44" viewBox="0 0 87 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M6.5924 29.9602L0.379623 6.48968C-0.518997 3.09489 3.13531 0.149635 6.32339 1.62216C15.1884 5.71679 29.3779 11.4023 40 11.5C51.1224 11.6023 68.9877 5.08592 80.2175 0.471209C83.6964 -0.958365 87.277 2.73031 85.6526 6.12255L74.0084 30.4383C73.6765 31.1314 73.1618 31.7087 72.4979 32.0958C68.3305 34.5261 52.0276 43.5578 40 43.5C28.47 43.4446 12.999 34.9986 8.36584 32.3112C7.48107 31.798 6.85414 30.949 6.5924 29.9602Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>