<template>
    
    <svg width="20" height="64" viewBox="0 0 20 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M19.2457 58.8128C18.2081 52.0181 16.4706 39.236 16.5 30C16.5247 22.2311 17.8157 11.8809 18.8225 4.96882C19.2857 1.78879 16.0326 -0.705225 13.1047 0.619312L3.28812 5.06014C1.04082 6.07677 0.221548 8.87844 1.45492 11.0145C4.1594 15.6983 8.22984 23.615 9 30C10.0638 38.8195 5.50971 50.3445 2.19801 57.3204C0.874046 60.1093 2.87704 63.5 5.96422 63.5H15.2435C17.727 63.5 19.6206 61.2678 19.2457 58.8128Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>