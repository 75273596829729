export default {
	'radio': 'Radio',
	'large_image_select': 'LargeImageSelect',
	'box_select': 'BoxSelect',
	'small_image_select': 'SmallImageSelect',
	'star': 'Star',
	'select': 'Select',
	'binary': 'Binary',
	'text': 'TextBox',
	'slider': 'Slider',
	'scroller': 'Scroller',
	'image_upload': 'Upload',
	'human_map': 'HumanMap',
	'multi_question': 'MultiQuestion',
	'long_text_bubble': 'LongTextBubble',
	'date': 'Date',
	'sign_document': 'Signature',
	'unit': 'Unit',
	'section_separator': 'Separator',
	'query': 'Query',
	'video': 'Video',
	'video_consent': 'InformedConsent'
}
