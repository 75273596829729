<template>
    
    <svg width="87" height="94" viewBox="0 0 87 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M4.7874 76.8888L0.0776443 16.6713C-0.685211 6.91768 8.57241 -0.696541 18.1606 1.24803C26.8195 3.00413 36.8529 4.56111 45 4.5C52.1076 4.44669 60.704 3.13992 68.4387 1.59749C78.4078 -0.390492 87.9078 7.90038 86.5017 17.968L78.1789 77.5594C77.4548 82.7439 73.906 87.1005 68.8785 88.5589C61.4974 90.7002 50.4955 93.4197 42 93.5C33.2538 93.5826 21.9252 90.8878 14.2247 88.7058C8.88165 87.1918 5.22042 82.4253 4.7874 76.8888Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>