<template>
	<div class="thanks">
		<div class="image">
			<img src="../../image/thanks.png">
		</div>
        <p class="title">Thanks!</p>
		<p class="message">Your information is now available for {{ appointment.doctor.name }} to review before your appointment.</p>
		<ButtonMain class="button"
			:buttonName="buttonName"
			:route="route">
		</ButtonMain>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDate from '@/module/util/format-date'
import ButtonMain from '../../../component/ButtonMain'

export default {
	name: 'appointment-thanks',

	props: {
		appointment: {
			type: Object
		}
	},
	data() {
		return {
			buttonName: 'DONE',
			route: { name: 'appointment-landing' }
		}
	},
	components: {
		ButtonMain
	}
}
</script>

<style lang="scss" scoped>
.thanks {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
		width: 50%;
	}
    .title {
		font-size: 28px;
		font-weight: 700;
		text-align: center;
		margin-top: 50px;
	}
    .message {
		font-size: 14pt;
		margin-top: 50px;
		margin-left: 10%;
		margin-right: 10%;
		text-align: center;
    }
	.button {
        margin-top: 50px;
        // a {
        //     padding: 10px 180px;
        // }
    }
}

@media only screen and (min-width: 600px) {
    .thanks {
        .title {
            font-size: 40pt;
        }
        .message {
            font-size: 18pt;
        }
    }
}
</style>
