<template>
	<div class="viewer">
		<template v-if="!featureReleased">
			<Download text="Download the DoctorPlan app to complete this checklist."></Download>
		</template>
		<!-- If the checklist content has been loaded -->
		<template v-else-if="checklistsContent">
			<Header
					class="checklists-header"
					:checklists="checklistsEvent"
					:content="checklistsContent"
					:taskId="taskId"
					:urlToken="urlToken"
					:complete="complete">
			</Header>

			<el-row>
				<el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 8, offset: 8 }">
					<!-- The List screen initially shows all the tasks -->
					<List v-if="showList" :urlToken="urlToken" :checklists="checklistsEvent" :content="checklistsContent"></List>
					<!-- Checklist viewer -->
					<template v-else>
						<router-view
								:key="taskId"
								ref="tasks"
								class="checklists"
								:id="id"
								:urlToken="urlToken"
								:checklists="checklistsEvent"
								:content="checklistsContent"
								v-bind:state="state">
						</router-view>
					</template>

				</el-col>
			</el-row>

		</template>
		<template v-else-if="complete">
			<Complete
				:id="id"
				:completed="complete">
			</Complete>
		</template>
		<!-- Otherwise show a loading dialog -->
		<template v-else>
			<Loading v-if="query.account === 'doctorplan'" color="#c45531" text="Loading checklists..."></Loading>
			<GenericLoading v-else color="#c45531" text="Loading checklists..."></GenericLoading>
		</template>

	</div>



</template>


<script>
import PromptDownload from '@/mixin/prompt-download'

import { mapActions, mapGetters } from 'vuex'
import Loading from '@/component/Loading'
import GenericLoading from '@/component/GenericLoading'
import Vue from 'vue'
import List from './List'
import Complete from './Complete'
import Task from './Task'
import Header from '../header/Header'

export default {

	name: 'checklists-viewer',

	props: {
		id: {
			type: String
		},
		query: {
			type: Object
		},
		taskId: {
			type: String
		},
		start: {
			type: Boolean
		}
	},

	mixins: [ PromptDownload ],

	data() {
		return {
			result: [],
			state: {},
			complete: false,
			featureReleased: true,
			backwardSkip: [],
			forwardSkip: [],
			urlToken: this.$route.fullPath.split('?')[1]
		}
	},

	mounted() {
		// Checks if saved event is present, if not, logout and fetch it via handoff token
		if (!this.checklistsEvent) {
			if (!this.query['handoff_token']) {
				this.complete = true
				return
			}
			console.log('handoff token found')

			this.logout()
			.then(() => {
				return this.handoff(this.query)
			})
			.then(() => {
				return this.loadEvent({ id: this.id, type: 'checklist'})
			})
			.then((event) => {
				if (event.completed)
					return this.$router.push({ name: 'checklists-complete', params: { completed: true }})

				return this.loadObject({
					type: 'checklist',
					id: event.type.split(':')[1]
				})
			})
		}
	},

	methods: {
		...mapActions('user', [
			'loadEvent'
		]),
		...mapActions('content', [
			'loadObject'
		]),

		...mapActions('auth', [
			'handoff',
			'logout'
		])
	},

	computed: {
		...mapGetters('user', [
			'getEvent'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		showList() {
			return this.taskId === undefined
		},
		/**
		 * @func checklistsEvent
		 * @desc Gets the event from store
		 * @return Object checklist event
		 */
		checklistsEvent() {
			return this.getEvent(this.id)
		},
		/**
		 * @func checklistsContent
		 * @desc fetches the checklist content either from store or API
		 * @return Object checklist content
		 */
		checklistsContent() {
			if (this.checklistsEvent)
				return this.getObject('checklist', this.checklistsEvent.type.split(':')[1])
		},
		/**
		 * @func checklists
		 * @desc fetches tasks from checklist content
		 * @return Array tasks
		 */
		checklists() {
			if (this.checklistsContent)
				return this.checklistsContent.item
			return []
		}
	},

	components: {
		Loading,
		GenericLoading,
		List,
		Task,
		Header,
		Complete
	}
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
