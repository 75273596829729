<template>
	<div>
		<div v-if="question.hasOwnProperty('options')" class="stars">
			<StarIcon v-for="(option, index) in question.options" :key="option.id"
				class="star"
				v-bind:color="starColor(index)"
				v-bind:fill="starFill(index)"
				@click.native="select(option)">
			</StarIcon>
			<br>
			<p>
				- {{ getSelected }}
			</p>
		</div>
		<div v-else class="stars">
			<StarIcon v-for="star in 5"
				:key="star"
				class="star"
				v-bind:color="starColor(star-1)"
				v-bind:fill="starFill(star-1)"
				@click.native="select(star-1)">
			</StarIcon>
			<br>
			<p v-if="hasSelected">
				- {{ getSelected }}
			</p>
		</div>
	</div>
</template>

<script>
import Question from './index'
import StarIcon from './icon/Star'
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'star-question',
	extends: Question,

	mounted() {
		this.$emit('selected', this.saved)
	},

	methods: {
		starColor(star) {
			var split = star
			if (this.selected.length > 0)
				split = parseInt(this.selected[0].value)

			if (this.selected.length > 0 && star <= split)
				return '#833e93'
			else
				return '#eeeeee'
		},
		starFill(star) {
			var split = star
			if (this.selected.length > 0)
				split = parseInt(this.selected[0].value)


			if (this.selected.length > 0 && star <= split)
				return '#faf5fc'
			else
				return '#eeeeee'
		}
	},
	computed: {
		hasSelected() {
			if (this.selected.length == 0)
				return false

			let selection = this.selected[0]

			if (!selection) return false

			if (this.question.hasOwnProperty('options')) {
				this.question.options.forEach(option => {
					if (option.value === selection.value)
						return true
				})
				return false
			}
			else if (this.question.label[parseInt(selection.value)] != null)
				return true
			return false
		},
		getSelected() {
			var selection = this.selected[0]
			if (!selection) return ''
			if (this.question.hasOwnProperty('options')) {
				let valueDescription = ''
				this.question.options.forEach(option => {
					if (parseInt(option.value) === parseInt(selection.value))
						valueDescription = option.description
				})
				return valueDescription
			} else return this.question.label[parseInt(selection.value)] || ''
		}
	},
	components: {
		StarIcon
	}
}
</script>

<style lang="scss" scoped>
.stars {
	text-align: center;
	.star {
		width: 10%;
		height: 10%;
		margin: 0.7em;
	}
	p {
		padding: 20px 0 0 0.4em;
		color: #833e93;
		font-size: 16px;
		font-weight: 600;
		float: left;
	}
}
</style>
