<template>
<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse opacity="0.9" cx="13.0396" cy="12.4243" rx="12.8416" ry="11.2314" transform="rotate(24.7477 13.0396 12.4243)" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_hand_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
