<template>
<svg width="33" height="42" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.34689 10.5324C3.34689 10.5324 5.3423 -0.890646 17.8495 0.138875C30.353 1.16661 29.8826 14.7023 29.8826 14.7023C29.8826 14.7023 32.5266 16.4146 32.5266 20.7561C32.5266 25.0958 30.2942 27.8376 30.2942 27.8376C30.2942 27.8376 29.1807 33.8342 26.2445 36.9764C23.3084 40.1168 19.4921 42.1169 12.2693 41.3733C5.05016 40.6316 3.34689 34.1774 3.34689 34.1774C3.34689 34.1774 2.58253 30.1791 3.63903 24.926C3.63903 24.926 -0.177242 22.9832 0.236172 18.2413C0.645912 13.503 3.34689 10.5324 3.34689 10.5324Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_foot_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
