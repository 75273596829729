<template>
<svg width="41" height="72" viewBox="0 0 41 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M26.0986 30.536C33.2977 21.0714 45.8902 16.0759 37.088 8.57541C32.8848 4.99381 26.3523 1.16621 20.867 0.529061C15.0577 -0.145712 11.0812 0.255379 8.27879 5.38857C5.44888 10.5722 3.83212 22.104 2.4415 33.0606C0.744939 46.4275 -0.423603 63.0937 1.52458 67.5512C3.20536 71.3968 9.94371 72.7129 13.1486 70.0032C18.0734 65.8394 16.856 42.6872 26.0986 30.536Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_outer_hip_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
