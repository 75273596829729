<template>
	<el-row class="container">
	 	<el-col :span="12" type="flex" align="center"
				class="block" :style="{ backgroundColor: bgColor }">
			<span class="title">Date</span><br>
			<span class="subtitle">{{ getDate() }}</span>
		</el-col>
	 	<el-col :span="12" type="flex" align="center"
				class="block" :style="{ backgroundColor: bgColor, float: 'right' }">
			<span class="title">Time</span><br>
			<span class="subtitle">{{ getTime() }}</span>
		</el-col>
	</el-row>
</template>

<script>
	const months =['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
	export default {
		name: 'DateAndTime',
		props: {
			bgColor: {
				type: String,
				default: '#eeeeee'
			},
		},
		data() {
			return {
				months
			}
		},
		methods: {
			getDate() {
				let date = new Date
				let month = date.getMonth()
				let day = date.toString().split(' ')[0]
				return day + ', ' + this.months[month] + ' ' + date.getDate()
			},
			getTime() {
				let date = new Date
				let hours = date.getHours()
				let minutes = date.getMinutes()
				let ampm = hours >= 12 ? 'PM' : 'AM'
				hours = hours % 12
				hours = hours ? hours : 12 // the hour '0' should be '12'
				minutes = minutes < 10 ? '0'+minutes : minutes
				let strTime = hours + ':' + minutes + ' ' + ampm
				return strTime
			}
		},
		computed: {

		}
	}
</script>

<style lang="scss" scoped>
	.container {
		margin-top: 5px;
		height: 50px;
	}
	.block {
		height: 100%;
		padding: 5px;
		width: 48%;
		.title {
			font-size: 12px;
			color: #333333;
		}
		.subtitle {
			line-height: 20px;
			font-size: 16px;
			font-weight: 600;
			color: #333333;
		}
	}
</style>
