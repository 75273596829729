<template>
    <div class="box-section">
        <div class ="image-title">
            <img 
                :src="getImage()">
            <span class="title">
                {{ attributes.title }}
            </span>
            <span class="count">
                {{ this.getCount() }}
            </span>
        </div>
    </div>
</template>

<script>

export default {

	props: {
        attributes: {
            type: Object
        }
	},

    data() {
        return {
        }
    },
    mounted() {
    },
	methods: {
        getImage() {
            if (! this.attributes.image) {
                var file = (this.attributes.title).replace(' ', '_').toLowerCase() + '.png'
                return require('../image/' + file)
            }
            return this.attributes.image
        },
        getCount() {
            return `${this.attributes.count > 1 ? this.attributes.count + ' times' : ' 1 time'}`
        }
    },
    computed: {

    },
	components: {

	}
}
</script>

<style lang="scss">
@import "style.scss";
// .box-section {
//     .image-title {
//         display: flex;
//         align-items: center;
//         justify-content: flex-start; 
//         margin-top: 2.5%;
//         margin-right: 5%;
//         margin-bottom: 2%;
//         img {
//             width: 30px;
//             margin-left: -5%;
//         }
//         .title {
//             margin-left: 5%;
//         }
//         .count {
//             margin-left: auto;
//         }
//     }
// }

// @media only screen and (min-width: 600px) {
//     .box-section {
//         .medication {
//             .title {
//                 font-size: 18pt;
//                 font-weight: 500;
//             }
//             .count {
//                 font-size: 18pt;
//                 font-weight: 500;
//             }
//         }
//         .image-title {
//             .count {
//                 font-size: 18pt;
//                 font-weight: 500;
//             }
//         }
//     }
// }
</style>
