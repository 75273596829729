<template>
<svg width="60" height="93" viewBox="0 0 60 93" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.323372 0.5C0.323372 0.5 -0.0394408 20.618 0.32333 24.8782C3.0596 57.0111 1.61585 60.6096 7.99988 77.5C7.99988 77.5 25.5448 75.2746 34.9999 79.5C43.3234 83.2197 51.4999 93 51.4999 93C51.4999 93 53.8718 57.7739 55.0439 44.6523C56.0024 33.9266 59.9999 8 59.9999 8C59.9999 8 49.9999 18.6426 29.9999 17C8.49988 15.2342 0.323372 0.5 0.323372 0.5Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
