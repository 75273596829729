<template>
    
    <svg width="88" height="94" viewBox="0 0 88 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M82.7126 76.8888L87.4224 16.6713C88.1852 6.91768 78.9276 -0.696541 69.3394 1.24803C60.6805 3.00413 50.6471 4.56111 42.5 4.5C35.3924 4.44669 26.796 3.13992 19.0613 1.59749C9.09218 -0.390492 -0.407768 7.90038 0.998328 17.968L9.32115 77.5594C10.0452 82.7439 13.594 87.1005 18.6215 88.5589C26.0026 90.7002 37.0045 93.4197 45.5 93.5C54.2462 93.5826 65.5748 90.8878 73.2753 88.7058C78.6183 87.1918 82.2796 82.4253 82.7126 76.8888Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>