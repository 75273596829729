<template>
    
    <svg width="16" height="33" viewBox="0 0 16 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M16 16.0137C16 24.846 14.8622 32.2865 8.035 32.006C1.66306 32.006 0.0699889 24.846 0.0699889 16.0137C0.0699889 7.18136 -1.29537 -0.259224 8.035 0.0213574C15.0898 0.0224118 16 7.18136 16 16.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>