<template>
<svg width="60" height="148" viewBox="0 0 60 148" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.209 49.2487C59.209 49.2487 22.7285 4.10406 10.4163 0C10.4163 0 -2.35185 54.7208 0.384416 90.2894C3.12068 125.858 2.66423 124.034 9.04826 142.73C9.04826 142.73 16.5923 125.774 30.0247 125.805C43.9901 127.68 50.5451 147.29 50.5451 147.29C50.5451 147.29 53.9329 126.702 55.105 112.178C56.4351 95.7019 59.3883 75.313 59.209 49.2487Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_thigh_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
