<template>
	<DownloadView v-if="completed" :type="'symptom'"></DownloadView>
</template>

<script>
import DownloadView from '@/component/DownloadView'

export default {
	name: 'symptom-complete',
	data() {
		return {}
	},
	props: {
		completed: {
			type: Boolean
		}
	},
	mounted() {
		console.log('complete page mounted')
	},
	components: {
		DownloadView
	}
}
</script>


<style lang="scss" scoped>
//@import "../style.scss";
</style>
