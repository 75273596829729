<template>
<svg width="56" height="41" viewBox="0 0 56 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M55.2313 2.84039C55.362 1.45928 54.0835 0.35949 52.7342 0.681826C47.4118 1.9533 35.5184 4.59463 27 5C19.8982 5.33796 9.5313 4.57563 3.30746 4.0187C1.46649 3.85396 0.273476 6.14421 1.38734 7.61921C4.11597 11.2325 7.5 16.5167 7.5 20.5C7.5 25.8238 9.40895 33.1533 10.1813 35.901C10.374 36.5863 10.9189 37.0982 11.6143 37.2501C15.1454 38.0215 26.3071 40.2938 34 40C39.5843 39.7867 47.4792 38.0631 50.6269 37.3291C51.4522 37.1366 52.0534 36.436 52.1332 35.5923L55.2313 2.84039Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_upper_abdomen',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
