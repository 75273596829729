<template>
<svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse opacity="0.9" cx="13.468" cy="12.4243" rx="12.8416" ry="11.2314" transform="rotate(24.7477 13.468 12.4243)" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_hand_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
