<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="58" height="67" viewBox="0 0 58 67">
        <defs>
            <path id="zi0eshnrha" d="M0 0.505L39.191 0.505 39.191 61 0 61z"/>
        </defs>
        <g fill="none" fill-rule="evenodd" transform="rotate(-18 40.284 4.588)">
            <path fill="#F8F8F8" d="M7.19 35.978V4.974C7.19 2.79 8.97 1 11.144 1s3.95 1.79 3.95 3.974l-.195 21.254c0 .132.19.145.207.017.28-2.065-.153-6.316 3.736-6.39 2.173 0 3.952 1.79 3.952 3.976v5.183c0-2.186-.384-6.888 3.95-7.016 2.172 0 3.412 1.79 3.412 3.974l-.032 4.919c-.02.292-.02.383.086.112.806-2.073-.268-6.533 3.842-6.762 2.173 0 3.949 1.79 3.949 3.974V39.69s0 5.282-2.236 9.315C33.82 52.508 34.21 59 34.21 59H10.828s-.67-6.743-6.082-12.51c0 0-4.458-4.95-3.647-8.908 0 0 .635-3.914 3.852-6.39 0 0 1.8-1.47 2.24-2.373"/>
            <mask id="nqju9805nb" fill="#fff">
                <use xlink:href="#zi0eshnrha"/>
            </mask>
            <path fill="#000" d="M11.84 58.821h21.367c-.019-2.113.143-6.627 1.725-9.49 2.069-3.749 2.101-8.783 2.101-8.836V28.96c0-1.604-1.29-2.909-2.874-2.909-1.813.105-2.125 1.21-2.389 3.704-.093.871-.179 1.695-.453 2.403l-.337.87-.986-.04-.902-.203.056-1.207.033-4.882c0-1.333-.61-2.9-2.337-2.9-2.223.068-2.81 1.615-2.875 4.464v1.501h-2.157l-.005-.49c-.004-.34-.008-.75.005-1.2v-3.52c0-1.604-1.29-2.907-2.877-2.907-1.94.037-2.363 1.353-2.563 4.288-.033.437-.06.845-.108 1.193l-.136 1.007-1.112.022-1.007-.066-.1-1.126.195-21.378c0-1.595-1.29-2.9-2.875-2.9-1.588 0-2.876 1.305-2.876 2.908v31.171H6.195v-4.379c-.222.199-.4.343-.483.414-2.847 2.2-3.466 5.724-3.473 5.758-.58 2.85 2.249 6.774 3.388 8.046 4.422 4.734 5.81 10.058 6.213 12.22zM34.319 61H9.94l-.1-.978c-.006-.066-.72-6.503-5.8-11.94-.211-.238-4.833-5.456-3.923-9.924.02-.133.738-4.349 4.258-7.07.66-.542 1.484-1.344 1.819-1.819V5.592c0-2.803 2.26-5.087 5.034-5.087 2.775 0 5.032 2.284 5.032 5.087l-.135 14.793c.66-.543 1.56-.898 2.788-.92 2.168 0 4.013 1.369 4.734 3.292.73-.658 1.746-1.095 3.163-1.139 2.167 0 3.79 1.37 4.331 3.477.646-.669 1.582-1.142 2.957-1.22h.002c2.835 0 5.09 2.28 5.09 5.085v11.537c0 .23-.028 5.643-2.376 9.896-1.767 3.2-1.424 9.39-1.42 9.452l.07 1.155h-1.146z" mask="url(#nqju9805nb)"/>
        </g>
    </svg>

</template>
<script>
export default {
	name: 'scroller-hand-icon'
}
</script>
