<template>
	<div class="doctor">
		<img :src="doctor.image">
		<span class="name">{{ doctor.name }}</span>
		<span>{{ doctor.specialization }}</span>
	</div>
</template>
<script>
export default {
	name: 'auth-doctor',

	props: {
		doctor: {
			type: Object,
			required: true
		}
	}
}
</script>
<style lang="scss">
.doctor {
	padding: 10px;
	background: #eeeeee;
	height: 60px;
	margin: -10px 0 10px 0;

	img {
		float: left;
		width: 40px;
		height: 40px;
		margin-right: 10px;
		border-radius: 50%;
	}

	span {
		line-height: 18px;
		font-size: 14px;
		display: block;
		text-align: left;
	}
	.name {
		margin-top: 4px;
		font-size: 16px;
		font-weight: 700;
	}
}
</style>
