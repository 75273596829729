<template>
    
    <svg width="312" height="281" viewBox="0 0 312 281" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_3080_9108" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="312" height="281">
<rect width="312" height="281" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_3080_9108)">
<path d="M84.4844 92.2972C88.2835 91.1575 96.4515 90.2457 98.731 95.7164" :stroke="color" stroke-width="3" stroke-linecap="round"/>
<path d="M87.904 100.846C88.4738 102.745 97.5916 107.684 97.5916 107.684C94.7423 108.634 88.8157 111.559 87.904 115.662M89.6135 103.125C87.9039 104.835 87.334 109.508 89.6135 112.243M67.9587 170.369C67.9587 170.369 71.7692 171.49 74.2272 171.509C76.9069 171.529 81.0655 170.939 81.0655 170.939M65.1094 148.144C67.7687 146.625 69.0984 145.637 71.3779 149.284" :stroke="color" stroke-linecap="round"/>
<path d="M74.7883 97.6809C74.7035 95.1728 73.8317 66.707 77.6438 58.5372C81.6329 49.9883 89.438 31.5323 120.384 18.6459C143.849 8.87429 183.655 9.6108 207.003 22.6358C251.055 47.2117 249.025 89.1383 246.341 105.158C246.329 105.233 246.316 105.298 246.301 105.372C246.112 106.257 244.79 112.412 244.044 114.384C243.022 117.085 241.252 120.787 238.915 124.64C233.862 132.973 226.512 144.047 222.959 155.984C219.733 166.82 217.26 173.185 217.83 184.477C218.505 197.831 220.445 206.989 225.808 219.238C233.984 237.908 250.162 241.884 256.011 261.408C257.561 266.583 259.133 276.145 260.087 282.539C260.592 285.927 257.899 288.91 254.476 288.811L118.012 284.862C114.856 284.771 113.96 280.496 116.814 279.145C116.914 279.098 117.007 279.06 117.111 279.022C118.834 278.408 135.233 272.361 139.759 263.118C142.35 257.826 142.652 254.193 142.608 248.301C142.566 242.684 139.815 234.226 139.76 234.058L139.758 234.052C139.703 233.884 137.255 226.375 134.63 222.087C131.175 216.444 127.222 211.831 123.233 209.551C119.575 207.46 96.7486 212.079 92.9853 212.86C92.626 212.935 92.2782 212.973 91.9113 212.966C90.3075 212.938 85.5666 212.727 82.7726 211.26C78.4338 208.981 76.2376 204.42 74.7945 201.002C73.6046 198.183 73.8858 192.534 74.2741 188.632C74.5197 186.163 73.2485 183.749 71.0035 182.693L69.7509 182.103C67.1273 180.868 65.891 177.826 66.9092 175.111L67.2516 174.198C68.0159 172.16 67.4171 169.862 65.7555 168.456L65.2466 168.025C63.0844 166.195 63.0808 162.862 65.239 161.028C68.1705 158.536 66.917 153.76 63.1397 153.029L57.8794 152.01C55.9945 151.646 54.4306 150.335 53.7414 148.543L53.3398 147.499C52.5292 145.392 53.0706 143.005 54.6596 141.401C57.6165 138.415 62.3394 133.402 65.1068 129.199C68.6647 123.797 70.5975 120.562 72.5151 114.384C74.2918 108.659 74.7287 99.4799 74.7872 98.0481C74.7926 97.9168 74.7928 97.8122 74.7883 97.6809Z" :stroke="color" stroke-width="2" stroke-linecap="round"/>
<path d="M144.314 94.5766C144.314 94.5766 143.745 83.1789 154.002 83.7492C164.259 84.3195 168.818 94.5766 168.818 94.5766C168.818 94.5766 172.654 104.55 172.807 111.673C172.974 119.444 167.109 132.188 167.109 132.188C167.109 132.188 159.833 144.281 152.292 144.725C148.077 144.973 146.024 141.306 146.024 141.306" :stroke="color" stroke-linecap="round"/>
<path d="M149.978 100.267C146.442 91.2442 158.57 92.8678 160.849 96.8576C163.129 100.847 164.15 106.707 164.266 112.03C164.393 117.837 159.936 127.36 159.936 127.36C159.936 127.36 153.954 137.795 150.592 132.758C148.31 129.339 152.717 128.621 152.871 125.92C153.021 123.295 147.76 122.187 148.31 119.082C148.679 116.232 150.592 114.523 150.592 114.523" :stroke="color" stroke-linecap="round"/>
</g>
</svg>

</template>

<script>
export default {
	name: 'side',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>