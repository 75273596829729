
import sections from '../../util/sections'
import formatDate from '@/module/util/format-date'
import { mapActions } from 'vuex'
import Vue from 'vue'

import TopicHeader from '../form/component/TopicHeader'
import Summary from '../form/component/Summary'
import TopicSection from '../form/section/TopicSection'
import TopicAdder from '../form/component/TopicAdder'
import PreviousButton from '../form/component/PreviousButton'
import NextButton from '../form/component/NextButton'

export default {
    props: {
		// appointment: {
		// 	type: Object
		// },
		// content: {
		// 	type: Object
		// },
		// summary: {
		// 	type: Object
		// },
		// result: {
		// 	type: Object
		// },
		// selected: {
		// 	type: Object
		// }
    },
    data() {
        return {
			input: null,
			topics: null,
            image: null,
            color: null,
			count: [],
			path: null,
			buttonName: null,
			first: null,
			//name: this.getName,
			previousPath: null,
			previousName: 'PREVIOUS'
        }
	},
    mounted() {
    },
    methods: {
		...mapActions('user', [
			'addEvent'
		]),
		formatDate,
        onClickSection({ key, selected }) {
			if (key in this.addedQuestions)
				Vue.set(this.result[this.name].addedQuestions, key, selected)
			else {//if (this.selectedQuestions)
				Vue.set(this.result[this.name].selectedQuestions, key, selected)
			}
			localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
		},
		checkFirst() {
			// Check if current topic is the first one
			if (this.result.first == this.name) {
				this.first = true
				return false
			}
			this.first = false
			return true
		},
		addTopic(value) {
			if (value) {
				// Add custom question
				this.topics.push({ question: value, selected: true })
				// Reset input text
				this.input = null
				// Save value to accumulated list of selected questions (used for review at the end)
				Vue.set(this.result[this.name].addedQuestions, value, true)
				localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
			}
        },
		setButtonName(name, button) {
			// if (this.result[this.name].status == 'edit') {
			// 	Vue.set(this.result[this.name], status, 'none')
			// 	localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
			// 	this.path = 'appointment_review'
			// 	this.buttonName = 'SAVE'
			// }
			if (this.name === 'coping_living_question') {
				this.path = 'appointment_cause_risk_question'
				this.buttonName = 'NEXT'
			}
			else if (this.name === 'cause_risk_question') {
				this.path = 'appointment_diagnosis_treatment_question'
				this.buttonName = 'NEXT'
			}
			else if (this.name === 'diagnosis_treatment_question') {
				this.path = 'appointment_other_question'  
				this.buttonName = 'NEXT'
			}
			else if (this.name === 'other_question') {
				this.path = 'appointment_review'
				this.buttonName = 'SAVE'
			}
			else if (name) {
				this.path = 'appointment_' + name
				this.buttonName = button ? button : 'NEXT'
			}
			else if (this.next) {
				this.path = 'appointment_' + this.next
				this.buttonName = 'NEXT'
			}
			else if (this.name === 'other_question') {
				this.path = 'appointment_review'
				this.buttonName = 'REVIEW'
			}
			// else if (this.result['coping_living_question']) {
			// 	this.path = 'appointment_coping_living_question'
			// 	this.buttonName = 'NEXT'
			// }
			else {
				this.path = 'appointment_other_question'
				this.buttonName = 'NEXT'
			}
		},
		setPrevious() {
			// Update previous topic value
			if (this.result[this.name].previous) {
				this.previousPath = 'appointment_' + this.result[this.name].previous
			}
			else
				this.previousPath = null
		}
    },
    computed: {
        // getName() {
        //     return (this.$route.name).split('_')[1]
		// },
		questions() {
			return this.topics
		},
		getReferences() {
			this.addedQuestions = this.result[this.name].addedQuestions
			// Set except for 'Other Questions' section (since questions will only be added and none will be pre-existing)
			if (this.name !== 'other_question') {
				this.selectedQuestions = this.result[this.name].selectedQuestions
				this.next = this.result[this.name].next
			}
		},
		// Get the statement that will be shown above questions to discuss
		getStatement() {
			return (misc) => {
				return `<strong>${ misc ? 'Topics' : 'Questions' }</strong>&nbsp;(to discuss with your doctor)`
			}
		},
		// Get attributes from ocal storage
		getFromStorage() {
			// Get the state from local storage, and initialize if not yet initialized
			var state = localStorage.getItem(this.appointment.id + '_result')
			if (state) {
				this.result = JSON.parse(state)
				Object.keys(this.result).forEach((topic) => {
					Vue.set(this.result, topic, this.result[topic])
				})
			}
		},
		// Set reference to current topic
		updateStorage() {
			Vue.set(this.result, 'current', this.name)
			localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
		},
		// Get the image and color associated with section (sections is an array, thus filtering)
		getResources() {
			return (topic) => {
				if (this.name !== 'other_question') {
					// Get image and color for current topic
					var filtered = sections.filter(item => item.key == topic)
					this.image = filtered[0].image
					this.color = filtered[0].color
				}
			}
		},
		// Get all the content that needs to be shown inside the box
		getCount() {
			return (topic) => {
				if (this.summary) {
					var events = this.summary[topic].event
					// Load all related events to topic
					Object.keys(events).forEach((event) => {
						this.count.push(events[event])
					})
				}
			}
		},
		// Get all questions that need to be shown as selected or unselected
		loadSelected() {
			var addedKeys
			// Hold all questions temporarily
			var questions = {}
			
			// Set topics in object format
			if (this.name !== 'other_question') {
				this.content[this.name].forEach((question) => {
					if (this.selectedQuestions[question] && this.selectedQuestions[question] == true)
						questions[question] = true
					else
						questions[question] = false
				})
			}
			if (this.addedQuestions) {
				addedKeys = Object.keys(this.addedQuestions)
				// Insert added questions
				if (addedKeys.length > 0) {
					addedKeys.forEach((added) => {
						questions[added] = this.addedQuestions[added]
					})
				}
			}
			// Convert questions object to array
			this.topics = Object.keys(questions).map((item) => {
				return { question: item, selected: questions[item] }
			})
		},
		// // Get all questions that need to be shown as selected or unselected for the topic, 'Other Question'
		// loadSelectedOther() {
		// 	var addedKeys = Object.keys(this.addedQuestions)
		// 	// Hold all questions temporarily
		// 	var questions = {}
		// 	// Insert added questions
		// 	if (addedKeys.length > 0) {
		// 		addedKeys.forEach((added) => {
		// 			questions[added] = this.addedQuestions[added]
		// 		})
		// 	}
		// 	// Convert questions object to array
		// 	this.topics = Object.keys(questions).map((item) => {
		// 		return { question: item, selected: questions[item] }
		// 	})
		// },
		getRoute() {
			return { name: this.path }
		},
		getPreviousRoute() {
			return { name: this.previousPath }
		}
	},
	components: {
		TopicSection,
		TopicHeader,
		Summary,
		TopicAdder,
		PreviousButton,
		NextButton
	}
}
