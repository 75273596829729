<template>
    <div class="button" >
        <router-link
            v-on:click.native="handleClick"
            :to="route">
                {{ buttonName }}
        </router-link>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
	name: 'button-main',
	props: {
		buttonName: {
			type: String
        },
        route: {
            type: Object
        },
        review: {
            type: Boolean
        }
    },
    methods: {
        handleClick() {
            if (this.review) {
                this.$emit('saved')
            }
        }
    },
    computed: {
    }
}
</script>
<style lang="scss">
.button {
    text-align: center;
    a {
        border: #2D3E4F;
        background-color: #2D3E4F;
        color: white;
        text-decoration: none;
        text-align: center;
        padding: 10px 120px;
        border-radius: 4px;
        font-size: 20px;
        font-weight: 700;
    }
}
@media only screen and (min-width: 600px) {
    .button {
        a {
            //padding: 15px 225px;
        }
    }
}
</style>
