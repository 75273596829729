<template>
<svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3122 6.3841C7.60726 7.97664 2.67029e-05 9.57615 2.67029e-05 9.57615V25.7965V57.5C2.67029e-05 57.5 9.75732 58 14.2573 59C20.6213 60.4142 27.7573 63.5 27.7573 63.5C27.7573 63.5 26.5979 43.8023 26.2573 38C25.9104 32.0898 20.9586 16.8805 20.5203 10.9763C19.9773 3.66043 23.7124 0 23.7124 0C23.7124 0 16.3217 5.02696 12.3122 6.3841Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_flank_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
