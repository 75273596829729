<template>
    
    <svg width="191" height="270" viewBox="0 0 191 270" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.2579 186.994L44.2838 248.192C44.2555 249.067 43.8252 249.876 43.109 250.379C40.1426 252.464 31.9509 258.141 25.9816 261.518C20.88 264.404 13.975 267.55 8.56572 269.887C6.01416 270.99 6.38344 274.801 9.10782 275.352C73.7824 288.434 114.356 288.01 181.685 275.263C184.432 274.743 184.852 270.872 182.304 269.722C177.249 267.439 170.995 264.411 166.423 261.518C158.667 256.611 148.573 247.554 145.548 244.787C144.974 244.262 144.654 243.521 144.654 242.743V187.084C144.654 185.533 143.397 184.275 141.846 184.275H49.0653C47.5493 184.275 46.3068 185.478 46.2579 186.994Z" fill="white" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round"/>
<path d="M23.8755 115.46C23.8755 115.46 21.0667 116.162 19.6622 118.971C18.2578 121.779 21.0666 143.548 26.6843 149.165C32.3019 154.783 36.5152 153.379 36.5152 153.379C36.5152 153.379 37.8407 164.502 40.0262 171.285C42.3964 178.641 48.4527 189.191 48.4527 189.191C48.4527 189.191 67.4122 222.897 96.2027 222.897C124.993 222.897 142.548 189.191 142.548 189.191C142.548 189.191 147.208 178.415 149.57 171.285C151.851 164.401 154.486 153.379 154.486 153.379C154.486 153.379 155.89 154.081 162.912 149.165C169.934 144.25 171.339 121.077 169.934 118.971C168.53 116.864 165.019 115.46 165.019 115.46C165.019 115.46 170.168 96.3904 171.339 83.8603C172.384 72.6656 171.339 55.0699 171.339 55.0699C171.339 55.0699 155.89 1 94.7982 1C33.7063 1 18.2578 55.0699 18.2578 55.0699C18.2578 55.0699 18.6669 72.6477 19.6622 83.8603C20.763 96.261 23.8755 115.46 23.8755 115.46Z" fill="white" stroke="#B2B2B2" stroke-width="2" stroke-linecap="round"/>
<path d="M87.376 178.166L89.3092 178.429C92.3159 178.811 94.3695 178.929 97.3097 178.939C100.766 178.95 103.23 178.78 107.243 178.233C107.935 178.139 108.571 178.624 108.666 179.315C108.76 180.006 108.275 180.643 107.584 180.737L105.471 181.007C102.486 181.358 100.227 181.476 97.3012 181.466C93.655 181.453 91.287 181.278 87.0186 180.668C86.3278 180.569 85.8478 179.929 85.9465 179.238C86.0342 178.624 86.5496 178.177 87.1483 178.154L87.376 178.166Z" fill="#B2B2B2"/>
<path d="M94.4604 172.27C94.8123 172.179 95.1865 172.244 95.4872 172.448L97.4007 173.748L99.5078 172.423C99.7466 172.273 100.027 172.208 100.304 172.236L100.51 172.273L115.047 176.201C115.72 176.383 116.119 177.077 115.937 177.751C115.775 178.35 115.209 178.731 114.612 178.681L114.387 178.641L100.385 174.855L98.0477 176.327C97.6229 176.594 97.0803 176.585 96.6651 176.303L94.5401 174.86L79.9323 178.644C79.3318 178.8 78.7203 178.497 78.4689 177.953L78.3923 177.738C78.2367 177.138 78.54 176.526 79.0837 176.275L79.2985 176.198L94.4604 172.27Z" fill="#B2B2B2"/>
<path d="M83.1213 154.451C84.3614 153.06 86.7985 152.514 89.5231 153.308C91.3089 153.829 92.4362 154.491 93.7174 155.627L94.9086 156.734C95.4784 157.251 97.9226 157.542 99.3144 156.618L99.6728 156.365C100.557 155.716 102.03 154.509 101.896 154.611C102.894 153.855 103.813 153.442 105.086 153.269C107.518 152.94 109.833 153.648 111.972 155.355C112.517 155.791 112.606 156.586 112.171 157.131C111.736 157.676 110.941 157.765 110.395 157.33C108.778 156.039 107.147 155.54 105.425 155.773C104.613 155.884 104.092 156.118 103.422 156.625L102.829 157.113C102.205 157.615 101.241 158.372 100.713 158.723C98.3173 160.314 94.6065 159.871 93.2113 158.606L92.4204 157.865C91.2143 156.731 90.3665 156.186 88.816 155.734C87.1185 155.24 85.7777 155.473 85.1494 155.996L85.0074 156.133C84.5429 156.654 83.7442 156.699 83.2234 156.235C82.7026 155.77 82.6568 154.972 83.1213 154.451Z" fill="#B2B2B2"/>
<path d="M90.2078 105.713C90.8594 105.463 91.5901 105.789 91.8399 106.44C94.0584 112.226 92.0975 132.608 89.4656 139.456C87.6671 144.136 86.0527 146.417 83.6252 148.496C82.3717 149.569 82.1113 152.3 84.812 154.272C85.3756 154.683 85.4989 155.474 85.0874 156.037C84.6759 156.601 83.8854 156.724 83.3218 156.313C79.1546 153.27 79.5982 148.618 81.9816 146.577C84.0909 144.77 85.4592 142.837 87.1068 138.55C89.5353 132.231 91.4382 112.451 89.4804 107.345C89.2306 106.694 89.5562 105.963 90.2078 105.713Z" fill="#B2B2B2"/>
<path d="M142.671 87.796C131.928 87.796 119.136 89.6993 109.394 92.9967C108.468 93.3099 107.972 94.314 108.285 95.2393C108.598 96.1647 109.602 96.661 110.528 96.3478C119.886 93.1805 132.297 91.3339 142.671 91.3339C148.753 91.3339 153.493 92.9353 156.978 96.109C157.7 96.7669 158.819 96.7146 159.477 95.9924C160.135 95.2701 160.082 94.1513 159.36 93.4934C155.179 89.6855 149.587 87.796 142.671 87.796Z" fill="#B2B2B2"/>
<path d="M49.72 87.796C60.4624 87.796 73.2545 89.6993 82.997 92.9967C83.9224 93.3099 84.4187 94.314 84.1055 95.2393C83.7923 96.1647 82.7882 96.661 81.8628 96.3478C72.5047 93.1805 60.0933 91.3339 49.72 91.3339C43.6372 91.3339 38.8973 92.9353 35.4126 96.109C34.6904 96.7669 33.5716 96.7146 32.9137 95.9924C32.2559 95.2701 32.3081 94.1513 33.0304 93.4934C37.2114 89.6855 42.8039 87.796 49.72 87.796Z" fill="#B2B2B2"/>
</svg>

</template>


<script>
export default {
	name: 'body_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
