export default function({ dispatch, commit }) {
    // POST /auth/login
    return dispatch('post', {
        url: '/auth/logout'
    }, {
        root: true
    })
    .then((response) => {
        commit('invalidatedToken')
    })
    .catch((error) => {
        console.log('error', error)
    })
}
