<template>
    
    <svg width="28" height="59" viewBox="0 0 28 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M20.3617 1.79149L12.2765 0.586697C10.0989 0.262217 8.06851 1.75843 7.73353 3.93441L4.38992 25.6539C4.35722 25.8663 4.30868 26.0718 4.24365 26.2766C3.76927 27.7709 1.69098 34.4553 0.999425 39C0.527727 42.0998 0.81688 46.4372 1.11453 49.3514C1.30347 51.2012 2.81019 52.6022 4.65234 52.8551C6.88365 53.1614 9.84914 53.6853 11.998 54.5C13.7587 55.1675 15.6967 56.3053 17.4646 57.4957C20.1026 59.272 23.9333 57.8206 24.3855 54.6726L27.4191 33.5524C27.4718 33.1859 27.4733 32.8139 27.4236 32.447L23.736 5.21113C23.4973 3.44752 22.122 2.05378 20.3617 1.79149Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_upper_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>