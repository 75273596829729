import SymptomLanding from './Landing'
import SymptomViewer from './Viewer'
import SymptomQuestion from './Question'
import SymptomThankyou from './Thankyou'
import SymptomComplete from './Complete'
import AbsentModule from '@/component/AbsentModule'

export default [
	{
		path: '/symptom',
		name: 'symptom-landing',
		props: true,
		component: AbsentModule
	},
	{
		path: '/symptom/:id',
		name: 'symptom-viewer',
		props: (route) => ({
			id: route.params.id,
			number: route.params.number,
			type: 'symptom',
			query: route.query
		}),
		component: AbsentModule,

		meta: {
			link: true
		},
		children: [
			{
				path: 'question/:number',
				name: 'symptom-question',
				props: true,
				// Import the questionnaire viewer
				component: SymptomQuestion
				//component: QuestionnaireDownload
			}
		]
	},
	{
		path: '/learn/:id',
		name: 'learn-viewer',
		props: true,
		component: SymptomViewer,

		meta: {
			link: true
		}
	},
	{
		path: '/symptom/complete',
		name: 'symptom-complete',
		component: SymptomComplete,
		props: true,
		// meta: {
		// 	authComponent: true
		// }
	},
	{
		path: '/symptom/thankyou',
		name: 'symptom-thankyou',
		component: SymptomThankyou,
		props: true,
		meta: {
			authComponent: true
		}
	}
]
