<template>
<svg width="49" height="54" viewBox="0 0 49 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.1474 34.783C30.0724 29.6642 30.6 25.4722 33.9875 20.647C38.7196 13.9067 48.5797 5.92809 48.5797 5.92809L44.0197 0.126697C44.0197 0.126697 36.4252 -0.36287 31.7075 0.582704C22.9561 2.33676 18.0221 5.28403 11.6432 11.5269C5.70623 17.3372 1.1551 26.5751 0.699034 29.7671C0.242968 32.9592 0.699034 53.4795 0.699034 53.4795C0.699034 53.4795 23.4993 41.1671 27.1474 34.783Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_shoulder_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
