export default {
    // The authentication ID
    id: localStorage.getItem('auth'),

    // Basic authentication information for showing the user's account
    name: null,
    email: null,
    type: null,
    light: false,
    // For signing up
    code: null,
    codeProvider: null,
    failedValidation: false,
    
    // For upgrading and resetting a password
    upgradeToken: null,
    resetToken: null,
    setPassword: false,

    // Doctor information for join/upgrade
    doctor: null,

    // Whether the client is authenticated
    authenticated: (localStorage.getItem('authenticated') === 'true'),
    authenticating: false,

    // Whether the client has attempted to verify the access token stored in an HTTP-only cookie
    verified: localStorage.getItem('verified'),

    // Link-based handoff
    // handoff_token: localStorage.getItem('handoff_token')
    handoff_token: null
}
