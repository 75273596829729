import body_f from "./body_f"
import body_b from "./body_b"

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import around_right_eye from "./around_right_eye"
import around_left_eye from "./around_left_eye"
import right_cheekbone from "./right_cheekbone"
import left_cheekbone from "./left_cheekbone"
import right_jaw from "./right_jaw"
import left_jaw from "./left_jaw"
import tongue from "./tongue"

import face from "./face"
import neck from "./neck"
import right_armpit from "./right_armpit"
import left_armpit from "./left_armpit"
import right_breast from "./right_breast"
import left_breast from "./left_breast"
import upper_abdomen from "./upper_abdomen"
import lower_abdomen from "./lower_abdomen"
import groin_area from "./groin_area"
import right_arm from "./right_arm"
import left_arm from "./left_arm"
import right_hand_fingers from "./right_hand_fingers"
import left_hand_fingers from "./left_hand_fingers"
import right_leg_calf from "./right_leg_calf"
import left_leg_calf from "./left_leg_calf"
import right_foot from "./right_foot"
import left_foot from "./left_foot"
import lips_mouth from "./lips_mouth"


export default {
    body_b,
    body_f,
    body_f_selected,
    body_b_selected,
    around_right_eye,
    around_left_eye,
    right_cheekbone,
    left_cheekbone,
    right_jaw,
    left_jaw,
    face,
    neck,
    right_armpit,
    left_armpit,
    right_breast,
    left_breast,
    upper_abdomen,
    lower_abdomen,
    groin_area,
    right_arm,
    left_arm,
    right_hand_fingers,
    left_hand_fingers,
    right_leg_calf,
    left_leg_calf,
    right_foot,
    left_foot,
    tongue,
    lips_mouth
}