<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="35" height="29" viewBox="0 0 35 29">
	    <defs>
	        <linearGradient id="i-h-vg" x1="100%" x2="35.018%" y1="8.945%" y2="71.855%">
	            <stop offset="0%" stop-color="#AFC94A"/>
	            <stop offset="100%" stop-color="#709F34"/>
	        </linearGradient>
	    </defs>
	    <g fill="none" fill-rule="evenodd">
	        <path fill="url(#i-h-vg)" d="M14.575 19.778l-.032-.037c-2-2.372-2.114-5.916 0-8.173 2.114-2.256 5.541-2.256 7.655 0l1.28 1.367.022-.023.021.022 1.28-1.366c2.115-2.257 5.542-2.257 7.656 0s2 5.8 0 8.173c-.105.125-.224.26-.35.399h-2.652l-1.494-5.395a.693.693 0 0 0-.573-.514.67.67 0 0 0-.668.361l-3.74 7-2.393-4.895a.68.68 0 0 0-.538-.386.661.661 0 0 0-.596.272l-2.367 3.195h-2.511zM28.774 22.3h1.717c-2.768 2.834-6.99 6.605-7.031 6.605 0 0-4.595-4.103-7.339-6.966h1.448a.639.639 0 0 0 .517-.275l1.868-2.593 2.413 5.079a.66.66 0 0 0 .584.39c.237 0 .456-.14.573-.366l3.465-6.676 1.153 4.283c.083.308.34.52.632.52z" transform="translate(1)"/>
	        <path fill="#709F34" d="M7 5.5h5.5v1H7V12H6V6.5H.5v-1H6V0h1v5.5z"/>
	    </g>
	</svg>

</template>
<script>
export default { name: 'header-add-vital-icon' }
</script>
