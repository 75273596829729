<template>
    <div v-if="question" class="questionnaire-video">
        <div class="video">
            <video id="questVideo" width="100%" height="100%" @play="videoPlaying" controls>
                <source :src="question.video" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'


    export default {
        name: 'questionnaire-video',
        extends: Question,
        props: {
            saved: {
                type: Array
            }
        },
        data() {
            return {
                watchedTime: ''
            }
        },
        watch: {
            watchedTime: function() {
                let reqObj = {
                    id: uuidv4(),
                    value: this.watchedTime
                }
                this.$emit('selected', [ reqObj ])
            },
        },
        mounted() {
            if (!this.saved || this.saved.length === 0)
                this.$emit('selected', [])
            else {
                this.watchedTime = this.saved[0].value
            }
        },
        methods: {
            videoPlaying() {
                const d = new Date();
                this.watchedTime = d.getTime();
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>
