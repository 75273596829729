<template>
<svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.10415 16.8885C3.37081 22.0254 10.5795 23.8196 17.2052 20.8961C23.831 17.9725 27.3647 11.4381 25.098 6.3012C22.8314 1.16428 15.6227 -0.629981 8.99693 2.29361C2.3712 5.2172 -1.16251 11.7515 1.10415 16.8885ZM21.247 9.52699C21.247 10.0626 20.8127 10.4969 20.2771 10.4969C19.7414 10.4969 19.3072 10.0626 19.3072 9.52699C19.3072 8.99134 19.7414 8.55711 20.2771 8.55711C20.8127 8.55711 21.247 8.99134 21.247 9.52699ZM12.5181 9.52715C13.0537 9.52715 13.4879 9.09291 13.4879 8.55726C13.4879 8.02161 13.0537 7.58738 12.5181 7.58738C11.9824 7.58738 11.5482 8.02161 11.5482 8.55726C11.5482 9.09291 11.9824 9.52715 12.5181 9.52715ZM14.4579 15.3463C14.4579 15.882 14.0236 16.3162 13.488 16.3162C12.9523 16.3162 12.5181 15.882 12.5181 15.3463C12.5181 14.8107 12.9523 14.3764 13.488 14.3764C14.0236 14.3764 14.4579 14.8107 14.4579 15.3463ZM6.69879 15.3465C7.23444 15.3465 7.66867 14.9123 7.66867 14.3766C7.66867 13.8409 7.23444 13.4067 6.69879 13.4067C6.16314 13.4067 5.72891 13.8409 5.72891 14.3766C5.72891 14.9123 6.16314 15.3465 6.69879 15.3465Z" :fill="color"/>
</g>
</svg>
</template>
<script>
export default {
	name: 'left_elbow_crease',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
