<template>
    <div class="topic-section-main">
        <div 
            v-bind:class="['topic-section-item', { last }]">
            <div class="topic-image-title">
                <component v-if="personal" :is="getImage" class="section-image"></component>
                <span v-bind:class="['section-title', { settings }]">
                    {{ section.title }}
                </span>
                <div 
                    v-if="personal && ! sectionValue"
                    @click="clickArrow"
                    v-bind:class="['arrow', { selected }]">
                    <Arrow></Arrow>
                </div>
                <div 
                    v-if="sectionValue"
                    class="section-value">
                    {{ sectionValue }}
                </div>
                <SwitchItem 
                    v-if="settings" 
                    :status="getSwitchStatus"
                    @toggle="updateSwitch"
                    class="arrow">
                </SwitchItem>
            </div>
            <div v-if="settings" class="message">
                {{ getMessage }}
            </div>
        </div>
        <div v-if="drop" class="topic-section-drop">
            <component v-if="! componentSaved" :is="getComponent" @params="handleParams" @invalidText="invalidValue = false"></component>
            <div v-else class="component-saved">{{ savedText }}</div>
        </div>
        <div v-if="invalidValue" class="invalid-text">
            {{ getInvalidText }}
        </div>
        <div v-if="drop && ! componentSaved" class="save-cancel">
            <MainButton @clickButton="handleCancel" :cancel=true></MainButton>
            <MainButton @clickButton="handleSave" :save=true></MainButton>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Arrow from '../../image/Arrow'
import SwitchItem from '../SwitchItem'
import Icons from '../../image/icon/components'
import Date from './Date'
import GenderSection from './Gender'
import HeightSection from './Height'
import MainButton from '../button/Button'

const genderMap = {
    male: 'M',
    female: 'F',
    other: 'O'
}
const genderStringMap = {
    M: 'Male',
    F: 'Female',
    O: 'Other'
}
const notificationMap = {
    email: 'email_disabled',
    sms: 'phone_disabled',
    pn: 'push_disabled'
}
const componentMap = {
    birthdate: 'Date',
    height: 'HeightSection',
    gender: 'GenderSection'
}
const savedMap = {
    birthdate: 'birth date',
    height: 'height',
    gender: 'gender'
}
const handlerMap = {
    day: 'updateDay',
    month: 'updateMonth',
    year: 'updateYear',
    gender: 'handleGender',
    feet: 'handleFeet',
    inch: 'handleFeet',
    cm: 'handleCM'
}
const validationMap = {
    birthdate: [
        'dayValue',
        'monthValue',
        'yearValue'
    ],
    gender: [
        'genderValue'
    ],
    height: [
        'feetValue',
        'inchValue'
    ]
}
export default {
	props: {
        topics: {
            type: Object
        },
        section: {
            type: Object
        },
        last: {
            type: Boolean
        },
        personal: {
            type: Boolean
        },
        settings: {
            type: Boolean
        },
        type: {
            type: String
        }
	},
	mounted() {
        this.checkType
	},
	data() {
		return {
            selected: false,
            drop: false,
            status: false,
            genderValue: null,
            feetValue: 3,
            inchValue: 3,
            cmValue: null,
            dayValue: null,
            monthValue: null,
            yearValue: null,
            dateSaved: false,
            genderSaved: false,
            heightSaved: false,
            sectionType: null,
            sectionValue: null,
            componentSaved: false,
            invalidValue: false
		}
	},
    methods: { 
        ...mapActions('user', [
            'updateUser',
            'removeUser',
            'loadUser'
		]),
        clickArrow() {
            this.selected = this.selected ? false : true
            this.drop = this.drop ? false : true
        },
        updateSwitch(value) {
            if (value) {
                this.status = false
                return this.updateUser({ [notificationMap[this.section.id]]: true })
            }
            else {
                this.status = true
                // Section is related to sms and user does not have sms number set up for profile yet
                if (this.section.id == 'sms' && ! this.getUser.sms)
                    return this.$router.push({ name: 'sms', params: { sms: true }})
                
                return this.removeUser({ [notificationMap[this.section.id]]: true })
            }
        },
        handleBirth(value) {
            this.birthValue = value
        },
        handleGender(value) {
            this.genderValue = value.gender
        },
        handleSave() {
            var update, topics = validationMap[this.type], newValue
            // Go through topics of each type and validate params
            for (var i = 0; i < topics.length ; i++) {
                if (! this[topics[i]]) {
                    this.invalidValue = true
                    return 
                }
            }
            if (this.type == 'birthdate') {
                var date = this.monthValue + '/' + this.dayValue + '/'  + this.yearValue
                update = { birthdate: date }
                newValue = date
            }
            else if (this.type == 'gender') {
                update = { gender: genderMap[this.genderValue] }
                newValue = this.genderValue.charAt(0).toUpperCase() + this.genderValue.slice(1)
            }
            else if (this.type == 'height') {
                var total = (this.feetValue * 12) + this.inchValue
                update = { height: { unit: 'in', value: total }}
                newValue = '' + this.feetValue + "\' " + this.inchValue + "\'\'"
            }
            else {

            }
            return this.updateUser(update)
                .then((response) => {
                    this.componentSaved = true
                    this.sectionValue = newValue
                    return
                })
        },
        handleCancel() {
            this.selected = this.selected ? false : true
            this.drop = this.drop ? false : true
        },
        handleFeet({ feet }) {
            this.feetValue = feet
        },
        handleInch({ inch }) {
            this.inchValue = inch
        },
        handleCM({ cm }) {
            this.cmValue = cm
        },
        updateDay(value) {
            this.dayValue = value.day
        },
        updateMonth(value) {
            this.monthValue = value.month
        },
        updateYear(value) {
            this.yearValue = value.year
        },
        handleParams(value) {
            Object.keys(value).forEach((item) => {
                return this[handlerMap[item]](value)
            })
        }
    },
    computed: {
        ...mapGetters('user', [
            'getUser'
        ]),
        getImage() {
            return this.section.image
        },
        getComponent() {
            return componentMap[this.type]
        },
        getMessage() {
            return this.status ? this.section.on : this.section.off
        },
        getSwitchStatus() {
            // Checks if first time set up for sms in profile section
            if (this.section.id == 'sms' && ! this.getUser.sms)
                return false
            else if (this.getUser[notificationMap[this.section.id]])
                return false
            else
                return true
        },
        checkType() {
            // Check if section is notification type
            if (this.section && notificationMap[this.section.id]) {
            }
            // Check if section is gender, height, etc
            else if (this.type && this.getUser[this.type]) {
                var value = this.getUser[this.type]
                if (this.type == 'gender') {
                    this.sectionValue = genderStringMap[value]
                }
                else if (this.type == 'height') {
                    this.sectionValue = Math.floor(value.value / 12) + "\' " + (value.value % 12) + "\'\'"
                }
                else if (this.type == 'birthdate') {
                    this.sectionValue = value
                }
                else
                    this.sectionValue = this.getUser[this.type]
            }
        },
        savedText() {
            return 'Your '+ savedMap[this.type] + ' has been updated.'
        },
        getInvalidText() {
            return 'Your ' + savedMap[this.type] + ' value is invalid. Please try again.'
        }
    },
    components: {
        Arrow,
        SwitchItem,
        Date,
        HeightSection,
        GenderSection,
        MainButton,
        ...Icons
    }
}
</script>
<style lang="scss" scoped>
// @import "../../../app/font/ProximaNova/font.css";
// $font-family: 'Proxima Nova';
.topic-section-item {
    border-bottom: 1px solid #E5E3E3;
    padding: 20px 0px 20px 0px;
    margin-right: 40px;
    .topic-image-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        .section-image {
            width: 25px;
            margin-left: -2.5px;
        }
        .section-title {
            margin-left: 5px;
            font-size: 14pt;
            //font-family: $font-family;
        }
        .section-title.settings {
            margin-left: 0px;
        }
        .arrow {
            margin-left: auto;
        }
        .section-value {
            margin-left: auto;
        }
        .arrow.selected {
            transform: rotate(90deg);
        }
    }
    .message {
        color: #6D6D6D;
        font-size: 10pt;
        margin-right: 100px;
        margin-top: 5px;
    }

}
.last {
    border-bottom: 0px solid #E5E3E3;
}
.topic-section-drop {
    margin-right: 40px;
    .component-saved {
        font-size: 11pt;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
.invalid-text {
    margin-left: 40px;
    margin-bottom: 10px;
    color: red;
    font-size: 10pt;
}
.save-cancel {
    display: flex;
    margin-right: 40px;
    margin-bottom: 20px;
    margin-top: 20px;
}
</style>