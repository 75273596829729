<template>
	<header v-bind:class="['header', { landing }]">
		<router-link v-if="authenticated" :to="appointment ? back() : '/'" class="nav">
			<NavIcon :color="landing ? 'white' : 'black'"></NavIcon>
		</router-link>
		<HeaderIcon v-if="landing" class="icon"></HeaderIcon>
		<span v-bind:class="['title', { 'title-left': ! authenticated }]">{{ title }}</span>
	</header>
</template>

<script>
import NavIcon from './icon/Back'
import HeaderIcon from './icon/HeaderIcon'
import AppointmentView from '../viewer/mixin/appointment-view'
import formatDate from '@/module/util/format-date'
import { mapState } from 'vuex'

export default {

	props: {
		landing: {
			type: Boolean
		},
		index: {
			type: Number
		},
		date: {
			type: Number
		},
		previous: {
			type: Object
		},
		appointment: {
			type: Object
		}
	},
	mixins: [ AppointmentView ],
	data() {
        return {
        }
    },
	mounted() {
	},
	methods: {
		// getPaths() {
		// 	var split = this.$route.path.split('/')
		// 	this.path = split[split.length -1]
		// 	this.topicPath = split[split.length -2]
		// },
		back() {
			var split = this.$route.path.split('/')
			this.path = split[split.length -1]
			this.topicPath = split[split.length -2]

			if (this.landing) {
				return '/'
			}
			if (this.path == 'none' || this.topicPath == 'topic') {
				return { name: 'appointment-select' }
			}
			if (this.path == 'select') {
				return { name: 'appointment-viewer' }
			}
			if (this.topicPath == this.appointment.id) {
				return '/appointment'
			}
			return '/'
		}
	},
	computed: {
		...mapState('auth', [
			'authenticated'
		]),
		title() {
			var split = this.$route.path.split('/')
			this.path = split[split.length -1]
			this.topicPath = split[split.length -2]

			if (this.appointment && this.path == this.appointment.id)
				return 'Appointment - ' + formatDate(this.appointment.time)
			
			if (this.topicPath == 'topic' || this.path == 'select') {
				if (this.$route.name == 'appointment_review')
					return 'Summary'
				else 
					return 'Appointment - ' + formatDate(this.appointment.time)
			}
			else
				return 'APPOINTMENTS'
		},
		setSplit() {
			return this.$route.path.split('/')
		}
	},
	components: {
		NavIcon,
		HeaderIcon
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
