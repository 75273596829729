<template>
    <router-link
        :to="route"
        v-bind:class="['previous-button', { first }]">
            PREVIOUS
    </router-link>
</template>
<script>
import { mapActions } from 'vuex'

export default {
	name: 'button-previous',
	props: {
		buttonName: {
			type: String
        },
        route: {
            type: Object
        },
        first: {
            type: Boolean
        }
    },
    mounted(){
    }
}
</script>
<style lang="scss" scoped>
.previous-button.first {
    display: none;
}
.previous-button {
    margin-left: 10%;
    margin-right: 2%;
    border: 2px solid #2D3E4F;
    background-color: white;
    color: #2D3E4F;
    text-decoration: none;
    text-align: center;
    width: 35%;
    height: 10%;
    padding: 2%;
    font-size: 16px;
}
@media only screen and (min-width: 600px) {

}
</style>
