<template>
<svg width="41" height="46" viewBox="0 0 41 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.1403 45.3547H5.13185H0V0C11.3833 0 17.2567 4.71224 18.2153 5.55417C18.3079 5.63555 18.3561 5.75171 18.3561 5.87503V26.2024L40.2444 39.8826L36.1403 45.3547Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
