<template>
    <div class="filler"></div>
</template>
<script>

export default {
	props: {
	},
	mounted() {
	}
}
</script>
<style lang="scss" scoped>
.filler {
    background: #EEEEEE;
    height: 20px;
    width: auto;
}
</style>