function detectBrowser(){
    const userAgent = navigator.userAgent;

    // Check for mobile browsers
    const isMobile = /Mobi|Android/i.test(userAgent);

    if (/Chrome/i.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
        if (isMobile) {
            return 'chrome-mobile';
        } else {
            return 'chrome';
        }
    } else if (/Firefox/i.test(userAgent)) {
        return 'firefox';
    } else if (/Safari/i.test(userAgent) && /Apple Computer/.test(navigator.vendor)) {
        if (isMobile) {
            return 'safari-mobile';
        } else {
            return 'safari';
        }
    } else if (/Edg/i.test(userAgent)) {
        return 'edge';
    } else {
        return 'other';
    }
}

export default {
    redirect: null,
    download: false,
    currentBrowser: detectBrowser(),
    uploadProgress: {}
}
