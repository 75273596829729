<template>
    
    <svg width="43" height="85" viewBox="0 0 43 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M34.0975 33.9254C30.4063 44.2714 24.396 53.8315 20.6154 61.983C12.8222 78.7861 7.96762 85.4127 4.40242 84.5681C4.20873 84.5222 4.00912 84.4657 3.81698 84.4137C-3.28278 82.4929 4.22969 64.0326 12.6154 30.983C13.4839 28.5488 13.6084 19.2397 14.9498 13.483C15.847 9.63226 17.6908 5.61054 19.2121 2.70183C20.3788 0.471385 23.3291 0.0838511 25.3597 1.57133C27.1574 2.88816 29.38 4.28285 31.5117 4.99341C34.2906 5.91971 37.1772 6.73873 39.4756 7.35115C41.8506 7.98397 43.1845 10.5528 42.2728 12.8353C39.9427 18.6697 35.8949 28.8876 34.0975 33.9254Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_forearm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>