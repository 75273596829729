<template>
<svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.1559 19.0008C32.1559 29.494 24.9917 38 16.1567 38C7.3185 38 0.155884 29.494 0.155884 19.0008C0.155884 8.50604 7.3185 0 16.1567 0C24.9917 0 32.1559 8.50604 32.1559 19.0008Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_knee_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
