<template>
    
    <svg width="79" height="47" viewBox="0 0 79 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M78.9732 25.3236C78.9732 20.9615 79.5406 8.22983 75.5019 4.82947C68.4564 -1.1024 55.5181 0.100067 41.6564 0.100067C27.7947 0.100067 11.819 -1.1024 4.77356 4.82947C0.734868 8.22983 0.000488281 20.9615 0.000488281 25.3236C0.000488281 37.2951 17.6791 47 39.4868 47C61.2945 47 78.9732 37.2951 78.9732 25.3236Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>