export default function({ dispatch, commit }, phone) {
    // POST /auth/verify/phone
    return dispatch('post', {
        url: '/auth/verify/phone',
        data: phone
    }, {
        root: true
    })
    .then((response) => {
        return response
    })
    .catch((error) => {
        throw error
    })
}