<template>
<svg width="52" height="65" viewBox="0 0 52 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.00345 0C3.00345 0 15 13.5388 52 16.5V64.297C50.532 64.2583 48 62.4551 48 62.4551C48 62.4551 36.384 46.0234 26.7158 36.0245C17.2479 26.2327 0.723415 12.7682 0.723415 12.7682C0.723415 12.7682 0.21771 8.55101 0.723415 5.92809C1.19299 3.49255 3.00345 0 3.00345 0Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_pelvic',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
