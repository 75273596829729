import body_f from "./body_f"
import body_b from "./body_b"

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'
import right_upper_thigh_f from "./right_upper_thigh_f"
import left_upper_thigh_f from "./left_upper_thigh_f"
import right_lower_thigh_f from "./right_lower_thigh_f"
import left_lower_thigh_f from "./left_lower_thigh_f"
import right_ankle_f from "./right_ankle_f"
import left_ankle_f from "./left_ankle_f"
import right_toes_f from "./right_toes_f"
import left_toes_f from "./left_toes_f"
import left_knee_f from "./left_knee_f"
import left_foot_f from "./left_foot_f"
import left_lower_leg_f from "./left_lower_leg_f"
import pelvic from "./pelvic_f"
import right_foot_f from "./right_foot_f"
import right_knee_f from "./right_knee_f"
import right_lower_leg_f from "./right_lower_leg_f"
import left_buttock from "./left_buttock_b"
import right_buttock from "./right_buttock_b"
import left_lower_thigh_b from "./left_lower_thigh_b"
import right_lower_thigh_b from "./right_lower_thigh_b"
import left_upper_thigh_b from "./left_upper_thigh_b"
import right_upper_thigh_b from "./right_upper_thigh_b"
import left_knee_b from "./left_knee_b"
import right_knee_b from "./right_knee_b"
import left_heel from "./left_heel"
import right_heel from "./right_heel"
import left_lower_calf from "./left_lower_calf_b"
import right_lower_calf from "./right_lower_calf_b"
import left_upper_calf from "./left_upper_calf_b"
import right_upper_calf from "./right_upper_calf_b"

export default {
    body_b,
    body_f,
    body_f_selected,
    body_b_selected,
    right_upper_thigh_f,
    left_upper_thigh_f,
    right_lower_thigh_f,
    left_lower_thigh_f,
    right_ankle_f,
    left_ankle_f,
    right_toes_f,
    left_toes_f,
    left_knee_f,
    left_foot_f,
    left_lower_leg_f,
    pelvic,
    right_foot_f,
    right_knee_f,
    right_lower_leg_f,
    left_buttock,
    right_buttock,
    left_upper_thigh_b,
    right_upper_thigh_b,
    left_lower_thigh_b,
    right_lower_thigh_b,
    left_knee_b,
    right_knee_b,
    left_upper_calf,
    right_upper_calf,
    left_lower_calf,
    right_lower_calf,
    left_heel,
    right_heel
}