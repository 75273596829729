<template>
<svg width="19" height="47" viewBox="0 0 19 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.6713 40.8168C4.64617 45.4591 6.0247 46.6012 7.51186 46.796C8.99902 46.9909 10.634 41.7635 12.02 38.2563C13.7556 33.864 14.3438 31.199 14.8849 26.5073C15.3432 22.5335 15.3619 20.531 15.9733 17.0699C16.5548 13.7785 17.8833 9.23697 17.8833 9.23697C17.8833 9.23697 19.1947 2.56164 16.3164 1.00322C13.9808 -0.261275 10.7385 1.36633 9.63002 2.24534C7.41378 4.00282 4.55571 6.38955 2.69567 10.0019C0.721041 13.8369 -0.196282 16.5489 0.44198 20.8149C0.907862 23.9288 2.89542 25.1616 3.61365 28.2271C4.68636 32.8057 2.70486 36.2147 3.6713 40.8168Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_side_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
