<template>
    
    <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.52308 43.9766C-0.347857 47.456 -3.8273 59.2856 8.00239 56.5021C19.8321 53.7187 35.972 50.7937 46.2748 37.7139C54.8917 26.7743 58.8003 7.09627 56.7127 3.61669C55.5164 1.62275 54.5486 1.00001 53.9403 0.831729C53.4917 0.707636 53.1358 1.05442 52.9415 1.47732C51.3759 4.88393 43.7244 20.9366 35.141 28.6677C25.2078 37.6145 9.39402 40.4973 4.52308 43.9766Z" :fill="color"/>
</svg>

</template>


<script>
export default {
	name: 'jaw',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
