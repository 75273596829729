<template>
<svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M39.004 22.5505C38.5459 31.2314 30.574 37.8676 21.1983 37.3729C11.8225 36.8781 4.59334 29.4397 5.05144 20.7588C5.50954 12.0778 13.4814 5.44165 22.8572 5.93641C32.2329 6.43117 39.4621 13.8695 39.004 22.5505ZM13.9613 19.9692C13.9545 20.5214 14.3967 20.9746 14.949 20.9813C15.5012 20.9881 15.9544 20.5459 15.9611 19.9936C15.9678 19.4414 15.5256 18.9882 14.9734 18.9815C14.4211 18.9747 13.968 19.417 13.9613 19.9692ZM22.9607 20.079C22.4085 20.0722 21.9662 19.6191 21.973 19.0669C21.9797 18.5146 22.4329 18.0724 22.9851 18.0791C23.5373 18.0859 23.9796 18.539 23.9728 19.0913C23.9661 19.6435 23.5129 20.0857 22.9607 20.079ZM20.8875 26.0542C20.8808 26.6064 21.323 27.0596 21.8752 27.0663C22.4275 27.073 22.8806 26.6308 22.8874 26.0786C22.8941 25.5263 22.4519 25.0732 21.8997 25.0664C21.3474 25.0597 20.8943 25.5019 20.8875 26.0542ZM28.8867 26.1522C28.3345 26.1455 27.8923 25.6923 27.899 25.1401C27.9057 24.5879 28.3589 24.1456 28.9111 24.1524C29.4634 24.1591 29.9056 24.6123 29.8989 25.1645C29.8921 25.7168 29.439 26.159 28.8867 26.1522Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_knee_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
