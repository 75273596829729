<template>
<svg width="52" height="128" viewBox="0 0 52 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M34.4106 76.3936C30.7193 86.7395 24.7091 96.2997 20.9285 104.451C12.9241 121.71 8.01975 128.232 4.42847 126.951C-6.07153 125.451 4.42847 106.951 12.9285 73.4512C13.797 71.0169 13.9214 61.7079 15.2628 55.9512C16.9408 48.7502 22.1705 41.0221 22.9285 37.9512C26.4924 23.5122 25.6205 24.6184 27.4285 14.9506C28.4285 6.95066 30.4284 0.167402 43.4285 0.167402C53.9285 0.167567 51.6786 16.4933 50.9285 23.4512C49.6976 34.8679 41.56 56.3553 34.4106 76.3936Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
