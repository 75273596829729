<template>
<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.283262 21.5945C0.0262415 14.6514 -0.102269 11.1798 2.68272 7.69734C5.4677 4.21484 9.2334 3.51142 16.7648 2.10459C22.7088 0.994261 29.091 0.0327725 33.4978 0.000838667C36.8014 -0.0231006 40.6143 0.467864 44.3286 1.16527C51.7549 2.55966 55.468 3.25686 58.2329 6.58924C60.9978 9.92162 60.9978 14.1932 60.9978 22.7364V40.0163C60.9978 44.3429 60.9978 46.5062 59.8505 48.9379C58.7032 51.3696 57.4576 52.3957 54.9666 54.4479C51.037 57.6851 45.6117 61.0311 39.9978 61.001C29.5319 60.9448 21.1685 59.8119 10.998 51.5C2.94489 44.9185 0.670328 32.0505 0.283262 21.5945Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_buttock',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
