<template>
    
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M26.0117 13.5C26.0117 20.0104 21.6707 26.7573 18.6736 27.4676C18.5664 27.493 18.4642 27.5005 18.3541 27.4984C14.8229 27.4305 2.90655 25.2012 1.01184 22.5C-0.429167 20.4456 5.01172 16.5 3.01281 12C-0.487192 2 9.71273 0 15.5117 0C25.0117 0 26.0117 3.31213 26.0117 13.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_palm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>