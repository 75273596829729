<template>
    
    <svg width="80" height="96" viewBox="0 0 80 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M79.1201 48.1406C79.1201 74.3742 61.4354 95.6406 39.6201 95.6406C17.8049 95.6406 0.120117 74.3742 0.120117 48.1406C0.120117 21.9071 7.12012 0.640625 39.6201 0.640625C72.1201 0.640625 79.1201 21.9071 79.1201 48.1406Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>