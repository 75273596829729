<template>
    
    <svg width="27" height="59" viewBox="0 0 27 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M7.13826 1.79149L15.2235 0.586697C17.4011 0.262217 19.4315 1.75843 19.7665 3.93441L23.1101 25.6539C23.1428 25.8663 23.1913 26.0718 23.2563 26.2766C23.7307 27.7709 25.809 34.4553 26.5006 39C26.9723 42.0998 26.6831 46.4372 26.3855 49.3514C26.1965 51.2012 24.6898 52.6022 22.8477 52.8551C20.6164 53.1614 17.6509 53.6853 15.502 54.5C13.7413 55.1675 11.8033 56.3053 10.0354 57.4957C7.39739 59.272 3.5667 57.8206 3.11453 54.6726L0.080864 33.5524C0.028223 33.1859 0.0267181 32.8139 0.076395 32.447L3.76396 5.21113C4.00275 3.44752 5.378 2.05378 7.13826 1.79149Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_upper_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>