<template>
    
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M18.5621 24.1101C11.6719 25.6757 1.21137 25.0675 0.234208 13.8291C-0.765812 2.3278 6.73418 0.828197 12.7342 0.829162C18.7342 0.830126 23.5677 3.27068 24.4894 7.32752C25.4112 11.3844 25.4524 22.5446 18.5621 24.1101Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_heel_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>