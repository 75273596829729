<template>
	<header v-bind:class="['header', { landing }]">
		<!-- Redirect back to the landing screen or to the home page -->
		<router-link v-if="authenticated" :to="taskId ? `/checklist/${checklists.id}` : (checklists ? '/checklist' : '/')" class="nav">
			<NavIcon :color="landing ? 'white' : 'black'"></NavIcon>
		</router-link>
		<router-link v-else-if="taskId" :to="`/checklist/${checklists.id}?${urlToken}`" class="nav">
			<NavIcon :color="landing ? 'white' : 'black'"></NavIcon>
		</router-link>
		<HeaderIcon v-if="landing" class="icon"></HeaderIcon>
		<span v-bind:class="['title', { 'title-left': !authenticated && !taskId }]">
			{{ complete ? '' : content ? content.title : 'Checklists' }}
		</span>
	</header>

</template>

<script>
import { mapState } from 'vuex'

import NavIcon from './icon/Back'
import HeaderIcon from './icon/HeaderIcon'

export default {

	props: {
		landing: {
			type: Boolean
		},
		checklists: {
			type: Object
		},
		content: {
			type: Object
		},
		complete: {
			type: Boolean
		},
		taskId: {
			type: String
		},
		urlToken: {
			type: String
		}
	},

	computed: {
		...mapState('auth', [
			'authenticated'
		])
	},

	mounted() {
	},

	components: {
		NavIcon,
		HeaderIcon
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
