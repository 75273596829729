<template>
	<div v-if="question">
		<div class="question-title">
			<!-- Display the question text -->
			<h3>{{ number }}. {{ question.question }}</h3>
			<p v-if="question.hasOwnProperty('subheader') && question.subheader" style="font-size: 14px; padding: 5px 0px; margin: 5px 0px;">{{ question.subheader}}</p>
		</div>
		<!-- Show the underlying text -->
		<div :is="getComponent(question.type)"
			 :question="question"
			 :saved="saved"
			 v-on:selected="selected"
			 v-scroll="handleScroll"
			 class="question-content">
		</div>
	</div>
</template>

<script>
import Questions from './question/components'
import questionComponent from './question/map'
import { mapActions } from 'vuex'
import Vue from 'vue'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
	props: {
		questions: {
			type: Array,
			required: true
		},
		number: {
			type: String,
			required: true
		},
		saved: {
			type: Array
		},
		result: {
			type: Array
		},
		id: {
			type: String
		}
	},
	computed: {
		index() {
			return parseInt(this.number) - 1
		},
		question() {
			return this.questions[this.index]
		},
		length() {
			return this.questions.length
		}
	},
	methods: {
		...mapActions('user', [
			'addEvent'
		]),
		selected(selected) {
			if (selected === null || selected === undefined) return
			this.selectedQuestion(selected)
			this.$emit('selected', selected)
		},
		selectedQuestion(choice) {
			var answer, value, choiceText = null
			let reqObj = {}
			// Is a selection question in object format with defined values for each index
			if (this.question.select && Object.keys(this.question.select).length > 0) {
				answer = {
					[choice.toString()]: this.question.select[choice.toString()]
				}
			} else if (this.question.option && this.question.type === 'select') { // Is a selection question in array format
				answer = choice
			} else if (this.question.type === "radio") {
				answer = null
				value = choice[0]
			} else if (this.question.type === "slider" || this.question.type === "scroller") {
				if (this.question.type === 'scroller' && choice.length === 0) answer = null
				else if (choice.length) {

					// let split = choice[0].includes(' - ') ? choice[0].split(' - ') : choice[0].split(' ')
					let split = []
					if (choice[0].includes(' - '))
						split = [choice[0].substr(0,choice[0].indexOf(' - ')), choice[0].substr(choice[0].indexOf(' - ')+3)]
					else
						split = [choice[0].substr(0,choice[0].indexOf(' ')), choice[0].substr(choice[0].indexOf(' ')+1)]


					choice = this.question.type === 'slider' ? parseInt(split[0]) - 1 : parseInt(split[0])

					if (split.length > 2 && split[2].length > 0)
						choiceText = split[1] + ' ' + split[2]
					else if (split.length > 1 && split[1].length > 0)
						choiceText = split[1]
					else
						choiceText = null

					answer = {
						[choice.toString()]: choiceText
					}
				}

			} else if (this.question.type === 'human_map') {
				answer = choice
			} else { // Is a question requiring selections that have no select options defined
				choice = choice - 1
				answer = {
					[choice.toString()]: null
				}
			}

			reqObj.answer = answer
			reqObj.value = value

			Vue.set(this.result, this.index, {
				id: this.question.id,
				...reqObj
			})

			console.log('RESULT', JSON.parse(JSON.stringify(this.result)))

			localStorage.setItem(this.id + '_result', JSON.stringify(this.result))
		},
		getComponent(id) {
			return questionComponent[id]
		},
		home() {

		},
		patchSymptom() {
			return this.addEvent({
				id: this.id,
				body: {
					completed: Date.now(),
					question: this.result
				}
			}).then().catch((error) => {
				this.$message({
					showClose: true,
					message: 'Unable to send response. Error: ' + error.code,
					type: 'error',
					duration: '0'
				})
			})
		},
		handleScroll(evt, el) {
			if (window.scrollY > 50) {
				el.setAttribute(
					'style',
					'opacity: 1; transform: translate3d(0, -10px, 0)'
				)
			}
			return window.scrollY > 100
		}
	},

	components: {
		...Questions
	}
}
</script>

<style lang="scss" scoped>
@import "./style/style.scss";
.question-content {
	text-align: center;
}
.question-title {
	height: auto;
	min-height: 30px;
	h3 {
		text-align: left;
		margin: 10px 0px;
	}
}
</style>
