<template>
	<div class="empty">
		<div class="text">
			<NoneIcon class="icon"></NoneIcon>
			<p>Currently you do not have any questionnaires</p>
		</div>
		<router-link to="/">
			Go Back
		</router-link>
	</div>
</template>

<script>
import NoneIcon from './icon/None'

export default {
	components: {
		NoneIcon
	}
}
</script>

<style lang="scss" scoped>
.empty {
	color: #3f3f3f;
	text-align: center;
	min-height: calc(100% - 70px);
	position: relative;

	.text {
		position: absolute;
		padding: 0 10px;
		left: 0;
		width: 100%;
		top: 50%;
		margin-top: -150px;
	}
	p {
		line-height: 21px;
		font-size: 18px;
	}
	.icon {
		height: 160px;
	}
	a {
		position: absolute;
		text-align: center;
		bottom: 15px;
		left: 15px;
		right: 15px;
		padding: 15px;
		color: white;
		font-weight: 700;
		font-size: 18px;
		text-decoration: none;
		background-color: #833e93;
	}
}
</style>
