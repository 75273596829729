<template>
	<div class="block">
		<div v-for="child in children"
			:is="blockElement(child)"
			:text="child">
		</div>
	</div>
</template>
<script>
import Markdown from '.'
import MarkdownHeading from './Heading'
import MarkdownText from './Text'
import MarkdownImage from './Image'
import MarkdownVideo from './Video'

export default {
	name: 'markdown-block',
	extends: Markdown,

	methods: {
		blockElement(section) {
			section = section.trim()
			if (section.startsWith('#'))
				return 'MarkdownHeading'
			else if (section.startsWith('[![]('))
				return 'MarkdownVideo'
			else if (section.startsWith('![]('))
				return 'MarkdownImage'
			else
				return 'MarkdownText'
		}
	},

	computed: {
		children() {
			return this.text.split(/\n+/)
		}
	},

	components: {
		MarkdownHeading,
		MarkdownText,
		MarkdownImage,
		MarkdownVideo
	}
}
</script>
<style lang="scss" scoped>
@import "../style/markdown.scss";
</style>
