<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.57542 15.7533C1.05769 16.9817 0.503729 24.7948 4.32742 23.6126C8.15111 22.4305 14.4998 20.6687 17.571 16.2523C20.1397 12.5586 21.464 8.47563 21.846 6.95061C22.0776 6.02637 21.6515 3.68733 21.2799 1.98782C21.1047 1.18633 20.0911 0.964534 19.5761 1.60323C17.7573 3.85913 14.3619 8.001 12.2751 10.1176C9.22498 13.211 4.09316 14.525 2.57542 15.7533Z" :fill="color"/>
<circle r="0.994312" transform="matrix(-0.997091 0.0762226 0.0762226 0.997091 16.6206 10.3416)" fill="#EEEEEE"/>
<circle r="0.994312" transform="matrix(-0.997091 0.0762226 0.0762226 0.997091 12.9581 14.6107)" fill="#EEEEEE"/>
<circle r="0.994312" transform="matrix(-0.997091 0.0762226 0.0762226 0.997091 9.14394 16.8963)" fill="#EEEEEE"/>
</svg>
</template>
<script>
export default {
	name: 'left_jaw',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
