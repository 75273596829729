<template>
	<header>
		<div>
			<img v-if="$route.query.account === 'doctorplan'" src="@/image/logo.svg">
			<img v-else-if="$route.query.account === 'livechair'" src="@/image/livechair.png">
			<img v-else-if="isAccountGerd($route.query.account)" src="@/image/gerdhelp.png">
		</div>
		<aside>
			<a :href="getiosLink">
				<img src="@/image/download/ios.svg">
				<span class="long">GET iPHONE APP</span>
				<span class="short">iOS</span>
			</a>
			<a :href="getAndroidLink">
				<img src="@/image/download/android.svg">
				<span class="long">GET ANDROID APP</span>
				<span class="short">ANDROID</span>
			</a>
		</aside>
	</header>
</template>
<script>
import isAccountGerd from '../util/is-account-gerd'
export default {
	name: 'article-header',

	methods: {
		isAccountGerd
	},

	computed: {
		getiosLink() {
			if (this.$route.query.account === 'livechair')
				return 'https://apps.apple.com/in/app/live-chair-health/id1537755288'
			else if (this.isAccountGerd(this.$route.query.account))
				return 'https://apps.apple.com/in/app/gerdhelp/id1568842316'
			else return 'https://itunes.apple.com/us/app/doctorbox/id1437144807'
		},
		getAndroidLink() {
			if (this.$route.query.account === 'livechair')
				return 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient'
			else if (this.isAccountGerd(this.$route.query.account))
				return 'https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US'
			else return 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'
		}
	}
}
</script>
<style lang="scss" scoped>
@import "./style/header.scss";
</style>
