<template>
    
    <svg width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M60.9815 7.53915C59.1056 2.46065 53.8789 -0.44035 48.525 0.362746L12.3521 5.78867C7.76941 6.47608 3.99474 9.75929 3.06818 14.2997C2.02168 19.4278 0.896866 26.6322 0.881691 33.9604C0.864105 42.4566 2.32629 53.7636 3.47818 61.338C4.23272 66.2997 8.14697 70.1068 13.1628 70.2748C19.6786 70.4931 29.4592 70.1691 40.4616 67.6016C51.5845 65.006 58.5392 62.2019 62.4971 60.244C65.3851 58.8153 67.0239 55.9046 67.2267 52.6889C67.6194 46.4588 68.0233 35.7164 66.8448 28.0237C65.8609 21.6021 63.1968 13.5368 60.9815 7.53915Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>