import body_f from "./body_f"
import body_b from "./body_b"

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import right_ankle from "./right_ankle"
import left_ankle from "./left_ankle"
import right_lateral_foot_f from "./right_lateral_foot_f"
import right_dorsal_foot_f from "./right_dorsal_foot_f"
import right_medial_foot_f from "./right_medial_foot_f"
import left_lateral_foot_f from "./left_lateral_foot_f"
import left_dorsal_foot_f from "./left_dorsal_foot_f"
import left_medial_foot_f from "./left_medial_foot_f"

import left_big_toe from "./left_big_toe"
import right_big_toe from "./left_big_toe"
import right_long_toe from "./right_long_toe"
import left_long_toe from "./left_long_toe"
import right_middle_toe from "./right_middle_toe"
import left_middle_toe from "./left_middle_toe"
import right_ring_toe from "./right_ring_toe"
import left_ring_toe from "./left_ring_toe"
import right_little_toe from "./right_little_toe"
import left_little_toe from "./left_little_toe"
import left_plantar_foot_b from "./left_plantar_foot_b"
import right_plantar_foot_b from "./right_plantar_foot_b"
import left_heel from "./left_heel"
import right_heel from "./right_heel"

export default {
    body_b,
    body_f,
    body_f_selected,
    body_b_selected,
    right_ankle,
    left_ankle,
    right_lateral_foot_f,
    right_dorsal_foot_f,
    right_medial_foot_f,
    left_lateral_foot_f,
    left_dorsal_foot_f,
    left_medial_foot_f,
    left_big_toe,
    right_big_toe,
    right_long_toe,
    left_long_toe,
    right_middle_toe,
    left_middle_toe,
    right_ring_toe,
    left_ring_toe,
    right_little_toe,
    left_little_toe,
    left_plantar_foot_b,
    right_plantar_foot_b,
    left_heel,
    right_heel
}