import body_f from './body_f'
import body_b from './body_b'
import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import left_outer_hip_f from './left_outer_hip_f'
import left_inner_hip_f from './left_inner_hip_f'
import right_outer_hip_f from './right_outer_hip_f'
import right_inner_hip_f from './right_inner_hip_f'
import right_outer_thigh_f from './right_outer_thigh_f'
import left_outer_thigh_f from './left_outer_thigh_f'
import right_thigh_f from './right_thigh_f'
import left_thigh_f from './left_thigh_f'
import groin from './groin'
import left_outer_hip_b from './left_outer_hip_b'
import right_outer_hip_b from './right_outer_hip_b'
import left_buttock_inner_hip from './left_buttock_inner_hip'
import right_buttock_inner_hip from './right_buttock_inner_hip'
import lower_back from './lower_back'
import left_thigh_b from './left_thigh_b'
import right_thigh_b from './right_thigh_b'


export default {
    body_f,
    body_b,
    body_f_selected,
    body_b_selected,


    left_outer_hip_f,
    left_inner_hip_f,
    right_outer_hip_f,
    right_inner_hip_f,
    right_outer_thigh_f,
    left_outer_thigh_f,
    right_thigh_f,
    left_thigh_f,
    groin,

    left_outer_hip_b,
    right_outer_hip_b,
    left_buttock_inner_hip,
    right_buttock_inner_hip,
    lower_back,
    left_thigh_b,
    right_thigh_b
}