<template>
    
    <svg width="32" height="67" viewBox="0 0 32 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.5 33.5C0.5 52.0015 -3.5 67 15.5 67C32 67 32 52.0015 32 33.5C32 14.9985 31 0 15.5 0C0 0 0.5 14.9985 0.5 33.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>