<template>
<svg width="17" height="43" viewBox="0 0 17 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.52319 20.1466C1.2762 31.6774 -1.97681 41.1079 7.26967 42.1079C14.0232 42.8382 13.7762 34.1772 15.0232 22.6465C16.2702 11.1157 20.0476 1.24486 11.7854 0.351345C3.52319 -0.542169 3.77017 8.61587 2.52319 20.1466Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_under_arm',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
