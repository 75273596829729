<template>
	<div class="progress-bar">
		<div class="background">
			<div class="bar"
				v-if="index != length+1"
				v-bind:style="{
					width: (100.0 * index / length) + '%'
				}">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'symptom-progress-bar',

	props: {
		index: {
			type: Number
		},
		length: {
			type: Number
		}
	}
}
</script>

<style scoped lang="scss">
.progress-bar {

	.background {
		background: #eeeeee;
		border-radius: 25px;

		.bar {
			height: 5px;
			background: #e28816;
			border-radius: 25px;
			transition: width 0.3s ease-in-out;
		}
	}
}

</style>
