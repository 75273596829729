<template>
    
    <svg width="53" height="60" viewBox="0 0 53 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.523048 58.0211V9.25273C0.523048 7.64564 1.48648 6.20181 2.99276 5.64157C7.93793 3.80227 19.2902 -0.0471364 27.5679 0.000436924C35.0808 0.0436146 45.1672 3.32625 50.4271 5.24379C52.4168 5.96915 53.3937 8.15843 52.7082 10.1622C50.8993 15.4495 47.5913 25.5439 46.0376 32.982C44.8428 38.7016 44.7981 46.3133 45.0007 52.0139C45.1178 55.3092 41.3811 57.6328 38.4403 56.1415C33.5443 53.6588 27.0707 50.9018 22.2909 50.792C15.0806 50.6264 5.85479 56.3527 2.16006 58.8685C1.47805 59.3329 0.523048 58.8462 0.523048 58.0211Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>