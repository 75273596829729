import EmailInput from './Email'
import NameInput from './Name'
import CodeInput from './Code'
import PasswordInput from './Password'
import PhoneInput from './Phone'

export default {
    EmailInput,
    NameInput,
    CodeInput,
    PasswordInput,
    PhoneInput
}
