<template>
<svg width="31" height="57" viewBox="0 0 31 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.71814 26.8367C2.83662 39.6835 21.1964 61.5 22.6964 54.5C23.4252 51.0991 23.9682 47.8641 24.4951 44.7252C25.0528 41.4034 25.5923 38.1893 26.3147 35.0003L30.4131 5.28153C30.8914 1.81416 21.1084 2.05912 17.0397 3.43725C8.56829 6.30663 4.46539 15.9469 3.71814 26.8367ZM18.5772 14.2482C18.5017 14.7953 17.997 15.1777 17.4499 15.1022C16.9028 15.0268 16.5205 14.5221 16.5959 13.975C16.6714 13.4279 17.1761 13.0455 17.7232 13.121C18.2703 13.1964 18.6526 13.7011 18.5772 14.2482ZM19.3289 23.4372C19.876 23.5126 20.3807 23.1303 20.4561 22.5832C20.5316 22.0361 20.1492 21.5314 19.6021 21.4559C19.055 21.3805 18.5503 21.7629 18.4749 22.31C18.3994 22.8571 18.7818 23.3617 19.3289 23.4372ZM14.5124 21.7634C14.437 22.3105 13.9323 22.6928 13.3852 22.6174C12.8381 22.5419 12.4557 22.0372 12.5312 21.4901C12.6066 20.943 13.1113 20.5607 13.6584 20.6361C14.2055 20.7116 14.5879 21.2163 14.5124 21.7634ZM12.2922 30.5427C12.8393 30.6181 13.344 30.2358 13.4195 29.6887C13.4949 29.1416 13.1126 28.6369 12.5655 28.5614C12.0183 28.486 11.5137 28.8683 11.4382 29.4154C11.3628 29.9625 11.7451 30.4672 12.2922 30.5427ZM19.3633 30.5085C19.2878 31.0556 18.7831 31.4379 18.236 31.3625C17.6889 31.287 17.3066 30.7824 17.382 30.2353C17.4575 29.6881 17.9621 29.3058 18.5092 29.3812C19.0563 29.4567 19.4387 29.9614 19.3633 30.5085ZM14.3079 37.8869C14.855 37.9624 15.3596 37.58 15.4351 37.0329C15.5105 36.4858 15.1282 35.9811 14.5811 35.9057C14.034 35.8302 13.5293 36.2126 13.4538 36.7597C13.3784 37.3068 13.7607 37.8115 14.3079 37.8869Z" :fill="color"/>
</g>
</svg>
</template>
<script>
export default {
	name: 'left_axilla',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
