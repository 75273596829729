<template>
	<div v-bind:class="['input', { 'error': error }]">
		<input
			type="password"
			ref="input"
			:value="value"
			:autofocus="true"
			v-on:change="change"
			v-on:keydown.enter="enter"
			v-on:focus="reset">
		<label>Password</label>
		<label v-if="error" class="error">
			{{ error }}
		</label>
		<label v-if="secondary" class="forgot"
			@click="altNext">
			{{ secondary }}
		</label>
	</div>
</template>

<script>
import AuthInput from '.'

export default {
	name: 'auth-password-input',
	extends: AuthInput,

	data() {
		return {
			type: 'password'
		}
	},

	mounted() {
		setTimeout(() => {
			this.$refs.input.focus()
		}, 100)
	},

	methods: {
		reset() {
			this.$emit('reset')
		}
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
