<template>
<svg width="53" height="146" viewBox="0 0 53 146" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.0747618 13.8342C0.0720627 7.25271 4.91756 1.72234 11.4619 1.02447C15.5979 0.583418 20.1373 0.211594 23.7136 0.207528C28.7691 0.20178 37.8206 3.20003 44.5943 5.74658C49.9047 7.74303 53.1442 13.0159 52.8374 18.6809L46.6903 132.165C46.3002 139.367 40.3472 145.006 33.1353 145.006H25.3005C18.3997 145.006 12.5977 139.849 11.7885 132.996C9.70389 115.342 5.74126 82.6749 3.04354 65.9233C0.513778 50.2147 0.0803244 27.3983 0.0747618 13.8342Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_front_thigh',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
