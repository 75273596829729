<template>
    
    <svg width="66" height="126" viewBox="0 0 66 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.00153681 66.6649C0.0940586 85.0978 4.46403 111.333 6.37666 121.955C6.81908 124.412 9.41035 125.836 11.7668 125.011C17.3514 123.057 27.0174 120.095 34.3023 120.095C41.2671 120.095 50.408 122.802 56.0782 124.748C58.6709 125.638 61.4769 123.813 61.6002 121.074L62.9963 90.0818L65.4132 42.7016C65.4686 41.6152 65.0813 40.5602 64.3245 39.7787C61.3451 36.7022 53.0178 28.2184 43 19C32.4989 9.33683 19.6171 3.47309 11.7967 0.29486C9.46479 -0.652846 6.87208 0.810424 6.47951 3.29678C4.58677 15.2846 -0.0971868 46.9965 0.00153681 66.6649Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>