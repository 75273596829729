<template>
<svg width="22" height="47" viewBox="0 0 22 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M20.141 22.3296C21.8351 35.1972 25.1904 45.3676 14.2855 46.8033C3.38048 48.239 3.9891 37.5468 2.29497 24.6791C0.600833 11.8115 -3.25043 1.70644 8.15046 0.205421C19.5521 -1.2957 18.4468 9.46192 20.141 22.3296Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_arm_pit',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
