<template>
    
    <svg width="92" height="78" viewBox="0 0 92 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M54.2623 -0.000753452C63.2292 -0.000751884 72.1461 2.57666 76.9127 4.2168C79.1807 4.99721 80.8444 6.86735 81.4906 9.17721C84.3537 19.4117 92.1361 48.4214 91.4575 59.0434C89.7679 85.49 68.36 76.4069 47.1956 76.5426C31.9392 76.6404 7.89267 64.9925 6.06785 64.0975C5.98604 64.0574 5.91603 64.0244 5.83464 63.9835C4.64413 63.3847 -4.6334 58.2488 2.95751 47.0432C10.9256 35.2808 17.7419 17.4718 20.5659 9.56701C21.4634 7.05477 23.4965 5.10877 26.0846 4.46177C32.5384 2.84839 45.2161 -0.000755034 54.2623 -0.000753452Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>