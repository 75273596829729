<template>
  <div
    class="FormDate"
  >
    <input
      v-if="showMonth"
      ref="month"
      v-model="month"
      class="FormDate__input FormDate__input--month"
      type="number"
      placeholder="mm"
      @input="updateMonth"
      @blur="month = month.padStart(2, 0)">
    <span
      v-if="showYear && (showDay || showMonth)"
      class="FormDate__divider"
    >/</span>
    <input
      v-if="showDay"
      ref="day"
      v-model="day"
      class="FormDate__input FormDate__input--day"
      type="number"
      placeholder="dd"
      @input="updateDay"
      @blur="day = day.padStart(2, 0)">
    <span
      v-if="showDay && showMonth"
      class="FormDate__divider"
    >/</span>
    <input
      v-if="showYear"
      ref="year"
      v-model="year"
      class="FormDate__input FormDate__input--year"
      type="number"
      placeholder="yyyy"
      @input="updateYear"
      @blur="year = year.padStart(4, 0)">
  </div>
</template>

<script>
export default {
  name: 'FormDate',
  props: {
    showDay: {
      type: Boolean,
      default: true,
    },
    showMonth: {
      type: Boolean,
      default: true,
    },
    showYear: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Number, String],
      //required: true,
    }
  },
  data() {
    return {
      day: `${this.value ? new Date(this.value).getDate() : ''}`,
      month: `${this.value ? new Date(this.value).getMonth() + 1 : ''}`,
      year: `${this.value ? new Date(this.value).getFullYear(): ''}`,
    };
  },
  watch: {
    year(current, prev) {
      if (current > 9999) this.year = prev;
    },
  },
  methods: {
    updateDay() {
      this.$emit('invalidText', true)
      this.$emit('params', { 
        day: this.day
      })
      if (!this.day.length || parseInt(this.day, 10) < 4) {
        return;
      }
      this.$refs.year.select();
    },
    updateMonth() {
      this.$emit('invalidText', true)
      this.$emit('params', { 
        month: this.month
      })
      if (!this.month.length || parseInt(this.month, 10) < 2) return;
      this.$refs.day.select();
    },
    updateYear() {
      this.$emit('invalidText', true)
      this.$emit('params', { 
        year: this.year
      })
    },
  },
};
</script>

<style lang="scss">
.FormDate {
  $spacing: 0.75em;

margin: 20px 40px 10px 40px;
text-align: center;

  overflow: hidden;
  border: 1px solid #888;
  border-radius: 0.25em;
  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    padding: $spacing;
    padding-right: $spacing / 2;
    padding-left: $spacing / 2;
    border: none;
    text-align: center;
    font-size: 12pt;
    -moz-appearance: textfield; // 1

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $spacing;
    }

    &:last-child {
      padding-right: $spacing;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 3em;
    }

    &--year {
      width: 4em;
    }
  }

&__divider {
        padding-top: $spacing;
        padding-bottom: $spacing;
        pointer-events: none;
    }
}
</style>