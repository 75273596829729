import scalp from "./scalp"
import right_temple from "./right_temple"
import left_temple from "./left_temple"
import around_right_eye from "./around_right_eye"
import around_left_eye from "./around_left_eye"
import forehead from "./forehead"
import body_f from "./body_f"
import left_cheekbone from "./left_cheekbone"
import right_cheekbone from "./right_cheekbone"
import body_b from "./body_b"
import head_b from "./head_b"
import neck_b from "./neck_b"
import side from "./side"
import jaw_s from "./jaw_s"
import above_ear_s from "./above_ear_s"
import behind_ear_s from "./behind_ear_s"
import under_ear_s from "./under_ear_s"
import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'
import body_s_selected from './body_s_selected'


export default {
    scalp,
    right_cheekbone,
    right_temple,
    around_left_eye,
    around_right_eye,
    left_cheekbone,
    left_temple,
    body_f,
    body_f_selected,
    body_b_selected,
    body_s_selected,
    forehead,
    body_b,
    head_b,
    neck_b,
    side,
    jaw_s,
    above_ear_s,
    behind_ear_s,
    under_ear_s
}