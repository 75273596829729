<template>
    
    <svg width="25" height="71" viewBox="0 0 25 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.56248 63.5007L4.58533 69.2968C4.8211 70.6328 6.2911 71.3487 7.50649 70.7459C12.8187 68.1111 16.7104 66.8638 22.5178 65.8404C23.6328 65.6439 24.3839 64.5782 24.1688 63.4667L12.5944 3.66558C12.5732 3.55584 12.5438 3.44939 12.4948 3.34892C12.1454 2.63246 10.3538 -0.322318 5.06248 1.00069C-0.0507838 2.27917 -0.080207 5.37301 0.022781 6.25957C0.0416511 6.42201 0.0624838 6.58338 0.0624838 6.74691V29.5007L3.56248 63.5007Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'pinky_finger_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>