<template>
<svg width="61" height="94" viewBox="0 0 61 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.726 20.0761C36.5496 12.7729 29.6939 0.467773 29.6939 0.467773C29.6939 0.467773 24.1101 10.9159 17.8377 14.148C11.6904 17.3156 0.0534248 15.516 0.0534248 15.516C0.0534248 15.516 3.52749 27.3845 5.75351 33.5283C7.97953 39.6721 8.35011 42.3742 10.9976 48.3485C17.7012 63.476 27.2254 69.4081 35.166 84.373C37.0324 87.8906 39.726 93.4932 39.726 93.4932C39.726 93.4932 44.9552 89.4744 48.8462 88.0211C53.0698 86.4435 60.2463 86.1971 60.2463 86.1971C60.2463 86.1971 56.4547 79.0561 54.3182 74.3409C45.2616 54.3524 48.4786 40.1996 39.726 20.0761Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_forearm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
