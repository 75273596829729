<template>
    
    <svg width="30" height="79" viewBox="0 0 30 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M14.5 30.5C11.5576 41.9311 5.02866 56.7044 0.830506 65.6225C-1.17392 69.8805 0.769079 75.0076 5.13866 76.7555L6.08559 77.1342C6.69291 77.3772 7.32772 77.5447 7.97581 77.6331L10.8967 78.0314C14.9394 78.5826 18.7492 76.0033 19.7387 72.045L27.3209 41.7164C27.4401 41.2398 27.515 40.7533 27.5449 40.263L29.6539 5.67577C29.8415 2.59862 27.3966 0 24.3137 0C21.5769 0 19.2778 2.06313 18.939 4.77885C18.1462 11.1322 16.5621 22.4888 14.5 30.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>