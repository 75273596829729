export default function({ dispatch, commit }, data) {
    commit('startedAuthentication')
    // POST /auth/login
    return dispatch('post', {
        url: '/auth/reset/password',
        data
    }, {
        root: true
    })
    .then((token) => {
        commit('finishedAuthentication')
        commit('receivedToken', token)
        return token
    })
    .catch((error) => {
        commit('finishedAuthentication')
        throw error
    })
}
