<template>
	<svg width="53" height="44" viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.1168 4.45505C4.04632 9.26542 0.564453 20.5188 0.564453 20.5188C0.564453 20.5188 2.45194 24.6329 3.50363 27.315C4.4417 29.7074 4.3717 31.2388 5.70801 33.4934C9.90071 40.5672 16.8838 44.1252 26.2822 43.9966C35.4366 43.8714 42.0378 40.3834 46.1217 33.4934C47.458 31.2388 47.2872 29.6778 48.326 27.315C49.5283 24.5805 52 20.5188 52 20.5188C52 20.5188 49.4148 8.95801 43.1825 4.45505C39.9691 2.13328 37.8526 0.423712 33.6302 0.130195C30.7137 -0.0725371 29.2071 1.28654 26.2822 1.36587C22.7931 1.46051 20.8699 -0.516392 17.4647 0.130195C14.048 0.778962 12.6659 2.43509 10.1168 4.45505Z" :fill="showColor"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 20.5188C0 20.5188 3.58777 9.26542 9.84286 4.45506C10.3272 4.0826 10.7706 3.72252 11.1938 3.37884C13.066 1.85861 14.5428 0.659337 17.4143 0.130195C19.333 -0.22338 20.7951 0.207535 22.314 0.655224C23.5729 1.02623 24.8707 1.40876 26.5 1.36587C27.8923 1.32922 28.9728 1.01943 30.0287 0.716695C31.2583 0.364131 32.4546 0.0211217 34.0714 0.130195C38.1663 0.406443 40.339 1.93702 43.3409 4.05178C43.5286 4.18398 43.7195 4.31846 43.9143 4.45506C50.3361 8.95801 53 20.5188 53 20.5188C53 20.5188 50.4531 24.5805 49.2143 27.315C48.7537 28.3317 48.5239 29.1999 48.3018 30.0388C48.0078 31.1495 47.7274 32.2089 46.9429 33.4934C42.7348 40.3835 35.9328 43.8714 26.5 43.9966C16.8157 44.1252 9.62022 40.5672 5.3 33.4934C4.46154 32.1205 4.16038 31.0158 3.83482 29.8215C3.62574 29.0545 3.40659 28.2506 3.02857 27.315C1.9449 24.6329 0 20.5188 0 20.5188ZM1 20.6525C1 20.6525 6.94468 13.7201 12.0786 9.71203C12.4761 9.40169 12.84 9.10166 13.1874 8.8153C14.724 7.54862 15.9361 6.54937 18.2929 6.10848C19.8676 5.81388 21.0676 6.17292 22.3144 6.54594C23.3475 6.85507 24.4128 7.1738 25.75 7.13807C26.8928 7.10753 27.7795 6.84941 28.6462 6.59716C29.6554 6.3034 30.6373 6.0176 31.9643 6.10848C35.3252 6.33866 37.1084 7.61396 39.5723 9.37601C39.7263 9.48616 39.883 9.59821 40.0429 9.71203C45.3136 13.464 52 20.6525 52 20.6525C46.6477 23.401 43.745 26.9479 41.0286 31.4632C37.5748 37.2041 33.492 38.8911 25.75 38.9954C17.8016 39.1025 13.3958 37.3572 9.85 31.4632C7.50226 27.5607 4.97059 23.6138 1 20.6525Z" :fill="backColor"/>
	</svg>
</template>

<script>
export default {
	name: 'lips_mouth',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	},
	computed: {
		showColor() {
			if(this.color === '#833E93')
				return '#f6e1ff'
			return this.color
		},
		backColor() {
			if(this.color === '#CCCCCC')
				return '#D9D9D9'
			return this.color
		}
	}
}
</script>