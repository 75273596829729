<template>
	<div v-bind:class="['input', { 'error': error }]">
		<input
			ref="input"
			type="email"
			:value="value"
			autofocus="true"
			@input="change"
			placeholder="Enter your Email"
			v-on:keydown.13="enter">

		<label>Email</label>
		<label
			v-if="error"
			class="error">
			{{ error }}
		</label>
		<label
			v-if="secondary"
			class="forgot"
			@click="altNext">
			{{ secondary }}
		</label>
	</div>
</template>

<script>
import AuthInput from '.'

export default {
	name: 'auth-email-input',
	extends: AuthInput
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
