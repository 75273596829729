<template>
<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.07322 4.15248L16.9611 0.331317C16.9611 0.331317 23.6661 1.16551 27.7622 2.96685C31.8699 4.7733 36.6794 9.02719 36.6794 9.02719L36.5909 23.0527L30.9543 39.9018L0.185443 26.7193L2.61217 18.1631L5.07322 4.15248Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_hand_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
