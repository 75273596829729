<template>
    
    <svg width="69" height="66" viewBox="0 0 69 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M68.825 30.2916C68.825 46.0273 60.2308 67.6122 31.0107 64.8031C4.36885 62.2419 0.0717773 46.0273 0.0717773 30.2916C0.0717773 14.5559 8.23622 0.595703 33.1592 0.595703C58.0823 0.595703 68.825 14.5559 68.825 30.2916Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>