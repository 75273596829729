<template>
<svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.5 43.0629L42 18.0629C42 18.0629 31.0928 4.13987 21 1.06291C13.1554 -1.32864 0 1.06291 0 1.06291L3 45.5629C3 45.5629 13.6066 40.0544 21 39.5629C28.3367 39.0751 39.5 43.0629 39.5 43.0629Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_knee_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
