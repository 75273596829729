<template>
<svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.532984 13.2655L18.682 0.0400878L16.4019 11.4531C14.0161 13.516 8.98253 17.9865 4.54568 19.2053C0.108841 20.4241 -0.00901892 15.5998 0.532984 13.2655Z" :fill="color"/>
<path d="M8.99203 51.5011L14.6708 28.9393L16.056 23.8802L23.242 27.0217L24.154 27.4204L31.9061 30.8095L32.8182 31.2082L39.8779 34.2946L41.0263 34.7966L46.0424 36.9895L39.8779 55.2099C39.8779 55.2099 38.0969 56.1527 37.212 55.5752C36.418 55.057 36.6633 53.234 36.6633 53.234L41.0263 34.7966L39.8779 34.2946L30.2821 61.7654C30.2821 61.7654 28.219 63.8822 26.8813 63.3024C25.6511 62.7692 25.5466 60.1771 25.5466 60.1771L32.8182 31.2082L31.9061 30.8095L20.7866 62.4847C20.7866 62.4847 18.7599 64.5115 17.3857 64.0217C15.8506 63.4746 16.056 60.1172 16.056 60.1172L24.154 27.4204L23.242 27.0217L14.2861 53.8721C14.2861 53.8721 11.8701 56.2011 10.3218 55.4056C8.87426 54.6619 8.99203 51.5011 8.99203 51.5011Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_fingers_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
