<template>
	<div style="width: 276px">
		<svg width="312" height="50" viewBox="0 0 312 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.5337 0.88501C22.967 6.18476 23.4783 12.3679 21.4908 17.5459C20.3248 20.5765 18.4465 23.2622 16.2052 25.6324C15.7233 26.1387 13.818 28.1411 12.9193 27.8676C13.6219 28.5704 14.3268 29.2782 15.0318 29.981C14.7437 29.3458 15.2231 28.1846 15.3898 27.5702C15.8375 25.9273 16.1084 23.9699 16.8452 22.4413C18.38 19.2428 20.4658 16.5838 23.5114 14.7185C24.6283 14.0242 26.6784 13.6642 27.9879 13.7281C30.1948 13.8338 31.0605 15.6265 31.7225 17.4992C33.1497 21.5197 31.7556 25.9912 29.9644 29.6443C26.9077 35.8693 21.6123 40.5804 15.1251 42.9507C10.9011 44.505 3.90019 45.7719 3.11168e-05 42.6877C-0.0109318 42.6975 2.87644 45.5581 6.26408 46.3641C13.8854 48.1594 20.1155 45.9415 25.3186 42.3608C34.9323 35.7452 38.7773 21.4828 29.7435 13.4222C22.0106 6.51648 13.0333 20.8071 12.7784 27.6857C12.7404 28.461 14.2705 29.8557 14.9461 30.0646C15.5358 30.2464 16.5657 29.3728 16.985 29.0666C20.9392 26.2036 23.5139 20.5746 24.4666 15.9482C25.3273 11.7544 24.9644 8.12589 23.3312 4.24794C22.5943 2.50943 21.5337 0.896088 21.5337 0.88501Z" fill="white"/>
			<path d="M180.517 38.6285V9.97803H184.621V38.6285H180.517ZM160.357 38.6285V9.97803H164.461V38.6285H160.357ZM162.047 25.7921V22.3718H182.891V25.7921H162.047Z" fill="white"/>
			<path d="M191.322 38.6285V9.97803H209.913V13.3179H195.426V22.3718H209.108V25.7519H195.426V35.2886H209.913V38.6285H191.322Z" fill="white"/>
			<path d="M215.096 38.6285V9.97803H219.241V35.1679H233.164V38.6285H215.096Z" fill="white"/>
			<path d="M237.299 38.6285V9.97803H249.169C252.201 9.97803 254.548 10.7023 256.211 12.151C257.901 13.5996 258.746 15.5981 258.746 18.1466C258.746 20.6415 257.901 22.6266 256.211 24.1021C254.521 25.5775 252.174 26.3152 249.169 26.3152H241.443V38.6285H237.299ZM241.443 23.0156H248.767C250.752 23.0156 252.241 22.5998 253.234 21.7682C254.226 20.9097 254.722 19.7026 254.722 18.1466C254.722 16.5907 254.226 15.3969 253.234 14.5653C252.241 13.7337 250.752 13.3179 248.767 13.3179H241.443V23.0156Z" fill="white"/>
			<path d="M60.7965 38.951C57.8188 38.951 55.2435 38.3743 53.0705 37.2207C50.9244 36.0404 49.2746 34.3637 48.1211 32.1908C46.9944 29.9911 46.431 27.3487 46.431 24.2636C46.431 21.2323 46.9944 18.6301 48.1211 16.4572C49.2746 14.2574 50.911 12.5808 53.0303 11.4273C55.1496 10.2469 57.6578 9.65674 60.5551 9.65674C62.567 9.65674 64.4449 9.93841 66.1886 10.5018C67.9323 11.0651 69.4077 11.8967 70.6149 12.9966L68.8444 16.7791C67.4762 15.7329 66.1483 14.9818 64.8607 14.5257C63.573 14.0428 62.1646 13.8014 60.6355 13.8014C57.7115 13.8014 55.4849 14.6867 53.9558 16.4572C52.4535 18.2277 51.7024 20.8299 51.7024 24.2636C51.7024 27.7779 52.4804 30.4337 54.0363 32.2311C55.5922 34.0016 57.9127 34.8869 60.9977 34.8869C62.1781 34.8869 63.3316 34.793 64.4583 34.6052C65.6118 34.3906 66.6983 34.0955 67.7177 33.7199L66.5105 35.6514V26.8792H60.3539V23.1369H71.2185V36.8988C70.3332 37.3549 69.3273 37.7304 68.2006 38.0255C67.0739 38.3206 65.8801 38.5486 64.6193 38.7096C63.3584 38.8706 62.0842 38.951 60.7965 38.951Z" fill="white"/>
			<path d="M77.1029 38.6291V9.97865H96.2569V14.0428H82.1731V22.0102H95.4521V26.1147H82.1731V34.5649H96.2569V38.6291H77.1029Z" fill="white"/>
			<path d="M101.034 38.6291V9.97865H113.307C116.553 9.97865 119.021 10.6896 120.711 12.1113C122.401 13.5331 123.247 15.5719 123.247 18.2277C123.247 20.7494 122.375 22.7614 120.631 24.2636C118.887 25.7391 116.459 26.4768 113.348 26.4768L116.567 25.833C117.559 25.833 118.445 26.1281 119.223 26.7183C120.001 27.3084 120.618 28.2205 121.074 29.4545L124.615 38.6291H119.142L115.521 29.0924C115.145 28.1535 114.608 27.523 113.911 27.2011C113.24 26.8792 112.395 26.7183 111.376 26.7183H106.145V38.6291H101.034ZM106.105 22.6943H112.583C114.568 22.6943 116.017 22.3322 116.929 21.6078C117.841 20.8835 118.297 19.7971 118.297 18.3485C118.297 16.873 117.841 15.7865 116.929 15.0891C116.017 14.3916 114.568 14.0428 112.583 14.0428H106.105V22.6943Z" fill="white"/>
			<path d="M129.288 38.6291V9.97865H139.831C142.97 9.97865 145.639 10.542 147.839 11.6687C150.039 12.7954 151.715 14.4318 152.869 16.5779C154.049 18.6972 154.639 21.2591 154.639 24.2636C154.639 27.295 154.049 29.8838 152.869 32.0299C151.715 34.176 150.025 35.8124 147.799 36.9391C145.599 38.0658 142.943 38.6291 139.831 38.6291H129.288ZM134.479 34.404H139.509C142.809 34.404 145.277 33.559 146.913 31.8689C148.576 30.1788 149.408 27.6438 149.408 24.2636C149.408 17.5571 146.108 14.2038 139.509 14.2038H134.479V34.404Z" fill="white"/>
			<path d="M263.197 17.5514V10.6103H260.525V9.65674H267.022V10.6103H264.35V17.5514H263.197Z" fill="white"/>
			<path d="M268.091 17.5514V9.65674H269.022L272.127 16.7198H271.65L274.721 9.65674H275.653V17.5514H274.61V11.2423L274.965 11.3089L272.26 17.5514H271.484L268.734 11.3089L269.111 11.2423L269.122 17.5514H268.091Z" fill="white"/>
		</svg>
	</div>
</template>

<script>
	export default {
		name: 'gerdhelp-logo-icon',
		props: {
			primaryColor: {
				type: String,
				default: '#365475'
			},
			secondaryColor: {
				type: String,
				default: '#ffffff'
			},
		}
	}
</script>

<style scoped lang="scss">
	svg {
		width: 100%;
		height: 100%;
	}
</style>
