<template>
<svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.447676 28.6096V0.495331H20.7524C24.6572 7.5239 27.3905 14.943 28.9524 20.8001C30.1239 28.6096 30.1586 33.3567 29.343 41.1049H13.7238C13.7238 41.1049 12.943 35.6382 9.4287 32.1239C6.30489 29.0001 0.447676 28.6096 0.447676 28.6096Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_upper_outer_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
