export default function({ dispatch, commit }, setPassword) {
    commit('startedAuthentication')

    // POST /auth/login
    return dispatch('post', {
        url: '/auth/verify/password',
        data: setPassword
    }, {
        root: true
    })
    .then((response) => {
        commit('finishedAuthentication')
        return response
    })
    .catch((error) => {
        commit('finishedAuthentication')
        throw error
    })
}
