<template>
    
    <svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M35.3672 32.9212V26.1652C35.3672 25.5975 35.3672 25.3137 35.3253 25.0006C35.2835 24.6875 35.2176 24.4454 35.0859 23.9612C34.3259 21.1687 32.0746 13.7102 28.9237 10.4605C25.0288 6.44344 10.5657 2.11817 7.23033 1.15988C7.17859 1.14501 7.15271 1.13758 7.11111 1.12644C6.75336 1.03064 6.33932 0.972343 5.96903 0.965625C5.92597 0.964844 5.88948 0.964844 5.81649 0.964844C4.93523 0.964844 4.4946 0.964844 4.18229 1.00647C1.19177 1.40511 -0.673553 4.45524 0.334087 7.29896C0.439316 7.59593 0.64006 7.98816 1.04154 8.77258L1.04158 8.77268L15.7891 37.587C17.3762 40.6881 18.1698 42.2386 19.6168 42.9308C21.0639 43.623 22.7691 43.2677 26.1795 42.5572L27.8075 42.218C31.4284 41.4637 33.2388 41.0865 34.303 39.7778C35.3672 38.469 35.3672 36.6197 35.3672 32.9212Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_shoulder_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>