<template>
	<div class="questionnaire welcome">
		<img class="image" src="./image/expired.png">
        <p class="expired-title">Expired Questionnaire</p>
        <p class="expired-subtitle">
			<span>The questionnaire you are trying to answer has expired.</span> <span v-if="showCTABtn"> Click on the button below for the latest version.</span>
		</p>
		<button v-if="showCTABtn" @click="openLatestQuestionnaire()" class="CTABtn">
			Answer Latest Version
		</button>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'questionnaire-expired',

	props: {
		expiry_ref: {
			type: String,
			required: true
		},
		expiry_handoff: {
			type: String,
			required: true
		},
		query: {
			type: Object,
			default: {}
		}
	},

	data() {
		return {
			showCTABtn: true
		}
	},

	mounted() {
		// After getEvents call the component mounts again
		// while having the green message box
		// if message box is present 
		// remove the bottom CTA button
		setTimeout(() => {
			const messageBoxes = document.getElementsByClassName('el-message--success')
			if (messageBoxes.length > 0) this.showCTABtn = false
		}, 100)
	},

	methods: {
		...mapActions('user', [
			'loadEvent'
		]),
		openLatestQuestionnaire() {
			let url = ''
			if (this.expiry_ref.length && this.expiry_handoff.length) {
				// if next questionnaire is not completed
				this.loadEvent({ id: this.expiry_ref, type: 'questionnaire', handoff_token: this.expiry_handoff })
					.then(res => {
						if (this.getEvent(this.expiry_ref)) {
							url = this.expiry_ref  +
								'?auth=' + this.query.auth +
								'&handoff_token=' + this.expiry_handoff +
								'&account=' + this.query.account
							window.location.href = url
						} else {
							this.loadEvent({ id: this.query.handoff_token.split(':')[0], type: 'questionnaire', handoff_token: this.query.handoff_token })
								.then(res => {
									this.$message({
										showClose: false,
										message: 'You have already answered the latest version of this questionnaire.',
										type: 'success',
										duration: '0'
									})
									this.showCTABtn = false
								})
						}
					})
			} else if (this.expiry_ref.length) {
				// if next questionnaire is completed
				let event = this.getEvent(this.expiry_ref)
				if (!Object.keys(event).length) {
					this.$message({
						showClose: false,
						message: 'You have already answered the latest version of this questionnaire.',
						type: 'success',
						duration: '0'
					})
					this.showCTABtn = false
				} else {
					url = this.expiry_ref  +
						'?auth=' + this.query.auth +
						'&handoff_token=' + this.expiry_ref + ':' + event.handoff_token +
						'&account=' + this.query.account
					window.location.href = url
				}
			}
		}
	},

	computed: {
		...mapGetters('user', [
			'getEvent'
		])
	}
}
</script>


<style lang="scss" scoped>
@import "./style.scss";

.el-message {
	top: 55px !important;
}
.image {
	width: 70px !important;
	height: 65px !important;
}
.expired-title {
	color: #333333;
	font-size: 18px;
	font-weight: 700;
	line-height: 28px;
	margin: 15px 0;
}
.expired-subtitle {
	color: #333333;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}
.CTABtn {
	cursor: pointer;
	position: absolute;
	text-align: center;
	color: white;
	text-decoration: none;
	bottom: 10px;
	padding: 15px;
	width: 90%;
	border: #833e93;
	margin-left: 5%;
	margin-right: 5%;
	// margin-bottom: 44px;
	left: 0;
	background-color: #833e93;
}
</style>
