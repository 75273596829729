<template>
<svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M34.0041 17.5504C33.546 26.2314 25.5741 32.8676 16.1984 32.3728C6.82265 31.878 -0.40653 24.4397 0.051568 15.7587C0.509666 7.0778 8.48157 0.441599 17.8573 0.936363C27.233 1.43113 34.4622 8.8695 34.0041 17.5504ZM10.9614 16.9692C10.9547 17.5214 11.3969 17.9746 11.9491 17.9813C12.5014 17.988 12.9545 17.5458 12.9612 16.9936C12.968 16.4413 12.5258 15.9882 11.9735 15.9814C11.4213 15.9747 10.9681 16.4169 10.9614 16.9692ZM17.9608 14.079C17.4086 14.0722 16.9664 13.6191 16.9731 13.0668C16.9799 12.5146 17.433 12.0724 17.9852 12.0791C18.5375 12.0858 18.9797 12.539 18.973 13.0912C18.9662 13.6435 18.5131 14.0857 17.9608 14.079ZM16.8877 22.0541C16.8809 22.6064 17.3231 23.0595 17.8754 23.0663C18.4276 23.073 18.8808 22.6308 18.8875 22.0785C18.8943 21.5263 18.452 21.0731 17.8998 21.0664C17.3475 21.0597 16.8944 21.5019 16.8877 22.0541ZM23.8869 20.1522C23.3346 20.1455 22.8924 19.6923 22.8991 19.1401C22.9059 18.5878 23.359 18.1456 23.9113 18.1523C24.4635 18.1591 24.9057 18.6122 24.899 19.1645C24.8923 19.7167 24.4391 20.1589 23.8869 20.1522Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_knee_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
