<template>
    
    <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse opacity="0.9" rx="21.1082" ry="18.4697" transform="matrix(-1 0 0 1 22.0974 18.7353)" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>