import body_f from './body_f'
import body_selected from './body_selected'

import neck from './neck'
import left_shoulder from './left_shoulder'
import right_shoulder from './right_shoulder'
import upper_back from './upper_back'
import mid_back from './mid_back'
import right_buttock from './right_buttock'
import left_buttock from './left_buttock'
import lower_back from './lower_back'
import left_side_flank from './left_side_flank'
import right_side_flank from './right_side_flank'



export default {
    body_f,
    body_selected,

    neck,
    left_shoulder,
    right_shoulder,
    upper_back,
    mid_back,
    right_buttock,
    left_buttock,
    lower_back,
    left_side_flank,
    right_side_flank
}