<template>
    
    <svg width="66" height="126" viewBox="0 0 66 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M65.4985 66.6649C65.4059 85.0978 61.036 111.333 59.1233 121.955C58.6809 124.412 56.0897 125.836 53.7332 125.011C48.1486 123.057 38.4826 120.095 31.1977 120.095C24.2329 120.095 15.092 122.802 9.42177 124.748C6.82905 125.638 4.02314 123.813 3.8998 121.074L2.50374 90.0818L0.0867996 42.7016C0.0313797 41.6152 0.418686 40.5602 1.17546 39.7787C4.15495 36.7022 12.4822 28.2184 22.5 19C33.0011 9.33683 45.8829 3.47309 53.7033 0.29486C56.0352 -0.652846 58.6279 0.810424 59.0205 3.29678C60.9132 15.2846 65.5972 46.9965 65.4985 66.6649Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>