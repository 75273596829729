<template>
	<div class="download-phone-enroll-page">
		<img src="./icon/doctorplan.png" class="doctorplan-logo">
		<div class="download-text" :style="isMobile ? '' : 'top: 23%;'">Download the App now!</div>
				<div class="desktop-app-store-icon" v-if="!isMobile">
			<a href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
				<img src="@/image/download/ios-white.png">
			</a>
			<a href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
				<img src="@/image/download/android-white.png">
			</a>
		</div>
		<img src="./icon/letsgetstarted.png" class="lets-get-started-image" :style="!isMobile ? 'margin-top: 20px; width: 550px; max-width: 550px;' : ''">

		<p class="footer" :style="isMobile ? '' : 'width: 600px'">
			Access exercise videos, track your symptoms, answer check-in questionnaires, and prepare for appointments  - all personalized for your treament plan.
		</p>
		<button class="download-button" v-if="isMobile" @click="redirectToAppStore">Download the App</button>
	</div>
</template>
<script>
import isAccountGerd from '../module/util/is-account-gerd'

export default {
	name: 'auth-download-phone-enroll',
	props: {
		account: {
			type: String,
			default: 'doctorplan'
		}
	},
	methods: {
		redirectToAppStore() {
			if (this.isIos) window.open('https://itunes.apple.com/us/app/doctorbox/id1437144807')
			else if(this.isAndroid) window.open('https://play.google.com/store/apps/details?id=com.doctorbox.patient')
		},
		isAccountGerd
	},
	computed: {
		getAccountName() {
			if (this.isAccountGerd(this.account)) return 'GERDHelp'
			else if (this.account === 'livechair') return 'LiveChair'
			else return 'DoctorPlan'
		},
		isIos() {
			return /iPhone|iPad|iPod/i.test(navigator.userAgent)
		},
		isAndroid() {
			return /Android/i.test(navigator.userAgent)
		},
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		},
	}
}
</script>
<style lang="scss" scoped>

.download-phone-enroll-page {
	background-image: linear-gradient(to right, rgb(45, 62, 79), rgb(44, 108, 163));
	display: flex;
	align-items: center;
	// justify-content: center;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
}
.doctorplan-logo {
	width: 200px;
	margin-top: 70px;
}
.download-text {
	width: calc(100vw - 40px);
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
}
.lets-get-started-image {
	width: 98%;
    max-width: 400px;
	height: auto;
	margin-left: 2%;
}
.desktop-app-store-icon {
	margin-top: 20px;

	a {
		opacity: 1;
		img {
			width: 135px;
			height: 50px;
		}
		img:last-child {
			margin-left: 30px;
		}
	}
	a:hover {
		opacity: 1;
	}
}
.footer {
	width: 350px;
    text-align: center;
	color: white;
	font-size: 14px;
}

.download-button {
	position: fixed;
    bottom: 20px;
	width: calc(100vw - 40px);
	height: 44px;
	background: #FFFFFF;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #2D3E4F;
	border: none;
	cursor: pointer;
}

</style>
