<template>
<svg width="43" height="131" viewBox="0 0 43 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.4839 5.41554C42.4839 5.41554 34.3699 23.1707 21.5 23.5C5.25408 23.9173 0.987316 0.855469 0.987316 0.855469C1.87181 20.6091 0.987266 44.6321 0.987305 67.8885C0.987305 88.4088 3.98935 112.681 7.37149 130.361C7.37149 130.361 12.0119 125.345 18.3157 125.345C24.6194 125.345 31.0838 130.361 31.0838 130.361C32.4518 125.345 30.6278 110.753 34.7319 93.4249C38.8361 76.0966 34.7319 30.9519 42.4839 5.41554Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_lower_leg_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
