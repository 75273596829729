<template>
	<div class="checklists landing" style="height: 100%">
		<Header :landing="true"></Header>

		<el-row>
			<el-col :xs="24" :sm="{ span: 14, offset: 5 }" :md="{ span: 10, offset: 7 }">
				<!-- Show all unfinished checklists -->
				<template v-if="unfinishedChecklists.length > 0">
					<Checklists
							v-for="checklists in unfinishedChecklists"
							:key="checklists.id"
							:checklists="checklists"
							:content="checklistsContent(checklists.type)">
					</Checklists>
				</template>

				<!-- If the events are still loading -->
				<Loading v-else-if="loadingEvents"
						 style="height: 100%"
						 color="#c45531"
						 text="Loading checklists...">
				</Loading>

				<!-- Show empty state -->
				<Empty v-else-if="unfinishedChecklists.length === 0"></Empty>

			</el-col>
		</el-row>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import formatDate from '@/module/util/format-date'

import Loading from '@/component/Loading'
import Empty from './Empty'
import Checklists from './Checklists'

import Header from '../header/Header'

export default {
	name: 'checklists-landing',

	mounted() {
        this.loadEvents({ type: 'checklist' })
	},

	data() {
		return {
			sidebar: false
		}
	},

	methods: {
		formatDate,
		...mapActions('user', [
			'loadEvents'
        ])
	},

	computed: {
		...mapState('user', [
			'loadingEvents'
		]),
		...mapGetters('user', [
			'getEventType'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		/**
		 * @func checklists
		 * @desc Gets the event from store
		 * @return Object checklist
		 */
		checklists() {
			return this.getEventType('checklist')
		},
		/**
		 * @func unfinishedChecklists
		 * @desc Calculates unfinished checklists
		 * @return Array
		 */
		unfinishedChecklists() {
			if (this.checklists)
				return this.checklists.filter((c) => (!c.completed && c.time + 86400000 > Date.now()))
		},
		/**
		 * @func checklistsContent
		 * @desc fetches the checklist content either from store
		 * @return Object checklist content
		 */
		checklistsContent() {
			return (type) => {
                let id = type.substring(type.indexOf(':') + 1)
				return this.getObject('checklist', id)
			}
        }
	},

	components: {
		Header,
		Empty,
		Loading,
		Checklists
	}
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
@import "./style.scss";
</style>
