<template>
    
    <svg width="53" height="60" viewBox="0 0 53 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M52.4301 58.0211V9.25273C52.4301 7.64564 51.4666 6.20181 49.9604 5.64157C45.0152 3.80227 33.663 -0.0471364 25.3852 0.000436924C17.8723 0.0436146 7.78595 3.32625 2.52603 5.24379C0.53633 5.96915 -0.440571 8.15843 0.244953 10.1622C2.05381 15.4495 5.36178 25.5439 6.91557 32.982C8.11037 38.7016 8.15501 46.3133 7.9524 52.0139C7.83529 55.3092 11.572 57.6328 14.5128 56.1415C19.4088 53.6588 25.8825 50.9018 30.6623 50.792C37.8725 50.6264 47.0983 56.3527 50.7931 58.8685C51.4751 59.3329 52.4301 58.8462 52.4301 58.0211Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>