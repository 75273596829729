<template>
	<div class="symptom">
		<template v-if="symptom">
			<Header :title="symptom.title"></Header>
			<main v-if="!complete">
				<DateAndTime bgColor="#fff1df"></DateAndTime>
				<ProgressBar
						v-bind:index="index"
						style="margin-top: 15px;"
						:length="symptom.question.length - 1">
				</ProgressBar>
				<router-view
						:key="index"
						ref="question"
						class="question"
						v-bind:index="index"
						v-bind:saved="savedSelected"
						v-bind:result="savedResult()"
						:id="id"
						:style="'height: ' + this.questionHeight + 'px; overflow-y: scroll; padding-bottom: 25px'"
						:questions="symptom.question"
						v-bind:state="state"
						v-on:selected="selected">
				</router-view>
				<!-- Navigate to the next/previous question -->
				<nav>
					<router-link v-if="index > 0"
								 :to="'/symptom/' + id + '/question/' + (index) + '?' + urlToken" class="previous">
						<span>Previous</span>
					</router-link>
					<router-link v-if="(index + 1) === symptom.question.length"
								 :to="'/symptom/' + id + '/question/' + (index + 2) + '?' + urlToken" class="submit">
						<span>Record and Continue</span>
					</router-link>
					<router-link v-else-if="index < symptom.question.length - 1"
								 :to="'/symptom/' + id + '/question/' + (index + 2) + '?' + urlToken" class="next" :style="index > 0 ? 'width: 61%' : ''">
						<span>Next</span>
					</router-link>
				</nav>
			</main>
		</template>

		<template v-else>
			<Loading color="#e28816" text="Loading symptom..."></Loading>
		</template>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

import ThankYou from './Thankyou'
import Question from './Question'
import Header from './header/Header'
import ProgressBar from './ProgressBar'
import DateAndTime from './DateAndTime'
import Loading from '@/component/Loading'


export default {
	name: 'symptom-viewer',

	props: {
		id: {
			// symptom event id
			type: String,
			required: true
		},
		query: {
			type: Object
		},
		number: {
			type: String,
			default: '1'
		},
	},

	data() {
		return {
			state: {},
			result: [],
			missing: false,
			complete: false,
			hasHandoffToken: undefined,
			questionHeight: '0',
			urlToken: this.$route.fullPath.split('?')[1]
		}
	},

	mounted() {
		window.addEventListener("resize", this.resetHeight)
		// called to initially set the height.
		this.resetHeight()

		// if the user is coming from Landing page
		// we dont use event for this
		// just log the patient symptoms with event creation
		if (!this.query['handoff_token']) {
			this.hasHandoffToken = false
		}
		// if the user is coming by clicking email i.e we have handoff_token
		// we fetch the event and content
		// then log event using the event api
		else if (!this.symptomEvent) {
			this.hasHandoffToken = true
			// if (!this.query['handoff_token']) {
			// 	this.complete = true
			// 	return
			// }
			this.logout()
					.then(() => {
						return this.handoff(this.query)
					})
					.then(() => {
						return this.loadEvent({ id: this.id, type: 'symptom' })
					})
					.then((event) => {
						if (Object.keys(event).length === 0 || event.completed)
							return this.$router.push({ name: 'symptom-complete', params: { completed: true }})
						this.loadObject({
							type: 'symptom',
							id: event.type.split(':')[1]
						})
					}).then(_ => {
						this.$router.push('/symptom/' + this.symptomEvent.id + '/question/' + this.number +'?' + this.urlToken)
					})
		}
	},

	methods: {
		...mapActions('content', [
			'loadObject'
		]),
		...mapActions('user', [
			'loadEvent',
		]),
		...mapActions('auth', [
			'handoff',
			'logout'
		]),
		resetHeight(){
			// reset the body height to that of the inner browser
			// substracting 60px to give space to navigation buttons
			this.questionHeight = window.innerHeight - 180
		},
		selected(selected) {
			var state
			if (Object.keys(this.state) > 0)
				state = this.state
			else {
				// Get the state from local storage, and initialize if not yet initialized
				state = localStorage.getItem(this.id)
				if (state)
					state = JSON.parse(state)
				else
					state = { question: [] }
			}
			// Set the question state
			if (selected.length === 0) state.question[this.index] = null
			else state.question[this.index] = selected

			// Update variable that manages which answers are chosen for each question
			// (handles saving of decisions when previous button is clicked)
			// If screen is hard-refreshed, the localStorage data will be used to populate answers
			Vue.set(this.state, 'question', state.question)

			// save to local storage
			localStorage.setItem(this.id, JSON.stringify(state))

			// console.log('THIS.STATE', JSON.parse(JSON.stringify(this.state)))
			// console.log('STATE', JSON.parse(JSON.stringify(state)))
		},
		savedState() {
			var state
			if (Object.keys(this.state).length > 0) {
				state = this.state
				return state
			}
			else {
				state = localStorage.getItem(this.id)
				if (state)
					return JSON.parse(state)
				else
					return { question: [] }
			}
		},
		savedResult() {
			var result
			if (this.result.length > 0) {
				result = this.result
				return result
			}
			else {
				result = localStorage.getItem(this.id + '_result')
				if (result) {
					return JSON.parse(result)
				}
				else
					return []
			}
		},
	},

	computed: {
		...mapGetters('content', [
			'getObject'
		]),
		...mapGetters('user', [
			'getEvent'
		]),
		symptomId() {
			// if the user enters this component via email link
			if (this.hasHandoffToken) {
				let symptomEvent = this.symptomEvent
				if (symptomEvent) return symptomEvent.type.split(':')[1]
				return undefined
			}
			return this.id
		},
		symptomEvent() {
			return this.getEvent(this.id)
		},
		symptom() {
			return this.getObject('symptom', this.symptomId)
		},
		/**
		 * @func index
		 * @desc Convert the human-readable number in the URL to the index in the question array
		 */
		index() {
			if (this.number) {
				let current = parseInt(this.number) - 1
				if (current >= this.symptom.question.length) {
					this.complete = true
					this.$router.push({
						name: 'symptom-thankyou', params: {
								id: this.id,
								symptom: this.symptom,
								result: this.savedResult(),
								hasHandoffToken: this.hasHandoffToken
						}
					})
				}
				return current
			}
			return 0
		},
		savedSelected() {
			return this.savedState().question[this.index]
		},
	},

	components: {
		Header,
		ThankYou,
		Loading,
		Question,
		DateAndTime,
		ProgressBar
	}
}
</script>

<style lang="scss" scoped>
@import "style/header.scss";
@import "style/viewer.scss";
</style>
