<template>
<svg width="22" height="50" viewBox="0 0 22 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 0V47C22 47 9.63931 50 0 50V1.5C9.58576 1.5 22 0 22 0Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_lower_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
