<template>
    
    <svg width="115" height="138" viewBox="0 0 115 138" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M82.5625 0.999627C94.3971 2.11389 111.125 6.49974 112.062 8.49963C113 10.4995 114.5 11.9995 114.5 24.4995C114.5 36.9995 112.062 37.9995 111.062 41.9996C110.07 45.9686 115.732 87.6626 114.591 117.305C114.573 117.759 114.4 118.186 114.102 118.528L98.207 136.76C97.8005 137.227 97.2185 137.48 96.601 137.442C91.5256 137.133 67.4809 135.606 51.5625 133.5C36.4364 131.498 14.2877 127.073 8.17586 125.83C7.23183 125.638 6.57624 124.803 6.58923 123.839C6.61858 121.663 6.65072 117.796 6.5625 115C6.09571 100.206 2.27134 92.2515 1.0625 77.4996C-0.484378 58.6225 1.0625 47.9401 1.0625 28.9996V17.8708C1.0625 17.0428 1.5676 16.306 2.34241 16.0142C6.03081 14.6253 16.9068 10.6007 24.5625 8.49963C34.9395 5.65166 40.9023 4.46694 51.5625 2.99963C63.5806 1.34541 70.4845 -0.13755 82.5625 0.999627Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'palm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>