<template>
    
    <svg width="63" height="135" viewBox="0 0 63 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M39.9146 130.09L45.4228 110.278C45.4742 110.093 45.5318 109.914 45.5983 109.734C46.5216 107.228 53.7041 87.6179 56.5 76.4995C61.582 56.2897 62.6926 24.3083 62.9337 12.2823C62.9916 9.39692 61.2012 6.83622 58.4588 5.93755C52.4354 3.96377 41.6722 0.787661 34 0.499502C24.8309 0.155121 12.6194 2.08254 5.60308 3.38222C2.34283 3.98614 0.0894375 6.89458 0.271342 10.2053C0.837384 20.5074 2.40291 42.532 6 57.4995C9.99241 74.112 12.356 100.845 12.4936 102.426C12.4981 102.478 12.5028 102.526 12.5083 102.577L15.2581 127.988C15.6675 131.771 19.0856 134.603 22.8898 134.703C23.888 134.73 24.9922 134.775 26.2007 134.845C28.802 134.995 31.4703 135.016 33.6615 134.99C36.6107 134.956 39.1246 132.932 39.9146 130.09Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>