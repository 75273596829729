<template>
    
    <svg width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M9.00018 7C5.10588 12.9073 -0.0920103 15.2394 1.00018 15C10.0002 13.027 10.9148 13.2745 19.0002 12.973C23.1147 12.8196 28.6827 12.8971 31.1858 12.9448C31.8471 12.9574 32.3504 12.3357 32.2554 11.6811C32.0915 10.5514 31.9883 8.9473 32.5002 8C33.3635 6.4025 34.6763 4.88019 35.5602 3.94398C36.0179 3.45917 35.9192 2.67195 35.2979 2.43014C32.2702 1.25182 26.8214 0.499138 24.0002 0.5C21.0704 0.500897 15.7125 1.71677 14.6763 1.95848C14.5583 1.986 14.451 2.03341 14.3518 2.10295C13.6144 2.61984 10.3279 4.98595 9.00018 7Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>