import { v4 as uuidv4 } from 'uuid'

const SINGLE_SELECT = ['radio', 'star', 'binary', 'scroller', 'slider']
const TOGGLE_SELECT = ['large_image_select', 'box_select', 'small_image_select', 'select', 'long_text_bubble']

export default {
    props: {
        question: {
            type: Object
        },
        saved: {
            type: Array
        },
        questions: {
            type: Array,
        },
        result: {
            type: Array
        }
    },

    data() {
		return {
			selected: this.saved || []
		}
	},

	methods: {
        convertAnsToObj(choice) {
            let reqObj = {
                id: uuidv4(),
                value: String(choice),
            }

            if (this.question.type === 'star')
                reqObj.description = String(this.question.label[choice])


            return reqObj
        },
        // selects the choice of the questionnaire
        // Maintains selected array
        // Emits the selected array to Viewer.vue
		select(choice) {
            if (typeof choice !== 'object')
                choice = this.convertAnsToObj(choice)

            let found = -1
            this.selected.forEach((selected, index) => {
                if (selected.value == choice.value) 
                    found = index
            })
            if (found === -1) { // not found
                if(this.question.limit_select && this.question.max_selection && this.question.max_selection === this.selected.length && !this.isSingleSelect) {
                    return;
                }
                
                if(this.question.limit_select && this.question.validations && !this.question.max_selection && !this.isSingleSelect) {
                    const max_selection_obj = this.question.validations.find(ele => ele.type === "max_selection");

                    if(this.selected.length === max_selection_obj.value) return;
                }

                if (this.isSingleSelect && this.selected.length > 0) this.selected = []

                if (this.question.hasOwnProperty('options')) {
                    this.question.options.forEach(option => {
                        if (option.value === choice.value) {
                            let choiceOption = {
                                id: option.id,
                                value: String(option.value),
                            }
                            if (this.question.type === 'star' && choice.hasOwnProperty('description'))
                                choiceOption.description = choice.description
                            if(option.questionnaire)
                                choiceOption.questionnaire = option.questionnaire
                            this.selected.push(choiceOption)
                        }
                            
                    })
                } else {
                    this.selected.push(choice)
                }
            } else if (this.isToggleSelect) // found
                this.selected.splice(found, 1)

			this.$emit('selected', this.selected)
        },

        isSelected(choice) {
            if (typeof choice === 'object')
                return this.selected.filter(ans => choice.value === ans.value).length ? true : false
            return this.selected.filter(ans => String(choice) === String(ans.value)).length ? true : false
        },
        selectFor(choice) {
            const choiceOption = {
                id: uuidv4(),
                value: this.question.select[choice],
                active_image: this.question.active_image[choice],
                inactive_image: this.question.inactive_image[choice]
            }

            this.select(choiceOption)
        }
	},

    computed: {
        isSingleSelect() {
            if(this.question.limit_select && this.question.max_selection) {
                return this.question.limit_select && this.question.max_selection === 1
            }

            if(this.question.limit_select && this.question.validations) {
                const max_selection_obj = this.question.validations.find(ele => ele.type === "max_selection");

                return max_selection_obj.value === 1
            }
            if(this.question.single)
                return true
            if(TOGGLE_SELECT.includes(this.question.type))
                return false
         
            return true
        },
        isToggleSelect() {
            return this.question.type.indexOf('long_text_bubble') >= 0 || this.question.type.indexOf('select') >= 0
        },

        max() {
			return this.question.max
		},
        isMobile() {
            // if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
            //     return false
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
    }
}
