<template>
	<div v-if="query.verification_token" :class="{ form: !(verifySetPassword || verifyEmailError) || !isAccountGerd(query.account)}">

		<div v-if="verifySetPassword || verifyEmailError" class="success">
			<template v-if="isAccountGerd(query.account)">
				<img class="gerdhelp-started" src="./icon/gerdhelp-started.svg" />
				<p class="gerd-footer">
					Use {{ getAccountName }} App to stay on top of your GERD progress, read articles on GERD and healthy living, track your symptoms and vitals, and more.
				</p>
			</template>
			<template v-else>
				<div v-if="!verifyEmailError" class="set-password-success">
					<img src="./icon/round-logo.svg" />
					<h3 class="start-title">Lets get started</h3>
				</div>
				<el-row style="margin: 110px 0 35px 0">
					<el-col :span="9" :offset="1" style="text-align: left; color: #333333; margin-top: 7px;">
						<span class="info-text">
							Open the <span class="account-name">{{ getAccountName }} App</span> on your mobile and use your password to <span class="account-name">Log In</span>.
						</span>
					</el-col>
					<el-col :span="13">
						<img v-if="isAccountGerd(query.account)" src="./icon/gerdhelp.png" class="image-class">
						<img v-else-if="query.account === 'livechair'" src="./icon/livechair.png" class="image-class">
						<div v-else class="phone_image_wrapper">
							<img class="ellipse-class" src="./icon/ellipse.svg"/>
							<img src="./icon/phone-info.svg" class="image-class">
						</div>
					</el-col>
				</el-row>
			</template>
			
			<p v-if="!isAccountGerd(query.account)" class="footer">
				Access exercise videos, track your symptoms, answer check-in questionnaires, and prepare for appointments  - all personalized for your treament plan.
			</p>
		</div>

		<template v-else-if="showSetPhone">
			<div>
				<div class="phone-info">
				<div class="tick"><Tick /></div>
				Use your password to Log In to the {{ getAccountName }} app
				</div>
				<h3 class="phone-title">{{ getTitle }}</h3>
				<div class="success">
				<p class="password">
					It will only be used to notify you of messages or action items from
					your doctor.
				</p>
				</div>
				<PhoneInput :value="phone" :change="handlePhoneInputChange" />
				<el-button
				:class="['save-button', 'custom_class']"
				:loading="isLoading"
				@click="setPhone"
				:disabled="!isEnterPhoneIsValid"
				>
				Submit
				</el-button>
			</div>
		</template>


		<template v-else-if="showSetPassword">
			<h3 v-if="!verifySetPassword" class="phone-title">
				{{ getTitle }}
			</h3>
			<div class="success">
				<p class="password">Secure your account by choosing a password you'll remember</p>
			</div>
			<!-- Password input -->
			<div v-bind:class="['input']">
				<input
					type="password"
					ref="input"
					v-model="password">
				<label>Password</label>
				<span v-if="password.length" class="password-show-hide" @click="togglePasswordVisibility">{{ showPassword ? 'HIDE' : 'SHOW' }}</span>
			</div>
			<div :class="['bottom']">6 characters or more</div>

			<el-row class="agree-terms-checkbox" v-if="isAccountGerd(query.account) && (query.consent === 'true')">
				<el-col :span="3">
					<input type="checkbox" v-model="agreedToTerms">
				</el-col>
				<el-col :span="21">
					<label> I agree to the <a href="https://doctorplan.com/terms/" >Terms of Service</a> and <a href="https://doctorplan.com/privacy/index.html" >Privacy Policy</a></label>
				</el-col>
			</el-row>
			
			<!-- Login button -->
			<el-button :class="['save-button', { 'gerd-background': isAccountGerd(query.account)}]"
				:disabled="authenticating || !agreedToTerms || password.length < 6"
				:loading="isLoading"
				@click="setPassword">
				Save New Password
			</el-button>
		</template>



		<div v-else-if="verifySuccess" class="success">
			<div class="thanks">
				<CheckIcon class="check-icon"></CheckIcon>
				<p>
					Thank you.
				</p>
			</div>
		</div>



		<p v-else>
			Confirming your email...
		</p>



		<template v-if="verifyEmailError">
			<Download :account="query.account" :connect="getDownloadStatus"></Download>
		</template>
	</div>
</template>

<script>
import AuthForm from '.'
import validPassword from '../../util/valid-password'
import Tick from "./icon/Tick.vue"

export default {
	name: 'verify-form',
	extends: AuthForm,

	data() {
		return {
			verifySuccess: null,
			verifyEmailError: null,
			verifySetPassword: false,
			showSetPassword: false,
			passwordToken: null,
			agreedToTerms: false,
			isLoading: false,
			showPassword: false,
			phone: "",
			showSetPhone: false,
			isEnterPhoneIsValid: false,
			phone_token: null,
			phoneThroughImport: ""
		}
	},

	mounted() {
		// no terms to agree for doctorplan and livechair
		if (['doctorplan', 'livechair'].includes(this.query.account) || this.query.consent === 'false' || !this.query.hasOwnProperty('consent'))
			this.agreedToTerms = true

		if (this.query.auth && this.query.verification_token) {
			this.verifyEmail(this.query).then((result) => {
				this.verifySuccess = result

				// Enable a flag to set the password
				if (result.set_password)
					this.showSetPassword = true
			})
			.catch((error) => {
				if (error.set_password) {
					//show set password step
					this.showSetPassword = true
					this.passwordToken = error.set_token
				}
				else {
					//show download app step
					this.verifyEmailError = true
					this.$router.push({ path: '/download', query: { account: this.query.account } })
					this.$message('Email already verified.')
				}
			})
		} else {
			this.$router.push({ path: '/download', query: { account: this.query.account } })
			if (!this.query.verification_token) {
				this.$message({
					showClose: true,
					message: 'Verification token missing.',
					type: 'error',
					duration: '0'
				})
			}
			if (!this.query.auth) {
				this.$message({
					showClose: true,
					message: 'Auth token missing.',
					type: 'error',
					duration: '0'
				})
			}
		}
	},

	methods: {
		handlePhoneInputChange(event) {
			const [input, val] = this.getFomattedPhoneNumber(event.target.value);
			if (!input) {
				this.phone = "";
				this.isEnterPhoneIsValid = false;
				return;
			}
			if (input.length === 10) {
				this.isEnterPhoneIsValid = true;
			} else this.isEnterPhoneIsValid = false;
			this.phone = val;
		},
		getFomattedPhoneNumber(mobile) {
			const input = mobile.replace(/\D/g, "").substring(0, 10);
			const areaCode = input.substring(0, 3);
			const middle = input.substring(3, 6);
			const last = input.substring(6, 10);

			let val;
            if (this.authenticating) return
			if (input.length > 6) {
				val = `(${areaCode}) ${middle}-${last}`;
			} else if (input.length > 3) {
				val = `(${areaCode}) ${middle}`;
			} else if (input.length > 0) {
				val = `(${areaCode}`;
			}

			return [input, val];
		},
		setPhone() {
			const phone = this.phone.replace(/\D/g, "").substring(0, 10);

			// This means number has been not modified, so no need to call the api
			// if(phone === this.phoneThroughImport) {
			// 	this.showSetPhone = false;
			// 	this.verifySetPassword = true;
			// 	return;
			// }
			this.isLoading = true;
			return this.addPhone({
				auth: this.query.auth,
				phone_token: this.phone_token,
				verified_phone: "+1" + phone,
			})
			.then(() => {
			this.showSetPhone = false;
			this.verifySetPassword = true;
			this.isLoading = false;
			})
			.catch(() => {
			this.isLoading = false;
			});
		},
		setPassword() {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null
			if (! validPassword(this.password)) {
				this.passwordError = 'Need at least 6 characters'
				return
			}
			this.isLoading = true
            return this.postVerifySetPassword({
				auth: this.query.auth,
				set_token: this.verifySuccess ? this.verifySuccess.set_token : this.passwordToken,
				password: this.password
            })
			.then((result) => {
				this.isLoading = false;
				this.verifySuccess = null;
				this.showSetPassword = false;
				this.phone_token = result.phone_token;
				this.showSetPhone = true;
				if (result.phone) {
					const val = result.phone.substr(-10)
					this.phoneThroughImport = result.phone.substr(-10);
					this.isEnterPhoneIsValid = true
					this.phone = this.getFomattedPhoneNumber(val)[1] ? this.getFomattedPhoneNumber(val)[1] : "";
				}
			})
			.catch((error) => {
				this.isLoading = false
				this.passwordError = error || 'Invalid Password'
			})
		},
	},
	computed: {
		getAccountName() {
			if (this.isAccountGerd(this.query.account)) return 'GERDHelp'
			else if (this.query.account === 'livechair') return 'LiveChair'
			else return 'DoctorPlan'
		},
		getTitle() {
			if (this.showSetPhone) return "Enter your CELL PHONE Number";
			return this.showSetPassword ? 'Set a New Password': 
					(this.verifySetPassword || this.verifyEmailError) ? 'Download App' : 'Confirm Email'
		},
		getDownloadStatus() {
			return (this.verifySetPassword || this.verifyEmailError) ? true : false
		}
	},
	components: {
		Tick
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
.gerdhelp-started {
	position: fixed;
	top: 180px;
	left: calc(50% - 255px);
}

.gerd-footer {
	position: fixed;
	top: 610px;
	left: calc(50% - 275px);
	line-height: 18px;
	font-size: 14px;
	text-align: center;
	color: #ffffff;
	width: 550px;
}

@media screen and (max-width: 540px) {
	.gerdhelp-started {
		width: 100%;
		left: 0;
		top: 100px;
	}
	.gerd-footer {
		width: 100%;
		left: 0;
		top: 500px;
		padding: 0 10px;
	}
}


.input {
	margin-bottom: 20px;
	position: relative;

	label {
		position: absolute;
		left: 10px;
		color: rgba(0, 0, 0, 0.5);
		background: white;
		padding: 0 5px;
		line-height: 0.1em;
		font-size: 1em;
	}

	input {
		width: 100%;
		height: 50px;
		font-size: 15px;
		letter-spacing: .5px;
		border: solid 1px #bfbfbf;
		padding: 0 15px;
		border-radius: 2px;
	}

	input:focus {
		outline: none;
		border-color: #afafaf;
	}

	label.error,
	label.forgot {
		top: unset;
		bottom: 0px;
		font-size: 0.8em;
		line-height: 0.2em;
	}

	label.forgot:hover {
		cursor: pointer;
		color: #4f4f4f;
	}

	label.error {
		color: red;
	}
}

.input.error {
	label {
		color: rgba(255, 0, 0, 0.5);
	}
	input {
		border-color: red;
	}
}

.phone-info {
  height: 28px;
  margin: 10px 0;
  background: #e7ffea;
  margin: 0px -15px 20px -25px;
  line-height: 30px;
  font-size: 14px;
  color: #44b358;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150%;
  padding-left: 25px;
}
.phone-title {
  background: white !important;
  color: #2D3E4F;
  text-align: left;

}
.tick {
  width: 20px;
  height: 20px;
}

.custom_class {
  width: 100%;
  background-color: #2d3e4f;
  color: #fff;
}
.custom_class:hover {
  background-color: #2d3e4f;
  color: #fff;
}

.save-button {
	margin-top: 85px;
}


.start-title{ 
	margin-left: 15px;
}

.account-name {
	font-weight: bold;
}

.info-text {
	color: #2D3E4F;
	line-height: 22px;
	font-size: 18px;
}

.image-class {
	height: 250px;
	width: 250px;
	position: absolute;
	top: -40px;
	left: 0;
}

.phone_image_wrapper {
	position: relative;
}

.ellipse-class {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
}

.footer {
	position: fixed;
	display: flex;
	width: 35%;
	left: 32%;
	top: 78%;
}
</style>
