<template>
	<div class="box-select">
		<!-- Iterate through choices -->
		<div v-for="choice in max" :key="choice"
			v-bind:class="['choice', 'with-box-text',
				{ selected: isSelected(choice - 1) }]"
			@click="select(choice - 1)">
			<p class="select-text">{{ getSelectText(choice) }}</p>
		</div>
	</div>
</template>


<script>
import Question from './index'
import ImageSelect from './mixin/image-select'
export default {
	name: 'box-select',
	extends: Question,
	mixins: [ ImageSelect ],

	mounted() {
		this.$emit('selected', this.saved)
	}

}
</script>

<style lang="scss" scoped>
$choice-size: 115px;
$choice-font-size: 20px;
@import "style.scss";

.choice {
	margin-right: 5px;
	min-height: 100px;
	height: auto;
	width: 110px;//150px;
	display: inline-block;
	justify-content: center;

    .select-text {
        font-size: 16px;
        line-height: 20px;
        align-self: center;
		margin-top: 20px;
		margin-bottom: 20px;
    }
}
	.box-select {
		// display: grid;
		// grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
		// //grid-gap: 5px;
		// max-width: 960px;
		// margin: 0 auto 10px;
		// text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
</style>
