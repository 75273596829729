<template>
    <svg width="74" height="19" viewBox="0 0 74 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M36.0603 18.7479C43.8458 18.7783 52.8017 17.7217 60.7812 16.4339C68.4661 15.1936 72.3085 14.5735 73.3683 12.2804C73.7271 11.5042 73.8756 10.552 73.7702 9.70331C73.459 7.19648 69.8933 5.38778 62.7618 1.77039L62.7618 1.77035C61.3457 1.05206 60.6377 0.692904 59.9202 0.573141C59.6342 0.52541 59.3921 0.50627 59.1022 0.508478C58.3748 0.514018 57.5695 0.77344 55.9589 1.29228C51.1897 2.82865 42.5079 5.28685 36.0603 5.18266C30.2365 5.08854 22.4901 2.84331 18.0006 1.36749C16.3256 0.816871 15.4881 0.541559 14.7293 0.541034C14.4319 0.540829 14.1826 0.562862 13.8898 0.615247C13.143 0.748868 12.4128 1.14206 10.9523 1.92843C4.27866 5.52195 0.941813 7.31871 0.670321 9.79965C0.580012 10.6249 0.732991 11.5586 1.08193 12.3119C2.13093 14.5765 5.75232 15.195 12.9951 16.4319C20.3289 17.6844 28.6087 18.7187 36.0603 18.7479Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'collarbone',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>