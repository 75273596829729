<template>
    
    <svg width="47" height="13" viewBox="0 0 47 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.54196 9.33462L0.881851 4.24038C0.221859 2.21512 1.82382 0.166388 3.94847 0.318516L12.1147 0.903226H20.1881L30.7844 2.25806L40.5919 3.51204C41.108 3.57803 41.5963 3.78358 42.0042 4.10654L46.1553 7.39313C47.8252 8.71526 46.4044 11.3652 44.379 10.706C43.7674 10.507 43.0989 10.6354 42.5411 10.9556C41.9753 11.2804 41.1898 11.6425 40.3716 11.7419C39.6014 11.8355 38.5995 11.6171 37.6917 11.33C36.2111 10.8617 34.5689 11.0534 33.2063 11.7981C32.4303 12.2223 31.5542 12.5908 30.7844 12.6452C29.7217 12.7202 28.4438 12.3621 27.3822 11.9584C26.0203 11.4405 24.4773 11.4935 23.1301 12.0486C22.3476 12.371 21.4631 12.6415 20.6927 12.6452C19.7321 12.6497 18.6788 12.2405 17.7504 11.7389C16.1168 10.8564 14.0798 10.7369 12.4132 11.5554C11.2067 12.148 9.79073 12.6703 8.58257 12.6452C7.25243 12.6175 5.60989 12.0206 4.458 11.5204C3.52633 11.1159 2.85667 10.3003 2.54196 9.33462Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>