<template>
<svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M27.5714 28.124C10.9762 48.0383 19.385 21.4859 15.8571 21.4859C13.1373 21.4859 13.1103 41.7907 4.53337 30.8574C-3.27611 21.4859 -0.680123 0.00974655 15.0626 0.00974655C30.8054 0.00974655 39.2857 14.0669 27.5714 28.124Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_under_arm',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
