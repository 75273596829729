import axios from 'axios'
import baseUrl from './base-url'

export default function({ state, commit }, { url, data, query, cancelToken, progressId }) {
    
    if (query && query !== '' && query !== 'verifyProviderEmail')
        url = url + '?' + Object.keys(query).map((q) => (q + '=' + query[q])).join('&')

    let headers = {}


    // if this is the call for provider verification
    if (data && query === 'verifyProviderEmail' && data.hasOwnProperty('auth'))
        headers['x-auth'] = data.auth

    // if this call is for uploading image
    if (url.includes('http')) headers['Content-Type'] = data['Content-Type']
    else url = baseUrl + url

    let config = {
        headers,
        withCredentials: false
    }
    // If progress upload Id is present 
    // then set the progress of the upload
    if(progressId) {
        config.onUploadProgress = (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            commit('setProgress', { progressId, percentCompleted })
        }
    }
    
    if (cancelToken) config.cancelToken = cancelToken

    return axios.post(url, data || {}, config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            // console.log('post error', error)
            // console.log('error', JSON.stringify(error.response.data, null, 4))
            throw error.response.data
        })
}
