<template>
<svg width="33" height="129" viewBox="0 0 33 129" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.886865 13.26C0.50762 6.94193 4.68596 1.17879 11.0037 0.793649C15.191 0.538379 19.8166 0.800448 23.2965 2.46914C29.2009 5.3005 25.7869 26.9268 25.7869 35.8407C25.7869 49.4156 28.0494 57.3342 30.8774 77.6965C34.4166 103.178 31.437 121.602 31.004 124.048C30.9702 124.239 30.9238 124.418 30.8646 124.603C29.3686 129.281 22.7426 129.145 21.49 124.396C16.324 104.81 8.02344 72.4725 5.42456 57.3344C2.96262 42.9939 1.57228 24.6787 0.886865 13.26Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_outer_thigh_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
