import body_f from './body_f'
import body_b from './body_b'

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import head_map from './head'
import groin from './groin'
import chest from './chest'
import right_foot from './right_foot'
import left_foot from './left_foot'
import right_leg_f from './right_leg_f'
import left_leg_f from './left_leg_f'
import right_arm_f from './right_arm_f'
import left_arm_f from './left_arm_f'
import stomach from './stomach'
import right_hand_f from './right_hand_f'
import left_hand_f from './left_hand_f'
import neck_f from './neck_f'
import right_under_arm from './right_under_arm'
import left_under_arm from './left_under_arm'

import right_leg_b from './right_leg_b'
import left_leg_b from './left_leg_b'
import back from './back'
import neck_b from './neck_b'
import glutes_buttock from './glutes_buttock'
import right_arm_b from './right_arm_b'
import left_arm_b from './left_arm_b'
import right_hand_b from './right_hand_b'
import left_hand_b from './left_hand_b'



export default {
    body_f,
    body_b,
    body_f_selected,
    body_b_selected,

    head_map,
    groin,
    chest,
    right_foot,
    left_foot,
    right_leg_f,
    left_leg_f,
    right_arm_f,
    left_arm_f,
    stomach,
    right_hand_f,
    left_hand_f,
    neck_f,
    right_under_arm,
    left_under_arm,

    right_leg_b,
    left_leg_b,
    back,
    neck_b,
    glutes_buttock,
    right_arm_b,
    left_arm_b,
    right_hand_b,
    left_hand_b
}