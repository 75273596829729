<template>
    
    <svg width="45" height="52" viewBox="0 0 45 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M44.9849 22.0723C44.9849 26.5141 45.3081 39.4783 43.0076 42.9407C38.9944 48.9809 31.3964 52 23.5005 52C15.6046 52 6.73256 48.9809 2.71933 42.9407C0.418806 39.4783 0.000488281 26.5141 0.000488281 22.0723C0.000488281 9.88212 10.0706 0 22.4927 0C34.9148 0 44.9849 9.88212 44.9849 22.0723Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>