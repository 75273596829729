<template>
        <svg width="69" height="66" viewBox="0 0 69 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.211182 30.2916C0.211182 46.0273 8.80533 67.6122 38.0254 64.8031C64.6673 62.2419 68.9644 46.0273 68.9644 30.2916C68.9644 14.5559 60.7999 0.595703 35.8769 0.595703C10.9539 0.595703 0.211182 14.5559 0.211182 30.2916Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>