export default function({ dispatch }, params) {
    // POST /auth/add/phone
    return dispatch('post', {
        url: '/auth/patch/phone',
        data: params
    }, {
        root: true
    })
    .then((response) => {
        return response
    })
    .catch((error) => {
        return error
    })
}