<template>
<svg width="52" height="65" viewBox="0 0 52 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.9966 0C48.9966 0 37 13.5388 0 16.5V64.297C1.468 64.2583 4 62.4551 4 62.4551C4 62.4551 15.616 46.0234 25.2842 36.0245C34.7521 26.2327 51.2766 12.7682 51.2766 12.7682C51.2766 12.7682 51.7823 8.55101 51.2766 5.92809C50.807 3.49255 48.9966 0 48.9966 0Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_pelvic',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
