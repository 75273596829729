<template>
<svg width="38" height="78" viewBox="0 0 38 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M37.8862 11.8233C37.0271 5.67764 33.1437 2.04676 29.9821 1.3759C26.2934 0.593225 18.9087 -1.96003 17.3354 2.86806C15.2276 9.33598 14.6231 17.4748 12.0659 25.7533C10.1235 32.0416 8.18863 37.535 4.5 44.5C3.65196 48.1723 4.33616 52.8884 3.63474 56.1008C2.37726 61.8599 -0.319221 59.6054 1 63.0656C2.5 67 21.6323 77.8974 25 77.5C28.3677 77.1026 30.009 74.5 31 72C33.3419 66.0919 34 62.4347 34 56.1008C34 48.5 28.8295 43.6853 30.509 33.7133C31.8249 25.9004 38.9827 19.6663 37.8862 11.8233Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_foot',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
