<template>
<svg width="49" height="28" viewBox="0 0 49 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.7005 24.1655V0.90918C48.7005 0.90918 34.2757 2.7069 24.9881 2.73321C15.3454 2.76052 0.36377 0.90918 0.36377 0.90918V24.1655C0.36377 24.1655 14.7071 27.7614 24.0761 27.8136C33.7973 27.8677 48.7005 24.1655 48.7005 24.1655Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'lower_abdomen',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
