<template>
    <!-- Document View -->
    <div v-if="question && !showSignaturePage" class="questionnaire-signature">
        <div v-if="resignError && !hasSignature" 
            class="resign-error">
            {{ (question.signature && question.signature.acknowledgement_only) ?  'Acknowledge' : 'Sign' }} again because you made changes 
        </div>
        <div v-html="getTransformedText" class="html-container"></div>
        <br>
        <div v-if="isAcknowledgementOnly">
            <button :class="['acknowledgement-button', { 'active': isAcknowledged }]" @click="handleAcknowledgement()">
                <i v-if="isAcknowledged" class="el-icon-circle-check"></i>
                <span v-else class="empty-circle"></span>
                <span class="i-agree-text">I agree</span>
            </button>
        </div>
        <div v-else class="tap-to-sign-container" :style="!hasSignature ? 'cursor: pointer' : ''" @click="switchToSignature">
            <i v-if="hasSignature" class="el-icon-check success-text"></i>
            <Pen v-else></Pen>
            <span :class="['tap-to-sign', { 'success-text': hasSignature }]">
                {{ hasSignature ? 'Signed - ' + getLastSignatureDate : (isMobile ? 'Tap' : 'Click') + ' to Sign' }}
            </span>
        </div>
    </div>
<!--    Signature View-->
    <div v-else-if="showSignaturePage"
         class="signature-container"
         :style="isMobile ? ('top: -80px; height: ' + signatureContainerHeight + 'px')
         :
         ('top: -28px; min-height: 100%; height: auto ; box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.2)')">
<!--        Header-->
        <div class="signature-header">
            <i class="el-icon-close signature-title" @click="hideSignaturePage"></i>
            <span class="signature-title">Signature</span>
        </div>

        <div style="padding: 10px">

            <template v-if="hasSignature">
                <div class="signature-clear-text"><span>Clear Signature to sign again</span></div>
                <div class="signature-image-container">
                    <img :src="saved[0]" :class="[isMobile ? 'signature-image' : 'desktop-signature-image']" />
                </div>
            </template>


            <template v-if="!hasSignature">

                <template v-if="!isMobile">
                    <div class="signature-desktop-text">You can either use an auto generated signature, or you can sign using your mouse.</div>
                    <!--Auto generated Signature Section-->
                    <el-radio v-model="desktopSignatureType" label="auto"
                              :class="['signature-radio']">
                        Auto Generate Signature
                    </el-radio>
                    <input :class="['name-input', { 'disabled': desktopSignatureType === 'manual' }]"
                           :disabled="desktopSignatureType === 'manual'" maxlength="80"
                           :value="name" @input="nameInput" placeholder="Enter your name here to create a signature">
                    <el-row :class="['auto-signature-container', { 'disabled': desktopSignatureType === 'manual' }]" :key="name.length">
                        <el-col :span="12" :style="'font-size:' + getFontSize"
                                :class="['auto-signature', { 'gray': !name.length }, { 'selected': selectedSignature === 'caveat'}]"
                                @click.native="selectSignature('caveat')">
                            <div v-if="selectedSignature === 'caveat'" class="select-indicator-tick"><i class="el-icon-check"></i></div>
                            <div ref="caveat" class="caveat">{{ name || 'Signature' }}</div>
                        </el-col>
                        <el-col :span="12" :style="'font-size:' + getFontSize"
                                :class="['auto-signature', { 'gray': !name.length }, { 'selected': selectedSignature === 'dancing-script'}]"
                                style="float: right" @click.native="selectSignature('dancing-script')">
                            <div v-if="selectedSignature === 'dancing-script'" class="select-indicator-tick"><i class="el-icon-check"></i></div>
                            <div ref="dancing-script" class="dancing-script">{{ name || 'Signature' }}</div>
                        </el-col>
                    </el-row>
                    <div class="divider"><span>OR</span></div>

                    <!--Manually generated Signature-->
                    <div style="position: relative">
                        <el-radio v-model="desktopSignatureType" label="manual"
                                  :class="['signature-radio']">
                            Sign Using Your Mouse
                        </el-radio>
                        <template v-if="desktopSignatureType === 'manual'">
                            <div class="signature-horizontal-text">
                                <span>Sign inside the box using your mouse</span>
                            </div>
                            <button @click="clear" class="desktop-clear-button" :style="'top: ' + (240) + 'px'">
                                Clear
                            </button>
                        </template>
                    </div>

                    <br><br>

                </template>

                <template v-else-if="isMobile">
                    <div v-if="isMobile" class="signature-vertical-text">Turn your screen and sign like this</div>
                    <div v-if="isMobile" class="signature-vertical-date">Dated - {{ getDate }}</div>
                </template>

<!--            For both desktop and mobile-->
                <VueSignaturePad
                        ref="signaturePad"
                        :height="isMobile ? '65vh' : '250px'" width="auto"
                        :class="[{'signature-pad': !isMobile && desktopSignatureType === 'manual' },
                        { 'disabled': desktopSignatureType === 'auto' && !isMobile }]" />

            </template>
        </div>

        <div class="button-container">
            <div v-if="!hasSignature" class="signature-checkbox-container">
                <el-checkbox v-model="checked" class="signature-checkbox">
                    I agree to signing this digitally
                </el-checkbox>
                <p v-if="hasError" class="error-text">
                    Signature upload failed. Please try again.
                </p>
            </div>
<!--         For mobile-->
            <button @click="clear" class="clear-button" v-if="hasSignature || (!hasSignature && isMobile)"
                    :style="hasSignature ? 'width: 95%' : 'width: 32%'">
                Clear
            </button>
            <el-button v-if="!hasSignature" @click="save"
                       :loading="uploading" :style="!(hasSignature || (!hasSignature && isMobile)) ? 'width: 95.5%' : ''"
                       :class="['save-button', { 'disabled': isSaveDisabled }]">
                {{ hasError ? 'Try again' : 'Save' }}
            </el-button>
        </div>
    </div>
</template>


<script>
    import Vue from 'vue'
    import { mapActions, mapState } from 'vuex'
    import Question from './index'
    import Pen from './icon/pen'
    import { v4 as uuidv4 } from 'uuid'
    import { Checkbox, Radio } from 'element-ui'
    import VueHtml2Canvas from 'vue-html2canvas'
    import getDisplayAnswer from '@/module/util/get-display-answer'
    import formatDate from '@/module/util/format-date'

    Vue.use(Radio)
    Vue.use(Checkbox)
    Vue.use(VueHtml2Canvas)

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    export default {
        name: 'questionnaire-signature',
        extends: Question,
        props: {
            saved: {
                type: Array
            }
        },
        watch: {
            desktopSignatureType() {
                if (this.desktopSignatureType === 'auto') {
                    this.$refs.signaturePad.clearSignature()
                    this.$refs.signaturePad.lockSignaturePad()
                }
                else {
                    this.name = ''
                    this.selectedSignature = ''
                    this.$refs.signaturePad.openSignaturePad()
                }
            },
            showSignaturePage() {
                // if the device is desktop then lock signature pad
                // because the default option chosen for desktop is Auto Generate Signature.
                if (this.showSignaturePage && !this.isMobile && this.desktopSignatureType !== 'manual')
                    setTimeout(() => {
                        if (this.$refs.signaturePad)
                            this.$refs.signaturePad.lockSignaturePad()
                    }, 100)
            },
            name(newValue, oldValue) {
                if (oldValue.length === 0 && newValue.length >= 1)
                    this.selectedSignature = 'caveat'
            }
        },
        data() {
            return {
                showSignaturePage: false,
                checked: false,
                hasError: false,
                uploading: false,
                hasSignature: false,
                name: '',
                output: null,
                selectedSignature: '',
                desktopSignatureType: 'auto', // 'auto' or 'manual',
                signatureContainerHeight: '0',
                resignError: false,
                isAcknowledged: false
            }
        },
        mounted() {
            window.addEventListener("resize", this.resetHeight)
            // called to initially set the height.
            this.resetHeight()
            // if does not have saved answer in localStorage then emit empty value
            // else parse the signature from saved answer and assign it to date variable
            if (!this.saved)
                this.$emit('selected', [])
            else if (this.saved[0] !== '') {
                if(this.saved[0].text !== this.getTransformedText) {
                    this.$emit('selected', [])
                    this.resignError = true
                } else if (this.isAcknowledgementOnly && this.saved[0].hasOwnProperty('acknowledged')) {
                    this.isAcknowledged = this.saved[0].acknowledged
                    this.$emit('selected', [this.saved[0]])
                } else {
                    this.hasSignature = true
                    this.$emit('selected', [this.saved[0]])
                }
                
            }
        },
        methods: {
            ...mapActions('user', [
                'cdn'
            ]),
            resetHeight() {
                // reset the body height to that of the inner browser
                this.signatureContainerHeight = window.innerHeight
            },
            selectSignature(myRef) {
                if (this.name.length && this.desktopSignatureType === 'auto') {
                    this.selectedSignature = myRef
                }
            },
            handleAcknowledgement() {
                this.isAcknowledged = !this.isAcknowledged
                if (this.isAcknowledgementOnly) {
                    let d = new Date()
                    let meta = {}
                    meta.device_time = d.getTime()
                    meta.screen_size = window.innerWidth + 'x' + window.innerHeight

                    let ansObj = {
                        'id': uuidv4(),
                        'acknowledged' : true,
                        'meta': meta,
                        'text': this.getTransformedText
                    }
                    try {
                        if(this.question.role)
                            ansObj.role = this.transformText(this.question.role)
                        if(this.question.name)
                            ansObj.name = this.transformText(this.question.name)
                    } catch (error) {
                        console.error('Problem in transforming text')
                    }
                    
                    if (this.isAcknowledged)
                        this.$emit('selected', [ ansObj ])
                    else
                        this.$emit('selected', [])
                }

            },
            async print(myRef) {
                let el = this.$refs[myRef]
                el.innerHTML = el.innerHTML + '&nbsp;'
                // add option type to get the image version
                // if not provided the promise will return
                // the canvas.
                const options = { type: 'dataURL' }
                this.output = await this.$html2canvas(el, options)
            },
            nameInput($event) {
                this.name = $event.target.value
                if (this.name.length === 0) {
                    this.selectedSignature = ''
                }
            },
            hideSignaturePage() {
                this.checked = false
                this.$refs.signaturePad.clearSignature()
                this.showSignaturePage = false
            },
            clear() {
                if (this.hasSignature) this.hasSignature = false
                else this.$refs.signaturePad.clearSignature()
                this.hasError = false
            },
            save() {
                if (this.isSaveDisabled)
                    return
                this.hasError = false
                if (this.desktopSignatureType === 'auto' && this.selectedSignature.length) {
                    // workaround for printing the signature without color
                    let temp = this.selectedSignature
                    // removes the color
                    this.selectedSignature = ''
                    // then prints and
                    this.print(temp)
                        .then(_ => {
                            // adds the color back
                            this.selectedSignature = temp
                            this.handleChange(this.dataURItoBlob(this.output))
                        })
                } else {
                    const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
                    let srcImg = new Image,
                        canvas = document.createElement("canvas"),
                        ctx = canvas.getContext("2d")
                    srcImg.addEventListener('load', () => {
                        const MAX_WIDTH = 210
                        const MAX_HEIGHT = 210
                        let width = srcImg.width
                        let height = srcImg.height

                        // Add the resizing logic
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                            } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width
                        canvas.height = height
                        ctx.drawImage(srcImg, 0, 0, width, height)
                        var dataurl = canvas.toDataURL("image/png")
                        let newSrcImage = new Image
                        newSrcImage.src = dataurl
                        this.handleChange(this.dataURItoBlob(dataurl))
                    })
                    srcImg.src = data
                }
            },
            dataURItoBlob(dataURI) {
                var binary = atob(dataURI.split(',')[1]);
                var array = [];
                for(var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                return new Blob([new Uint8Array(array)], {type: 'image/png'});
            },
            handleChange(file) {
                this.uploading = true

                this.cdn({ file })
                    .then(response => {
                        this.uploading = false

                        let d = new Date()
                        let meta = {}
                        meta.device_time = d.getTime()
                        meta.screen_size = window.innerWidth + 'x' + window.innerHeight

                        let ansObj = {
                            'id': uuidv4(),
                            'image_url': response.url,
                            'text': this.getTransformedText,
                            'meta': meta
                        }
                        try {
                            if(this.question.role)
                                ansObj.role = this.transformText(this.question.role)
                            if(this.question.name)
                                ansObj.name = this.transformText(this.question.name)
                        } catch (error) {
                            console.error('Problem in transforming text')
                        }
                        

                        this.$emit('selected', [ ansObj ])
                        this.hasSignature = true
                        this.showSignaturePage = false
                    })
                    .catch(err => {
                        this.uploading = false
                        console.log('Error', err)
                        this.hasError = true
                    })
                return false
            },
            /**
             * Transform the `textInput` by replacing the `{{ id }}` with the
             * proper text
             */
             transformText(textInput) {
                const matchRegex = /{{(.[^}}]*)}}/g
                return textInput.match(matchRegex) ? textInput.match(matchRegex)
                    .reduce((matchAcc, translationMatch) => {
                        const replaceWith = translationMatch.slice(2, -2).trim()
                        return matchAcc.replace(translationMatch, this.getQuestionAnswerObject[replaceWith] ? this.getQuestionAnswerObject[replaceWith] : '')
                    }, textInput) :
                    textInput
            },
            switchToSignature() {
                if (this.hasSignature) return
                this.showSignaturePage = true
            },
            /**
             * @param dateObj
             * @desc Converts dateObj to javascript date string
             * @returns javascript date string
             */
        },
        computed: {
            ...mapState('user', {
                event: state => state.event,
                patientName: state => state.name
            }),
            isAcknowledgementOnly() {
                return this.question.hasOwnProperty('signature') && this.question.signature.acknowledgement_only
            },
            providerName() {
                if(this.event && Array.isArray(this.event) && this.event.length > 0 && this.event[0] && this.event[0].doctor && this.event[0].doctor.name)
                    return this.event[0].doctor.name
                return ''
            },
            /**
             * @desc Gets the signature pad height for desktop
             * 590px is the height of all other components on the page
             * @returns {Number}
             */
            getSignaturePadHeight() {
                return this.signatureContainerHeight - 590
            },
            getFontSize() {
                if (this.name.length < 30) return '35px'
                else if (this.name.length < 40) return '30px'
                else if (this.name.length < 50) return '25px'
                return '25px'
            },
            isMobile() {
                // if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
                //     return false
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            },
            isSaveDisabled() {
                // if checkbox not checked then return true
                if (!this.checked) return true

                // for mobile
                if (this.isMobile) {
                    return this.$refs.signaturePad.saveSignature().isEmpty
                }
                // for desktop
                else if (this.desktopSignatureType === 'auto') {
                    return !this.name.length || !this.selectedSignature.length
                } else if (this.desktopSignatureType === 'manual') {
                    return this.$refs.signaturePad.saveSignature().isEmpty
                }
                return false
            },
            getLastSignatureDate() {
                if (!this.saved[0] || !this.saved[0].hasOwnProperty('meta'))
                    return ''

                let date  = new Date(this.saved[0].meta.device_time)

                // converts to AM/PM format
                var hours = date.getHours()
                var minutes = date.getMinutes()
                var ampm = hours >= 12 ? 'PM' : 'AM'
                hours = hours % 12
                hours = hours ? hours : 12
                minutes = minutes < 10 ? '0' + minutes : minutes;
                var strTime = (hours < 10 ? '0' + hours : hours) + ':' + minutes + ' ' + ampm;

                return months[date.getMonth()] + ' ' + date.getDate() + ', ' + strTime
            },
            /**
             * @desc Specifies the format according to question.prompt array
             * @returns {string}
             */
            getDate() {
                let date  = new Date()
                return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
            },
            getCurrentEvent() {
                let curEvent = this.event.filter((event) => event.id === this.$route.params.id)
                return curEvent.length ? curEvent[0] : {}
            },
            /**
             * Get a display answer object for all answered questions
             * @returns Object
             */
            getQuestionAnswerObject() {
                const today = new Date()
                const tomorrow = new Date(today)
                tomorrow.setDate(today.getDate() + 1)
                let acc = {
                    'DateToday': formatDate(today, false, true),
                    'DateTomorrow': formatDate(tomorrow, false, true),
                    'ProviderName': this.providerName,
                    'PatientName': this.patientName
                }

                // if we are getting metadata in the event api then assign it to the accumulator
                if (this.getCurrentEvent.hasOwnProperty('metadata'))
                    acc = this.getCurrentEvent.metadata

                this.result.forEach(({answer, id, version}) => {
                    const question = this.questions.find((question) => question.id === id)
                    if(question.type === 'multi_question' && answer) {
                        answer.forEach((subAns) => {
                            const subQuestion = question.questions.find((subQ) => subQ.id === subAns.id)
                            acc[subAns.id] = getDisplayAnswer(subQuestion, subAns.answer, subAns.version)
                        })
                    }
                    else
                        acc[id] = getDisplayAnswer(question, answer, version)
                })
                return acc
             },
            /**
             * Get a transformed signature text for question by rendering question answers
             */
            getTransformedText() {
                const questionText = (Array.isArray(this.question.attachments) && this.question.attachments.length > 0) ? this.question.attachments[0].text : ''
                const matchRegex = /{{(.[^}}]*)}}/g
                return questionText.match(matchRegex) ? questionText.match(matchRegex)
                    .reduce((matchAcc, translationMatch) => {
                        const replaceWith = translationMatch.slice(2, -2).trim()
                        return matchAcc.replace(translationMatch, this.getQuestionAnswerObject[replaceWith] ? this.getQuestionAnswerObject[replaceWith] : '')
                    }, questionText) :
                    questionText
            }
        },
        components: {
            Pen
        }
    }
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

    button, .el-button, .el-icon-close .signature-title {
        cursor: pointer;
    }
    .acknowledgement-button {
        width: 100%;
        height: 40px;
        border: 1px solid #999999;
        border-radius: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .acknowledgement-button.active {
        background-color: #E7FFEA;
        border: 1px solid #44B358;
        border-radius: 24px;
        color: #44B358;
    }
    .el-icon-circle-check {
        font-size: 21px;
    }
    .empty-circle {
        width: 18px;
        height: 18px;
        border: 1px solid #999999;
        border-radius: 50%;
        display: inline-block;
    }
    .i-agree-text {
        padding-left: 10px;
    }
    .selected {
        border: 2px solid #833e93 !important;
        border-radius: 0 !important;
        color: #833e93 !important;
    }
    .divider {
        height: 2px;
        width: 100%;
        background-color: #cccccc;
        position: relative;
        display: block;
        margin: 20px 0;
        span {
            position: absolute;
            top: -18px;
            left: 44%;
            background-color: #ffffff;
            padding: 10px;
            font-size: 20px;
            color: #333333;
            font-weight: 600;
        }
    }
    .gray {
        color: #eeeeee !important;
    }
    .auto-signature {
        // font-size: 35px;
        background-color: #f8f8f8;
        width: 49%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        color: #333333;
        overflow: hidden;
        .dancing-script {
            font-family: 'Dancing Script', cursive;
        }
        .caveat {
            font-family: 'Caveat', cursive;
        }
    }
    .select-indicator-tick {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: #833e93;
        color: #ffffff;
        font-size: 17px;
    }
    .auto-signature-container {
        height: 120px;
        margin-top: 10px;
    }
    .signature-radio {
        display: inline-block;
        float: left;
        margin: 5px 0;
    }
    .name-input {
        height: 40px;
        width: 100%;
        margin: 5px 0;
        font-size: 17px;
        font-weight: 500;
        padding-left: 10px;
        border: 1px solid #cccccc;
        outline: none;
    }
    .name-input::placeholder {
        font-size: 14px;
    }
    .signature-desktop-text {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .resign-error {
        font-size: 14px;
        line-height: 16px;
        color: #DA4E4E;
        text-align: left;
        margin-bottom: 8px;
    }
    .error-text {
        font-size: 14px;
        color: #da4e4e;
        float: left;
        margin-top: 5px;
    }
    .success-text {
        color: #44b358 !important;
    }
    .signature-radio::v-deep .el-radio__input+.el-radio__label {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        padding-left: 5px;
    }
    .signature-radio::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #333333;
        background-color: #ffffff;
        color: #333333;
    }
    .signature-radio::v-deep .el-radio__input.is-checked .el-radio__inner::after {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #000;
    }
    .signature-checkbox {
        float: left;
    }
    .signature-checkbox::v-deep .el-checkbox__input.is-focus {
        border-color: #333333;
    }
    .signature-checkbox::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .signature-checkbox::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #833e93;
        border-color: #833e93;
    }
    .signature-checkbox::v-deep .el-checkbox__label,
    .signature-checkbox::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #333333;
    }
    .signature-checkbox-container {
        height: 50px;
        padding: 10px;
        position: absolute;
        bottom: 65px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
    .signature-clear-text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        font-size: 14px;
        color: #333333;
        font-weight: 500;
    }
    .html-container {
        width: 100%;
        height: 40vh;
        padding: 10px;
        border: 1px solid #cccccc;
        border-radius: 0;
        overflow: scroll;
        text-align: justify;
    }
    .tap-to-sign-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tap-to-sign {
        color: #833e93;
        font-weight: bold;
        margin-left: 5px;
        text-decoration: underline;
    }
    .signature-container {
        z-index: 102;
        position: absolute;
        width: 100%;
        left: 0;
        background-color: #ffffff;
    }
    .signature-header {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.2);
    }
    .el-icon-close.signature-title {
        cursor: pointer !important;
    }
    .signature-title {
        color: #333333;
        line-height: 20px;
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 0 10px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .signature-image-container {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .signature-image, .desktop-signature-image {
        z-index: 102;
        max-height: 100%;
    }
    .signature-pad {
        border: 2px solid #cccccc;
        background-color: #f8f8f8;
        position: relative;
    }
    .signature-vertical-text, .signature-vertical-date {
        transform: rotate(90deg);
        position: absolute;
        top: 43vh;
        right: -162px;
        font-size: 25px;
        color: #cccccc;
        font-weight: 600;
    }
    .signature-vertical-date {
        top: 47vh;
        right: 70vw;
        min-width: 160px;
        font-size: 16px;
    }
    .signature-horizontal-text {
        position: absolute;
        top: 45px;
        left: 0;
        font-size: 17px;
        width: 100%;
        color: #999999;
        font-weight: 600;
        z-index: 101;
    }
    .button-container {
        position: relative;
        height: 140px;
        background-color: #ffffff !important;
    }
    .clear-button {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background-color: #ffffff;
        color: #833e93;
        border: 2px solid #833e93;
        border-radius: 0;
        width: 32%;
        height: 50px;
        font-weight: 600;
        font-size: 16px;
        outline: none;
    }
    .desktop-clear-button {
        background-color: #e2e2e2;
        color: #333333;
        border: none;
        font-size: 15px;
        height: 32px;
        width: 70px;
        position: absolute;
        right: 10px;
        font-weight: 500;
        outline: none;
        z-index: 101;
    }
    .el-button:focus, .save-button, .save-button.is-loading {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 61%;
        background-color: #833e93;
        color: #ffffff;
        height: 50px;
        border: none;
        border-radius: 0;
        font-weight: 600;
        font-size: 16px;
        outline: none;
    }
    .el-button, .el-button:focus, .el-button:hover {
        background-color: #833e93;
        color: #ffffff;
    }
    .disabled {
        opacity: 0.4;
    }
    .el-date-editor::v-deep .el-input__inner {
        height: 50px;
        background-color: #faf5fc;
        border: none;
        border-radius: 0px;
        border-bottom: 3px solid #833e93;
        color: #333333;
        font-size: 16px;
        font-weight: 600;
        padding-left: 45px;
    }
    .el-date-editor::v-deep .el-input__icon {
        color: #833e93;
        font-size: 20px;
        font-weight: 600;
        width: 40px;
    }
    .el-date-editor::v-deep .el-input__inner::placeholder {
        color: #333333;
    }


    input[type="date"] {
        padding: 5px 20px;
    }
    input[type="date"], focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        outline: none;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        float: left;
    }

</style>
