<template>
    
    <svg width="67" height="69" viewBox="0 0 67 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M65.6934 38.5778C64.5824 47.508 61.3962 59.702 59.6382 66.0351C59.0103 68.2968 56.5491 69.4864 54.3362 68.7039C48.8247 66.7548 38.8584 63.644 31.3926 63.644C24.4322 63.644 15.2983 66.348 9.62729 68.2935C7.02936 69.1847 4.21786 67.3559 4.09426 64.6121L2.69871 33.6308L0.480087 16.5981C0.120346 13.8362 2.65443 11.4806 5.41866 11.8214C11.8054 12.6088 22.8399 13.1766 34.6908 10.2139C48.5148 6.75787 55.5157 3.56715 60.7806 1.15231C63.2065 0.0396154 65.8898 1.67966 66.0373 4.34453C66.481 12.3579 67.0461 27.7048 65.6934 38.5778Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>