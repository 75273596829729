<template>
    
    <svg width="299" height="270" viewBox="0 0 299 270" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M80.6829 240.806C69.6262 246.444 49.9168 247.26 48.2048 247.321C48.116 247.324 48.0527 247.322 47.9643 247.313C46.4089 247.164 30.2417 245.747 21.2829 250.222C12.351 254.685 4.90911 267.84 2.14899 273.232C1.54805 274.405 2.28293 275.784 3.58472 275.994L148.82 299.449C149.03 299.483 149.245 299.483 149.456 299.449L295.647 275.954C296.875 275.757 297.616 274.505 297.152 273.351C295.187 268.466 289.939 256.883 282.788 252.396C276.699 248.575 265.327 247.45 264.068 247.335C263.988 247.328 263.924 247.326 263.844 247.329C261.946 247.39 235.76 248.087 221.215 240.806C210.429 235.406 204.195 230.299 197.31 221.247C190.723 212.586 185.103 197.882 182.991 191.96C182.389 190.27 182.303 188.45 182.692 186.699L187.827 163.59C187.871 163.395 187.941 163.21 188.04 163.036C188.92 161.474 193.539 153.192 195.861 147.359C198.326 141.167 200.595 131.719 201.372 128.336C201.549 127.568 202.153 126.969 202.892 126.697C204.826 125.983 208.536 124.11 210.349 119.832C213.018 113.532 219.041 97.7194 213.971 96.6519C213.63 96.5803 213.238 96.5439 212.841 96.5297C211.561 96.4841 210.458 95.4521 210.572 94.1764C211.361 85.3895 213.971 55.9594 213.971 51.0154C213.971 45.1906 209.624 -0.386098 149.138 1.03242C88.6512 2.45093 85.7537 43.7412 84.3049 51.0154C83.0831 57.1497 87.0129 85.3928 88.2745 94.0933C88.4624 95.3889 87.3701 96.4766 86.0678 96.6102C85.9589 96.6214 85.8536 96.6351 85.7537 96.6519C80.6829 97.503 86.0404 114.264 88.6512 120.557C90.385 124.736 94.1395 126.247 96.3483 126.786C97.3207 127.023 98.1273 127.815 98.2275 128.811C98.5938 132.452 99.6276 141.019 101.69 146.635C103.968 152.835 109.575 161.089 110.847 162.924C111.02 163.173 111.133 163.451 111.183 163.749L115.147 187.537C115.35 188.751 115.328 189.99 115.05 191.189C113.874 196.252 110.181 210.706 104.588 219.074C97.5765 229.563 91.9225 235.074 80.6829 240.806Z" :stroke="color" stroke-width="2" stroke-linecap="round"/>
</svg>

</template>

<script>
export default {
	name: 'body_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
