import DownloadView from '@/component/DownloadView'

export default [
	{
		path: '/yoga',
		name: 'yoga-landing',
		props: true,
		component: DownloadView
    },
	{
		path: '/yoga/:id',
		name: 'yoga-viewer',
		props: (route) => ({
			id: route.params.id,
            query: route.query
		}),
		component: DownloadView,
		meta: {
			link: true
		}
	}
]