<template>
	<div>
		<div class="exercise landing">
			<Header></Header>
			<template v-if="exercise">
				<div v-for="category in exercise" class="category">
					<h5>
						{{ category.title }}
					</h5>
					<div class="exercises">
						<router-link v-for="exercise in category.exercise"
							:key="exercise.id"
							class="exercise"
							:to="{ name: 'exercise-viewer', params: { id: exercise.id } }">
							<img :src="exercise.image">
							<p>{{ exercise.title }}</p>
							<small>{{ exercise.length }} min read</small>
						</router-link>
					</div>
				</div>
			</template>
			<template v-else>
				<Loading text="Loading exercises..."></Loading>
			</template>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/component/Loading'
import Header from './Header'

export default {
	name: 'exercise-landing',

	mounted() {
		this.loadContent()
	},

	methods: {
		...mapActions('user', [
			'loadContent'
		])
	},

	computed: {
		...mapGetters('user', [
			'getContent',
			'getContentLoadingState'
		]),
		loading() {
			return this.getContentLoadingState
		},
		exercise() {
			if (this.getContent)
				return this.getContent.exercise
		}
	},

	components: {
		Loading,
		Header
	}
}
</script>
<style lang="scss" scoped>
@import "style/landing.scss";
</style>
