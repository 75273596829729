import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=3765f611"
import script from "./Article.vue?vue&type=script&lang=js"
export * from "./Article.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports