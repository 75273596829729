<template>
    
    <svg width="14" height="33" viewBox="0 0 14 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 16.0137C0 24.846 0.995561 32.2865 6.96938 32.006C12.5448 32.006 13.9388 24.846 13.9388 16.0137C13.9388 7.18136 15.1335 -0.259224 6.96938 0.0213574C0.79643 0.0224118 0 7.18136 0 16.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>