import AppointmentLanding from './landing/Landing'
import AppointmentViewer from './viewer/Viewer'

import AppointmentWelcome from './viewer/welcome/Welcome'
import AppointmentSelect from './viewer/select/Select'

import AppointmentTopic from './viewer/topic/Topic'
import AppointmentTopicRoutes from './viewer/topic/routes'

import AppointmentDownload from '../../component/EmptyDownload'

export default [
	{
		path: '/appointment',
		name: 'appointment-landing',
		// Import the appointment landing screen
		component: AppointmentLanding
	},
	{
		// path: '/appointment/:id',
		// // Import the appointment viewer
		// component: AppointmentViewer,
		// props: (route) => ({
        //     id: route.params.id,
        //     query: route.query
        // }),
		// meta: {
		// 	link: true
		// },
		// children: [
		// 	{
		// 		name: 'appointment-viewer',
		// 		path: '',
		// 		component: AppointmentWelcome
		// 	},
		// 	{
		// 		name: 'appointment-select',
		// 		path: 'select',
		// 		props: true,
		// 		component: AppointmentSelect
		// 	},
		// 	{
		// 		name: 'appointment-topic',
		// 		path: 'topic',
		// 		component: AppointmentTopic,
		// 		props: true,
		// 		children: AppointmentTopicRoutes
		// 	}
		// ]
		path: '/appointment/:id',
		name: 'appointment-download',
		component: AppointmentDownload,
		props: {
			topic: 'appointment'
		},
		meta: {
            authComponent: true
        }
	}
]
