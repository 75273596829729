<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44" height="50" viewBox="0 0 44 50">
	    <defs>
	        <linearGradient id="a" x1="84.56%" x2="31.07%" y1="0%" y2="85.254%">
	            <stop offset="0%" stop-color="#D3713E"/>
	            <stop offset="100%" stop-color="#C45531"/>
	        </linearGradient>
	        <ellipse id="b" cx="33.224" cy="36.154" rx="10.776" ry="10.846"/>
	    </defs>
	    <g fill="none" fill-rule="evenodd">
	        <path fill="url(#a)" d="M13.785 27.252c-.848 0-1.535-.55-1.535-1.226 0-.678.687-1.228 1.535-1.228h9.632c.846 0 1.535.55 1.535 1.228 0 .676-.689 1.226-1.535 1.226h-9.632zm14.642 1.518c.85 0 1.535.55 1.535 1.226 0 .678-.685 1.228-1.535 1.228H13.783c-.846 0-1.533-.55-1.533-1.228 0-.676.687-1.226 1.533-1.226h14.644zm-14.642-5.473c-.848 0-1.535-.55-1.535-1.226 0-.678.687-1.229 1.535-1.229h13.318c.846 0 1.533.55 1.533 1.229 0 .676-.687 1.226-1.533 1.226H13.785zM6.125 0h29.75c2.625 0 2.54 1.75 2.54 3.5v3.645c0 .49-.405.887-.904.887H4.375a.871.871 0 0 1-.875-.887V3.5c0-1.75 0-3.5 2.625-3.5zm26.25 33.25c0 .981-.769 1.75-1.75 1.75h-19.25c-.98 0-1.75-.769-1.75-1.75V19.275c0-.98.795-1.775 1.775-1.775h19.225c.981 0 1.75.795 1.75 1.775V33.25zM7.45 10.22a.887.887 0 0 0-.888.887v29.115c0 .98.796 1.775 1.775 1.775h25.375c.982 0 1.775-.795 1.775-1.775V11.107a.887.887 0 0 0-.887-.887H7.45z" transform="translate(-3)"/>
	        <g fill-rule="nonzero" transform="translate(-3)">
	            <use fill="#FFF" stroke="#FFF" stroke-width="6" xlink:href="#b"/>
	            <use stroke="#C45531" stroke-width="2" xlink:href="#b"/>
	        </g>
	        <g stroke="#CC6438" stroke-linecap="round" stroke-width="2.3">
	            <path d="M29.865 31.04v9.283M34.894 35.682H24.837"/>
	        </g>
	    </g>
	</svg>
</template>
<script>
export default { name: 'header-add-medication-icon' }
</script>
