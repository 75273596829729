<template>
    <svg width="43" height="85" viewBox="0 0 43 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M9.41418 33.9254C13.1055 44.2714 19.1157 53.8315 22.8963 61.983C30.6895 78.7861 35.5441 85.4127 39.1093 84.5681C39.303 84.5222 39.5026 84.4657 39.6947 84.4137C46.7945 82.4929 39.282 64.0326 30.8963 30.983C30.0278 28.5488 29.9034 19.2397 28.562 13.483C27.6647 9.63226 25.8209 5.61054 24.2996 2.70183C23.133 0.471385 20.1826 0.0838511 18.152 1.57133C16.3544 2.88816 14.1317 4.28285 12 4.99341C9.22109 5.91971 6.33451 6.73873 4.03611 7.35115C1.66112 7.98397 0.327266 10.5528 1.23888 12.8353C3.56904 18.6697 7.61678 28.8876 9.41418 33.9254Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_forearm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>