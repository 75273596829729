<template>
	<div class="empty">
		<div class="text">
			<NoneIcon class="icon"></NoneIcon>
			<p>No active checklists at this time</p>
		</div>
		<router-link to="/">
			Go Back
		</router-link>
	</div>
</template>

<script>
import NoneIcon from './icon/None'

export default {
	components: {
		NoneIcon
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
