<template>
    
    <svg width="88" height="45" viewBox="0 0 88 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M87.2415 26.3244V21.2047C87.2415 20.3326 87.0453 19.4806 86.5751 18.7461C83.8964 14.5622 72.7001 0.216797 45.1091 0.216797C17.9232 0.216797 4.66457 14.1441 1.1469 18.5554C0.475304 19.3976 0.167969 20.4513 0.167969 21.5285V26.2255C0.167969 27.1303 0.382465 28.0186 0.915921 28.7495C3.61667 32.4497 14.3074 43.8678 43.7047 44.4558C73.4711 45.0511 84.0583 32.5238 86.5915 28.6966C87.056 27.9949 87.2415 27.1659 87.2415 26.3244Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'forehead',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
