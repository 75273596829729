<template>
	<div class="lis">
		<!-- Iterate through choices -->
		<template v-if="question.hasOwnProperty('options')">
			<div v-for="(option, index) in question.options" :key="option.id"
				:class="['choice', 'with-image', 'with-text', { selected: isSelected(option) }]"
				@click="select(option)">

				<!-- Choice content -->
				<img :src="isSelected(option) ? option.active_image : option.inactive_image">
				<div class="lis-text">{{ option.value }}</div>
			</div>
		</template>
		<div v-else v-for="choice in max" :key="choice"
			v-bind:class="['choice', 'with-image', 'with-text',
				{ selected: isSelected(question.select[choice - 1]) }]"
			@click="selectFor(choice - 1)">

			<!-- Choice content -->
			<img :src="getImage(choice)">
			<div class="lis-text">{{ getText(choice) }}</div>
			<!-- <div class="lis-text">Base</div> -->
		</div>
	</div>
</template>

<script>
// import Vue from 'vue'
import Question from './index'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'


Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
	name: 'questionnaire-large-image-select',
	extends: Question,

	mounted() {
		this.$emit('selected', this.saved)
	},

	methods: {
		/**
		 * @func getImage
		 * @desc Return the image for the answer at the given 1-based index
		 *
		 */
		getImage(index) {
			if (this.selected.includes(index - 1))
				return this.question.active_image[index - 1]
			else
				return this.question.inactive_image[index - 1]
		},

		getText(index) {
			return this.question.select[index - 1]
		},
		handleScroll(evt, el) {
			if (window.scrollY > 50) {
				el.setAttribute(
					'style',
					'opacity: 1; transform: translate3d(0, -10px, 0)'
				)
			}
			return window.scrollY > 100
		}

	},

	computed: {
		max() {
			return this.question.max
		}
	}
}
</script>

<style lang="scss" scoped>
$choice-size: 120px;//30%;
$choice-font-size: 20px;

@import "style.scss";
.choice {
	width: 45%;
	height: auto;
	min-height: 150px;
	margin-bottom: 15px;

	border: none;
	padding: 10px !important;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		width: auto;
		height: 60px;
		max-width: 60px;
		max-height: 60px;
	}
}
.lis {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: 15px;
}
</style>
