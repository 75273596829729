<template>
    
    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.278965 9.02779C1.35489 13.5056 2.68661 18.8127 3.79291 22.5003C5.29291 27.5003 9.79291 34.0003 13.2929 36.5003C16.7929 39.0003 25.154 37.9236 28.2929 29.0003C30.7056 22.1415 35.0245 6.09703 32.2929 1.99973C29.2929 -2.50001 20.5296 2.04365 14.2929 2.49999C12.4106 2.63772 9.63354 2.3788 6.91928 2.00601C2.86299 1.44891 -0.677605 5.04673 0.278965 9.02779Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>