
<template>
	<div class="appointment review">
        <div
			v-for="section in topics"
			:key="section.title">
			<SectionEdit
				:section="section"
				:questions="getSelectedQuestions(section)"
				@clicked="onClickSection">
			</SectionEdit>
		</div>
		<div
			v-on:click="patchAppointment"
			class="button">
			<router-link :to="{ name: 'appointment_thanks' }">
				SAVE
			</router-link>
		</div>
		<!-- <ButtonMain class="button"
			:buttonName="buttonName"
			:route="route"
			:review="review"
			@saved="patchAppointment">
		</ButtonMain> -->
	</div>
</template>
<script>
import Vue from 'vue'
import sections from '../../util/sections'
import SectionEdit from './section/SectionEdit'
import AppointmentView from '../../mixin/appointment-view'
import ButtonMain from '../../../component/ButtonMain'
import { mapActions } from 'vuex'

const TOPIC_MAP = {
	symptom_question: true,
	vital_question: true,
	medication_question: true,
	activity_question: true,
	lifestyle_question: true,
	coping_living_question: true,
	cause_risk_question: true,
	diagnosis_treatment_question: true,
	other_question: true
}
export default {
	name: 'appointment-review',
	props: {
		result: {
			type: Object
		},
		appointment: {
			type: Object
		},
		content: {
			type: Object
		},
		summary: {
			type: Object
		}
	},
	mixins: [ AppointmentView ],
	data() {
		return {
			sections,
			topics: null,
			buttonName: 'SAVE',
			route: { name: 'appointment_thanks' },
			review: true
		}
	},
	mounted() {
		this.getFromStorage()
		this.updateStorage()
		this.loadSections()
		this.checkQuestions()
	},
	methods: {
		...mapActions('user', [
			'addEvent'
		]),
		getFromStorage() {
			// Get the state from local storage, and initialize if not yet initialized
			var state = localStorage.getItem(this.appointment.id + '_result')
			if (state) {
				this.result = JSON.parse(state)
				Object.keys(this.result).forEach((topic) => {
					Vue.set(this.result, topic, this.result[topic])
				})
			}
		},
		updateStorage() {
			Vue.set(this.result, 'current', 'review')
			localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
		},
		// Update the topic selection from child Section
		onClickSection({key}) {
			// Add flag to come back to summary page after finished editting
			//Vue.set(this.result[key], 'status', 'edit')
			//localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
            this.$router.replace({ name: 'appointment_' + key })
		},
		loadSections() {
			var hasQuestion = false
			// Get all topics related to user
			this.topics = sections.filter((section) => {
				// Reset flag
				hasQuestion = false

				// Check if section exists in central state store
				if (this.result[section.key]) {
					// Go through each question in topic to see if set to true
					Object.keys(this.result[section.key].selectedQuestions).forEach((question) => {
						if (this.result[section.key].selectedQuestions[question] == true) {
							hasQuestion = true
						}
					})
				}
				// Only return the topic information for topics that have selected questions that are true
				return ((Object.keys(this.content).includes(section.key) /*||
							section.key in questionList*/) &&
								hasQuestion == true)
			})
			if (this.result['other_question'] &&
					Object.keys(this.result['other_question'].addedQuestions).length !== 0)
				this.topics.push({
					image: null,
					key: "other_question",
					title: "Other Questions",
					color: "black"
				})

			if (this.topics.length == 0) {
				this.$router.replace({ name: 'appointment_noselection'})
			}
		},
		getSelectedQuestions(section) {
			var selectedKeys, addedKeys
			if (this.result[section.key].selectedQuestions)
				selectedKeys = Object.keys(this.result[section.key].selectedQuestions)
			if (this.result[section.key].addedQuestions)
				addedKeys = Object.keys(this.result[section.key].addedQuestions)

			// Check each set of questions for selected flag
			if (selectedKeys)
				selectedKeys = selectedKeys.filter((key) => {
					return this.result[section.key].selectedQuestions[key] == true
				})
			else
				selectedKeys = []
			if (addedKeys)
				addedKeys = addedKeys.filter((key) => {
					return this.result[section.key].addedQuestions[key] == true
				})
			else
				addedKeys = []
			return selectedKeys.concat(addedKeys)
		},
		checkQuestions() {
			var selected = []

			this.topics.forEach((topic) => {
				var selectedOnes = this.getSelectedQuestions(topic)
				selected = selected.concat(selectedOnes)
			})
			if (selected.length == 0)
				this.$router.replace({ name: 'appointment_noselection' })
		},
		patchAppointment() {
			// Clear local storage
			localStorage.removeItem(this.appointment.id + '_selected')
			localStorage.removeItem(this.appointment.id + '_result')
			// Convert selected questions to array
			var body = {
				last_updated: Date.now()
			}
			var questions = []
			Object.keys(this.result).forEach((topic) => {
				if (TOPIC_MAP[topic]) {
					this.selectedQuestions = this.result[topic].selectedQuestions
					this.addedQuestions = this.result[topic].addedQuestions

					if (this.selectedQuestions)
						Object.keys(this.selectedQuestions).forEach((question) => {
							if (this.selectedQuestions[question] == true)
								questions.push(question)
						})
					if (this.addedQuestions)
						Object.keys(this.addedQuestions).forEach((question) => {
							if (this.addedQuestions[question] == true)
								questions.push(question)
						})
						if (questions.length > 0) {
							body[topic] = JSON.parse(JSON.stringify(questions))
							questions = []
						}
				}
			})
			return this.addEvent({
				id: this.appointment.id,
				type: 'appointment',
				body
			}).then().catch((error) => {
				this.$message({
					showClose: true,
					message: 'Unable to send response. Error: ' + error.code,
					type: 'error',
					duration: '0'
				})
			})
		}
	},
	computed: {
	},
	components: {
		SectionEdit,
		ButtonMain
	}
}
</script>


<style lang="scss" scoped>
//@import "./style.scss";
.review {
    .image-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 10%;
        margin-bottom: 5%;
        .image {
            width: 25px;
            margin-right: 10px;
        }
    }
    .button {
        display: flex;
        align-items: center;
		justify-content: center;
		margin-bottom: 50px;
        a {
            border: #2D3E4F;
            background-color: #2D3E4F;
            color: white;
            text-decoration: none;
            text-align: center;
            width: 100%;
            height: 100%;
            margin-left: 10%;
            margin-right: 10%;
            padding: 2%;
            font-size: 18px;
            margin-top: 5%;
        }
    }
}
</style>
