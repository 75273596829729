<template>
    <div v-bind:class="['section', { selected }]">
        <div v-bind:class="['image-message', { last }]">
            <div class="image-title">
                <img
                    :src="getImage(section.image)">
                <span v-bind:style="{ color: section.color }">
                    <strong>{{ section.title }}</strong>
                </span>
                <button
                    v-if="selected"
                    v-bind:class="{ selected }"
                    v-on:click="toggleSelected">
                    <img
                        class="check"
                        src="./image/check_blue.png">
                </button>
                <button
                    v-else
                    class="button"
                    v-on:click="toggleSelected">
                    {{ selectText }}
                </button>
            </div>
            <div class="message">
                <div v-html="section.message" >
                </div>
            </div>
        </div>
        <el-card>
            <div class="card">
                <img 
                    :src="getImage(section.image)" 
                    class="image">
                <div class="content">
                    <span 
                        v-bind:style="{ color: section.color }"
                        class="title">
                        <strong>{{ section.title }}</strong>
                    </span>
                    <div 
                        v-html="section.message"
                        class="html">
                    </div>
                    <button
                        v-if="selected"
                        v-bind:class="{ selected }"
                        v-on:click="toggleSelected">
                        <img
                            class="check"
                            src="./image/check_blue.png">
                    </button>
                    <button
                        v-else
                        v-on:click="toggleSelected"
                        class="select">
                        {{ selectText }}
                    </button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import Vue from 'vue'
// import CardTopic from './CardTopic'

export default {

	props: {
        section: {
            type: Object
        },
        message: {
            type: String
        },
        index: {
            type: Number
        },
        topics: {
            type: Array
        }
	},

    data() {
        return {
            selected: this.section.selected ? this.section.selected : false,
            last: this.index === Object.keys(this.topics).length - 1 ? true : false
        }
    },
    mounted () {
    },
	methods: {
		getImage(file) {
			return require('../../image/' + file)
		},
        toggleSelected() {
            this.selected = ! this.selected
            this.$emit('clicked', {
				key: this.section.key,
				selected: this.selected
			})
        }
    },
    computed: {
		selectText() {
			return (this.selected) ? 'SELECTED' : 'SELECT'
        }
    },
	components: {
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
