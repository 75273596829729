<template>
    <div>
        <div v-if="thanks" class="settings-thanks">
            <div class="thanks-image">
                <img src="../image/thanks.png">
            </div>
            <p class="title">Thanks!</p>
            <p class="message">We will get back to you soon regarding your contact request.</p>
            <MainButton @clickButton="done" :done=true class="done-button"></MainButton>
        </div>
        <div v-else class="contact-support">
            <div class="support-question">
                How can we help you?
            </div>
            <div class="support-text">
                Please let us know if you're having any issues with the DoctorPlan app or if you have suggestions on how we can make it better.
            </div>
            <textarea 
                v-model="input" 
                placeholder="Start typing..."
                class="support-input"/>
            <MainButton @clickButton="send" :send=true class="support-send"></MainButton>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MainButton from './button/Button'

export default {
	props: {
	},
	mounted() {
    },
	data() {
		return {
            input: null,
            thanks: false
		}
	},
    methods: {
        ...mapActions('user',[
            'sendSupport'
        ]),
        send() {
            this.thanks = true
            return this.sendSupport({ question: this.input, type: 'support' })
        },
        done() {
            return this.$router.push({ path: '/setting'})
        },
        handleClick() {

        }
    },
    computed: {

    },
    components: {
        MainButton
    }
}
</script>
<style lang="scss" scoped>
.settings-thanks {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
		width: 50%;
	}
    .title {
		font-size: 28px;
		font-weight: 700;
		text-align: center;
		margin-top: 50px;
	}
    .message {
		font-size: 14pt;
		margin-top: 50px;
		margin-left: 10%;
		margin-right: 10%;
		text-align: center;
    }
    .done-button {
        margin-top: 50px;
    }
}

.contact-support {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    .support-question {
        margin-top: 20px;
        font-size: 18pt;
        font-weight: 600;
    }
    .support-text {
        margin-top: 10px;
        font-size: 11pt;
        line-height: 20px;
    }
    .support-input {
        margin-top: 30px;
        height: 300px;
        font-size: 12pt;
        border: 1px solid #6D6D6D;
        box-sizing: border-box;
        padding-left: 10px;
        padding-top: 5px;
    }
    .support-send {
        margin-top: 30px;
    }
}
</style>
