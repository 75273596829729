<template>
<svg width="103" height="29" viewBox="0 0 103 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M53.0243 29C71.4618 29 86.0183 27.0525 95.0553 23.1122C99.8997 21 102.696 18.5099 102.696 15.8436C102.696 13.6475 104.134 4.56693 100.758 2.72263C92.3892 -1.84957 71.5967 2.00962 51.416 2.00962C34.8286 2.00962 14.6809 -1.99439 5.30773 1.30117C-1.32261 3.63238 0.136224 12.6781 0.136223 15.8436C0.136223 18.5364 2.98828 21.0496 7.92194 23.1752C16.9862 27.0804 34.6852 29 53.0243 29Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'lower_abdomen',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
