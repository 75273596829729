<template>
    
    <svg width="109" height="90" viewBox="0 0 109 90" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M108.659 47.5053C108.659 70.7093 84.3345 89.5199 54.3293 89.5199C24.324 89.5199 0 70.7093 0 47.5053C0 24.3012 24.324 0.419922 54.3293 0.419922C84.3345 0.419922 108.659 24.3012 108.659 47.5053Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'head_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
