<template>
    
    <svg width="43" height="85" viewBox="0 0 43 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M34.0858 33.9254C30.3945 44.2714 24.3843 53.8315 20.6037 61.983C12.8105 78.7861 7.9559 85.4127 4.3907 84.5681C4.19701 84.5222 3.9974 84.4657 3.80526 84.4137C-3.2945 82.4929 4.21797 64.0326 12.6037 30.983C13.4722 28.5488 13.5966 19.2397 14.938 13.483C15.8353 9.63226 17.6791 5.61054 19.2004 2.70183C20.367 0.471385 23.3174 0.0838511 25.348 1.57133C27.1456 2.88816 29.3683 4.28285 31.5 4.99341C34.2789 5.91971 37.1655 6.73873 39.4639 7.35115C41.8389 7.98397 43.1727 10.5528 42.2611 12.8353C39.931 18.6697 35.8832 28.8876 34.0858 33.9254Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_forearm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>