<template>
    
    <svg width="65" height="46" viewBox="0 0 65 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.673297 41.7321L6.56324 5.73796C6.79036 4.35002 7.83305 3.24291 9.21182 2.96552C13.9966 2.00292 25.3132 -0.0828731 33.1515 0.00254605C41.2929 0.0912696 53.5303 2.54884 58.8666 3.70711C60.4243 4.04522 61.5121 5.42639 61.5121 7.02039V25.01C61.5121 25.2402 61.5353 25.4697 61.5811 25.6952L64.2121 38.6302C64.9057 42.0404 60.325 44.5452 57.6094 42.3688C53.1714 38.812 47.6979 35.1943 42.605 33.9489C30.852 31.0749 15.5052 39.461 6.56353 45.3845C3.92285 47.1338 0.161774 44.858 0.673297 41.7321Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>