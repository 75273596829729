<template>
<svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.0016 27.0767C27.6179 39.9389 8.81192 61.3721 7.45665 54.3426C6.7982 50.9274 6.322 47.6819 5.85996 44.5328C5.37098 41.2002 4.89786 37.9757 4.24136 34.7724L0.756929 4.97541C0.350343 1.49892 10.1262 1.94564 14.1656 3.40741C22.576 6.45095 26.4791 16.1738 27.0016 27.0767ZM12.4054 14.1845C12.4696 14.7331 12.9663 15.1257 13.5148 15.0616C14.0634 14.9974 14.4561 14.5008 14.3919 13.9522C14.3278 13.4037 13.8311 13.011 13.2825 13.0751C12.734 13.1393 12.3413 13.636 12.4054 14.1845ZM11.4643 23.356C10.9157 23.4202 10.419 23.0275 10.3549 22.4789C10.2908 21.9304 10.6834 21.4337 11.232 21.3696C11.7805 21.3054 12.2772 21.6981 12.3414 22.2467C12.4055 22.7952 12.0128 23.2919 11.4643 23.356ZM16.3143 21.7822C16.3784 22.3307 16.8751 22.7234 17.4236 22.6593C17.9722 22.5951 18.3649 22.0984 18.3007 21.5499C18.2366 21.0013 17.7399 20.6086 17.1913 20.6728C16.6428 20.7369 16.2501 21.2336 16.3143 21.7822ZM18.3528 30.6051C17.8043 30.6692 17.3076 30.2765 17.2435 29.728C17.1793 29.1794 17.572 28.6827 18.1205 28.6186C18.6691 28.5544 19.1658 28.9471 19.2299 29.4957C19.2941 30.0442 18.9014 30.5409 18.3528 30.6051ZM11.2841 30.4247C11.3482 30.9733 11.8449 31.366 12.3935 31.3018C12.942 31.2377 13.3347 30.741 13.2706 30.1925C13.2064 29.6439 12.7097 29.2512 12.1612 29.3154C11.6126 29.3795 11.22 29.8762 11.2841 30.4247ZM16.1862 37.9058C15.6377 37.97 15.141 37.5773 15.0768 37.0287C15.0127 36.4802 15.4054 35.9835 15.9539 35.9194C16.5025 35.8552 16.9991 36.2479 17.0633 36.7965C17.1274 37.345 16.7348 37.8417 16.1862 37.9058Z" :fill="color"/>
</g>
</svg>
</template>
<script>
export default {
	name: 'right_axilla',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
