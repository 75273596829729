<template>
<svg width="17" height="43" viewBox="0 0 17 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M15 20.0001C16.247 31.5309 19.5 40.9614 10.2535 41.9614C3.50001 42.6917 3.74699 34.0308 2.5 22.5C1.25301 10.9693 -2.52444 1.09837 5.73778 0.204861C14 -0.688653 13.753 8.46939 15 20.0001Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_under_arm',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
