<template>
    
    <svg width="65" height="50" viewBox="0 0 65 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M63.7944 25C63.7944 38.8071 71.8318 50 33.6542 50C0.5 50 0.5 38.8071 0.5 25C0.5 11.1929 2.50935 0 33.6542 0C64.7991 0 63.7944 11.1929 63.7944 25Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>