<template>
<svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.0033391 9.09694C0.00333935 6.19688 -0.148941 3.03545 1.60307 1.19237C3.94843 -1.2749 11.1556 1.19237 15.2017 1.19237C18.8123 1.19237 24.4736 -1.21404 26.8004 0.810238C29.0288 2.74882 30 6.18647 30 9.09694C30 12.4989 28.5264 17.4805 25.6005 19.4547C23.4094 20.9332 18.2284 23.2388 15.2017 23.2388C12.175 23.2388 6.99386 20.9339 4.80274 19.4554C1.87678 17.4812 0.0033388 12.4989 0.0033391 9.09694Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'neck_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
