<template>
	<div>
		<template>
			<Loading v-if="$route.query.account === 'doctorplan'" text="Loading article..."></Loading>
			<GenericLoading v-else text="Loading article..."></GenericLoading>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Loading from '@/component/Loading'
import GenericLoading from '@/component/GenericLoading'

export default {
	name: 'category-viewer',

	props: {
		id: {
			type: String,
			required: true
		},
		// category: {
		// 	type: String
        // },
		query: {
			type: Object
		}
	},

	data() {
		return {
			missing: false
		}
	},

	mounted() {
		if (! this.exercise)
			this.logout()
			.then(() => {
				return this.handoff(this.query)
			})
			.then(() => {
                return this.loadCategory()
			})
			.then((category) => {
				return this.$router.push( {path: '/article/' + category.article[0] } )
            })
	},

	methods: {
		...mapActions('content', [
			'loadObject'
        ]),
        ...mapActions('user', [
			'loadContent'
        ]),
        ...mapActions('auth', [
			'handoff',
			'logout'
		]),
		loadCategory() {
			return this.loadObject({ type: 'category', id: this.id })
		}
	},

	computed: {
		...mapGetters('content', [
			'getObject'
        ]),
        ...mapGetters('user', [
			'getContent',
			'getContentLoadingState'
		]),
        // article() {
		// 	if (this.getContent)
		// 		return this.getContent.article.filter((item) => {
        //             return item.id == this.id
        //         })
		// }
    },
    
	components: {
		Loading,
		GenericLoading
	}
}
</script>

<style lang="scss" scoped>

</style>
