<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="26" viewBox="0 0 29 26">
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g fill="#833E93">
                            <path d="M0 4.356H4.978V18.978H0z" transform="translate(-102 -498) translate(103 498) rotate(30 10.048 39.029)"/>
                            <path d="M2.489 19.911L4.978 23.956 0 23.956z" transform="translate(-102 -498) translate(103 498) rotate(30 10.048 39.029) rotate(-180 2.489 21.933)"/>
                            <path d="M2.489 0c1.374 0 2.489 1.114 2.489 2.489v.933H0V2.49C0 1.114 1.114 0 2.489 0z" transform="translate(-102 -498) translate(103 498) rotate(30 10.048 39.029)"/>
                            <path d="M3.111 2.178h2.733c.553 0 1 .447 1 1v.244H3.111V2.178z" transform="translate(-102 -498) translate(103 498) rotate(30 10.048 39.029)"/>
                            <path d="M4.2 5.133h4.667v1.245H4.2c-.344 0-.622-.279-.622-.622 0-.344.278-.623.622-.623z" transform="translate(-102 -498) translate(103 498) rotate(30 10.048 39.029) rotate(-90 6.222 5.756)"/>
                        </g>
                        <path stroke="#833E93" stroke-linecap="round" d="M0 23.019c.685-.728 3.158-4.18 1.446-4.865-1.18-.472-1.36 2.96-1.184 3.362 1.052 2.42 3.61-.719 4.57-.644.716.055.8 1.477 1.516 1.53.895.067 1.497-1.144 2.389-1.24.594-.063 1.005.741 1.598.815.539.068.875-.351 1.306-.624" transform="translate(-102 -498) translate(103 498)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>


</template>
<script>
export default {
	name: 'pen-icon'
}
</script>
