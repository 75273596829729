<template>
	<div class="scroller">
		<Scroller v-model="labelValue" color="symptom" :key="renderKey" :options="labels"></Scroller>
	</div>
</template>

<script>
import Question from './index'
import Scroller from '@/component/picker/picker'
import '@/component/picker/picker.scss'

import Vue from 'vue'

Vue.component('Scroller', Scroller)

export default {

	name: 'symptom-scroller',
	extends: Question,
	props: {
		saved: {
			type: Array
		}
	},
	data() {
		return {
			renderKey: 0,
			labelValue: ''
		}
	},
    watch: {
        labelValue(value) {
			if (this.labelValue === 'Please choose') this.$emit('selected', [])
            else this.$emit('selected', [ value ])
        }
	},
	mounted() {
		if (this.saved) this.labelValue = this.saved[0]
		else this.labelValue = 'Please choose'
		this.$nextTick(() => this.renderKey += 1)
	},

	computed: {
		labels() {
			var arr = ['Please choose']
			if (this.question.hasOwnProperty('min') && this.question.hasOwnProperty('max'))
				for (let i = this.question.min; i <= this.question.max; i++)
					arr.push(i.toString())
			return arr
		}
	},
	components: {
		Scroller
	}
}
</script>

<style lang="scss" scoped>
.scroller {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}
</style>
