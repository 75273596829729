import QuestionnaireLanding from './landing/Landing'
import QuestionnaireViewer from './viewer/Viewer'

import QuestionnaireQuestion from './viewer/Question'
import Download from '../../component/Download'
import QuestionnaireDownload from '../../component/EmptyDownload'
import QuestionnaireComplete from './viewer/Complete'
import QuestionnaireThankyou from './viewer/Thankyou'
import DownloadView from '@/component/DownloadView'

export default [
	{
		path: '/questionnaire',
		name: 'questionnaire-landing',
		// Import the questionnaire landing screen
		component: QuestionnaireLanding
		// component: DownloadView
	},
	{
		path: '/questionnaire/:id',
		name: 'questionnaire-viewer',
		props: (route) => ({
			id: route.params.id,
			number: route.params.number,
            query: route.query
        }),
		// Import the questionnaire viewer
		component: QuestionnaireViewer,
		//component: QuestionnaireDownload,

		meta: {
			link: true
		},

		children: [
			{
				path: 'question/:number',
				name: 'questionnaire-question',
				props: true,
				// Import the questionnaire viewer
				component: QuestionnaireQuestion
				//component: QuestionnaireDownload
			}
		]
		// path: '/questionnaire/:id',
		// name: 'questionnaire-download',
		// component: QuestionnaireDownload,
		// props: {
		// 	topic: 'questionnaire'
		// },
		// meta: {
        //     authComponent: true
        // }
	},
	{
		path: '/questionnaire/download',
		name: 'questionnaire-download',
		component: QuestionnaireDownload,
		props: true,
		meta: {
            authComponent: true
		}
	},
	{
		path: '/questionnaire/complete',
		name: 'questionnaire-complete',
		component: QuestionnaireComplete,
		props: true,
		meta: {
			authComponent: true
		}
	},
	{
		path: '/questionnaire/thankyou',
		name: 'questionnaire-thankyou',
		component: QuestionnaireThankyou,
		props: true,
		meta: {
			authComponent: true
		}
	}
]
