<template>
    
    <svg width="26" height="61" viewBox="0 0 26 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.551179 56.2516C17.717 41.3642 11.596 15.9218 6.38973 5.06152C1.70391 -4.71274 14.7898 1.93624 20.7367 10.3113C26.6836 18.6863 26.8059 36.7988 24.9079 42.0781C23.0098 47.3575 18.8185 55.669 11.5542 58.5559C1.85783 62.4093 -1.28798 57.8466 0.551179 56.2516Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'behind_ear_s',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
