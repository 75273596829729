<template>
    <svg width="137" height="52" viewBox="0 0 137 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.774077 14.2555C1.15389 19.9528 1.88669 27.056 3.25705 35.2369C3.99113 39.6193 4.35817 41.8105 5.96007 43.3991C7.56196 44.9878 9.80217 45.344 14.2826 46.0564C27.2543 48.1189 51.1629 51.5 68.5013 51.5C85.8456 51.5 109.764 48.1167 122.733 46.0543C127.208 45.3428 129.445 44.987 131.046 43.4012C132.647 41.8154 133.017 39.6273 133.756 35.2513L133.756 35.2512C135.164 26.9101 135.901 19.7149 136.273 13.9836C136.698 7.43836 136.91 4.16572 134.529 1.90775C132.148 -0.350227 128.64 0.065155 121.625 0.895924C107.027 2.62469 84.573 4.92232 67.7863 5.00146C51.0954 5.08015 29.7423 2.83159 15.618 1.06459C8.47692 0.171207 4.90637 -0.275482 2.50683 1.98902C0.10729 4.25352 0.329552 7.58753 0.774077 14.2555Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'mid_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>