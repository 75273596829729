import ExerciseLanding from './Landing'
import ExerciseViewer from './Viewer'

export default [
	{
		path: '/exercise',
		name: 'exercise-landing',
		props: true,
		component: ExerciseLanding
	},
	{
		path: '/exercise/:id',
		name: 'exercise-viewer',
		props: (route) => ({
			id: route.params.id,
            query: route.query
		}),
		component: ExerciseViewer,
		meta: {
			link: true
		}
	}
	// {
	// 	path: '/learn/:id',
	// 	name: 'learn-viewer',
	// 	props: true,
	// 	component: ExerciseViewer,

	// 	meta: {
	// 		link: true
	// 	}
	// }
]
