<template>
    
    <svg width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.8125 37.3906V25.5157C0.8125 25.2017 0.849218 24.8891 0.927953 24.5852C1.46365 22.5171 3.82718 13.9969 7.25598 10.4605C11.2242 6.36779 26.1625 1.95516 29.1258 1.10935C29.4706 1.01092 29.8203 0.964844 30.1789 0.964844H32.5935C35.5835 0.964844 37.5165 4.12564 36.1543 6.78727L18.4807 41.3187C17.6598 42.9226 15.868 43.7797 14.1041 43.4122L3.99668 41.3065C2.14181 40.92 0.8125 39.2853 0.8125 37.3906Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_shoulder_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>