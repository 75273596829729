<template>
<svg width="29" height="72" viewBox="0 0 29 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.528023 13.3633C-0.286236 6.24219 5.28287 0 12.4503 0H17C23.6274 0 29 5.37258 29 12V58.8114C29 63.7503 25.974 68.1848 21.375 69.9854L19.8345 70.5886C11.9641 73.6701 3.45946 67.8667 3.45946 59.4146V39L0.528023 13.3633Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_side_flank',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
