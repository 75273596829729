<template>
<svg width="33" height="42" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.1797 10.4592C29.1797 10.4592 27.1843 -0.963889 14.6771 0.0656327C2.17364 1.09337 2.64402 14.6291 2.64402 14.6291C2.64402 14.6291 0 16.3414 0 20.6829C0 25.0226 2.23244 27.7644 2.23244 27.7644C2.23244 27.7644 3.3459 33.761 6.28206 36.9032C9.21823 40.0436 13.0345 42.0436 20.2573 41.3001C27.4765 40.5583 29.1797 34.1042 29.1797 34.1042C29.1797 34.1042 29.9441 30.1058 28.8876 24.8528C28.8876 24.8528 32.7039 22.9099 32.2904 18.168C31.8807 13.4297 29.1797 10.4592 29.1797 10.4592Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_foot_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
