import body_f from './body_f'
import body_selected from './body_selected'

import right_under_arm from './right_under_arm'
import left_under_arm from './left_under_arm'
import left_areola from './left_areola'
import left_lower_inner_quadrant from './left_lower_inner_quadrant'
import left_lower_outer_quadrant from './left_lower_outer_quadrant'
import left_upper_inner_quadrant from './left_upper_inner_quadrant'
import left_upper_outer_quadrant from './left_upper_outer_quadrant'
import under_collarbone from './under_collarbone'
import right_areola from './right_areola'
import right_lower_outer_quadrant from './right_lower_outer_quadrant'
import right_lower_inner_quadrant from './right_lower_inner_quadrant'
import right_upper_outer_quadrant from './right_upper_outer_quadrant'
import right_upper_inner_quadrant from './right_upper_inner_quadrant'


export default {
    body_f,
    body_selected,

    right_under_arm,
    left_under_arm,
    left_areola,
    left_lower_inner_quadrant,
    left_lower_outer_quadrant,
    left_upper_inner_quadrant,
    left_upper_outer_quadrant,
    under_collarbone,
    right_areola,
    right_lower_outer_quadrant,
    right_lower_inner_quadrant,
    right_upper_outer_quadrant,
    right_upper_inner_quadrant
}