<template>
<svg width="74" height="134" viewBox="0 0 74 134" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M73.4993 13.4621C73.3026 5.43653 65.7802 -0.0829419 57.8488 1.15857C50.8449 2.25489 42.5836 3.26576 35.9534 3.2612C30.1188 3.25717 23.0149 2.46502 16.6306 1.5246C7.89946 0.238487 -0.182182 7.06387 0.185226 15.8815L4.53539 120.284C4.83613 127.501 10.7748 133.198 17.9986 133.198H38.9786C45.1287 133.198 50.4927 129.057 52.027 123.101C56.4076 106.097 65.2309 71.9214 69.8078 54.6972C73.3467 41.379 73.784 25.0799 73.4993 13.4621Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
