<template>
	<el-row class="select-container" ref="selectContainer">
		<template v-if="question.hasOwnProperty('options')">
			<el-col :span="12" v-for="option in question.options" :key="option.id"
					class="option" @click.native="select(option)">
				<span :class="[{ selected: isSelected(option) }]">{{ option.value }}</span>
			</el-col>
		</template>

		<el-col v-else :span="12" v-for="(option, index) in question.option" :key="option + index"
				class="option" @click.native="select(option)">
			<span :class="[{ selected: isSelected(option) }]">{{ option }}</span>
		</el-col>
		<el-col :span="12" v-show="question.other" class="option option-other">
			<input ref="other" :value="other" 
				@blur="blurOther"
				@input="other = $event.target.value" 
				v-on:keyup.13="selectOther">
			<button v-show="!otherFocus"
                 :class="[other.trim().length ? 'btn-active' : '']" @click="focusOther">
                <AddIcon></AddIcon>
                ADD
            </button>
		</el-col>
		<el-col span="18" v-if="maxSelect > 1" style="text-align: left;">
			<span>*Can select up to {{ maxSelect }} choices</span>
		</el-col>

	</el-row>
</template>


<script>
import Question from './index'
import AddIcon from './icon/Add.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'multi-questionnaire-select',
	extends: Question,

	data() {
		return {
			other: '',
			defaultLimit: 100,
            otherFocus: false
		}
	},
	watch: {
		other(value) {
			if (value.length > this.defaultLimit)
				this.other = this.other.substr(0, this.defaultLimit)
		}
	},
	mounted() {
		if (this.saved) {
			this.$emit('selected', this.saved)
			this.saved.forEach((selected) => {
				if (this.question.hasOwnProperty('options')) {
					if (this.question.options.filter(opt => opt.value === selected.value).length === 0) {
						let newObj = {
							id: uuidv4(),
							version: 2, // version indicating that this is qv2 format
							value: String(selected.value),

						}
						this.question.options.push(newObj)
					}
				}
				if (!this.question.option.includes(String(selected.value)))
					this.question.option.push(selected.value)
			})
		}
	},

	methods: {
		selectOther() {
			this.addOption()
			this.other = ''
            this.otherFocus = false
            this.$refs.other.blur()
			this.scrollToVisible()
		},
        focusOther() {
            this.otherFocus = true
            this.$refs.other.focus()
        },
		blurOther() {
			this.addOption()
			this.other = ''
			this.otherFocus = false
			this.scrollToVisible()
		},
		addOption() {
			this.other = this.other.trim()
			if (this.other !== '') {
				if (this.question.hasOwnProperty('options')) {
					if (this.question.options.filter(option => option.value === this.other).length)
					return
				}
				else if (this.question.option.filter(option => option === this.other).length)
					return

				let reqObj = {
					version: 2, // version indicating that this is qv2 format
					value: this.other,
					id: uuidv4()
				}
				

				if (this.question.hasOwnProperty('options'))
					this.question.options.push(reqObj)
				else 
					this.question.option.push(this.other)
				this.select(reqObj)
			}
		},
		scrollToVisible() {
			this.$nextTick(() => {
				const otherPos = this.$refs.other.getBoundingClientRect()
				const clientHeight = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight
				if(otherPos.bottom > (clientHeight - 80)) {
					this.$emit('scroll-into')
					//this.$refs.selectContainer
				}
			})
		},
	},

	computed: {
		charactersLeft() {
			return this.defaultLimit - this.other.length
		},
		maxSelect() {
			if(this.question.validations && Array.isArray(this.question.validations)) {
				const maxSelectObj = this.question.validations.find((validObj) => validObj.type === 'max_selection')
				if(maxSelectObj)
					return maxSelectObj.value
			}
			return 0
		}
	},

    components: {
        AddIcon
    }
}
</script>

<style lang="scss" scoped>
$choice-size: 50px;
$choice-font-size: 14px;

@import "style.scss";

$background: #eeeeee;
$choice-border: darken($background, 5%);
$background-hover: lighten($background, 5%);

.select-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
    width: 100%;
}
.option {
	flex-basis: 49%;
	margin-bottom: 10px;
	padding: 7px;
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #eeeeee;
		height: auto;
		min-height: 45px;
		font-size: $choice-font-size;
		border-radius: 30px;
		width: 95%;
		padding: 10px;
		cursor: pointer;
		white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
		white-space: -webkit-pre-wrap;          /* Chrome & Safari */
		white-space: -pre-wrap;                 /* Opera 4-6 */
		white-space: -o-pre-wrap;               /* Opera 7 */
		white-space: pre-wrap;                  /* CSS3 */
		word-wrap: break-word;                  /* Internet Explorer 5.5+ */
		word-break: break-word;
		white-space: normal;
	}
	.selected {
		background: $selected-background;
		border: 2px solid $selected-color;
		color: $selected-color;
		font-weight: 700;
	}
}
.option-other {
    position: relative;
	input {
		width: 95%;
		float: left;
		border: 1px solid #e2e2e2;
		outline: none;
		height: 40px;
		line-height: 40px;
		font-size: $choice-font-size;
		padding-left: 10px;
        text-align: center;
        min-height: 45px;
        border-radius: 30px;

	}
	input:focus {
		outline: none;
        border: 2px solid #833e93;
        background: #faf5fc;
        color: #833e93;
        font-weight: 700;
	}
	.btn-active {
		background-color: $selected-color;
		color: #ffffff;
	}
	button {
        z-index: 1;
        background-color: transparent;
		height: 45px;
		line-height: 45px;
		border: none;
		border-left: none;
		font-size: $choice-font-size;
        font-weight: 600;
		color: #333333;
		padding: 0;
        position: absolute;
        left: 0;
        width: 95%;
        text-align: center;
        svg {
            vertical-align: sub;
            margin-right: 5px;
            margin-bottom: -1px;
        }
	}
	button:focus {
		outline:0;
	}
	.character-count {
		float: right;
		color: #666666;
		font-size: 13px;
		margin-top: 7px;
	}
}
.select {
	position: relative;
	display: inline-block;
	margin-bottom: 40px;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: center;
}

.choice-other {
	vertical-align: top;
	position: relative;
	display: inline-block;
	background: $background;
	width: auto;
	height: auto;
	font-size: $choice-font-size;
	text-align: center;
	// margin-right: 15px;
	// border: 2px solid $choice-border;


	width: auto;
	height: auto;
	padding: 2.5px 2.5px;
	//padding: 0px 0px 0px 0px;
	border-radius: 10px;
	input {
		background: none;
		border: none;
		height: 29;//height: 30px;
		//line-height: 30px;
		font-size: 20px;
		width: auto;//100px;
		height: auto;
	}

	input:focus {
		outline: none;
	}
}
</style>
