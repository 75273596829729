import body_f from './body_f'
import body_b from './body_b'

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import groin from './groin'
import left_knee_f from './left_knee_f'
import right_knee_f from './right_knee_f'
import neck_f from './neck_f'
import right_jaw from './right_jaw'
import left_jaw from './left_jaw'
import right_axilla from './right_axilla'
import left_axilla from './left_axilla'
import right_elbow_crease from './right_elbow_crease'
import left_elbow_crease from './left_elbow_crease'

import neck_b from './neck_b'
import left_knee_b from './left_knee_b'
import right_knee_b from './right_knee_b'


export default {
    body_f,
    body_b,
    body_f_selected,
    body_b_selected,

    groin,
    left_knee_f,
    right_knee_f,
    neck_f,
    right_jaw,
    left_jaw,
    right_axilla,
    left_axilla,
    right_elbow_crease,
    left_elbow_crease,

    neck_b,
    left_knee_b,
    right_knee_b
}