<template>
<svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.07957 15.7061C2.07954 17.2047 10.7074 21.9901 16.0796 19.7071C21.4538 17.4233 24.9498 6.25562 24.0796 4.20798C22.9498 1.54936 12.9538 -1.07795 7.57958 1.20589C3.07958 3.11823 0.0795553 13.2054 1.07957 15.7061Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_elbow',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
