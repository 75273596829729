<template>
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M29.3429 0.666885H13.6734C13.6734 0.666885 14.5048 5.3526 10.2096 10.4288C5.91433 15.505 0.447659 14.724 0.447659 14.724V29.5621C0.447659 29.5621 10.2096 27.5373 18.4096 19.8002C26.6096 12.0631 29.3429 0.666885 29.3429 0.666885Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_lower_outer_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
