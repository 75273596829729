<template>
	<div v-if='loading'>
		<GenericLoading color="#a54eae" class="loading-section" text="Loading..."></GenericLoading>
	</div>
	<DownloadView v-else :type="'questionnaire'"></DownloadView>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DownloadView from '@/component/DownloadView'
import GenericLoading from '@/component/GenericLoading'

export default {
	name: 'questionnaire-complete',
	data() {
		return {
			loading: false
		}
	},
	props: {
		event: {
			type: Object
		},
		questionnaire: {
			type: Object
		},
		result: {
			type: Array
		},
		id: {
			type: String
		},
		completed: {
			type: Boolean
		}
	},
	mounted() {
		if (!this.completed && this.id) {
			this.loading = true
			this.patchQuestionnaire()
				.then(() => {
					this.loading = false
					// Final thank you page with note
					// if there are no pending events or this questionnaire is a survey questionnaire
					// then show thank you page with note.
					if ((this.getEvents.length === 0 || (this.event && this.event.provider_survey)) && this.questionnaire) {
						this.$router.push({
							name: 'questionnaire-thankyou', params: {
								id: this.id,
								title: this.questionnaire.title,
								account: this.$route.query.account,
								display_name: this.questionnaire.display_name || null,
								completed_header: this.questionnaire.completed_header || '',
								completed_subheader: this.questionnaire.completed_subheader || ''
							}
						})
					}
					// Thank you page with remaining questionaires
					// else show the pending questionnaires
					else if (this.getEvents.length && this.questionnaire) {
						this.$router.push({
							name: 'questionnaire-thankyou', params: {
								id: this.id,
								title: this.questionnaire.title,
								account: this.$route.query.account,
								display_name: this.questionnaire.display_name || null
							}
						})
					}
					return this.removeLocalStorage()
			})
			.catch(err => {
				this.loading = false
			})
		} else if (this.completed && this.getEvents.length) {
			this.$router.push({ name: 'questionnaire-thankyou', params: { id: this.id, completed: true } })
		} else
			this.removeLocalStorage()
	},
	methods: {
		...mapActions('user', [
			'addEvent',
			'removeEvent'
		]),
		patchQuestionnaire() {
			if(Array.isArray(this.result) && this.result.length === 0)
				throw 'Empty result pushed' + JSON.stringify(this.questionnaire)
			return this.addEvent({
				id: this.id,
				type: 'questionnaire',
				body: {
					completed: Date.now(),
					question: this.result
				}
			}).then(() => {
				localStorage.removeItem(this.id)
			}).catch((error) => {
				this.$emit('patchError', error)
				this.$message({
					showClose: true,
					message: 'Unable to send response. Error: You appear to be Offline.',
					type: 'error',
					duration: '0'
				})
				throw error
			})
		},
		removeLocalStorage() {
			// localStorage.removeItem('auth')
			localStorage.removeItem('handoff_token')
			localStorage.removeItem(this.id + '_result')
		}
	},
	computed: {
		...mapGetters('user', [
			'getEvents'
		])
	},
	components: {
		GenericLoading,
		DownloadView
	}
}
</script>


<style lang="scss" scoped>
//@import "../style.scss";
    .loading-section {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
	}
</style>
