<template>
	<small>
		This exercise was not intended to take the place of your discussion with your surgeon about the need for this procedure. If you have questions about your need for a procedure, do not hesitate to ask your surgeon or the care team about it. If you have questions about the procedure or subsequent follow-up, please discuss them with your surgeon or care team.
		<br><br>
		Exercises on DoctorPlan are intended only to provide general guidance. They do not provide medical advice. It is very important that you consult your doctor about your specific condition, diagnosis, procedures, treatment, and all other topics.
	</small>
</template>
<script>
export default { name: 'exercise-disclaimer' }
</script>
<style lang="scss" scoped>
small {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #5f5f5f;
}
</style>
