<template>
	<h1>
		{{ title }}
	</h1>
</template>
<script>
import Markdown from '.'

export default {
	name: 'markdown-heading',
	extends: Markdown,

	computed: {
		title() {
			var title = this.text, i = 0
			while (title.charAt(i) == '#')
				i++
			title = title.substring(i).trim()
			return title
		}
	}
}
</script>
<style lang="scss" scoped>
@import "../style/markdown.scss";
</style>
