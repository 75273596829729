<template>
	<!-- The login view transitions to the next view by expanding the content div to fit the element,
 		 by triggering it with the "success" class - see transition named "success" below -->
	<div v-bind:class="['auth', { authenticated: success, logo }]"
		 :style="form === 'VerifyProvider' ? 'background-color: #ffffff' : 
		 (isAccountGerd(query.account) ? 'background: linear-gradient(234.75deg, #438FE9 -7.23%, #1D457A 94.69%)' : 'background-image: linear-gradient(to right, #2d3e4f, #2c6ca3)')">

		<template v-if="form !== 'VerifyProvider'">
			<div v-if="query.account === 'livechair'" class="livechair-logo-container">
				<LivechairLogo class="livechair-logo"></LivechairLogo>
			</div>
			<div v-else-if="isAccountGerd(query.account)" class="livechair-logo-container">
				<GerdHelpLogo class="gerdhelp-logo"></GerdHelpLogo>
			</div>
			<!-- The DoctorPlan logo that appears above the login input -->
			<div v-else-if="query.account === 'doctorplan'">
				<Logo class="logo"></Logo>
				<span class="logo-text">DoctorPlan</span>
			</div>
		</template>

		<div v-if="form === 'VerifyProvider'"
			 ref="form"
			 v-bind:is="formType"
			 v-bind:key="formType"
			 v-bind:query="query"
			 @success="success = true"
			 class="verify-provider-container">
		</div>

		<div v-else-if="form === 'VerifyEmail'"
			 :class="[{ success }]"
				ref="form"
				:is="formType"
				:key="formType + 1"
				:query="query"
				@success="success = true">
		</div>

		
		<!-- The .form that has the username and password fields -->
		<transition v-else name="success">
			<div v-bind:class="['form', { success }]"
				ref="form"
				v-bind:is="formType"
				v-bind:key="formType"
				v-bind:query="query"
				@success="success = true">
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Logo from './Logo.vue'
import LivechairLogo from './LivechairLogo.vue'
import GerdHelpLogo from './GerdHelpLogo'
import isAccountGerd from '../util/is-account-gerd'

import Forms from './form/components'

export default {
	name: 'login',

	props: {
		form: {
			type: String,
			required: true
		},
		query: {
			type: Object
		},
		logout: {
			type: Boolean
		}
	},

	data() {
		return {
			active: false,
			logo: false,
			success: false
		}
	},

	methods: {
		isAccountGerd
	},

	computed: {
		...mapState('auth', [
			'authenticated'
		]),
		formType() {
			return this.form + 'Form'
		}
	},

	mounted() {
		this.active = true
		setTimeout(() => {
			this.logo = true
		}, 1000)

		if (this.logout) {
			this.$store.dispatch('auth/logout')
			.then(() => {
				this.$router.replace('/login')
			})
			.catch(() => {
				this.$router.replace('/login')
			})
		}
	},

	components: {
		Logo,
		LivechairLogo,
		GerdHelpLogo,
		...Forms
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";

.title-logo {
	position: fixed;
	left: 40%;
	top: 20%;
}
</style>
