<template>
    
    <svg width="36" height="63" viewBox="0 0 36 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1182 42.1166C6.26429 29.0049 1.20134 5.62705 0.433153 1.89462C0.370687 1.59112 0.540993 1.30538 0.833764 1.20388C1.62221 0.930547 3.1775 0.440175 4.3304 0.385224C5.94494 0.308271 8.2327 1.01553 9.03705 1.28463C9.223 1.34683 9.36618 1.49462 9.42745 1.68088L21.8071 39.3152C21.8226 39.3625 21.8436 39.4078 21.8697 39.4502L35.4042 61.4794C35.7797 62.0906 35.0562 62.7305 34.5105 62.265C29.7595 58.2117 20.1835 49.6177 15.1182 42.1166Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>