<template>
  <div id="app">
    	<router-view>
		</router-view>
        <Download :account="$route.query.account" v-if="download"></Download>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Download from './Download'
import isAccountGerd from '../util/is-account-gerd'

export default {

    /**
	 * @desc Uses the vue-meta library to add tags dynamically
	 * For Gerdhelp users, adds app id of gerdhelp app for safari to suggest in iOS devices
	 * @returns {{meta: Array}}
	 */
	metaInfo() {
		if (this.isAccountGerd(this.$route.query.account)) {
			let metaArray = []
			if (process.env.NODE_ENV === 'live' || process.env.NODE_ENV === 'production')
				metaArray.push({ name: 'apple-itunes-app', content: 'app-id=1568842316'})
			else if (process.env.NODE_ENV === 'dev')
				metaArray.push({ name: 'apple-itunes-app', content: 'app-id=1570407019'})

			return { meta: metaArray }
        }
        
        return { meta: [] }
	},

	computed: {
        ...mapState(['download'])
    },

    mounted() {
	    if (this.$route.query.account === 'livechair')
	        document.title = 'Patient - LiveChair'
        else if (this.isAccountGerd(this.$route.query.account))
	        document.title = 'Patient - GERDHelp'
        else
            document.title = 'Patient - DoctorPlan'
        // reset the height whenever the window's resized
        window.addEventListener("resize", this.resetHeight)
        // called to initially set the height.
        this.resetHeight()
    },

    methods: {
        resetHeight() {
            // reset the body height to that of the inner browser
            document.body.style.height = window.innerHeight + "px"
            // console.log('current height', window.innerHeight + "px")
        },
        isAccountGerd
    },

    components: {
        Download
    }
}
</script>

<style lang="scss">
@import "app.scss";
</style>
