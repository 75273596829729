<template>
    
    <svg width="14" height="33" viewBox="0 0 14 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M14 16.0137C14 24.846 13.0044 32.2865 7.03062 32.006C1.45518 32.006 0.0612403 24.846 0.0612403 16.0137C0.0612403 7.18136 -1.13345 -0.259224 7.03062 0.0213574C13.2036 0.0224118 14 7.18136 14 16.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>