<template>
	<div class="welcome">
		<img src="../image/welcome.png">
		<div v-bind:class="['message', { doctor }]">
			<div
				class="body">
				<span>Hi{{ name ? (' ' + name) : ' there!' }}</span>
			</div>
			<div class="body">
				<p>
					Your appointment {{ appointment.doctor ? 'with' : '' }}
					<strong>{{ appointment.doctor ? appointment.doctor.name : '' }}</strong>
					is coming up.
				</p>
			</div>
		</div>
		<div
			v-if="unauthenticatedFullUser"
			class="login">
				<p>Enter your password to include your recorded symptoms, vitals, and lifestyle data.</p>
				<div class="input-button">
					<input
						v-model="password"
						placeholder="Type your password"
						:autofocus="true"
						type="password"/>
					<button
						v-on:click="login"
						type="primary"
						class="buttonItem">
							Login
					</button>
				</div>
		</div>
		<Download v-if="unauthenticatedLightUser"></Download>
		<ButtonMain class="button"
			:buttonName="buttonName"
			:route="route">
		</ButtonMain>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import formatDate from '@/module/util/format-date'
import ButtonMain from '../../component/ButtonMain'
import Download from '../Download'

export default {
	name: 'appointment-planning',

	props: {
		summary: {
			type: Object
		},
		appointment: {
			type: Object
		},
		content: {
			type: Object
		},
		result: {
			type: Object
		}
	},

	mounted() {
	},

	data() {
		return {
			sidebar: false,
			password: '',
			buttonName: 'START',
			doctor: this.getDoctor(),
			route: { name: 'appointment-select' }
		}
	},
	methods: {
		...mapActions('auth', {
            postLogin: 'login'
		}),
        login() {
            return this.postLogin({
                email: this.query.email,
                password: this.password,
                device: '' + Math.random(),
                platform: 'Patient Web App'
            })
            .then((result) => {
				this.loadUser()
				return this.loadAppointment({ id: this.id })
            })
            .catch((error) => {
                this.passwordError = error.message || 'Incorrect password'
                throw error
            })
		},
		// Work in progress: tried centering block of text when name of patient is not given
		getDoctor() {
			if (this.appointment) {
				return false
			}
			return false
		}
	},
	computed: {
		...mapState('auth', [
			'authenticated',
			'handoff_token',
			'light'
		]),
		...mapGetters('user', [
			'getName'
		]),
		name() {
			if (this.getName)
				return this.getName.split(' ')[0]
			else
				return ''
		},
		unauthenticatedFullUser() {
			if (this.authenticated)
				return false
			return this.handoff_token == null || this.light === false
		},
		unauthenticatedLightUser() {
			if (this.authenticated)
				return false
			return this.light === true && this.email
		}
	},
	components: {
		ButtonMain,
		Download
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
