<template>
    
    <svg width="22" height="64" viewBox="0 0 22 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M5.47729 0.555404L12.7091 2.81533C14.8071 3.47096 15.9947 5.69503 15.4503 7.82461C13.961 13.6503 11.5186 24.9217 12.5 33C13.5483 41.6285 19.3633 53.6979 20.3559 55.7103C20.4533 55.9078 20.533 56.1056 20.5987 56.3158L21.3772 58.8069C22.1821 61.3826 20.2578 64 17.5592 64H16.448C14.9455 64 13.5739 63.165 12.9149 61.8148C10.6898 57.2562 5.64019 46.4927 3.5 38.5C0.640839 27.8222 0.335938 12.2366 0.394854 4.20846C0.41416 1.57788 2.96639 -0.229253 5.47729 0.555404Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>