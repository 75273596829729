<template>
    <div class="appointment topic">
		<router-view
			:appointment="appointment"
			:content="content"
			:summary="summary"
			:selected="selected"
			:result="result">
		</router-view>
    </div>
</template>

<script>
import Vue from 'vue'

import AppointmentView from '../mixin/appointment-view'
import formatDate from '@/module/util/format-date'

const KEY_MAP = {
	"symptom_question": 1,
	"vital_question": 2,
	"medication_question": 3,
	"lifestyle_question": 4,
	"activity_question": 5
}
const REVERSE_KEY_MAP = {
	1: "symptom_question",
	2: "vital_question",
	3: "medication_question",
	4: "lifestyle_question",
	5: "activity_question"
}

export default {
	props: {
		route: {
			type: Object
		}
	},
	mixins: [ AppointmentView ],

	data() {
		return {
			completed: {},
			selectedTopics: {},
			selectedQuestions: {},
			group: null,
			next: null,
			keys: null
		}
	},

	mounted() {
		this.getFromStorage()
		this.checkRoute()
        this.filterKeys()

		this.setMainTopicStructure()
		this.setMiscTopicStructure()
		this.goToNextTopic()
	},

	methods: {
        formatDate,
		getFromStorage() {
			//localStorage.clear()
			var state = localStorage.getItem(this.appointment.id + '_selected')
			if (state) {
				this.selected = JSON.parse(state)
			}
			var result = localStorage.getItem(this.appointment.id + '_result')
			if (result) {
				this.result = JSON.parse(result)
			}
		},
		// Check if need to be routed outside of main route (showing topic details)
		checkRoute() {
			var routeObject
			// Flag that signifies user chose no topics, so route to 'thanks' screen
			if (this.route)
				routeObject = { name: 'appointment_thanks' }
			// Go back to 'select' screen to choose topics
			else if (this.selected == null)
				routeObject = { name: 'appointment-select' }
			// No appointment topics were selected intentionally
			else if (Object.keys(this.selected).length == 0) {
				Vue.set(this.result, "none", true)
				localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
				routeObject = { name: 'appointment_noselection' }
			} 
			// Page was refreshed. Go to same page that was refreshed
			else if (this.result && this.result.current) {
				routeObject = { name: 'appointment_' + this.result.current }
			}
			if (routeObject)
				return this.$router.push(routeObject)
		},
		setTopic(name, previous) {
			if (this.content[name]) {
				Vue.set(this.result, name, {
					selectedQuestions: {},
					addedQuestions: {},
					previous: previous
				})
			}
		},
		setMainTopicStructure() {
			var object
			// Set structure of central state store
			for (var i = 0; i < this.keys.length; i++) {
				// If fresh start
				if (! this.result[this.keys[i]]) {
					object = {
						selectedQuestions: {},
						addedQuestions: {},
						next: (i+1 < this.keys.length) ? this.keys[i+1]: null,
						previous: (i > 0) ? this.keys[i-1] : null
					}
					Vue.set(this.result, this.keys[i], object)
				}
				// results data already exists from before, so only update ones that were newly selected or unselected
				else {
					Vue.set(this.result[this.keys[i]], 'next', (i+1 < this.keys.length) ? this.keys[i+1]: null)
					Vue.set(this.result[this.keys[i]], 'previous', (i > 0) ? this.keys[i-1] : null)
				}
			}
		},
		setMiscTopicStructure() {
			// Get reference to last topic chosen
			var lastTopic = this.keys[this.keys.length-1]
			if (! this.result["other_question"]) {
				// Set extra topics if existing in content object
				this.setTopic("coping_living_question", lastTopic)
				this.setTopic("cause_risk_question", "coping_living_question")
				this.setTopic("diagnosis_treatment_question", "cause_risk_question")

				// Pre-set key for 'other_question'
				Vue.set(this.result, "other_question", {
					addedQuestions: {},
					// Only set to diagnosis if coping question exists. If not, just set to last topic selected by user
					previous: (this.content["coping_living_question"]) ? "diagnosis_treatment_question" : lastTopic
				})
			}
			else {
				if (this.content["coping_living_question"]) {
					Vue.set(this.result, "coping_living_question", lastTopic)
					Vue.set(this.result["other_question"], 'previous', "diagnosis_treatment_question")
				}
				else {
					Vue.set(this.result["other_question"], 'previous', lastTopic)
				}
			}
		},
		goToNextTopic() {
			
			// Reference to the first topic screen to show
			var first = this.keys[0]

			Vue.set(this.result, "first", first)
			localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
			// Route to the next screen
			this.$router.replace({ name: 'appointment_' + ( this.result.current ? this.result.current : first )})
		},
		filterKeys() {
			// Remove all the unselected topics
			this.keys = Object.keys(this.selected)
			this.keys = this.keys.filter(key => this.selected[key])

			// Sort keys by order
			this.keys = this.keys
				.map((key) => {
					return KEY_MAP[key]
				})
				.sort((a, b) => a - b)
				.map((key) => {
					return REVERSE_KEY_MAP[key]
				})
			// All key values were false
			if (! this.keys.length) {
				Vue.set(this.result, "none", true)
				localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
				// No topics selected so go to 'no selection' page
				return this.$router.push({ name: 'appointment_noselection' })
			}
		}
	},
	computed: {
	}
}
</script>

<style lang="scss" scoped>

</style>
