export default {
    getId(state) {
        if (state.user)
            return state.user.id
    },

    getName(state) {
        if (state.user)
            return state.user.name
    },
    getEmail(state) {
        if (state.user)
            return state.user.email
    },
    getFitbit(state) {
        if (state.user)
            return state.user.fitbit
    },

    getUser(state) {
        return state.user
    },

    getPatchedSubQ(state) {
        return state.patchedSubQ
    },

    getContent(state) {
        return state.content
    },

    getContentLoadingState(state) {
        return state.loadingContent
    },

    getEvents(state) {
        return state.event
    },

    getEvent(state) {
        return (id) => state.event[state.eventIndex[id]]
    },

    getAppointment(state) {
        return (id) => state.appointment[id]
    },

    getOne(state) {
        return (id) => state.content
    },
    getProvider(state) {
        return state.hasOwnProperty('provider') ? state.provider : []
    },
    getEventType(state) {
        return (type) => state.event.filter((event) => event.type.startsWith(type))
    },
    getProgressPercent(state) {
        return state.uploadProgress ? state.uploadProgress : 69
    },
    getQueryQuestOptions(state) {
        return state.queryQuestOptions
    }
}
