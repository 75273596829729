<template>
	<div class="download" @click="hide">
		<div class="prompt">
			<h1>{{ text }}</h1>
			<div class="link">
				<a v-if="isAccountGerd(account)" href="https://apps.apple.com/in/app/gerdhelp/id1568842316">
 				   <img src="@/image/download/ios.png">
 			   </a>
				<a v-else-if="account === 'livechair'" href="https://apps.apple.com/in/app/live-chair-health/id1537755288">
 				   <img src="@/image/download/ios.png">
 			   </a>
			   <a v-else href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
 				   <img src="@/image/download/ios.png">
 			   </a>

 			   <a v-if="isAccountGerd(account)" href="https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US">
 				   <img src="@/image/download/android.png">
 			   </a>
				<a v-else-if="account === 'livechair'" href="https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient">
 				   <img src="@/image/download/android.png">
 			   </a>
				<a v-else href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
 				   <img src="@/image/download/android.png">
 			   </a>
		   </div>
		   <a @click="hide">{{ smallText }}</a>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex'
import isAccountGerd from '../module/util/is-account-gerd'


export default {
	name: 'download-prompt',
	props: {
		account: {
			type: String,
			default: 'doctorplan'
		},
		text: {
			type: String,
			default: "Download the DoctorPlan app to track your symptoms, vitals, lifestyle, and more."
		},
		smallText: {
			type: String,
			default: "or continue on the web"
		}
	},
	methods: {
		...mapActions(['promptDownload']),
		hide() {
			this.promptDownload(false)
		},
		isAccountGerd
	}
}
</script>
<style lang="scss">
.download {
	position: relative;

	.prompt {
		display: block;
		margin: 0 auto;
		width: 360px;
		height: 200px;
		margin-top: 15px;
		background: white;
		border-radius: 4px;

		text-align: center;

		h1 {
			font-size: 20px;
			color: #3f3f3f;
			text-align: center;
			padding: 5px 25px;
		}
		.link {
			a {
				margin-top: 15px;
				width: 40%;
				height: 40px;
				display: inline-block;

				img {
					width: 100%;
				}
			}
			a:first-child {
				width: 36%;
				margin-right: 2%;
			}
		}
		> a {
			display: block;
			margin-top: 10px;
			color: #6f6f6f;
			font-size: 14px;
		}
		> a:hover {
			cursor: pointer;
		}
	}
}
</style>
