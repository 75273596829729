<template>
    
    <svg width="47" height="177" viewBox="0 0 47 177" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M45.5001 95C27.5 119.5 45.1513 149.57 41.0001 165C38.5362 174.158 29.6886 176.5 26.5002 176.5C23.3117 176.5 15.464 169.659 13.0001 160.5C8.84889 145.07 13 116.306 13 86.5C13 60.8141 -2.21784 29.2583 1.00004 13.5C3.73243 0.119187 22.5692 1.00021 26.5002 1.00021C30.4311 1.00021 42.7677 0.119184 45.5001 13.5C48.718 29.2583 45.5001 69.3141 45.5001 95Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>