<template>
<svg width="42" height="31" viewBox="0 0 42 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M41.9715 0.666916H13.8572C13.8572 0.666916 13.4667 8.08596 9.1715 11.6003C4.87626 15.1145 0.58102 14.7241 0.58102 14.7241V29.9526C0.58102 29.9526 15.8045 31.899 27.6986 23.3352C39.5926 14.7715 41.9715 0.666916 41.9715 0.666916Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_lower_inner_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
