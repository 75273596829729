<template>
	<TopicTemplate 
		:name="name" 
		:topicImage="topicImage" 
		:title="title"
		:type="type"
		:conditions="getConditions"
		:appointment="appointment"
		:summary="summary"
		:content="content"
		:result="result"
		:selected="selected">
	</TopicTemplate>
</template>
<script>

import AppointmentView from '../../mixin/appointment-view'
import TopicTemplate from './template/TopicTemplate'

export default {
	name: 'appointment_activity_question',
	data() {
		return {
			name: 'activity_question',
			topicImage: 'activity.png',
			type: 'activity',
			title: 'Activites'
		}
	},
	mixins: [ AppointmentView ],
	mounted() {

	},
	computed: {
        getConditions() {
			if (this.summary) {
				var conditions = {}
				if (this.summary.exercise)
					conditions.exercise = this.summary.exercise
				if (this.summary.mindfulness)
					conditions.mindfulness = this.summary.mindfulness
				if (this.summary.yoga)
					conditions.yoga = this.summary.yoga
				return conditions
			}
        }
	},
	components: {
		TopicTemplate
	}
}
</script>

<style lang="scss" scoped>
</style>