<template>
<svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4451 6.3841C20.1501 7.97664 27.7573 9.57615 27.7573 9.57615V25.7965V57.5C27.7573 57.5 18 58 13.5 59C7.13604 60.4142 0 63.5 0 63.5C0 63.5 1.15939 43.8023 1.5 38C1.84695 32.0898 6.79871 16.8805 7.23699 10.9763C7.78007 3.66043 4.04494 0 4.04494 0C4.04494 0 11.4356 5.02696 15.4451 6.3841Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_flank_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
