<template>
    <svg width="26" height="112" viewBox="0 0 26 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.0625 53L0.262255 109.529C0.166917 110.947 1.542 112.007 2.9067 111.61C10.0172 109.54 15.2816 109.3 23.167 110.581C24.4093 110.782 25.5524 109.83 25.5416 108.571L25.0625 53V8.55877C25.0625 8.5196 25.0634 8.48606 25.0647 8.44691C25.0884 7.73031 25.1453 0 16.5625 0C8.05793 0 7.58977 7.58995 7.564 8.42556C7.56239 8.47787 7.56045 8.52612 7.55634 8.57829L4.0625 53Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'middle_finger_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>