<template>
	<div>
    	<svg baseProfile="full" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
			<path d="M 275 275 L 500 25 A 440 450 -10 1 1 500 975 L 50 975 C 25 975, 25 975, 25 900 L 25 550 L 275 275" fill="rgba(255,255,255,0)" :stroke="secondaryColor" stroke-width="50" stroke-dasharray="3500 3500" stroke-dashoffset="3500">
				<animate fill="freeze" attributeName="stroke-dashoffset" to="0" begin="900ms" dur="750ms"/>
				<animate fill="freeze" begin="1575ms" dur="200ms" attributeName="fill" :to="secondaryColor"/>
			</path>
			<rect x="485" y="485" width="30" height="30" rx="15" :fill="secondaryColor">
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="x" to="300" values="485; 280; 300" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="y" to="425" values="485; 415; 425" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="width" to="400" values="30; 440; 400" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="height" to="150" values="30; 170; 150" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="650ms" dur="250ms" attributeName="x" to="40" keyTimes="0; 1" keySplines="0 0.6 0.6 1; 0 0 0 1"/>
				<animate fill="freeze" begin="650ms" dur="250ms" attributeName="y" to="175" keyTimes="0; 1" keySplines="0 0.6 0.6 1; 0 0 0 1"/>
			</rect>
			<rect x="485" y="485" width="30" height="30" rx="15" :fill="secondaryColor">
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="x" to="430" values="485; 420; 430" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="y" to="280" values="485; 260; 280" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="width" to="140" values="30; 160; 140" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="100ms" dur="350ms" attributeName="height" to="440" values="30; 480; 440" keyTimes="0; 0.6; 1" keySplines="0 0.6 0.6 1; 0 0 0 1; 0 0.6 0.6 1"/>
				<animate fill="freeze" begin="650ms" dur="250ms" attributeName="x" to="175" keyTimes="0; 1" keySplines="0 0.6 0.6 1; 0 0 0 1"/>
				<animate fill="freeze" begin="650ms" dur="250ms" attributeName="y" to="30" keyTimes="0; 1" keySplines="0 0.6 0.6 1; 0 0 0 1"/>
			</rect>
			<polygon :fill="secondaryColor" points="430,600 430,600 430,670 430,670">
				<animate fill="freeze" begin="650ms" dur="250ms" attributeName="points" to="175,350 332,175 400,175 175,420"/>
				<animate fill="freeze" begin="1575ms" dur="100ms" attributeName="fill" to="rgba(255,255,255,0)"/>
			</polygon>
			<path d="M 175 350 L 332 175 L 400 175 C 440 175, 440 175, 440 215 L 440 285 C 440 325, 440 325, 400 325 L 315 325 L 315 430 C 315 470, 315 470, 275 470 L 215 470 C 175 470, 175 470, 175 430" :fill="primaryColor">
				<animate fill="freeze" begin="1575ms" dur="100ms" attributeName="fill" :to="primaryColor"/>
			</path>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'logo-icon',
	props: {
		primaryColor: {
			type: String,
			default: '#365475'
		},
		secondaryColor: {
			type: String,
			default: '#ffffff'
		},
	}
}
</script>

<style scoped lang="scss">
svg {
	width: 100%;
	height: 100%;
}
</style>
