<template>
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="13.8003" cy="13.667" r="11.7143" :fill="color" :stroke="color === '#833E93' ? '#5F2C6A' : '#B2B2B2'" stroke-width="2.34286" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
	name: 'left_areola',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
