<template>
	<div class="exercise">
		<Header></Header>

		<main v-if="exercise">
			<h1>{{ exercise.title }}</h1>
			<p class="author">By {{ exercise.author }} &middot; {{ exercise.length }} minute duration</p>
			<div class="video">
				<video width="100%" height="auto" controls>
					<source :src="exercise.video" type="video/mp4">
				</video>
			</div>
			<hr>
			<Disclaimer></Disclaimer>
			<hr>
			<ExtendDownload :type="'exercise'"></ExtendDownload>
			<Copyright></Copyright>
		</main>
		<template v-else>
			<Loading text="Loading exercise..."></Loading>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Loading from '@/component/Loading'
import Header from './Header'
import Disclaimer from './Disclaimer'
import Copyright from '../home/copyright/Copyright'
import ExtendDownload from '@/component/ExtendDownload'

export default {
	name: 'exercise-viewer',

	props: {
		id: {
			type: String,
			required: true
		},
		category: {
			type: String
		},
		query: {
			type: Object
		}
	},

	data() {
		return {
			missing: false
		}
	},

	mounted() {
		if (! this.exercise)
			this.logout()
			.then(() => {
				return this.handoff(this.query)
			})
			.then(() => {
				return this.loadExercise()
			})
			.then(() => {
				if (! this.exercise)
					this.missing = true
				return
			})
	},

	methods: {
		...mapActions('content', [
			'loadObject'
		]),
		...mapActions('auth', [
			'handoff',
			'logout'
		]),
		loadExercise() {
			return this.loadObject({ type: 'exercise', id: this.id })
		}
	},

	computed: {
		...mapGetters('content', [
			'getObject'
		]),
		exercise() {
			return this.getObject('exercise', this.id)
		}
	},

	components: {
		Header,
		Loading,
		Disclaimer,
		Copyright,
		ExtendDownload
	}
}
</script>

<style lang="scss" scoped>
//@import "style/style.scss";
@import "style/header.scss";
@import "style/viewer.scss";
</style>
