<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14">
		<g fill="none" fill-rule="evenodd">
			<path fill="#833E93" d="M10.766.379c-.286-.405-.89-.491-1.336-.231-.445.26-.54.809-.255 1.213.032.058.096.116.128.145l5.184 5.46-5.184 5.546c-.382.318-.414.866-.032 1.213a.988.988 0 0 0 1.336.029.518.518 0 0 0 .127-.144L17 6.936 10.766.38z"/>
			<path stroke="#833E93" stroke-linecap="round" stroke-width="1.5" d="M15 7H1"/>
		</g>
	</svg>

</template>
<script>
export default {
	name: 'questionnaire-startArrow-icon',

}
</script>
