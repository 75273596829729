<template>
    
    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M18.3678 0.619097L5.98192 4.21533C5.36526 4.39438 4.87208 4.85862 4.65611 5.46334L0.16637 18.0346C0.0571027 18.3405 0.0240102 18.6685 0.0699543 18.9901L0.873955 24.6181C0.94579 25.121 1.38318 25.4901 1.89094 25.4763L18.5123 25.0267C19.1348 25.0099 19.7134 24.7054 20.0307 24.1695C20.8791 22.7364 22.4512 19.6037 22.5 16C22.5449 12.686 21.4672 5.73301 20.8757 2.18445C20.6805 1.01366 19.5076 0.288135 18.3678 0.619097Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>