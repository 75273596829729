<template>
    <svg width="32" height="97" viewBox="0 0 32 97" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5625 63.5L0.0743006 88.6542C-0.192012 89.8748 0.716508 91.0362 1.96412 91.1014C11.4272 91.5961 16.3081 93.0916 24.3955 96.7668C25.6792 97.3502 27.1592 96.477 27.2486 95.0697L29.0625 66.5V32.5L31.0283 5.96117C31.0508 5.65773 31.0058 5.35452 30.8592 5.08791C30.201 3.89116 27.8273 0.5 22.0625 0.5C15.3708 0.5 14.1622 5.06931 14.0687 5.47176C14.0639 5.4927 14.0607 5.5106 14.0572 5.53181L9.5625 32.5L5.5625 63.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'index_finger_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>

