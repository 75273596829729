<template>
<svg width="42" height="67" viewBox="0 0 42 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.7047 17.8243L0.432278 0.0400391L1.34429 37.4326C1.34429 37.4326 3.91405 46.5178 6.36037 52.0248C9.01469 58.0001 14.5685 66.617 14.5685 66.617C14.5685 66.617 18.0865 53.2777 23.5796 50.7061C29.8779 47.7575 41.4729 52.0248 41.4729 52.0248C41.4729 52.0248 39.0815 40.6903 36.4568 33.7845C33.995 27.3074 28.7047 17.8243 28.7047 17.8243Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_upper_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
