<template>
	<svg width="43" height="131" viewBox="0 0 43 131" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M0.671936 5.41554C0.671936 5.41554 8.78597 23.1707 21.6559 23.5C37.9018 23.9173 42.1686 0.855469 42.1686 0.855469C41.2841 20.6091 42.1686 44.6321 42.1686 67.8885C42.1686 88.4088 39.1665 112.681 35.7844 130.361C35.7844 130.361 31.144 125.345 24.8402 125.345C18.5365 125.345 12.0721 130.361 12.0721 130.361C10.7041 125.345 12.5281 110.753 8.42397 93.4249C4.31981 76.0966 8.42397 30.9519 0.671936 5.41554Z" :fill="color"/>
	</svg>
</template>
<script>
export default {
	name: 'left_lower_leg_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
