<template>
<svg width="312" height="180" viewBox="0 0 312 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M80.2476 99.181L79.8571 78.8762L48.2285 185.867H-1.75244C-1.75244 185.867 9.88277 131.863 20.5047 96.0571C27.9279 71.0342 33.3757 46.6667 46 33C62.0095 15.6686 131.4 39.4381 134.914 17.181C136.508 7.08911 134.914 -8.98096 134.914 -8.98096H184.505C184.505 -8.98096 183.416 7.02223 184.505 17.181C186.848 39.0476 259.476 12.6859 273.143 33C278.134 40.419 281.536 53.1258 285.248 67.9429C289.822 86.2042 296.571 114.8 296.571 114.8L314.533 188.21H273.143L261.819 150.333L238.39 78.8762L238 100.743C238 100.743 245.078 121.336 243 134.5C240.5 150.333 230.5 158.5 230.5 158.5C230.002 171.452 232.924 185.867 232.924 185.867H85.3238C85.3238 185.867 88.4692 170.964 88.0571 158.924C88.0571 158.924 77.5 150.5 74.5 133C71.5 115.5 80.2476 99.181 80.2476 99.181Z" fill="#FAF5FC" stroke="#833E93" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M151.49 133.152C151.474 144.315 145.962 154.948 137.042 161.657C128.121 168.367 116.203 169.992 105.238 167.905C98.4906 166.62 92.6903 163.238 87.9999 159M84.3278 89.8096C84.3278 89.8096 82.594 93.2734 80.5471 98.5" stroke="#833E93" stroke-width="1.5619" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M166.152 133.152C166.167 144.315 171.679 154.948 180.6 161.657C189.521 168.367 200.873 170.773 211.838 168.686C219.156 167.293 225.327 163.531 230.5 158.5M233.314 89.8096C233.314 89.8096 235.499 94.1754 237.86 100.5" stroke="#833E93" stroke-width="1.5619" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M166.152 47.2476C167.584 44.5143 174.977 39.0476 193.095 39.0476" stroke="#833E93" stroke-width="1.5619" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M149.362 47.2476C147.93 44.5143 140.537 39.0476 122.419 39.0476" stroke="#833E93" stroke-width="1.5619" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
	name: 'body_selected',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
