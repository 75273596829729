<template>
    <svg width="150" height="133" viewBox="0 0 150 133" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.70906 46.3213C4.45311 33.221 12.7812 17.3589 18.3591 7.73125C20.7294 3.64012 21.9145 1.59456 24.0328 0.677362C26.1511 -0.239837 28.6342 0.337497 33.6004 1.49217C43.9199 3.89154 60.3452 7.15885 73.2693 7.15885C86.6263 7.15885 103.723 3.66908 113.954 1.25422C118.443 0.194686 120.688 -0.335082 122.682 0.413871C124.677 1.16283 125.909 2.88591 128.373 6.33207C135.035 15.6512 145.722 32.2225 148.694 46.3213C153.07 67.0815 145.379 100.957 141.376 116.214C140.39 119.974 139.897 121.853 138.422 123.234C136.947 124.614 135.013 124.987 131.146 125.731C118.328 128.199 92.7201 132.592 73.2693 132.5C53.9801 132.409 28.671 128.276 15.4044 125.843C11.0926 125.052 8.93662 124.657 7.38684 123.086C5.83706 121.515 5.47366 119.372 4.74687 115.084C2.07129 99.3008 -2.50468 66.438 1.70906 46.3213Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'upper_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>