<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 50 50">
        <defs>
            <circle id="b" cx="19" cy="19" r="19"/>
            <filter id="a" width="152.6%" height="152.6%" x="-26.3%" y="-21.1%" filterUnits="objectBoundingBox">
                <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1"/>
                <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(6 4)">
                <use fill="#000" filter="url(#a)" xlink:href="#b"/>
                <use fill="#FFF" xlink:href="#b"/>
            </g>
            <path stroke="#666" stroke-linecap="round" stroke-width="2" d="M32 23.667H18"/>
        </g>
    </svg>
</template>
<script>
export default { name: 'icon-minus' }
</script>
