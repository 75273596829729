<template>
	<svg xmlns="http://www.w3.org/2000/svg" style="margin: auto; background: #eeeeee; display: block; shape-rendering: auto;" width="70px" height="70px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
		<circle cx="50" cy="50" fill="none" stroke="#2d3e4f" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(35.8268 50 50)">
			<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
		</circle>
	</svg>
</template>
<script>
export default { 
	name: 'auth-form-check-icon',
	props: {
		type: {
			type: Boolean
		}
	},
	computed: {
		getColor() {
			return this.type ? '#da4e4e' : '#44b358'
		}
	}
}
</script>
