<template>
	<div class="appointment noselection">
        <div class="title">
            <span class="span-title">Uh Oh!</span>
        </div>
		<div class="image">
			<img src="../../image/zero.png">
		</div>
		<div class="message">
            <p>It seems like you haven't chosen any questions to discuss with your doctor.</p>
            <p>Preparing for your doctor's visit is a vital step in partnering with your doctor and becoming an active participant in your own health care.</p>
            <p>Prioritizing your concerns and discussion topics is critical to utilizing the appointment time effectively.</p>
		</div>
		<div class="button">
            <router-link 
                :to="{ name: getRouteName() }">
                    {{ getButtonText() }}
            </router-link>
        </div>
        <div class="link">
            <router-link 
                :to="getRedirect()">
                    I don't have any questions to discuss
            </router-link>
        </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDate from '@/module/util/format-date'

export default {
	name: 'appointment_noselection',

	props: {
		summary: {
			type: Object
		},
		appointment: {
			type: Object
		},
		content: {
			type: Object
        },
        result: {
            type: Object
        }
	},
	data() {
		return {
			sidebar: false,
			route: false
		}
	},
	mounted() {
		this.getFromStorage()
	},
    methods: {
		getFromStorage() {
			var result = localStorage.getItem(this.appointment.id + '_result')
			if (result) {
				this.result = JSON.parse(result)
				if (this.result.none) {
					this.route = true
					this.result.none = false
				}
				localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
			}
		},
		getRouteName() {
			if (this.route)
				return 'appointment-select'
			return 'appointment_' + this.result.first
		},
		getRedirect() {
			return { name: 'appointment_thanks' }
		},
		getButtonText() {
			if (this.route)
				return "SELECT TOPICS"
			return "SELECT QUESTIONS"
		}
	}
}
</script>

<style lang="scss" scoped>
//@import "./style.scss";
.appointment.noselection {
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: 700;
        margin-top: 5%;
    }
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            border: #2D3E4F;
            background-color: #2D3E4F;
            color: white;
            text-decoration: none;
            text-align: center;
            width: 100%;
            height: 100%;
            margin-left: 10%;
            margin-right: 10%;
            padding: 2%;
            font-size: 18px;
            margin-top: 5%;
        }
    }
    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
        img {
            width: 50%;
            height: 50%;
        }
    }
    .message {
        display: flex;
        align-items: left;
        justify-content: flex-start;
        flex-direction: column;
        margin-left: 12%;

        p {
            display: flex;
            align-items: left;
            justify-content: flex-start;
            font-size: 16px;
            margin-top: 5%;
            width: 90%;
        }
    }
}

@media only screen and (min-width: 600px) {
    .appointment.noselection {
        .title {
            font-size: 40pt;
        }
        .link {
            font-size: 18pt;
            margin-bottom: 10%;
        }
        .message {
            p {
                font-size: 18pt;
            }
        }
    }
}
</style>
