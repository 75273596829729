<template>
<svg width="29" height="72" viewBox="0 0 29 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M28.472 13.3633C29.2862 6.24219 23.7171 0 16.5497 0H12C5.37258 0 0 5.37258 0 12V58.8114C0 63.7503 3.02602 68.1848 7.62498 69.9854L9.16553 70.5886C17.0359 73.6701 25.5405 67.8667 25.5405 59.4146V39L28.472 13.3633Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_side_flank',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
