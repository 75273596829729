<template>
    <svg width="64" height="72" viewBox="0 0 64 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M63.9844 56.6161V46.6542C63.9844 45.8173 63.9844 45.3989 63.9227 44.9373C63.861 44.4758 63.7639 44.1189 63.5696 43.4051L63.5696 43.4051C62.4492 39.2879 59.13 28.2913 54.4844 23.5C48.1953 17.0136 8.27367 2.03579 5.63687 1.05104C5.54139 1.01538 5.45094 0.985984 5.35294 0.957984C1.82297 -0.0505809 -1.04597 3.89227 0.999706 6.94074L20.5 36L35.069 63.666C37.435 68.1588 38.618 70.4053 40.7366 71.4036C42.8552 72.4019 45.3408 71.8841 50.3118 70.8485L52.8397 70.3218C58.1776 69.2097 60.8466 68.6537 62.4155 66.7243C63.9844 64.7948 63.9844 62.0686 63.9844 56.6161Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_shoulder',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>