<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="49" height="39" viewBox="0 0 49 39">
        <g fill="none" fill-rule="evenodd" :stroke="(select) ? '#833E93' : '#999'" stroke-linecap="round" stroke-width="3">
            <path d="M1.5 25.5l11.314 11.314M13.193 36.463L46.936 2.325"/>
        </g>
    </svg>
</template>
<script>
export default {
	name: 'questionnaire-yes-icon',

	props: {
		select: {
			type: Boolean
		}
	}
}
</script>
