<template>
<svg width="23" height="47" viewBox="0 0 23 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.29459 22.3296C0.600454 35.1972 -2.7549 45.3676 8.15008 46.8033C19.0551 48.239 18.4464 37.5468 20.1406 24.6791C21.8347 11.8115 25.686 1.70644 14.2851 0.205421C2.88348 -1.2957 3.98872 9.46192 2.29459 22.3296Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_arm_pit',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
