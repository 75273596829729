<template>
    <svg width="53" height="24" viewBox="0 0 53 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M27 23.5C33.5993 23.4673 42.0012 20.5929 47.6658 18.2992C48.6623 17.8957 49.1606 17.694 49.7091 17.3421C51.6678 16.0855 53.0023 13.5451 52.9255 11.2193C52.904 10.5679 52.7682 9.95692 52.4966 8.7349C51.9668 6.35045 51.7018 5.15823 51.368 4.4512C49.9733 1.49743 47.5261 0.127029 44.2789 0.481308C43.5016 0.566112 42.1364 1.03549 39.406 1.97424C35.3766 3.35956 30.8359 4.5237 27 4.5C23.2152 4.47662 18.7375 3.28829 14.7771 1.89726C11.9695 0.911097 10.5656 0.418015 9.72562 0.335613C6.70141 0.0389442 4.42215 1.22856 2.93622 3.87919C2.52349 4.61543 2.18428 5.8366 1.50585 8.27893C1.11129 9.69935 0.914012 10.4096 0.871538 11.1623C0.74039 13.4865 2.02203 16.0658 3.95366 17.365C4.57924 17.7858 5.17434 18.0218 6.36453 18.4937L6.36455 18.4937C12.127 20.7788 20.4448 23.5325 27 23.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'neck_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>