<template>
	<div class="questionnaire landing">
		<Header :landing="true"></Header>

		<el-row>
			<el-col :xs="24" :sm="{ span: 14, offset: 5 }" :md="{ span: 10, offset: 7 }" style="height: calc(100% - 70px)">
				<!-- Show all unfinished questionnaires -->
				<template v-if="unfinishedQuestionnaires.length > 0">
					<Questionnaire
							v-for="questionnaire in unfinishedQuestionnaires"
							:key="questionnaire.id"
							:questionnaire="questionnaire"
							:content="questionnaireContent(questionnaire.type)">
					</Questionnaire>
				</template>

				<!-- If the events are still loading -->
				<Loading v-else-if="loadingEvents"
						 color="#a54eae"
						 text="Loading questionnaires...">
				</Loading>

				<!-- Show empty state -->
				<Empty v-else-if="unfinishedQuestionnaires.length === 0"></Empty>

			</el-col>
		</el-row>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import formatDate from '@/module/util/format-date'

import Loading from '@/component/Loading'
import Empty from './Empty'
import Questionnaire from './Questionnaire'

import Header from '../header/Header'
import NoneIcon from './icon/None'
import StartArrowIcon from './icon/StartArrow'

export default {
	name: 'questionnaire-landing',

	mounted() {
        this.loadEvents({ type: 'questionnaire' })
	},

	data() {
		return {
			sidebar: false
		}
	},

	methods: {
		formatDate,
		...mapActions('user', [
			'loadEvents'
        ])
	},

	computed: {
		...mapState('user', [
			'loadingEvents'
		]),
		...mapGetters('user', [
			'getEventType'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		questionnaires() {
			return this.getEventType('questionnaire')
		},
		unfinishedQuestionnaires() {
			if (this.questionnaires) {
				return this.questionnaires.filter((q) => (! q.completed)).sort((a, b) => (b.requested - a.requested))
			}
		},
		questionnaireContent() {
			return (type) => {
				console.log('questionnaireContent type', type)
                let id = type.substring(type.indexOf(':') + 1)
				return this.getObject('questionnaire', id)
			}
        }
	},

	components: {
		Header,
		Empty,
		Loading,
		Questionnaire,
		StartArrowIcon,
		NoneIcon
	}
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
@import "./style.scss";
</style>
