<template>
    
    <svg width="37" height="17" viewBox="0 0 37 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M18.4178 16.4697C22.8938 16.4475 28.5925 14.498 32.4346 12.9423L32.4346 12.9423C33.1102 12.6687 33.448 12.5319 33.8199 12.2934C35.1488 11.441 36.0542 9.71749 36.002 8.13955C35.9873 7.69793 35.8953 7.28367 35.7112 6.45515C35.3519 4.8383 35.1722 4.02988 34.9459 3.55047C33.9999 1.54644 32.3396 0.616684 30.1366 0.857248C29.6096 0.914797 28.6838 1.23309 26.8323 1.86966C24.0993 2.80927 21.0195 3.59886 18.4178 3.58279C15.8507 3.56693 12.8137 2.76093 10.1275 1.81745C8.22357 1.14871 7.27159 0.814345 6.70204 0.758418C4.65031 0.556953 3.10392 1.36406 2.09598 3.16245C1.81617 3.66168 1.58615 4.48975 1.12612 6.14589L1.12611 6.1459C0.858596 7.10896 0.724837 7.59049 0.695995 8.10088C0.606886 9.67771 1.47641 11.4276 2.78697 12.3089C3.21118 12.5942 3.61465 12.7542 4.42158 13.0742C8.33005 14.6241 13.9717 16.4918 18.4178 16.4697Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'neck_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>