<template>
    <svg width="41" height="106" viewBox="0 0 41 106" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M40.7286 46.7511L36.6349 12.8878C36.1423 8.81279 35.8959 6.7753 34.7625 5.27155C34.4485 4.85485 34.0888 4.47458 33.6902 4.13781C32.2518 2.92253 30.2311 2.5633 26.1899 1.84484C20.623 0.855147 17.8395 0.360303 15.7002 1.50536C15.1156 1.81822 14.5785 2.21255 14.1048 2.67651C12.3714 4.37458 12.0097 7.17843 11.2861 12.7861L7.18765 44.549C7.09535 45.2643 7.0492 45.622 6.97346 45.9527C6.89771 46.2833 6.77549 46.6497 6.53104 47.3824C5.28968 51.1031 1.95046 61.5513 1.00042 69C0.357051 74.0443 0.426666 80.7137 0.616667 85.6585C0.740883 88.8912 0.802992 90.5075 1.63989 91.987C2.4768 93.4664 3.89677 94.3958 6.73672 96.2547L11.226 99.1931C19.0541 104.317 22.9681 106.879 26.3928 105.45C29.8174 104.02 30.7492 99.4362 32.6127 90.2678L40.5492 51.2199L40.5492 51.2198C40.7743 50.1123 40.8869 49.5585 40.9095 48.9963C40.932 48.4342 40.8642 47.8731 40.7286 46.7511Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_upper_arm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>