<template>
<svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M40.9954 18.8648C40.9954 22.7335 41.2036 26.9507 38.8091 29.4094C35.6038 32.7006 25.754 29.4094 20.2244 29.4094C15.2898 29.4094 7.55281 32.6194 4.37275 29.9191C1.32732 27.3331 0 22.7473 0 18.8648C0 14.3267 2.01385 7.68145 6.01267 5.04783C9.0072 3.07564 16.0879 0 20.2244 0C24.3609 0 31.4417 3.07466 34.4362 5.04686C38.4351 7.68048 40.9954 14.3267 40.9954 18.8648Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'neck',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
