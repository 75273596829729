<template>
	<section>
		<router-link class="header" to="/exercise">
			<ExerciseIcon class="icon"></ExerciseIcon>
			<span style="color:#2d3e4f">EXERCISE</span>
			<ArrowIcon class="arrow"></ArrowIcon>
		</router-link>
		<main>
			<router-link class="preview" :to="{ name: 'exercise-viewer', params: { id: firstExercise.id } }">
				<img :src="firstExercise.image">
				<h5>{{ firstExercise.title }}</h5>
				<p>By {{ firstExercise.author }} &middot; {{ firstExercise.length }} min duration</p>
			</router-link>
		</main>
		<aside>
			<!-- <router-link v-for="article in firstCategory.article"
				:key="article.id"
				class="preview"
				:to="{ name: 'article-viewer', params: {
					id: article.id,
					category: firstCategory.id
				} }">
				<img :src="article.image">
				<h5>{{ article.title }}</h5>
				<p>By {{ article.author }} &middot; {{ article.length }} min read</p>
			</router-link> -->
			<!-- <router-link class="preview" to="/article/baretts">
				<img src="https://cdn.doctorbox.com/article/barrett-s-esophagus/thumbnail-overview-barrett_s-esophagus.png">
				<h5>Barett's Esophagus</h5>
				<p>By DoctorPlan Staff &middot; 3 min read</p>
			</router-link> -->
		</aside>
	</section>
</template>

<script>
import ExerciseIcon from './icon/Exercise'
import ArrowIcon from './icon/Arrow'

export default {
	name: 'home-exercise-section',

	props: {
		exercise: {
			type: Array
		}
	},

	computed: {
		firstExercise() {
			return this.exercise[0].exercise[0]
		},
		firstCategory() {
			return this.exercise[0]
		}
	},

	components: {
		ExerciseIcon,
		ArrowIcon
	}
}
</script>

<style lang="scss" scoped>
@import "../section.scss";
@import "style.scss";
</style>
