<template>
    
    <svg width="22" height="103" viewBox="0 0 22 103" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4941 59.5879V100.058C21.4941 101.47 20.0687 102.434 18.7459 101.94C12.7299 99.6937 8.96683 98.8847 2.49996 98.5457C1.39505 98.4878 0.528782 97.5602 0.571447 96.4546L1.99414 59.5879L2.49414 19.7084V8.90132C2.49414 7.07474 3.09889 5.27333 4.47816 4.07584C6.25815 2.53042 9.03881 0.701827 11.9941 1.00001C15.2558 1.3291 17.6663 3.78248 18.9483 5.44867C19.7 6.42578 20.0194 7.64211 20.0678 8.874L20.4941 19.7084L21.4941 59.5879Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'ring_finger_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>