<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <path id="4d15r3g2ha" d="M0 0.375L23.341 0.375 23.341 19.765 0 19.765z"/>
            <path id="r6ukeyhz7c" d="M0 21.885L23.341 21.885 23.341 0 0 0z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g transform="translate(-45 -185) translate(45 185)">
                    <g transform="translate(0 2.12)">
                        <mask id="uv6t25woxb" fill="#fff">
                            <use xlink:href="#4d15r3g2ha"/>
                        </mask>
                        <path fill="#833E93" d="M3.145 1.787c-.956 0-1.733.777-1.733 1.733v13.1c0 .955.777 1.733 1.733 1.733h17.051c.956 0 1.733-.778 1.733-1.734V3.52c0-.956-.777-1.733-1.733-1.733H3.145zm17.051 17.978H3.145C1.41 19.765 0 18.354 0 16.619V3.52C0 1.786 1.411.375 3.145.375h17.051c1.734 0 3.145 1.41 3.145 3.145v13.1c0 1.734-1.411 3.145-3.145 3.145z" mask="url(#uv6t25woxb)"/>
                    </g>
                    <mask id="kbh1bmzprd" fill="#fff">
                        <use xlink:href="#r6ukeyhz7c"/>
                    </mask>
                    <path fill="#833E93" d="M.817 9.492L22.746 9.492 22.746 8.081.817 8.081zM9.235 5.03c-.39 0-.706-.316-.706-.705V.705c0-.389.316-.705.706-.705.39 0 .706.316.706.706v3.619c0 .39-.316.706-.706.706M14.106 5.03c-.39 0-.706-.316-.706-.705V.705c0-.389.316-.705.706-.705.39 0 .706.316.706.706v3.619c0 .39-.316.706-.706.706M8.024 12.92c0 .562-.456 1.018-1.019 1.018-.562 0-1.019-.456-1.019-1.018 0-.563.457-1.019 1.02-1.019.562 0 1.018.456 1.018 1.019M12.631 12.92c0 .562-.456 1.018-1.017 1.018-.563 0-1.02-.456-1.02-1.018 0-.563.457-1.019 1.02-1.019.561 0 1.017.456 1.017 1.019M17.101 12.92c0 .562-.456 1.018-1.018 1.018-.563 0-1.019-.456-1.019-1.018 0-.563.456-1.019 1.019-1.019.562 0 1.018.456 1.018 1.019M8.024 16.398c0 .563-.456 1.02-1.019 1.02-.562 0-1.019-.457-1.019-1.02 0-.562.457-1.018 1.02-1.018.562 0 1.018.456 1.018 1.018M12.631 16.398c0 .563-.456 1.02-1.017 1.02-.563 0-1.02-.457-1.02-1.02 0-.562.457-1.018 1.02-1.018.561 0 1.017.456 1.017 1.018M17.101 16.398c0 .563-.456 1.02-1.018 1.02-.563 0-1.019-.457-1.019-1.02 0-.562.456-1.018 1.019-1.018.562 0 1.018.456 1.018 1.018" mask="url(#kbh1bmzprd)"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
	name: 'calendar-icon'
}
</script>
