<template>
	<div>
		<div class="symptom landing">
			<Header :landing="true"></Header>
			<el-row>
				<el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 8, offset: 8 }">
					<div class="search">
						<i class="el-icon-search"></i>
						<input class="search-input" placeholder="Search for a symptom here" v-model="query">
					</div>
					<template v-if="symptoms">
						<p class="header-text">CARDINAL SYMPTOMS</p>
						<div class="symptoms">
							<router-link v-for="symptom in symptoms"
										 :key="symptom.id"
										 class="symptom"
										 :to="'/symptom/' + symptom.id + '/question/1'">
								<img :src="symptom.image">
								<span class="symptom-title">{{ symptom.title }}</span>
							</router-link>
						</div>
					</template>
					<template v-else>
						<Loading color="#e28816" text="Loading symptoms..."></Loading>
					</template>
					<template v-if="other_symptoms">
						<p class="header-text">OTHER SYMPTOMS</p>
						<router-link v-for="symptom in other_symptoms"
									 :key="symptom.id"
									 class="other-symptom"
									 :to="{ name: 'symptom-viewer', params: { id: symptom.id } }">
							<span class="other-symptom-title">{{ symptom.title }}</span>
						</router-link>
					</template>
					<template v-else>
						<Loading color="#e28816" text="Loading symptoms..."></Loading>
					</template>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/component/Loading'
import Header from './header/Header'

export default {
	name: 'symptom-landing',

	data() {
		return {
			query: ''
		}
	},

	mounted() {
		this.loadContent()
	},

	methods: {
		...mapActions('user', [
			'loadContent'
		]),
		titleSort(obj1, obj2) {
			var textA = obj1.title.toUpperCase();
			var textB = obj2.title.toUpperCase();
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		},
	},

	computed: {
		...mapGetters('user', [
			'getContent',
			'getContentLoadingState'
		]),
		loading() {
			return this.getContentLoadingState
		},
		symptoms() {
			if (this.getContent) {
				let symptoms = this.getContent.symptom.filter(s => this.query.length ? s.title.toLowerCase().includes(this.query) : true)
				return symptoms.sort(this.titleSort)
			}
		},
		other_symptoms() {
			if (this.getContent) {
				let other_symptoms = this.getContent.other_symptom.filter(s => this.query.length ? s.title.toLowerCase().includes(this.query) : true)
				return other_symptoms.sort(this.titleSort)
			}
		}
	},

	components: {
		Loading,
		Header
	}
}
</script>
<style lang="scss" scoped>
@import "style/landing.scss";
</style>
