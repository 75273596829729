<template>
    
    <svg width="44" height="61" viewBox="0 0 44 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.60784 31.5073C3.47413 23.7118 4.79094 12.9292 5.59768 6.40716C5.93869 3.65031 8.95613 2.05097 11.5317 3.09166C15.0262 4.50366 19.5992 5.98213 23.051 5.7817C26.9524 5.55517 31.3931 3.2578 34.8648 1.01142C37.5395 -0.719229 41.3826 0.806346 41.7266 3.97348C42.5943 11.96 43.8057 24.719 43.8056 33.7051L43.8056 56.1776C43.8056 58.7442 41.4084 60.6408 38.9031 60.0832C33.3855 58.8553 24.5664 57.0031 19.7568 56.5733C15.3242 56.1771 9.38898 56.4865 5.31169 56.8017C2.85887 56.9914 0.75465 54.9922 0.909786 52.5369C1.25275 47.109 1.87733 38.0809 2.60784 31.5073Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>