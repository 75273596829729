<template>
    
    <svg width="114" height="181" viewBox="0 0 114 181" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M18.0785 163.994C18.7193 148.094 19.1983 123.643 17.2412 105.642C16.1284 95.406 6.5613 79.2986 4.24121 67.6418C0.667076 49.6845 0.765961 35.0451 1.0393 28.8426C1.12994 26.7858 2.09697 24.9036 3.7104 23.6248C11.6901 17.2999 37.4439 -1.59449 57.7412 0.64183C80.8373 3.18652 105.923 37.4819 112.556 47.1315C113.671 48.7522 114.073 50.6954 113.765 52.6378C112.253 62.1737 107.468 92.4652 104.241 114.142C101.481 132.682 97.5482 157.151 94.9468 173.134C93.8665 179.771 85.2789 182.425 80.0423 178.206C73.561 172.985 65.3754 167.72 57.7412 166.642C49.5387 165.484 39.4886 168.81 31.452 172.449C25.4177 175.182 17.8117 170.613 18.0785 163.994Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>