<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35" height="35" viewBox="0 0 50 50">
		<defs>
			<path id="a" d="M.465.476h11.47V12.11H.464z"/>
		</defs>
		<g fill="none" fill-rule="evenodd">
			<circle cx="25" cy="25" r="25" fill="#EEE" fill-rule="nonzero"></circle>
			<g transform="translate(18.864 9.438)">
				<path fill="#B2B2B2" d="M.616 4.974C1.335 1.846 4.416-.1 7.5.628c3.085.73 5.002 3.856 4.283 6.985-.718 3.128-3.8 5.073-6.884 4.345-3.083-.73-5.001-3.855-4.283-6.984" mask="url(#b)"/>
			</g>
			<path fill="#B2B2B2" d="M31.695 31.892v2.52H29.9v-2.52h-2.484v-1.82H29.9v-2.52h1.794v2.52h2.484v1.82h-2.484zm.956-7.285c-1.086-1.166-1.571-2.083-5.026-2.083 0 0-2.355 7.042-2.562 7.042-.297 0-2.761-6.887-2.761-6.887-3.2.11-3.548.272-5.263 2.322-2.65 3.17-4.007 7.721-4.896 11.743h25.84c-.953-3.888-2.662-9.27-5.332-12.137z"/>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'questionnaire-default-doctor-icon',

}
</script>
