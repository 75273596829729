<template>
    
    <svg width="44" height="61" viewBox="0 0 44 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M41.9342 31.5073C41.0679 23.7118 39.7511 12.9292 38.9443 6.40716C38.6033 3.65031 35.5859 2.05097 33.0103 3.09166C29.5158 4.50366 24.9428 5.98213 21.491 5.7817C17.5896 5.55517 13.1489 3.2578 9.67716 1.01142C7.00246 -0.719229 3.15943 0.806346 2.81535 3.97348C1.94768 11.96 0.736332 24.719 0.736348 33.7051L0.736375 56.1776C0.736376 58.7442 3.13361 60.6408 5.6389 60.0832C11.1565 58.8553 19.9756 57.0031 24.7852 56.5733C29.2178 56.1771 35.153 56.4865 39.2303 56.8017C41.6831 56.9914 43.7873 54.9922 43.6322 52.5369C43.2892 47.109 42.6647 38.0809 41.9342 31.5073Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>