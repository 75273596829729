<template>
	<div class="image">
		<video width="100%" height="auto" :poster="poster" controls>
			<source :src="src" type="video/mp4">
			Your browser does not support the video tag.
		</video>
	</div>
</template>
<script>
import Markdown from '.'

export default {
	name: 'markdown-video',
	extends: Markdown,

	data() {
		return {
			poster: ''
		}
	},

	computed: {
		src() {
			// [![](https://upload.doctorbox.com/cdn/a0d517e9-dcf8-4b67-94b4-b719d08692ad.jpeg)](https://upload.doctorbox.com/cdn/650fc5aa-f059-458e-94ca-4b0ec12bacce.mp4)
			let urlRegex = /\((.*?)\)/g
			// Array to store the extracted URLs
			let urls = []
			// Using a loop to find all matches of URLs in the inputString
			let match
			while ((match = urlRegex.exec(this.text))) {
				urls.push(match[1])
			}
			this.poster = urls[0]
			return urls[1]
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../style/markdown.scss";
img {
	display: block;
	width: 80%;
	margin-top: 30px;
	margin-bottom: 30px;
}
</style>
