<template>
	<div v-bind:class="['form', { success }]">
		<h3>
			Enter Password
			<router-link :to="loginSecondary" v-on:click.native="reset(authenticatedEmail)">{{ loginSecondaryText }}</router-link>
		</h3>
		<p v-if="! failedValidation">
			<CheckIcon class="icon"></CheckIcon>
			We have sent you an email with a password. Please check your email.
		</p>
		<div
			v-if="failedValidation"
			v-bind:class="['verified', { failedValidation }]">
			<CheckIcon v-bind:type="failedValidation" class="check-icon"></CheckIcon>
			<p>{{ getVerificationText }}</p>
		</div>
		<!-- Reset code input -->
		<PasswordInput
			v-model="code"
			v-bind:error="codeError"
            :autofocus="true"
			@next="reset"
            @reset="invalid = false">
		</PasswordInput>

        <div 
			v-if="invalid"
			v-bind:class="['bottom', { invalid }]">{{ getInvalidText }}</div>

		<!-- Login button -->
		<button
			v-bind:class="{ disabled: authenticating }"
			v-on:click="verifyResetAction">
			{{ resetActionText }}
		</button>
	</div>
</template>

<script>
import AuthForm from '.'

export default {
	name: 'reset-verify-form',
	extends: AuthForm,

	props: {
		logout: {
			type: Boolean
		}
    },
    data() {
        return {
			invalid: false,
			resend: false,
			codeVerified: false,
			invalidCode: this.failedValidation
        }
    },
    watch: {
        code(value) {
            this.invalid = false
        }
	},
	mounted() {
		if (this.query.resend)
			this.reset(this.authenticatedEmail)
	},
	methods: {
		verifyResetAction() {
            this.verifyReset({
                email: this.authenticatedEmail,
                reset_token: this.code
            })
            .then((result) => {
                if (! result.set_password) {
                    this.invalid = true
                    return
                }
                else
                    return this.$router.push({ name: 'reset-password'})
            })
            .catch((err) => {
                console.log(err)
                if (err.code == "InvalidToken") {
					this.invalid = true
				}
            })
        }
	},

	computed: {
		resetActionText() {
			return 'next'
		},
		loginSecondaryText() {
			return 'resend?'
		},
		loginSecondary() {
			return '/reset/verify?resend=true'
        },
        getInvalidText() {
            return "Seems like you have entered a wrong password"
		},
		getVerificationText() {
			return this.failedValidation ? 'Reset code validation failed. Please try again.' : 'We have sent you an email with a password. Please check your email.'
		}
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
