//import CategoryLanding from './Landing'
import CategoryViewer from './Viewer'

export default [
	// {
	// 	path: '/category',
	// 	name: 'category-landing',
	// 	props: true,
	// 	component: CategoryLanding
	// },
	{
		path: '/category/:id',
		name: 'category-viewer',
		props: (route) => ({
			id: route.params.id,
            query: route.query
		}),
		component: CategoryViewer,

		meta: {
			link: true
		}
	}
]
