<template>
<svg width="50" height="61" viewBox="0 0 50 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M49.1746 8.57486C49.5123 7.02627 48.0063 5.61885 46.4706 6.01136C42.9036 6.92309 36.9382 8.16983 30 8.5C23.1887 8.82413 11.3687 4.12569 3.61559 0.636101C1.69245 -0.229477 -0.329807 2.16008 0.869766 3.89463C5.39721 10.4412 11.8317 20.1297 14.5 26C18.2253 34.1957 26.114 48.7753 29.8942 55.6763C31.2149 58.0876 33.6083 59.7098 36.3345 60.0654C40.4851 60.6068 44.4286 58.1015 45.3019 54.0079C46.3959 48.8795 47.4196 41.8244 46.5 36C45.2702 28.211 47.7909 14.9193 49.1746 8.57486Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_ribs',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
