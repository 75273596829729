<template>
	<div class="viewer">
		<Header :contact="contact" :sms="sms"></Header>
		<router-view>
		</router-view>
		<template v-if="! contact && ! sms && loaded">
			<UserInfo></UserInfo>
			<Topic :personal=true></Topic>
			<Filler></Filler>
			<Topic :settings=true></Topic>
			<Filler></Filler>
			<Misc></Misc>
			<Footer></Footer>
		</template>
	</div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import Header from './header/Header'
import UserInfo from './component/UserInfo'
import Topic from './component/topic/Topic'
import Filler from './component/Filler'
import Misc from './component/Misc'
import Footer from './footer/Footer'

export default {
	name: 'setting-viewer',
	props: {
		id: {
			type: String
		},
		contact: {
			type: Boolean
		},
		sms: {
			type: Boolean
		}
	},
	data() {
		return {
            loaded: false
		}
	},
	mounted() {
        this.loadUser()
        .then(() => {
            this.loaded = true
            return
        })
	},
	methods: {
        ...mapActions('user', [
            'loadUser'
		])
	},
	computed: {
		...mapGetters('user', [
            'getName'
		]),
	},
	components: {
        Header,
		UserInfo,
		Topic,
		Filler,
		Misc,
		Footer
	}
}
</script>
<style lang="scss" scoped>
// @import "./style.scss";
</style>
