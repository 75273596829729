<template>
    
    <svg width="38" height="116" viewBox="0 0 38 116" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M15.3022 111.117C14.4982 107.542 11.4027 93.9017 8.99998 84.9991C6.21536 74.6817 4.79953 72.4354 2.49998 61.9991C-1.85088 42.2532 0.749886 9.34492 1.37542 2.33689C1.43559 1.66277 2.13132 1.26643 2.7599 1.5173C6.02362 2.81989 14.8562 6.08645 20 5.49944C25.8972 4.82645 32.5155 1.73063 35.3792 0.268408C36.0468 -0.0725116 36.8581 0.383218 36.8796 1.13258C37.0513 7.10594 37.5118 28.2149 36 43.5C34.4871 58.7954 29.7769 79.1831 28.5 94.5C27.7094 103.983 28.7294 111.071 29.3391 114.212C29.49 114.99 28.7431 115.679 27.9928 115.425C26.2955 114.852 23.6621 113.926 21.5 112.999C19.4181 112.107 17.4946 111.954 16.4117 111.955C15.8943 111.955 15.4158 111.622 15.3022 111.117Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>