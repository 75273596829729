<template>
	<div class="download">
		<Download :text="getText" :smallText="getSmallText"></Download>
	</div>
</template>

<script>
import Download from './Download'
import Vue from 'vue'

export default {
    props: {
        topic: {
            type: String
        },
        completed: {
            type: Boolean
        }
    },
    computed: {
        getText() {
            return this.completed ? 'You have already completed this questionnaire.' :
				('Download the DoctorPlan app to complete this ' + (this.topic || 'questionnaire.'))
        },
        getSmallText() {
            return 'The DoctorPlan app will also let you report your symptoms and vitals, and access important information about your treatment and care.'
        }
    },
	components: {
	    Download
	}
}
</script>

<style lang="scss" scoped>
.download {
    margin-top: 350px;
}
</style>
