<template>
    <svg width="60" height="39" viewBox="0 0 60 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M56.3195 18.7969L54.224 12.8494L54.224 12.8493C53.3665 10.4156 52.9378 9.19874 52.021 8.16941C51.1043 7.14007 50.0599 6.62998 47.9712 5.60979C43.1723 3.26587 35.4892 0.0372061 29.5156 0.000299463C23.5864 -0.0363328 15.9568 3.29572 11.2796 5.67054C9.32785 6.66151 8.35199 7.157 7.47472 8.13912C6.59745 9.12124 6.16988 10.267 5.31475 12.5586L5.31474 12.5586L3.01511 18.7211C0.421448 25.6715 -0.875385 29.1467 0.759611 31.9975C2.39461 34.8483 5.91196 35.4601 12.9467 36.6836C18.6882 37.6821 24.9172 38.5233 29.5156 38.5005C34.0979 38.4778 40.4147 37.6058 46.225 36.5963C53.2031 35.3839 56.6921 34.7777 58.3339 31.9757C59.9756 29.1737 58.7569 25.7148 56.3195 18.7969L56.3195 18.7969Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>