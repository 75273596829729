<template>
    
    <svg width="32" height="52" viewBox="0 0 32 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M31.7411 8.12341L30.5134 43.7265C30.4752 44.8356 30.2874 45.9406 29.8082 46.9416C29 48.6298 27.4844 51.0904 25.5403 51.017C14.9277 50.6166 6.20864 38.8741 3.06971 29.9509C0.657007 23.0921 -0.900051 10.6604 4.47416 4.6713C8.65057 0.0170275 13.686 0.00172019 19.9227 0.458064C22.7587 0.665576 26.2816 2.02829 28.763 3.14863C30.6939 4.02041 31.8142 6.00611 31.7411 8.12341Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_cheekbone',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
