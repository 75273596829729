<template>
<svg width="36" height="64" viewBox="0 0 36 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M33.4372 35.0227C31.1787 46.3162 27.4941 54.9945 22.5399 60.0484C19.8842 62.7576 17.1104 64.166 14.507 63.8402C12.3628 63.5718 3.32057 63.3429 1.93333 61.0498C-1.50579 55.365 4.8092 43.1006 7.28121 30.7394C9.31307 20.5791 7.87158 7.74875 12.2375 2.41014C15.3258 -1.36627 23.9792 0.632688 27.0699 1.0195C29.6992 1.34856 31.8036 3.40263 33.2747 6.68439C35.9774 12.7137 35.6836 23.7894 33.4372 35.0227Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_flank_side',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
