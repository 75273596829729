export default function({ dispatch, commit }, code) {
    // POST /auth/login
    return dispatch('post', {
        url: '/auth/verify/code',
        data: { code }
    }, {
        root: true
    })
    .then((doctor) => {
        commit('validatedCode', code)
        commit('validatedDoctor', doctor)
        return doctor
    })
}
