<template>
	<div class="radio">
		<div class="boxes">
			<div
				v-for="choice in 5"
				:key="choice"
				v-bind:class="['choice', 'radio', {
					selected: isSelected(choice)
				}]"
				v-bind:style="{ 'margin-bottom': '20px' }"
				@click="select(choice)">
				{{ choice }}
			</div>
		</div>

		<div class="labels">
			<div class="start">
				{{ question.start }}
			</div>
			<div class="arrow">
				<LongArrow></LongArrow>
			</div>
			<div class="end">
				{{ question.end }}
			</div>
		</div>
	</div>
</template>

<script>
import Question from './index'
import LongArrow from './icon/LongArrow'

export default {
	name: 'symptom-radio',
	extends: Question,

	mounted() {
		this.$emit('selected', this.saved)
	},

	components: {
		LongArrow
	}
}
</script>

<style lang="scss" scoped>
$choice-size: 60px;
$choice-font-size: 20px;

@import "style.scss";
.radio {
	// position: relative;
	// display: inline-block;
	margin-bottom: 40px;
	//padding: 2px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	.boxes {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.labels {
		.start, .end, .arrow {
			display: inline-block;
			text-align: center;
		}
		.arrow {
			text-align: center;
		}
		.start {
			float: left;
			width: 30%;
		}
		.end {
			float: right;
			width: 30%;
		}
	}
}

</style>
