<template>
<svg width="48" height="177" viewBox="0 0 48 177" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M45.9286 95C28.9285 121.5 45.5798 149.57 41.4286 165C38.9646 174.158 30.1171 176.5 26.9286 176.5C23.7402 176.5 15.8925 169.659 13.4286 160.5C9.27735 145.07 13.4285 116.306 13.4285 86.5C13.4285 60.8141 -1.78937 29.2583 1.42851 13.5C4.1609 0.119187 22.9977 1.00021 26.9286 1.00021C30.8596 1.00021 43.1962 0.119184 45.9286 13.5C49.1464 29.2583 45.9286 69.3141 45.9286 95Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_leg_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
