<template>
<svg width="66" height="63" viewBox="0 0 66 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M50.7603 19.4944L9.94484 1.16911C6.62231 -0.322637 2.71449 1.07102 1.08572 4.32857C-0.610687 7.72137 0.799828 11.8461 4.21852 13.4897L20.6608 21.3946C22.8154 22.4305 24.5276 24.2039 25.4871 26.3936L38.8643 56.9212C41.3095 62.5013 48.1338 64.6167 53.3066 61.3981L61.283 56.435C64.2165 54.6097 66 51.3995 66 47.9444V40.4661C66 38.2209 65.2444 36.041 63.8549 34.2774L54.5194 22.4284C53.5213 21.1616 52.2316 20.155 50.7603 19.4944Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_shoulder',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
