export default {
    methods: {
        /**
		 * @func getImage
		 * @desc Return the image for the answer at the given 1-based index
		 *
		 */
		getImage(index) {
			if (this.selected.includes(index - 1))
				return this.question.active_image[index - 1]
			else
				return this.question.inactive_image[index - 1]
		},

        /**
         * @func getSelectText
         */
		getSelectText(index) {
			// Used for testing long text
			//return index == 2 ? 'Bleeding / Blood in Stool / Bleeding / Bleeding / BleedingBleeding / Bleeding': this.question.select[index - 1]
			return this.question.select[index - 1]
		}
    }
}
