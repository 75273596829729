<template>
    <div class="user-info">
        <div class="user-name">{{ getName }}</div>
        <div class="user-email">{{ getEmail }}</div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'


export default {
	props: {
	},
	mounted() {
	},
	data() {
		return {
		}
	},
    methods: {

    },
    computed: {
        ...mapGetters('user', [
            'getName',
            'getEmail'
		]),
    }
}
</script>
<style lang="scss" scoped>
.user-info {
    margin: 50px 0px 50px 0px;
    text-align: center;
    .user-name {
        font-size: 24pt;
        font-weight: 600;
    }
    .user-email {
        margin-top: 10px;
        font-size: 12pt;
    }
}
</style>