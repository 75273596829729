<template>
<svg width="59" height="88" viewBox="0 0 59 88" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M59 69.7691L57.6111 29.6404C57.6111 29.6404 53.4552 18.3413 48.8146 12.3122C44.4867 6.6892 37.143 0.456007 37.143 0.456007L16.638 0H0V88C0 88 16.4065 87.6176 27.9808 85.7936C39.5551 83.9696 49.2776 77.1295 49.2776 77.1295L59 69.7691Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_upper_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
