import body_f from './body_f'
import body_selected from './body_selected'

import right_ribs from './right_ribs'
import left_ribs from './left_ribs'
import epigastric_area from './epigastric_area'
import right_lower_chest from './right_lower_chest'
import left_lower_chest from './left_lower_chest'
import right_side_chest from './right_side_chest'
import left_side_chest from './left_side_chest'
import center_chest from './center_chest'
import upper_chest from './upper_chest'
import right_arm_pit from './right_arm_pit'
import left_arm_pit from './left_arm_pit'



export default {
    body_f,
    body_selected,

    right_ribs,
    left_ribs,
    epigastric_area,
    right_lower_chest,
    left_lower_chest,
    right_side_chest,
    left_side_chest,
    center_chest,
    upper_chest,
    right_arm_pit,
    left_arm_pit
}