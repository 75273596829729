<template>
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.58136 28.6097V0.495377C0.58136 0.495377 20.49 1.31097 30.9613 11.9137C41.4326 22.5164 41.9718 41.1049 41.9718 41.1049H13.8576C13.8576 41.1049 13.4671 35.2478 9.17184 31.7335C5.26707 28.5387 0.58136 28.6097 0.58136 28.6097Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_upper_inner_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
