<template>
    
    <svg width="78" height="36" viewBox="0 0 78 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M77.9735 16.6032C77.9735 19.9444 78.5337 29.6963 74.5462 32.3008C67.5899 36.8444 54.8154 35.9234 41.1291 35.9234C27.4429 35.9234 11.6694 36.8444 4.71315 32.3008C0.725572 29.6963 0.000488281 19.9444 0.000488281 16.6032C0.000488281 7.43352 17.4554 0 38.987 0C60.5187 0 77.9735 7.43352 77.9735 16.6032Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>