<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7" cy="7" r="4" fill="#DA4E4E"/>
        <circle cx="7" cy="7" r="6.5" stroke="#DA4E4E"/>
    </svg>
</template>

<script>
/**
 * An Icon component that displays a record icon.
 */
export default {
    name: 'record-icon'
}
</script>
