<template>
    <svg width="66" height="71" viewBox="0 0 66 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M62.8016 55.7644L65.9159 16.1498C66.6822 6.40222 57.4347 -1.18455 47.8026 0.495853C42.3542 1.44637 36.6038 2.15871 31.7308 2.12235C27.734 2.09252 23.1252 1.55008 18.6108 0.799293C8.58717 -0.867698 -0.901403 7.38736 0.511218 17.45L5.98433 56.4369C6.68361 61.4181 9.99404 65.6637 14.7913 67.1763C20.3957 68.9434 28.0316 70.9423 34.0385 70.9987C40.1947 71.0566 48.0141 69.1059 53.8367 67.3261C58.9595 65.7602 62.3818 61.1047 62.8016 55.7644Z" :fill="color"/>
</svg>
    
</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>