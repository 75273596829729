<template>
<svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 15.7061C22.5 17.2047 13.8722 21.9901 8.5 19.7071C3.12583 17.4233 -0.370182 6.25562 0.499994 4.20798C1.62982 1.54936 11.6258 -1.07795 17 1.20589C21.5 3.11823 24.5 13.2054 23.5 15.7061Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_elbow',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
