<template>
	<div class="copyright">
		&copy; DoctorBox Health Inc., 2020 | <a href="https://doctorplan.com/terms">Terms</a> | <a href="https://doctorplan.com/privacy">Privacy</a>
	</div>
</template>

<script>
export default {
	name: 'copyright'
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
