<template>
<svg width="57" height="64" viewBox="0 0 57 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M12.7156 52.2677C23.4405 63.1889 44.7218 67.8183 53.1914 59.5011C61.6609 51.1838 53.1914 38.0075 44.1384 25.5639C35.0854 13.1203 13.5834 -5.94383 5.1138 2.37342C-3.35576 10.6907 1.9907 41.3464 12.7156 52.2677Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_inner_hip_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
