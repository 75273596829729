<template>
<svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.5066 4.15248L20.6187 0.331317C20.6187 0.331317 13.9137 1.16551 9.81762 2.96685C5.7099 4.7733 0.90045 9.02719 0.90045 9.02719L0.988912 23.0527L6.62551 39.9018L37.3944 26.7193L34.9677 18.1631L32.5066 4.15248Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_hand_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
