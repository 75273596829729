<template>
    
    <svg width="55" height="59" viewBox="0 0 55 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M28.3946 0.920359C36.1563 0.964967 46.6649 4.46721 51.7603 6.34962C53.5316 7.004 54.522 8.84403 54.1592 10.6972C53.1473 15.8663 51.0951 26.279 49.5027 33.9018C48.1635 40.3127 46.2236 49.0784 44.93 54.8709C44.2967 57.7064 40.8889 58.9731 38.4103 57.4573C34.2132 54.8906 28.1769 51.8281 23.1175 51.7119C17.995 51.5943 11.8552 54.4505 7.40416 57.0165C4.69025 58.5811 1.03748 56.8717 0.833824 53.7457C0.460693 48.0182 0.0230446 39.9844 0.0304451 33.9018C0.0400238 26.0309 0.802837 14.8126 1.16401 9.93554C1.27432 8.44604 2.21314 7.16212 3.61205 6.63883C8.44061 4.83263 19.9989 0.872108 28.3946 0.920359Z" :fill="color" />
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>