<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15">
        <g fill="#333" fill-rule="evenodd">
            <path d="M4.556 1.162c-1.945 0-3.527 1.538-3.527 3.43 0 1.89 1.582 3.43 3.527 3.43s3.528-1.54 3.528-3.43c0-1.892-1.583-3.43-3.528-3.43m0 7.86C2.044 9.021 0 7.033 0 4.591 0 2.148 2.044.161 4.556.161s4.556 1.987 4.556 4.43c0 2.442-2.044 4.43-4.556 4.43M13.348 6.91c-1.945 0-3.528 1.538-3.528 3.429 0 1.891 1.583 3.43 3.528 3.43s3.527-1.539 3.527-3.43c0-1.891-1.582-3.43-3.527-3.43m0 7.86c-2.513 0-4.556-1.988-4.556-4.43 0-2.443 2.043-4.43 4.556-4.43 2.512 0 4.556 1.987 4.556 4.43 0 2.442-2.044 4.43-4.556 4.43"/>
            <path d="M4.556 14.768a.507.507 0 0 1-.514-.5V8.521c0-.276.23-.5.514-.5.284 0 .514.224.514.5v5.747c0 .276-.23.5-.514.5M13.348 6.828a.507.507 0 0 1-.515-.5V.581c0-.276.23-.5.515-.5.284 0 .514.224.514.5v5.747c0 .276-.23.5-.514.5"/>
            <path d="M6.006 13.368H3.098a.507.507 0 0 1-.514-.5c0-.276.23-.5.514-.5h2.908c.284 0 .514.224.514.5s-.23.5-.514.5M11.21 3.114a.52.52 0 0 1-.363-.147.49.49 0 0 1 0-.707L13.02.146a.525.525 0 0 1 .727 0c.2.196.2.512 0 .707l-2.174 2.114a.52.52 0 0 1-.364.147"/>
            <path d="M15.521 3.114a.52.52 0 0 1-.363-.147L12.984.853a.49.49 0 0 1 0-.707.525.525 0 0 1 .727 0l2.174 2.114a.49.49 0 0 1 0 .707.52.52 0 0 1-.364.147"/>
        </g>
    </svg>
</template>
<script>
export default { name: 'icon-gender' }
</script>
