export default function({ dispatch, commit, state }, force) {
    // If the token has already been verified and this is not a
    // forced re-verification request (i.e. it is not the initial token verification)
    if (state.verified && force !== true)
        return (state.authenticated) ?
            Promise.resolve({ id: state.id, valid: true }) :
            Promise.reject({ code: 'InvalidToken' })

    // POST /auth/login
    return dispatch('post', {
        url: '/auth/verify/token',
        data: {}
    }, {
        root: true
    })
    .then((response) => {
        console.log('verify', response)
        commit((response.valid) ? 'verifiedToken' : 'unverifiedToken', response)
        return response
    })
    .catch((error) => {
        commit('unverifiedToken')
        throw error
    })
}
