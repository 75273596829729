<template>
	<div class="loading">
		<div class="text" v-bind:style="{ color: color }">{{ text }}</div>
	</div>
</template>

<script>
export default {
	name: 'generic-loading',

	props: {
		text: {
			type: String,
			default: 'Loading...'
		},
		background: {
			type: String,
			default: '#fafafa'
		},
		color: {
			type: String,
			default: '#2d3e4f'
		}
	}
}
</script>

<style lang="scss" scoped>
$logo-size: 100px;
$text-height: 50px;
$text-font-size: 24px;
$text-color: #2d3e4f;
$vertical-align: 30%;

.loading {
	width: 100%;
	height: 100%;
	position: relative;
	text-align: center;

	svg {
		position: absolute;
		left: 50%;
		top: $vertical-align;
		margin-left: $logo-size / -2;
		width: $logo-size;
		margin-top: $logo-size * -1 - 10;
	}

	.text {
		position: absolute;
		left: 0;
		top: $vertical-align;
		width: 100%;
		text-align: center;
		margin-top: $text-height - 5;
		color: $text-color;
		font-weight: 700;
		opacity: 0.8;
		font-size: $text-font-size;
		line-height: $text-height;
	}
}
</style>
