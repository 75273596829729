<template>
    <svg width="53" height="128" viewBox="0 0 53 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M34.9821 76.3936C31.2908 86.7395 25.2806 96.2997 21.5 104.451C13.4956 121.71 8.59128 128.232 5 126.951C-5.5 125.451 5 106.951 13.5 73.4512C14.3685 71.0169 14.4929 61.7079 15.8344 55.9512C17.5123 48.7502 22.742 41.0221 23.5 37.9512C27.0639 23.5122 26.1921 24.6184 28.0001 14.9506C29.0001 6.95066 30.9999 0.167402 44 0.167402C54.5001 0.167567 52.2501 16.4933 51.5 23.4512C50.2692 34.8679 42.1315 56.3553 34.9821 76.3936Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>