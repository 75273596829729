<template>
	<el-row class="thankyou">
		<el-col :span="24">
			<div class="header">
				<span v-if="display_name" class="header-title">{{ display_name }}</span>
				<span v-else-if="title" class="header-title">{{ title }}</span>
			</div>
		</el-col>
		 <el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 8, offset: 8 }">
			 <div v-if="completed_header !== undefined || completed_subheader !== undefined" class="questionnaire thankyou-original">
				 <Bye></Bye>
				 <!-- <pre>{{questionnaire}}</pre> -->
				 <p class="title">{{ completed_header || '' }}</p>
				 <p class="subtitle">{{ completed_subheader || '' }}</p>

				 <div>
					 <router-link :to="{ name: 'questionnaire-complete', query: { account: account } }" class="action">
						 DONE
					 </router-link>
				 </div>
			 </div>
			 <div v-else class="thankyou-question-list" :key="renderKey">
				 <div class="thankyou-container landing" v-if="allQuestionnaires.length">
					 <div class="thankyou-text">
						 <!-- For the thankyou page when you complete a questionnaire 
						 and there are pending questionnaire remaining in the event -->
						 <div v-show="!this.completed">
						 	<p class="title">Thank you!</p>
							<p class="subtitle">You have {{ allQuestionnaires.length }} more questionnaire{{ allQuestionnaires.length > 1 ? 's' : '' }} to complete.</p>
						 </div>
						 <!-- For the thankyou page when you have completed the questionnaire before 
						 and again opening the questionnaire using the link
						 and there are pending questionnaire remaining in the event -->
						 <div v-if="this.completed">
							<p class="answered">You’ve already answered the questionnaire</p>
							<p class="subtitle">There are {{ allQuestionnaires.length }} more to complete.</p>
						 </div>
					 </div>
					 <div class="pending-questionnaire-header" style="">
						Pending Questionnaire ({{ allQuestionnaires.length }})
					 </div>
					 <Questionnaire
							 v-for="(questionnaire, index) in allQuestionnaires"
							 :key="allEvents[index].id"
							 :account="account"
							 :questionnaire="allEvents[index]"
							 :content="questionnaire">
					 </Questionnaire>
				 </div>
			 </div>
		 </el-col>
		<template v-if="loadingEvents">
			<Loading v-if="account === 'doctorplan'" color="#a54eae" text="Loading questionnaire..."></Loading>
			<GenericLoading v-else color="#a54eae" text="Loading questionnaire..."></GenericLoading>
		</template>
	</el-row>
</template>

<script>
import Bye from './icon/Bye'
import NavIcon from '../header/icon/Back'
import Loading from '@/component/Loading'
import GenericLoading from '@/component/GenericLoading'
import Questionnaire from '../landing/Questionnaire'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'questionnaire-thankyou',

	props: {
		id: {
			type: String
		},
		title: {
			type: String
		},
		display_name: {
			type: String
		},
		completed_header: {
			type: String
		},
		completed_subheader: {
			type: String
		},
		account: {
			type: String,
			default: 'doctorplan'
		},
		completed: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		allQuestionnaires: {
			immediate: true,
			handler(allValue) {
				if(allValue && Array.isArray(allValue) && allValue.length > 0) {
					const firstQ = this.allEvents[0]
					this.$router.push('/questionnaire/' + firstQ.id +
						(this.hasAccessToken ? '' : ('?auth=' + firstQ.user + '&handoff_token=' + firstQ.id + ':' + firstQ.handoff_token + '&account=' + this.account + '&thanks=' + allValue.length)))
				}
			}
		}
	},
	data() {
		return {
			allEvents: [],
			allQuestionnaires: [],
			renderKey: 0,
			loadingEvents: false
		}
	},
	mounted() {
		if (this.completed_header === undefined) setTimeout(() => { this.findMissingQuestionnaires() }, 100)
	},
	methods: {
		...mapActions('user', [
			'addEvent',
			'loadEvents',
			'removeEvent'
		]),
		...mapActions('content', [
			'loadObject'
		]),
		goBack() {
			this.$router.push('/questionnaire')
		},
		findMissingQuestionnaires() {
			this.loadingEvents = true
			let type = 'questionnaire'
			let missingQuestionnaires = []
			this.allEvents = this.getEvents.filter(e => e.type.split(':')[0] === 'questionnaire' && e.id !== this.id && !e.hasOwnProperty('completed') && !e.hasOwnProperty('expiry_ref'))
			this.allEvents.forEach(event => {
				let id = event.type.split(':')[1]
				// counting the DISTINCT number of questionnaires missing from store
				if (!this.getObject(type, id)) missingQuestionnaires.push(id)
			})
			this.$nextTick(() => this.fetchQuestionnaires(missingQuestionnaires))
		},
		async fetchQuestionnaires(missingQuestionnaires) {
			let type = 'questionnaire'
			for (let i = 0; i < missingQuestionnaires.length; i++) {
				let eventId = missingQuestionnaires[i]
				try {
					const response = await this.requestPromise(type, eventId)
					// console.log('fetchQuestionnaire success ' + eventId, response)
				} catch (error) {
					console.log('Thankyou fetchQuestionnaire failure ' + eventId, error)
				}
			}
			this.$nextTick(() => this.loadQuestionnaires())
		},
		requestPromise(type, id) {
			let self = this
			return new Promise(function(resolve, reject) {
				self.loadObject({ type, id })
						.then((res) => resolve(res))
						.catch((err) => reject(err))
			});
		},
		loadQuestionnaires() {
			this.renderKey += 1
			this.allQuestionnaires = []
			if (this.allEvents.length) {
				this.allEvents = this.allEvents.reverse()
				this.allEvents.filter(e => e.type.split(':')[0] === 'questionnaire' && e.id !== this.id && !e.hasOwnProperty('completed') && !e.hasOwnProperty('expiry_ref') && !e.branch)
					.forEach(event => {
						let type = 'questionnaire'
						let id = event.type.split(':')[1]
						this.allQuestionnaires.push(this.getObject(type, id))
				})
			}
			this.loadingEvents = false
		}
	},
	computed: {
		...mapGetters('user', [
			'getEvents'
		]),
		...mapGetters('content', [
			'getObject'
		]),
		hasAccessToken() {
			return !!localStorage.getItem['access_token']
		}
	},
	components: {
		Bye,
		Loading,
		NavIcon,
		GenericLoading,
		Questionnaire
	}
}
</script>


<style lang="scss" scoped>
@import "./style.scss";
	.answered {
		text-align: center;
		font-size: 20px;
		font-weight: 600;
		color: #44b358;
	}
	@media screen and (min-width: 901px){
		.answered {
			font-size: 28px;
		}
	}
	.subtitle {
		font-weight: 500;
		text-align: center;
	}
	.pending-questionnaire-header {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		background-color: #FAF5FC;
		color: #833E93;
		padding: 15px 10px;
	}
</style>
