<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="129" height="139" viewBox="0 0 129 139">
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 13)">
                <path stroke="#833E93" stroke-width="1.5" d="M106.376 104H45.622C43.08 104 41 101.876 41 99.282V12.718C41 10.122 43.08 8 45.622 8h60.754C108.92 8 111 10.122 111 12.718v86.564c0 2.594-2.081 4.718-4.624 4.718z"/>
                <path fill="#833E93" d="M62 12h28V3H62z"/>
                <path fill="#FFF" d="M70 3h12V0H70z"/>
                <path fill="#833E93" stroke="#833E93" stroke-width="1.5" d="M70 3h12V0H70z"/>
                <path stroke="#833E93" stroke-width="1.5" d="M18 87h8V23h-8z"/>
                <path fill="#833E93" d="M21 22h3v-4h-3z"/>
                <path stroke="#833E93" stroke-width="1.5" d="M16 45V26.094h.17L26 26M18 88h8l-3.035 10h-1.93zM47 98h59V18H47z"/>
                <ellipse cx="64.5" cy="121.5" fill="#EEE" fill-rule="nonzero" rx="64.5" ry="4.5"/>
            </g>
            <g stroke-linecap="round" opacity=".203">
                <g stroke="#BD9AC5" stroke-width=".75">
                    <path d="M76.231 65.679v-13.42M76.231 92.269v-13.42M95.741 72.594H82.434M70.506 72.594H57.198"/>
                </g>
                <path stroke="#833E93" d="M71.054 67.748l-1.44-1.388"/>
                <path stroke="#833E93" stroke-dasharray="1.478,2.216" d="M67.511 63.673l-3-3"/>
                <path stroke="#833E93" d="M63.094 59.225l-1.438-1.39M90.54 86.86l-1.44-1.388"/>
                <path stroke="#833E93" stroke-dasharray="1.478,2.216" d="M86.313 81.548l-3-3"/>
                <path stroke="#833E93" d="M82.58 78.336l-1.437-1.388M90.267 57.286l-1.388 1.44"/>
                <path stroke="#833E93" stroke-dasharray="1.478,2.216" d="M85.917 61.79l-3 3"/>
                <path stroke="#833E93" d="M81.193 67.169l-1.39 1.438M72.117 77.05l-1.39 1.438"/>
                <path stroke="#833E93" stroke-dasharray="1.478,2.216" d="M69.003 80.867l-3 3"/>
                <path stroke="#833E93" d="M64.83 84.322l-1.389 1.44"/>
            </g>
            <path fill="#833E93" fill-rule="nonzero" d="M74.388 78.024l-.836-20.372h5.632l-.792 20.372h-4.004zm-1.056 6.38c0-1.672 1.364-3.08 3.036-3.08s3.08 1.408 3.08 3.08c0 1.628-1.408 3.036-3.08 3.036-1.672 0-3.036-1.408-3.036-3.036z"/>
            <circle cx="120" cy="33" r="3" fill="#FFF" stroke="#833E93"/>
            <circle cx="13" cy="17" r="3" fill="#FFF" stroke="#833E93"/>
            <circle cx="5.5" cy="75.5" r="1.5" fill="#833E93"/>
            <circle cx="124.5" cy="100.5" r="1.5" fill="#833E93"/>
            <circle cx="93.5" cy="1.5" r="1.5" fill="#833E93"/>
        </g>
    </svg>


</template>
<script>
export default {
	name: 'questionnaire-none-icon',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
// @import "./style.scss";
    svg {
        padding-bottom:20px;
    }
</style>
