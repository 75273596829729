<template>
    <div :style="{ width: size + 'px', height: size + 'px' }" class="spinner">
        <svg class="icon" viewBox="0 0 100 100">
            <circle class="path" :style="{stroke : color}" cx="50" cy="50" r="45" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'loading-icon',
    props: {
        size: {
            type: Number,
            default: 14
        },
        color: {
            type: String,
            default: '#ffffff'
        }
    }
};
</script>

<style>
.spinner {
    position: relative;
    display: inline-block;
}

.icon {
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;
    transform-origin: center;
}

.path {
    fill: none;
    stroke-width: 10;
    stroke-linecap: round;
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
    }
    100% {
        stroke-dashoffset: 187;
    }
}
</style>
