<template>
	<TopicTemplate 
		:name="name" 
		:title="title"
		:other="other"
		:size="size"
		:descriptionOther="getDescription"
		:appointment="appointment"
		:summary="summary"
		:content="content"
		:result="result"
		:selected="selected">
	</TopicTemplate>
</template>
<script>

import AppointmentView from '../../mixin/appointment-view'
import TopicTemplate from './template/TopicTemplate'

export default {
	name: 'appointment-other',
	mixins: [ AppointmentView ],
	data() {
		return {
			name: 'other_question',
			title: "Other Questions",
			other: true,
			size: 'large'
		}
	},
	mounted() {
	},
	methods: {
	},
	computed: {
		getDescription () {
			return "Is there anything else you'd like to discuss with your doctor that we have not covered in the last few steps?"
		}
	},
	components: {
		TopicTemplate
	}
}
</script>

<style lang="scss" scoped>
</style>