import get from './get'
import post from './post'
import patch from './patch'
import put from './put'
import del from './delete'

export default {
    get,
    post,
    patch,
    put,
    'delete': del,

    // For prompting the user to download the app
    promptDownload: ({ state }, active) => { state.download = (active == null) ? true : active }
}
