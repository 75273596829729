export default {
    /**
     * @func verifiedToken
     * @desc Mutation that is committed when the user's cookie tokens have been successfully verified.
     */
    verifiedToken(state, auth) {
        // Cache account information
        state.name = auth.name
        state.email = auth.email

        localStorage.setItem('auth', state.id = auth.id)
        localStorage.setItem('authenticated', state.authenticated = true)
        localStorage.setItem('verified', state.verified = Date.now())
    },

    /**
     * @func handoffToken
     * @desc Mutation that is committed when handoff information needs to persist.
     */
    handoffToken(state, auth) {
        if (auth.auth && auth.handoff_token) {
            localStorage.setItem('auth', state.id = auth.auth)
            state.handoff_token = auth.handoff_token
            // localStorage.setItem('handoff_token', state.handoff_token = auth.handoff_token)
            state.light = (auth.light === true || auth.light === 'true')
        }
    },

    /**
     * @func unverifiedToken
     * @desc Mutation that is committed when the user's cookie tokens could not be verified.
     */
    unverifiedToken(state) {
        state.id = null
        state.name = null
        state.email = null

        state.authenticated = false
        localStorage.removeItem('auth')
        localStorage.removeItem('verified')
        localStorage.removeItem('handoff_token')
    },

    /**
     * @func receivedToken
     * @desc Mutation committed when the user successfully logs in or resets their account, and receives an access token.
     */
    receivedToken(state, token) {
        localStorage.setItem('auth', state.id = token.auth)
        localStorage.setItem('authenticated', state.authenticated = true)
        localStorage.setItem('verified', state.verified = Date.now())
    },

    /**
     * @func invalidatedToken
     * @desc Mutation when the user invalidates
     */
    invalidatedToken(state) {
        state.id = null
        state.name = null
        state.email = null

        state.authenticated = false
        localStorage.removeItem('auth')
        localStorage.removeItem('verified')
        localStorage.removeItem('authenticated')
        localStorage.removeItem('handoff_token')
    },

    validatedEmail(state, email) {
        state.email = email
    },

    validatedCode(state, code) {
        state.code = code
    },

    validatedDoctor(state, doctor) {
        state.doctor = doctor
    },
    failedValidation(state) {
        state.failedValidation = true
    },
    validatedUpgradeToken(state, response) {
        state.upgradeToken = response.upgrade_token
        state.doctor = response.doctor
    },

    validatedResetToken(state, token) {
        state.resetToken = token
    },

    /**
     * @func initiatedLogin
     * @desc The user initiated the login flow, so the state records this as the current user's email,
     *       and stores the light user flag.
     */
    initiatedLogin(state, auth) {
        state.email = auth.email
        state.type = auth.type
        state.light = auth.light
    },

    initiatedJoin(state, auth) {
        state.email = auth.email
        state.light = auth.light
        state.name = auth.name
        state.code = auth.code
        state.doctor = auth.doctor
    },

    initiatedReset(state, auth) {
        state.email = auth.email
    },
    
    /**
     * @func startedAuthentication
     * @desc When the user initiates an authentication/verification/reset request
     */
    startedAuthentication(state) {
        state.authenticating = true
    },

    /**
     * @func finishedAuthentication
     * @desc When the user completes an authentication request
     */
    finishedAuthentication(state) {
        state.authenticating = false
    }
}
