import { render, staticRenderFns } from "./Verify.vue?vue&type=template&id=4c82a2fe&scoped=true"
import script from "./Verify.vue?vue&type=script&lang=js"
export * from "./Verify.vue?vue&type=script&lang=js"
import style0 from "./Verify.vue?vue&type=style&index=0&id=4c82a2fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c82a2fe",
  null
  
)

export default component.exports