<template>
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.124023 0.666916H15.7935C15.7935 0.666916 14.9621 5.35263 19.2574 10.4288C23.5526 15.505 29.0193 14.7241 29.0193 14.7241V29.5622C29.0193 29.5622 19.2574 27.5373 11.0574 19.8002C2.85736 12.0632 0.124023 0.666916 0.124023 0.666916Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_lower_outer_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
