import body_f from "./body_f"
import body_b from "./body_b"
import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'
import above_right_knee_f from "./above_right_knee_f"
import above_left_knee_f from "./above_left_knee_f"
import right_lateral_knee_f from "./right_lateral_knee_f"
import left_lateral_knee_f from "./left_lateral_knee_f"
import right_kneecap from "./right_kneecap"
import left_kneecap from "./left_kneecap"
import right_medial_knee_f from "./right_medial_knee_f"
import left_medial_knee_f from "./left_medial_knee_f"
import below_right_knee_f from "./below_right_knee_f"
import below_left_knee_f from "./below_left_knee_f"
import left_lower_leg_f from "./left_lower_leg_f"
import right_lower_leg_f from "./right_lower_leg_f"

import left_upper_thigh_b from "./left_upper_thigh_b"
import right_upper_thigh_b from "./right_upper_thigh_b"
import left_lower_thigh_b from "./left_lower_thigh_b"
import right_lower_thigh_b from "./right_lower_thigh_b"
import left_knee_b from "./left_knee_b"
import right_knee_b from "./right_knee_b"
import left_upper_calf_b from "./left_upper_calf_b"
import right_upper_calf_b from "./right_upper_calf_b"
import left_lower_calf_b from "./left_lower_calf_b"
import right_lower_calf_b from "./right_lower_calf_b"


export default {
    body_b,
    body_f,
    body_f_selected,
    body_b_selected,
    above_right_knee_f,
    above_left_knee_f,
    right_lateral_knee_f,
    left_lateral_knee_f,
    right_kneecap,
    left_kneecap,
    right_medial_knee_f,
    left_medial_knee_f,
    below_right_knee_f,
    below_left_knee_f,
    right_lower_leg_f,
    left_lower_leg_f,
    left_upper_thigh_b,
    right_upper_thigh_b,
    left_lower_thigh_b,
    left_knee_b,
    right_knee_b,
    left_upper_calf_b,
    right_upper_calf_b,
    left_lower_calf_b,
    right_lower_calf_b,
    right_lower_thigh_b
}