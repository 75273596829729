<template>
<svg width="43" height="46" viewBox="0 0 43 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.02661 43.1362L0.526611 18.1362C0.526611 18.1362 11.4338 4.21311 21.5266 1.13615C29.3712 -1.2554 42.5266 1.13615 42.5266 1.13615L39.5266 45.6362C39.5266 45.6362 28.92 40.1277 21.5266 39.6362C14.1899 39.1484 3.02661 43.1362 3.02661 43.1362Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_knee_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
