<template>
	<div v-if="query.verification_token">


		<!-- Show phone number and ask for consent -->
		<div v-if="!hasUserConfirmedPhone" class="phone-confirmation-screen">
			<span class="title">Confirm Cell Phone Number</span>
			<div class="user-phone-confirm-block">
				{{ formatPhone(query.phone) }}
			</div>
			<span class="info-text">
				Please click on the button below to confirm your cell phone number and to consent <a href="https://www.doctorplan.com" target="_blank">(details)</a> to receiving information from {{ query.provider }}’s office.
			</span>

			<el-button :class="['confirm-button', { 'gerd-background': isAccountGerd(query.account)}]"
				:loading="isLoading"
				@click="confirmPhoneNumber">
				Confirm
			</el-button>
		</div>

		<!-- Set Password -->
		<template v-else-if="showSetPassword">
			<h3 v-if="!verifySetPassword" class="email-title">
				{{ getTitle }}
			</h3>
			<div class="success">
				<p class="password">Secure your account by choosing a password you'll remember</p>
			</div>
			<div v-bind:class="['input']">
				<input
					type="password"
					ref="input"
					v-model="password">
				<label>Password</label>
				<span v-if="password.length" class="password-show-hide" @click="togglePasswordVisibility">{{ showPassword ? 'HIDE' : 'SHOW' }}</span>
			</div>
			<div :class="['bottom']">6 characters or more</div>

			<el-row class="agree-terms-checkbox" v-if="isAccountGerd(query.account) && (query.consent === 'true')">
				<el-col :span="3">
					<input type="checkbox" v-model="agreedToTerms">
				</el-col>
				<el-col :span="21">
					<label> I agree to the <a href="https://doctorplan.com/terms/" >Terms of Service</a> and <a href="https://doctorplan.com/privacy/index.html" >Privacy Policy</a></label>
				</el-col>
			</el-row>
			
			<el-button :class="['save-button', { 'gerd-background': isAccountGerd(query.account)}]"
				:disabled="password.length < 6"
				:loading="isLoading"
				@click="setPassword">
				Save New Password
			</el-button>
		</template>

		<!-- Ask for Email as well -->
		<template v-else-if="showSetEmail">
			<div>
				<div class="email-info">
					<div class="tick"><Tick /></div>
					Use your password to Log In to the <br>DoctorPlan app
				</div>
				<h3 class="email-title">{{ getTitle }}</h3>
				<div class="success">
					<p class="password">
						It will only be used to notify you of messages or action items from
						your doctor.
					</p>
				</div>
				<EmailInput
					v-model="email"
					:error="(nextClicked && isInvalidEmail) ? 'Enter a valid email address' : ''"
					@next="toggleEmailConfirmationScreen">
				</EmailInput>
				<el-button
					:class="['confirm-button', 'custom_class']"
					:loading="isLoading"
					@click="toggleEmailConfirmationScreen"
					:disabled="(nextClicked && isInvalidEmail)">
						Next
				</el-button>
			</div>
		</template>


		<!-- Show user the email to confirm it is correct -->
		<div v-else-if="showEmailConfirmationScreen" class="email-confirmation-screen">
			<span class="title">Please Confirm your Email</span>
			<div class="user-phone-confirm-block">
				<img src="./icon/email.svg" />
				<p>{{ email }}</p>
			</div>

			<el-button :class="['edit-button', { 'gerd-background': isAccountGerd(query.account)}]"
				@click="toggleEmailConfirmationScreen">
				Edit
			</el-button>

			<el-button :class="['confirm-button', { 'gerd-background': isAccountGerd(query.account)}]"
				:loading="isLoading"
				@click="setEmail">
				Confirm Email
			</el-button>
		</div>
	</div>
</template>

<script>
import AuthForm from '.'
import validPassword from '../../util/valid-password'
import Tick from "./icon/Tick.vue"
import validEmail from '../../util/valid-email'
import isAccountGerd from '../../util/is-account-gerd'

export default {
	name: 'verify-phone',
	extends: AuthForm,

	data() {
		return {
			hasUserConfirmedPhone: false,
			showEmailConfirmationScreen: false,
			nextClicked: false,

			verifySuccess: null,
			verifyPhoneError: null,
			verifySetPassword: false,
			showSetPassword: false,
			passwordToken: null,
			agreedToTerms: false,
			isLoading: false,
			showPassword: false,
			email: "",
			showSetEmail: false,
			isEnterEmailIsValid: false,
			email_token: null,
			phoneThroughImport: ""
		}
	},

	mounted() {},

	methods: {
		formatPhone(phone) {
			let withoutCountryCode = phone.slice(-10)
			let formattedPhone = withoutCountryCode.replace(/\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*/, '$1 $2 $3').split(' ')
			return '(' + formattedPhone[0] + ') ' + formattedPhone[1] + '-' + formattedPhone[2]
		},
		toggleEmailConfirmationScreen() {
			setTimeout(() => {
				this.nextClicked = true
			}, 1000);
			// if the user has typed in their email then show them email confirmation page
			if (this.email.length && !this.isInvalidEmail) {
				this.showSetEmail = !this.showSetEmail
				this.showEmailConfirmationScreen = !this.showEmailConfirmationScreen
			} else if (!this.email.length) { // show call the api to set the email
				this.setEmail()
			}
		},
		confirmPhoneNumber() {
			this.isLoading = true

			// no terms to agree for doctorplan and livechair
			if (['doctorplan', 'livechair'].includes(this.query.account) || this.query.consent === 'false' || !this.query.hasOwnProperty('consent'))
				this.agreedToTerms = true

			if (this.query.auth && this.query.verification_token) {
				this.verifyPhone(this.query).then((result) => {
					this.$message({
						message: 'Phone number confirmed',
						type: 'success'
					});
					this.verifySuccess = result

					// Enable a flag to set the password
					if (result.set_password)
						this.showSetPassword = true
					this.hasUserConfirmedPhone = true
					this.isLoading = false
				})
				.catch((error) => {
					if (error.set_password) {
						//show set password step
						this.showSetPassword = true
						this.passwordToken = error.set_token
					}
					else {
						//show download app step
						this.verifyPhoneError = true
						// redirect to Phone Enroll's download page
						this.$router.push({ path: '/downloadPhoneEnroll', account: this.query.account })
						this.$message('Cell phone number already verified.')
					}
				})
			} else {
				this.$router.push({ path: '/download', query: { account: this.query.account } })
				if (!this.query.verification_token) {
					this.$message({
						showClose: true,
						message: 'Verification token missing.',
						type: 'error',
						duration: '0'
					})
				}
				if (!this.query.auth) {
					this.$message({
						showClose: true,
						message: 'Auth token missing.',
						type: 'error',
						duration: '0'
					})
				}
			}
		},
		setEmail() {
			this.isLoading = true
			return this.addEmail({
				auth: this.query.auth,
				email_token: this.email_token,
				verified_email: this.email,
			})
			.then(() => {
				this.showSetEmail = false
				this.showEmailConfirmationScreen = false
				this.verifySetPassword = true
				this.isLoading = false
				// redirect to Phone Enroll's download page
				this.$router.push({ path: '/downloadPhoneEnroll', account: this.query.account })
			})
			.catch(() => {
				this.isLoading = false
			})
		},
		setPassword() {
			if (this.authenticating) return

            // Reset error message
            this.passwordError = null
			if (! validPassword(this.password)) {
				this.passwordError = 'Need at least 6 characters'
				return
			}
			this.isLoading = true
            return this.postVerifySetPassword({
				auth: this.query.auth,
				set_token: this.verifySuccess ? this.verifySuccess.set_token : this.passwordToken,
				password: this.password
            })
			.then((result) => {
				this.isLoading = false
				this.verifySuccess = null
				this.showSetPassword = false
				this.email_token = result.email_token // replace with real key
				this.showSetEmail = true
				if (result.phone) {
					const val = result.phone.substr(-10)
					this.emailThroughImport = result.phone.substr(-10);
					this.isEnterEmailIsValid = true
					this.email = this.getFomattedPhoneNumber(val)[1] ? this.getFomattedPhoneNumber(val)[1] : "";
				}
			})
			.catch((error) => {
				this.isLoading = false
				this.passwordError = error || 'Invalid Password'
			})
		}
	},
	computed: {
		isInvalidEmail() {
			return !validEmail(this.email)
		},
		getAccountName() {
			if (this.isAccountGerd(this.query.account)) return 'GERDHelp'
			else if (this.query.account === 'livechair') return 'LiveChair'
			else return 'DoctorPlan'
		},
		getTitle() {
			if (this.showSetEmail) return "Enter your Email";
			return this.showSetPassword ? 'Set a New Password': 
					(this.verifySetPassword || this.verifyPhoneError) ? 'Download App' : 'Confirm Email'
		},
		getDownloadStatus() {
			return (this.verifySetPassword || this.verifyPhoneError) ? true : false
		}
	},
	components: {
		Tick
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
.input {
	margin-bottom: 20px;
	position: relative;

	label {
		position: absolute;
		left: 10px;
		color: rgba(0, 0, 0, 0.5);
		background: white;
		padding: 0 5px;
		line-height: 0.1em;
		font-size: 1em;
	}

	input {
		width: 100%;
		height: 50px;
		font-size: 15px;
		letter-spacing: .5px;
		border: solid 1px #bfbfbf;
		padding: 0 15px;
		border-radius: 2px;
	}

	input:focus {
		outline: none;
		border-color: #afafaf;
	}

	label.error,
	label.forgot {
		top: unset;
		bottom: 0px;
		font-size: 0.8em;
		line-height: 0.2em;
	}

	label.forgot:hover {
		cursor: pointer;
		color: #4f4f4f;
	}

	label.error {
		color: red;
	}
}

.input.error {
	label {
		color: rgba(255, 0, 0, 0.5);
	}
	input {
		border-color: red;
	}
}

.phone-confirmation-screen {
	height: auto;
	min-height: 353px;
	text-align: left;
	.title {
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #2D3E4F;
		display: block;
		padding-bottom: 15px;
	}
	.user-phone-confirm-block {
		height: 56px;
		background: #FFF1DF;
		border-radius: 14px;
		font-weight: 700;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		letter-spacing: 2px;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.info-text {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #2D3E4F;
		padding-top: 15px;
		display: block;
		text-align: left;

		a {
			color: blue;
			font-size: inherit;
			float: initial;
		}
	}
	.confirm-button {
	    background: #2d3e4f;
		border: solid 1px #2d3e4f;
		font-size: 16px;
		border-radius: 0px;
		padding: 15px 0;
	    width: 100%;
	    color: #ffffff;
		font-weight: 500;
		position: absolute;
		bottom: 25px;
		width: 88%;
		left: 25px;
	}

	.confirm-button:hover {
		cursor: pointer;
	}

	.confirm-button:focus {
		outline: none;
	}

	.confirm-button.disabled {
		opacity: 0.7;
		cursor: no-drop;
	}
	.confirm-button.gerd-background {
		background-color: #2A63B0 !important;
		border: solid 1px #2A63B0;
	}
}
.email-confirmation-screen {
	height: auto;
	min-height: 353px;
	text-align: left;
	.title {
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #2D3E4F;
		display: block;
		padding-bottom: 15px;
	}
	.user-phone-confirm-block {
		height: 170px;
		background: #FFF1DF;
		border-radius: 14px;
		font-weight: 700;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		color: #333333;
		word-wrap: break-word; 
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		p {
			max-width: 290px;
		}
	}
	.edit-button {
		background: #ffffff;
		border: solid 1px #2d3e4f;
		font-size: 16px;
		border-radius: 0px;
		padding: 15px 0;
	    color: #2d3e4f;
		font-weight: 500;
		position: absolute;
		bottom: 25px;
		width: 26%;
		left: 25px;
	}
	.confirm-button {
	    background: #2d3e4f;
		border: solid 1px #2d3e4f;
		font-size: 16px;
		border-radius: 0px;
		padding: 15px 0;
	    color: #ffffff;
		font-weight: 500;
		position: absolute;
		bottom: 25px;
		width: 55%;
		right: 25px;
	}

	.confirm-button:hover {
		cursor: pointer;
	}

	.confirm-button:focus {
		outline: none;
	}

	.confirm-button.disabled {
		opacity: 0.7;
		cursor: no-drop;
	}
	.confirm-button.gerd-background {
		background-color: #2A63B0 !important;
		border: solid 1px #2A63B0;
	}
}

.email-info {
	height: auto;
	min-height: 30px;
	margin: 10px 0;
	background: #e7ffea;
	margin: 0px -15px 20px -25px;
	display: flex;
	align-items: center;
	gap: 10px;
	width: 150%;
	padding: 10px 25px;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #44B358;
	text-align: left;
}
.email-title {
  background: white !important;
  color: #2D3E4F;
  text-align: left;
  padding-bottom: 10px;

}
.tick {
  width: 20px;
  height: 20px;
}

.custom_class {
  width: 100%;
  background-color: #2d3e4f;
  color: #fff;
  height: 44px;
  border-radius: 0;
  margin-top: 15px;
  font-size: 16px;
}
.custom_class:hover {
  background-color: #2d3e4f;
  color: #fff;
}

.save-button {
	margin-top: 85px;
}


.start-title{ 
	margin-left: 15px;
}

.account-name {
	font-weight: bold;
}

.info-text {
	color: #2D3E4F;
	line-height: 22px;
	font-size: 18px;
}

.image-class {
	height: 250px;
	width: 250px;
	position: absolute;
	top: -40px;
	left: 0;
}

.phone_image_wrapper {
	position: relative;
}

.ellipse-class {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
}

.footer {
	position: fixed;
	display: flex;
	top: 76%;
    left: 50%;
    width: 350px;
    margin-left: -175px;
}

.download-button {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;

	left: 20px;
    bottom: 20px;
    width: calc(100vw - 40px);
    height: 44px;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
	color: #2D3E4F;
	border: none;
	cursor: pointer;
}
</style>
