<template>
	<section>
		<a class="header">
			<FitbitIcon class="icon"></FitbitIcon>
			<span style="color:#2D3E4F">FITBIT</span>
		</a>

		<div class="item">
			<a :href="fitbitLogin">
				<FitbitArrowIcon class="icon"></FitbitArrowIcon>
				<h5>Connect your Fitbit</h5>
				<p>Synchronize your activity and vitals</p>
			</a>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
import Icons from './icon/components'

const FITBIT_LOGIN = 'https://' + ((process.env.NODE_ENV == 'live' || process.env.NODE_ENV == 'production') ? '' : 'dev.') + 'doctorboxapi.com/fitbit/login/'

export default {
	name: 'home-fitbit-section',

	computed: {
		...mapGetters('user', [
			'getId',
			'getFitbit'
		]),
		fitbit() {
			return this.getFitbit
		},
		fitbitLogin() {
			return FITBIT_LOGIN + this.getId
		}
	},

	components: {
		...Icons
	}
}
</script>

<style lang="scss" scoped>
@import "../section.scss";
@import "style.scss";
</style>
