<template>
    
    <svg width="44" height="61" viewBox="0 0 44 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.60735 31.5073C3.47364 23.7118 4.79045 12.9292 5.59719 6.40716C5.9382 3.65031 8.95564 2.05097 11.5312 3.09166C15.0257 4.50366 19.5987 5.98213 23.0505 5.7817C26.9519 5.55517 31.3926 3.2578 34.8643 1.01142C37.539 -0.719229 41.3821 0.806346 41.7262 3.97348C42.5938 11.96 43.8052 24.719 43.8052 33.7051L43.8051 56.1776C43.8051 58.7442 41.4079 60.6408 38.9026 60.0832C33.385 58.8553 24.5659 57.0031 19.7563 56.5733C15.3237 56.1771 9.38849 56.4865 5.3112 56.8017C2.85838 56.9914 0.754162 54.9922 0.909298 52.5369C1.25226 47.109 1.87684 38.0809 2.60735 31.5073Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>