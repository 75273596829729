<template>
<svg width="36" height="80" viewBox="0 0 36 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.500035 12.4998C1.31525 6.32335 5.00003 2.67422 8 2C11.5 1.21339 18.5071 -1.35269 20 3.49965C22 10.0001 22.5736 18.1798 25 26.4998C26.8431 32.8197 29 41.9998 32.5 48.9998C33.3047 52.6906 32.3344 53.7713 33 56.9998C34.1932 62.7878 35.5 61.9998 35.5 63.9996C35.5 66.6924 14.1955 79.3993 11 78.9998C7.80452 78.6004 7.27409 72.9998 7 71.9998C5.31874 65.8658 3.75143 63.3156 3 56.9998C1.80108 46.9229 9.09364 44.5219 7.5 34.4998C6.25141 26.6477 -0.540348 20.3823 0.500035 12.4998Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_foot',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
