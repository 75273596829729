import { render, staticRenderFns } from "./Checklists.vue?vue&type=template&id=6db6140d&scoped=true"
import script from "./Checklists.vue?vue&type=script&lang=js"
export * from "./Checklists.vue?vue&type=script&lang=js"
import style0 from "./Checklists.vue?vue&type=style&index=0&id=6db6140d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db6140d",
  null
  
)

export default component.exports