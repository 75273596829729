<template>
	<div class="appointment-link">
		<div class="section">
			<span class="date">{{ formatDate(appointment.time) }}</span>
			<span class="dot">•</span>
			<span class="dot">{{ appointment.doctor.name }}</span>
			
			<router-link :to="'/appointment/' + appointment.id" class="nav">
				<StartArrowIcon></StartArrowIcon>
			</router-link>

		</div>
	</div>
</template>
<script>
import StartArrowIcon from './icon/StartArrow'
import formatDate from '@/module/util/format-date'

export default {
	name: 'landing-appointment',

	props: {
		appointment: {
			type: Object
		},
		content: {
			type: Object
		}
	},
	mounted () {
	},
	methods: {
		formatDate
	},

	components: {
		StartArrowIcon
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
