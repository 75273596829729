import DateOfBirth from './DateOfBirth'
import Gender from './Gender'
import Height from './Height'
import HealthData from './HealthData'
import MyDoctors from './MyDoctors'
import Plus from './Plus'
import Minus from './Minus'

export default {
    DateOfBirth,
    Gender,
    Height,
    HealthData,
    MyDoctors,
    Plus,
    Minus
}
