<template>
    
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M6.99648 24.1101C13.8867 25.6757 24.3472 25.0675 25.3244 13.8291C26.3244 2.3278 18.8244 0.828197 12.8244 0.829162C6.82444 0.830126 1.99091 3.27068 1.06915 7.32752C0.147389 11.3844 0.106222 22.5446 6.99648 24.1101Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>