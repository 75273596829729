<template>
<svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.2945 13.2655L28.1456 0.0400878L30.4256 11.4531C32.8114 13.516 37.845 17.9865 42.2818 19.2053C46.7187 20.4241 46.8365 15.5998 46.2945 13.2655Z" :fill="color"/>
<path d="M37.8355 51.5011L32.1567 28.9393L30.7715 23.8802L23.5855 27.0217L22.6735 27.4204L14.9214 30.8095L14.0094 31.2082L6.94958 34.2946L5.80124 34.7966L0.78516 36.9895L6.94958 55.2099C6.94958 55.2099 8.73058 56.1527 9.6155 55.5752C10.4095 55.057 10.1642 53.234 10.1642 53.234L5.80124 34.7966L6.94958 34.2946L16.5454 61.7654C16.5454 61.7654 18.6086 63.8822 19.9462 63.3024C21.1764 62.7692 21.2809 60.1771 21.2809 60.1771L14.0094 31.2082L14.9214 30.8095L26.041 62.4847C26.041 62.4847 28.0676 64.5115 29.4418 64.0217C30.9769 63.4746 30.7715 60.1172 30.7715 60.1172L22.6735 27.4204L23.5855 27.0217L32.5415 53.8721C32.5415 53.8721 34.9574 56.2011 36.5057 55.4056C37.9533 54.6619 37.8355 51.5011 37.8355 51.5011Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_fingers_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
