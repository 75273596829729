import body_f from "./body_f"
import body_b from "./body_b"

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import right_lower_calf from "./right_lower_calf"
import left_lower_calf from "./left_lower_calf"
import above_right_ankle_f from "./above_right_ankle_f"
import above_left_ankle_f from "./above_left_ankle_f"
import right_lateral_ankle_f from "./right_lateral_ankle_f"
import left_lateral_ankle_f from "./left_lateral_ankle_f"
import right_anterior_ankle_f from "./right_anterior_ankle_f"
import left_anterior_ankle_f from "./left_anterior_ankle_f"
import right_medial_ankle_f from "./right_medial_ankle_f"
import left_medial_ankle_f from "./left_medial_ankle_f"
import right_dorsal_foot_f from "./right_dorsal_foot_f"
import left_dorsal_foot_f from "./left_dorsal_foot_f"

import left_posterior_ankle_b from "./left_posterior_ankle_b"
import right_posterior_ankle_b from "./left_posterior_ankle_b"
import left_heel from "./left_heel"
import right_heel from "./right_heel"

export default {
    body_f,
    body_b,
    body_f_selected,
    body_b_selected,
    right_lower_calf,
    left_lower_calf,
    above_right_ankle_f,
    above_left_ankle_f,
    right_lateral_ankle_f,
    left_lateral_ankle_f,
    right_anterior_ankle_f,
    left_anterior_ankle_f,
    right_medial_ankle_f,
    left_medial_ankle_f,
    right_dorsal_foot_f,
    left_dorsal_foot_f,
    left_posterior_ankle_b,
    right_posterior_ankle_b,
    left_heel,
    right_heel
}