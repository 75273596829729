<template>
<svg width="53" height="128" viewBox="0 0 53 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.7654 76.3936C21.4567 86.7395 27.4669 96.2997 31.2476 104.451C39.2519 121.71 44.1563 128.232 47.7476 126.951C58.2476 125.451 47.7476 106.951 39.2476 73.4512C38.3791 71.0169 38.2546 61.7079 36.9132 55.9512C35.2353 48.7502 30.0055 41.0221 29.2476 37.9512C25.6837 23.5122 26.5555 24.6184 24.7475 14.9506C23.7475 6.95066 21.7477 0.167402 8.74756 0.167402C-1.75251 0.167567 0.497432 16.4933 1.24756 23.4512C2.47839 34.8679 10.6161 56.3553 17.7654 76.3936Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
