<template>
    
    <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M45 37.6725V32.6516C45 31.7929 45 31.3635 44.9311 30.876C44.8621 30.3884 44.7605 30.036 44.5571 29.3312L44.5571 29.3311C43.6553 26.2056 41.431 19.4053 38.3898 16.34C34.0124 11.9279 6.21853 1.73795 4.39952 1.07408C4.33469 1.05042 4.27273 1.03071 4.20626 1.01215C1.76088 0.329343 -0.202 3.07483 1.23517 5.16783L14.7431 24.84L24.4513 42.8565C25.7748 45.3126 26.4365 46.5406 27.3914 47.375C28.3829 48.2413 29.5985 48.8104 30.8988 49.017C32.1512 49.216 33.5181 48.9377 36.252 48.3811C39.2382 47.7731 40.7313 47.4691 41.8668 46.7166C43.045 45.9359 43.9592 44.8168 44.4892 43.5065C45 42.2438 45 40.72 45 37.6725Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_shoulder_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>