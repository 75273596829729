<template>
<svg width="49" height="32" viewBox="0 0 49 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0761 0.164435C14.7732 0.197273 0.36377 2.44447 0.36377 2.44447V28.8929C0.36377 28.8929 15.3307 31.207 24.9881 31.1729C34.291 31.1401 48.7005 28.8929 48.7005 28.8929V2.44447C48.7005 2.44447 33.7336 0.130346 24.0761 0.164435Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'upper_abdomen',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
