<template>
	<div class="disclaimer"><b>Disclaimer - </b>{{ content }}</div>
</template>
<script>
export default {
	name: 'article-disclaimer',

	props: {
		content: String
	}
}
</script>
<style lang="scss" scoped>
.disclaimer {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #525252;
}
</style>
