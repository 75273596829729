<template>
	<svg width="44" height="26" viewBox="0 0 44 26" fill="none" xmlns="http://www.w3.org/2000/svg">
	<ellipse opacity="0.9" cx="22" cy="13" rx="22" ry="13" :fill="color"/>
	</svg>
</template>
<script>
export default {
	name: 'head',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
