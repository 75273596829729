<template>
<svg width="19" height="47" viewBox="0 0 19 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M14.7096 40.8168C13.7347 45.4591 12.3562 46.6012 10.869 46.796C9.38184 46.9909 7.74682 41.7635 6.36091 38.2563C4.62526 33.864 4.03705 31.199 3.49596 26.5073C3.03765 22.5335 3.01896 20.531 2.40754 17.0699C1.8261 13.7785 0.497533 9.23697 0.497533 9.23697C0.497533 9.23697 -0.813881 2.56164 2.0645 1.00322C4.40001 -0.261275 7.64239 1.36633 8.75084 2.24534C10.9671 4.00282 13.8252 6.38955 15.6852 10.0019C17.6598 13.8369 18.5771 16.5489 17.9389 20.8149C17.473 23.9288 15.4854 25.1616 14.7672 28.2271C13.6945 32.8057 15.676 36.2147 14.7096 40.8168Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_side_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
