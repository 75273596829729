import Vue from 'vue'

function add(state, obj) {
	if (! state.data[obj.type])
		Vue.set(state.data, obj.type, {})
	Vue.set(state.data[obj.type], obj.id, obj)
}

export default {
	namespaced: true,
    state: {
		data: {},
		profile: {},
		loading: {}
	},
    actions: {
		getProfile({ state }, query) {

		},
		getType({ state }, query) {

		},
		loadObject({ state, dispatch, commit, rootState }, query) {
			if ((! query.type || state.loading[query.type]) && !query.next)
				return Promise.resolve()

			commit('startLoadingType', query.type)

			var url = '/user/' + rootState.auth.id + '/content/' + query.type + '/' + query.id

			if (rootState.auth.handoff_token) {
				url += '?auth=' + rootState.auth.id + '&handoff_token=' + rootState.auth.handoff_token
			}
			return dispatch('get', {
				url
			}, { root: true })
			.then((response) => {
				commit('finishLoadingType', query.type)
				commit('cacheObject', response)
				let allSubQuestions = []
				if (response.hasOwnProperty('question') && response.question)
					response.question
					.forEach(q => { 
						if(q.type === 'questionnaire' && q.questionnaire)
							allSubQuestions.push(dispatch('loadObject', { type: q.type, id: q.questionnaire, next: true }) )
						else if(Array.isArray(q.options) && q.options.length > 0 && q.options.some(({questionnaire}) => questionnaire)) {
							q.options.filter(({questionnaire}) => questionnaire).forEach((branchQ) => {
								allSubQuestions.push(dispatch('loadObject', { type: 'questionnaire', id: branchQ.questionnaire, next: true }))
							})
						}
							
					})
				return Promise.all(allSubQuestions).finally((_) => {
					return response
				})
			})
			.catch((error) => {
				console.log(error)
				commit('finishLoadingType', query.type)
				throw error
			})
		}
	},
	getters: {
		getObject(state) {
			return (type, id) => {
				if (state.data[type]) {
					if (Array.isArray(id))
						return id.map((i) => state.data[type][i])
					else {
						return state.data[type][id]
					}
				}
			}
		},
		getObjectKey(state) {
			return (type, id, key) => {
				if (state.data[type] && state.data[type][id])
					return state.data[type][id][key]
			}
		},
		getObjectType(state) {
			return (type) => state.data[type]
		},
		getProfileContent(state) {
			return (profile, type) => {
				let content = state.profile[profile]
				return (content && type) ? content[type] : (content || [])
			}

		}
	},
	mutations: {
		startLoadingType(state, type) {
			Vue.set(state.loading, type, true)
		},
		finishLoadingType(state, type) {
			Vue.delete(state.loading, type)
		},
		cache(state, content) {
			Object.keys(content).forEach((type) => {
				if (! state.data[type])
					Vue.set(state.data, type, {})
				Object.keys(content[type]).forEach((id) => {
					Vue.set(state.data[type], id, content[type][id])

					// Profile can have nested types
					if (type === 'profile') {
						Object.keys(content[type][id]).forEach((k) => {
							let value = content[type][id][k]
							if (Array.isArray(value))
								value.forEach((v) => {
									if (! state.data[v.type])
										Vue.set(state.data, v.type, {})
									Vue.set(state.data[v.type], v.id, v)
								})
						})
					}
				})
			})
		},
		cacheProfile(state, profile) {
			Object.keys(profile).forEach((k) => {
				if (Array.isArray(profile[k]))
					profile[k].forEach((v) => {
						if (! state.data[v.type])
							Vue.set(state.data, v.type, {})
						Vue.set(state.data[v.type], v.id, v)

						// Add each article/exercise/etc in the category
						if (v.type == 'category') {
							Object.keys(v).forEach((c) => {
								if (Array.isArray(v[c]))
									v[c].forEach((sub) => {
										if (! state.data[sub.type])
											Vue.set(state.data, sub.type, {})
										Vue.set(state.data[sub.type], sub.id, sub)
									})
							})
						}
					})
			})
		},
		cacheObject(state, obj) {
			if (typeof obj !== 'object' || ! obj.type || ! obj.id)
				return

			if(obj.type === 'questionnaire')
				obj.question = (obj.question || []).filter((question) => !question.archive)

			if (! state.data[obj.type])
				Vue.set(state.data, obj.type, {})
			Vue.set(state.data[obj.type], obj.id, obj)
		},
		addProfile(state, profile) {
			state.profile[profile.id] = profile
			Object.keys(profile).forEach((k) => {
				if (Array.isArray(profile[k]))
					profile[k].forEach((obj) => add(state, obj))
			})
		},
		addContent(state, content) {
			if (Array.isArray(content))
				content.forEach((c) => add(state, c))
			else
				add(state, c)
		}
	}
}
