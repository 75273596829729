<template>
    
    <svg width="21" height="73" viewBox="0 0 21 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.5475 64.5C17.2287 66.3698 16.6329 68.0211 15.8543 69.4766C11.8143 77.0279 3.0014 70.5641 3.00163 62C3.00168 60.2881 2.0949 42 1.0492 35C0.00349784 28 -0.452854 14.5 1.04778 8.5C1.362 7.60483 1.68361 6.76657 2.00464 5.98651C3.91596 1.34217 9.2295 -0.188692 13.8825 1.70144C18.6467 3.63675 21.4662 8.58083 20.7221 13.669C19.6479 21.0138 18.1894 31.1756 17.5475 36.5C16.2387 47.3561 19.5933 52.5 17.5475 64.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>