<template>
    <div class="box-section">
        <div v-if="exercise" class="image-title">
            <img 
                :src="getExerciseImage()">
            <span class="title">
                Exercise
            </span>
            <span class="count">
                {{ this.getCount('exercise') }}
            </span>
        </div>
        <div v-if="mindfulness" class="image-title">
            <img 
                :src="getMindfulnessImage()">
            <span class="title">
                Mindfulness
            </span>
            <span class="count">
                {{ this.getCount('mindfulness') }}
            </span>
        </div>
        <div v-if="yoga" class="image-title">
            <img 
                :src="getYogaImage()">
            <span class="title">
                Yoga
            </span>
            <span class="count">
                {{ this.getCount('yoga') }}
            </span>
        </div>
    </div>
</template>
<script>

export default {

	props: {
        topics: {
            type: Object
        }
	},
    data() {
        return {
            exercise: this.topics.exercise,
            mindfulness: this.topics.mindfulness,
            yoga: this.topics.yoga
        }
    },
    mounted () {
    },
	methods: {
        getExerciseImage() {
            return require('../image/exercise.png')
        },
        getMindfulnessImage() {
            return require('../image/mindfulness.png')
        },
        getYogaImage() {
            return require('../image/yoga.png')
        },
        getCount(topic) {
            return `${this[topic].count > 1 ? this[topic].count + ' times' : ' 1 time'}`
        }
    }
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
