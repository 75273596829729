<template>
    <div label-width="0" class="compact-preview">

        <el-upload action=""
                   :show-file-list="false"
                   :before-upload="handleChange"
                   :file-list="uploads">
            <img v-if="uploading" src="../../../../image/loading.gif" class="loader">
            <div class="upload-field">
                <el-button :disabled="uploading" v-if="!uploads.length && !uploading" size="small" class="compact-button">{{ uploadMessage }}</el-button>
                <span v-else-if="uploading" style="font-size: 1rem; color: #333333; top: 90px" class="please-wait">Adding Image...</span>
                <span v-if="uploading" class="please-wait">please wait...</span>
            </div>
        </el-upload>

        <el-button v-if="uploading" icon="el-icon-close"
                   class="compact-abort-button"
                   size="small"
                   @click="handleAbort">
            Cancel
        </el-button>

        <div v-if="!uploading" class="image-wrapper">
            <div class="preview" v-if="hidePreview">
                <slot></slot>
            </div>
            <template v-else>
                <img v-if="previewImage" class="compact-preview-image" :src="previewImage">
                <el-button v-if="!multiple && !uploading && uploads.length > 0"
                           icon="el-icon-close" class="compact-delete-button"
                           size="small" @click="remove">
                    Delete Picture
                </el-button>
            </template>
        </div>


        <textarea rows="5" class="text-area"
                  v-model="textarea"
                  placeholder="You may add a description here.">
        </textarea>

    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'
    import { Upload, Progress, Input, Message } from 'element-ui'
    import { mapActions, mapGetters } from 'vuex'

    Vue.use(Upload)
    Vue.use(Progress)
    Vue.use(Input)
    Vue.prototype.$message = Message;

    export default {
        name: 'image_upload',
        extends: Question,

        props: {
            buttonText: {
                type: String
            },
            prefix: {
                type: String
            },
            multiple: {
                type: Boolean,
                default: false
            },
            hidePreview: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            textarea: function() {
                let reqObj = {
                    id: uuidv4(),
                    value: (this.uploads.length && this.uploads[0].hasOwnProperty('url')) ? this.uploads[0].url : '',
                    answer: this.textarea
                }
                if(this.question.category_type)
                    reqObj['category_type'] = this.question.category_type
                this.$emit('selected', [ reqObj ])
            },
            uploading: function(newValue) {
                    this.$emit('disableNext', newValue)
            }
        },

        data() {
            var data = {
                uploading: false,
                uploads: []
            }

            return {
                ...data,
                textarea: '',
                source: axios.CancelToken.source()
            }
        },

        mounted() {
            if (!this.saved || this.saved.length === 0)
                this.$emit('selected', [])
            else {
                if (this.saved.length && this.saved[0].value) this.uploads = [ { url: this.saved[0].value } ]
                this.textarea = this.saved[0].answer
            }
        },

        computed: {
            ...mapGetters('user', [
                'getProgressPercent'
            ]),
            previewImage() {
                return this.uploads.length > 0 ? this.uploads[0].url : ''
            },
            uploadMessage() {
                return (this.uploading) ? 'Adding Image...' : '+ Add Picture'
            }
        },

        methods: {
            ...mapActions('user', [
                'cdn'
            ]),
            handleAbort() {
                this.source.cancel()
            },
            handleChange(file) {
                if (!file.type.includes('image')) {
                    this.$message.error({ message: 'Please upload an image file' })
                    return
                }
                this.uploading = true

                // cancelToken for cancelling the upload in-flight
                this.source = axios.CancelToken.source()
                let cancelToken = this.source.token

                this.cdn({ file, cancelToken })
                    .then(response => {
                        this.uploading = false
                        this.uploads = [ { url: response.url } ]
                        let reqObj = {
                            id: uuidv4(),
                            value: response.url,
                            answer: this.textarea
                        }
                        if(this.question.category_type)
                            reqObj['category_type'] = this.question.category_type
                        this.$emit('selected', [ reqObj ])
                    })
                    .catch(err => {
                        this.uploading = false
                        console.log('Error', err)
                        if (file.type.includes('image')) this.$message.error({message: 'Image upload failed'})
                        else this.$message.error({ duration: '100', message: 'Upload failed'})
                    })
                return false
            },
            remove(e) {
                this.uploads = []
                let reqObj = {
                    id: uuidv4(),
                    value: '',
                    answer: this.textarea
                }
                if(this.question.category_type)
                    reqObj['category_type'] = this.question.category_type
                this.$emit('selected', [ reqObj ])
                // this.uploadAndSave()
                e.stopPropagation()
            }
        },

        components: {
        }
    }
</script>

<style lang="scss" scoped>
    $choice-size: 150px;
    $choice-font-size: 20px;
    @import "style.scss";

    .compact-preview {
        height: auto;
        max-height: 100%;
        width: 100%;
        position: relative;
    }
    .upload-field {
        height: 200px;
        width: 100%;
        border: 1.5px dashed #999999;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -50%;
    }
    .text-area {
        position: absolute;
        top: 215px;
        left: 0;
        height: 90px;
        width: 100%;
        border-radius: 0 !important;
        color: #333333;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #999999;
        outline: none;
    }
    .text-area:focus {
        outline: none;
        border: 2px solid #833e93;
        color: black;
    }
    .compact-preview >>> .el-form-item__content {
        width: 269px;
        position: absolute;
        top: 40px;
        left: 0;
    }
    .preview-image {
        width: 300px;
        height: auto;
        border: solid 1px #dfdfdf;
        border-radius: 4px;
        margin-top: 5px;
    }
    audio, video {
        margin-top: 5px;
    }
    .profile-copy {
        margin: 0px;
        display: flex;
        align-items: center;
        height: 100%;
    }
    .profile-copy>span {
        padding: 6px 0px;
        background-color: transparent;
        font-size: 14px;
        display: inline-block;
    }
    .profile-copy>.icon {
        display: none;
        margin: 1em auto 0px auto;
        cursor: pointer;
    }
    .abort-button {
        position: absolute;
        top: 2px;
        left: 135px;
    }
    .image-wrapper {
        width: 98%;
        height: 196px;
        position: absolute;
        top: 2px;
        left: 51%;
        margin-left: -50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .compact-preview-image {
        width: auto;
        max-width: 98%;
        height: auto;
        max-height: 196px;
    }
    .compact-button, .compact-button:hover {
        width: 160px;
        height: 36px;
        border-radius: 18px;
        background-color: #f2e0f7;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        position: absolute;
        top: 80px;
        left: 50%;
        margin-left: -80px;
        z-index: 2;
    }
    .please-wait {
        color: #999999;
        font-size: .8rem;
        width: 160px;
        position: absolute;
        top: 120px;
        left: 50%;
        margin-left: -80px;
        z-index: 2;
    }
    .compact-delete-button, .compact-delete-button:hover, .compact-delete-button:focus, .compact-delete-button:visited {
        width: 159px;
        height: 36px;
        opacity: 0.46;
        border-radius: 18px;
        background-color: #000000;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        position: absolute;
        bottom: 5px;
        left: 50%;
        margin-left: -80px;
        outline: none;
    }
    .compact-abort-button, .compact-abort-button:hover {
        width: 159px;
        height: 36px;
        border: none;
        border-radius: 18px;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        color: #da4e4e;
        position: absolute;
        top: 160px;
        left: 50%;
        margin-left: -80px;
    }
    .compact-progress {
        position: absolute;
        top: 60px;
        left: 50%;
        margin-left: -85px;
        width: 170px;
    }
    .loader {
        height: 30px;
        width: 30px;
        position: absolute;
        top: 45px;
        left: 50%;
        margin-left: -15px;
    }
</style>
