<template>
	<div class="slider hide-numbers" :key="renderKey" style="padding-left: 25px">
		<div class="hiding-element" :style="'height: ' + (getMax * 42 + 20) + 'px'"></div>
		<div class="slider-bar">
			<img v-if="showThumb"
				 src="../image/thumb_active.svg"
				 class="thumb-image" :style="'bottom: ' + getThumbHeight">
			<img v-if="!showThumb"
				 src="../image/thumb_inactive.svg"
				 :class="[getMax > 1 ? 'element' : '',  'thumb-image']" :style="'bottom: ' + getThumbHeight">
			<vue-slider
					ref="slider"
					class="left"
					v-model="value"
					v-bind="options"
					:marks="'marks'"
					:data="marksMap">
			</vue-slider>
			<div class="disabled-option" @click="selectDisabledOption"></div>
		</div>
	</div>
</template>


<script>
	import VueSlider from 'vue-slider-component'
	import 'vue-slider-component/theme/antd.css'
	import Question from './index'
	import { v4 as uuidv4 } from 'uuid'

	const SINGLE = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
	const FIRST = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
	const DOUBLE = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"


	export default {

		name: 'questionnaire-slider',
		extends: Question,
		props: {
			saved: {
				type: Array
			}
		},
		data() {
			return {
				renderKey: 0,
				showThumb: false,
				value: this.getValue(),
				options: {
					// Go to nearest value
					adsorb: true,
					duration: 0.3,

					height: this.getMax > 1 ? this.getMax * 42: 10,
					width: 5,

					direction: 'btt',
					min: 1,
					max: this.getMax,
					useKeyboard: true,
					tooltip: 'none',
					included: true,
					processStyle: {
						cursor: 'pointer',
						backgroundColor: '#833e93',
						'overflow-wrap': 'break-word',
						'word-wrap': 'break-word',
						'hyphens': 'auto'
					},
					dotStyle: {
						display: 'none',
						cursor: 'pointer',
						borderColor: '#833e93',
						backgroundColor: '#833e93',
						boxShadow: '0 0 0 2px #833e93',
					},
					labelActiveStyle: {
						cursor: 'pointer',
					},
					labelStyle: {
						cursor: 'pointer',
						fontSize: '14px',
						marginTop: '1px',
						marginLeft: '-39px',
						fontWeight: '400',
						'overflow-wrap': 'break-word',
						'word-wrap': 'break-word',
						'hyphens': 'auto'
					},
					stepStyle: {
						left: '-12px',
						top: '2px',
						width: '30px',
						borderRadius: '0',
						height: '2px',
						backgroundColor: '#f2e8f4',
						padding: '0',
						boxShadow: 'none'
					},
					stepActiveStyle: {
						cursor: 'pointer',
						borderColor: '#f2e8f4',
						backgroundColor: '#f2e8f4',
					},
					railStyle: {
						backgroundColor: '#f2e8f4',
						width: '104px',
						margin: '0 60px 0 0',
						borderRight: '100px solid #ffffff',
						borderRadius: '0'
					}
				}
			}
		},
		watch: {
			value(value) {
				this.showActiveColors()
				this.$emit('selected', [ this.getSliderValue(value) ])
				this.showThumb = true
				this.renderKey += 1 
			}
		},
		// Note: when sending binded data back to be saved, use '-' and ' - '
		// instead of a bunch of spaces
		mounted() {
			// Filling the label array in question using max key in case some indices are missing.
			if (this.question && this.question.max) {
				for (var i = 0; i < this.question.max; i++)
					if (!this.question.label[i]) this.question.label[i] = ''
			}
			this.$nextTick(() => {
				this.options.height = this.getMax > 1 ? this.getMax * 42 : 10
				this.options.max = this.getMax
			})
			// If there is already a saved slider value
			if (this.saved) {
				this.$emit('selected', this.saved)
				this.showActiveColors()
				this.showThumb = true
			}
		},
		methods: {
			getSliderValue(value) {
				var split = value.split('\xa0')
				var string = ''

				// Check if multiple spaces exists
				if (value.includes('\xa0'))
					string = split[split.length-1]
				
				let index = parseInt(split[0]) - 1

				var match = ''
				if (this.question.hasOwnProperty('options')) {
					let found = this.question.options.find(opt => opt.value === String(index))
					if (found) {
						return {...found}
					} else return ''
				} else {
					match = {
						id: uuidv4(),
						description: String(string),
						value: String(index)
					}
				}
				return match
			},
			selectDisabledOption() {
				this.$refs.slider.setIndex(0)
			},
			showActiveColors() {
				this.options.labelActiveStyle.color = '#833e93'
				this.options.labelActiveStyle.fontWeight = '600'
				this.options.stepActiveStyle.borderColor = '#833e93'
				this.options.stepActiveStyle.backgroundColor = '#833e93'
			},
			// For setting the value of saved array from local storage on mounting.
			// converting JSON format to vertical slider format
			getValue() {
				if (this.saved && this.saved.length) {
					let string = ''
					let optionIndex = null
					if (this.question.hasOwnProperty('options')) {
						this.question.options.forEach((option, index) => {
							if (option.value === this.saved[0].value) optionIndex = index + 1 
						})
					} else {
						Object.entries(this.question.label).forEach((entry) => {
							if (entry[0] == this.saved[0].value) optionIndex = parseInt(entry[0]) + 1
						})
					}

					if (!optionIndex) return ''

					// Check if extra space is unnecessary for values with more than 1 digit
					if (optionIndex >= 10)
						string = DOUBLE
					else
						string = SINGLE

					if (!this.saved[0].hasOwnProperty('description') || !this.saved[0].description.length)
						return String(optionIndex)
					return optionIndex + string + this.saved[0].description
				}
				else
					return ''
			}
		},
		computed: {
			getMax() {
				if (this.question.hasOwnProperty('options'))
					return this.question.options.length
				return this.question.max
			},
			getThumbHeight() {
				let curValue = parseInt(this.getValue())
				// calculating total height of the slider including the top/bottom padding/margin 
				let totalHeight = (this.getMax * 42) + (this.getMax <= 8 ? 50 : 46)
				//calculating section height by dividing the total height by total number of questions
				let sectionHeight = totalHeight/this.getMax

				if (!this.showThumb) return this.getMax > 1 ? '-15px': '-11px'
				return ((curValue - 1) * sectionHeight) + 'px'
			},
			marks() {
				var arr = []
				var max = this.getMax
				for (var i = 1; i <= max; i++){
					arr.push(i)
				}
				return arr
			},

			marksMap() {
				var arr = []
				var max = this.getMax
				var string = ''
				for (var i = max; i >= 1; i--) {

					let value = ''
					if (this.question.hasOwnProperty('options'))
						value = this.question.options[max-i].description
					else
						value = this.question.label[max-i]

					if (value) {
						if (i == max)
							string = FIRST
						else if ((max - i > 8) && max > 9)
							string = DOUBLE
						else
							string = SINGLE

						arr.push(max - i + 1 + string + value)
					} else {
						arr.push(max - i + 1 + '')
					}
				}
				return arr
			},

		},

		components: {
			VueSlider
		}
	}

</script>

<style lang="scss" scoped>
	.disabled-option {
		cursor: pointer;
		position: absolute;
		z-index: 10000;
		bottom: -2.5%;
		width: 275px;
		margin-left: -35px;
		height: 20px;
		background-color: transparent;
		// border: 1px solid red;
	}
	.slider {
		height: auto;
		padding: 10px 0 30px 0;
		display: flex;
		justify-content: flex-start;
		.number-bar {

		}
		.slider-bar {
			margin: 10px 0 -5px 15px;
			position: relative;
		}
		.thumb-image {
			position: absolute;
			left: -38px;
			z-index: 91;
		}
		.numbers {
			font-size: 1pt;
			font-weight: 600;
			margin-bottom: 25px;
		}
	}
	.hide-numbers {
		.hiding-element {
			z-index: 90;
			width: 20px;
			margin-right: -7px;
			background-color: #ffffff;
		}
	}


	.element {
		animation: nudge 2s linear infinite alternate;
	}

	@keyframes nudge {
		0%, 100% {
			transform: translate(0, -15px);
		}

		50% {
			transform: translate(0, -60px);
		}
	}
</style>
