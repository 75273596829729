<template>
<svg width="56" height="64" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M44.1062 52.2677C33.3813 63.1889 12.1 67.8183 3.63042 59.5011C-4.83914 51.1838 3.63042 38.0075 12.6834 25.5639C21.7364 13.1203 43.2384 -5.94383 51.708 2.37342C60.1775 10.6907 54.8311 41.3464 44.1062 52.2677Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_inner_hip_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
