<template>
    <div class="wrapper human-map">
        <div
            :key="getSubType" 
            :is="getSubComponent(getSubType)" 
            class="human-container" 
            ref="human-container"
			:question="question"
            @changeHumanMapSide="changeHumanMapSide"
            :saved="saved"
            v-on:selectbp="selectBodyPart"
			:result="result">
		</div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Question from './index'
    import { RadioGroup, RadioButton } from 'element-ui'
    import Rash from '../question/components/Rash.vue'
    import GeneralLocation from '../question/components/GeneralLocation.vue'
    import SwollenLymphNodes from '../question/components/SwollenLymphNodes.vue'
    import BreastProblems from '../question/components/BreastProblems.vue'
    import Abdomen from '../question/components/Abdomen.vue'
    import ChestThoracic from '../question/components/ChestThoracic.vue'
    import Back from '../question/components/Back.vue'
    import Hip from '../question/components/Hip.vue'
    import Shoulder from '../question/components/Shoulder.vue'
    import Arm from '../question/components/Arm.vue'
    import Hand from '../question/components/Hand.vue'
    import Head from '../question/components/Head.vue'
    import Leg from '../question/components/Leg.vue'
    import Knee from '../question/components/Knee.vue'
    import Ankle from '../question/components/Ankle.vue'
    import Swelling from '../question/components/Swelling.vue'
    import Foot from '../question/components/Foot.vue'

    Vue.use(RadioGroup)
    Vue.use(RadioButton)

    const humanMap = [
        { id: 'head_f', side: 'Front', tooltip: 'Head Front', image: 'front_head' },
        { id: 'throat', side: 'Front', tooltip: 'Throat', image: 'front_neck' },
        { id: 'right_upper_body', side: 'Front', tooltip: 'Right Upper Body (F)', image: 'front_right_upper_body' },
        { id: 'left_upper_body', side: 'Front', tooltip: 'Left Upper Body (F)', image: 'front_left_upper_body' },
        { id: 'right_lower_body', side: 'Front', tooltip: 'Right Lower Body (F)', image: 'front_right_lower_body' },
        { id: 'left_lower_body', side: 'Front', tooltip: 'Left Lower Body (F)', image: 'front_left_lower_body' },
        { id: 'right_upper_arm_f', side: 'Front', tooltip: 'Right Upper Arm (F)', image: 'front_right_upper_arm' },
        { id: 'left_upper_arm_f', side: 'Front', tooltip: 'Left Upper Arm (F)', image: 'front_left_upper_arm' },
        { id: 'right_lower_arm_f', side: 'Front', tooltip: 'Right Lower Arm (F)', image: 'front_right_lower_arm' },
        { id: 'left_lower_arm_f', side: 'Front', tooltip: 'Left Lower Arm (F)', image: 'front_left_lower_arm' },
        { id: 'right_hand_f', side: 'Front', tooltip: 'Right Hand (F)', image: 'front_right_hand' },
        { id: 'left_hand_f', side: 'Front', tooltip: 'Left Hand (F)', image: 'front_left_hand' },
        { id: 'right_upper_leg_f', side: 'Front', tooltip: 'Right Upper Leg (F)', image: 'front_right_upper_leg' },
        { id: 'left_upper_leg_f', side: 'Front', tooltip: 'Left Upper Leg (F)', image: 'front_left_upper_leg' },
        // { id: 'left_groin', side: 'Front', tooltip: 'Left Groin', image: 'front_left_groin'},
        // { id: 'right_groin', side: 'Front', tooltip: 'Right Groin', image: 'front_right_groin'},
        { id: 'right_knee', side: 'Front', tooltip: 'Right Knee', image: 'front_right_knee' },
        { id: 'left_knee', side: 'Front', tooltip: 'Left Knee', image: 'front_left_knee' },
        { id: 'right_lower_leg_f', side: 'Front', tooltip: 'Right Lower Leg (F)', image: 'front_right_lower_leg' },
        { id: 'left_lower_leg_f', side: 'Front', tooltip: 'Left Lower Leg (F)', image: 'front_left_lower_leg' },
        { id: 'right_foot_f', side: 'Front', tooltip: 'Right Foot (F)', image: 'front_right_foot' },
        { id: 'left_foot_f', side: 'Front', tooltip: 'Left Foot (F)', image: 'front_left_foot' },
        // back body
        { id: 'head_b', side: 'Back', tooltip: 'Head Back', image: 'back_head' },
        { id: 'right_neck', side: 'Back', tooltip: 'Right Neck (B)', image: 'back_right_neck' },
        { id: 'left_neck', side: 'Back', tooltip: 'Left Neck (B)', image: 'back_left_neck' },
        { id: 'right_upper_back', side: 'Back', tooltip: 'Right Upper Back (B)', image: 'back_right_upper_back' },
        { id: 'left_upper_back', side: 'Back', tooltip: 'Left Upper Back (B)', image: 'back_left_upper_back' },
        { id: 'right_lower_back', side: 'Back', tooltip: 'Right Lower Back (B)', image: 'back_right_lower_back' },
        { id: 'left_lower_back', side: 'Back', tooltip: 'Left Lower Back (B)', image: 'back_left_lower_back' },
        { id: 'right_upper_arm_b', side: 'Back', tooltip: 'Right Upper Arm (B)', image: 'back_right_upper_arm' },
        { id: 'left_upper_arm_b', side: 'Back', tooltip: 'Left Upper Arm (B)', image: 'back_left_upper_arm' },
        { id: 'right_elbow', side: 'Back', tooltip: 'Right Elbow', image: 'back_right_elbow' },
        { id: 'left_elbow', side: 'Back', tooltip: 'Left Elbow', image: 'back_left_elbow' },
        { id: 'right_lower_arm_b', side: 'Back', tooltip: 'Right Lower Arm (B)', image: 'back_right_lower_arm' },
        { id: 'left_lower_arm_b', side: 'Back', tooltip: 'Left Lower Arm (B)', image: 'back_left_lower_arm' },
        { id: 'right_hand_b', side: 'Back', tooltip: 'Right Hand (B)', image: 'back_right_hand' },
        { id: 'left_hand_b', side: 'Back', tooltip: 'Left Hand (B)', image: 'back_left_hand' },
        { id: 'right_buttock', side: 'Back', tooltip: 'Right Buttocks', image: 'back_right_buttocks' },
        { id: 'left_buttock', side: 'Back', tooltip: 'Left Buttocks', image: 'back_left_buttocks' },
        { id: 'right_upper_leg_b', side: 'Back', tooltip: 'Right Upper Leg (B)', image: 'back_right_upper_leg' },
        { id: 'left_upper_leg_b', side: 'Back', tooltip: 'Left Upper Leg (B)', image: 'back_left_upper_leg' },
        { id: 'right_lower_leg_b', side: 'Back', tooltip: 'Right Lower Leg (B)', image: 'back_right_lower_leg' },
        { id: 'left_lower_leg_b', side: 'Back', tooltip: 'Left Lower Leg (B)', image: 'back_left_lower_leg' },
        { id: 'right_foot_b', side: 'Back', tooltip: 'Right Foot (B)', image: 'back_right_foot' },
        { id: 'left_foot_b', side: 'Back', tooltip: 'Left Foot (B)', image: 'back_left_foot' }
    ]

    const componentMap = {
        'general_location': 'GeneralLocation',
        'rash': 'Rash',
        'swollen_lymph_nodes': 'SwollenLymphNodes',
        'breast_problems': 'BreastProblems',
        'abdominal': 'Abdomen',
        'chest_thoracic': 'ChestThoracic',
        'back': 'Back',
        'hip': 'Hip',
        'shoulder': 'Shoulder',
        'arm': 'Arm',
        'hand': 'Hand',
        'head': 'Head',
        "leg": "Leg",
        "knee": "Knee",
        "ankle": "Ankle",
        "swelling": "Swelling",
        "foot_toes": "Foot"
    }

    export default {
        name: 'human_map',
        extends: Question,

        watch: {
            humanSide: function() {
                this.$nextTick(() => this.removeTooltips())
            }
        },
        data() {
            return {
                humanMap: JSON.parse(JSON.stringify(humanMap)),
                humanSide: 'Front',
                selectedParts: [] || this.saved,
                unsortedBodyParts: [] || this.saved
            }
        },
        mounted() {
            this.loadData()
        },

        methods: {
            /**
             * @param  {String} side
             * @desc Changes the side of the human map eg: Front, Back, Side, Body
             */
            changeHumanMapSide(side) {
                this.$emit('changeHumanMapSide', side)
            },
            getSubComponent(id) {
                return componentMap[id]
            },
            selectBodyPart(selectedBodyPart) {
                this.$emit('selected', selectedBodyPart)
            },
            getClass(bodyPart) {
                let part = bodyPart
                return part.replace('_active', '')
            },
            /**
             * @func placeTooltip
             * @desc places tooltip on body part selection
             * @param bodyPart(String) string indicating if the body part is active or inactive
             */
            placeTooltip(bodyPart) {
                let imgRef = this.$refs[bodyPart][0]
                let tooltipRef = this.$refs[bodyPart + '_tooltip'][0]
                tooltipRef.style.visibility = 'visible'
                let top = parseInt(window.getComputedStyle(imgRef, null).getPropertyValue('top').replace('px', ''))
                let left = parseInt(window.getComputedStyle(imgRef, null).getPropertyValue('left').replace('px', ''))
                tooltipRef.style.top = (top - 40) + 'px'
                // for body parts on right side of the screen
                if ((this.humanSide === 'Front' && bodyPart.includes('left')) || (this.humanSide === 'Back' && bodyPart.includes('right'))) {
                    tooltipRef.style.left = (left - 90) + 'px'
                }
                // for body parts on left side of the screen
                else if ((this.humanSide === 'Front' && bodyPart.includes('right')) || (this.humanSide === 'Back' && bodyPart.includes('left'))) {
                    tooltipRef.style.left = (left - 30) + 'px'
                }
                // for head
                else if(bodyPart.includes('head') || bodyPart.includes('neck')) {
                    tooltipRef.style.left = (left - 8) + 'px'
                }
                // for middle body parts
                else tooltipRef.style.left = (left + 10) + 'px'
            },
            /**
             * @func removeTooltips
             * @desc removes all tooltips
             * @param tooltipText(String)
             */
            removeTooltips(tooltipText) {
                if (document.getElementsByClassName('tooltiptext')) {
                    let classes = document.getElementsByClassName('tooltiptext')
                    Object.keys(classes).forEach(cls => {
                        if (!tooltipText || (tooltipText && classes[cls].innerHTML.trim() === tooltipText))
                            classes[cls].style.visibility = 'hidden'
                    })
                }
            },
            loadData() {
                if (!this.saved) this.$emit('selected', [])
                else {
                    this.unsortedBodyParts = this.saved
                    this.selectedParts = this.saved
                    this.sortBodyParts()
                    this.$emit('selected', this.saved)
                }
                this.selectedParts.forEach(part => {
                    this.humanMap.forEach(bodyPart => {
                        if (bodyPart.id === part.value) bodyPart.image += '_active'
                    })
                })
                this.$nextTick(() => this.removeTooltips())
            },
            /**
             * @func toggle
             * @desc toggles the state of body part
             * @param bodyPart(String) string indicating if the body part is active or inactive
             */
            toggle(bodyPart) {
                // making inactive
                if (bodyPart.includes('active')) {
                    this.humanMap.forEach(bp => {
                        if (bp.image === bodyPart) {
                            bp.image = bp.image.replace('_active', '')
                            this.selectedParts = this.selectedParts.filter(part => part.value !== bp.id)
                            this.unsortedBodyParts = this.unsortedBodyParts.filter(part => part.value !== bp.id)
                        }
                    })
                }
                // making active
                else {
                    let tooltipText = ''
                    this.humanMap.forEach(bp => {
                        if (bp.image === bodyPart) {
                            bp.image += '_active'
                            tooltipText = bp.tooltip
                            let reqObj = {
                                id: bp.id,
	                            value: bp.id
                            }
                            this.selectedParts.push(reqObj)
                            this.unsortedBodyParts.push(reqObj)
                        }
                    })
                    bodyPart += '_active'
                    // placing tooltip on top of the body part
                    this.$nextTick(() => this.placeTooltip(bodyPart))
                    // remove tooltip after 1.5seconds
                    setTimeout(() => this.removeTooltips(tooltipText), 1500)
                }
                // sort
                this.sortBodyParts()
                this.$emit('selected', this.selectedParts)
            },
            sortBodyParts() {
                let tempSelectedParts = []
                this.humanMap.forEach(bp => {
                    this.selectedParts.forEach(sp => {
                        if (sp.value === bp.id) tempSelectedParts.push(sp)
                    })
                })
                this.selectedParts = tempSelectedParts
            },
            getImage(bodyPart) {
                return require('@/image/questionnaire/human_map/' + bodyPart + '.svg')
            }
        },
        computed: {
            humanSideLowered() {
                return this.humanSide.toLowerCase()
            },
            isOldHumanMap() {
                if (this.question.hasOwnProperty('sub_type'))
                    return false
                return true
            },
            getSubType() {
                return this.question.hasOwnProperty('sub_type') ? this.question.sub_type : 'general_location'
            },
            calcSelectedList() {
                let selectedList = { 'Front': [], 'Back': [] }
                this.unsortedBodyParts.forEach(part => {
                    let partObj = this.humanMap.filter(bodyPart => part.value === bodyPart.id)[0]
                    selectedList[partObj.side].push(partObj.tooltip)
                })
                return selectedList
            }
        },

        components: {
            Rash,
            GeneralLocation,
            SwollenLymphNodes,
            BreastProblems,
            Abdomen,
            ChestThoracic,
            Back,
            Hip,
            Shoulder,
            Arm,
            Hand,
            Head,
            Leg,
            Knee,
            Ankle,
            Swelling,
            Foot
        },
    }
</script>

<style lang="scss" scoped>
    $choice-size: 150px;
    $choice-font-size: 20px;
    @import "style.scss";
</style>
