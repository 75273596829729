<template>
    <div class="display">
        <label>email</label>
        <p>{{ value }}</p>
    </div>
</template>

<script>
import AuthDisplay from '.'

export default {
	name: 'auth-email-display',
	extends: AuthDisplay
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
