import { mapState, mapActions } from 'vuex'

import Inputs from './input/components'
import Displays from './display/components'
import Icons from './icon'
import isAccountGerd from '../../util/is-account-gerd'

export default {

    props: {
        query: {
            type: Object
        }
    },

    data() {
        return {
            // Full name input
            name: '',
            nameError: null,

            // Email input
            email: '',
            emailError: null,

            // Password
            password: '',
            passwordError: null,
            resent: false,

            // Reset Code
            code: '',
            codeError: null,

            resetToken: null,
            upgradeToken: null,

            device: 'web',
            platform: 'Web',

            // UI flag for transitioning to the next view
            transition: false
        }
    },

    mounted() {
        if (this.query) {
            this.setQuery(this.query)
        }
    },

    methods: {
        ...mapActions('auth', {
            postLogin: 'login',
            postInitiateLogin: 'initiateLogin',
            postValidateCode: 'validateCode',
            postJoin: 'join',
            postInitiateJoin: 'initiateJoin',
            postUpgrade: 'upgrade',
            postVerifyUpgrade: 'verifyUpgrade',
            postReset: 'reset',
            postVerifyReset: 'verifyReset',
            postResetPassword: 'resetPassword',
            postVerifyEmail: 'verifyEmail',
            postVerifyPhone: 'verifyPhone',
            postVerifyProviderEmail: 'verifyProviderEmail',
            postVerifySetPassword: 'verifySetPassword',
            setQuery: 'setQuery',
            addPhone: "addPhone",
            addEmail: "addEmail"
        }),

        ...mapActions('user', [
            'loadProvider',
        ]),

        isAccountGerd,

        togglePasswordVisibility() {
			this.showPassword = !this.showPassword
			let type = this.$refs.input.type
			if (type === 'password') this.$refs.input.type = "text"
			else this.$refs.input.type = "password"
		},

        /**
         * @func login
         * @desc Attempts to login with the given email address and password.
         */
        login() {
            if (this.authenticating) return

            return this.postLogin({
                email: this.email,
                password: this.password,
                device: this.device,
                platform: this.platform
            })
            .then(() => {
                this.success()
            })
            .catch((error) => {
                this.passwordError = error.message || 'Incorrect password'
                throw error
            })
        },

        /**
         * @func initiateLogin
         * @desc Initiates a login request.
         */
        initiateLogin() {
            if (this.authenticating) return

            // Reset the error message
            this.emailError = null

            // Dispatch the login initiation action
            return this.postInitiateLogin({
                email: this.email
            })
            // On a successful response, redirect if the user is a light user
            .then((user) => {
                if (user.light)
                    this.$router.push({
                        name: 'upgrade',
                        params: {
                            email: this.email
                        }
                    })
            })
            // Display the error message
            .catch((error) => {
                this.emailError = error.message || 'Invalid email'
                throw error
            })
        },

        /**
         * @func initiateJoin
         * @desc Validates the email address for signing up a new user account.
         */
        initiateJoin() {
            if (this.authenticating) return

            // Reset error messages
            this.emailError = null

            return this.postInitiateJoin({
                email: this.email
            })
            .then((user) => {
                // If this is a light user that is already registered, redirect to the upgrade flow
                if (user.light)
                    this.$router.push({
                        name: 'upgrade',
                        params: {
                            email: this.email
                        }
                    })
            })
            .catch((error) => {
                this.emailError = error.message || 'Invalid email'
                throw error
            })
        },

        /**
         * @func initiateJoinNameCode
         * @desc Attempts to validate the given name and doctor code.
         */
        initiateJoinNameCode() {
            if (this.authenticating) return

            // Reset error messages
            this.nameError = null
            this.codeError = null

            return this.postInitiateJoin({
                email: this.authenticatedEmail,
                name: this.name,
                code: this.code
            })
            .then(() => {

            })
            .catch((error) => {
                this.nameError = error.message || 'Invalid name'
                this.codeError = error.message || 'Invalid code'
                throw error
            })
        },

        /**
         * @func validateCode
         * @desc Attempts to asynchronously validate the doctor code
         */
        validateCode() {
            return this.postValidateCode({
                code: this.code
            })
        },

        /**
         * @func join
         * @desc Attempts to login with the given email address and password.
         */
        join() {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postJoin({
                email: this.email,
                name: this.name,
                code: this.code,
                password: this.password,
                device: this.device,
                platform: this.platform
            })
            .then(() => {
                this.success()
            })
            .catch((error) => {
                this.passwordError = error.message || 'Invalid password'
                throw error
            })
        },

        upgrade() {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postUpgrade({
                email: this.authenticatedEmail,
                upgrade_token: this.authenticatedUpgradeToken || this.upgradeToken,
                password: this.password,
                device: this.device,
                platform: this.platform
            })
            .then((res) => {
                this.success()
                return res
            })
            .catch((error) => {
                this.passwordError = error.message || 'Invalid password'
                console.log(error)
                throw error
            })
        },

        verifyUpgrade() {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postVerifyUpgrade({
                email: this.authenticatedEmail,
                upgrade_token: this.password
            })
            .then((result) => {
                this.password = ''

                // If the user entered their new password (i.e. they completed the upgrade from another device)
                if (! result.set_password)
                    this.success()
            })
            .catch((error) => {
                this.passwordError = error.message || 'Invalid password'
                console.log(error)
            })
        },

        verifyEmail(verification) {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postVerifyEmail(verification)
        },


        verifyPhone(verification) {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postVerifyPhone(verification)
        },

        verifyProviderEmail(verification) {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postVerifyProviderEmail(verification)
        },

        resendUpgrade() {
            if (this.authenticating) return
            this.emailError = null
            this.resent = true

            return this.postInitiateLogin({
                email: this.authenticatedEmail,
                resend: true
            })
            .then((result) => {
                console.log(result)
                return result
            })
            .catch((error) => {
                this.emailError = error.message || 'Invalid email'
                console.log(error)
                throw error
            })
        },

        reset(email) {
            if (this.authenticating) return

            // Reset error message
            this.emailError = null

            return this.postReset({
                email: email ? email : this.email
            })
            .then(() => {
                return
            })
            .catch((error) => {
                this.emailError = error.message || 'Invalid email'
                console.log(error)
            })
        },

        verifyReset(params) {
            if (this.authenticating) return

            return this.postVerifyReset(params)
            // Reset error message
            // this.passwordError = null

            // return this.postVerifyReset({
            //     email: this.authenticatedEmail,
            //     reset_token: this.password
            // })
            // .then((result) => {
            //     console.log(result)
            // })
            // .catch((error) => {
            //     this.passwordError = error.message || 'Invalid password'
            //     console.log(error)
            // })
        },

        resetPassword(params) {
            if (this.authenticating) return

            // Reset error message
            this.passwordError = null

            return this.postResetPassword(params)
            
            // return this.postResetPassword({
            //     email: this.authenticatedEmail,
            //     reset_token: this.authenticatedResetToken || this.resetToken,
            //     password: this.password
            // })
            // .then((result) => {
            //     console.log(result)
            //     this.success()
            // })
            // .catch((error) => {
            //     this.passwordError = error.message || 'Invalid password'
            //     console.log(error)
            // })
        },

        resendReset() {
            if (this.authenticating) return
            this.emailError = null
            this.resent = true

            return this.postReset({
                email: this.email,
                resend: true
            })
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                this.emailError = error.message || 'Invalid email'
                console.log(error)
            })
        },

        success() {
            // this.$emit('success')
            // setTimeout(() => {
            //     this.$router.push(this.redirect || '/')
            // }, 300)
        },

        focus(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].focus()
            }
        }
    },

    computed: {
        ...mapState([
			'redirect'
		]),
		...mapState('auth', [
			'authenticating',
			'authenticated',
            'light'
		]),
        // If the user is already authenticated
        ...mapState('auth', {
            doctor: 'doctor',
            authenticatedId: 'id',
            authenticatedName: 'name',
            authenticatedEmail: 'email',
            authenticatedCode: 'code',
            authenticatedCodeProvider: 'codeProvider',
            authenticatedUpgradeToken: 'upgradeToken',
            authenticatedResetToken: 'resetToken',
            failedValidation: 'failedValidation'
        })
    },

    components: {
        ...Inputs,
        ...Displays,
        ...Icons
    }
}
