<template>
    
    <svg width="42" height="45" viewBox="0 0 42 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.73438 25.706C4.92161 30.9137 6.9394 37.0329 8.01276 40.152C8.45477 41.4365 9.53503 42.3844 10.8683 42.6446C13.8302 43.2228 19.2701 44.1713 23.2344 44.206C27.1979 44.2407 32.5506 43.3868 35.8165 42.7838C37.5517 42.4635 38.8183 40.9977 38.9294 39.2367C39.2204 34.6257 39.7344 25.7038 39.7344 20.206C39.7344 14.5735 40.7258 9.86298 41.7779 6.4498C42.6564 3.59978 40.6746 0.254391 37.693 0.185608C32.596 0.0680237 25.734 0.00993729 21.7344 0.390694C16.8731 0.853474 8.22198 2.73803 3.34221 3.85791C1.30679 4.32502 0.00858688 6.29845 0.373863 8.35459C1.19684 12.9871 2.64964 20.948 3.73438 25.706Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>