import body_f from "./body_f"
import body_b from "./body_b"

import body_f_selected from './body_f_selected'
import body_b_selected from './body_b_selected'

import collarbone from "./collarbone"
import neck_f from "./neck_f"
import right_elbow_f from './right_elbow_f'
import right_fingers_f from './right_fingers_f'
import right_forearm_f from './right_forearm_f'
import right_palm from './right_palm'
import right_shoulder_f from './right_shoulder_f'
import right_upper_arm_f from './right_upper_arm_f'
import left_elbow_f from './left_elbow_f'
import left_fingers_f from './left_fingers_f'
import left_forearm_f from './left_forearm_f'
import left_palm from './left_palm'
import left_upper_arm_f from './left_upper_arm_f'
import left_shoulder_f from './left_shoulder_f'

import neck_b from "./neck_b"
import right_elbow_b from './right_elbow_b'
import right_fingers_b from './right_fingers_b'
import right_forearm_b from './right_forearm_b'
import right_hand_b from './right_hand_b'
import right_shoulder_b from './right_shoulder_b'
import right_upper_arm_b from './right_upper_arm_b'
import left_elbow_b from './left_elbow_b'
import left_fingers_b from './left_fingers_b'
import left_forearm_b from './left_forearm_b'
import left_hand_b from './left_hand_b'
import left_upper_arm_b from './left_upper_arm_b'
import left_shoulder_b from './left_shoulder_b'
import upper_back from "./upper_back"



export default {
    body_f,
    body_b,
    body_f_selected,
    body_b_selected,
    collarbone,
    neck_f,
    right_elbow_f,
    right_fingers_f,
    right_palm,
    right_forearm_f,
    right_shoulder_f,
    right_upper_arm_f,
    left_elbow_f,
    left_fingers_f,
    left_forearm_f,
    left_palm,
    left_upper_arm_f,
    left_shoulder_f,
    neck_b,
    right_elbow_b,
    right_fingers_b,
    right_hand_b,
    right_forearm_b,
    right_shoulder_b,
    right_upper_arm_b,
    left_elbow_b,
    left_fingers_b,
    left_forearm_b,
    left_hand_b,
    left_upper_arm_b,
    left_shoulder_b,
    upper_back
}