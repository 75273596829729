export default function({ dispatch, commit }, link) {
    // commit('startedAuthentication')

    // POST /auth/login
    // return dispatch('post', {
    //     url: '/auth/link',
    //     data: { link }
    // }, {
    //     root: true
    // })
    // .then((token) => {
    //     commit('finishedAuthentication')
    //     commit('receivedToken', token)
    //     return token
    // })
    // .catch((error) => {
    //     commit('finishedAuthentication')
    //     throw error
    // })
}
