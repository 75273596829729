<template>
    <svg width="41" height="52" viewBox="0 0 41 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M37.8751 42.7242C38.1114 42.2783 38.0495 41.7491 37.8238 41.2978L32.1756 29.9999L27.4797 20.4832C26.9559 19.4217 25.6298 19.0448 24.6261 19.6722L24.1379 19.9774C23.225 20.548 22.9276 21.7389 23.465 22.6717L34.9369 42.5855C35.0938 42.8579 35.3108 43.0934 35.5925 43.2332C36.0268 43.4488 36.7079 43.707 37.176 43.4999C37.4723 43.3688 37.7076 43.0404 37.8751 42.7242Z" :fill="color"/>
<path opacity="0.9" d="M39.0262 20.0308C39.009 20.0093 38.9923 19.9889 38.9752 19.9672L33.5006 13L27.6538 4.22879C27.5524 4.07678 27.4307 3.93941 27.292 3.82054V3.82054C25.9982 2.71189 23.9998 3.63113 23.9997 5.33496L23.9996 7.21416C23.9996 7.71902 24.1905 8.2052 24.534 8.57515L36.909 21.9023C36.9694 21.9673 37.0247 22.0365 37.0829 22.1035C37.3259 22.3838 38.0758 23.097 39.0003 22.4994C40.2644 21.6824 39.2076 20.2584 39.0262 20.0308Z" :fill="color"/>
<path opacity="0.9" d="M29.085 50.759C29.5716 50.294 29.673 49.5758 29.452 48.9401L25.1712 36.6264L20.6972 23.9787C20.3917 23.1151 19.5267 22.5801 18.6176 22.6926V22.6926C17.3697 22.8469 16.5913 24.1237 17.0255 25.3037L25.9828 49.6432C26.0606 49.8548 26.1728 50.0523 26.3261 50.2176C26.7412 50.6649 27.6356 51.5287 28.2125 51.3572C28.489 51.275 28.8065 51.0252 29.085 50.759Z" :fill="color"/>
<path opacity="0.9" d="M18.2518 50.2129C19.0219 49.8369 19.3503 48.9423 19.1352 48.1127L15.9948 36.0002L13.8817 26.2631C13.6643 25.2615 12.7249 24.5863 11.7062 24.6996L11.3651 24.7375C10.1306 24.8747 9.31848 26.0923 9.66603 27.2848L15.9603 48.8819C15.9833 48.9608 16.0109 49.0381 16.0465 49.1123C16.2243 49.4826 16.8368 50.6377 17.5 50.5001C17.7111 50.4563 17.9801 50.3455 18.2518 50.2129Z" :fill="color"/>
<path opacity="0.9" d="M7.99841 44.5624C8.00038 44.5205 7.99897 44.4788 7.99635 44.437L7.49822 36.4996L6.56273 26.6676C6.52191 26.2386 6.31851 25.8414 5.99423 25.5575V25.5575C4.80814 24.5192 2.97247 25.5293 3.21476 27.0869L5.99526 44.9615C5.99922 44.987 6.00351 45.0119 6.00971 45.0369C6.05466 45.218 6.28987 45.9997 7.0018 45.9997C7.87705 45.9998 7.98508 44.8465 7.99841 44.5624Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_fingers_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>