export default function({ dispatch, commit }, data) {
    commit('startedAuthentication')

    // POST /auth/login
    return dispatch('post', {
        url: '/auth/reset/verify',
        data
    }, {
        root: true
    })
    .then((result) => {
        commit('finishedAuthentication')
        if (result.set_password) {
            commit('validatedEmail', data.email)
            commit('validatedCode', data.reset_token)
        }
        return result
    })
    .catch((error) => {
        commit('finishedAuthentication')
        commit('failedValidation')
        throw error
    })
}
