<template>
    
    <svg width="27" height="89" viewBox="0 0 27 89" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.1578 88.4098L8.9034 87.0048C5.40379 86.4091 2.71854 83.5882 2.36607 80.0558C1.52156 71.5922 0.169928 55.8392 0.500144 44.5C0.874608 31.6414 3.72789 13.6059 5.17049 5.18092C5.63655 2.45908 8.00157 0.5 10.763 0.5C13.9316 0.5 16.5001 3.0686 16.5001 6.23712V38.6809C16.5001 39.5544 16.6432 40.422 16.9236 41.2492L26.0767 68.2508C26.3571 69.078 26.5001 69.9456 26.5001 70.8191V80.5232C26.5001 85.4779 22.0422 89.2412 17.1578 88.4098Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>