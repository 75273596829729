<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15">
        <g fill="#333" fill-rule="evenodd">
            <path d="M5.553.773a2.818 2.818 0 0 0 0 5.637 2.818 2.818 0 0 0 0-5.637m0 6.41a3.592 3.592 0 0 1 0-7.183 3.59 3.59 0 0 1 3.583 3.591 3.591 3.591 0 0 1-3.583 3.592"/>
            <path d="M.778 13.798h9.435c-.113-3.349-1.71-6.615-4.66-6.615-3.023 0-4.658 3.266-4.775 6.615zm10.214.773H0v-.387C0 10.321 1.907 6.41 5.554 6.41c3.57 0 5.438 3.91 5.438 7.774v.387z"/>
            <path d="M7.567 11.613a.386.386 0 0 1-.352-.543c.426-.966.512-2.06.242-3.081-.043-.165-.101-.347-.208-.456-.227-.232-.505-.374-.743-.38-.147-.004-.293-.01-.44-.016-.555-.021-1.08-.043-1.597.04-.102.017-.31.123-.731.532-.168.163-.313 1.026-.09 1.497a.387.387 0 0 1-.184.514.384.384 0 0 1-.513-.183c-.338-.712-.21-1.937.25-2.383.338-.33.738-.674 1.146-.74.592-.094 1.181-.071 1.75-.05.143.006.286.012.429.016.44.012.904.234 1.273.61.233.239.335.539.404.801a5.468 5.468 0 0 1-.283 3.592.386.386 0 0 1-.353.23"/>
            <path d="M6.849 11.678a.665.665 0 1 1 1.329 0 .665.665 0 0 1-1.33 0M4.722 10.29a.639.639 0 1 1 1.277-.001.639.639 0 0 1-1.277.002M2.51 11.75a.638.638 0 1 1 .637.64.638.638 0 0 1-.638-.64"/>
            <path d="M3.034 11.758a.385.385 0 0 1-.332-.19c-.25-.421-.317-.853-.231-1.489.056-.412.239-.666.299-.75.118-.165.449-.387.614-.436.791-.24 1.641.162 2.167 1.025.111.182.054.42-.128.532a.385.385 0 0 1-.53-.129c-.255-.42-.744-.851-1.287-.688-.036.017-.18.117-.218.156-.029.042-.123.173-.153.394-.07.525-.006.761.13.99a.387.387 0 0 1-.33.585"/>
        </g>
    </svg>
</template>
<script>
export default { name: 'icon-date-of-birth' }
</script>
