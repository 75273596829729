import Vue from 'vue'

export default {
    /**
     * @func message
     * @desc When a WebSocket message is received.
     */
    message(state, message) {
        // console.log('user', message)
    },

    setUser(state, user) {
        state.user = user
    },

    setProvider(state, provider) {
        state.provider = provider
    },

    loadingContent(state, loading) {
        state.loadingContent = (loading !== false)
    },

    loadingEvents(state, loading) {
        state.loadingEvents = (loading !== false)
    },

    loadingAppointment(state, loading) {
        state.loadingAppointment = (loading !== false)
    },

    loadedContent(state) {
        state.loadedContent = true
    },

    setContent(state, content) {
        state.content = content
    },

    removeEvent(state, id) {
        if (state.eventIndex.hasOwnProperty(id)) {
            state.event = state.event.filter(e => e.id !== id)
            state.eventIndex = state.event.reduce((acc, event, index) => {
                acc[event.id] = index
                return acc
            }, {});
        }
    },

    addEvents(state, events) {
        const revEvent = events.reverse()
        state.eventIndex = {}
        state.event = []
        revEvent.forEach((event) => {
            if (state.eventIndex[event.id] == null) {
                // Store the index of the event
                state.eventIndex[event.id] = state.event.length
                state.event.push(event)
            }
            else {
                state.event.splice(state.eventIndex[event.id], 1, event)
            }
        })

    },
    addAppointment(state, summary) {
        Vue.set(state.appointment, summary.id, summary)
    },
    setPatchedSubQuestionnaire(state, question) {
        state.patchedSubQ[question.id] = question.questionnaire.map(question => ({type: question.type, id: question.id}))
    },
    addName(state, name) {
        state.name = name
    },
    updateQueryQuestOptions(state, oQParams) {
        Vue.set(state.queryQuestOptions, oQParams.type, oQParams.oResp)
    }
}
