<template>
<svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.85968 45.3547H35.8681H42V0C30.629 0 23.9284 4.70207 22.8108 5.55143C22.7027 5.63358 22.6439 5.76067 22.6439 5.89644V26.2024L0.755615 39.8826L4.85968 45.3547Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
