<template>
    
    <svg width="92" height="53" viewBox="0 0 92 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.431417 47.4307C3.50987 29.9525 4.37649 19.3884 4.54348 2.42736C4.55585 1.17104 5.71471 0.229294 6.94733 0.472478C38.7366 6.74418 56.997 9.24927 89.7074 10.8219C91.3258 10.8997 92.2232 12.7801 91.2842 14.1005C88.5642 17.9251 84.1246 24.585 82.0625 30C79.9011 35.6758 78.6754 44.3855 78.2427 47.924C78.1299 48.8463 77.4001 49.5864 76.4784 49.7039C46.8556 53.4798 30.614 53.4041 2.15168 49.7702C1.01845 49.6255 0.233249 48.5558 0.431417 47.4307Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'wrist_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>