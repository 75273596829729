<template>
    <div class="text">
        <textarea :value="message" @input="message = $event.target.value" ref="text-area" placeholder="Start typing.."></textarea>
        <span v-if="characterValidation" class="character-count" :style="charactersLeft <= 0 ? 'color: #da4e4e' : ''">
            {{ charactersLeft }}
        </span>
	</div>
</template>

<script>
import Question from './index'
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'questionnaire-text',
    extends: Question,
	props: {
		saved: {
			type: Array
		}
	},
    data() {
		return {
			message: ""
		}
    },
	watch: {
		message(value) {
            if (this.question.hasOwnProperty('validations')) {
                let limitReached = false
                this.question.validations.forEach(type => {
                    if (type.type === 'length' && value && value.length > type.max) {
                        limitReached = true
                        this.message = this.message.substr(0, type.max)
                    }
                })
                if (!limitReached) this.$emit('selected', [ value ])
            }
            else
			    this.$emit('selected', [ value ])
		}
	},
	mounted() {
		if (!this.saved)
            this.$emit('selected', [this.getValue()])
        else this.message = this.saved[0]
        this.$refs['text-area'].focus()
	},
	methods: {
		getValue() {
			return this.saved ? this.saved[0] : ""
		}
	},
    computed: {
        characterValidation() {
            if(this.question.hasOwnProperty('validations') && this.question.validations.length)
                return this.question.validations.find((valObj) => valObj.type === 'length')
            return false
        },
        charactersLeft() {
            if (this.characterValidation)
                return this.characterValidation.max - (this.message ? this.message.length : 0)
            return false
        },
       
    }
}
</script>

<style lang="scss" scoped>
.text {
    text-align: left;
    position: relative;
    textarea {
        width: 100%;
        color: #999999;
        border: none;
        padding: 10px 25px 10px 10px;
        background-color: #eeeeee;
        font-size: 16px;
        height: 200px;
    }
    textarea:focus, input:focus{
        outline: none;
        border: 2px solid #833e93;
        background-color: #faf5fc;
        color: black;
    }
    .character-count {
        position: absolute;
        bottom: 8px;
        right: 8px;
        float: right;
        color: #666666;
        font-size: 13px;
        margin-top: 7px;
    }
}
</style>
