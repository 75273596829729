<template>
<svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M6.06629 28.4666C22.6615 48.3809 14.2527 21.8285 17.7806 21.8285C20.5003 21.8285 20.5274 42.1333 29.1043 31.2C36.9138 21.8285 34.3178 0.352341 18.5751 0.352341C2.83233 0.352341 -5.64799 14.4095 6.06629 28.4666Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_under_arm',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
