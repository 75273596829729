<template>
<svg width="61" height="94" viewBox="0 0 61 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M60.203 0.573242C60.203 0.573242 60.5658 20.6913 60.203 24.9514C57.4668 57.0844 58.9105 60.6829 52.5265 77.5732C52.5265 77.5732 34.9815 75.3478 25.5265 79.5732C17.203 83.293 9.02649 93.0732 9.02649 93.0732C9.02649 93.0732 6.65456 57.8471 5.4825 44.7256C4.52396 33.9998 0.526489 8.07324 0.526489 8.07324C0.526489 8.07324 10.5265 18.7158 30.5265 17.0732C52.0265 15.3075 60.203 0.573242 60.203 0.573242Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
