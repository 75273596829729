<template>
        
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
        
            <g fill="none" fill-rule="evenodd">
                <path :fill="color" d="M6.057 12.877L.195 6.787 6.087.591c.03-.054.06-.08.12-.134a.937.937 0 0 1 1.255.027c.36.322.33.831-.03 1.126L2.558 6.76l4.876 5.07c.03.027.09.08.12.135.268.375.179.885-.24 1.126-.419.242-.987.161-1.256-.214z"/>
                <path :stroke="color" stroke-linecap="round" stroke-width="2" d="M3 7h12"/>
            </g>
        </svg>

</template>

<script>
export default { 
	name: 'header-nav-icon',
	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
