<template>
<svg width="40" height="55" viewBox="0 0 40 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M39.2649 50.2423C36.1205 56.6341 4.16262 56.5375 1.0657 50.2423C-4.09107 39.7601 10.6223 0 20.4468 0C27.9484 0 43.5605 41.5105 39.2649 50.2423Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'epigastric_area',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
