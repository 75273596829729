<template>
	<div>
		<div class="article landing">
			<Header></Header>
			<template v-if="article">
				<div v-for="category in article" class="category">
					<h5>
						{{ category.title }}
					</h5>
					<div class="articles">
						<router-link v-for="article in category.article"
							:key="article.id"
							class="article"
							:to="{ name: 'article-viewer', params: { id: article.id } }">
							<img :src="article.image">
							<p>{{ article.title }}</p>
							<small>{{ article.length }} min read</small>
						</router-link>
					</div>
				</div>
			</template>
			<template v-else>
				<Loading v-if="$route.query.account === 'doctorplan'" text="Loading articles..."></Loading>
				<GenericLoading v-else text="Loading articles..."></GenericLoading>
			</template>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/component/Loading'
import GenericLoading from '@/component/GenericLoading'
import Header from './Header'

export default {
	name: 'article-landing',

	mounted() {
		this.loadContent()
	},

	methods: {
		...mapActions('user', [
			'loadContent'
		])
	},

	computed: {
		...mapGetters('user', [
			'getContent',
			'getContentLoadingState'
		]),
		loading() {
			return this.getContentLoadingState
		},
		article() {
			if (this.getContent)
				return this.getContent.article
		}
	},

	components: {
		Loading,
		GenericLoading,
		Header
	}
}
</script>
<style lang="scss" scoped>
@import "style/landing.scss";
</style>
