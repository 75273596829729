<template>
    
    <svg width="25" height="71" viewBox="0 0 25 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.9941 63.4998L19.9713 69.2959C19.7355 70.632 18.2655 71.3478 17.0501 70.745C11.7476 68.1151 7.86043 66.8675 2.07063 65.8451C0.943579 65.6461 0.191198 64.5604 0.426163 63.4403L12.7411 4.73321C12.9126 3.91554 13.1987 3.11368 13.7783 2.51191C14.735 1.51849 16.5979 0.176054 19.4999 0.499167C23.2201 0.913381 24.2029 4.74696 24.4315 6.06061C24.4825 6.35366 24.4909 6.64886 24.4845 6.94624L23.998 29.4991L20.9941 63.4998Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'pinky_finger_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>