export default [
	{
		image: 'symptom.png',
		key: 'symptom_question',
		title: 'Symptoms',
		color: '#E28816',
		calculate: function (topic) {
			var keys, length, message = 'You have recorded '
			if (topic.event) {
				keys = Object.keys(topic.event)
				length = Object.keys(topic.event).length
			}
			if (! keys || length < 1 || topic === 'none')
				return "You have not recorded any symptoms"
			if (length == 1) {
				message += topic.event[keys[0]].title
			}
			else if (length == 2) {
				message += `${topic.event[keys[0]].title} and ${topic.event[keys[1]].title}`
			}
			else {
				for (var i = 0; i < length; i++) {
					if (i == length-1)
						message += `and ${topic.event[keys[i]].title}`
					else
						message += `${topic.event[keys[i]].title}, `
				}
			}
			message += ` <strong>${topic.count > 1 ? topic.count + ' times' : ' 1 time'}</strong>`
			return message
		}
	},
	{
		image: 'vital.png',
		key: 'vital_question',
		title: 'Vitals',
		color: '#709F35',
		calculate: function (topic) {
			var keys, length, message = 'You have recorded '
			if (topic.event) {
				keys = Object.keys(topic.event)
				length = Object.keys(topic.event).length
			}
			if (! keys || length < 1 || topic === 'none')
				return "You have not recorded any vitals"
			if (length == 1) {
				message += topic.event[keys[0]].title
			}
			else if (length == 2) {
				message += `${topic.event[keys[0]].title} and ${topic.event[keys[1]].title}`
			}
			else {
				for (var i = 0; i < length; i++) {
					if (i == length-1)
						message += `and ${topic.event[keys[i]].title}`
					else
						message += `${topic.event[keys[i]].title}, `
				}
			}
			message += ` <strong>${topic.count > 1 ? topic.count + ' times' : ' 1 time'}</strong>`
			return message
		}
	},
	{
		image: 'medication.png',
		key: 'medication_question',
		title: 'Medication',
		color: '#C45531',
		calculate: function (topic) {
			var count
			if (topic === 'none' || (! topic.count && (! topic.event && topic.event.length < 1)))
				return "You have <strong>0 medications</strong> being tracked at this time"
			if (topic.count)
				count = topic.count
			else if (topic.event && Object.keys(topic.event).length > 0)
				count = Object.keys(topic.event).length
			
			return `You currently have <strong>${count} ${ count == 1 ? "medication" : "medications"}</strong>`
		}
	},
	{
		image: 'lifestyle.png',
		key: 'lifestyle_question',
		title: 'Lifestyle',
		color: '#2D3E4F',
		calculate: function (topic) {
			var message = "You have recorded "
			if (topic.excretion && topic.food)
				message += "Food and Bowel Movement"
			else if (topic.excretion)
				message += "Bowel Movement"
			else if (topic.food)
				message += "Food"
			else
				message = "You have not recorded any lifestyle activity"
			return message
		}
	},
	{
		image: 'activity.png',
		key: 'activity_question',
		title: 'Activities',
		color: '#5B8CC6',
		calculate: function (topic) {
			if (topic && topic.count)
				return `You have recorded physical activities <strong>${topic.count} times</strong>`
			return "You have not recorded any physical activity"
		}
	},
	{
		image: 'coping_living.png',
		key: 'coping_living_question',
		title: 'Coping and Living',
		color: '#2D3E4F',
		calculate: function (topic) {
			return 'Message'
		}
	},
	{
		image: 'cause_risk.png',
		key: 'cause_risk_question',
		title: 'Cause/Risks',
		color: '#2D3E4F',
		calculate: function (topic) {
			return 'Message'
		}
	},
	{
		image: 'diagnosis.png',
		key: 'diagnosis_treatment_question',
		title: 'Diagnosis',
		color: '#2D3E4F',
		calculate: function (topic) {
			return 'Message'
		}
	}
]
