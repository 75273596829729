<template>
	<div>
		<div class="success" v-if="upgradeSuccess">
			<div class="set-password-success">
				<CheckIcon class="check-icon set-password"></CheckIcon>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<p>Your password has been saved</p>
			</div>
			<el-row style="margin: 165px 0 35px 0">
				<el-col :span="7">
					<img v-if="isAccountGerd(query.account)" src="./icon/gerdhelp.png" class="image-class">
					<img v-else-if="query.account === 'livechair'" src="./icon/livechair.png" class="image-class">
					<img v-else src="./icon/doctorplan.png" class="image-class">
				</el-col>
				<el-col :span="15" :offset="1" style="text-align: left; color: #333333; margin-top: 7px;">
					<span>
						Open the {{ getAccountName }} App on your mobile and enter your new password.
					</span>
				</el-col>
			</el-row>
			<p v-if="isAccountGerd(query.account)" class="footer">
				Use {{ getAccountName }} App to stay on top of your GERD progress, read articles on GERD and healthy living, track your symptoms and vitals, and more.
			</p>
			<p v-else class="footer">
				Access exercise videos, track your symptoms, answer check-in questionnaires, and prepare for appointments  - all personalized for your treament plan.
			</p>
		</div>
		<template v-else>
			<h3>Set a New Password</h3>
			<div class="success">
				<p class="password">Secure your account by choosing a password you'll remember</p>
			</div>
			<div class="email-text">{{ query.email }}</div>
			<div v-bind:class="['input']">
				<input
					type="password"
					ref="input"
					v-model="password">
				<label>Password</label>
				<span v-if="password.length" class="password-show-hide" @click="togglePasswordVisibility">{{ showPassword ? 'HIDE' : 'SHOW' }}</span>
			</div>
			<div :class="['bottom']">6 characters or more</div>

			<el-row class="agree-terms-checkbox" v-if="isAccountGerd(query.account) && query.consent === 'true'">
				<el-col :span="3">
					<input type="checkbox" v-model="agreedToTerms">
				</el-col>
				<el-col :span="21">
					<label> I agree to the <a href="https://doctorplan.com/terms/" >Terms of Service</a> and <a href="https://doctorplan.com/privacy/index.html" >Privacy Policy</a></label>
				</el-col>
			</el-row>

			<!-- Login button -->
			<el-button :class="['save-button', { 'gerd-background': isAccountGerd(query.account)}]"
				:disabled="authenticating || !agreedToTerms || password.length < 6"
				:loading="isLoading"
				@click="next()">
				Save New Password
			</el-button>
		</template>
	</div>
</template>

<script>
import AuthForm from '.'

export default {
	name: 'upgrade-form',
	extends: AuthForm,


	data() {
		return {
			agreedToTerms: false,
			isLoading: false,
			upgradeSuccess: false,
			showPassword: false
		}
	},

	mounted() {
		// no terms to agree for doctorplan and livechair
		if (['doctorplan', 'livechair'].includes(this.query.account) || this.query.consent === 'false' || !this.query.hasOwnProperty('consent'))
			this.agreedToTerms = true
	},

	methods: {
		resend() {
			this.resendUpgrade().then(() => {
				this.$message({
					showClose: true,
					message: 'Email sent',
					type: 'success',
					duration: '5000'
				})
			})
			.catch(err => {
				this.$message({
					showClose: true,
					message: 'Failed to send email. ' + err.code,
					type: 'error',
					duration: '5000'
				})
			})
		},
		next() {
			if (this.authenticating || !this.agreedToTerms || this.password.length < 6)
				return

			this.isLoading = true
			this.upgrade().then(() => { 
				this.upgradeSuccess = true
				this.isLoading = false
			})
			.catch(err => { 
				this.isLoading = false 
				this.$message({
					showClose: true,
					message: 'Failed to save password.',
					type: 'error',
					duration: '5000'
				})
			})
		}
	},
	computed: {
		getAccountName() {
			if (this.isAccountGerd(this.query.account)) return 'GERDHelp'
			else if (this.query.account === 'livechair') return 'LiveChair'
			else return 'DoctorPlan'
		},
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
.input {
	margin-bottom: 20px;
	position: relative;

	label {
		position: absolute;
		left: 10px;
		color: rgba(0, 0, 0, 0.5);
		background: white;
		padding: 0 5px;
		line-height: 0.1em;
		font-size: 1em;
	}

	input {
		width: 100%;
		height: 50px;
		font-size: 15px;
		letter-spacing: .5px;
		border: solid 1px #bfbfbf;
		padding: 0 15px;
		border-radius: 2px;
	}

	input:focus {
		outline: none;
		border-color: #afafaf;
	}

	label.error,
	label.forgot {
		top: unset;
		bottom: 0px;
		font-size: 0.8em;
		line-height: 0.2em;
	}

	label.forgot:hover {
		cursor: pointer;
		color: #4f4f4f;
	}

	label.error {
		color: red;
	}
}

.input.error {
	label {
		color: rgba(255, 0, 0, 0.5);
	}
	input {
		border-color: red;
	}
}
.password-show-hide {
    top: 63px !important;
}
</style>
