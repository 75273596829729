<template>
	<div>
		<div v-if="resetSuccess" class="success">
			<div class="set-password-success">
				<CheckIcon class="check-icon set-password"></CheckIcon>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<p>Your password has been saved</p>
			</div>
			<el-row style="margin: 165px 0 35px 0">
				<el-col :span="7">
					<img v-if="isAccountGerd(query.account)" src="./icon/gerdhelp.png" class="image-class">
					<img v-else-if="query.account === 'livechair'" src="./icon/livechair.png" class="image-class">
					<img v-else src="./icon/doctorplan.png" class="image-class">
				</el-col>
				<el-col :span="15" :offset="1" style="text-align: left; color: #333333; margin-top: 7px;">
					<span>
						Open the {{ getAccountName }} App on your mobile and enter your new password.
					</span>
				</el-col>
			</el-row>
			<p v-if="isAccountGerd(query.account)" class="footer">
				Use {{ getAccountName }} App to stay on top of your GERD progress, read articles on GERD and healthy living, track your symptoms and vitals, and more.
			</p>
			<p v-else class="footer">
				Access exercise videos, track your symptoms, answer check-in questionnaires, and prepare for appointments  - all personalized for your treament plan.
			</p>
		</div>

		<template v-else>
			<h3>Set a New Password</h3>
			<div class="success">
				<p class="password">Secure your account by choosing a password you'll remember</p>
			</div>

			<div class="email-text">{{ query.email }}</div>

			<!-- Password input -->
			<div v-bind:class="['input']">
				<input
					type="password"
					ref="input"
					v-model="password">
				<label>Password</label>
				<span v-if="password.length" class="password-show-hide" @click="togglePasswordVisibility">{{ showPassword ? 'HIDE' : 'SHOW' }}</span>
			</div>


			<div :class="['bottom', { invalid }]">{{ getBottomText }}</div>

			<!-- <p class="message">{{ getLoadingText }}</p> -->

			<el-row class="agree-terms-checkbox" v-if="isAccountGerd(query.account) && query.consent === 'true'">
				<el-col :span="3">
					<input type="checkbox" v-model="agreedToTerms">
				</el-col>
				<el-col :span="21">
					<label> I agree to the <a href="https://doctorplan.com/terms/" >Terms of Service</a> and <a href="https://doctorplan.com/privacy/index.html" >Privacy Policy</a></label>
				</el-col>
			</el-row>

			<!-- Login button -->
			<el-button :class="['save-button', { 'gerd-background': isAccountGerd(query.account)}]"
				:disabled="authenticating || !agreedToTerms || password.length < 6"
				:loading="isLoading"
				@click="resetAction()">
				Save New Password
			</el-button>
		</template>
	</div>
</template>

<script>
import AuthForm from '.'


export default {
	name: 'reset-password-form',
	extends: AuthForm,

	props: {
		logout: {
			type: Boolean
		}
	},
    data() {
        return {
			invalid: false,
			invalidCode: false,
			resettingPassword: false,
			agreedToTerms: false,
			resetSuccess: false,
			isLoading: false,
			showPassword: false
        }
	},
    mounted() {
		// no terms to agree for doctorplan and livechair
		if (['doctorplan', 'livechair'].includes(this.query.account) || this.query.consent === 'false' || !this.query.hasOwnProperty('consent'))
			this.agreedToTerms = true

        if (this.query && this.query.email && this.query.reset_token) {
            this.verifyReset({
                email: this.query.email,
                reset_token: this.query.reset_token
            })
            .then((result) => {
                if (! result.set_password) {
                    this.invalidCode = true
                }
            })
            .catch((err) => {
				console.log(err)
                if (err.code == "InvalidToken")
					this.invalidCode = true
					this.$router.push({ path: '/download', query: { account: this.query.account } })
					this.$message({
						showClose: true,
						message: 'Reset code validation failed. ' + err.code,
						type: 'error',
						duration: '0'
					})
            })
        }
    },
	methods: {
		resetAction() {
			if (this.authenticating || !this.agreedToTerms || this.password.length < 6)
				return
			
			this.isLoading = true
            return this.resetPassword({
                email: this.authenticatedEmail,
                reset_token: this.authenticatedCode ? this.authenticatedCode : this.authenticatedResetToken.reset_token,
                password: this.password,
                device: "device id"
            })
            .then((result) => {
				this.isLoading = false
				this.email = this.authenticatedEmail
				this.resetSuccess = true
            })
            .catch((err) => {
				this.isLoading = false
				console.log(err)
            })
		}
	},

	computed: {
		resetActionText() {
			return 'next'
		},
		loginSecondaryText() {
			return (this.authenticatedEmail) ? 'resend?' : 'sign in'
		},
		loginSecondary() {
			return (this.authenticatedEmail) ? '/reset?resend=true' : '/login'
        },
        getBottomText() {
            return this.invalid ? 'Please enter at least 6 characters' : '6 characters or more'
		},
		getVerificationText() {
			return this.invalidCode ? 'Reset code validation failed. Please try again.' : 'We have verified your reset code. Please choose a new password.'
		},
		getLoadingText() {
			return this.resettingPassword ? 'Resetting your password...' : 'Confirming your reset code...'
		},
		getAccountName() {
			if (this.isAccountGerd(this.query.account)) return 'GERDHelp'
			else if (this.query.account === 'livechair') return 'LiveChair'
			else return 'DoctorPlan'
		}
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
.input {
	margin-bottom: 20px;
	position: relative;

	label {
		position: absolute;
		left: 10px;
		color: rgba(0, 0, 0, 0.5);
		background: white;
		padding: 0 5px;
		line-height: 0.1em;
		font-size: 1em;
	}

	input {
		width: 100%;
		height: 50px;
		font-size: 15px;
		letter-spacing: .5px;
		border: solid 1px #bfbfbf;
		padding: 0 15px;
		border-radius: 2px;
	}

	input:focus {
		outline: none;
		border-color: #afafaf;
	}

	label.error,
	label.forgot {
		top: unset;
		bottom: 0px;
		font-size: 0.8em;
		line-height: 0.2em;
	}

	label.forgot:hover {
		cursor: pointer;
		color: #4f4f4f;
	}

	label.error {
		color: red;
	}
}

.input.error {
	label {
		color: rgba(255, 0, 0, 0.5);
	}
	input {
		border-color: red;
	}
}
.password-show-hide {
    top: 63px !important;
}


</style>
