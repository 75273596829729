import index_finger_f from "./index_finger_f"
import middle_finger_f from "./middle_finger_f"
import ring_finger_f from "./ring_finger_f"
import pinky_finger_f from "./pinky_finger_f"
import thumb_f from "./thumb_f"
import palm from "./palm_f"
import wrist_f from "./wrist_f"

import index_finger_b from "./index_finger_b"
import middle_finger_b from "./middle_finger_b"
import ring_finger_b from "./ring_finger_b"
import pinky_finger_b from "./pinky_finger_b"
import thumb_b from "./thumb_b"
import back_of_hand from "./palm_b"
import wrist_b from "./wrist_b"


export default {
    index_finger_f,
    middle_finger_f,
    ring_finger_f,
    pinky_finger_f,
    thumb_f,
    palm,
    wrist_f,
    index_finger_b,
    middle_finger_b,
    ring_finger_b,
    pinky_finger_b,
    thumb_b,
    back_of_hand,
    wrist_b
}