<template>
	<header>
		<button class="icon" @click="showSidebar">
			<NavIcon></NavIcon>
		</button>
		<div class="background"></div>
		<div class="brand">DoctorPlan</div>

		<!-- <a class="message" href="/message">
			<MessageIcon class="icon"></MessageIcon>
			<label>20</label>
		</a> -->

		<div class="actions">
			<a @click="promptDownload" class="action">
				<AddSymptomIcon class="icon"></AddSymptomIcon>
				<label>Symptom</label>
			</a>
			<div @click="promptDownload" class="action">
				<AddVitalIcon class="icon"></AddVitalIcon>
				<label>Vital</label>
			</div>
			<div @click="promptDownload" class="action">
				<AddJournalIcon class="icon"></AddJournalIcon>
				<label>Reminder</label>
			</div>
		</div>
	</header>
</template>

<script>
import PromptDownload from '@/mixin/prompt-download'

import NavIcon from './icon/Nav'
import MessageIcon from './icon/Message'

import AddSymptomIcon from './icon/AddSymptom'
import AddMedicationIcon from './icon/AddMedication'
import AddVitalIcon from './icon/AddVital'
import AddJournalIcon from './icon/AddJournal'

export default {
	mixins: [ PromptDownload ],

	methods: {
		showSidebar() {
			this.$emit('sidebar')
		}
	},

	components: {
		NavIcon,
		MessageIcon,
		AddSymptomIcon,
		AddMedicationIcon,
		AddVitalIcon,
		AddJournalIcon
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
