<template>
    <div class="topic-height">
        <!-- <div class="units">
            <div @click="clickFeet()" v-bind:class="['feet', { selected }]">
                ft
            </div>
            <div @click="clickCM()" v-bind:class="['cm', { selected }]">
                cm
            </div>
        </div> -->
        <div v-if="! selected" class="scrolls">
            <scroll-picker v-model="feetValue" :options="feetOptions"></scroll-picker>
            <div class="feet">ft</div>
            <scroll-picker v-model="inchValue" :options="inchOptions"></scroll-picker>
            <div class="inch">in</div>
        </div>
        <div v-if="selected" class="long-press-buttons">
            <div v-longclick="() => changeValue(-.1)" @click="() => changeValue(-.1)" class="minus">
                <Minus></Minus>
            </div>
            <div class="value-unit">
                <!-- <div class="counter">{{counter}}</div> -->
                <input v-model="counter" class="counter">
                <div class="unit">{{ selected ? 'ft' : 'cm' }}</div>
            </div>
            <div v-longclick="() => changeValue(.1)" @click="changeValue(.1)" class="plus">
                <Plus></Plus>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import Icons from '../../image/icon/components'
// import './picker/picker.scss'
// import ScrollPicker from './picker/picker'
import ScrollPicker from '@/component/picker/picker'
import '@/component/picker/picker.scss'

import LongClick from '../../directive/longclick'

Vue.component('ScrollPicker', ScrollPicker)
Vue.directive('longclick', LongClick)

export default {
	props: {
        msg: {
            type: String
        }
        // drop: {
        //     type: Boolean
        // }
    },
	data() {
		return {
            counter: parseFloat(10.0).toFixed(1),
            selected: false,
            feet: null,
            inch: null,
            cm: null,
            feetOptions: [1, 2, 3, 4, 5, 6, 7],
            inchOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            feetValue: 3,
            inchValue: 3
		}
    },
    watch: {
        // drop() {
        //     localStorage.setItem('cm', JSON.stringify(counter))
        // },
        feetValue(value) {
            this.$emit('invalidText', true)
            this.$emit('params', { feet: this.feetValue, inch: this.inchValue })
        },
        inchValue(value) {
            this.$emit('invalidText', true)
            this.$emit('params', { feet: this.feetValue, inch: this.inchValue })
        },
        counter(value) {
            this.$emit('invalidText', true)
            this.$emit('params', { cm: this.counter })
        },
    },
	mounted() {
        if (localStorage.getItem('cm')) {
            this.counter = JSON.parse(localStorage.getItem('cm'))
        }
	},
    methods: {
        changeValue (amount) {
            var result = parseFloat(this.counter) + parseFloat(amount)
            this.counter = result < 0 ? parseFloat(0).toFixed(1) : result.toFixed(1)
        },
        clickFeet() {
            this.selected = true
            this.toFeet(this.counter)
        },
        clickCM() {
            this.selected = false
            this.toCM()
        },
        toFeet(n) {
            var exactFeet = ((n * 0.3937) / 12)
            var feet = Math.floor(exactFeet)
            var inches = Math.round((exactFeet - feet) * 12)
            this.feetValue = feet
            this.inchValue = inches
            this.$emit('params', { feet: this.feetValue, inch: this.inchValue })
        },
        toCM() {
            var total = (this.feetValue * 12) + this.inchValue
            this.counter = 2.54 * total
            this.$emit('params', { cm: this.counter })
        }
    },
    computed: {
    },
    components: {
        ...Icons
    }
}
</script>
<style lang="scss" scoped>
.topic-height {
    margin-top: 20px;
    margin-bottom: 20px;
    .counter {
        text-align: center;
        font-size: 40px;
        margin-bottom: 5px;
        width: 125px;
        height: auto;
        border: 0px solid black;
    }
    // button:hover {
    //     opacity: .8;
    //     cursor: pointer;
    // }
    .long-press-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        .value-unit {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .counter {
                padding-bottom: 5px;
                border-bottom: 1px solid #999999;
            }
            .unit {
                color: #999999;
                font-size: 10pt;
            }
        }
        .plus {
            margin-left: 10px;
        }
        .minus {
            margin-right: 10px;
        }
    }
    .scrolls {
        display: flex;
        justify-content: center;
        align-items: center;

        .feet {
            font-size: 18pt;
            font-weight: 600;
            margin-right: 10px;
        }
        .inch {
            font-size: 18pt;
            font-weight: 600;
            margin-left: -2.5px;
        }
    }
    .units {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        div {
            border: 1px solid #2D3E4F;
            cursor: pointer;
        }
        .feet {
            padding: 2.5px 20px 2.5px 20px;
            color: #6D6D6D;
        }
        .feet.selected {
            color: white;
            background: #2D3E4F;
        }
        .cm {
            padding: 2.5px 20px 2.5px 20px;
            color: white;
            background: #2D3E4F;
        }
        .cm.selected {
            color: #6D6D6D;
            background: white;
        }
    }
}
</style>