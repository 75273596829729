<template>
    
    <svg width="38" height="116" viewBox="0 0 38 116" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M22.1978 111.117C23.0018 107.542 26.0973 93.9017 28.5 84.9991C31.2846 74.6817 32.7005 72.4354 35 61.9991C39.3509 42.2532 36.7501 9.34492 36.1246 2.33689C36.0644 1.66277 35.3687 1.26643 34.7401 1.5173C31.4764 2.81989 22.6438 6.08645 17.5 5.49944C11.6028 4.82645 4.9845 1.73063 2.12082 0.268408C1.45315 -0.0725116 0.641882 0.383218 0.620351 1.13258C0.448725 7.10594 -0.011849 28.2149 1.50002 43.5C3.01291 58.7954 7.72311 79.1831 9.00002 94.5C9.79057 103.983 8.77064 111.071 8.16093 114.212C8.01002 114.99 8.75687 115.679 9.50723 115.425C11.2045 114.852 13.8379 113.926 16 112.999C18.0819 112.107 20.0054 111.954 21.0883 111.955C21.6057 111.955 22.0842 111.622 22.1978 111.117Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>