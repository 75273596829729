<template>

    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26">
        <defs>
            <linearGradient id="prefix__a" x1="0%" x2="100%" y1="50%" y2="50%">
                <stop offset="0%"/>
                <stop offset="100%" stop-opacity="0"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path fill="url(#prefix__a)" d="M3.62 4.841l19.472-.102.4 17.18-18.712.671c3.38-3.31 4.98-6.332 4.802-9.065-.179-2.732-2.166-5.627-5.963-8.684z" opacity=".426" transform="translate(-1 -1) rotate(142 13.556 13.665)"/>
            <path fill="#FFF" d="M26 1c.429 0 .826.135 1.152.365l-6.735 6.734-1.509-1.507-.11-.097c-.457-.356-1.12-.324-1.54.097-.455.455-.455 1.194 0 1.65l2.334 2.333.11.097c.19.148.416.23.646.243h.138l.137-.017c.226-.04.443-.148.619-.323L28 3.816V13c0 1.105-.895 2-2 2H16c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2h10z" transform="translate(-1 -1)"/>
        </g>
    </svg>


</template>

<script>
export default { 
	name: 'header-icon'
}
</script>
