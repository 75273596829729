function getType(typeLong) {
	const types = ['png', 'jpg', 'jpeg', 'mp4', 'gif','bmp', 'heif', 'mp3', 'ogg','wav','wma', 'mpg', 'mpeg', 'wmv', 'mov', 'avi', 'mkv', 'webm']
	const type = types.find(type => typeLong.includes(type))
	return  type !== undefined ? type: typeLong
}

export default {
	loadUser({ dispatch, commit, rootState }) {
		return dispatch('get', {
			url: '/user/' + rootState.auth.id
		}, {
			root: true
		})
		.then((user) => {
			commit('setUser', user)
			return user
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
	},
	updateUser({ dispatch, commit, rootState }, update) {
		return dispatch('patch', {
			url: '/user/' + rootState.auth.id,
			data: update
		}, {
			root: true
		})
		.then((user) => {
			return user
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
	},
	removeUser({ dispatch, commit, rootState }, attributes) {
		return dispatch('patch', {
			url: '/user/' + rootState.auth.id + '?remove=true',
			data: attributes
		}, {
			root: true
		})
		.then((response) => {
			console.log(response)
			return response
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
	},
	sendSupport({ dispatch, commit, rootState }, question) {
		return dispatch('post', {
			url: '/user/' + rootState.auth.id + '/support',
			data: question
		}, {
			root: true
		})
		.then((response) => {
			return response
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
	},
	loadProvider({ dispatch, commit, rootState }, provider) {
		return dispatch('get', {
			url: '/provider/' + provider
		}, {
			root: true
		})
		.then((response) => {
			commit('setProvider', response)
			return response
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
	},
	loadEvents({ dispatch, commit, rootState }, query) {
		commit('loadingEvents', true)

		return dispatch('get', {
			url: '/user/' + rootState.auth.id + '/event',
			query
		}, {
			root: true
		})
		.then((response) => {
			commit('loadingEvents', false)
			commit('addEvents', response.event)
			commit('content/cache', response.content, { root: true })
			return response
		})
		.catch((error) => {
			commit('loadingEvents', false)
			throw error
		})
	},

	removeEvent({ dispatch, commit, rootState }, id) {
		commit('removeEvent', id)
	},

	loadEvent({ dispatch, commit, rootState }, query) {
		var url = ''
		if (!query.hasOwnProperty('handoff_token') && !query.handoff_token)
			query.handoff_token = rootState.auth.handoff_token
		if (query.type === 'questionnaire') {
			// fetches all questionnaire events
			// used for showing unanswered questionnaires on Thank You page.
			url = '/user/' + rootState.auth.id + '/event'
			if (query.handoff_token)
				url += '?auth=' + rootState.auth.id + '&handoff_token=' + query.handoff_token + '&type=' + query.type
		} else {
			// fetches only current event
			url = '/user/' + rootState.auth.id + '/event/' + query.id
			if (query.handoff_token)
				url += '?auth=' + rootState.auth.id + '&handoff_token=' + query.handoff_token
		}
		
		if (query.provider_survey)
			url += '&provider_survey=true'

		return dispatch('get', { url }, { root: true })
				.then((event) => {
					if (query.type === 'questionnaire') {
						try {
							if(event.content && event.content.questionnaire && typeof event.content.questionnaire === 'object') {
								for(let questionObj of Object.values(event.content.questionnaire)) {
									commit('content/cacheObject', questionObj, { root: true })
								}
								
							}
						} catch (error) {
							console.error(error)
						}
						
						commit('addName', event.name)
						event = event.event
						commit('addEvents', event)
						let curEvent = {}
						event.forEach(e => {
							if (e.id === query.id) curEvent = e
						})
						return curEvent
					} else {
						commit('addEvents', [ event ])
						return event
					}
				})
				.catch((error) => {
					console.log(error)
					throw error
				})
	},
	logSymptom({ dispatch, commit, rootState }, query) {
		// {{url}}/patient/{{patient}}/symptom
		var url = '/user/' + rootState.auth.id + '/event'

		return dispatch('post', {
			url,
			data: query.body
			}, {
				root: true
			})
			.then((response) => {
				console.log('RES', response)
				return response
			})
			.catch((error) => {
				console.log(error)
				throw error
			})
	},
	addEvent({ dispatch, commit, rootState }, query) {
		var url = '/user/' + rootState.auth.id + '/event/' + query.id

		if (['questionnaire', 'appointment', 'checklist', 'symptom', 'message'].includes(query.type))
			url += '?auth=' + rootState.auth.id + '&handoff_token=' + rootState.auth.handoff_token
		
		return dispatch('patch', {
			url,
			data: query.body
		}, {
			root: true
		})
		.then((response) => {
			try {
				if (query.type === 'questionnaire' && response.hasOwnProperty('completed'))
					commit('removeEvent', query.id)
				if (query.type === 'questionnaire' && response.hasOwnProperty('question')) {
					response.question.filter(q => q.hasOwnProperty('questionnaire') && q.questionnaire.length).forEach(question => {
						commit('setPatchedSubQuestionnaire', question)
					})
				}
				return response
			} catch (error) {
				console.error(error)
				return response
			}
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
	},
	loadAppointment({ dispatch, commit, state, rootState }, query) {
		commit('loadingAppointment')
		
		var url = '/user/' + rootState.auth.id + '/appointment'

		return dispatch('get', {
			url: url,
			query
		}, {
			root: true
		})
		.then((response) => {
			commit('loadingAppointment', false)
			commit('addEvents', [ response.event ])
			response.summary.id = response.event.id
			commit('addAppointment', response.summary)
			commit('content/cacheObject', response.content, { root: true })
			return response
		})
		.catch((error) => {
			commit('loadingAppointment', false)
			console.log(error)
			throw error
		})
	},
	loadContent({ dispatch, commit, state, rootState }) {
		if (state.loadedContent)
			return state.content

		commit('loadingContent')

		var url = '/user/' + rootState.auth.id + '/content'
		var query = {
			// expand: ['article', 'exercise', 'mindfulness', 'symptom', 'other_symptom'].join(',') //  'mindfulness', 'exercise', 'yoga'
			expand: 'true'
		}
		if (rootState.auth.handoff_token) {
			query.auth = rootState.auth.id
			query.handoff_token = rootState.auth.handoff_token
		}

		return dispatch('get', {
			url: url,
			query: query
		}, {
			root: true
		})
		.then((content) => {
			commit('loadingContent', false)
			commit('loadedContent')
			commit('setContent', content)
			commit('content/cacheProfile', content, { root: true })
			return content
		})
		.catch((error) => {
			commit('loadingContent', false)
			console.log(error)
		})
	},
	cdn({ dispatch, rootState }, payload) {
		let file = payload.file
		let cancelToken = payload.cancelToken
		let progressId = payload.progressId

		var url = '/user/' + rootState.auth.id + '/upload?type=' + getType(file.type) + '&mime=' + file.type +
			'&auth=' + rootState.auth.id +
			'&handoff_token=' + rootState.auth.handoff_token

		return dispatch('get', { url }, { root: true })
			.then((response) => {
				let formData = new FormData()
				formData.append('key', response.key)
				formData.append('Policy', response.policy)
				formData.append('X-Amz-Signature', response.signature)
				formData.append('X-Amz-Credential', response.credential)
				formData.append('X-Amz-Algorithm', response.algorithm)
				formData.append('X-Amz-Date', response.date)
				formData.append('acl', response.acl)
				formData.append('X-Amz-Security-Token', response.security)
				formData.append('Content-Type', file.type)
				formData.append('file', file)

				return dispatch('post', { url: response.url, data: formData, query: '', cancelToken, progressId }, { root: true })
					.then(() => {
						return {
							url: response.public,
							file
						}
					})
					.catch((error) => {
						console.log(error)
					})
			})
	},
	loadQueryQuestOptions({ dispatch, commit, state, rootState }, query) {
		var url = ''
		url = '/user/' + rootState.auth.id + '/query' + '/' + query.qType + "?param=" + query.params.join()
		if (rootState.auth.handoff_token)
			url += '&auth=' + rootState.auth.id + '&handoff_token=' + rootState.auth.handoff_token

		return dispatch('get', { url }, { root: true })
				.then((resp) => {
					let myPayloadObj = {
						type: query.qType,
						oResp: resp
					}
					commit('updateQueryQuestOptions', myPayloadObj)
					return resp
				})
				.catch((error) => {
					console.log(error)
					throw error
				})
	},
}
