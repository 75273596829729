<template>
	<div class="sidebar" v-on:click="hide">
		<div class="user">
			{{ name }}
		</div>
		<ul>
			<li v-for="item in menu"
				:key="item">
				<img :src="itemImage(item)">
				<router-link :to="{ path: '/' + (typeMap[item] ? 'download' : item), params: {} }">{{ menuName[item] }}</router-link>
			</li>
			<li class="divider"></li>
			<li>
				<img src="./image/logout.png">
				<router-link :to="{ name: 'logout', params: {} }">Logout</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

const typeMap = {
	vital: true,
	symptom: false,
	medication: true,
	food: true,
	water: true,
	reach_out: true,
	bowel_movement: true,
	message: true,
	questionnaire: false,
	checklist: false,
	appointment: true,
	exercise: true,
	article: false
}

const menuName = {
	//learn: 'Learn',
	article: 'Articles',
	appointment: 'Appointments',
	journal: 'Journal',
	activity: 'Activities',
	exercise: 'Exercises',
	mindfulness: 'Mindfulness',
	vital: 'Vitals',
	symptom: 'Symptoms',
	questionnaire: 'Questionnaires',
	checklist: 'Checklists',
	medication: 'Medication',
	water: 'Water',
	food: 'Food',
	bowel_movement: 'Bowel Movement',
	message: 'Messages',
	reach_out: 'Contact Doctor’s Office'
}

export default {
	name: 'home-sidebar',

	data() {
		return {
			menuName,
			typeMap
		}
	},

	methods: {
		hide() {
			this.$emit('hide')
		},

		itemImage(item) {
			return require('./image/' + item + '.png')
		}
	},

	computed: {
		...mapGetters('user', [
			'getName',
			'getContent'
		]),
		name() {
			return this.getName
		},
		menu() {
			var contentMenu = []
			if (this.getContent) {
				contentMenu = this.getContent.menu.map((item) => item.id)
					.filter((item) => { 
						return item !== 'learn' 
					})
				if (! contentMenu.includes('appointment') )
					contentMenu.push('appointment')
				if (! contentMenu.includes('questionnaire') )
					contentMenu.push('questionnaire')
				if (this.getContent.checklist && !contentMenu.includes('checklist'))
					contentMenu.push('checklist')
				if (this.getContent.exercise && !contentMenu.includes('exercise'))
					contentMenu.push('exercise')
				if (this.getContent.article && !contentMenu.includes('article'))
					contentMenu.push('article')
			}
			return contentMenu
		}
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
