<template>
    
    <svg width="136" height="62" viewBox="0 0 136 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M72.3942 59.6571C74.3827 57.1864 94.4467 32.3581 99.9105 29.6306C105.661 26.7599 127.151 24.7599 134.789 15.7889C137.463 12.6485 133.752 3.91267 132.384 0.976765C132.117 0.402604 131.475 0.125874 130.865 0.296772C124.244 2.1518 87.8641 12.0738 67.2033 12.0475C46.8696 12.0216 10.8652 2.08961 4.47619 0.280891C3.89306 0.115805 3.28315 0.363376 3.00545 0.902058C1.63801 3.55462 -1.9255 11.4165 1.33672 15.7889C7.50526 24.0567 32.3396 27.334 38.0903 29.6306C43.543 31.8082 60.9516 57.0292 62.7413 59.6373C62.8472 59.7917 62.9757 59.9072 63.1354 60.0048C67.1358 62.45 67.2961 62.4434 71.9869 59.9851C72.1483 59.9005 72.2799 59.799 72.3942 59.6571Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_lower_calf_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>