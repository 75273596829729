<template>
	<header v-bind:class="['header', { landing }]">
		<!-- Redirect back to the landing screen or to the home page -->
		<router-link v-if="authenticated" :to="questionnaire ? '/questionnaire' : '/'" class="nav">
			<NavIcon :color="landing ? 'white' : 'black'"></NavIcon>
		</router-link>
		<HeaderIcon v-if="landing" class="icon"></HeaderIcon>
		<span v-bind:class="['title', { 'title-left': !authenticated }]">
			{{ complete ? '' : content ? (content.hasOwnProperty('display_name') && content.display_name ? content.display_name : content.title) : 'Questionnaire' }}
		</span>
	</header>

</template>

<script>
import { mapState } from 'vuex'

import NavIcon from './icon/Back'
import HeaderIcon from './icon/HeaderIcon'

export default {

	props: {
		landing: {
			type: Boolean
		},
		questionnaire: {
			type: Object
		},
		content: {
			type: Object
		},
		complete: {
			type: Boolean
		}
	},

	computed: {
		...mapState('auth', [
			'authenticated'
		])
	},

	components: {
		NavIcon,
		HeaderIcon
	}
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
