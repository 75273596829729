<template>
<svg width="166" height="63" viewBox="0 0 166 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M77.2087 61.2067C77.6325 57.7788 78.6626 50.4944 79.9316 48.8994C82.0001 46.2995 85.0084 46.3243 87.0378 48.8994C88.0135 50.1375 89.2695 58.0236 89.7748 61.4331C89.8826 62.1601 90.7102 62.535 91.3257 62.1332C97.2719 58.2511 121.5 42.5001 128.5 39C136.5 35 153.875 35 164.5 22.5C168.427 17.8797 162.453 5.32931 160.869 2.20942C160.657 1.79151 160.208 1.58291 159.756 1.70802C153.727 3.37794 112.333 14.5374 83.0001 14.5C54.1678 14.4632 12.2389 2.47793 6.2281 0.715393C5.78453 0.585326 5.33942 0.778277 5.12125 1.1858C3.53103 4.1562 -2.30315 16.0516 2.50018 22.5C11.0814 34.0201 34.5001 35.8 42.5001 39C49.3891 41.7556 69.9963 57.5621 75.5873 61.8993C76.2088 62.3814 77.1122 61.9873 77.2087 61.2067Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'pelvic',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
