export default function (question, answer) {
    let oldAnswer = answer
    try {
        if(question.type === 'select') {
            oldAnswer = answer.map((ansObj) => ansObj.value)
        }
    
        else if (['box_select', 'large_image_select', 'small_image_select'].includes(question.type)) {
            oldAnswer = answer.reduce((accAns, ansObj) => {
                const selectArr = Object.entries(question.select).find(([key, value]) => String(ansObj.value) === value)
                if(selectArr)
                    accAns[selectArr[0]] = selectArr[1]
                return accAns
            }, {})
            try {
                if(question.type === 'box_select' && question.other)
                    oldAnswer.other  = answer.
                        filter((ansObj) => !Object.values(question.select).some((value) => ansObj.value === value))
                        .map(({value}) => value)
            } catch (error) {
                console.error('Error calculating box select other ', error)
            }
        }
    
        else if(['scroller', 'slider'].includes(question.type)) {
            oldAnswer = (Array.isArray(answer) && (answer.length === 1) && answer[0]) ? { [answer[0].value] : question.label[answer[0].value] } : null
        }
    
        else if(question.type === 'star') {
            oldAnswer = { [answer[0].value]: question.label[answer[0].value] }
        }
    
        else if(question.type === 'radio') {
            oldAnswer =  parseInt(answer[0].value) + 1
        }
        
        else if(question.type === 'human_map') {
            oldAnswer = answer.map(({value}) => value)
        }
    
        else if(question.type === 'binary')
            oldAnswer = Array.isArray(answer) && (answer.length === 1) ? { 0 : answer[0].value } : null
    } catch (error) {
        console.error('Problem getting error ', error)
    }
    
    return oldAnswer
}