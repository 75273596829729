<template>
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M41.8858 28.6096V0.495338C41.8858 0.495338 21.9772 1.31093 11.5059 11.9137C1.0346 22.5164 0.495327 41.1049 0.495327 41.1049H28.6096C28.6096 41.1049 29.0001 35.2477 33.2953 31.7334C37.2001 28.5386 41.8858 28.6096 41.8858 28.6096Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_upper_inner_quadrant',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
