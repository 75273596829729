<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="70" height="9" viewBox="0 0 134 9">
        <path fill="#333" fill-rule="nonzero" d="M125 5H0V4h125V0l9 4.5-9 4.5V5z"/>
    </svg>
</template>
<script>
export default {
	name: 'questionnaire-longArrow-icon',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
