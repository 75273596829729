<template>
    
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.0033391 9.09645C0.00333935 6.1964 -0.148941 3.03497 1.60307 1.19188C3.94843 -1.27539 11.1556 1.19188 15.2017 1.19188C18.8123 1.19188 24.4736 -1.21453 26.8004 0.809749C29.0288 2.74833 30 6.18598 30 9.09645C30 12.4985 28.5264 17.48 25.6005 19.4542C23.4094 20.9327 18.2284 23.2383 15.2017 23.2383C12.175 23.2383 6.99386 20.9334 4.80274 19.455C1.87678 17.4807 0.0033388 12.4985 0.0033391 9.09645Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>