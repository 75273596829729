<template>
    
    <svg width="23" height="64" viewBox="0 0 23 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.0227 0.555404L9.79093 2.81533C7.69292 3.47096 6.50528 5.69503 7.0497 7.82461C8.53899 13.6503 10.9814 24.9217 10 33C8.95172 41.6285 3.13668 53.6979 2.14411 55.7103C2.04669 55.9078 1.96699 56.1056 1.9013 56.3158L1.12284 58.8069C0.31793 61.3826 2.2422 64 4.94076 64H6.05199C7.55448 64 8.9261 63.165 9.58514 61.8148C11.8102 57.2562 16.8598 46.4927 19 38.5C21.8592 27.8222 22.1641 12.2366 22.1051 4.20846C22.0858 1.57788 19.5336 -0.229253 17.0227 0.555404Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>