<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22">
        <defs>
            <linearGradient id="prefix__a" x1="0%" x2="100%" y1="50%" y2="50%">
                <stop offset="0%"/>
                <stop offset="100%" stop-opacity="0"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <path fill="url(#prefix__a)" d="M8.743 5.668L19.255 4.836 19.193 17.836 2.906 17.415 2.193 9.171z" opacity=".339" transform="rotate(142 10.724 11.336)"/>
            <path fill="#FFF" d="M19.379 4.378l-2.487.76.8-4.047c.077-.392-.296-.72-.685-.602l-4.34 1.327c-.22.067-.373.263-.384.488l-.31 6.678c-.016.365.338.633.695.524l2.652-.81-.446 5.307c-.018.566.758.753 1.02.246l4.12-9.128c.216-.419-.18-.882-.635-.743"/>
        </g>
    </svg>
</template>

<script>
export default { 
	name: 'header-icon'
}
</script>
