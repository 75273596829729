<template>
    <div class="misc">
        <MiscSectionItem :title="'Change Email Address'"></MiscSectionItem>
        <MiscSectionItem :title="'Change Password'"></MiscSectionItem>
        <MiscSectionItem v-if="getUser.phone" :title="'Change Phone Number'"></MiscSectionItem>
        <MiscSectionItem :title="'Contact Support'"></MiscSectionItem>
        <div class="logout-button">
            <MainButton @clickButton="showModal" :logout=true></MainButton>
            <modal v-show="isModalVisible" @close="closeModal"/>
        </div>
    </div>
</template>
<script>
import Arrow from '../image/Arrow'
import MiscSectionItem from './MiscSectionItem'
import Modal from './Modal'
import MainButton from './button/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
	props: {
	},
	mounted() {
	},
	data() {
		return {
            selected: false,
            drop: false,
            isModalVisible: false
		}
	},
    methods: {
        clickArrow() {
            this.selected = this.selected ? false : true
            this.drop = this.drop ? false : true
        },
        showModal() {
            this.isModalVisible = true
        },
        closeModal() {
            this.isModalVisible = false
        }
    },
    computed: {
        ...mapGetters('user', [
            'getUser'
        ])
    },
    components: {
        Arrow,
        MiscSectionItem,
        modal: Modal,
        MainButton
    }
}
</script>
<style lang="scss" scoped>
.misc {
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    .logout-button {
        text-align: center;
        margin-top: 20px;
    }
}
</style>