<template>
<svg width="123" height="46" viewBox="0 0 123 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 12.1821C0 5.11064 6.08305 -0.440274 13.1328 0.113835C26.1692 1.1385 46.3428 2.5 61.5 2.5C76.6572 2.5 96.8308 1.1385 109.867 0.113835C116.917 -0.440275 123 5.11064 123 12.1821V34C123 40.6274 117.627 46 111 46H12C5.37259 46 0 40.6274 0 34V12.1821Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'lower_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
