<template>
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_118_3078)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0769 74.1538C60.3448 74.1538 75.1538 59.3448 75.1538 41.0769C75.1538 22.809 60.3448 8 42.0769 8C23.809 8 9 22.809 9 41.0769C9 59.3448 23.809 74.1538 42.0769 74.1538ZM36.5641 51.6432L53.1026 41.5363L36.5641 31.4295V51.6432Z" fill="white"/>
        </g>
        <defs>
            <filter id="filter0_d_118_3078" x="0" y="0" width="84.1538" height="84.1538" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="4.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_118_3078"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_118_3078" result="shape"/>
            </filter>
        </defs>
    </svg>
</template>

<script>
/**
 * An Icon component that displays a play icon.
 */
export default {
    name: 'play-icon',
}
</script>