<template>
	<div class="binary">
		<template v-if="question.hasOwnProperty('options')">
			<div v-for="(option, index) in question.options" :key="option.id"
				v-bind:class="['choice', { selected: isSelected(option) }]"
		    	@click="select(option)">

				<div :is="getIcon((index+1)%2)"
					v-bind:select="isSelected(option)">
				</div>
				<span>{{ option.value }}</span>
			</div>
		</template>
		<div v-else v-for="(_, choice) in 2" :key="choice"
		    v-bind:class="['choice', { selected: isSelected(getBinaryText((choice+1)%2)) }]"
		    @click="select(getBinaryText((choice+1)%2))">

			<div :is="getIcon((choice+1)%2)"
				 v-bind:select="isSelected(getBinaryText((choice+1)%2))">
			 </div>
			<span>{{ getBinaryText((choice+1)%2) }}</span>
		</div>
	</div>
</template>

<script>
import Question from './index'
import YesIcon from './icon/Yes'
import NoIcon from './icon/No'

export default {
	name: 'binary-question',
	extends: Question,

	mounted() {
		if (this.saved) this.$emit('selected', this.saved)
	},

	methods: {
		getBinaryText(choice) {
			return (choice == 1) ? 'Yes' : 'No'
		},
		getIcon(choice) {
			return (choice == 1) ? 'YesIcon' : 'NoIcon'
		}
	},

	components: {
    	YesIcon,
    	NoIcon
	}
}
</script>

<style lang="scss" scoped>
$choice-size: 150px;
$choice-font-size: 20px;

@import "style.scss";
.binary {
	position: relative;
	text-align: center;
    .choice {
        width: 140px;
        height: 140px;
        line-height: 50px;
        font-size: 20px;
        margin-right: 15px;
		padding: 30px;
		margin-bottom: 10px;
        // color: #999999;
    }
    .choice.selected {
        color: #833e93;
    }
}
</style>
