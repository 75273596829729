import DownloadView from '@/component/DownloadView'

export default [
	{
		path: '/mindfulness',
		name: 'mindfulness-landing',
		props: true,
		component: DownloadView
    },
	{
		path: '/mindfulness/:id',
		name: 'mindfulness-viewer',
		props: (route) => ({
			id: route.params.id,
            query: route.query
		}),
		component: DownloadView,
		meta: {
			link: true
		}
	}
]