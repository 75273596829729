<template>
<svg width="92" height="60" viewBox="0 0 92 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M91 27.5C91 33.0339 91.6538 49.1861 87 53.5C78.8816 61.0255 63.9728 59.5 48 59.5C32.0272 59.5 13.6184 61.0255 5.5 53.5C0.846223 49.1861 0 33.0339 0 27.5C0 12.3122 20.371 0 45.5 0C70.629 0 91 12.3122 91 27.5Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
