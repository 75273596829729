<template>
    
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.996094 13.5C0.996094 20.0104 5.33707 26.7573 8.33424 27.4676C8.44143 27.493 8.5436 27.5005 8.65375 27.4984C12.1849 27.4305 24.1013 25.2012 25.996 22.5C27.437 20.4456 21.9961 16.5 23.995 12C27.495 2 17.2951 0 11.4961 0C1.99609 0 0.996094 3.31213 0.996094 13.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_palm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>