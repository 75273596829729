<template>
    <div v-bind:class="['image-title', { other } ]">
        <img v-if="! other" class="image" :src="getImage()"/>
        <span 
            v-bind:style="[ color ? { color: this.color } : {} ]"
            v-html="getTitle()"
            class="title">
        </span>
    	<p v-if="other" class="description">
			{{ getDescription }}
		</p>
    </div>
</template>

<script>

export default {

	props: {
        color: {
            type: String
        },
        image: {
            type: String
        },
        title: {
            type: String
        },
        description: {
            type: String
        },
        other: {
            type: Boolean
        }
	},

    data() {
        return {
        }
    },
    mounted() {
    },
	methods: {
		getImage() {
			return require('../../../image/' + this.image)
        },
        getTitle() {
            return `<strong>${this.title}</strong>`
        }
    },
    computed: {
        getDescription() {
            return this.description
        }
    },
	components: {
	}
}
</script>

<style lang="scss">
//@import "style.scss";
.image-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10%;
    margin-bottom: 5%;
    margin-top: 5%;

    .image {
        width: 25px;
        margin-right: 10px;
    }
    .description {
        margin-right: 10%;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
.image-title.other {
    display: block;
}
@media only screen and (min-width: 600px) {
    .image-title {
        .image {
            width: 35px;
        }
        .title {
            font-size: 18pt;
        }
        .description {
            font-size: 18pt;
        }
    }
}
</style>
