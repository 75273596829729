<template>
    
    <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 37.6725V32.6516C0 31.7929 0 31.3635 0.0689294 30.876C0.137858 30.3884 0.239545 30.036 0.442917 29.3312L0.442922 29.3311C1.34474 26.2056 3.56902 19.4053 6.61019 16.34C10.9876 11.9279 38.7815 1.73795 40.6005 1.07408C40.6653 1.05042 40.7273 1.03071 40.7937 1.01215C43.2391 0.329343 45.202 3.07483 43.7648 5.16783L30.2569 24.84L20.5487 42.8565C19.2252 45.3126 18.5635 46.5406 17.6086 47.375C16.6171 48.2413 15.4015 48.8104 14.1012 49.017C12.8488 49.216 11.4819 48.9377 8.74799 48.3811C5.76177 47.7731 4.26866 47.4691 3.13317 46.7166C1.95503 45.9359 1.04081 44.8168 0.510811 43.5065C0 42.2438 0 40.72 0 37.6725Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_shoulder_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>