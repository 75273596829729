<template>
    
    <svg width="27" height="40" viewBox="0 0 27 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M2.85306 11.7725C0.0460072 17.1631 0.358109 26.2976 0.613863 29.9041C0.685217 30.9103 1.06371 31.86 1.71839 32.6274C3.3249 34.5105 6.67507 38.0699 9.87513 39.1585C12.2575 39.969 15.6671 39.7878 17.9554 39.525C19.5263 39.3446 20.8756 38.3882 21.5913 36.9782C23.0961 34.0133 25.5565 28.453 26.0259 23.0078C26.4557 18.0217 24.9663 9.90268 23.7954 4.53932C23.1386 1.53115 20.1139 -0.238701 17.2535 0.900873C12.4501 2.81458 5.68099 6.34188 2.85306 11.7725Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_temple',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
