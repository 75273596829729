<template>
	<div class="progress-bar">
		<div class="background">
			<div class="bar"
				v-if="index != length+1"
				v-bind:style="{
					width: (100.0 * index / length) + '%'
				}">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'questionnaire-progress-bar',

	props: {
		index: {
			type: Number
		},
		length: {
			type: Number
		}
	}
}
</script>

<style scoped lang="scss">
.progress-bar {
	padding-left: 10px;
	padding-right: 10px;

	.background {
		background: #f2e8f4;
		border-radius: 25px;

		.bar {
			height: 5px;
			background: #833e93;
			border-radius: 25px;
			transition: width 0.3s ease-in-out;
		}
	}
}

</style>
