<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
        <g fill="#333" fill-rule="evenodd">
            <path d="M2.096 2.595c-.637 0-1.155.516-1.155 1.151v8.699c0 .634.518 1.151 1.155 1.151h11.368c.637 0 1.155-.517 1.155-1.151V3.746c0-.635-.518-1.151-1.155-1.151H2.096zm11.368 11.938H2.096A2.095 2.095 0 0 1 0 12.445V3.746c0-1.152.94-2.089 2.096-2.089h11.368c1.156 0 2.096.937 2.096 2.09v8.698c0 1.151-.94 2.088-2.096 2.088z"/>
            <path d="M.545 6.304h14.619v-.938H.544zM6.157 3.34a.47.47 0 0 1-.47-.468V.469a.47.47 0 0 1 .94 0v2.403a.47.47 0 0 1-.47.469M9.404 3.34a.47.47 0 0 1-.47-.468V.469a.47.47 0 0 1 .94 0v2.403a.47.47 0 0 1-.47.469M5.35 8.58a.678.678 0 0 1-.68.676.678.678 0 0 1-.68-.677c0-.373.305-.676.68-.676.375 0 .68.303.68.676M8.42 8.58a.678.678 0 1 1-1.358 0 .678.678 0 0 1 1.359 0M11.4 8.58a.678.678 0 0 1-.678.676.678.678 0 0 1-.68-.677c0-.373.305-.676.68-.676.375 0 .679.303.679.676M5.35 10.89a.678.678 0 0 1-.68.676.678.678 0 0 1-.68-.676c0-.374.305-.677.68-.677.375 0 .68.303.68.677M8.42 10.89a.678.678 0 1 1-1.358 0 .678.678 0 0 1 1.359 0M11.4 10.89a.678.678 0 0 1-.678.676.678.678 0 0 1-.68-.676c0-.374.305-.677.68-.677.375 0 .679.303.679.677"/>
        </g>
    </svg>
</template>
<script>
export default { name: 'icon-date-of-birth' }
</script>
