<template>
<svg width="47" height="29" viewBox="0 0 47 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.000396729 9.48299C0.000396729 14.4535 11.8125 24 24.5 27.5C29.9996 29.0171 35.5004 27.9829 39.5004 25.9829C44.5004 23.4829 47 21.7608 47 19C47 14.0294 38.5 15.1667 32.5 12.5C28 10.5 24.3425 2.00945 19.0004 0.482997C15.5004 -0.517099 8.91069 0.14308 5.5004 1.48281C0.916935 3.28341 0.000396729 7.59666 0.000396729 9.48299Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_lower_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
