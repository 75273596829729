<template>
    
    <svg width="21" height="103" viewBox="0 0 21 103" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.0625 59.5879V100.058C0.0625 101.47 1.48793 102.434 2.81075 101.94C8.82678 99.6937 12.5898 98.8847 19.0567 98.5457C20.1616 98.4878 21.0279 97.5602 20.9852 96.4546L19.5625 59.5879L19.0625 19.7084V6.78435C19.0625 6.3277 18.9076 5.88511 18.5982 5.5493C17.3259 4.16857 13.6078 0.59185 9.5625 1.00001C5.38524 1.42149 2.60394 5.32739 1.80173 6.60139C1.6375 6.8622 1.55619 7.16138 1.54408 7.46936L1.0625 19.7084L0.0625 59.5879Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'ring_finger_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>