<template>
<svg width="58" height="107" viewBox="0 0 58 107" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M57.7265 69.8589L56.3376 29.7303C56.3376 29.7303 52.1817 18.4312 47.5411 12.402C43.2132 6.77905 35.8695 0.545851 35.8695 0.545851L15.3645 0.0898438H0V103.603C0 103.603 15.133 107.707 26.7073 105.883C38.2817 104.059 48.0041 97.2193 48.0041 97.2193L57.7265 69.8589Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
