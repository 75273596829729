<template>
	<div v-bind:class="['input', { 'error': error }]">
		<input
			ref="input"
			type="text"
			:value="value"
			autofocus="true"
			v-on:change="change"
			v-on:keydown.13="enter">

		<label>Doctor Code</label>
		<label
			v-if="error"
			class="error">
			{{ error }}
		</label>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import AuthInput from '.'

export default {
	name: 'auth-code-input',
	extends: AuthInput
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
