<template>
    <div class="misc-section-main">
        <div class="misc-section">
            <span class="misc-section-title">
                {{ title }}
            </span>
            <div 
                @click="clickArrow"
                v-bind:class="['arrow', { selected }]">
                <Arrow></Arrow>
            </div>
        </div> 
        <div v-if="drop && sectionTitle == 'Change Password'" class="misc-email-drop">
            <div class="input">
                <input type="password" v-model="origPassword" placeholder="original password">
            </div>
            <div class="input">
                <input type="password" v-model="newPassword" placeholder="desired password">
            </div>
            <span v-if="passwordFailed" class="password-failed">
                Either of the passwords is incorrect, please try again.
            </span>
            <div class="change-cancel">
                <MainButton :cancel=true @clickButton="cancelPassword"></MainButton>
                <MainButton :change=true @clickButton="changeUserPassword"></MainButton>
            </div>
        </div> 
        <div v-if="drop && sectionTitle == 'password_success'" class="misc-email-drop">  
            <span class="confirm-text">
                Your password has been successfully changed.
            </span>
        </div> 
        <div v-if="drop && sectionTitle == 'Change Phone Number'" class="misc-email-drop">
            <div class="input">
                <input v-model="newPhone" placeholder="desired phone number">
            </div>
            <span v-if="phoneFailed" class="password-failed">
                Phone number is already associated with another account, please try again.
            </span>
            <span v-if="phoneValidationFailed" class="password-failed">
                Please enter a valid phone number, try again.
            </span>
            <div class="change-cancel">
                <MainButton :cancel=true @clickButton="cancelPhone"></MainButton>
                <MainButton :change=true @clickButton="changePhoneNumber"></MainButton>
            </div>
        </div>
        <div v-if="drop && sectionTitle == 'phone_success'" class="misc-email-drop">  
            <span class="confirm-text">
                Your request to change the phone number associated with this account has been initiated. We will send a text message to your previous phone number. Please follow the instructions in the message.
            </span>
        </div> 
        <div v-if="drop && sectionTitle == 'Change Email Address'" class="misc-email-drop">
            <div class="input">
                <input v-model="newEmail" placeholder="desired email">
            </div>
            <div class="change-cancel">
                <MainButton :cancel=true @clickButton="cancelEmail"></MainButton>
                <MainButton :change=true @clickButton="changeEmailAddress"></MainButton>
            </div>
        </div>  
        <div v-if="drop && sectionTitle == 'confirm'" class="misc-email-drop">
            <span class="confirm-text">
                An email requiring you to confirm the email change to:
            </span>
            <span class="entered-email">
                {{ newEmail }}
            </span>
            <span class="confirm-text">
                will be sent to your current email address.
            </span>
            <span class="confirm-text">
                Confirm Change?
            </span>
            <div class="change-cancel">
                <MainButton :cancel=true @clickButton="cancelConfirm"></MainButton>
                <MainButton :change=true @clickButton="confirmEmail"></MainButton>
            </div>
            <span @click="noPassword" class="no-password">
                I don't have the password to my email
            </span>
        </div>  
        <div v-if="drop && sectionTitle == 'no-password'" class="misc-email-drop">
            <span class="entered-email">
                {{ newEmail }}
            </span>
            <div class="input">
                <input type="password" v-model="currentPassword" placeholder="current password">
            </div>
            <span v-if="passwordFailed" class="password-failed">
                Wrong password, please try again.
            </span>
            <div class="change-cancel">
                <MainButton :cancel=true @clickButton="cancelNoPassword"></MainButton>
                <MainButton :change=true @clickButton="changeNoPassword"></MainButton>
            </div>
        </div>
        <div v-if="drop && successMessageMap[sectionTitle]" class="misc-email-drop">  
            <span class="confirm-text">
                {{ getSuccessMessage }}
            </span>
        </div> 
        <!-- <div v-if="drop && sectionTitle == 'email_change_success'" class="misc-email-drop">  
            <span class="confirm-text">
                Email has been sent successfully. Please check the inbox of your current email address registered to this account.
            </span>
        </div> 
        <div v-if="drop && sectionTitle == 'no_password_success'" class="misc-email-drop">  
            <span class="confirm-text">
                Email has been changed successfully. We will sent to your inbox an email requesting verification of your new email address.
            </span>
        </div> 
        <div v-if="drop && sectionTitle == 'no_password_failed'" class="misc-email-drop">  
            <span class="confirm-text">
                Email has been changed successfully. We will sent to your inbox an email requesting verification of your new email address.
            </span>
        </div>  -->
    </div>
</template>
<script>
import Arrow from '../image/Arrow'
import { mapActions, mapGetters } from 'vuex'
import MainButton from './button/Button'

const successMessageMap = {
    email_change_success: "Email has been sent successfully. Please check the inbox of your current email address registered to this account.",
    no_password_success: "Email has been changed successfully. We will sent to your inbox an email requesting verification of your new email address.",
    no_password_failed: "Email has been changed successfully. We will sent to your inbox an email requesting verification of your new email address."
}

export default {
	props: {
        title: {
            type: String
        }
	},
	mounted() {
	},
	data() {
		return {
            selected: false,
            drop: false,
            dropBack: false,
            newEmail: '',
            newPassword: '',
            origPassword: '',
            newPhone: '',
            sectionTitle: this.title,
            currentPassword: null,
            passwordFailed: false,
            phoneFailed: false,
            phoneValidationFailed: false,
            successMessageMap: successMessageMap
		}
	},
    methods: {
        ...mapActions('auth', [
            'changeEmail',
            'changePhone',
            'changePassword'
		]),
        clickArrow() {
            if (this.sectionTitle == 'Contact Support') {
                return this.$router.push({ name: 'contact', params: { contact: true } })
            }
            else {
                this.selected = this.selected ? false : true
                this.drop = this.drop ? false : true
            }
        },
        changeEmailAddress() {
            this.sectionTitle = 'confirm'
        },
        cancelEmail() {
            this.newEmail = ''
            this.selected = this.selected ? false : true
            this.drop = this.drop ? false : true
        },
        cancelEMailConfirm() {
            this.sectionTitle = 'Change Email Address'
        },
        changeUserPassword() {
            return this.changePassword({ password: this.origPassword, new_password: this.newPassword })
                .then((response) => {
                    if (response && response.changed) {
                        this.sectionTitle = 'password_success'
                        this.passwordFailed = false
                    }
                    else {
                        this.passwordFailed = true
                    }
                    return
                })
        },
        cancelPassword() {
            this.origPassword = ''
            this.newPassword = ''
            this.selected = this.selected ? false : true
            this.drop = this.drop ? false : true
        },
        changePhoneNumber() {
            return this.changePhone({ phone: this.newPhone })
                .then((response) => {
                    if (response && response.phone_changed) {
                        this.sectionTitle = 'phone_success'
                        this.phoneFailed = false
                        this.phoneValidationFailed = false
                    }
                    else if (response && response.code == 'WrongPassword') {
                        this.phoneFailed = true
                    }
                    else {
                        this.phoneValidationFailed = true
                    }
                    return
                })
        },
        cancelPhone() {
            this.newPhone = ''
            this.selected = this.selected ? false : true
            this.drop = this.drop ? false : true
        },
        cancelConfirm() {
            this.sectionTitle = 'Change Email Address'
        },
        confirmEmail() {
            return this.changeEmail({ email: this.newEmail })
                .then((response) => {
                    if (response && response.email_changed) {
                        this.sectionTitle = 'email_change_success'
                    }
                    return
                })
        },
        noPassword() {
            this.sectionTitle = 'no-password'
        },
        cancelNoPassword() {
            this.sectionTitle = 'confirm'
        },
        changeNoPassword() {
            return this.changeEmail({ email: this.newEmail, password: this.currentPassword })
                .then((response) => {
                    if (response && response.confirmed) {
                        this.sectionTitle = 'no_password_success'
                        this.passwordFailed = false
                    }
                    else {
                        this.passwordFailed = true
                    }
                    return
                })
        }
    },
    computed: {
        getSuccessMessage() {
            return successMessageMap[this.sectionTitle]
        }
    },
    components: {
        Arrow,
        MainButton
    }
}
</script>
<style lang="scss" scoped>
.misc-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 20px 0px;
    //border-bottom: 1px solid #E5E3E3;
    .misc-section-title {
        font-size: 14pt;
    }
    .arrow {
        margin-left: auto;
    }
    .arrow.selected {
        transform: rotate(90deg);
    }
    .drop {

    }
}
.misc-email-drop {
    .input {
        margin-top: 10px;
        text-align: center;
        input {
            border: 1px solid black;
            padding: 5px;
            width: 250px;
            font-size: 12pt;
            font-style: italic;
            color: #6D6D6D;
        }
    }
    .change-cancel {
        display: flex;
        //text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
        // button {
        //     font-size: 12pt;
        //     border: 1px solid #2D3E4F;
        //     border-radius: 4px;
        //     cursor: pointer;
        // }
        // .change {
        //     padding: 5px 25px 5px 25px;
        //     margin-left: 5px;
        //     color: white;
        //     background: #2D3E4F;
        // }
        // .cancel {
        //     padding: 5px 5px 5px 5px;
        // }
    }
    .entered-email {
        display: flex;
        justify-content: center;
        font-size: 12pt;
        font-style: italic;
        color: #6D6D6D;
        margin-bottom: 15px;
    }
    .confirm-text {
        display: flex;
        justify-content: flex-start;
        font-size: 11pt;
        margin-bottom: 10px;
    }
    .no-password {
        font-size: 11pt;
        font-style: italic;
        color: #6D6D6D;
        text-decoration: underline;
        cursor: pointer;
    }
    .password-failed {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        font-size: 10pt;
        color: red;
    }
}
</style>