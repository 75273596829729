import Star from './Star'
import Radio from './Radio'
import LargeImageSelect from './LargeImageSelect'
import BoxSelect from './BoxSelect'
import SmallImageSelect from './SmallImageSelect'
import Select from './Select'
import Binary from './Binary'
import TextBox from './TextBox'
import Slider from './Slider'
import Scroller from './Scroller'
import Upload from './Upload'
import HumanMap from './HumanMap'
import MultiQuestion from './MultiQuestion'
import LongTextBubble from './LongTextBubble'
import Date from './Date'
import Signature from './Signature'
import Unit from './Unit'
import Separator from './Separator'
import Query from './Query'
import Video from './Video'
import InformedConsent from './InformedConsent'



export default {
    Radio,
    LargeImageSelect,
    BoxSelect,
    SmallImageSelect,
    Star,
    Select,
    Binary,
    TextBox,
    Slider,
    Scroller,
    Upload,
    HumanMap,
    MultiQuestion,
    LongTextBubble,
    Date,
    Signature,
    Unit,
    Separator,
    Query,
    Video,
    InformedConsent
}
