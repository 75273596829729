<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g fill="none" fill-rule="evenodd" :stroke="(select) ? '#833E93' : '#999'" stroke-linecap="round" stroke-width="3">
            <path d="M2 2l33.941 33.941M2 35.941L35.941 2"/>
        </g>
    </svg>
</template>
<script>
export default {
	name: 'questionnaire-no-icon',

	props: {
		select: {
			type: Boolean
		}
	}
}
</script>
