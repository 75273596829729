<template>
<svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M59.8679 24.2305C60.2513 16.4823 60.4431 12.6082 57.307 8.63959C54.1709 4.67101 49.9955 3.89459 41.6447 2.34175C36.4729 1.38005 31.1924 0.61229 27.4033 0.583384C24.7777 0.563354 21.8236 0.892997 18.8593 1.40229C10.8322 2.78139 6.8187 3.47094 3.64715 7.23386C0.475597 10.9968 0.475597 15.7593 0.475597 25.2844V41.4292C0.475597 46.1119 0.475597 48.4532 1.73235 51.1555C2.9891 53.8577 4.27834 54.9439 6.85682 57.1164C10.6605 60.3212 15.7646 63.495 21.0385 63.4652C31.2867 63.4072 39.476 62.2395 49.4348 53.6711C56.9851 47.175 59.348 34.7378 59.8679 24.2305Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_buttock_inner_hip',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
