<template>
<svg width="23" height="63" viewBox="0 0 23 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4882 5.92809C15.1932 7.52063 22.8004 9.12014 22.8004 9.12014V35.3405V62.929C22.8004 62.929 15.0777 60.6105 10.693 58.1768C6.06362 55.6071 0.456051 50.1608 0.456051 50.1608C0.456051 50.1608 0.11544 41.1428 0.456052 35.3405C0.803001 29.4303 1.8418 26.4244 2.28008 20.5203C2.82316 13.2044 0 0 0 0C0 0 6.4787 4.57095 10.4882 5.92809Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_flank_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
