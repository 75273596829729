<template>
<svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M0.00355641 12.3314C0.00355643 12.0767 0.00267236 11.8203 0.00178506 11.563C-0.00935475 8.33218 -0.0210046 4.95348 1.70994 2.9212C4.1982 -0.000236988 11.8168 -8.49221e-05 16.1452 0L16.2151 2.43474e-06C20.0665 2.77144e-06 26.1051 0.0564303 28.5871 2.46629C30.964 4.77412 32 8.86656 32 12.3314C32 16.3814 30.4282 22.3118 27.3072 24.6621C25.9934 25.6514 23.6719 24.2819 21.3172 22.8928C19.4831 21.8107 17.6288 20.7168 16.2151 20.7168C14.8014 20.7168 12.9471 21.8109 11.1128 22.8932C8.75818 24.2825 6.43669 25.6523 5.12292 24.663C2.00189 22.3126 0.00355605 16.3814 0.00355641 12.3314ZM21.3862 16.0779C21.4616 15.5308 21.9663 15.1485 22.5134 15.2239C23.0605 15.2994 23.4429 15.8041 23.3674 16.3512C23.292 16.8983 22.7873 17.2806 22.2402 17.2052C21.6931 17.1297 21.3107 16.6251 21.3862 16.0779ZM8.2638 15.1365C7.7167 15.0611 7.21201 15.4434 7.13656 15.9905C7.06112 16.5377 7.44347 17.0423 7.99058 17.1178C8.53768 17.1932 9.04236 16.8109 9.11781 16.2638C9.19326 15.7167 8.81091 15.212 8.2638 15.1365ZM7.13656 5.99054C7.21201 5.44344 7.7167 5.06108 8.2638 5.13653C8.81091 5.21198 9.19326 5.71666 9.11781 6.26377C9.04236 6.81088 8.53768 7.19323 7.99058 7.11778C7.44347 7.04233 7.06112 6.53765 7.13656 5.99054ZM22.2638 5.13653C21.7167 5.06108 21.212 5.44344 21.1366 5.99054C21.0611 6.53765 21.4435 7.04233 21.9906 7.11778C22.5377 7.19323 23.0424 6.81088 23.1178 6.26377C23.1933 5.71666 22.8109 5.21198 22.2638 5.13653ZM4.13657 10.9905C4.21201 10.4434 4.7167 10.0611 5.2638 10.1365C5.81091 10.212 6.19326 10.7167 6.11781 11.2638C6.04236 11.8109 5.53768 12.1932 4.99058 12.1178C4.44347 12.0423 4.06112 11.5377 4.13657 10.9905ZM26.5134 10.2239C25.9663 10.1485 25.4616 10.5308 25.3862 11.0779C25.3107 11.6251 25.6931 12.1297 26.2402 12.2052C26.7873 12.2806 27.292 11.8983 27.3674 11.3512C27.4429 10.8041 27.0605 10.2994 26.5134 10.2239Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'neck_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
