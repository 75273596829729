<template>
<svg width="59" height="88" viewBox="0 0 59 88" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0 69.7691L1.38892 29.6404C1.38892 29.6404 5.54484 18.3413 10.1854 12.3122C14.5133 6.6892 21.857 0.456007 21.857 0.456007L42.362 0H59V88C59 88 42.5935 87.6176 31.0192 85.7936C19.4449 83.9696 9.72242 77.1295 9.72242 77.1295L0 69.7691Z" :fill="color"/>
</svg>


</template>
<script>
export default {
	name: 'left_upper_back',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
