<template>
    <svg width="53" height="63" viewBox="0 0 53 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M52.5 47.6161V37.6542C52.5 36.8173 52.5 36.3989 52.4383 35.9373C52.3766 35.4758 52.2795 35.1189 52.0853 34.4051L52.0853 34.4051C50.9649 30.2879 47.6456 19.2913 43 14.5C37.2576 8.57742 15.9336 2.20042 11.0162 0.787556L11.016 0.787497C10.9397 0.765593 10.9016 0.754626 10.8402 0.738205C10.3129 0.597003 9.70257 0.511064 9.15674 0.501153C9.09321 0.5 9.03936 0.5 8.93167 0.5C7.63148 0.5 6.98138 0.5 6.52062 0.561468C2.11256 1.14952 -0.636902 5.64537 0.847961 9.83726C1.00317 10.2754 1.29933 10.8541 1.89163 12.0113L1.89173 12.0115L23.6352 54.495C25.9751 59.0667 27.145 61.3526 29.2783 62.373C31.4115 63.3934 33.9254 62.8697 38.9531 61.8223L41.3554 61.3218C46.6933 60.2097 49.3622 59.6537 50.9311 57.7243C52.5 55.7948 52.5 53.0686 52.5 47.6161Z" :fill="color" />
</svg>

</template>

<script>
export default {
	name: 'left_shoulder_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>