<template>
<svg width="24" height="63" viewBox="0 0 24 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2048 5.92809C8.49983 7.52063 0.892605 9.12014 0.892605 9.12014V35.3405V62.929C0.892605 62.929 8.61531 60.6105 13 58.1768C17.6294 55.6071 23.2369 50.1608 23.2369 50.1608C23.2369 50.1608 23.5776 41.1428 23.2369 35.3405C22.89 29.4303 21.8512 26.4244 21.4129 20.5203C20.8698 13.2044 23.693 0 23.693 0C23.693 0 17.2143 4.57095 13.2048 5.92809Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'left_flank_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
