<template>
	<div v-bind:class="['form', { success }]">
		<h3>
			Reset Password
			<router-link v-if="authenticatedEmail && ! resent"
				:to="loginSecondary">{{ loginSecondaryText }}</router-link>
		</h3>

		<p>
			<CheckIcon class="icon"></CheckIcon>
			Enter the email address for your DoctorPlan account.
		</p>

		<!-- Username input -->
		<EmailInput
			v-model="email"
			v-bind:error="emailError"
			@next="initiateLogin">
		</EmailInput>

		<!-- Password input -->
		<!-- <PasswordInput
			v-else
			v-model="password"
			v-bind:error="passwordError"
			@next="reset">
		</PasswordInput> -->

		<!-- Login button -->
		<button
			v-bind:class="{ disabled: authenticating }"
			v-on:click="resetAction">
			{{ resetActionText }}
		</button>
	</div>
</template>

<script>
import AuthForm from '.'

export default {
	name: 'login-form',
	extends: AuthForm,

	props: {
		logout: {
			type: Boolean
		}
	},

	methods: {
		resetAction() {
			if (this.authenticatedEmail)
				this.verifyReset()
			else
				this.reset()
		}
	},

	computed: {
		resetActionText() {
			return (this.authenticatedEmail) ? 'login' : 'next'
		},
		loginSecondaryText() {
			return (this.authenticatedEmail) ? 'resend?' : 'sign in'
		},
		loginSecondary() {
			return (this.authenticatedEmail) ? '/reset?resend=true' : '/login'
		}
	}
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
