<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="27" height="29" viewBox="0 0 27 29">
	    <defs>
	        <linearGradient id="i-h-sg" x1="80.991%" x2="26.982%" y1="-9.164%" y2="76.119%">
	            <stop offset="0%" stop-color="#F1C330"/>
	            <stop offset="100%" stop-color="#E28815"/>
	        </linearGradient>
	    </defs>
	    <g fill="none" fill-rule="evenodd">
	        <path fill="url(#i-h-sg)" d="M25.282 16.45h-3.725l2.79-5.316A.774.774 0 0 0 23.663 10h-6.5a.774.774 0 0 0-.73.518l-3.221 9.2a.774.774 0 0 0 .73 1.03h3.973l-2.833 7.228c-.262.785.723 1.378 1.294.78l9.467-10.998a.774.774 0 0 0-.56-1.309"/>
	        <path fill="#E28816" d="M7 5.5h5.5v1H7V12H6V6.5H.5v-1H6V0h1v5.5z"/>
	    </g>
	</svg>
</template>
<script>
export default { name: 'header-add-symptom-icon' }
</script>
