<template>
<svg width="39" height="117" viewBox="0 0 39 117" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.0266 7.07295C35.0266 7.07295 38.5393 28.0284 38.5393 37.4261C38.5393 55.8233 34.4088 100.722 31.0266 116.573C31.0266 116.573 26.3303 111.573 20.0266 111.573C13.7229 111.573 9.02661 115.073 9.02661 115.073C7.65859 110.576 8.89885 75.8557 4.79469 60.3204C2.28082 50.8046 0.0266342 5.07376 0.0266342 5.07376C0.0266342 5.07376 4.03826 2.00392 14.5266 1.07327C24.2545 0.210088 35.0266 7.07295 35.0266 7.07295Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_lower_leg_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
