<template>
	<div class="select">
		<div class="greeting">
			<p>Hi{{ name ? (' ' + name) : ' there!' }}</p>
			<p>
				To make the most out of your upcoming appointment, you should select the areas you'd like
				to discuss with {{ appointment.doctor ? '' : 'your doctor' }}
				<strong>{{ appointment.doctor ? appointment.doctor.name : '' }}</strong>.
			</p>
			<p>
				DoctorPlan will walk you through suggested topics for each selected area, or you can add
				your own. {{ appointment.doctor ? '' : 'Your doctor' }}
				<strong>{{ appointment.doctor ? appointment.doctor.name : '' }}</strong>
				will also have a copy before he meets with you.
			</p>
		</div>
		<div class="sections">
			<div
				v-for="(section, index) in topics"
				v-bind="topics"
				:key="section.title"
				class="topics">
				<SectionSelect
					:index="index"
					:topics="topics"
					:section="section"
					@clicked="onClickSection">
				</SectionSelect>
			</div>
		</div>
		<br><br>
		<ButtonMain class="button"
			:buttonName="buttonName"
			:route="route">
		</ButtonMain>
	</div>
</template>

<script>
import Vue from 'vue'
import sections from '../util/sections'
import SectionSelect from './section/SectionSelect'
import { mapActions, mapGetters } from 'vuex'
import { Col, Row, Card, Button } from 'element-ui'
import AppointmentView from '../mixin/appointment-view'
import ButtonMain from '../../component/ButtonMain'

Vue.component(Col.name, Col)
Vue.component(Row.name, Row)
Vue.component(Card.name, Card)
Vue.component(Button.name, Button)

export default {
	name: 'appointment-select',

	props: {
		appointment: {
			type: Object
		},
		content: {
			type: Object
		},
		summary: {
			type: Object
		},
		result: {
			type: Object
		},
		selected: {
			type: Object
		}
	},
	data() {
		return {
			topics: null,
			sections,
			select: "SELECT",
			selectedTopics: [],
			buttonName: 'SEE DETAILS',
			route: { name: 'appointment-topic' }
		}
	},
	mixins: [ AppointmentView ],
	mounted() {
		this.getFromStorage
		this.getSections
		if (this.summary)
			return this.loadUser()
	},
	methods: {
		...mapActions('user', [
			'loadUser'
		]),
		// Update the topic selection from child Section
		onClickSection({ key, selected }) {
			Vue.set(this.selected, key, selected)
			// Update topics to change each topic as selected
			this.topics.forEach((item) => {
				if (item.key == key)
					item.selected = selected
			})
			localStorage.setItem(this.appointment.id + '_selected', JSON.stringify(this.selected))
		}
	},
	computed: {
		...mapGetters('user', [
			'getName'
		]),
		name() {
			return this.getName ? this.getName.split(' ')[0] : ''
		},
		getFromStorage() {
			// Get the state from local storage, and initialize if not yet initialized
			var state = localStorage.getItem(this.appointment.id + '_selected')
			if (state) {
				this.selected = JSON.parse(state)
				Object.keys(this.selected).forEach((select) => {
					Vue.set(this.selected, select, this.selected[select])
				})
			}
			var result = localStorage.getItem(this.appointment.id + '_result')
			if (result) {
				this.result = JSON.parse(result)
				delete this.result.current
				localStorage.setItem(this.appointment.id + '_result', JSON.stringify(this.result))
			}
		},
		getSections() {
			// Create copy of all topic data
			this.selectedTopics = Array.from(sections)
			// Get topic data only related to appointment (only the ones that exist in content object)
			this.selectedTopics = this.selectedTopics.filter((section) => {
				return (Object.keys(this.content).includes(section.key)
							&& section.key != "coping_living_question"
							&& section.key != "cause_risk_question"
							&& section.key != "diagnosis_treatment_question")
			})
			// Retain selected status if coming back from previous page
			this.selectedTopics.forEach((item) => {
				if (this.selected[item.key] && this.selected[item.key] == true) {
					item.selected = true
				}
				item.message = this.getMessage(item)
			})
			this.topics = this.selectedTopics
		},
		getMessage() {
			return (item) => {
				if (! this.summary)
					return item.calculate('none')
				var name = item.key.split('_')[0]
				if (name == 'lifestyle')
					return item.calculate({ food: this.summary.food, excretion: this.summary.excretion})
				if (name == 'activity')
					return item.calculate(this.summary['exercise'])
				return item.calculate(this.summary[name])
			}
		}
	},
	components: {
		SectionSelect,
		ButtonMain
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
