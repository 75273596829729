<template>
<svg width="45" height="70" viewBox="0 0 45 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M16.2474 31.2197C8.17948 22.6035 -4.76123 18.9469 3.1704 10.6485C6.95786 6.68586 13.0204 2.24427 18.3723 1.0592C24.0403 -0.195845 28.0076 -0.202795 31.295 4.58196C34.6147 9.41374 37.3799 20.637 39.8635 31.315C42.8934 44.3423 45.7363 60.6809 44.2644 65.2798C42.9944 69.2475 36.474 71.23 33.0347 68.8791C27.7497 65.2667 26.6055 42.2817 16.2474 31.2197Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_outer_hip_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
