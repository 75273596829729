<template>
    
    <svg width="50" height="95" viewBox="0 0 50 95" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M18.5 0.999838C16.1849 0.999838 14.0453 0.905097 12.0918 0.749297C5.83514 0.250275 -0.624302 6.00409 0.348119 12.2048C2.01442 22.8302 4.03468 37.0181 4.5 46C5.20833 59.6728 3.55126 76.1165 2.42837 85.175C1.94099 89.1068 4.33271 92.7752 8.25472 93.3363C12.1942 93.8998 17.9028 94.4408 25.5 94.5009C35.2143 94.5777 42.471 90.5475 46.5969 87.4582C49.1386 85.555 49.6329 82.1475 48.4146 79.2153C45.601 72.444 40.7412 59.5451 39 47.5C37.5693 37.6025 37.3107 21.9382 37.3355 10.8857C37.3488 4.95203 30.8574 0.185604 24.9476 0.718281C23.0155 0.892438 20.8652 0.999837 18.5 0.999838Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>