<template>
	<svg width="47" height="65" viewBox="0 0 47 65" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M14.9874 29.2655C16.8115 25.6175 20.9155 15.5853 20.9155 14.2173C20.9155 12.8493 21.8276 5.55319 21.8276 5.55319C21.8276 5.55319 28.1416 0.537109 32.7717 0.537109C37.4018 0.537109 44.1719 5.55319 44.1719 5.55319C44.1719 5.55319 47.3639 23.7935 45.9959 26.9855C44.6279 30.1776 38.2438 33.8256 36.8758 37.0177C35.5077 40.2097 35.9638 48.8738 34.1397 52.0659C32.3157 55.2579 25.2744 65.7461 20.0035 63.9221C17.9493 63.2112 17.2675 62.098 16.3554 61.642C15.4434 61.186 10.8834 61.186 8.60335 60.73C6.32332 60.274 0.85114 56.626 0.395204 54.8019C-0.0607328 52.9779 -0.0608094 51.1539 0.395204 48.8738C0.851217 46.5938 13.1634 32.9136 14.9874 29.2655Z" :fill="color"/>
	</svg>
</template>
<script>
export default {
	name: 'right_foot_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
