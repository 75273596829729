<template>
    
    <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M34.075 32.0849C33.6437 31.2071 32.5801 30.8485 31.7053 31.2859C30.8107 31.7333 30.4674 32.8348 30.9496 33.7112L40.6256 51.2997C40.9613 51.9098 41.7189 52.1461 42.3419 51.8349C42.9798 51.5162 43.2409 50.7424 42.9265 50.1024L34.075 32.0849Z" :fill="color"/>
<path opacity="0.9" d="M26.9821 35.0274C26.703 34.1431 25.832 33.5845 24.9119 33.6995C23.6812 33.8533 22.9166 35.1157 23.3505 36.2777L31.4082 57.8569C31.6755 58.5728 32.4907 58.9154 33.1892 58.6055C33.8066 58.3316 34.1197 57.6375 33.9163 56.9934L26.9821 35.0274Z" :fill="color"/>
<path opacity="0.9" d="M19.7062 37.208C19.4806 36.326 18.627 35.7554 17.7257 35.8841C16.6937 36.0315 16.0075 37.0287 16.2385 38.0453L20.4319 56.4999C20.7225 57.7788 22.1274 58.4549 23.3082 57.884C24.1817 57.4617 24.6367 56.4825 24.3963 55.5426L19.7062 37.208Z" :fill="color"/>
<path opacity="0.9" d="M11.642 37.9568C11.5539 37.3097 11.0179 36.8169 10.3657 36.7831C9.48979 36.7378 8.80101 37.5219 8.95879 38.3847L11.6653 53.1848C11.7501 53.6484 12.1424 53.993 12.6131 54.0173C13.2496 54.0502 13.759 53.4961 13.673 52.8646L11.642 37.9568Z" :fill="color"/>
<path opacity="0.9" d="M36.1686 17.1546C35.513 16.2264 34.2467 15.9716 33.2831 16.574C32.1151 17.3043 31.93 18.9312 32.904 19.9052L42.6472 29.6486C42.8686 29.87 43.1837 29.9702 43.4924 29.9174C44.1918 29.7976 44.5271 28.9892 44.1177 28.4096L36.1686 17.1546Z" :fill="color"/>
<ellipse opacity="0.9" rx="12.8416" ry="11.2314" transform="matrix(-0.90816 0.418624 0.418624 0.90816 16.3643 16.4233)" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>