<template>
<svg width="141" height="39" viewBox="0 0 141 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0 14C0 6.26801 6.26801 0 14 0H127C134.732 0 141 6.26801 141 14V25C141 32.732 134.732 39 127 39H123.22C121.339 39 119.505 38.4106 117.976 37.3146L109.949 31.5599C108.08 30.2204 105.839 29.5 103.54 29.5H36.5195C34.4767 29.5 32.4743 30.0688 30.7366 31.1427L20.1974 37.656C18.7756 38.5346 17.1373 39 15.466 39H14C6.26801 39 0 32.732 0 25V14Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'upper_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
