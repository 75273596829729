<template>

    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <g fill="none" fill-rule="evenodd" :stroke="color" stroke-linecap="round" stroke-width="1.5">
            <path d="M10.919 -2.126L10.919 26.182" transform="rotate(-44 10.919 12.028)"/>
            <circle cx="11.385" cy="11.5" r="10.615"/>
        </g>
    </svg>


</template>
<script>
    export default {
        name: 'not-applicable-icon',

        props: {
            // Color or hex code
            color: {
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>
    // @import "./style.scss";

</style>

