<template>
    
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M26 13.5C26 20.0104 21.659 26.7573 18.6619 27.4676C18.5547 27.493 18.4525 27.5005 18.3423 27.4984C14.8112 27.4305 2.89483 25.2012 1.00012 22.5C-0.440886 20.4456 5 16.5 3.00109 12C-0.498911 2 9.70101 0 15.5 0C25 0 26 3.31213 26 13.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_palm_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>