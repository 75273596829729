<template>
	<DownloadView :type="'checklist'"></DownloadView>
</template>

<script>
import { mapActions } from 'vuex'
import DownloadView from '@/component/DownloadView'

export default {
	name: 'checklists-complete',
	data() {
		return {
		}
	},
	props: {
		checklists: {
			type: Object
		},
		result: {
			type: Array
		},
		id: {
			type: String
		},
		completed: {
			type: Boolean
		}
	},
	mounted() {

	},
	methods: {

	},
	computed: {
	},
	components: {
		DownloadView
	},
	beforeDestroy () {
		//window.history.forward(1)
		this.$router.push({ name: 'checklists-complete', params: { completed: true }})
	}
}
</script>


<style lang="scss" scoped>
//@import "../style.scss";
</style>
