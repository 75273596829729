<template>
    <div>
        <template v-if="isLoading">
            <div class="loading-section">
                <Spinner class="icon"></Spinner>
            </div>
        </template>
        <el-row v-else style="margin-top: 40px;">
            <el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 10, offset: 7 }">
                <div class="message">

                    <img class="error" src="@/image/tick.jpg">

                    <p class="content-title">
                        Thanks for reading instructions from {{ doctor }}
                    </p>

                    <p class="content">
                        Get future instructions, questionnaires, and more from {{ doctor }} directly on the 
                        {{ query.account === 'livechair' ? 'Live Chair Health' : (isAccountGerd(query.account) ? 'GERDHelp' : 'DoctorPlan') }} app.
                        If you haven't already, click below to download the app.
                    </p>

                    <hr class="divider">
                    <div class="divider-container">
                        <div class="download-now">Download now!</div>
                    </div>

                    <div class="end">
                        <div class="link">

                            <a v-if="isAccountGerd(query.account)" href="https://apps.apple.com/in/app/gerdhelp/id1568842316">
                                <img src="@/image/download/ios.png">
                            </a>
                            <a v-else-if="query.account === 'livechair'" href="https://apps.apple.com/in/app/live-chair-health/id1537755288">
                                <img src="@/image/download/ios.png">
                            </a>
                            <a v-else href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
                                <img src="@/image/download/ios.png">
                            </a>
                            <a v-if="isAccountGerd(query.account)" href="https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US">
                                <img src="@/image/download/android.png">
                            </a>
                            <a v-else-if="query.account === 'livechair'" href="https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient">
                                <img src="@/image/download/android.png">
                            </a>
                            <a v-else href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
                                <img src="@/image/download/android.png">
                            </a>




                            <!-- <a :href="query.account === 'livechair' ? 'https://apps.apple.com/in/app/live-chair-health/id1537755288' : 'https://itunes.apple.com/us/app/doctorbox/id1437144807'">
                                <img src="@/image/download/ios.png">
                            </a>
                            <a :href="query.account === 'livechair' ? 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient' : 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'">
                                <img src="@/image/download/android.png">
                            </a> -->
                        </div>
                    </div>

                    <img class="logo" v-if="query.account === 'doctorplan'" src="@/image/DoctorPlan_logo_blue.png">
                    <img class="logo" style="width: 100px; margin-top: 10px" v-else-if="query.account === 'livechair'" src="@/image/livechair.png">
                    <img class="logo" style="width: 170px; margin-top: 10px" v-else-if="isAccountGerd(query.account)" src="@/image/gerdhelp.png">

                </div>
            </el-col>
        </el-row>
    </div>

</template>

<script>
import { mapActions } from 'vuex'
import Spinner from '@/component/icon/Spinner'
import isAccountGerd from '../module/util/is-account-gerd'


export default {
    name: 'message',
    props: {
        id: {
            type: String
        },
        query: {
            type: Object
        }
    },
    data() {
        return {
            doctor: 'doctor',
            isLoading: true
        }
    },
    mounted() {
        if (!this.query['handoff_token']) {
            this.$router.push({ path: '/download', query: { account: this.query.account } })
        } else {
            this.logout()
                .then(() => {
                    return this.handoff(this.query)
                })
                .then(() => {
                    var d = new Date()
                    // call the message read api
                    this.addEvent({
                        id: this.id,
                        type: 'message',
                        body: { 'accepted': d.getTime() }
                    }).then(e => {
                        if (e.hasOwnProperty('doctor') && e.doctor.hasOwnProperty('name'))
                            this.doctor = e.doctor.name
                        this.isLoading = false
                    }).catch((err) => {
                        this.isLoading = false
                        this.$router.push({ path: '/download', query: { account: this.query.account } })
                        this.$message({
                            showClose: true,
                            message: 'Unable to send response. Error: ' + err.code,
                            type: 'error',
                            duration: '0'
                        })
                    })
                })
        }


    },
    methods: {
        ...mapActions('user', [
            'loadEvent',
            'addEvent'
        ]),
        ...mapActions('auth', [
            'handoff',
            'logout'
        ]),
        isAccountGerd
    },
    components: {
        Spinner
    }
}
</script>

<style lang="scss">
    .loading-section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        .icon {
            padding: 12px;
            border-radius: 50%;
            background-color: #eeeeee;
        }
    }
    .message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 80vh;
        padding: 0 10px;

        .error {
            width: 60px;
        }

        .content-title {
            font-size: 18px;
            font-weight: 600;
            margin-top: 20px;
            text-align: center;
            color: #333333;
        }

        .content {
            font-size: 17px;
            font-weight: 400;
            color: #333333;
            text-align: center;
            line-height: 22px;
            padding: 40px 0 30px 0;
        }

        .divider-container {
            position: relative;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -27px;
        }

        .download-now {
            font-size: 16px;
            color: #666666;
            width: 130px;
            background: #ffffff;
            height: 50px;
            padding: 10px;
            font-weight: 500;
        }

        .divider {
            border-top: 1px solid #cccccc;
            width: 80%;
        }

        .logo {
            padding-top: 25px;
            width: 150px;
        }
        .end {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .link {
            padding-left: 20%;
            a {
                margin-top: 15px;
                width: 40%;
                height: 40px;
                display: inline-block;

                img {
                    width: 100%;
                }
            }
            a:first-child {
                width: 36%;
                margin-right: 2%;
            }
        }
        /*.link {*/
        /*    width: 40%;*/
        /*    a {*/
        /*        margin-top: 15px;*/
        /*        width: 51%;*/
        /*        height: auto;*/
        /*        display: inline-block;*/

        /*        img {*/
        /*            width: 100%;*/
        /*        }*/
        /*    }*/
        /*    a:first-child {*/
        /*        width: 47%;*/
        /*        margin-right: 2%;*/
        /*    }*/
        /*}*/
    }
</style>
