<template>
    
    <svg width="129" height="50" viewBox="0 0 129 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.15406 39.6358L15.4835 48.2665C17.895 49.9545 21.2477 49.5252 23.4567 47.5797C29.7176 42.0656 43.9343 32.3015 66.1071 32.3015C87.5168 32.3015 100.497 41.4051 106.45 46.9959C108.76 49.1655 112.443 49.6883 114.978 47.7868L125.851 39.6321C128.198 37.8724 128.774 34.6339 126.958 32.3312C119.396 22.743 97.9538 0 64.7027 0C31.6362 0 9.81534 22.4911 1.95566 32.1703C0.043543 34.525 0.669062 37.8963 3.15406 39.6358Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'scalp',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
