<template>
    
    <svg width="28" height="59" viewBox="0 0 28 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M20.3735 1.79149L12.2882 0.586697C10.1106 0.262217 8.08022 1.75843 7.74524 3.93441L4.40164 25.6539C4.36894 25.8663 4.32039 26.0718 4.25537 26.2766C3.78099 27.7709 1.7027 34.4553 1.01114 39C0.539446 42.0998 0.828599 46.4372 1.12625 49.3514C1.31519 51.2012 2.82191 52.6022 4.66406 52.8551C6.89537 53.1614 9.86086 53.6853 12.0098 54.5C13.7705 55.1675 15.7084 56.3053 17.4763 57.4957C20.1143 59.272 23.945 57.8206 24.3972 54.6726L27.4309 33.5524C27.4835 33.1859 27.485 32.8139 27.4353 32.447L23.7478 5.21113C23.509 3.44752 22.1337 2.05378 20.3735 1.79149Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_upper_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>