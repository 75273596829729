<template>
<svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M0.0206299 17.5504C0.478725 26.2314 8.45063 32.8676 17.8264 32.3728C27.2021 31.878 34.4313 24.4397 33.9732 15.7587C33.5151 7.0778 25.5432 0.441599 16.1674 0.936363C6.79171 1.43113 -0.437469 8.8695 0.0206299 17.5504ZM23.0634 16.9692C23.0701 17.5214 22.6279 17.9746 22.0756 17.9813C21.5234 17.988 21.0702 17.5458 21.0635 16.9936C21.0568 16.4413 21.499 15.9882 22.0512 15.9814C22.6035 15.9747 23.0566 16.4169 23.0634 16.9692ZM16.0639 14.079C16.6162 14.0722 17.0584 13.6191 17.0516 13.0668C17.0449 12.5146 16.5918 12.0724 16.0395 12.0791C15.4873 12.0858 15.045 12.539 15.0518 13.0912C15.0585 13.6435 15.5117 14.0857 16.0639 14.079ZM17.1371 22.0541C17.1438 22.6064 16.7016 23.0595 16.1494 23.0663C15.5971 23.073 15.144 22.6308 15.1372 22.0785C15.1305 21.5263 15.5727 21.0731 16.125 21.0664C16.6772 21.0597 17.1303 21.5019 17.1371 22.0541ZM10.1379 20.1522C10.6901 20.1455 11.1324 19.6923 11.1256 19.1401C11.1189 18.5878 10.6657 18.1456 10.1135 18.1523C9.56124 18.1591 9.11902 18.6122 9.12576 19.1645C9.1325 19.7167 9.58565 20.1589 10.1379 20.1522Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'right_knee_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
