<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="59" height="51" viewBox="0 0 59 51">
	    <defs>
	        <linearGradient id="i-as-a" x1="100%" x2="0%" y1="0%" y2="100%">
	            <stop offset="0%" stop-color="#2C6CA3"/>
	            <stop offset="100%" stop-color="#2D3E4F"/>
	        </linearGradient>
	        <linearGradient id="i-as-b" x1="0%" x2="89.581%" y1="50%" y2="43.571%">
	            <stop offset="0%"/>
	            <stop offset="100%" stop-opacity="0"/>
	        </linearGradient>
	    </defs>
	    <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
	        <path fill="#2D3E4F" d="M0 8h32v32H0z"/>
	        <circle cx="33.5" cy="23.5" r="24.5" fill="url(#i-as-a)" stroke="#FFF" stroke-width="2"/>
	        <path fill="url(#i-as-b)" d="M16.432 24.102l21.437-2.25.563 17.058-19.647 1.344-.696-8.278z" opacity=".339" transform="rotate(142 27.432 31.053)"/>
	        <path fill="#FFF" d="M28.295 17.875c1.252.003 3.53.293 4.715 2.202a.516.516 0 0 1 .073.272v7.72c0 .259-.3.406-.503.244-1.395-1.107-3.172-1.313-4.283-1.315a.538.538 0 0 1-.538-.537v-8.05a.533.533 0 0 1 .534-.536h.002zm10.946 8.586a.538.538 0 0 1-.539.537c-1.11.002-2.887.208-4.282 1.315a.31.31 0 0 1-.503-.245v-7.72c0-.1.025-.193.073-.271 1.186-1.909 3.463-2.199 4.715-2.202h.002a.534.534 0 0 1 .534.537v8.049zm1.222-6.73c.297 0 .537.24.537.537v8.53a.534.534 0 0 1-.657.523c-1.007-.229-2.609-.488-4.285-.337-.35.031-.477-.45-.158-.596 1-.455 2.069-.556 2.805-.558a1.373 1.373 0 0 0 1.37-1.37v-6.416c0-.173.14-.313.312-.313h.076zM31.1 28.388c.32.145.193.627-.157.595-1.676-.15-3.278.109-4.285.338a.536.536 0 0 1-.657-.523v-8.754c0-.173.14-.313.313-.313h.3c.173 0 .313.14.313.313v6.417c0 .753.614 1.368 1.368 1.37.737.002 1.806.102 2.805.557z"/>
	    </g>
	</svg>
</template>
<script>
export default { name: 'article-section-icon' }
</script>
