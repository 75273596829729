<template>
<svg width="60" height="107" viewBox="0 0 60 107" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 69.8589L1.38892 29.7303C1.38892 29.7303 5.54484 18.4312 10.1854 12.402C14.5133 6.77905 21.857 0.545851 21.857 0.545851L42.362 0.0898438H60V103.603C60 103.603 42.5935 107.707 31.0192 105.883C19.4449 104.059 9.72242 97.2193 9.72242 97.2193L0 69.8589Z" :fill="color"/>
</svg>

</template>
<script>
export default {
	name: 'right_chest',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
