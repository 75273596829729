<template>
    <div v-if="question" class="questionnaire-date">
        <!-- <Calendar class="calendar"></Calendar> -->
        <cleave v-model="date" 
            pattern="\d*"
            :options="options" 
            :placeholder="getType === 'date' ? 'MM/DD/YYYY' : 'MM/YYYY'"
            class="form-control"
            :style="getType === 'date' ? 'width: 170px' : 'width: 135px'"
            name="card"/>
    </div>
</template>

<script>
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'

    import Cleave from 'vue-cleave-component'
    import Calendar from './icon/calendar'
import question from './index'


    export default {
        name: 'questionnaire-date',
        extends: Question,
        props: {
            saved: {
                type: Array
            }
        },
        data() {
            return {
                options: {
                    date: true,
                    delimiter: '/',
                    datePattern: this.getType === 'date' ? ['m', 'd', 'Y'] : ['m', 'Y']
                },
                date: null
            }
        },
        watch: {
            date(value) {
                var currentDate = new Date()

                let ansObj = {}
                if (value === null || value.length === 0) {
                    if(this.isMandatory)
                        this.$emit('disableNext', true)
                    this.$emit('selected', [{}])
                    return
                } else if (typeof value === 'object') {
                    return
                } else if (this.getType === 'date' && value.length === 8) {
                    this.$emit('disableNext', false)
                
                    // Parses the entered date from value
                    let date = parseInt(value[2] + value[3]),
                    month = parseInt(value[0] + value[1])-1,
                    year = parseInt(value.substr(4, 4))

                    // if entered year is less than 1900 then set the year to 1900
                    if (year < 1900) {
                        year = 1900
                        this.date = value.substr(0, 4) + '1900'
                    }

                    // Makes a date obj from the parsed date
                    let enteredDate = new Date(year, month, date)

                    // if the date entered is in future then set today's(current) date
                    if (enteredDate.getTime() > currentDate.getTime() && this.hasValidation) {
                        enteredDate = currentDate
                        // set this.date to currrent date
                        this.date = this.populateZeros(currentDate.getMonth() + 1) 
                        + this.populateZeros(currentDate.getDate()) 
                        + this.populateZeros(currentDate.getFullYear())
                    }

                    ansObj = {
                        'id': uuidv4(),
                        'date': enteredDate.getDate(),
                        'month': enteredDate.getMonth() + 1,
                        'year': enteredDate.getFullYear()
                    }
                    this.$emit('selected', [ansObj])
                } else if (this.getType === 'month' && value.length === 6) {
                    this.$emit('disableNext', false)

                    // Parses the entered date from value
                    let month = parseInt(value[0] + value[1]),
                    year = parseInt(value.substr(2, 4))

                    // if entered year is less than 1900 then set the year to 1900
                    if (year < 1900) {
                        year = 1900
                        this.date = value.substr(0, 2) + '1900'
                    }

                    // Makes a date obj from the parsed date
                    let enteredDate = new Date(year, month, 0)

                    // if the date entered is in future then set today's(current) date
                    if (enteredDate.getTime() > currentDate.getTime() && this.hasValidation) {
                        enteredDate = currentDate
                        // set this.date to currrent date
                        this.date = this.populateZeros(currentDate.getMonth() + 1) 
                        + this.populateZeros(currentDate.getFullYear())
                    }

                    // if question.prompt array does not have date
                    // then do not send date in the answer
                    ansObj = {
                        'id': uuidv4(),
                        'month': enteredDate.getMonth() + 1,
                        'year': enteredDate.getFullYear()
                    }
                    this.$emit('selected', [ansObj])
                } else {// if the value (date) is not complete the disable next
                    this.$emit('selected', [{}])
                    if(this.isMandatory)
                        this.$emit('disableNext', true)
                }

            }
        },

        created() {
            this.options.datePattern = this.getType === 'date' ? ['m', 'd', 'Y'] : ['m', 'Y']
        },
        mounted() {
            // if does not have saved answer in localStorage then emit empty value
            // else parse the date from saved answer and assign it to date variable
            if (!this.saved)
                this.$emit('selected', [])
            else if (this.saved[0] && this.saved[0] !== '')
                this.date = this.objToDateFormat(this.saved[0])
        },
        methods: {
            /**
             * @param digits {number}
             * @desc Converts single digit numbers to string with zeros prefixed
             * @returns string eg: '09'
             */
            populateZeros(digits) {
                if ((''+digits).length === 1)
                    return '0' + digits
                return ''+digits
            },
            /**
             * @param dateObj
             * @desc Converts dateObj to javascript date string
             * @returns string with format MMDDYYYY
             */
            objToDateFormat(dateObj) {
                if(dateObj && typeof dateObj === 'object' && Object.keys(dateObj).length === 0)
                    return ''
                console.log(dateObj)
                // adds zeros which were lost during string to integer conversion
                if ((''+dateObj.month).length !== 2)
                    dateObj.month = '0' + dateObj.month

                let date = dateObj.month
                if (this.getType === 'date') {
                    // adds zeros which were lost during string to integer conversion
                    if ((''+dateObj.date).length !== 2)
                        dateObj.date = '0' + dateObj.date
                    date = date + ' ' + dateObj.date
                }

                // adds zeros which were lost during string to integer conversion
                if (('' + dateObj.year).length === 1)
                    dateObj.year = '000' + dateObj.year
                else if (('' + dateObj.year).length === 2)
                    dateObj.year = '00' + dateObj.year
                else if (('' + dateObj.year).length === 3)
                    dateObj.year = '0' + dateObj.year

                date = date + ' ' + dateObj.year
                return date
            }
        },
        computed: {
            hasValidation() {
                if (!this.question.hasOwnProperty('validations'))
                    return false 
                return this.question.validations.filter(v => v.type === 'range').length !== 0
            },
            /**
             * @desc Specifies the type according to question.prompt array
             * @returns {string}
             */
            getType() {
                if (this.question && this.question.prompt && !this.question.prompt.includes('date'))
                    return 'month'
                return 'date'
            },
            isMandatory() {
                if(this.question.hasOwnProperty('validations')) {
                    const mandateType = this.question.validations.find((val) => val.type === "mandatory")
                    if(mandateType)
                        return mandateType.mandatory
                }
                return false
            }
        },
        components: {
            Calendar,
            Cleave
        }
    }
</script>

<style lang="scss" scoped>
    .questionnaire-date {
        position:relative;
    }
    .calendar {
        position: absolute;
        top: 12px;
        left: 15px;
        z-index: 10001;
    }
    .flatpickr-input, .flatpickr-input.flatpickr-mobile {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 100%;
        background-color: #faf5fc;
        border: none;
        border-radius: 0px;
        border-bottom: 3px solid #833e93;
        color: #333333;
        font-size: 16px;
        font-weight: 600;
        padding: 2px 0 0 50px;
        outline: none;
    }
    .flatpickr-monthDropdown-months {
        background-color: #ffffff;
    }


    .form-control {
        padding: 5px 20px;
        height: 52px;
        font-size: 20px;
        border: none;
        border-bottom: 3px solid #833e93;
        letter-spacing: 2px;
        font-weight: 500;
    }
    .form-control::placeholder {
        font-size: 18px;
    }
    .form-control, focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        outline: none;
    }

</style>
