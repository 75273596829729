<template>
    
    <svg width="21" height="72" viewBox="0 0 21 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M17.9892 57.0489C17.9664 55.7761 17.9541 54.4277 17.954 53C17.954 51.2881 21.3618 39.0759 20.9545 32.5C20.5506 25.9796 17.0631 16.5 14.957 10.5C14.5962 9.2008 14.3082 7.99537 14.0795 6.88372C13.2147 2.6784 8.14667 -1.02734 4.12222 0.468165C1.71992 1.36087 0.270447 3.80808 0.644842 6.34339C1.67945 13.3495 3.67322 27.014 4.45517 33.5C5.76397 44.3561 2.5 47 2.5 62C2.5 64.2836 2.90455 66.2079 3.59048 67.8295C7.33363 76.6784 18.1612 66.6554 17.9892 57.0489Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>