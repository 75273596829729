<template>
	<div class="checklists-link">
		<router-link :to="'/checklist/' + checklists.id">
			<el-row type="flex">
				<el-col :span="23">
					<h1>{{ content.title }}</h1>
					<span :style="checklists.hasOwnProperty('item') && calcCompleted === checklists.item.length ? 'color: #44b358' : ''"
						  class="checklist-status-text" v-html="calcText">
					</span>
					<el-row class="status" v-if="checklists.hasOwnProperty('doctor')">
						<el-col :xs="2" :sm="1" type="flex" align="left">
							<img v-if="checklists.doctor.hasOwnProperty('image')" :src="checklists.doctor.image">
							<div v-else style="width: 35px; height: 35px;"><DefaultDoctor></DefaultDoctor></div>
						</el-col>
						<el-col :span="20" type="flex" align="left">
							<span class="title">{{ checklists.doctor.name }}</span>
							<br>
							<span class="subtitle">{{ checklists.doctor.specialization }}</span>
						</el-col>
					</el-row>
				</el-col>
				<el-col :span="1" style="display: flex; align-items: center">
					<i class="el-icon-arrow-right"></i>
				</el-col>
			</el-row>
		</router-link>

	</div>
</template>
<script>
import formatDate from '@/module/util/format-date'
import DefaultDoctor from './icon/DefaultDoctor'

export default {
	name: 'landing-checklists',

	props: {
		checklists: {
			type: Object
		},
		content: {
			type: Object
		}
	},

	methods: {
		formatDate
	},
	computed: {
		/**
		 * @func calcCompleted
		 * @desc Calculates the number of tasks completed in a checklist
		 * @return Number
		 */
		calcCompleted() {
			if (this.checklists && this.checklists.hasOwnProperty('item'))
				return this.checklists.item.filter(task => task.hasOwnProperty('state') && task.state).length
			return 0
		},
		/**
		 * @func calcText
		 * @desc Calculates the text to be shown according to the number of tasks completed
		 * @return String
		 */
		calcText() {
			let completed = this.calcCompleted
			if (this.checklists.hasOwnProperty('item') && completed === this.checklists.item.length) {
				return 'All ' + completed + ' tasks completed before ' + this.formatDate(this.checklists.time)
			} else {
				return completed + ` tasks completed <span style="color: #333333; font-weight: 400">&nbsp;&bull;&nbsp; Due on <b>${ this.formatDate(this.checklists.time) }</b></span>`
			}
		}
	},
	components: {
		DefaultDoctor
	}
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
