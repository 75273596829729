<template>
    
    <svg width="49" height="60" viewBox="0 0 49 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0357 31.4693C16.567 30.3844 17.8807 29.9404 18.9613 30.4806C20.0714 31.0357 20.4925 32.4058 19.8859 33.4886L10.0086 51.1188C9.5871 51.8712 8.6489 52.1598 7.87739 51.7744C7.08372 51.3779 6.7583 50.4156 7.14846 49.6188L16.0357 31.4693Z" :fill="color"/>
<path d="M23.5026 34.7072C23.7952 33.9971 24.5322 33.5761 25.2925 33.6847C26.398 33.8426 27.0441 35.0157 26.5867 36.0345L17.0611 57.2504C16.7549 57.9323 15.9429 58.2226 15.2738 57.8894C14.6558 57.5816 14.3807 56.8485 14.6437 56.21L23.5026 34.7072Z" :fill="color"/>
<path d="M30.1152 37.004C30.3342 36.1481 31.1626 35.5943 32.0372 35.7193C33.0756 35.8676 33.7446 36.8979 33.4575 37.9068L28.0884 56.7785C27.8126 57.7481 26.6643 58.158 25.8368 57.5822C25.3337 57.2321 25.1008 56.6063 25.2527 56.0125L30.1152 37.004Z" :fill="color"/>
<path d="M37.3286 37.9894C37.4266 37.1402 38.1456 36.4994 39.0004 36.4994C40.0449 36.4993 40.8373 37.4407 40.659 38.4698L38.1831 52.7607C38.0774 53.3707 37.5481 53.8163 36.9289 53.8163C36.1681 53.8163 35.5772 53.1532 35.6645 52.3974L37.3286 37.9894Z" :fill="color"/>
<path d="M13.9757 18.0637C14.5383 17.4795 15.4708 17.4707 16.0444 18.0442C16.5827 18.5825 16.6127 19.4455 16.1132 20.02L7.5327 29.8875C7.19438 30.2766 6.70408 30.5 6.18848 30.5C4.61762 30.5 3.81572 28.6146 4.90533 27.483L13.9757 18.0637Z" :fill="color"/>
<ellipse opacity="0.9" cx="32.0396" cy="16.4233" rx="12.8416" ry="11.2314" transform="rotate(24.7477 32.0396 16.4233)" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>