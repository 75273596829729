<template>
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M1.0008 15.2618C4.00391 24.2618 8.99646 22.2618 15.9977 19.2618C22.9977 15.2618 25.9977 15.2618 22.9988 6.26176C21.4996 0.261897 20.5007 -1.23796 11 1.26196C3.50077 4.26202 -2.00231 6.26176 1.0008 15.2618Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_elbow_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>