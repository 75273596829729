<template>
    <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <rect x="6" y="4" width="4" height="16" fill="currentColor" />
    <rect x="14" y="4" width="4" height="16" fill="currentColor" />
</svg>
</template>

<script>
export default {
    name: 'PauseIcon',
    props: {
        width: {
            type: String,
            default: '24',
        },
        height: {
            type: String,
            default: '24',
        },
    },
};
</script>
