<template>
    <svg width="41" height="106" viewBox="0 0 41 106" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M0.287064 46.2511L4.38075 12.3878C4.87337 8.31279 5.11968 6.2753 6.25309 4.77155C6.56717 4.35485 6.92685 3.97458 7.32544 3.63781C8.76384 2.42253 10.7845 2.0633 14.8257 1.34484C20.3926 0.355147 23.1761 -0.139697 25.3155 1.00536C25.9 1.31822 26.4372 1.71255 26.9108 2.17651C28.6442 3.87458 29.006 6.67843 29.7295 12.2861L33.828 44.049C33.9203 44.7643 33.9664 45.122 34.0422 45.4527C34.1179 45.7833 34.2401 46.1497 34.4846 46.8824C35.7259 50.6031 39.0652 61.0513 40.0152 68.5C40.6586 73.5443 40.589 80.2137 40.399 85.1585C40.2747 88.3912 40.2126 90.0075 39.3757 91.487C38.5388 92.9664 37.1189 93.8958 34.2789 95.7547L29.7897 98.6931C21.9616 103.817 18.0475 106.379 14.6229 104.95C11.1982 103.52 10.2665 98.9362 8.40296 89.7678L0.466393 50.7199L0.466381 50.7198C0.241276 49.6123 0.128727 49.0585 0.106167 48.4963C0.0836067 47.9342 0.151424 47.3731 0.287064 46.2511Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_upper_arm_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>