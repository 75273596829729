<template>
    
    <svg width="93" height="78" viewBox="0 0 93 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M38.1943 -0.000753452C29.2273 -0.000751884 20.3104 2.57666 15.5439 4.2168C13.2758 4.99721 11.6121 6.86735 10.9659 9.17721C8.10281 19.4117 0.320445 48.4214 0.999043 59.0434C2.68862 85.49 24.0965 76.4069 45.2609 76.5426C60.5173 76.6404 84.5639 64.9925 86.3887 64.0975C86.4705 64.0574 86.5405 64.0244 86.6219 63.9835C87.8124 63.3847 97.0899 58.2488 89.499 47.0432C81.5309 35.2808 74.7146 17.4718 71.8906 9.56701C70.9931 7.05477 68.96 5.10877 66.3719 4.46177C59.9181 2.84839 47.2404 -0.000755034 38.1943 -0.000753452Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>