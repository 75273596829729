<template>
	<el-row>
		<el-col :xs="24" :sm="{ span: 12 }" :md="{ span: 8, offset: 2 }">
			<div class="download complete">
				<img v-if="hasAccountGerd" style="width: 280px" class="logo" src="@/image/gerdhelp.png">
				<img v-else-if="$route.query.account === 'livechair'" class="logo" src="@/image/livechair.png">
				<img v-else class="logo" src="@/image/DoctorPlan_logo_blue.png">
				<p v-if="hasAnswered" class="answered">You’ve already answered the questionnaire</p>
				<!-- <p class="content">Next, download the free DoctorPlan app on your phone, so your doctor can monitor your progress.</p> -->
				<p class="content-main" :style="$route.query.account === 'livechair' ? 'color: #25136c' : ''"
				   :class="{ qanswered: hasAnswered }">
					Maintain your Digital Health Diary with 
					{{ $route.query.account === 'livechair' ? 'Live Chair Health' : (hasAccountGerd ? 'GERDHelp' : 'DoctorPlan') }}
				</p>
				<div class="download-gif hide-for-large" >
					<img v-if="hasAccountGerd" :src="require('@/image/gerdhelp.gif')" />
					<img v-else-if="$route.query.account === 'livechair'" :src="require('@/image/LCH-Download-App-GIF.gif')" />
					<img v-else :src="require('@/image/download-dp.gif')" />
				</div>

				<div class="end">
					<div class="link">
						<a v-if="hasAccountGerd" href="https://apps.apple.com/in/app/gerdhelp/id1568842316">
							<img src="@/image/download/ios.png">
						</a>
						<a v-else-if="$route.query.account === 'livechair'" href="https://apps.apple.com/in/app/live-chair-health/id1537755288">
							<img src="@/image/download/ios.png">
						</a>
						<a v-else href="https://itunes.apple.com/us/app/doctorbox/id1437144807">
							<img src="@/image/download/ios.png">
						</a>
						<a v-if="hasAccountGerd" href="https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US">
							<img src="@/image/download/android.png">
						</a>
						<a v-else-if="$route.query.account === 'livechair'" href="https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient">
							<img src="@/image/download/android.png">
						</a>
						<a v-else href="https://play.google.com/store/apps/details?id=com.doctorbox.patient">
							<img src="@/image/download/android.png">
						</a>


						<!-- <a :href="$route.query.account === 'livechair' ? 'https://apps.apple.com/in/app/live-chair-health/id1537755288' : 'https://itunes.apple.com/us/app/doctorbox/id1437144807'">
							<img src="@/image/download/ios.png">
						</a>
						<a :href="$route.query.account === 'livechair' ? 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient' : 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'">
							<img src="@/image/download/android.png">
						</a> -->
					</div>
				</div>
				<div class="question-button" v-if="$route.query.account === 'doctorplan'">
					<a href="https://doctorplan.com">
						What is <b>DoctorPlan?</b>
					</a>
				</div>
				<div class="question-button" v-if="hasAccountGerd">
					<a href="https://www.gerdhelp.com">
						What is <b>GERDHelp?</b>
					</a>
				</div>
			</div>
		</el-col>
		<el-col :md="10" :offset="2" class="hide-for-small">
			<div class="download complete">
				<div class="download-gif" >
					<img v-if="hasAccountGerd" :src="require('@/image/gerdhelp.gif')" />
					<img v-else-if="$route.query.account === 'livechair'" :src="require('@/image/LCH-Download-App-GIF.gif')" />
					<img v-else :src="require('@/image/download-dp.gif')" />
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
import { mapActions } from 'vuex'
import isAccountGerd from '../module/util/is-account-gerd'


const modules = ['symptom', 'lifestyle', 'message', 'activity', 'questionnaire']

export default {
	name: 'download-complete',
	data() {
		return {
			modules,
			questionnaireContent: [
				{
					text: 'Access dozens of ' + 'exercise'.bold() + ' videos and ' + 'mindfulness'.bold() + ' lessons for your condition.',
					img: "exercise"
				},
				{
					text: 'Record your ' + 'symptoms'.bold() + ' and ' + 'vitals'.bold() + ' so your doctor can monitor and follow your progress.',
					img: "symptom"
				},
				{
					text: 'See 50+ ' + 'articles'.bold() + ' related to your condition and treatment plan',
					img: "article"
				},
				{
					text: 'Review all previously answered ' + 'questionnaires'.bold(),
					img: "questionnaire"
				}
            ],
            articleContent: [
				{
					text: 'See 50+ more ' + 'articles'.bold() + ' related to your condition and treatment plan',
					img: "article"
				},                
				{
					text: 'Access dozens of ' + 'exercise'.bold() + ' videos and ' + 'mindfulness'.bold() + ' lessons for your condition.',
					img: "exercise"
                },   
				{
					text: 'Record your ' + 'symptoms'.bold() + ' and ' + 'vitals'.bold() + ' so your doctor can monitor and follow your progress.',
					img: "symptom"
                },
				{
					text: 'Review all previously answered ' + 'questionnaires'.bold(),
					img: "questionnaire"
                }                             
            ]
		}
	},
	props: {
		result: {
			type: Array
		},
		id: {
			type: String
		},
		completed: {
			type: Boolean
        },
        type: {
            type: String
        }
	},
	mounted() {
		console.log(this.$route.query.account)
	},
	methods: {
		getImageClass(image) {
			var classObj = { 'complete-icon': true }

			if (image == 'questionnaire')
				classObj.questionnaire = true
			if (image == 'symptom')
				classObj.symptom = true
			if (image == 'exercise')
				classObj.exercise = true
			return classObj
		},
		isAccountGerd
	},
	computed: {
		getImage() {
			return (image) => { 
				return require('@/image/download/' + image + '.svg')
			}
		},
		getSymptomScreen() {
			return require("@/image/download/symptoms-ss.jpeg")
		},
		getDashboardScreen() {
			return require("@/image/download/dashboard-ss.png")
        },
        getType() {
            return this.type == 'article' ? this.articleContent : this.questionnaireContent
		},
		hasAnswered() {
			if(this.$route.params.completed)
				return true
			return false
		},
		hasAccountGerd() {
			return this.isAccountGerd(this.$route.query.account)
		}
	},
	components: {
	}
}
</script>


<style lang="scss" scoped>
//@import "../style.scss";
.download.complete {
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0 40px;
	.logo {
		width: 220px;
		height: auto;
		margin-top: 40px;
		margin-bottom: 40px;
	}
	.download-gif {
		width: 100%;
		img {
			max-width: 100%;
		}
	}
	.content-main {
		text-align: left;
		margin: 50px 0 10px 0;
		max-width: 350px;
        font-size: 18px;
		line-height: 26px;
		color: #293f4e;
		margin-top: 12px;
		margin-bottom: 20px;
	}
	.answered {
		text-align: left;
		font-size: 25px;
		font-weight: 500;
		color: #44b358;
	}
	.content-sub {
		text-align: center;
		margin: 0 0 10px 0;
		font-size: 18px;
		color: #666666;
		line-height: 26px;
	}
	.modules {
		margin: 30px 0 40px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		width: 100%;
		padding-left: 40px;
		img {
			margin-right: 35px;
		}
	}
	.text-personalized {
		font-size: 18pt;
		margin: 30px 50px 30px 50px;
        text-align: center;
        line-height: 30px;
	}
	.text-etc {
		margin-top: 10px;
		text-align: center;
		font-weight: 600;
	}
	.link {
		margin-top: 30px;
		a {
			margin-top: 15px;
			width: 43.5%;
			height: auto;
			display: inline-block;

			img {
				width: 100%;
			}
		}
		a:first-child {
			width: 40%;
			margin-right: 2%;
		}
	}
	.question-button {
		text-align: center;
		margin-top: 40px;
		a {
			color: #293f4e;
			text-align: center;
			font-size: 20px;
			text-decoration: underline;
		}
	}
	
}
@media screen and (max-width: 900px) {
	.hide-for-small {
		display: none;
	}
	.download.complete {
		.logo {
			width: 140px;
		}
	}
}
@media screen and (min-width: 901px){
	.hide-for-large {
		display: none;
	}
	.download.complete {
		margin-top: 100px;
		text-align: left;
		align-items: flex-start;
		.content-main {
			font-size: 42px;
			line-height: 50px;
			font-weight: 600;
			max-width: 400px;
			margin-top: 50px;
		}
		.content-main.qanswered {
			font-size: 24px;
			max-width: 400px;
			line-height: 31px;
			font-weight: 400;
			margin-top: 16px;
		}
		.answered {
			font-size: 36px;
		}
	}
}
</style>
