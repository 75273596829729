<template>
	<div class="radio">
		<div class="boxes" v-if="question.hasOwnProperty('options')">
			<div v-for="(option, index) in question.options"
				:key="option.id"
				v-bind:class="['choice', 'radio', {
					selected: isSelected(option)
				}]"
				v-bind:style="{ 'margin-bottom': '20px' }"
				@click="select(option)">
				{{ index + 1 }}
			</div>
		</div>
		<div class="boxes" v-else>
			<div
				v-for="choice in 5"
				:key="choice"
				v-bind:class="['choice', 'radio', {
					selected: isSelected(choice - 1)
				}]"
				v-bind:style="{ 'margin-bottom': '20px' }"
				@click="select(choice - 1)">
				{{ choice }}
			</div>
		</div>

		<div class="labels">
			<div class="start">
				{{ start }}
			</div>
			<div class="arrow">
				<LongArrow></LongArrow>
			</div>
			<div class="end">
				{{ end }}
			</div>
		</div>
	</div>
</template>

<script>
import Question from './index'
import LongArrow from './icon/LongArrow'

export default {
	name: 'questionnaire-radio',
	extends: Question,

	data() {
		return {
			start: '',
			end: ''
		}
	},

	mounted() {
		this.$emit('selected', this.saved)
		this.fillValues()
	},

	methods: {
		fillValues() {
			if (this.question.hasOwnProperty('options') && this.question.options.length >= 2) {
				let lastIndex = this.question.options.length - 1
				this.start = this.question.options[0].description
				this.end = this.question.options[lastIndex].description
			} else {
				this.start = this.question.start
				this.end = this.question.end
			}
		}
	},

	components: {
		LongArrow
	}
}
</script>

<style lang="scss" scoped>
$choice-size: 60px;
$choice-font-size: 20px;

@import "style.scss";
.radio {
	margin-top: 20px;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	.boxes {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
        align-items: stretch;
	}
	.choice {
		flex: 1;
		height: auto;
	}
	.labels {
		.start, .end, .arrow {
			display: inline-block;
			text-align: center;
		}
		.arrow {
			text-align: center;
		}
		.start {
			float: left;
			width: 20%;
		}
		.end {
			float: right;
			width: 20%;
		}
	}
}

</style>
