<template>
  <div>
    <el-radio-group
      v-model="humanSide"
      fill="#833e93"
      text-color="#ffffff"
      size="small"
    >
      <el-radio-button label="Front"></el-radio-button>
      <el-radio-button label="Back"></el-radio-button>
    </el-radio-group>

    <div class="human-map-wrapper">
      <span :class="humanSideLowerCase + '_left'">LEFT</span>
      <span :class="humanSideLowerCase + '_right'">RIGHT</span>
      <div key="body">
        <body_f
            v-if="humanSide === 'Front' && !isFrontAllPartsSelected"
            :key="selectedParts.length"
            color="#B2B2B2"
            class="body_f">
        </body_f>
        <body_f_selected
            v-if="humanSide === 'Front' && isFrontAllPartsSelected"
            :key="selectedParts.length"
            class="body_f_selected">
        </body_f_selected>
        <body_b
            v-if="humanSide === 'Back' && !isBackAllPartsSelected"
            :key="selectedParts.length"
            color="#B2B2B2"
            class="body_b">
        </body_b>
        <body_b_selected
            v-if="humanSide === 'Back' && isBackAllPartsSelected"
            :key="selectedParts.length"
            class="body_b_selected">
        </body_b_selected>
      </div>
      <div v-for="bodyPart in humanMap" :key="bodyPart.id">
        <template v-if="bodyPart.side.toLowerCase() === humanSideLowerCase">
          <div
            class="tooltip"
            v-if="selectedParts.filter((bp) => bp.id === bodyPart.id).length"
          >
            <span :ref="bodyPart.id + '_tooltip'" class="tooltiptext">{{
              bodyPart.tooltip
            }}</span>
          </div>
          <div
            :is="bodyPart.id"
            :ref="bodyPart.id"
            :key="selectedParts.length"
            :class="bodyPart.id"
            @click.native="toggle(bodyPart.id)"
            :color="
              selectedParts.filter((bp) => bp.id === bodyPart.id).length
                ? '#833E93'
                : '#CCCCCC'
            "
          ></div>
        </template>
      </div>
    </div>
    <div class="selected-text">
      <span v-if="calcSelectedList['Front'].length" style="margin-top: 20px; "
        >Front<br />
        <span
          v-for="part in calcSelectedList['Front']"
          :key="part"
          class="bullet-text"
          >&bull; {{ part }}</span
        >
      </span>
      <br /><br />
      <span v-if="calcSelectedList['Back'].length" style="margin-top: 10px;"
        >Back<br />
        <span
          v-for="part in calcSelectedList['Back']"
          :key="part"
          class="bullet-text"
          >&bull; {{ part }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { RadioGroup, RadioButton } from "element-ui";
import { v4 as uuidv4 } from "uuid";
import SubQuestion from './index'
import IconComponents from "../icon/SwollenLymphNodes/components";

Vue.use(RadioGroup);
Vue.use(RadioButton);

const humanMap = [
  { id: "groin", side: "Front", tooltip: "Groin" },
  { id: "left_knee_f", side: "Front", tooltip: "Left Knee (F)" },
  { id: "right_knee_f", side: "Front", tooltip: "Right Knee (F)" },
  { id: "neck_f", side: "Front", tooltip: "Neck (F)" },
  { id: "right_jaw", side: "Front", tooltip: "Right Jaw" },
  { id: "left_jaw", side: "Front", tooltip: "Left Jaw" },
  { id: "right_axilla", side: "Front", tooltip: "Right Axilla" },
  { id: "left_axilla", side: "Front", tooltip: "Left Axilla" },
  { id: "right_elbow_crease", side: "Front", tooltip: "Right Elbow Crease" },
  { id: "left_elbow_crease", side: "Front", tooltip: "Left Elbow Crease" },

  { id: "neck_b", side: "Back", tooltip: "Neck (B)" },
  { id: "left_knee_b", side: "Back", tooltip: "Left Knee (B)" },
  { id: "right_knee_b", side: "Back", tooltip: "Right Knee (B)" },
];

export default {
  name: "swollen_lymph_nodes",
  extends: SubQuestion,
  watch: {
    humanSide: function() {
      this.$nextTick(() => this.removeTooltips())
      this.$emit('changeHumanMapSide', this.humanSide)
    },
  },

  props: {
    saved: {
      type: Array,
    },
  },

  data() {
    return {
      humanMap: JSON.parse(JSON.stringify(humanMap)),
      humanSide: "Front",
      selectedParts: [] || this.saved,
      unsortedBodyParts: [] || this.saved,
      frontTotalParts: JSON.parse(JSON.stringify(humanMap)).filter(ele => ele.side === "Front"),
      backTotalParts: JSON.parse(JSON.stringify(humanMap)).filter(ele => ele.side === "Back"),
      normalizeHumanMap: JSON.parse(JSON.stringify(humanMap)).reduce((acc,ele) => {
          return { ...acc, [ele.id]: { ...ele }}
      }, {})
    };
  },

  mounted() {
    this.loadData()
    this.$emit('changeHumanMapSide', this.humanSide)
  },

  methods: {
    /**
     * @func placeTooltip
     * @desc places tooltip on body part selection
     * @param bodyPart(String) string indicating if the body part is active or inactive
     */
    placeTooltip(bodyPart) {
      let imgRef = this.$refs[bodyPart][0].$el;
      let tooltipRef = this.$refs[bodyPart + "_tooltip"][0];
      tooltipRef.style.visibility = "visible";

      let top = parseInt(
        window
          .getComputedStyle(imgRef, null)
          .getPropertyValue("top")
          .replace("px", "")
      );
      let left = parseInt(
        window
          .getComputedStyle(imgRef, null)
          .getPropertyValue("left")
          .replace("px", "")
      );

      tooltipRef.style.top = top + 80 + "px";
      // for body parts on right side of the screen
      if (
        (this.humanSide === "Front" && bodyPart.includes("left")) ||
        (this.humanSide === "Back" && bodyPart.includes("right"))
      ) {
        tooltipRef.style.left = left - 60 + "px";
      }
      // for body parts on left side of the screen
      else if (
        (this.humanSide === "Front" && bodyPart.includes("right")) ||
        (this.humanSide === "Back" && bodyPart.includes("left"))
      ) {
        tooltipRef.style.left = left - 30 + "px";
      }
      // for head
      else if (
        bodyPart.includes("head") ||
        bodyPart.includes("neck") ||
        bodyPart.includes("throat") ||
        bodyPart.includes("knee") ||
        bodyPart.includes("abdomen")
      ) {
        tooltipRef.style.top = top + 50 + "px";
        tooltipRef.style.left = left + "px";
      }
      // for middle body parts
      else tooltipRef.style.left = left + 10 + "px";
    },
    /**
     * @func removeTooltips
     * @desc removes all tooltips
     * @param tooltipText(String)
     */
    removeTooltips(tooltipText) {
      if (document.getElementsByClassName("tooltiptext")) {
        let classes = document.getElementsByClassName("tooltiptext");
        Object.keys(classes).forEach((cls) => {
          if (
            !tooltipText ||
            (tooltipText && classes[cls].innerHTML.trim() === tooltipText)
          )
            classes[cls].style.visibility = "hidden";
        });
      }
    },
    loadData() {
      if (!this.saved) this.$emit("selectbp", []);
      else {
        this.unsortedBodyParts = this.saved;
        this.selectedParts = this.saved;
        this.sortBodyParts();
        this.$emit("selectbp", this.saved);
      }
      this.$nextTick(() => this.removeTooltips());
    },
    /**
     * @func toggle
     * @desc toggles the state of body part
     * @param bodyPart(String) string indicating if the body part is active or inactive
     */
    toggle(bodyPart) {
      // making inactive
      if (this.selectedParts.filter((bp) => bp.id === bodyPart).length) {
        this.selectedParts = this.selectedParts.filter(
          (part) => part.id !== bodyPart
        );
        this.unsortedBodyParts = this.unsortedBodyParts.filter(
          (part) => part.id !== bodyPart
        );
      }
      // making active
      else {
        if (this.limitReached(this.humanSide)) 
          return
        
        let tooltipText = "";
        let reqObj = {
          id: bodyPart,
          value: bodyPart,
        };
        this.selectedParts.push(reqObj);
        this.unsortedBodyParts.push(reqObj);

        tooltipText = this.humanMap.filter((bp) => bp.id === bodyPart)[0]
          .tooltip;
        // placing tooltip on top of the body part
        this.$nextTick(() => this.placeTooltip(bodyPart));
        // remove tooltip after 1.5seconds
        setTimeout(() => this.removeTooltips(tooltipText), 1500);
      }
      // sort
      this.sortBodyParts();
      this.$emit("selectbp", this.selectedParts);
    },
    sortBodyParts() {
      let tempSelectedParts = [];
      this.humanMap.forEach((bp) => {
        this.selectedParts.forEach((sp) => {
          if (sp.value === bp.id) tempSelectedParts.push(sp);
        });
      });
      this.selectedParts = tempSelectedParts;
    },
  },

  computed: {
    humanSideLowerCase() {
      return this.humanSide.toLowerCase();
    },
    calcSelectedList() {
      let selectedList = { Front: [], Back: [] };
      this.unsortedBodyParts.forEach((part) => {
        let partObj = this.humanMap.filter(
          (bodyPart) => part.value === bodyPart.id
        )[0];
        selectedList[partObj.side].push(partObj.tooltip);
      });
      return selectedList;
    },
    isFrontAllPartsSelected() {
      return this.selectedParts.filter(ele => this.normalizeHumanMap[ele.id].side === "Front").length === this.frontTotalParts.length
    },
    isBackAllPartsSelected() {
      return this.selectedParts.filter(ele => this.normalizeHumanMap[ele.id].side === "Back").length === this.backTotalParts.length
    }
  },

  components: {
    ...IconComponents,
  },
};
</script>

<style lang="scss" scoped>
$choice-size: 150px;
$choice-font-size: 20px;
@import "style.scss";

.body_f {
  position: absolute;
  top: 64px;
  right: 21px;
}
.body_b {
  position: absolute;
  top: 64px;
  right: 21px;
}

.body_f_selected {
  position: absolute;
  top: 64px;
  right: 21px;
}
.body_b_selected {
  position: absolute;
  top: 64px;
  right: 21px;
}

.groin {
  position: absolute;
  top: 344px;
  right: 130px;
}
.left_knee_f {
  position: absolute;
  top: 478px;
  right: 114px;
}
.right_knee_f {
  position: absolute;
  top: 478px;
  right: 170px;
}
.neck_f {
  position: absolute;
  top: 147px;
  right: 144px;
}
.right_jaw {
  position: absolute;
  top: 121px;
  right: 162px;
}
.left_jaw {
  position: absolute;
  top: 121px;
  right: 141px;
}
.right_axilla {
  position: absolute;
  top: 200px;
  right: 191px;
}
.left_axilla {
  position: absolute;
  top: 200px;
  right: 106px;
}
.right_elbow_crease {
  position: absolute;
  top: 276px;
  right: 225px;
}
.left_elbow_crease {
  position: absolute;
  top: 276px;
  right: 76px;
}

.neck_b {
  position: absolute;
  top: 133px;
  right: 148px;
}
.left_knee_b {
  position: absolute;
  top: 482px;
  right: 174px;
}
.right_knee_b {
  position: absolute;
  top: 482px;
  right: 118px;
}
</style>
