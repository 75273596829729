<template>
	<header>
		<div>
			<img src="@/image/logo.svg">
		</div>
		<aside>
			<a :href="getiosLink">
				<img src="@/image/download/ios.svg">
				<span class="long">GET iPHONE APP</span>
				<span class="short">iOS</span>
			</a>
			<a :href="getAndroidLink">
				<img src="@/image/download/android.svg">
				<span class="long">GET ANDROID APP</span>
				<span class="short">ANDROID</span>
			</a>
		</aside>
	</header>
</template>
<script>
import isAccountGerd from '../util/is-account-gerd'

export default {
	name: 'exercise-header',

	methods: {
		isAccountGerd
	},

	computed: {
		getiosLink() {
			if (this.query.account === 'livechair')
				return 'https://apps.apple.com/in/app/live-chair-health/id1537755288'
			else if (this.isAccountGerd(this.query.account))
				return 'https://apps.apple.com/in/app/gerdhelp/id1568842316'
			else return 'https://itunes.apple.com/us/app/doctorbox/id1437144807'
		},
		getAndroidLink() {
			if (this.query.account === 'livechair')
				return 'https://play.google.com/store/apps/details?id=com.doctorplan.livechair.patient'
			else if (this.isAccountGerd(this.query.account))
				return 'https://play.google.com/store/apps/details?id=com.doctorplan.gerdhelp.patient&hl=en_IN&gl=US'
			else return 'https://play.google.com/store/apps/details?id=com.doctorbox.patient'
		}
	}
}
</script>
<style lang="scss" scoped>
@import "./style/header.scss";
</style>
