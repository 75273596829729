<template>
    
    <svg width="67" height="71" viewBox="0 0 67 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.69836 55.7644L0.584068 16.1498C-0.182236 6.40222 9.06528 -1.18455 18.6974 0.495853C24.1458 1.44637 29.8962 2.15871 34.7692 2.12235C38.766 2.09252 43.3748 1.55008 47.8892 0.799293C57.9128 -0.867698 67.4014 7.38736 65.9888 17.45L60.5157 56.4369C59.8164 61.4181 56.506 65.6637 51.7087 67.1763C46.1043 68.9434 38.4684 70.9423 32.4615 70.9987C26.3053 71.0566 18.4859 69.1059 12.6633 67.3261C7.54049 65.7602 4.11819 61.1047 3.69836 55.7644Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>