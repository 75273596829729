<template>
    
    <svg width="15" height="37" viewBox="0 0 15 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M15 18.0137C15 27.95 13.9333 36.3207 7.53281 36.005C1.55912 36.005 0.0656146 27.95 0.0656146 18.0137C0.0656146 8.07732 -1.21441 -0.293336 7.53281 0.022318C14.1467 0.0235043 15 8.07732 15 18.0137Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'left_ankle',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>