<template>
    
    <svg width="66" height="39" viewBox="0 0 66 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M65.6504 34.2887L64.144 9.80974C64.0561 8.38132 63.2174 7.11208 61.9101 6.52966C57.7082 4.65757 47.9628 0.714053 40 0.500859C30.0452 0.234334 12.9977 3.27042 6.56216 4.49853C4.99867 4.7969 3.79035 6.00333 3.48535 7.56554C2.73887 11.3891 1.40384 18.701 1.00189 24.0009C0.740593 27.4462 0.736168 30.887 0.799224 33.4953C0.857213 35.894 3.06834 37.5992 5.43201 37.1866C12.0716 36.0275 24.853 34.0368 34.5 34.0009C42.0316 33.9728 53.671 36.6933 60.4931 38.4801C63.1817 39.1842 65.8211 37.0627 65.6504 34.2887Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>