<template>
    <div v-if="question" class="questionnaire-unit">
        <input class="numeric-input" :style="hasOnlySingleUnit ? 'width: 250px' : ''" v-model="numericValue" placeholder="Enter value">
        <div v-if="hasOnlySingleUnit" class="unit-label">
            <div>{{ question.options[0].display_text }}</div>
        </div>
        <select v-else class="unit-dropdown" @keypress="isNumber($event)" @change="onUnitChange($event)" :disabled="!numericValue">
            <option value="" selected>Select unit</option>
            <option v-for="unit in question.options" :selected="selectedUnit === unit.value" :key="unit.id" :value="unit.value">{{ unit.display_text}}</option>
        </select>
    </div>
</template>

<script>
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'


    export default {
        name: 'questionnaire-unit',
        extends: Question,
        props: {
            saved: {
                type: Array
            }
        },
        data() {
            return {
                selectedUnit: null,
                numericValue: ''
            }
        },
        watch: {
            numericValue(value) {

                // deselect unit when numericalValue becomes empty
                if (!value)
                    this.selectedUnit = null
                else {
                    // if there is only one unit available, then select that unit
                    if (this.question.options.length === 1)
                        this.selectedUnit = this.question.options[0].value
                }

                value = value.split().filter(char => !(char != '.' && char.charCodeAt() != 8 && char.charCodeAt() != 0 && char.charCodeAt() < 48 || char.charCodeAt() > 57)).join()

                this.numericValue = value

                // Allows only numbers and decimal in input
                document.querySelector(".numeric-input").addEventListener("keypress", function (evt) {
                    if (evt.key != '.' && evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57 ) {
                        evt.preventDefault()
                    }
                })

                let ansObj = {}
                if (value === null || value === '') {
                    this.$emit('selected', [])
                } else if (typeof value === 'object') {
                    return
                } else {
                    // make ansObj
                    ansObj = {
                        "id": uuidv4(),
                        "value": this.selectedUnit,
                        "display_text": this.selectedUnit,
                        "answer": value
                    }
                }
                this.$emit('selected', [ansObj])
            }
        },
        mounted() {
            // if does not have saved answer in localStorage then emit empty value
            if (!this.saved) {
                // if there is only one unit available, then select that unit
                if (this.question.options.length === 1)
                    this.selectedUnit = this.question.options[0].value
                // emit empty because numericalValue has not yet been entered
                this.$emit('selected', [])
            } else if (this.saved[0] !== '') {
                this.selectedUnit = this.saved[0].display_text
                this.numericValue = this.saved[0].answer
            }

        },
        methods: {
            onUnitChange(e) {
                this.selectedUnit = e.target.value
                let ansObj = {
                    "id": uuidv4(),
                    "value": this.selectedUnit,
                    "display_text": this.selectedUnit,
                    "answer": this.numericValue
                }
                this.$emit('selected', [ansObj])
            },
            /**
             * @desc Return if the option is selected
             * @returns Boolean
             */
            isSelected(value) {
                return this.selectedUnit === value
            }
        },
        computed: {
            /**
             * @desc Return if the question has only one unit or not
             * @returns Boolean
             */
            hasOnlySingleUnit() {
                return this.question.options.length === 1
            }
        },
        components: {}
    }
</script>

<style lang="scss" scoped>
    .questionnaire-unit {
        position:relative;
    }

    .numeric-input {
        padding: 10px;
        border-radius: 0px;
        border: 1px solid #999999;
        outline: none;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        height: 52px;
        width: 160px;
        float: left;
    }

    .numeric-input:focus {
        border: 2px solid #833e93;
    }

    .unit-dropdown {
        padding: 8px 10px;
        border-radius: 0px;
        border: 1px solid #999999;
        outline: none;
        font-weight: 500;
        margin-left: 15px;
        font-size: 16px;
        color: #333333;
        height: 52px;
        width: 150px;
        float: left;
    }

    .unit-label {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 10px;
        color: #333333;
        font-size: 1.2rem;
    }
</style>
