<template>
    
    <svg width="22" height="73" viewBox="0 0 22 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.95252 64.5C4.2713 66.3698 4.86706 68.0211 5.64574 69.4766C9.6857 77.0279 18.4986 70.5641 18.4984 62C18.4983 60.2881 19.4051 42 20.4508 35C21.4965 28 21.9529 14.5 20.4522 8.5C20.138 7.60483 19.8164 6.76657 19.4954 5.98651C17.584 1.34217 12.2705 -0.188692 7.6175 1.70144C2.85331 3.63675 0.0337715 8.58083 0.777912 13.669C1.85209 21.0138 3.31062 31.1756 3.95252 36.5C5.26133 47.3561 1.90668 52.5 3.95252 64.5Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>