<template>
<svg width="41" height="72" viewBox="0 0 41 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M14.6079 30.536C7.40881 21.0714 -5.18369 16.0759 3.61857 8.57541C7.82177 4.99381 14.3542 1.16621 19.8395 0.529061C25.6488 -0.145712 29.6254 0.255379 32.4278 5.38857C35.2577 10.5722 36.8744 22.104 38.265 33.0606C39.9616 46.4275 41.1301 63.0937 39.182 67.5512C37.5012 71.3968 30.7628 72.7129 27.5579 70.0032C22.6331 65.8394 23.8506 42.6872 14.6079 30.536Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'left_outer_hip_f',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
