<template>
<svg width="91" height="51" viewBox="0 0 91 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M90.9686 27.4788C90.9686 22.7455 91.6221 8.93024 86.9699 5.24049C78.8543 -1.19622 63.9507 0.108582 47.9834 0.108582C32.0161 0.108582 13.6137 -1.19622 5.4981 5.24049C0.845931 8.93024 0 22.7455 0 27.4788C0 40.4692 20.364 51 45.4843 51C70.6046 51 90.9686 40.4692 90.9686 27.4788Z" :fill="color"/>
</svg>
</template>
<script>
export default {
	name: 'stomach',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>
