<template>
	<section>
		<router-link class="header" to="/article">
			<ArticleIcon class="icon"></ArticleIcon>
			<span style="color:#2d3e4f">LEARN</span>
			<ArrowIcon class="arrow"></ArrowIcon>
		</router-link>
		<main>
			<router-link class="preview" :to="{ name: 'article-viewer', params: { id: firstArticle.id } }">
				<img :src="firstArticle.image">
				<h5>{{ firstArticle.title }}</h5>
				<p>By {{ firstArticle.author }} &middot; {{ firstArticle.length }} min read</p>
			</router-link>
		</main>
		<aside>
			<!-- <router-link v-for="article in firstCategory.article"
				:key="article.id"
				class="preview"
				:to="{ name: 'article-viewer', params: {
					id: article.id,
					category: firstCategory.id
				} }">
				<img :src="article.image">
				<h5>{{ article.title }}</h5>
				<p>By {{ article.author }} &middot; {{ article.length }} min read</p>
			</router-link> -->
			<!-- <router-link class="preview" to="/article/baretts">
				<img src="https://cdn.doctorbox.com/article/barrett-s-esophagus/thumbnail-overview-barrett_s-esophagus.png">
				<h5>Barett's Esophagus</h5>
				<p>By DoctorPlan Staff &middot; 3 min read</p>
			</router-link> -->
		</aside>
	</section>
</template>

<script>
import ArticleIcon from './icon/Article'
import ArrowIcon from './icon/Arrow'

export default {
	name: 'home-article-section',

	props: {
		article: {
			type: Array
		}
	},

	computed: {
		firstArticle() {
			return this.article[0].article[0]
		},
		firstCategory() {
			return this.article[0]
		}
	},

	components: {
		ArticleIcon,
		ArrowIcon
	}
}
</script>

<style lang="scss" scoped>
@import "../section.scss";
@import "style.scss";
</style>
