<template>
    
    <svg width="36" height="63" viewBox="0 0 36 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.5053 42.1166C29.3592 29.0049 34.4222 5.62705 35.1904 1.89462C35.2528 1.59112 35.0825 1.30538 34.7898 1.20388C34.0013 0.930547 32.446 0.440175 31.2931 0.385224C29.6786 0.308271 27.3908 1.01553 26.5865 1.28463C26.4005 1.34683 26.2574 1.49462 26.1961 1.68088L13.8165 39.3152C13.8009 39.3625 13.7799 39.4078 13.7539 39.4502L0.219302 61.4794C-0.156186 62.0906 0.567355 62.7305 1.11304 62.265C5.86399 58.2117 15.44 49.6177 20.5053 42.1166Z" :fill="color"/>
</svg>

</template>

<script>
export default {
	name: 'right_thigh_b',

	props: {
		// Color or hex code
		color: {
			type: String
		}
	}
}
</script>